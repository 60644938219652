import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UniqueBrand } from './brands-model';
import { actionToError } from '../utils/transform';
import { sanitizeBrand } from '../utils/sanitizers';

export interface Brand {
  id: string;
  name: string;
  logoURL: string;
  websiteURL?: string;
  consent?: boolean;
  autoApproved?: boolean;
}

export interface BrandState {
  loading: boolean;
  approving: boolean;
  brands: Brand[];
  filteredBrands: Brand[];
  brandUsers: any[];
  brandsCount: number;
  lastBrand: any;
  error?: any;
  filtered: boolean;
  uniqueBrandsSearch: string;
  createBrandStatus: {
    loading: boolean;
    success?: boolean;
    createdBrand?: {
      brandId: string;
      brandName: string;
      programId: string;
      programName: string;
    };
  };
  updateBrandStatus: {
    loading: boolean;
    success?: boolean;
  };
  requestConsentStatus: {
    loading: boolean;
    success?: boolean;
  };
  getUniqueBrandByBrandId: {
    uniqueBrand?: UniqueBrand | null;
    success?: boolean;
  };
}

const initialState: BrandState = {
  brands: [],
  filteredBrands: [],
  brandUsers: [],
  brandsCount: 0,
  lastBrand: null,
  loading: false,
  approving: false,
  filtered: false,
  uniqueBrandsSearch: '',
  createBrandStatus: {
    loading: false,
  },
  updateBrandStatus: {
    loading: false,
  },
  requestConsentStatus: {
    loading: false,
  },
  getUniqueBrandByBrandId: {
    uniqueBrand: null,
    success: undefined,
  },
};

const brandSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {
    clear: () => initialState,
    resetCreateBrandStatus(state) {
      state.loading = false;
      state.createBrandStatus = {
        loading: false,
      };
    },
    createBrand(state) {
      state.loading = true;
      state.createBrandStatus = {
        loading: true,
      };
    },
    createBrandSuccess(state, action: PayloadAction<any>) {
      state.loading = false;

      state.createBrandStatus = {
        loading: false,
        success: true,
        createdBrand: {
          brandId: action.payload.brandId,
          brandName: action.payload.brandName,
          programId: action.payload.programId,
          programName: action.payload.programName,
        },
      };
    },
    createBrandError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.createBrandStatus = {
        loading: false,
        success: false,
      };
      state.error = actionToError(action);
    },
    updateBrand(state) {
      state.updateBrandStatus = {
        loading: true,
      };
    },
    updateBrandSuccess(state) {
      state.updateBrandStatus = {
        loading: false,
        success: true,
      };
    },
    updateBrandError(state, action: PayloadAction<any>) {
      state.updateBrandStatus = {
        loading: false,
        success: false,
      };

      state.error = actionToError(action);
    },
    deleteBrand(state) {
      state.loading = true;
      state.error = undefined;
    },
    deleteBrandError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },
    deleteBrandUser(state) {
      state.loading = true;
      state.error = undefined;
    },
    deleteBrandUserError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },
    getAllBrands(state) {
      state.loading = true;
      state.error = undefined;
    },
    getAllBrandsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.brands = action.payload.map(sanitizeBrand);
    },
    getAllBrandsError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },
    getBrands(state) {
      state.loading = true;
      state.error = undefined;
      state.lastBrand = undefined;
    },
    getBrandsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.brands = action.payload.items.map(sanitizeBrand);
      state.lastBrand = action.payload.last;
    },
    getBrandsError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },
    getMoreBrands(state) {
      state.loading = true;
      state.error = undefined;
    },
    getMoreBrandsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.brands = [
        ...state.brands,
        ...action.payload.items.map(sanitizeBrand),
      ];
      state.lastBrand = action.payload.last;
    },
    getMoreBrandsError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },
    getBrandsCount(state) {
      state.loading = true;
      state.error = undefined;
    },
    getBrandsCountSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.brandsCount = action.payload;
    },
    getBrandsCountError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },
    getBrandUsers(state) {
      state.loading = true;
      state.error = undefined;
    },
    getBrandUsersSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.brandUsers =
        action.payload?.items.map((brand: Brand) => brand) || [];
    },
    getBrandUsersError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },
    resetRequestConsentStatus(state) {
      state.requestConsentStatus = {
        loading: false,
      };
      state.error = undefined;
    },
    requestConsent(state) {
      state.requestConsentStatus = {
        loading: true,
      };
      state.error = undefined;
    },
    requestConsentSuccess(state) {
      state.requestConsentStatus = {
        loading: false,
        success: true,
      };
      state.error = undefined;
    },
    requestConsentError(state, action: PayloadAction<any>) {
      state.requestConsentStatus = {
        loading: false,
        success: false,
      };
      state.error = actionToError(action);
    },
    approveBrand(state) {
      state.approving = true;
    },
    approveBrandSuccess(state) {
      state.approving = false;
    },
    approveBrandError(state) {
      state.approving = false;
    },
    filterBrands(state) {
      state.loading = true;
    },
    filterBrandsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.filtered = true;
      state.brands = state.brands.map(sanitizeBrand).filter(action.payload);
    },
    filterBrandsError(state) {
      state.loading = false;
    },
    setUniqueBrandName(state, action: PayloadAction<string>) {
      state.uniqueBrandsSearch = action.payload;
    },
    getUniqueBrandByBrandId(state) {
      state.loading = true;
      state.getUniqueBrandByBrandId.uniqueBrand = null;
      state.getUniqueBrandByBrandId.success = undefined;
    },
    getUniqueBrandByBrandIdSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.getUniqueBrandByBrandId.uniqueBrand = action.payload;
      state.getUniqueBrandByBrandId.success = true;
    },
    getUniqueBrandByBrandIdError(state) {
      state.loading = false;
      state.getUniqueBrandByBrandId.uniqueBrand = undefined;
      state.getUniqueBrandByBrandId.success = false;
    },
    resetUniqueBrandByBrandId(state) {
      state.getUniqueBrandByBrandId = initialState.getUniqueBrandByBrandId;
    },
  },
});

export default brandSlice.reducer;
export const { actions } = brandSlice;
