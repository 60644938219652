import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Modal, Dropdown, Empty } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { Offer } from '../../../store/offers/offers-model';
import * as OfferActions from '../../../store/offers';
import {
  LinkToOfferButton,
  OffersMenu,
} from '../styled/link-to-offer-dropdown';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

export const isExpired = ({ endDate }: Offer) =>
  endDate && moment().isAfter(moment(endDate));

const LinkToOfferDropdown = ({
  brandId,
  locations,
}: {
  locations: string[];
  brandId?: string;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['locations', 'offers']);
  const allOffers = useAppSelector(state => state.offers.offers.all ?? []);
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);
  const filteredOffers = allOffers
    .filter(offer => brandId === offer.brandId)
    .filter(offer => !isExpired(offer));

  useEffect(() => {
    dispatch(OfferActions.getAllOffers());
  }, [dispatch]);

  const menu = (
    <OffersMenu>
      {filteredOffers.length === 0 && (
        <OffersMenu.Item disabled>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t('offers:empty')}
          />
        </OffersMenu.Item>
      )}
      {filteredOffers.map(offer => {
        const { id, name } = offer;
        return (
          <OffersMenu.Item
            key={id}
            onClick={() => {
              setShowDropdownMenu(false);
              Modal.confirm({
                title: t(`locations:linkToOffer.title`),
                content: t(`locations:linkToOffer.message`, {
                  offer: name,
                  count: locations.length,
                }),
                onOk: () => {
                  dispatch(OfferActions.linkLocations(id as string, locations));
                },
                okText: t(`locations:link`),
                maskClosable: true,
                icon: null,
              });
            }}
          >
            {name}
          </OffersMenu.Item>
        );
      })}
    </OffersMenu>
  );

  if (!brandId) return null;

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      visible={showDropdownMenu}
      onVisibleChange={setShowDropdownMenu}
      placement="bottomRight"
    >
      <LinkToOfferButton
        $disableAnimation
        $controlledVisibility={showDropdownMenu}
      >
        {t(`locations:actions.linkToOffer`)} <DownOutlined />
      </LinkToOfferButton>
    </Dropdown>
  );
};

export default LinkToOfferDropdown;
