import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Row, Checkbox, InputNumber } from 'antd';
import Form from '../../../../../components/form';
import { CreateUpdateOfferFormModel } from '../../../types';
import createUpdateOfferRules from '../../validation';
import { colors } from '../../../../../theme';
import { HelpMessage } from '../../styled/offer-form';

interface ReturnDaysInputProps {
  formModel: CreateUpdateOfferFormModel | null;
  disabled: boolean;
}

export default function ReturnDaysInput({
  formModel,
  disabled,
}: ReturnDaysInputProps) {
  const { t } = useTranslation('offers');

  const {
    control,
    formState: { errors },
  } = useFormContext<CreateUpdateOfferFormModel>();

  const [showReturn, setShowReturn] = useState(!!formModel?.returnDays);

  return (
    <Form.Item>
      <Checkbox
        data-testid="show-return"
        checked={showReturn}
        onChange={e => {
          const isChecked = e.target.checked;
          setShowReturn(isChecked);
        }}
        disabled={disabled}
      >
        {t('createUpdate.includeReturn')}
      </Checkbox>

      {showReturn ? (
        <Row style={{ padding: '0 20px' }}>
          <Form.ItemController
            label={
              <span
                style={{
                  marginLeft: '8px',
                  fontSize: '14px',
                  letterSpacing: '0.3px',
                  color: colors.black,
                }}
              >
                {t('createUpdate.return.label')}
              </span>
            }
            wrapperCol={{ span: 12 }}
            style={{
              alignItems: 'center',
              display: 'flex',
              flexFlow: 'row-reverse',
              justifyContent: 'flex-end',
              padding: '11px 0',
              marginBottom: 0,
            }}
            errors={errors}
            controller={{
              name: 'returnDays',
              render: ({ field }) => (
                <InputNumber
                  {...field}
                  placeholder={t('createUpdate.enterValue')}
                  min={createUpdateOfferRules.returnDays.min}
                  max={createUpdateOfferRules.returnDays.max}
                  disabled={disabled}
                />
              ),
              rules: createUpdateOfferRules.returnDays,
              control,
            }}
          />
          <HelpMessage>
            {t('createUpdate.return.message', { min: 7, max: 150 })}
          </HelpMessage>
        </Row>
      ) : (
        <>{/* we need an empty element that is not null, to satisfy antd */}</>
      )}
    </Form.Item>
  );
}
