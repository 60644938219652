import React, { useState } from 'react';
import { Dropdown, Space } from 'antd';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  MoreFiltersButton,
  StyledMenu,
  SubMenuItem,
  SubmenuContainerStyle,
} from '../styled';
import FilterDropdown, { FilterDropdownProps } from './FilterDropdown';
import { ReactComponent as PlusIcon } from '../../../assets/plus.svg';
import OverlayMultiple from './OverlayMultiple';
import Overlay from './Overlay';

interface MoreFiltersProps {
  filters: FilterDropdownProps[];
}

type FilterName = FilterDropdownProps['name'];

function MoreFilters({ filters }: MoreFiltersProps) {
  const { t } = useTranslation('common');
  const [activeFilters, setActiveFilters] = useState<FilterName[]>(() =>
    filters.reduce(
      (acc, filter) =>
        filter.selected.length !== 0 ? [...acc, filter.name] : acc,
      [] as FilterName[],
    ),
  );
  const form = useForm();

  const { reset } = form;

  const allAvailableFiltersAreActive = filters.length === activeFilters.length;

  const overlay = (
    <StyledMenu triggerSubMenuAction="click">
      <SubmenuContainerStyle />
      {filters
        .filter(({ name }) => !activeFilters.includes(name))
        .map(({ name, multiple, options, setSelected }) => (
          <SubMenuItem key={`${name}-menu`} title={t(`filters.${name}.name`)}>
            {multiple ? (
              <FormProvider {...form}>
                <OverlayMultiple
                  options={options}
                  setSelected={setSelected}
                  setVisible={() => setActiveFilters([...activeFilters, name])}
                />
              </FormProvider>
            ) : (
              <Overlay
                selected={[]}
                setSelected={setSelected}
                options={options}
                setVisible={() => setActiveFilters([...activeFilters, name])}
              />
            )}
          </SubMenuItem>
        ))}
    </StyledMenu>
  );

  return (
    <Space wrap>
      {filters
        .filter(({ name }) => activeFilters.includes(name))
        .sort(
          (a, b) =>
            activeFilters.indexOf(a.name) - activeFilters.indexOf(b.name),
        )
        .map(props => (
          <FilterDropdown
            key={props.name}
            {...props}
            onClear={() => {
              setActiveFilters(
                activeFilters.filter(filter => filter !== props.name),
              );
              reset();
            }}
          />
        ))}
      {!allAvailableFiltersAreActive && (
        <Dropdown overlay={overlay} trigger={['click']}>
          <MoreFiltersButton $active={false}>
            <PlusIcon /> {t('filters.moreFilters')}
          </MoreFiltersButton>
        </Dropdown>
      )}
    </Space>
  );
}

export default MoreFilters;
