import styled from 'styled-components';

export const MerchantGroupForm = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  > *:nth-child(1) {
    margin-right: 3px;
    flex: 1;
  }

  > *:nth-child(2) {
    flex: 1.2;
    margin-left: 3px;
  }
`;
