import { chunk } from 'lodash';
import { OfferTransactionSource } from '@fidel.uk/types/lib/offer';
import OfferApiService from '../offers-api-service';
import { Offer, OfferNotification } from '../offers-model';
import { actions as statusActions } from '../../status/status-reducer';
import { actions } from './current-offer-reducer';
import { Thunk } from '../../types';
import { SelectedUniqueOfferPayload } from '../../../modules/offers/types';
import { queryClient } from '../../../react-query';

const offerService: OfferApiService = new OfferApiService();

export const { clear, clearError, clearSubState, resetNewlyAddedUniqueOffer } =
  actions;

export const offerStatus =
  (code: OfferNotification, refetch = true, onClose?: () => void): Thunk =>
  async dispatch => {
    dispatch(statusActions.status({ code, onClose }));

    if (refetch) {
      queryClient.invalidateQueries(['offers']);
    }
  };

export const getOffer =
  (offerId: string): Thunk =>
  async dispatch => {
    dispatch(actions.getOffer());

    try {
      const response = await offerService.getOffer(offerId);
      dispatch(actions.getOfferSuccess(response.data));
    } catch (error) {
      dispatch(actions.getOfferError({ error }));
    }
  };

export const linkCardToOffer =
  (cardId: string, offerId: string): Thunk =>
  async dispatch => {
    dispatch(actions.linkCardToOffer());

    try {
      await offerService.linkCardToOffer(cardId, offerId);
      dispatch(actions.linkCardToOfferSuccess());
    } catch (error) {
      dispatch(actions.linkCardToOfferError({ error }));
    }
  };

export const linkLocation =
  (locationId: string, offerId: string): Thunk =>
  async dispatch => {
    dispatch(actions.linkLocation());

    try {
      await offerService.linkLocation(locationId, offerId);
      dispatch(actions.linkLocationSuccess());
    } catch (error) {
      dispatch(actions.linkLocationError({ error }));
    }
  };

export const unlinkLocation =
  (locationId: string, offerId: string): Thunk =>
  async dispatch => {
    dispatch(actions.unlinkLocation());

    try {
      await offerService.unlinkLocation(locationId, offerId);
      dispatch(actions.unlinkLocationSuccess());
    } catch (error) {
      dispatch(actions.unlinkLocationError({ error }));
    }
  };

export const linkLocations =
  (offerId: string, locations: string[]): Thunk =>
  async dispatch => {
    dispatch(actions.linkLocations());

    const payload = await Promise.all(
      locations.map(locationId =>
        offerService
          .linkLocation(locationId, offerId)
          .then(() => ({ locationId, linked: true }))
          .catch(() => ({ locationId, linked: false })),
      ),
    );

    dispatch(actions.linkLocationsSuccess(payload));
    dispatch(offerStatus(OfferNotification.MANAGE_LOCATIONS_MANUALLY));
  };

export const linkMid =
  (mid: string, offerId: string): Thunk =>
  async dispatch => {
    dispatch(actions.linkMid());

    try {
      await offerService.linkMid(mid, offerId);
      dispatch(actions.linkMidSuccess());
    } catch (error) {
      dispatch(actions.linkMidError({ error }));
    }
  };

export const linkMids =
  (offerId: string, mids: string[]): Thunk =>
  async dispatch => {
    dispatch(actions.linkMids());

    // we split our list into batches of 10
    const chunks = chunk(mids, 10);
    for (const chk of chunks) {
      // eslint-disable-next-line no-await-in-loop
      await Promise.allSettled(
        chk.map(mid => offerService.linkMid(mid, offerId)),
      );
    }

    dispatch(actions.linkMidsSuccess());
  };

export const promoteMarketplaceOaasOffer =
  (offerId: string, done?: () => void): Thunk =>
  async dispatch => {
    dispatch(actions.promoteMarketplaceOaasOffer());

    try {
      await offerService.promoteMarketplaceOaasOffer(offerId);
      done && done();
      dispatch(actions.promoteMarketplaceOaasOfferSuccess());
    } catch (error) {
      dispatch(actions.promoteMarketplaceOaasOfferError({ error }));
    }
  };

export const unlinkLocations =
  (offerId: string, locations: string[]): Thunk =>
  async dispatch => {
    dispatch(actions.unlinkLocations());

    const payload = await Promise.all(
      locations.map(locationId =>
        offerService
          .unlinkLocation(locationId, offerId)
          .then(() => ({ locationId, unlinked: true }))
          .catch(() => ({ locationId, unlinked: false })),
      ),
    );

    dispatch(actions.unlinkLocationsSuccess(payload));
    dispatch(offerStatus(OfferNotification.MANAGE_LOCATIONS_MANUALLY));
  };

export const getAllLocations =
  (offerId: string): Thunk =>
  async dispatch => {
    dispatch(actions.getAllLocations(offerId));

    try {
      const response = await offerService.getAllLocations(offerId);
      dispatch(actions.getAllLocationsSuccess({ offerId, items: response }));
    } catch (error) {
      dispatch(actions.getAllLocationsError({ offerId, error }));
    }
  };

export const createOffer =
  (
    offer: Offer,
    locations?: string[],
    programIds?: string[],
    mids?: string[],
    // is OaaS offer and content provider creating the offer
    isOaas?: boolean,
    isContentProvider?: boolean,
  ): Thunk =>
  async dispatch => {
    dispatch(actions.createOffer());

    try {
      const { data } = await offerService.createOffer(offer);
      !isOaas && dispatch(actions.createOfferSuccess(data));

      if (programIds)
        dispatch(linkAllProgramsLocationsToOffer(data.items[0].id, programIds));
      else if (locations) dispatch(linkLocations(data.items[0].id, locations));
      else if (mids) dispatch(linkMids(data.items[0].id, mids));

      const dispatchOfferStatusChange = () => {
        dispatch(actions.createOfferSuccess(data));
        dispatch(
          offerStatus(
            OfferNotification.CREATE_OFFER,
            ![programIds, locations].some(Boolean),
          ),
        );
      };

      if (isContentProvider && isOaas) {
        // should auto promote offer to marketplace if oaas and cp
        dispatch(
          promoteMarketplaceOaasOffer(
            data.items[0].id,
            dispatchOfferStatusChange,
          ),
        );
      } else {
        dispatchOfferStatusChange();
      }
    } catch (error) {
      dispatch(actions.createOfferError({ error }));
    }
  };

export const updateOffer =
  (
    offerId: string,
    offer: Partial<Offer>,
    locations?: { link?: string[]; unlink?: string[] },
  ): Thunk =>
  async dispatch => {
    dispatch(actions.updateOffer());

    try {
      const { data } = await offerService.updateOffer(offerId, offer);
      dispatch(actions.updateOfferSuccess(data));

      if (locations?.link)
        dispatch(linkLocations(data.items[0].id, locations.link));
      if (locations?.unlink)
        dispatch(unlinkLocations(data.items[0].id, locations.unlink));

      dispatch(offerStatus(OfferNotification.UPDATE_OFFER));
    } catch (error) {
      dispatch(actions.updateOfferError({ error }));
    }
  };

const linkAllProgramsLocationsToOffer =
  (offerId: string, programIds: string[]): Thunk =>
  async dispatch => {
    try {
      dispatch(actions.linkAllProgramsLocationsToOffer());
      const locationLinkingPromises =
        await offerService.linkAllProgramsLocationsToOffer(offerId, programIds);
      await Promise.all(locationLinkingPromises);

      dispatch(actions.linkAllProgramsLocationsToOfferSuccess());
      dispatch(offerStatus(OfferNotification.LINK_ALL_PROGRAM_LOCATIONS));
    } catch (error) {
      dispatch(actions.linkAllProgramsLocationsToOfferError());
    }
  };

export const getAllUniqueLocations =
  (uniqueOfferId: string): Thunk =>
  async dispatch => {
    dispatch(actions.getAllUniqueLocations(uniqueOfferId));

    try {
      const response = await offerService.getAllUniqueLocations(uniqueOfferId);
      dispatch(
        actions.getAllUniqueLocationsSuccess({
          uniqueOfferId,
          items: response,
        }),
      );
    } catch (error) {
      dispatch(actions.getAllUniqueLocationsError({ uniqueOfferId, error }));
      dispatch(actions.addUniqueOfferError({ error }));
    }
  };

export const addUniqueOffer =
  (
    {
      id,
      programIds,
      transactionSource,
      supplierFields,
      cardActivationLimit: qualifiedTransactionsLimit,
    }: SelectedUniqueOfferPayload,
    onCloseHandler?: () => void,
  ): Thunk =>
  async dispatch => {
    const isOaas = transactionSource === OfferTransactionSource.OAAS;
    dispatch(actions.addUniqueOffer());

    try {
      const offerSupplierFields = Object.entries(supplierFields ?? {}).length
        ? supplierFields
        : undefined;

      const activation = qualifiedTransactionsLimit
        ? { enabled: true, qualifiedTransactionsLimit }
        : undefined;

      await offerService.addUniqueOffer(
        id,
        programIds,
        offerSupplierFields,
        activation,
        transactionSource,
      );
      dispatch(actions.addUniqueOfferSuccess(id));

      dispatch(
        offerStatus(
          isOaas
            ? OfferNotification.ADD_UNIQUE_OAAS_OFFER
            : OfferNotification.ADD_UNIQUE_OFFER,
          true,
          onCloseHandler,
        ),
      );
    } catch (error) {
      dispatch(actions.addUniqueOfferError({ error }));
    }
  };
