import React, { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';

import DrawerForm from '../../../components/drawer-form';
import drawerTransition, {
  DrawerVisibleProps,
} from '../../../components/drawer-transition';
import { CardForm, stripeWrapper } from '../../../components/stripe';
import { useAddPayment } from '../../../hooks/use-stripe-hooks';

export interface AddPaymentProps extends DrawerVisibleProps {
  onClose: () => any;
}

const AddPayment = ({
  onClose,
  visible,
  afterVisibleChange,
}: AddPaymentProps) => {
  const { t } = useTranslation();
  const { submit, loading, done } = useAddPayment();

  const processing = loading && !done;

  function handleSubmit(e: FormEvent) {
    e.preventDefault();
    submit();
  }

  return (
    <DrawerForm
      title={t('account.payment.addNewPaymentCard')}
      onClose={onClose}
      onSubmit={handleSubmit}
      processing={processing}
      hasFinished={done}
      visible={visible}
      afterVisibleChange={afterVisibleChange}
    >
      <CardForm />
    </DrawerForm>
  );
};

export default drawerTransition(stripeWrapper(AddPayment));
