import React from 'react';

import { Tag, Typography } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import BrandCard from '../components/BrandCard';
import { Brand } from '../../../store/brands/brands-model';
import { getBrandLogoUrl } from '../utils';
import { Action } from '../../../components/actions-menu';
import { useBrands } from '../context/brands-context';

export default function useBrandToRow(
  actions: (brand: Brand) => Action[],
  onRowClick: (brand: Brand) => void,
  selectedBrand: Brand | null,
) {
  const { t } = useTranslation('brands');
  const { editedBrandsIds } = useBrands();

  return (brand: Brand, index: number) => {
    const consent = brand.consent ? (
      <Tag color="green" data-onboarding-target={`brand-${index}`}>
        {t('status.approved')}
      </Tag>
    ) : (
      <Tag color="orange">{t('status.pending')}</Tag>
    );
    const contents = [
      <BrandCard
        logoURL={getBrandLogoUrl(brand, editedBrandsIds)}
        name={brand.name}
      />,
      consent,
      <Typography.Text type="secondary">
        {moment(brand.created).fromNow()}
      </Typography.Text>,
    ];

    return {
      contents,
      actions: actions(brand),
      active: selectedBrand?.id === brand.id,
      onClick: () => onRowClick(brand),
    };
  };
}
