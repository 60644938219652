import styled from 'styled-components';
import { Menu } from 'antd';

import { boxShadow, colors, translucentBorder } from '../../theme';

const { Divider, Item } = Menu;

export const StyledMenu = styled(Menu)`
  background: white;
  right: -9px;
  border: 1px solid ${colors.lightgrey2};
  border-radius: 4px;
  box-shadow: ${boxShadow};
  overflow: hidden;
  z-index: 5;
  padding: 6px 0;
  ${translucentBorder};
`;

export const StyledMenuItem = styled(Item)`
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 125px;
  height: 32px;
  padding: 0 14px;
  background: white;
  border: none;
  outline: none;

  font-size: 14px;
  text-align: left;

  cursor: pointer;
  transition: background 0.3s ease;

  &[disabled] {
    cursor: not-allowed;
  }

  &:focus,
  &:hover {
    background: ${colors.lightgrey2};
    transition: background 0.3s ease;
  }
`;

export const StyledMenuDivider = styled(Divider)`
  margin: 8px 0;
`;
