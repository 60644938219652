import React, { useEffect, useState } from 'react';
import {
  FieldError,
  RegisterOptions,
  DeepMap,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

import Form from '..';
import { FormButton } from '../../buttons';
import { KeyValuePairsWrapper, Equals, RemoveKeyValuePair } from '../styled';
import {
  CreateUpdateOfferFormModel,
  CreateUpdateOfferKeyValueFields,
} from '../../../modules/offers/types';

export type KeyValuePairsDefaultFields = {
  key: string;
  value: string;
}[];

interface KeyValuePairsProps {
  name: keyof CreateUpdateOfferKeyValueFields;
  errors?: DeepMap<any, FieldError>;
  fixedFields: boolean;
  defaultFields?: KeyValuePairsDefaultFields | null;
  keyField?: {
    rules: RegisterOptions;
  };
  valueField?: {
    rules: RegisterOptions;
  };
  showKeyValueLabel?: boolean;
  disabled?: boolean;
}

const KeyValuePairs = ({
  name,
  errors,
  fixedFields,
  defaultFields,
  keyField,
  valueField,
  showKeyValueLabel,
  disabled,
}: KeyValuePairsProps) => {
  const { t } = useTranslation();
  const { setValue, control } = useFormContext<CreateUpdateOfferFormModel>();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const [defaultFieldsAdded, setDefaultFieldsAdded] = useState(false);

  useEffect(() => {
    setDefaultFieldsAdded(false);
    remove();
  }, [defaultFields, remove]);

  useEffect(() => {
    if (
      !defaultFieldsAdded &&
      fields.length === 0 &&
      defaultFields &&
      defaultFields.length > 0
    ) {
      setDefaultFieldsAdded(true);
      append(defaultFields, { shouldFocus: false });
    }
  }, [fields, defaultFields, defaultFieldsAdded, append]);

  useEffect(() => {
    if (fixedFields)
      fields.forEach(({ key }, idx) => {
        setValue(`${name}.${idx}.key`, key);
      });
  }, [fixedFields, fields, name, setValue]);

  return (
    <>
      {fields.map((field, idx) => {
        const currErrorKey = Array.isArray(errors) ? errors[idx]?.key : errors;
        const currErrorValue = Array.isArray(errors)
          ? errors[idx]?.value
          : errors;

        return (
          <KeyValuePairsWrapper
            hasKeyValueLabel={showKeyValueLabel}
            key={field.id}
          >
            <Form.ItemController
              label={t('form.keyValuePairs.key')}
              errors={{ [`${name}.${idx}.key`]: currErrorKey }}
              controller={{
                name: `${name}.${idx}.key`,
                render: ({ field: { ...rest } }) =>
                  fixedFields ? (
                    <span>{field.key}</span>
                  ) : (
                    <Input
                      {...rest}
                      placeholder={t('form.keyValuePairs.key')}
                      defaultValue={field.key}
                    />
                  ),
                rules: keyField?.rules,
                control,
              }}
            />
            <Equals />
            <Form.ItemController
              label={t('form.keyValuePairs.value')}
              errors={{ [`${name}.${idx}.value`]: currErrorValue }}
              controller={{
                name: `${name}.${idx}.value`,
                render: ({ field: { onChange, ...rest } }) => (
                  <Input
                    {...rest}
                    placeholder={t('form.keyValuePairs.value')}
                    defaultValue={field.value}
                    disabled={disabled}
                    onChange={({ target }) => onChange(`${target.value}`)}
                  />
                ),
                rules: valueField?.rules,
                control,
              }}
            />
            {!fixedFields && (
              <RemoveKeyValuePair
                onClick={() => remove(idx)}
                data-testid="remove"
              />
            )}
          </KeyValuePairsWrapper>
        );
      })}
      {!fixedFields && (
        <FormButton size="small" block onClick={() => append({})}>
          {t('form.keyValuePairs.addField')}
        </FormButton>
      )}
    </>
  );
};

export default KeyValuePairs;
