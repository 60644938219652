import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import { getExportableCards } from '../../../store/cards/cards-actions';
import { selectHideLastNumbers } from '../../../store/account/account-selectors';
import ExportService from '../../../services/export-service';
import ExportDropdown from '../../../components/export-dropdown';
import useSelectedProgram from '../../../hooks/use-selected-program';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

interface CardsFilterProps {
  selectedIds?: string[];
}

const CardsExport = ({ selectedIds }: CardsFilterProps) => {
  const dispatch = useAppDispatch();
  const { selectedProgram } = useSelectedProgram();
  const { t } = useTranslation();
  const dateRange = { from: '', to: '' };
  const { cards, exportableCards, filters } = useAppSelector(
    state => ({
      cards: state.cards.cards,
      exportableCards: state.cards.exportableCards,
      filters: state.filters.cards,
    }),
    shallowEqual,
  );
  const [selectFormatVisible, setSelectFormatVisible] = useState(false);
  const [fileFormat, setFileFormat] = useState('pdf');
  const [shouldExport, setShouldExport] = useState(false);
  const hideLastNumbers = useAppSelector(selectHideLastNumbers);

  function exportCards(format: string) {
    const exportService = new ExportService(t);
    const columnsOptions = [
      { key: 'lastNumbers', title: t('cards.label.lastNumbers') },
      { key: 'expDate', title: t('cards.label.expDate') },
      { key: 'scheme', title: t('cards.label.scheme') },
      { key: 'id', title: t('cards.label.cardId') },
      { key: 'created', title: t('cards.label.added') },
    ];

    const cardsToExport = (selectedIds ? cards : exportableCards).filter(
      card => !selectedIds || selectedIds.includes(card.id),
    );

    exportService.exportCards({
      options: columnsOptions.filter(column =>
        hideLastNumbers ? column.key !== 'lastNumbers' : column,
      ),
      format,
      raw: cardsToExport,
      programName: selectedProgram.name,
      dateRange,
    });

    setShouldExport(false);
  }

  useEffect(() => {
    if (exportableCards.length > 0 && shouldExport && selectedProgram) {
      exportCards(fileFormat);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportableCards, shouldExport, selectedProgram]);

  function handleClickExport(format: string) {
    setFileFormat(format);
    setSelectFormatVisible(false);
    setShouldExport(true);

    if (selectedIds) {
      exportCards(format);
      return;
    }

    dispatch(
      getExportableCards({
        programId: selectedProgram?.id,
        ...filters.dateRange,
      }),
    );
  }

  return (
    <ExportDropdown
      label={selectedIds ? t('export.selectedLabel') : t('export.label')}
      disabled={cards.length === 0}
      loading={shouldExport}
      handleClickExport={handleClickExport}
      showSelectFormat={setSelectFormatVisible}
      selectFileFormatOpen={selectFormatVisible}
    />
  );
};

export default CardsExport;
