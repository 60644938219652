import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

import { VirtualCard } from '../../../../store/account/account-model';
import {
  Card,
  CardContainer,
  CardElements,
  CardInfo,
  CardInfoItem,
  CardNumbers,
  CardScheme,
} from './styled';
import ExpandingCaption from '../../../../components/expanding-caption';
import { GreyButton } from '../../../../components/buttons';
import { formatCardNumber } from '../../../../utils/schemes';
import { ReactComponent as Visa } from './assets/visa.svg';

interface VirtualCardItem {
  label: 'cardNumber' | 'expiry' | 'cvc' | 'cardholder' | 'email';
  value: string | number;
}

const VirtualCards = ({ card }: { card: VirtualCard }) => {
  const { t } = useTranslation('common', { keyPrefix: 'account.virtualCard' });
  const [showAllCardInfo, setShowAllCardInfo] = useState(false);

  const formattedCardNumber =
    card?.fullNumber && formatCardNumber(card.fullNumber, card.scheme);

  function getVirtualCardItems(): VirtualCardItem[] {
    return [
      {
        label: 'cardNumber',
        value: showAllCardInfo
          ? formattedCardNumber
          : `•••• ${card.lastNumbers}`,
      },
      {
        label: 'expiry',
        value: `${card.expMonth} / ${card.expYear}`,
      },
      { label: 'cvc', value: showAllCardInfo ? card.cvc : '•••' },
      { label: 'cardholder', value: card.cardholder.name },
      { label: 'email', value: card.cardholder.email },
    ];
  }

  return (
    <CardContainer>
      <Card>
        <CardElements>
          <CardNumbers>
            {showAllCardInfo ? formattedCardNumber : `•••• ${card.lastNumbers}`}
          </CardNumbers>
          <CardScheme>
            <Visa />
          </CardScheme>
        </CardElements>
      </Card>
      <CardInfo>
        {getVirtualCardItems().map(({ label, value }) => (
          <CardInfoItem key={label}>
            <Typography.Text type="secondary" style={{ fontSize: '16px' }}>
              {t(`card.${label}`)}
            </Typography.Text>
            <Typography.Text style={{ textAlign: 'right' }}>
              {value}
            </Typography.Text>
          </CardInfoItem>
        ))}
        <GreyButton
          size="small"
          onClick={() => setShowAllCardInfo(!showAllCardInfo)}
          style={{ margin: '16px 0 40px' }}
        >
          {t(`card.${!showAllCardInfo ? 'showInfo' : 'hideInfo'}`)}
        </GreyButton>
        <div>
          <ExpandingCaption i18nKey="account.virtualCard.caption" />
        </div>
      </CardInfo>
    </CardContainer>
  );
};

export default VirtualCards;
