import React from 'react';
import { ReactComponent as DefaultEmpty } from '../../assets/illustrations/empty-states/default.svg';
import {
  ReactComponent as EmptyLocations,
  ReactComponent as EmptyOffers,
} from '../../assets/illustrations/empty-states/offers.svg';

import { ReactComponent as EmptyOffersAwaitingApproval } from '../../assets/illustrations/empty-states/offers-awaitingApproval.svg';

export const illustrations = {
  locations: <EmptyLocations width={280} height={170} />,
  offers: <EmptyOffers />,
  default: <DefaultEmpty />,
  offersAwaitingApproval: <EmptyOffersAwaitingApproval />,
};
export type IllustrationsKeys = keyof typeof illustrations;
