import React, { ReactNode } from 'react';
import { Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Avatar from '../../avatar';

import { DetailTitleContainer } from '../styled/detail-title';

interface DetailTitleProps {
  description?: string;
  emptyMessage?: string;
  name?: string;
  title?: ReactNode;
  subTitle?: ReactNode;
  logo?: string;
  labels?: ReactNode;
}

const DetailTitle = ({
  description,
  emptyMessage,
  logo,
  name,
  title,
  subTitle,
  labels,
}: DetailTitleProps) => {
  const { t } = useTranslation();

  return (
    <DetailTitleContainer>
      {title ? (
        <Row>
          <Col>
            <Row justify="center">
              <Col>
                <Avatar size={72} src={logo} color={{ border: 'lightgrey2' }}>
                  {name}
                </Avatar>
              </Col>
            </Row>

            {labels && (
              <Row justify="center" style={{ marginTop: 0 }}>
                <Col>{labels}</Col>
              </Row>
            )}

            {subTitle && (
              <Row justify="center" className="subtitle">
                <Col>
                  <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                    {subTitle}
                  </Typography.Text>
                </Col>
              </Row>
            )}

            <Row justify="center" className="title">
              <Col span={24}>
                <Typography.Text aria-label={`${title}`}>
                  {title}
                </Typography.Text>
              </Col>
            </Row>

            <Row justify="center">
              <Col className="description">
                <Typography.Text>{description}</Typography.Text>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Typography.Paragraph>
          {emptyMessage || t('detail.empty')}
        </Typography.Paragraph>
      )}
    </DetailTitleContainer>
  );
};

export default DetailTitle;
