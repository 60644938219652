import http from '../../services/http-service';

export default class StripeApiService {
  public async setStripeIntent(accountId: string) {
    const response = await http.post(
      `accounts/${accountId}/stripe-setup-intent`,
      {},
    );
    return response.data.items[0];
  }
}
