import React from 'react';
import styled from 'styled-components';
import Avatar from '../../../../components/avatar';
import { colors } from '../../../../theme';
import { Card } from '../../../transactions/components/styled/transaction-card';

const Container = styled(Card)`
  display: flex;
  background: ${colors.lightgrey7};
`;

const Info = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Text = styled.p`
  margin: 0;
`;

const Name = styled.p`
  color: ${colors.black};
  margin: 0;
  font-size: 14px;
  font-weight: 500;
`;

interface LocationSummaryProps {
  location: any;
}

const LocationSummary = ({ location }: LocationSummaryProps) => {
  const { address, city, postcode } = location;
  const { name, websiteURL, logoURL } = location.brandId;

  return (
    <Container>
      <Avatar size={88} src={logoURL}>
        {name}
      </Avatar>
      <Info>
        <div>
          <Name>{name}</Name>
          <Text>{websiteURL}</Text>
        </div>
        <div>
          <Text>{address}</Text>
          <Text>
            {city}, {postcode}
          </Text>
        </div>
      </Info>
    </Container>
  );
};

export default LocationSummary;
