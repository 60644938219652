import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';

import Form from '../../../components/form';
import regex from '../../../utils/regex';

type FormItemControllerProps = React.ComponentProps<typeof Form.ItemController>;

interface EmailInputProps extends React.ComponentProps<typeof Input> {
  errors: FormItemControllerProps['errors'];
  control: FormItemControllerProps['controller']['control'];
}

const EmailInput = React.forwardRef<Input, EmailInputProps>(
  ({ errors, control, ...rest }, ref) => {
    const { t } = useTranslation();
    return (
      <Form.ItemController
        errors={errors}
        label={t('sign.email')}
        validateCharacters
        controller={{
          name: 'email',
          render: ({ field }) => (
            <Input
              {...rest}
              {...field}
              onChange={e => {
                field.onChange((e.target.value ?? '').toLowerCase());
              }}
              ref={ref}
              type="email"
              placeholder={t('sign.email')}
            />
          ),
          rules: {
            required: true,
            validate: { email: (value: string) => regex.email.test(value) },
          },
          control,
        }}
      />
    );
  },
);

export default EmailInput;
