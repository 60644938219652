import styled from 'styled-components';

export const MissingProgram = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 20px 0;

  .ant-typography {
    max-width: 270px;
    text-align: center;
    font-size: 14px;
  }
`;
