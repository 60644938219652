import styled from 'styled-components';
import { BlackButton } from '../../../components/buttons';
import { colors } from '../../../theme';

export const AccountStatusContainer = styled.div`
  color: ${colors.grey4};
  font-size: 12px;
  width: 100%;
  margin-top: 7px;
`;

export const GoLiveButton = styled(BlackButton).attrs(() => ({
  size: 'small',
}))`
  margin-top: 1rem;

  &:focus,
  &:active {
    background: ${colors.black};
    color: ${colors.white};
    border: none;
  }
`;
