import DocumentsApiService from '../store/documents/documents-api-service';

async function downloadDocument(
  documentUrl: string | undefined,
  onError: () => void,
) {
  const documentsApiService = new DocumentsApiService();
  const { getDownloadDocumentUrl } = documentsApiService;

  try {
    if (!documentUrl) throw Error('No URL provided.');
    const url = await getDownloadDocumentUrl(documentUrl);

    const downloadAnchorTag = document.createElement('a');
    downloadAnchorTag.style.display = 'none';
    document.body.appendChild(downloadAnchorTag);

    downloadAnchorTag.href = url;
    downloadAnchorTag.setAttribute('download', '');
    downloadAnchorTag.click();

    document.body.removeChild(downloadAnchorTag);
  } catch (error) {
    onError();
  }
}

export default downloadDocument;
