import { Program } from '../../store/programs/programs-reducer';
import { Field } from '../../types';

export type BrandAction =
  | 'approve'
  | 'addUser'
  | 'addUniqueBrand'
  | 'copy'
  | 'edit'
  | 'delete'
  | 'reject'
  | 'viewRawDetail';

export enum FormSteps {
  SELECT_BRAND = 'selectBrand',
  CREATE = 'create',
  EDIT_BRAND = 'editBrand',
  BRAND_CONSENT = 'brandConsent',
  ADD_LOCATIONS = 'addLocations',
  ADD_NEW_LOCATIONS = 'addNewLocations',
  EDIT_LOCATION = 'editLocation',
}

export type LocationsFormSteps =
  | FormSteps.ADD_LOCATIONS
  | FormSteps.ADD_NEW_LOCATIONS;

export enum BrandDetailFieldsNames {
  NAME = 'brandName',
  LOGO = 'brandLogo',
  WEBSITE_URL = 'websiteURL',
}

export type BrandDetailField = Omit<Field, 'name'> & {
  name: `${BrandDetailFieldsNames}`;
};

export type BrandStatus = 'approved' | 'pending';

export interface ProgramWithBrandUsers extends Program {
  brandUsers: BrandUser[];
}

interface BrandUser {
  created: string;
  email: string;
  nameFirst: string;
  nameLast: string;
  status: BrandStatus;
}
