import { QueryClient, QueryKey } from '@tanstack/react-query';

export async function setQueryData<T>({
  queryKey,
  updateFn,
  queryClient,
}: {
  queryKey: QueryKey;
  updateFn: (previous: T) => T;
  queryClient: QueryClient;
}) {
  await queryClient.cancelQueries({ queryKey });

  const previous = queryClient.getQueryData<T>(queryKey);

  if (previous) {
    queryClient.setQueryData(queryKey, updateFn(previous));
  }

  return previous;
}
