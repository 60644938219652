import { AccountStatusTypesEnum } from '@fidel.uk/types/lib/account';
import { useAppSelector } from '../store/hooks';
import useIsSignedIn from './use-is-signed-in';

function useIsAccountLocked() {
  const { isModerator, isAccountManagerAdmin } = useIsSignedIn();

  const isAccountLocked = useAppSelector(
    state =>
      state.account.details?.status === AccountStatusTypesEnum.FULLY_BLOCKED,
  );

  return !isModerator && !isAccountManagerAdmin && isAccountLocked;
}

export default useIsAccountLocked;
