import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Checkbox } from 'antd';
import { weekDays } from '../../../../../types';
import { Schedule } from '../../styled/offer-form';
import { CreateUpdateOfferFormModel } from '../../../types';
import createUpdateOfferRules from '../../validation';

interface ScheduleCheckboxGroupProps {
  disabled: boolean;
}

export default function ScheduleCheckboxGroup({
  disabled,
}: ScheduleCheckboxGroupProps) {
  const { t } = useTranslation(['common', 'offers']);

  const {
    control,
    formState: { errors },
  } = useFormContext<CreateUpdateOfferFormModel>();

  const scheduleOptions = [...weekDays.slice(1), weekDays[0]].map(day => ({
    label: t(`common:days.${day}`).slice(0, 2),
    value: day,
  }));

  return (
    <Schedule
      errors={errors}
      label={t('offers:fields.schedule.label')}
      controller={{
        name: 'schedule',
        render: ({ field: { ref, ...rest } }) => (
          <Checkbox.Group
            {...rest}
            options={scheduleOptions}
            disabled={disabled}
          />
        ),
        rules: createUpdateOfferRules.schedule,
        control,
      }}
    />
  );
}
