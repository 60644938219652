import styled from 'styled-components';
import { Col } from 'antd';

import { colors } from '../../../theme';

export const AmountCell = styled.div`
  color: ${colors.grey4};

  span:first-of-type {
    color: ${colors.black};
    font-weight: 500;
  }
`;

export const AccountTableDocRow = styled.div<{ bold?: boolean }>`
  display: flex;
  align-items: center;
  font-weight: ${({ bold }) => (bold ? '500' : '400')};

  svg {
    margin-right: 10px;
  }
`;

export const CardContainer = styled.div`
  padding: 8px 10px 8px 0;
  width: 100%;
  color: ${colors.grey4};
  font-size: 12px;

  .ant-col {
    display: flex;

    &:first-of-type {
      align-items: center;
      margin-right: 8px;

      .ant-badge {
        left: -19px;
        position: absolute;
      }
    }

    &:nth-of-type(2) {
      flex-flow: column wrap;
    }
  }
`;

export const CardContent = styled.div`
  display: flex;
  padding: 8px 10px 8px 0;
  align-items: center;
`;

export const CardContainerBordered = styled(CardContainer)`
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: ${colors.lightgrey2};
  margin-top: -1px;
`;

export const TableWithRightContentWrapper = styled(Col)<{
  $showSideBar?: boolean;
}>`
  flex: 1 1 auto;
  height: 100%;

  @media (min-width: 1200px) {
    margin-right: ${({ $showSideBar }) => ($showSideBar ? '10px' : '0px')};
    max-width: ${({ $showSideBar }) =>
      $showSideBar ? 'calc(100% - 380px)' : '100%'};
  }
`;
