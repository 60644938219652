import { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

type FormDataHandler = (data: any) => void;

export default function useCaptchaFormSubmit(formDataHandler: FormDataHandler) {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = useCallback(
    async data => {
      if (!executeRecaptcha) {
        return;
      }
      const token = await executeRecaptcha('loginFormSubmit');
      await formDataHandler({ ...data, token });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [executeRecaptcha],
  );

  return {
    onSubmit,
  };
}
