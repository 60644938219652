import React from 'react';
import { Col, Row } from 'antd';

import { EmptyText } from '../../../../../components/empty';
import { illustrations } from '../../../../../components/empty/illustrations';

interface EmptyProps {
  message?: React.ReactNode;
  illustration?: keyof typeof illustrations;
  children?: React.ReactNode;
}

export default function Empty({
  message,
  illustration = 'default',
  children,
}: EmptyProps) {
  return (
    <Row justify="center" style={{ padding: '16px 30px' }}>
      <Col>{illustrations[illustration]}</Col>

      {message && (
        <Col style={{ textAlign: 'center' }}>
          <EmptyText style={{ display: 'block', lineHeight: '20px' }}>
            {message}
          </EmptyText>
        </Col>
      )}
      <Col>{children}</Col>
    </Row>
  );
}
