import http from '../../services/http-service';

export default class BrandUserApiService {
  public getInvoices(brandId: string) {
    return http.get(`brands/${brandId}/invoices`);
  }

  public completeStep(userId: string, steps: any = {}, completedStep: string) {
    steps[completedStep] = true;
    return http.patch(`users/${userId}/update-steps`, { ...steps });
  }

  public updateSteps(userId: string, steps: any = {}) {
    return http.patch(`users/${userId}/update-steps`, { ...steps });
  }

  public updateBusiness(userId: string, data: any) {
    return http.patch(`users/${userId}/business`, data);
  }
}
