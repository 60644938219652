import React from 'react';
import styled from 'styled-components';
import { Col, Typography, Image, Divider, Modal, Input, Spin } from 'antd';
import { colors } from '../../../../theme';
import { ReactComponent as LampIcon } from '../../../../assets/lamp.svg';
import { ListStyle } from '../types';

export const StyledCol = styled(Col)<{
  $backgroundColor?: keyof typeof colors;
}>`
  padding: 32px;
  background-color: ${({ $backgroundColor }) =>
    colors[$backgroundColor ?? 'white']};
`;

export const StyledText = styled(Typography.Paragraph)<{
  $fontSize?: number;
  $fontWeight?: number;
}>`
  font-size: ${({ $fontSize }) => $fontSize || '16'}px;
  font-weight: ${({ $fontWeight }) => $fontWeight || '400'};

  &.ant-typography {
    margin-bottom: 8px;
  }
`;

export const StyledImage = styled(props => (
  <Image preview={false} {...props} />
))<{
  $maxHeight: number;
}>`
  max-height: ${({ $maxHeight }) => $maxHeight}px;
`;

export const StyledDivider = styled(Divider)<{
  $progress: number;
}>`
  .ant-divider-inner-text {
    padding: 0 0 0 8px;
    font-size: 12px;
    color: ${colors.grey6};
  }

  &.ant-divider-horizontal {
    &.ant-divider-with-text {
      top: 0;
      margin: 0 0 24px 0;
    }
    &.ant-divider-with-text-right {
      &::before {
        height: 2px;
        margin-top: -2px;
        border: none;
        background: linear-gradient(
          to right,
          ${colors.fidelBlue600} ${({ $progress }) => $progress}%,
          ${colors.lightgrey2} ${({ $progress }) => $progress}%
        );
      }
      &::after {
        display: none;
      }
    }
  }
`;

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    overflow: hidden;
  }
  .ant-modal-body {
    padding: 0;
  }

  .ant-image {
    padding-bottom: 24px;
  }
`;

export const StyledFormWrapper = styled.div`
  margin-top: 24px;
  min-height: 125px;

  .ant-image {
    padding: 0;
  }
`;

export const StyledRecommendationsWrapper = styled.div<{
  $isMdBreakpoint: boolean;
}>`
  padding-top: ${({ $isMdBreakpoint }) => ($isMdBreakpoint ? 72 : 0)}px;
`;

export const StyledLampIcon = styled(LampIcon)`
  margin-bottom: 16px;
`;

export const StyledFooterWrapper = styled.div<{
  $isIntro: boolean;
  $isMdBreakpoint: boolean;
}>`
  display: flex;
  margin: 32px ${({ $isMdBreakpoint }) => ($isMdBreakpoint ? ' 0 0 0' : '')};

  .ant-btn {
    &:first-of-type {
      margin: ${({ $isIntro }) => ($isIntro ? '0 0 0 auto' : '0 auto 0 0')};
    }
    &:last-of-type {
      margin-left: 8px;
    }
  }
`;

export const StyledList = styled.ul<{
  $styleType: ListStyle;
}>`
  padding-left: ${({ $styleType }) => ($styleType ? '20' : '0')}px;
  list-style-type: ${({ $styleType }) => $styleType ?? 'none'};
`;

export const FieldsWrapper = styled.div`
  position: relative;

  .ant-form-item-explain {
    margin-bottom: 0;
  }
`;

const spinnerAlign = {
  center: `top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `,
  topRight: `top: -8px;
    right: 0;
  `,
};

export const StyledSpin = styled(Spin)<{
  $align?: keyof typeof spinnerAlign;
  $fontSize?: number;
}>`
  position: absolute;
  ${({ $align }) => spinnerAlign[$align || 'center']}
`;

export const StyledTextArea = styled(Input.TextArea)<{
  height: number;
}>`
  &.ant-input {
    min-height: ${({ height }) => height}px;
    max-height: ${({ height }) => height}px;
  }
`;

export const StyledInput = styled(Input)`
  margin-bottom: 8px;
`;
