import { useState } from 'react';

function recordAsArray(record: Record<string, true>) {
  return Object.keys(record);
}

export interface SetType {
  items: string[];
  clear: () => string[];
  remove: (item: string) => string[];
  add: (items: string[]) => string[];
  has: (item: string) => boolean;
  setRecord: React.Dispatch<React.SetStateAction<Record<string, true>>>;
}

export default function useSet(): SetType {
  const [record, setRecord] = useState<Record<string, true>>({});

  function has(item: string) {
    return !!record[item];
  }

  function wrapMutation<T extends (...args: any[]) => Record<string, true>>(
    mutator: T,
  ) {
    return (...args: Parameters<T>) => {
      const newRecord = mutator(...args);
      setRecord(newRecord);
      return recordAsArray(newRecord);
    };
  }

  const clear = wrapMutation(() => ({}));

  const add = wrapMutation((items: string[]) => {
    const newRecord: Record<string, true> = { ...record };

    items.forEach(item => {
      newRecord[item] = true;
    });

    return newRecord;
  });

  const remove = wrapMutation((item: string) => {
    const { [item]: deleted, ...newRecord } = record;
    return newRecord;
  });

  const items = Object.keys(record);

  return { add, remove, has, clear, items, setRecord };
}
