import React from 'react';
import { Col, Input } from 'antd';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import createUpdateOfferRules from '../../validation';
import { FieldWithCurrencyWrapper } from '../../../../../components/form/styled';
import Form from '../../../../../components/form';
import { currencyBySupportedCountries } from '../../../../../utils/countries';
import { PublisherOfferFormModel } from '../../../types';

export interface MaxRewardProps {
  disabled: boolean;
}

export default function MaxReward({ disabled }: MaxRewardProps) {
  const { t } = useTranslation('offers');

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<PublisherOfferFormModel>();

  const { country, discountType } = watch();

  const isDiscountExact = discountType === 'exact';

  return (
    <Col span={12} key="maxReward-field">
      <Form.Item>
        <FieldWithCurrencyWrapper>
          <Form.ItemController
            label={t(`fields.maxReward.label`)}
            errors={errors}
            data-testid="maxReward"
            controller={{
              name: 'maxReward',
              control,
              render: ({ field }) => (
                <Input
                  {...field}
                  type="number"
                  prefix={currencyBySupportedCountries[country] || '--'}
                  placeholder={
                    isDiscountExact ? '--' : t('createUpdate.optional')
                  }
                  min={+(createUpdateOfferRules.maxReward.min ?? 0)}
                  disabled={disabled || isDiscountExact}
                />
              ),
              rules: createUpdateOfferRules.maxReward,
            }}
          />
        </FieldWithCurrencyWrapper>
      </Form.Item>
    </Col>
  );
}
