import styled from 'styled-components';
import { colors } from '../../../theme';

export const CurrentUser = styled.span`
  color: ${colors.grey5};
`;

export const Invited = styled.span`
  font-style: italic;
`;

export const InviteUsers = styled.div`
  padding: 10px 20px;
  text-align: center;
`;

export const Action = styled.span`
  color: ${colors.fidelBlue600};
  cursor: pointer;
`;
