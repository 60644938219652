import React, { ReactElement, useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { TFunction } from 'i18next';
import { useTranslation, Trans } from 'react-i18next';

import GoLive from '../../modules/go-live';
import { AccountStatusContainer, GoLiveButton } from './styled';
import Link from '../../components/link';
import {
  AccountStatusType,
  getAccountStatus,
} from '../../utils/account-status';
import memoNoProps from '../../utils/memo-no-props';
import useGoLiveStarted from './use-go-live-started';
import useIsSignedIn from '../../hooks/use-is-signed-in';
import useGoLiveFormState, {
  Step,
} from '../../modules/go-live/use-go-live-form-state';
import FinalGoLiveModal from '../../modules/go-live/FinalGoLiveModal';
import { Divider } from '../side-bar/styled';
import { useAppSelector } from '../../store/hooks';

function accountStatusToMessage(
  t: TFunction,
  accountStatus: AccountStatusType | undefined,
): string | ReactElement | null {
  const mapping = {
    [AccountStatusType.TEST]: (
      <Trans
        i18nKey="accountStatus.goLiveMissing"
        components={[<Link to="https://fidelapi.com/docs/" />]}
      />
    ),
    [AccountStatusType.REVIEW]: t('accountStatus.underReview'),
    [AccountStatusType.REJECTED]: (
      <Trans
        i18nKey="accountStatus.rejected"
        components={[<Link to="mailto:support@fidel.uk" />]}
      />
    ),
    [AccountStatusType.SUBSCRIBED]: null,
    [AccountStatusType.LIVE]: null,
    [AccountStatusType.UNDEFINED]: null,
  };

  return accountStatus !== undefined ? mapping[accountStatus] : null;
}

const AccountStatus = () => {
  const { t } = useTranslation();
  const { isModerator, isAccountAdmin } = useIsSignedIn();
  const [{ step: currentStep }, setGoLiveFormState] = useGoLiveFormState();
  const [goLiveVisible, setGoLiveVisible] = useState(false);
  const { accountDetails, isLive } = useAppSelector(
    state => ({
      accountDetails: state.account?.details,
      billingConfigurations: state.account.billingConfigurations.entity,
      isLive: state.live,
    }),
    shallowEqual,
  );
  const goLiveStarted = useGoLiveStarted();

  const accountStatus = getAccountStatus(accountDetails);

  const showGoLiveButton =
    accountStatus === AccountStatusType.TEST ||
    accountStatus === AccountStatusType.REVIEW;

  function accountStatusToGoLiveCta() {
    if (accountStatus === AccountStatusType.TEST && goLiveStarted) {
      return t(`account.goLive.cta.started`);
    }

    // TODO Fix 'any' below in task OFF-1406
    return accountStatus
      ? t(`account.goLive.cta.${accountStatus}` as any)
      : null;
  }

  useEffect(() => {
    if (accountStatus === AccountStatusType.LIVE && currentStep !== Step.DONE) {
      setGoLiveFormState({
        form: {},
        step: Step.LIVE,
      });
    }
  }, [accountStatus, setGoLiveFormState, currentStep]);

  function setGoLiveState() {
    setGoLiveVisible(true);

    if (accountStatus === AccountStatusType.REVIEW) {
      setGoLiveFormState({
        form: {},
        step: Step.REVIEW,
      });
    }
  }

  function getStatusMessage(): string | ReactElement | null {
    if (isModerator) {
      return isLive
        ? t('accountMode.moderatorLive')
        : t('accountMode.moderatorTest');
    }

    if (accountDetails?.liveActive) {
      return isLive ? t('accountMode.live') : t('accountMode.test');
    }

    if (accountStatus === AccountStatusType.TEST && goLiveStarted)
      return t('accountStatus.goLiveStarted');

    return accountStatusToMessage(t, accountStatus);
  }

  if (!isAccountAdmin || !accountDetails) return null;

  return (
    <>
      <AccountStatusContainer>{getStatusMessage()}</AccountStatusContainer>
      <>
        {showGoLiveButton && (
          <GoLiveButton onClick={setGoLiveState}>
            {accountStatusToGoLiveCta()}
          </GoLiveButton>
        )}
        <GoLive
          visible={goLiveVisible}
          onClose={() => {
            setGoLiveVisible(false);
          }}
        />
        {currentStep === Step.LIVE && (
          <FinalGoLiveModal
            onClose={() =>
              setGoLiveFormState({
                form: {},
                step: Step.DONE,
              })
            }
          />
        )}
        <Divider />
      </>
    </>
  );
};

export default memoNoProps(AccountStatus);
