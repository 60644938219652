import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import StyledPopover from '../../../../components/styled-popover';
import { updateSteps } from '../../../../store/brand-user/brand-user-actions';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

interface PopoverProps {
  isNew?: boolean;
  children?: ReactNode;
}

const PlaygroundPopover = ({ isNew, children }: PopoverProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'playground.popover' });
  const dispatch = useAppDispatch();
  const userDetails = useAppSelector(state => state.user.details);
  const { playgroundPopover } = userDetails?.stepsCompleted || {};
  const [popoverVisible, setPopoverVisible] = useState(false);

  useEffect(() => {
    setPopoverVisible(!playgroundPopover);
  }, [playgroundPopover]);

  function onClose() {
    if (userDetails) {
      dispatch(
        updateSteps(userDetails?.id, {
          playgroundPopover: true,
        }),
      );
    }
    setPopoverVisible(false);
  }

  return (
    <StyledPopover
      title={t('title')}
      overTitle={isNew ? t('new') : undefined}
      description={t('description')}
      visible={popoverVisible}
      placement="right"
      onClose={onClose}
    >
      {children}
    </StyledPopover>
  );
};

export default PlaygroundPopover;
