import { UniqueLocation } from '../../../../../../store/locations/locations-model';
import {
  createLocation,
  mapUniqueLocationsToProgram,
} from '../../../../../../store/locations/locations-actions';
import { merchantIdsToSearchBy } from '../../../../../locations/utils/transform-form-model';
import { filterLocations } from '../../../../../../store/filters/filters-actions';
import { CreateUpdateLocationFormModel } from '../../../../../locations/components/CreateUpdateLocationForm';
import { useAppDispatch } from '../../../../../../store/hooks';

export interface AddUniqueLocationsSubmit {
  offerBrandId: string;
  selectedUniqueBrandId: string;
  selectedUniqueLocations: UniqueLocation[];
}

export default function useFormSubmit() {
  const dispatch = useAppDispatch();

  const addUniqueLocations = (
    selectedProgramId: string,
    {
      offerBrandId,
      selectedUniqueBrandId,
      selectedUniqueLocations,
    }: AddUniqueLocationsSubmit,
  ) => {
    dispatch(
      mapUniqueLocationsToProgram({
        programId: selectedProgramId,
        brandId: offerBrandId,
        uniqueBrandId: selectedUniqueBrandId,
        uniqueLocationIds: selectedUniqueLocations.map(
          (item: UniqueLocation) => item.id,
        ),
      }),
    );
  };

  const addNewLocation = (
    selectedProgramId: string,
    {
      brandId,
      address,
      city,
      postcode,
      countryCode,
      stateCode,
      merchantIds,
    }: CreateUpdateLocationFormModel,
  ) => {
    dispatch(filterLocations({ brandId, countryCode }));

    dispatch(
      createLocation(selectedProgramId, {
        brandId,
        address,
        city,
        postcode,
        countryCode,
        stateCode,
        searchBy: merchantIdsToSearchBy(merchantIds),
      }),
    );
  };

  return {
    addLocations: addUniqueLocations,
    addNewLocations: addNewLocation,
  };
}
