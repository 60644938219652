import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'antd';

import Modal from '../../modal';
import { OnboardingStepTypes } from '../onboarding-config';
import { OnboardingTourContext } from '../onboarding-provider';

export const DoneStep = () => {
  const { t } = useTranslation('common', { keyPrefix: 'onboarding.done' });

  const { completeOnboardingStep, setStep } = useContext(OnboardingTourContext);

  function onClose() {
    completeOnboardingStep('dashboardTransactions');
    setStep(OnboardingStepTypes.EXIT_TOUR);
  }

  return (
    <Modal
      confetti
      title={t('title')}
      description={
        <div>
          <p>
            <Trans
              i18nKey="onboarding.done.description.paragraph1"
              components={[<NavLink to="/playground" onClick={onClose} />]}
            />
          </p>
          <p>
            <Trans
              i18nKey="onboarding.done.description.paragraph2"
              components={[
                <a // eslint-disable-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
                  href="https://fidelapi.com/docs/"
                  target="_blank"
                  rel="noopener noreferrer"
                />,
                <a // eslint-disable-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
                  href="https://reference.fidel.uk/reference"
                  target="_blank"
                  rel="noopener noreferrer"
                />,
              ]}
            />
          </p>
        </div>
      }
      buttons={[
        <Button
          type="primary"
          onClick={onClose}
          size="small"
          data-testid="modal-button-0"
          key="modal-button-0"
        >
          {t('buttons.goAndExplore')}
        </Button>,
      ]}
      visible
    />
  );
};
