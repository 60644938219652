import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Form } from 'antd';
import { LabeledValue } from 'antd/lib/select';
import { TFuncKey, useTranslation } from 'react-i18next';
import { createSelector } from '@reduxjs/toolkit';

import { State } from '../../store';
import { Location } from '../../store/locations/locations-model';
import { getAllOffers } from '../../store/offers';
import { getCards } from '../../store/cards/cards-actions';
import { Card } from '../../store/cards/cards-types';
import { getLocations } from '../../store/locations/locations-actions';
import { selectProgramsAsList } from '../../store/programs/programs-selectors';
import { getPrograms } from '../../store/programs/programs-actions';
import filterOption from '../../utils/filter-option';
import { Select } from '../../components/select';
import useOnLiveChange from '../../hooks/use-on-live-change';
import {
  CardDropdownProps,
  DropdownProps,
  CustomDropdownProps,
  ProgramDropdownProps,
} from './types';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

export function OfferDropdown(props: CustomDropdownProps) {
  const dropdownProps: DropdownProps = {
    ...props,
    name: 'offer',
    selector: (state: State) => state.offers.offers?.all as any[],
    placeholder: 'cards.label.selectOffer',
    action: getAllOffers(),
    testId: 'offers-dropdown',
  };

  return <Dropdown {...dropdownProps} />;
}

export function CardDropdown({
  programId = '',
  hideCardNumbers = false,
  onChange,
  formItemProps,
  ...props
}: CardDropdownProps) {
  const { t } = useTranslation();
  const [selectedCard, setSelectedCard] = useState<Card | null>(null);

  const { expDate } = selectedCard || {};

  const dropdownProps: DropdownProps = {
    ...props,
    name: 'card',
    selector: createSelector(
      (state: State) => state.cards.cards,
      cards =>
        cards.map(card =>
          hideCardNumbers
            ? { ...card, name: card.id }
            : { ...card, name: `**** ${card.lastNumbers}` },
        ),
    ),
    placeholder: 'cards.select',
    action: getCards({ programId }),
    refetchTrigger: programId,
    testId: 'card-dropdown',
    onChange: (card: Card) => {
      onChange(card);
      setSelectedCard(card);
    },
    formItemProps: {
      ...formItemProps,
      help:
        hideCardNumbers &&
        expDate &&
        t('playground.expDate', { date: moment(expDate).format('MM/YY') }),
    },
  };

  return <Dropdown {...dropdownProps} />;
}

export function LocationDropdown({
  programId = '',
  ...props
}: CustomDropdownProps) {
  function locationsToArray(items: any) {
    return (
      items?.[0]?.items?.map((location: Location) => ({
        ...location,
        name: location.address,
      })) || []
    );
  }

  const dropdownProps: DropdownProps = {
    ...props,
    name: 'location',
    selector: createSelector(
      (state: State) => state.locations.locations,
      locations => locationsToArray(Object.values(locations)),
    ),
    placeholder: 'transactions.label.selectLocation',
    action: getLocations(programId),
    refetchTrigger: programId,
    testId: 'location-dropdown',
  };

  return <Dropdown {...dropdownProps} />;
}

export function ProgramDropdown({
  programType,
  placeholder,
  selector = (state: State) => selectProgramsAsList(state)(programType),
  ...props
}: ProgramDropdownProps) {
  const dropdownProps: DropdownProps = {
    ...props,
    name: 'program',
    selector,
    placeholder: placeholder || 'transactions.label.selectProgram',
    action: getPrograms(),
    testId: 'programs-dropdown',
  };

  return <Dropdown {...dropdownProps} />;
}

const Dropdown = React.forwardRef<any, DropdownProps>(
  (
    {
      name,
      selector,
      placeholder,
      action,
      onChange,
      onBlur,
      onFilter,
      value,
      refetchTrigger,
      testId,
      disabled,
      multiple,
      formItemProps,
      defaultValue,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const selectedState = useAppSelector(selector);

    function initialFetch() {
      dispatch(action);

      if (refetchTrigger) onChange({});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(initialFetch, [refetchTrigger]);

    useOnLiveChange(() => {
      onChange({});
      initialFetch();
    });

    function handleChange(id: any) {
      if (multiple) {
        onChange(id);
        return;
      }

      const selectedItem: any = selectedState.find(
        (item: any) => item.id === (id as string),
      );
      onChange(selectedItem);
    }

    function filter(item: any) {
      if (onFilter) return onFilter(item);
      return true;
    }

    function showMaxTagPlaceholder(omittedValues: LabeledValue[]) {
      return <>+{omittedValues.length}</>;
    }

    return (
      <Form.Item {...formItemProps}>
        <Select
          data-name={name}
          allowClear
          showArrow
          style={{ minWidth: '200px' }}
          onChange={handleChange}
          onBlur={onBlur}
          placeholder={t(placeholder as TFuncKey)}
          data-testid={testId}
          value={value || undefined}
          disabled={disabled}
          mode={multiple ? 'multiple' : undefined}
          filterOption={multiple ? filterOption : undefined}
          ref={ref}
          defaultValue={defaultValue}
          defaultActiveFirstOption={!multiple}
          maxTagCount="responsive"
          maxTagPlaceholder={showMaxTagPlaceholder}
        >
          {selectedState?.filter(filter)?.map(({ id, name: itemName }) => (
            <Select.Option key={id} value={id}>
              {itemName}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  },
);
