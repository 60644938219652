import styled from 'styled-components';
import { colors, hiddenScrollbar } from '../../../../theme';

export const PayloadTitle = styled.h3`
  background: ${colors.black};
  color: white;
  margin: 0;
  padding: 20px 24px;

  position: sticky;
  top: 0;
  z-index: 2;
`;

export const PayloadWrapper = styled.div`
  height: 50%;
  overflow-y: scroll;
  ${hiddenScrollbar};

  display: flex;
  flex-direction: column;
`;
