import { AccountDetails } from '../store/account/account-model';

export enum AccountStatusType {
  TEST = 'test',
  REVIEW = 'review',
  REJECTED = 'rejected',
  LIVE = 'live',
  SUBSCRIBED = 'subscribed',
  UNDEFINED = 'undefined',
}

export const getAccountStatus = (
  accountDetails: AccountDetails | undefined,
): AccountStatusType => {
  if (accountDetails?.liveAgreement === false) {
    return AccountStatusType.TEST;
  }

  if (accountDetails?.subscriptionActive) {
    return AccountStatusType.SUBSCRIBED;
  }

  if (accountDetails?.liveActive) {
    return AccountStatusType.LIVE;
  }

  if (accountDetails?.liveRequest?.approved === undefined) {
    return AccountStatusType.REVIEW;
  }

  if (accountDetails?.liveRequest?.approved === false) {
    return AccountStatusType.REJECTED;
  }

  // eslint-disable-next-line no-console
  console.warn('Couldn’t find account status');
  return AccountStatusType.UNDEFINED;
};
