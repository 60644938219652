import { getAllItems, getCount } from '../utils/pagination';
import { api } from '../../config';
import http from '../../services/http-service';
import { UsersOptions } from './moderator-model';

class ModeratorApiService {
  public async getAccountsCount() {
    return getCount(last => {
      const params: any = { select: 'count' };
      if (last) params.start = JSON.stringify(last);
      return http.get('accounts', { params });
    }, api.MAX_ACCOUNTS_COUNT);
  }

  public getAccounts(last?: any) {
    return http.get('accounts', {
      params: {
        expand: 'userId',
        start: last && JSON.stringify(last),
      },
    });
  }

  public getAllAccounts() {
    return getAllItems(last => this.getAccounts(last));
  }

  public getUsers({ account, brand, last }: UsersOptions) {
    const params: any = {
      expand: 'userId,programId,accountId',
    };

    if (account) params.account = account;
    if (brand) params.brand = brand;
    if (last) params.start = JSON.stringify(last);

    return http.get(`brands-users`, { params });
  }

  async getAllUsers(options: UsersOptions = {}) {
    return getAllItems(last => this.getUsers({ ...options, last }));
  }

  public reviewAccount(
    accountId: string,
    liveRequestId: string | undefined,
    approved: boolean,
    message?: string,
  ) {
    return http.patch(`accounts/${accountId}/live-requests/${liveRequestId}`, {
      approved,
      message,
    });
  }
}

export default ModeratorApiService;
