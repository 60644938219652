import React from 'react';
import { Col } from 'antd';
import memoNoProps from '../../../../utils/memo-no-props';
import { Container, InfoSkeleton } from '../../styled/account-info';
import ProfilePhoto from './UpdateProfilePhoto';
import { useAppSelector } from '../../../../store/hooks';

const AccountInfo = () => {
  const userDetails = useAppSelector(({ user }) => user.details);

  const { nameFirst, nameLast } = userDetails || {};

  return (
    <>
      <Container justify="space-between" align="middle">
        <InfoSkeleton
          avatar={{ size: 64, shape: 'circle' }}
          paragraph={false}
          loading={!userDetails}
        />

        {userDetails && (
          <>
            <Col>
              <ProfilePhoto />
            </Col>

            <Col>
              <p style={{ marginBottom: 0 }}>
                {nameFirst} {nameLast}
              </p>
            </Col>
          </>
        )}
      </Container>
    </>
  );
};

export default memoNoProps(AccountInfo);
