import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Checkbox } from 'antd';
import { schemeOptions } from '../../../../../utils/schemes';
import { Schemes } from '../../styled/offer-form';
import { CreateUpdateOfferFormModel } from '../../../types';
import createUpdateOfferRules from '../../validation';

interface SchemesCheckboxGroupProps {
  disabled: boolean;
}

export default function SchemesCheckboxGroup({
  disabled,
}: SchemesCheckboxGroupProps) {
  const { t } = useTranslation('offers');

  const {
    control,
    formState: { errors },
  } = useFormContext<CreateUpdateOfferFormModel>();

  return (
    <Schemes
      errors={errors}
      label={t('fields.networks.label')}
      controller={{
        name: 'schemes',
        render: ({ field: { ref, ...rest } }) => (
          <Checkbox.Group
            {...rest}
            options={schemeOptions}
            disabled={disabled}
          />
        ),
        rules: createUpdateOfferRules.schemes,
        control,
      }}
    />
  );
}
