import styled from 'styled-components';
import { colors } from '../../../../../theme';

export const SelectedContainer = styled.div<{
  isBrandSelected: boolean;
}>`
  height: 300px;

  @media (max-width: 1200px) {
    position: fixed;
    z-index: 3;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: ${props => (props.isBrandSelected ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
  }
`;

export const SelectedBox = styled.div.attrs(() => ({
  'data-testid': 'selected-unique-brand-box',
}))`
  margin-top: 62px;
  height: calc(100% - 62px);
  width: 360px;
  background: ${colors.lightgrey7};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  padding: 24px;

  @media (max-width: 1200px) {
    margin-top: unset;
    height: 400px;
    background: ${colors.lightgrey};
    border-radius: 4px;
  }
`;

export const UnselectedDetails = styled.span`
  font-size: 12px;
  color: ${colors.grey4};
`;

export const SelectedDetailsWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const SelectedDetails = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin-left: 24px;
  width: 200px;
`;

export const SelectedName = styled.span`
  color: ${colors.black};
  font-weight: 500;
  font-size: 14px;
`;

export const SelectedURL = styled.div`
  margin-top: -10px;

  & > a {
    color: ${colors.grey4};
    font-weight: 400;
    font-size: 12px;
  }
`;

export const AddBrandDisabled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 32px;
  background: ${colors.lightgrey8};
  color: ${colors.grey6};
  font-size: 14px;

  & > .anticon {
    font-size: 12px;
  }
`;
