import styled from 'styled-components';

import { Subsection, SubsectionTitle } from '.';

export const CertificateSubsection = styled(Subsection)`
  margin-bottom: 50px;

  ${SubsectionTitle} {
    margin-bottom: 20px;
  }
`;
