import styled from 'styled-components';
import { colors } from '../../../../theme';

export const Title = styled.div`
  display: flex;
  justify-content: space-between;

  .ant-steps {
    max-width: 200px;
    .ant-steps-item {
      .ant-steps-item-container {
        display: flex;
        align-items: center;
      }

      .ant-steps-item-title {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.83;

        &:after {
          height: 2.4px;
        }
      }

      .ant-steps-item-icon {
        font-size: 12px;
        height: 20px;
        width: 20px;
        line-height: 1.7;

        .ant-steps-icon svg {
          color: ${colors.grey4};
        }
      }

      &.ant-steps-item-active .ant-steps-item-title {
        color: ${colors.fidelBlue600};
      }

      &.ant-steps-item-active .ant-steps-item-title::after,
      &.ant-steps-item-finish .ant-steps-item-title::after {
        background-color: ${colors.grey2};
      }

      &:not(.ant-steps-item-active)
        > .ant-steps-item-container[role='button']:hover
        .ant-steps-item-icon,
      &:not(.ant-steps-item-active) .ant-steps-item-icon {
        color: ${colors.grey4};
        background-color: ${colors.grey2};
        border-color: ${colors.grey2};
      }

      &.ant-steps-item-finish {
        color: ${colors.grey4};

        .ant-steps-item-title,
        .ant-steps-item-title:hover {
          color: ${colors.grey4};
        }
      }
    }
  }

  @media (max-width: 480px) {
    flex-flow: column wrap;

    .ant-steps {
      margin-top: 10px;
      max-width: 100%;

      .ant-steps-item-content {
        min-height: inherit;
      }
    }
  }
`;

export const JsonContainer = styled.div`
  border: 1px solid ${colors.lightgrey};
  border-radius: 3px;
  background: ${colors.white};
  padding: 4px 8px;
`;

export const TooltipWrapper = styled.div`
  display: inline-block;

  & > span {
    font-size: 14px;
    color: ${colors.grey4};
  }
`;
