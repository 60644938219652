import moment, { Moment } from 'moment';
import { WeekDaysIndexes, WeekDay, weekDays } from '../types';
import { MINUS } from './special-character';
import { arrayFromNumber } from './transform';

export const dateFormat = 'DD/MM/YYYY HH:mm:ss';

const weekDaysIndexes: Record<WeekDaysIndexes, WeekDay> = {
  0: 'sunday',
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
};

const getWeekDayIndex = (weekDay: WeekDay) => weekDays.indexOf(weekDay);

export const getCurrentDate = (format = dateFormat) => moment().format(format);

export const toWeekDays = (daysOfWeek: WeekDaysIndexes[] = []) =>
  daysOfWeek.map(day => weekDaysIndexes[day]);

export const toDate = (date: string) => moment.utc(date).format(dateFormat);

export const offsetTimezone = (date: string, format: string) =>
  moment(date).subtract(moment(date).utcOffset(), 'minutes').format(format);

export const toShortDate = (date: string) => moment(date).format('DD-MM-YYYY');

export const toFileDate = (endDate: Moment, startDate: Moment) =>
  `${toShortDate(startDate.toISOString())} ${MINUS} ${toShortDate(
    endDate.toISOString(),
  )}`;

export const toReportDate = (endDate: Moment, startDate: Moment) =>
  `${toDate(startDate.toISOString())} ${MINUS} ${toDate(
    endDate.toISOString(),
  )}`;

export function addBusinessDays(daysCount: number, currentDay: Moment) {
  const friday = getWeekDayIndex('friday');
  const weekend = (['saturday', 'sunday'] as typeof weekDays).map(
    getWeekDayIndex,
  );

  const weekDaysCount = arrayFromNumber(daysCount).reduce(
    (dayCount, businessDayIdx) => {
      const upcomingDay = currentDay
        .clone()
        .add(dayCount + 1, 'day')
        .day();

      const startsOnWeekend = !businessDayIdx && weekend.includes(upcomingDay);
      const isFriday = upcomingDay === friday;

      const daysToAdd = startsOnWeekend
        ? weekend.reverse().indexOf(upcomingDay) + 2
        : isFriday
        ? 3
        : 1;

      return dayCount + daysToAdd;
    },
    0,
  );

  return currentDay.add(weekDaysCount, 'days');
}
