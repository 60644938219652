import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectedRowsType } from '../../../../../hooks/use-selected-rows';
import { Location } from '../../../../../store/locations/locations-model';
import LinkedLocationsList from './LinkedLocationsList';
import LocationsInfoMessage from './LocationsInfoMessage';
import {
  Label,
  ManageButtonStyled,
  ManageLocationsBoxContainer,
} from '../../styled/add-locations-form';
import { InlineButton } from '../../../../../components/buttons';

interface ManageLocationsBoxProps {
  offerLocations?: any[];
  programLocations: Location[];
  selectedLocations: SelectedRowsType<Location>;
}

export default function ManageLocationsBox({
  offerLocations,
  programLocations,
  selectedLocations,
}: ManageLocationsBoxProps) {
  const { t } = useTranslation('offers');

  const [manageLocationsManually, setManageLocationsManually] = useState(false);

  const numLinkedOfferLocations = offerLocations ? offerLocations.length : 0;

  const showManageLocationsBtn =
    !manageLocationsManually && !!programLocations.length;

  return (
    <>
      <Label>{t('fields.locations.label')}</Label>

      <ManageLocationsBoxContainer>
        <LocationsInfoMessage
          numProgramLocations={programLocations.length}
          numLinkedOfferLocations={numLinkedOfferLocations}
          selectedLocations={selectedLocations}
        />

        {showManageLocationsBtn && (
          <ManageButtonStyled>
            <InlineButton
              type="link"
              data-testid="select-locations-manually"
              onClick={() => setManageLocationsManually(true)}
            >
              {t('createUpdate.locations.infoBox.manageLocations')}
            </InlineButton>
          </ManageButtonStyled>
        )}

        {manageLocationsManually && !!programLocations.length && (
          <LinkedLocationsList
            selectedLocations={selectedLocations}
            programLocations={programLocations}
          />
        )}
      </ManageLocationsBoxContainer>
    </>
  );
}
