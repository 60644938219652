import { cognito } from '../config';
import http from './http-service';

export default class CognitoApiService {
  public async revokeToken(token: string) {
    await http.post(
      `${cognito.DOMAIN_URL}/oauth2/revoke`,
      new URLSearchParams({
        token,
        client_id: cognito.CLIENT_ID,
      }),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );
  }
}
