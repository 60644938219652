import React from 'react';
import { InputNumber } from 'antd';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Form from '../../../../../components/form';
import { ContentProviderOfferFormModel } from '../../../types';
import createUpdateOfferRules from '../../validation';

export interface MaxPaymentTimelineInputProps {
  isUpdating: boolean;
}

function MaxPaymentTimelineInput({ isUpdating }: MaxPaymentTimelineInputProps) {
  const { t } = useTranslation('offers', {
    keyPrefix: 'fields.maxPaymentTimeline',
  });
  const {
    formState: { errors },
    control,
  } = useFormContext<ContentProviderOfferFormModel>();

  return (
    <Form.ItemController
      label={t('label')}
      errors={errors}
      tooltip={t('tooltip')}
      controller={{
        name: 'maxPaymentTimeline',
        control,
        rules: {
          required: true,
        },
        render: ({ field }) => (
          <InputNumber
            {...field}
            data-testid="max-payment-timeline"
            placeholder={t('placeholder')}
            min={createUpdateOfferRules.maxPaymentTimeline.min}
            disabled={isUpdating}
          />
        ),
      }}
    />
  );
}

export default MaxPaymentTimelineInput;
