import React, { useContext, useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from 'antd';

import { getVirtualCards } from '../../../../store/account/account-actions';
import { updateSteps } from '../../../../store/brand-user/brand-user-actions';
import Modal from '../../../../components/modal';
import { GreyButton } from '../../../../components/buttons';
import {
  ModalButtonsContainer,
  ModalDismissButton,
  ModalCaption,
  ModalIllustration,
  NewLetter,
} from './styled';
import CreateVirtualCard from './CreateVirtualCard';
import { roleCheck } from '../../../../components/role-check';
import { OnboardingTourContext } from '../../../../components/onboarding-tour/onboarding-provider';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

const VirtualCardsModal = ({
  showModal,
  setShowModal,
}: {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
}) => {
  const dispatch = useAppDispatch();
  const { enabled: onboardingEnabled } = useContext(OnboardingTourContext);
  const [showCreateVirtualCard, setShowCreateVirtualCard] = useState(false);
  const { liveActive, userDetails } = useAppSelector(
    state => ({
      liveActive: state.account.details?.liveActive,
      userDetails: state.user.details,
    }),
    shallowEqual,
  );
  const showVirtualCardsModal =
    !onboardingEnabled &&
    (!userDetails?.stepsCompleted?.virtualCardsModal || showModal);

  return (
    <>
      {liveActive && showVirtualCardsModal && (
        <InnerModal
          visible={showVirtualCardsModal}
          onActivate={() => setShowCreateVirtualCard(true)}
          hideModal={() => {
            dispatch(
              updateSteps(userDetails?.id as string, {
                virtualCardsModal: true,
              }),
            );
            setShowModal(false);
          }}
        />
      )}
      <CreateVirtualCard
        visible={showCreateVirtualCard}
        onClose={() => setShowCreateVirtualCard(false)}
      />
    </>
  );
};

const InnerModal = ({
  visible,
  hideModal,
  onActivate,
}: {
  visible: boolean;
  hideModal: () => void;
  onActivate: () => void;
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'account.virtualCard' });
  const dispatch = useAppDispatch();
  const [innerVisible, setInnerVisible] = useState(visible);
  const { loading, virtualCards } = useAppSelector(
    state => ({
      virtualCards: state.account.virtualCards.entities,
      loading: state.account.virtualCards.loading,
    }),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(getVirtualCards());
  }, [dispatch]);

  if (loading) return null;

  return (
    <Modal
      illustration={<ModalIllustration />}
      direction="vertical"
      title={
        <>
          <div>
            {t('noCard.new')
              .split('')
              .map((letter, idx) => (
                <NewLetter key={idx}>{letter}</NewLetter>
              ))}
          </div>
          <h1 style={{ fontSize: '18px', marginBottom: '-20px' }}>
            {t('modal.title')}
          </h1>
        </>
      }
      description={
        <Typography.Paragraph type="secondary" style={{ fontSize: '16px' }}>
          {t('modal.description')}
        </Typography.Paragraph>
      }
      buttons={[
        <ModalButtonsContainer key="buttons">
          {virtualCards.length ? (
            <GreyButton size="small" onClick={() => hideModal()}>
              {t('modal.buttons.gotIt')}
            </GreyButton>
          ) : (
            <>
              <Button
                type="primary"
                size="small"
                onClick={e => {
                  e.stopPropagation();
                  setInnerVisible(false);
                  hideModal();
                  onActivate();
                }}
              >
                {t('modal.buttons.activate')}
              </Button>
              <ModalDismissButton
                type="link"
                onClick={() => {
                  setInnerVisible(false);
                  hideModal();
                }}
              >
                {t('modal.buttons.dismiss')}
              </ModalDismissButton>
            </>
          )}
          <ModalCaption>{t('modal.caption')}</ModalCaption>
        </ModalButtonsContainer>,
      ]}
      visible={innerVisible}
      maxWidth={420}
    />
  );
};

export default roleCheck(
  { disabledForModerator: true, requiresAccountAdmin: true },
  VirtualCardsModal,
);
