import styled from 'styled-components';
import { Tag } from 'antd';

import { colors, fonts } from '../../../theme';

export const HiddenInput = styled.input`
  position: absolute;
  left: -10000px;
`;

export const RelativeContainer = styled.div`
  position: relative;
  width: 500px;
  max-width: 100%;
  padding-right: 10px;
`;

export const StyledTag = styled(Tag).attrs(() => ({
  color: 'blue',
}))<{ $width?: string }>`
  cursor: pointer;
  font-weight: normal;
  font-family: ${fonts.monospace};
  width: ${props => props.$width ?? 'min-content'};
  max-width: 100%;
  span {
    color: ${colors.fidelBlue600};
    vertical-align: bottom;
  }
  &:hover {
    background: ${colors.fidelBlue600};
    span {
      color: white;
    }
  }
`;
