import React from 'react';

const Link = ({ to, children, ...rest }: any) =>
  to.match(/^http(s)?:\/\//) || rest.download ? (
    <a href={to} target="_blank" rel="noopener noreferrer" {...rest}>
      {children}
    </a>
  ) : (
    <a href={to} {...rest}>
      {children}
    </a>
  );

export default Link;
