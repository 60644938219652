import React, { ReactElement } from 'react';
import { Tooltip } from 'antd';
import { TooltipPropsWithTitle as AntdTooltipProps } from 'antd/lib/tooltip';

interface TooltipProps extends AntdTooltipProps {
  title: string;
}

interface OfferIconProps {
  icon: ReactElement;
  tooltip?: TooltipProps;
}

export default function OfferIcon({ icon, tooltip }: OfferIconProps) {
  return tooltip ? (
    <Tooltip title={tooltip.title} placement={tooltip.placement}>
      {icon}
    </Tooltip>
  ) : (
    <>{icon}</>
  );
}
