import { createSlice } from '@reduxjs/toolkit';
import { actionToError, Error } from '../utils/transform';
import { VirtualCardTransaction } from './virtual-card-model';

export interface VirtualCardState {
  transactions?: VirtualCardTransaction[];
  error?: Error;
  loadingTransactions: boolean;
  submittingTransaction: boolean;
}

const initialState: VirtualCardState = {
  loadingTransactions: false,
  submittingTransaction: false,
};

const virtualCardSlice = createSlice({
  name: 'virtualCard',
  initialState,
  reducers: {
    clear: () => initialState,
    getVirtualCardTransactions(state) {
      state.error = undefined;
      state.loadingTransactions = true;
    },
    getVirtualCardTransactionsSuccess(state, action) {
      state.loadingTransactions = false;
      state.transactions = action.payload;
    },
    getVirtualCardTransactionsError(state, action) {
      state.error = actionToError(action);
      state.loadingTransactions = false;
    },

    submitVirtualCardTransaction(state) {
      state.error = undefined;
      state.submittingTransaction = true;
    },
    submitVirtualCardTransactionSuccess(state) {
      state.submittingTransaction = false;
    },
    submitVirtualCardTransactionError(state, action) {
      state.error = actionToError(action);
      state.submittingTransaction = false;
    },
  },
});

export const { actions } = virtualCardSlice;
export default virtualCardSlice.reducer;
