import { Button, Drawer, Row } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SecondaryLinkButton } from '../../../../../components/buttons';
import ImageUpload, {
  CroppedImages,
  ImageVisualizer,
} from '../../../../../components/upload/CroppedImageUpload';

export interface EditBrandLogoProps {
  onSubmit: ({ squared, rounded }: CroppedImages) => void;
  imageSrc?: string;
}

type EditAction = 'edit';

const EditBrandLogo = ({ onSubmit, imageSrc }: EditBrandLogoProps) => {
  const { t } = useTranslation(['common', 'brands']);
  const [images, setImages] = useState<CroppedImages>();
  const [action, setAction] = useState<EditAction>();

  const isEditing = action === 'edit';

  function onClose() {
    setAction(undefined);
  }

  async function onConfirm() {
    images && (await Promise.all([onSubmit(images), onClose()]));
  }

  function onImagesCrop({ squared, rounded }: CroppedImages) {
    setImages({ squared, rounded });
  }

  return (
    <>
      <ImageVisualizer
        imagePath={imageSrc}
        onChangeImage={() => setAction('edit')}
        generateUniqueId={!images}
      />

      <Drawer
        title={t('brands:createUpdate.title.editLogo')}
        visible={isEditing}
        closable={false}
        onClose={onClose}
        width={440}
        footer={
          <Row>
            <SecondaryLinkButton onClick={onClose} size="small">
              {t('common:confirm.cancel')}
            </SecondaryLinkButton>
            <Button
              type="primary"
              onClick={onConfirm}
              size="small"
              disabled={!images}
            >
              {t('brands:createUpdate.confirm.label')}
            </Button>
          </Row>
        }
      >
        <ImageUpload height={256} onImagesCrop={onImagesCrop} />
      </Drawer>
    </>
  );
};

export default EditBrandLogo;
