import styled from 'styled-components';
import { Menu } from 'antd';

import { GreyDropdownButton } from '../../../components/buttons';
import { hiddenScrollbar } from '../../../theme';

export const OffersMenu = styled(Menu)`
  max-height: 300px;
  overflow: scroll;
  padding-top: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);

  .ant-dropdown-menu-item {
    font-size: 14px;
    text-align: right;
  }

  .ant-empty {
    margin: 0;
    padding: 10px 20px;
  }

  ${hiddenScrollbar};
`;

export const LinkToOfferButton = styled(GreyDropdownButton)`
  width: 130px;
`;
