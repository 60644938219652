import React, { Dispatch, SetStateAction, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Row, Col, Typography } from 'antd';

import DrawerForm from '../../../../../components/drawer-form';
import { Brand, UniqueBrand } from '../../../../../store/brands/brands-model';
import { UniqueLocation } from '../../../../../store/locations/locations-model';
import CreateUpdateLocationForm, {
  CreateUpdateLocationFormModel,
} from '../../../../locations/components/CreateUpdateLocationForm';
import AddUniqueLocationStep from '../../../../brands/components/create-update-brand/components/steps/add-unique-locations';
import { Program } from '../../../../../store/programs/programs-reducer';
import { FormSteps, LocationsFormSteps } from '../../../../brands/types';
import { TitleWrapper, BackCaron } from '../styled';
import { OfferActions } from '../../../types';
import { colors } from '../../../../../theme';
import useFormSubmit, { AddUniqueLocationsSubmit } from './hooks/useFormSubmit';
import useMapUniqueLocationsToProgram from './hooks/useMapUniqueLocationsToProgram';
import { Offer } from '../../../../../store/offers/offers-model';

type SubmitPayload = CreateUpdateLocationFormModel & AddUniqueLocationsSubmit;

export interface AddCreateLocationsProps {
  offer: Partial<Offer>;
  visible: boolean;
  initialFormStep: LocationsFormSteps;
  selectedProgram: Program;
  selectedBrand: Brand;
  selectedUniqueBrand: UniqueBrand | null | undefined;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

export default function AddCreateLocations({
  offer,
  visible,
  initialFormStep,
  selectedProgram,
  selectedBrand,
  selectedUniqueBrand,
  setVisible,
}: AddCreateLocationsProps) {
  const { t } = useTranslation();
  const { t: tOffers } = useTranslation('offers', {
    keyPrefix: 'createUpdate',
  });

  const form = useForm<CreateUpdateLocationFormModel>({
    mode: 'onBlur',
    defaultValues: {
      brandId: selectedBrand.id,
      address: '',
      city: '',
      postcode: '',
      countryCode: offer.countryCode,
      stateCode: '',
    },
  });
  const formSubmit = useFormSubmit();

  const [step, setStep] = useState<LocationsFormSteps>(initialFormStep);

  const [selectedUniqueLocations, setSelectedUniqueLocations] = useState<
    UniqueLocation[]
  >([]);

  const { isLoading, hasFinished } = useMapUniqueLocationsToProgram(
    offer,
    selectedProgram,
  );

  const isAddNewLocationsStep = step === FormSteps.ADD_NEW_LOCATIONS;

  function onClose() {
    setVisible(false);
  }
  function onBack() {
    if (isAddNewLocationsStep) setStep(FormSteps.ADD_LOCATIONS);
    else onClose();
  }
  function onSubmit() {
    const payloads = {
      addLocations: {
        offerBrandId: selectedBrand.id,
        selectedUniqueBrandId: selectedUniqueBrand?.id ?? '',
        selectedUniqueLocations,
      },
      addNewLocations: form.getValues(),
    };
    formSubmit[step](selectedProgram.id, payloads[step] as SubmitPayload);
  }

  const Title = () => {
    const offerAction = OfferActions[offer.id ? 'EDIT' : 'CREATE'];

    return (
      <TitleWrapper>
        <BackCaron />
        {tOffers(`title.${offerAction}`)}
      </TitleWrapper>
    );
  };

  const labels = {
    addLocations: {
      title: t('addNew.location'),
      submit: t('confirm.done'),
    },
    addNewLocations: {
      title: tOffers('locations.addLocations.addNewLocations.title'),
      submit: tOffers('locations.addLocations.addNewLocations.submit'),
    },
  };

  const submitDisabled =
    !isAddNewLocationsStep && !selectedUniqueLocations.length;

  return (
    <DrawerForm
      title={<Title />}
      confirmText={labels[step].submit}
      onBack={onBack}
      onClose={onClose}
      onSubmit={onSubmit}
      visible={visible}
      processing={isLoading}
      hasFinished={hasFinished}
      submitDisabled={submitDisabled}
    >
      <Row>
        <Col span={24}>
          <Row>
            <Col style={{ marginBottom: '40px' }}>
              <Typography.Text style={{ color: colors.grey4 }}>
                {labels[step].title}
              </Typography.Text>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <FormProvider {...form}>
                {isAddNewLocationsStep ? (
                  <CreateUpdateLocationForm
                    selectedProgram={selectedProgram}
                    selectedBrand={selectedUniqueBrand || selectedBrand}
                    disableFields={['brandId', 'countryCode']}
                  />
                ) : (
                  <AddUniqueLocationStep
                    program={selectedProgram}
                    brand={selectedBrand}
                    uniqueBrand={selectedUniqueBrand}
                    setStep={setStep}
                    selectedUniqueLocations={selectedUniqueLocations}
                    setSelectedUniqueLocations={setSelectedUniqueLocations}
                    defaultSearchFilter="countryCode"
                    defaultSearchValue={offer.countryCode}
                    hideFilters
                    forceLoading={isLoading}
                    onClose={onClose}
                  />
                )}
              </FormProvider>
            </Col>
          </Row>
        </Col>
      </Row>
    </DrawerForm>
  );
}
