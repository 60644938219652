import React, { useState } from 'react';
import { Typography } from 'antd';
import { Namespace, Trans } from 'react-i18next';

import { InlineButton } from '../buttons';

interface ExpandingCaptionProps {
  i18nKey:
    | 'linkVirtualCardTransaction.description'
    | 'account.virtualCard.caption';
  ns?: Namespace;
}

const ExpandingCaption = ({
  i18nKey,
  ns = 'common',
}: ExpandingCaptionProps) => {
  const [captionExpanded, setCaptionExpanded] = useState(false);

  return (
    <Typography.Text type="secondary">
      <Trans
        ns={ns}
        i18nKey={i18nKey}
        components={[
          <p style={{ marginBottom: 0 }} />,
          <InlineButton
            type="link"
            data-testid="expand-caption-btn"
            style={{ display: captionExpanded ? 'none' : 'inline-block' }}
            onClick={() => setCaptionExpanded(true)}
          />,
          <p
            data-testid="expanded-caption"
            style={{
              marginTop: '1em',
              display: captionExpanded ? 'block' : 'none',
            }}
          />,
        ]}
      />
    </Typography.Text>
  );
};

export default ExpandingCaption;
