import styled from 'styled-components';
import { Button } from 'antd';
import { ReactComponent as TickIcon } from '../icons/tick.svg';
import { ReactComponent as ThunderIcon } from '../icons/rt.svg';

import { colors } from '../../../theme';

export const Active = styled(TickIcon)`
  position: relative;
  right: 4px;
`;

export const RealTimeActive = styled(ThunderIcon)`
  position: relative;
  top: 2px;
  right: 4px;
`;

export const TagButton = styled(Button)`
  max-width: 100%;
  background: transparent !important;
  border-radius: 20px;
  height: min-content;
  padding: 0;
  border: none;
  :focus {
    /* The color is colors.blue */
    box-shadow: 0 0 0pt 3px rgba(16, 94, 251, 0.2);
  }
`;

export const New = styled.span`
  color: ${colors.darkYellow};
  background: ${colors.yellow2};
  display: inline-block;
  margin-left: 4px;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 10px;
  font-weight: 500;
  line-height: 1;
`;
