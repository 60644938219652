import { ReactElement } from 'react';
import { TFuncKey } from 'react-i18next';

import { DefaultStatusCode } from '../../store/status/status-reducer';
import * as errorTemplates from './errors';
import * as infoTemplates from './infos';
import * as successTemplates from './successes';

type Level = 'info' | 'success' | 'error';

export type NotificationPayload = {
  level: Level;
  title: TFuncKey<'notifications'>;
  duration?: number;
  description?: string | ReactElement;
};

export type DefaultNotificationPayload = Omit<NotificationPayload, 'title'> & {
  // Note: 'string' type to consume any messages from API
  title: string | TFuncKey<'notifications'>;
};

const { defaultError, ...customErrorTemplates } = errorTemplates;

const defaultNotifications = (
  code: string | DefaultStatusCode,
  message?: string,
): DefaultNotificationPayload => {
  if (code === DefaultStatusCode.success) {
    return {
      level: 'success',
      title: message || 'success.default',
    };
  }

  if (code === DefaultStatusCode.info) {
    return {
      level: 'info',
      title: message || 'info.default',
    };
  }

  if (code === DefaultStatusCode.error) {
    return {
      level: 'error',
      title: message || 'error.server',
    };
  }

  return defaultError(message);
};

export const notificationTemplates = {
  ...customErrorTemplates,
  ...infoTemplates,
  ...successTemplates,
};

export type NotificationCode = keyof typeof notificationTemplates;

const brandUserError = customErrorTemplates.brandUserLoginError;

export { defaultNotifications, brandUserError };
