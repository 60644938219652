import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';

import { Location } from '../../../store/locations/locations-model';
import { updateLocation } from '../../../store/locations/locations-actions';
import DrawerForm from '../../../components/drawer-form';
import drawerTransition, {
  DrawerVisibleProps,
} from '../../../components/drawer-transition';
import { toCountryName } from '../../../utils/transform';
import { Description, Brand } from '../styled/submit-merchant-ids';
import MerchantIdsSubForm from './MerchantIdsSubForm';
import {
  merchantIdsToSearchBy,
  searchByToMerchantIds,
} from '../utils/transform-form-model';
import { MerchantIdsSubForModel } from './CreateUpdateLocationForm';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

interface SubmitMerchantIdsProps extends DrawerVisibleProps {
  onClose: () => unknown;
  programId: string;
  location?: Location;
}

interface SubmitMerchantIdsFormModel {
  merchantIds: MerchantIdsSubForModel[];
}

const SubmitMerchantIds = ({
  programId,
  location,
  onClose,
  visible,
  afterVisibleChange,
}: SubmitMerchantIdsProps) => {
  const { t } = useTranslation('locations');
  const dispatch = useAppDispatch();
  const { processing, accountDetails } = useAppSelector(state => ({
    processing: state.locations.updating,
    accountDetails: state.account?.details,
  }));
  const form = useForm<SubmitMerchantIdsFormModel>({
    mode: 'onBlur',
    defaultValues: searchByToMerchantIds(location, accountDetails),
  });
  const { handleSubmit } = form;

  function onSubmit({ merchantIds }: SubmitMerchantIdsFormModel) {
    const mappedMerchantIds = merchantIdsToSearchBy(merchantIds);

    const payload: any = {
      address: location?.address,
      city: location?.city,
      countryCode: location?.countryCode,
      postcode: location?.postcode,
      searchBy: {
        ...mappedMerchantIds,
      },
    };

    if (location?.stateCode) {
      payload.stateCode = location?.stateCode;
    }

    if (location?.id) {
      dispatch(updateLocation(programId, location.id, payload));
    }
  }

  return (
    <DrawerForm
      title={t('submitMerchantIds.title')}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      processing={processing}
      visible={visible}
      afterVisibleChange={afterVisibleChange}
    >
      <FormProvider {...form}>
        <Description>{t('submitMerchantIds.description')}</Description>
        <MerchantIdsSubForm />
        <Divider />
        <Brand>{location?.brandId.name}</Brand>
        <p>
          {location?.address}
          <br />
          {location?.city}
          {', '}
          {location?.postcode}
          <br />
          {location?.countryCode && toCountryName(location.countryCode)}
        </p>
        {}
      </FormProvider>
    </DrawerForm>
  );
};

export default drawerTransition(SubmitMerchantIds);
