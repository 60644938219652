import React, { useCallback, useEffect, useState } from 'react';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { flatten } from 'lodash';

import FilterDropdown, {
  FilterDropdownProps,
  FilterName,
  filterNames,
} from './components/FilterDropdown';
import MoreFilters from './components/MoreFilters';
import { SecondaryLinkButton } from '../buttons';
import useFiltersConfig from './hooks/use-filters-config';

export type Filter = Pick<
  FilterDropdownProps,
  'name' | 'options' | 'multiple' | 'search'
>;

export interface FiltersProps {
  defaultFilters: FilterName[];
  moreFilters?: FilterName[];
}

interface State {
  [key: string]: string[];
}

function defaultState(filters: Filter[]): State {
  return filters.reduce(
    (state, filter) => ({ ...state, [filter.name]: [] }),
    {},
  );
}

function clearFilterParams(prev: URLSearchParams) {
  const params = new URLSearchParams(prev);
  filterNames.forEach(filter => {
    params.delete(filter);
  });
  return params;
}

function paramsHaveFilters(params: URLSearchParams) {
  return filterNames.map(filter => params.has(filter)).includes(true);
}

function Filters(props: FiltersProps) {
  const { defaultFilters, moreFilters, loading } = useFiltersConfig(props);
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filtersState, setFiltersState] = useState<State>(
    defaultState([...defaultFilters, ...moreFilters]),
  );
  const [showClearAll, setShowClearAll] = useState(false);
  const [randomKey, setRandomKey] = useState(Math.random());

  const clear = useCallback(() => {
    setShowClearAll(false);
    setSearchParams(prev => clearFilterParams(prev));
    setRandomKey(Math.random());
    setFiltersState(defaultState([...defaultFilters, ...moreFilters]));
  }, [defaultFilters, moreFilters, setSearchParams]);

  useEffect(() => {
    const emptyParams = !paramsHaveFilters(searchParams);

    if (showClearAll && emptyParams) {
      clear();
      return;
    }

    if (emptyParams || loading) return;

    const filters = [...defaultFilters, ...moreFilters];
    const newState = defaultState(filters);

    filters.forEach(filter => {
      const { name } = filter;

      if (!searchParams.has(name)) return;

      newState[name] = searchParams.getAll(name);
    });

    setFiltersState(oldState => {
      if (flatten(Object.values(oldState)).length === 0)
        setRandomKey(Math.random());
      return newState;
    });
    setShowClearAll(true);
  }, [clear, defaultFilters, loading, moreFilters, searchParams, showClearAll]);

  function setSelected(name: string) {
    return (newSelected: string[]) => {
      setFiltersState({ ...filtersState, [name]: newSelected });

      setSearchParams(prev => {
        const params = new URLSearchParams(prev);
        params.delete(name);
        newSelected.forEach(value => params.append(name, value));
        return params.toString();
      });

      if (!showClearAll) setShowClearAll(true);
    };
  }

  function buildFilter({ name, ...rest }: Filter): FilterDropdownProps {
    return {
      name,
      ...rest,
      selected: filtersState[name],
      setSelected: setSelected(name),
    };
  }

  return (
    <Space wrap align="start">
      {defaultFilters.map(filter => (
        <FilterDropdown
          key={`${filter.name}-${randomKey}`}
          {...buildFilter(filter)}
        />
      ))}
      {moreFilters && (
        <MoreFilters
          key={randomKey}
          filters={moreFilters.map(filter => buildFilter(filter))}
        />
      )}
      {showClearAll && (
        <SecondaryLinkButton
          type="link"
          size="small"
          onClick={clear}
          style={{ margin: 0 }}
        >
          {t('filters.clearAll')}
        </SecondaryLinkButton>
      )}
    </Space>
  );
}

export default Filters;
