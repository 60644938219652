import React, { useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'antd';
import PlusOutlined from '@ant-design/icons/PlusOutlined';

import { selectProgramsAsList } from '../../../../store/programs/programs-selectors';
import CreateUpdateProgram from '../../../../modules/programs/components/CreateUpdateProgram';
import Avatar from '../../../../components/avatar';
import Ellipsis from '../../../../components/ellipsis';
import useSelectedProgram from '../../../../hooks/use-selected-program';
import {
  DropdownCaron,
  ProgramInfoLabel,
  MenuItem,
  MenuItemGroup,
  MenuItemTitle,
  ProgramInfo,
  ProgramMenu,
  ProgramList,
  ViewAll,
} from './styled';
import ProgramOptionInfo from '../../../program-option-info';
import { useAppSelector } from '../../../../store/hooks';

const ProgramDropdown = () => {
  const { t } = useTranslation(['common', 'programs']);
  const programs = useAppSelector(selectProgramsAsList)();
  const { selectedProgram, setSelectedProgramId } = useSelectedProgram();
  const [showCreateProgram, setShowCreateProgram] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const closeCreateUpdate = useCallback(() => setShowCreateProgram(false), []);

  const menu = (
    <ProgramList>
      <ViewAll>
        <NavLink to="/programs" onClick={() => setShowDropdown(false)}>
          {t('programs:viewAll')}
        </NavLink>
      </ViewAll>
      <MenuItemGroup title={t('common:nav.programs')}>
        {programs.map(({ name, id, icon, iconBackground, type }) => (
          <MenuItem
            key={id}
            onClick={() => {
              setSelectedProgramId(id);
              setShowDropdown(false);
            }}
          >
            <Avatar
              color={{ background: iconBackground }}
              emoji={icon}
              style={{
                marginRight: '10px',
              }}
            >
              {name}
            </Avatar>
            <ProgramInfo>
              <MenuItemTitle>
                <Ellipsis>{name}</Ellipsis>
              </MenuItemTitle>
              <ProgramInfoLabel>
                <ProgramOptionInfo id={id} type={type} />
              </ProgramInfoLabel>
            </ProgramInfo>
          </MenuItem>
        ))}
      </MenuItemGroup>
      <MenuItem onClick={() => setShowCreateProgram(true)}>
        <Avatar
          color={{ color: 'grey5', background: 'lightgrey2' }}
          icon={<PlusOutlined style={{ margin: 0 }} />}
        />
        {t('common:addNew.program')}
      </MenuItem>
    </ProgramList>
  );

  return (
    <>
      <Dropdown
        overlay={menu}
        trigger={['click']}
        visible={showDropdown}
        onVisibleChange={setShowDropdown}
      >
        <ProgramMenu data-testid="program-dd">
          {selectedProgram && (
            <>
              <Avatar
                size={36}
                color={{ background: selectedProgram.iconBackground }}
                emoji={selectedProgram.icon}
                style={{
                  marginRight: '10px',
                }}
              >
                {selectedProgram.name}
              </Avatar>
              <ProgramInfo>
                <MenuItemTitle>
                  <Ellipsis gutter={16}>{selectedProgram.name}</Ellipsis>
                </MenuItemTitle>
                <ProgramInfoLabel>
                  <ProgramOptionInfo
                    id={selectedProgram.id}
                    type={selectedProgram.type}
                  />
                </ProgramInfoLabel>
              </ProgramInfo>
              <DropdownCaron />
            </>
          )}
        </ProgramMenu>
      </Dropdown>
      <CreateUpdateProgram
        visible={showCreateProgram}
        onClose={closeCreateUpdate}
      />
    </>
  );
};

export default ProgramDropdown;
