import styled from 'styled-components';
import { colors } from '../../../theme';

export const CenteredContainer = styled.div`
  text-align: center;
  max-width: 410px;
  margin: 0 auto;
  width: 100%;
  padding: 0 10px;

  .ant-form-item-explain {
    text-align: left;
  }
`;

export const TopBar = styled.div`
  height: 20px;
  width: 100%;
  background-color: ${colors.lightgrey2};
  margin-bottom: 40px;
`;

export const SignUpBox = styled.div`
  margin-top: 50px;
  border-top: 1px solid ${colors.lightgrey2};
  padding-top: 20px;
`;

export const Title = styled.h2`
  color: ${colors.black};
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 5px;
`;

export const Subtitle = styled.h3`
  color: ${colors.grey5};
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 30px;
`;
