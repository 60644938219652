import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import { UploadFile, UploadChangeParam } from 'antd/lib/upload/interface';
import Cropper from 'react-easy-crop';
import { Point, Area } from 'react-easy-crop/types';
import Slider from '../slider';
import {
  getImageUrl,
  createImage,
  getCroppedImages,
} from './image-with-crop/image-crop-utils';
import ImageCropVisualizer from './image-with-crop/ImageCropVisualizer';
import {
  ImageCropperStyled,
  TrashIconStyled,
  ImageUploadStyled,
} from './styled';

export interface UploadedImage extends UploadFile {
  image?: HTMLImageElement;
}

export type CroppedImages = Record<'rounded' | 'squared', string | undefined>;

type Error = 'size' | 'width' | undefined;

export interface ImageUploadProps {
  height?: number;
  onImagesCrop?: (croppedImages: CroppedImages) => void;
  maxSize?: number;
  minWidth?: number;
  storedImage?: string;
}

const ImageUpload = ({
  height,
  onImagesCrop,
  maxSize = 1000000,
  minWidth = 360,
  storedImage,
}: ImageUploadProps) => {
  const [newUpload, setNewUpload] = useState(!storedImage);
  const [uploadedImage, setUploadedImage] = useState<UploadedImage>();
  const [cropPosition, setCropPosition] = useState<Point>({ x: 0, y: 0 });
  const [croppedArea, setCroppedArea] = useState<Area>();
  const [zoom, setZoom] = useState(1);
  const [croppedImages, setCroppedImages] = useState<CroppedImages>({
    rounded: undefined,
    squared: undefined,
  });
  const [error, setError] = useState<Error>();

  const { current: onImagesCropCached } = useRef(onImagesCrop);

  const { t } = useTranslation('common', {
    keyPrefix: 'components.imageWithCrop',
  });

  const areImagesCropped = Object.values(croppedImages).every(Boolean);

  const onCropComplete = useCallback(
    (_cropPos, cropArea) => {
      if (uploadedImage) setCroppedArea(cropArea);
    },
    [uploadedImage],
  );

  useEffect(() => {
    if (uploadedImage) setError(undefined);

    if (croppedArea && uploadedImage)
      setCroppedImages(getCroppedImages(uploadedImage, croppedArea));
  }, [croppedArea, uploadedImage]);

  useEffect(() => {
    if (areImagesCropped && onImagesCropCached)
      onImagesCropCached(croppedImages);
  }, [areImagesCropped, croppedImages, onImagesCropCached]);

  async function validateFile(uploadedFile: UploadedImage) {
    const url = await getImageUrl(uploadedFile);

    const image = await createImage(url);

    const validationError = getValidationError(uploadedFile.size, image.width);

    if (validationError) {
      setError(validationError);
    } else {
      setUploadedImage({ url, image, ...uploadedFile });
    }
  }

  async function onFileChange({ file: uploadedFile }: UploadChangeParam) {
    if (uploadedFile) validateFile(uploadedFile);
  }

  function getValidationError(uploadedFileSize: number, imageWidth: number) {
    let errorType: Error = undefined;

    if (uploadedFileSize > maxSize) {
      errorType = 'size';
    }
    if (imageWidth < minWidth) {
      errorType = 'width';
    }

    return errorType;
  }

  function onTrashIconClick() {
    setUploadedImage(undefined);
    setZoom(1);
  }

  const uploadContent = t(error ? `error.${error}` : 'instruction');

  return newUpload ? (
    <>
      <Row>
        <Col span={24}>
          <ImageCropperStyled $isUploaded={!!uploadedImage}>
            <Cropper
              image={uploadedImage?.url}
              crop={cropPosition}
              zoom={zoom}
              aspect={1}
              cropShape="round"
              onCropChange={setCropPosition}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
            <TrashIconStyled
              data-testid="trash-icon"
              width="32"
              height="32"
              onClick={onTrashIconClick}
            />
          </ImageCropperStyled>

          <ImageUploadStyled
            accept=".png, .jpg, .jpeg"
            listType="picture"
            fileList={uploadedImage ? [uploadedImage] : []}
            showUploadList={false}
            onChange={onFileChange}
            height={height}
            customRequest={() => null}
            $isUploaded={!!uploadedImage}
            $isError={!!error}
          >
            {uploadContent}
          </ImageUploadStyled>
        </Col>
      </Row>
      {uploadedImage && (
        <Row>
          <Col span={24}>
            <Slider
              min={1}
              max={10}
              marginTop={24}
              defaultValue={1}
              onChange={setZoom}
              disabled={!uploadedImage}
            />
          </Col>
        </Row>
      )}
    </>
  ) : (
    <ImageCropVisualizer
      imagePath={storedImage}
      onChangeImage={() => setNewUpload(true)}
    />
  );
};

export const ImageVisualizer = ImageCropVisualizer;

export default ImageUpload;
