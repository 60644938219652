const regex = {
  email: /[^@]+@[^.](.+)?\.(.+)?/,
  password: /^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W])).{8,30}$/, // https://regexr.com/56nhg
  imgUrl:
    /^https:\/\/(?:[a-z0-9-]+\.)+[a-z]{2,6}(?:\/[^/#?]+)+\.(?:gif|jp(e)?g|png)$/,
  httpsUrl:
    /^(?:https:\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/,
  scriptTag: /<script[\s\w="/]* src="(?<src>[\w/.:-]+)/g,
  alphanumericDashes: /^[a-zA-Z0-9-_]+$/,
  alphanumericSpaces: /^[a-zA-Z0-9\s]+$/,
  numeric: /^[0-9]+$/,
  decimalNumeric: /^-?\d*\.?\d*$/,
  positiveDecimalNumeric: /^\d*\.?\d*$/,
  positiveDecimalNumericWith2DecimalPlaces: /^\d*\.?\d{1,2}$/,
  forbiddenCharacters: /^((?![/\\<>|[\]{}()]).|\n)*$/,
  cityName:
    /^[a-zA-Z-'’ \u00C0-\u00D6\u00D8-\u00F6\u00F8-\u017F]+(?:[\s-][a-zA-Z]+)*$/,
  vatNumber: /^([A-Z]{2})?[0-9]+([A-Z]{1,2})?$/,
  emoji: /^(?::(?<emoji>[^:]+):)(?::skin-tone-(d):)?$/,
  hex: /^(?<color>#([a-f\d]{2})([a-f\d]{2})([a-f\d]{2}))$/,
  uuid: /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/,
};

export default regex;
