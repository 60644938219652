import http from '../../services/http-service';

export default class DocumentsApiService {
  public uploadDocument(accountId: string, document: FormData) {
    return http.post(`accounts/${accountId}/documents`, document, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  public async getDownloadDocumentUrl(documentUrl: string) {
    const response = await http.get(
      documentUrl[0] === '/' ? documentUrl.slice(1) : documentUrl,
    );
    return response.data.items[0].url;
  }
}
