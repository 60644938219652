import React, { ReactElement } from 'react';
import { WelcomeStep } from './steps/WelcomeStep';
import { MarketplaceOfferStep } from './steps/MarketplaceOfferStep';
import { AddToProgramStep } from './steps/AddToProgramStep';
import { OfferRequestsTabStep } from './steps/OfferRequestsTabStep';

export enum OfferMarketplaceOnboardingStepTypes {
  NEW_USER_WELCOME = 'new_user_welcome',
  MARKETPLACE_OFFER = 'marketplace_offer',
  ADD_TO_PROGRAM = 'add_to_program',
  OFFER_REQUESTS_TAB = 'offer_requests_tab',
  BLANK = 'blank',
  EXIT_TOUR = 'exit_tour',
}

export type OfferMarketplaceOnboardingKeys =
  | 'dashboardOffersMarketplaceWelcome'
  | 'dashboardOffersMarketplaceSampleOffer'
  | 'dashboardOffersMarketplaceAddToProgram'
  | 'dashboardOffersMarketplaceRequestsTab';

export const offerMarketplaceOnboardingKeys: Record<
  OfferMarketplaceOnboardingKeys,
  OfferMarketplaceOnboardingStepTypes
> = {
  dashboardOffersMarketplaceWelcome:
    OfferMarketplaceOnboardingStepTypes.NEW_USER_WELCOME,
  dashboardOffersMarketplaceSampleOffer:
    OfferMarketplaceOnboardingStepTypes.MARKETPLACE_OFFER,
  dashboardOffersMarketplaceAddToProgram:
    OfferMarketplaceOnboardingStepTypes.ADD_TO_PROGRAM,
  dashboardOffersMarketplaceRequestsTab:
    OfferMarketplaceOnboardingStepTypes.OFFER_REQUESTS_TAB,
};

type OfferMarketplaceOnboardingStep = {
  component: ReactElement | null;
};

const offerMarketplaceOnboardingSteps: Record<
  OfferMarketplaceOnboardingStepTypes,
  OfferMarketplaceOnboardingStep
> = {
  [OfferMarketplaceOnboardingStepTypes.NEW_USER_WELCOME]: {
    component: <WelcomeStep />,
  },
  [OfferMarketplaceOnboardingStepTypes.MARKETPLACE_OFFER]: {
    component: <MarketplaceOfferStep />,
  },
  [OfferMarketplaceOnboardingStepTypes.ADD_TO_PROGRAM]: {
    component: <AddToProgramStep />,
  },
  [OfferMarketplaceOnboardingStepTypes.OFFER_REQUESTS_TAB]: {
    component: <OfferRequestsTabStep />,
  },
  [OfferMarketplaceOnboardingStepTypes.BLANK]: {
    component: null,
  },
  [OfferMarketplaceOnboardingStepTypes.EXIT_TOUR]: {
    component: null,
  },
};

export const getOffersMarketplaceOnboardingStepComponent = (
  currStep: OfferMarketplaceOnboardingStepTypes,
) => offerMarketplaceOnboardingSteps[currStep].component;
