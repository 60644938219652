import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import { ReactComponent as Locked } from './assets/locked.svg';
import { Title } from './styled';
import { MediumBoldText } from '../styled/light-bold-text';
import useRedirect from '../../hooks/use-redirect';
import { roleCheck } from '../role-check';
import { getPaymentMethods } from '../../store/account/account-actions';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

const UpdatePaymentModal = () => {
  const { t } = useTranslation();
  const [shouldTriggerModal, setShouldTriggerModal] = useState(true);
  const [modal, contextHolder] = Modal.useModal();
  const redirect = useRedirect();
  const dispatch = useAppDispatch();
  const { hasLiveAgreement, paymentMethods, requiresStripe3ds, userDetails } =
    useAppSelector(
      state => ({
        hasLiveAgreement: !!state.account.details?.liveAgreement,
        paymentMethods: state.account.paymentMethods.entities,
        requiresStripe3ds: state.account.details?.requiresStripe3ds,
        userDetails: state.user.details,
      }),
      shallowEqual,
    );

  const isOnboarding = Object.values(userDetails?.stepsCompleted || {}).some(
    completed => completed === false,
  );

  useEffect(() => {
    if (hasLiveAgreement && !paymentMethods) dispatch(getPaymentMethods());
  }, [dispatch, hasLiveAgreement, paymentMethods]);

  useEffect(() => {
    if (!shouldTriggerModal || !paymentMethods || !userDetails || isOnboarding)
      return;
    if (requiresStripe3ds && paymentMethods.length > 0) {
      setShouldTriggerModal(false);
      modal.confirm({
        title: (
          <Title>
            <Locked />
            <p>{t('updatePayment.title')}</p>
          </Title>
        ),
        content: (
          <>
            <p>
              <Trans i18nKey="updatePayment.message1" />
            </p>
            <p>
              <Trans
                i18nKey="updatePayment.message2"
                components={{ 'light-bold': <MediumBoldText /> }}
              />
            </p>
          </>
        ),
        onOk: () => {
          redirect('/account/billing', { state: { addPayment: true } });
        },
        okText: t('updatePayment.cta'),
        cancelText: t('updatePayment.cancel'),
        icon: null,
      });
    }
  }, [
    isOnboarding,
    modal,
    paymentMethods,
    redirect,
    requiresStripe3ds,
    shouldTriggerModal,
    t,
    userDetails,
  ]);

  return <>{contextHolder}</>;
};

export default roleCheck(
  { disabledForModerator: true, requiresAccountAdmin: true },
  UpdatePaymentModal,
);
