import { KeyValuePairsDefaultFields } from '../../../components/form/components/KeyValuePairs';
import { Location } from '../../../store/locations/locations-model';
import { OfferProviderType } from '../types';

export function buildRecord(locations: Location[]) {
  const record: Record<string, true> = {};
  locations.forEach(({ id }) => {
    record[id] = true;
  });
  return record;
}

export function buildLocationsToUpdate(
  selectedLocations: string[],
  locations?: Location[],
): { link?: string[]; unlink?: string[] } {
  if (!locations && selectedLocations.length > 0)
    return { link: selectedLocations };

  const locationsId = locations?.map(({ id }) => id);
  const link: string[] = [];
  const unlink: string[] = [];

  selectedLocations.forEach(id => {
    if (!locationsId?.includes(id)) link.push(id);
  });

  if (locationsId)
    locationsId.forEach(id => {
      if (!selectedLocations.includes(id)) unlink.push(id);
    });

  return {
    link: link.length > 0 ? link : undefined,
    unlink: unlink.length > 0 ? unlink : undefined,
  };
}

export const SUPPORTED_REPORTING_PROVIDERS = ['awin', 'impact', 'rakuten'];

export function findProviderInfo(
  providers: OfferProviderType[],
  providerName: OfferProviderType['identifier'],
) {
  return providers.find(({ identifier }) => identifier === providerName);
}

export function findFieldValue(
  supplierFields: KeyValuePairsDefaultFields,
  fieldName: string,
) {
  return supplierFields.find(({ key }) => key === fieldName)?.value ?? '';
}

export function compileSupplierFields(
  supplierInfo: OfferProviderType | undefined,
) {
  const { requiredFields, profileRequiredFields } = supplierInfo ?? {};

  return [...(requiredFields ?? []), ...(profileRequiredFields ?? [])];
}
