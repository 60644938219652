import React, { Dispatch, SetStateAction } from 'react';
import { Row, Typography, Button, Drawer } from 'antd';
import { useTranslation } from 'react-i18next';

import { Offer } from '../../../store/offers/offers-model';

interface OfferTermsProps {
  offerTerms: Offer['additionalTerms'];
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

export function parseMarkdown(text: string) {
  const patternMarkdown = /\[([^\]]+)\]\(([^)]+)\)/;
  const parsedText = text.replace(
    patternMarkdown,
    '<a href="$2" target="_blank">$1</a>',
  );
  // eslint-disable-next-line react/no-danger
  return <span dangerouslySetInnerHTML={{ __html: parsedText }} />;
}

export default function OfferTerms({
  offerTerms,
  visible,
  setVisible,
}: OfferTermsProps) {
  const { t } = useTranslation(['common', 'offers']);

  return offerTerms ? (
    <Drawer
      closable={false}
      width={440}
      visible={visible}
      title={t('offers:createUpdate.terms.add.title')}
      footer={
        <Row>
          <Button type="primary" onClick={() => setVisible(false)} size="small">
            {t('common:confirm.done')}
          </Button>
        </Row>
      }
    >
      <span style={{ fontSize: '14px' }}>
        <Typography.Paragraph>{parseMarkdown(offerTerms)}</Typography.Paragraph>
      </span>
    </Drawer>
  ) : null;
}
