import React from 'react';
import styled from 'styled-components';
import { Tooltip as AntTooltip } from 'antd';

import { BadgeTooltipProps, BadgeProps } from './types';
import { getColor } from './utils';

const Tooltip = ({ props, children }: BadgeTooltipProps) =>
  props ? <AntTooltip {...props}>{children}</AntTooltip> : children;

const Badge = ({ icon, tooltip, className }: BadgeProps) => (
  <Tooltip props={tooltip}>
    <div data-testid="avatar-badge" className={`avatar-badge ${className}`}>
      {icon && <i className="badge-icon">{icon}</i>}
    </div>
  </Tooltip>
);

const StyledBadge = styled(Badge)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -1px;
  bottom: -1px;
  width: 12px;
  height: 12px;
  border-radius: 4px 0;
  background-color: ${({ color }) => getColor('background', color)};
  border: 2px solid white;
  transition: 200ms all ease-out;

  .badge-icon {
    opacity: 0;
  }
`;

export default StyledBadge;
