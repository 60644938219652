import styled from 'styled-components';
import { Menu } from 'antd';

import { colors } from '../../theme';

const HorizontalMenu = styled(Menu)`
  &.ant-menu {
    margin-bottom: 40px;

    .ant-menu-item {
      padding: 0;
      margin-right: 40px;
      color: ${colors.grey5};
      border-bottom-width: 1px;

      :hover {
        border-bottom-width: 1px;
      }

      &-selected,
      :hover {
        border-bottom-color: ${colors.black};
        -webkit-text-stroke: 0.3px ${colors.black};
        color: ${colors.black};

        a {
          color: ${colors.black};
        }
      }
    }

    .ant-menu-item-disabled {
      -webkit-text-stroke: 0;
      :hover {
        -webkit-text-stroke: 0;
      }
    }
  }
`;

export default HorizontalMenu;
