import React from 'react';

import { Transaction } from '../../../store';
import { Offer } from '../../../store/offers/offers-model';
import { AmountCell } from '../../../components/tables/styled/helpers';
import { toDecimal } from '../../../utils/transform';
import { colors } from '../../../theme';
import { DOUBLE_MINUS } from '../../../utils/special-character';

const OfferCashback = ({
  offer,
  transaction,
}: {
  offer?: Offer;
  transaction?: Transaction;
}) => {
  const { type, currency } = offer ?? {};
  const { cashback, qualified } = (transaction?.offer ?? {}) as {
    cashback: number;
    qualified: boolean;
  };

  const cashbackPercentage =
    type?.name === 'discount'
      ? type.value
      : ((cashback * 100) / (transaction?.amount as number)).toFixed(0);

  if (!offer || !qualified)
    return (
      <span style={{ color: colors.grey4, fontSize: '14px' }}>
        {DOUBLE_MINUS}
      </span>
    );

  return (
    <AmountCell>
      <span>{`${currency} `}</span>
      <span>{toDecimal(cashback)}</span> ({cashbackPercentage}%)
    </AmountCell>
  );
};

export default OfferCashback;
