import React from 'react';
import { Picker, Emoji, EmojiData } from 'emoji-mart';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import 'emoji-mart/css/emoji-mart.css';

import {
  ColorPicker,
  ColorPickerContainer,
  Container,
  ProgramIcon,
} from '../styled/change-program-icon';
import { ProgramIconType } from './CreateUpdateProgram';
import { Check } from '../../../components/icons';
import { colors, programColors } from '../../../theme';

interface ChangeProgramIconProps {
  programIcon: ProgramIconType;
  setProgramIcon: (programIcon: ProgramIconType) => void;
}

const ChangeProgramIcon = ({
  programIcon,
  setProgramIcon,
}: ChangeProgramIconProps) => {
  const { t } = useTranslation('programs');

  const { icon, iconBackground } = programIcon;

  function handleSelectEmoji(emoji: EmojiData) {
    setProgramIcon({ ...programIcon, icon: emoji.colons || '' });
  }

  const EmojiPicker = () => (
    <Container>
      <ColorPickerContainer>
        {(
          Object.keys(
            programColors.background,
          ) as (keyof typeof programColors['background'])[]
        ).map(color => {
          const background = programColors.background[color];
          const border = programColors.border[color];
          return (
            <ColorPicker
              key={color}
              $selected={iconBackground === background}
              $background={background}
              $borderColor={border}
              onClick={() => {
                setProgramIcon({
                  ...programIcon,
                  iconBackground: background,
                });
              }}
              data-testid={`bg-${color}`}
            >
              <Check />
            </ColorPicker>
          );
        })}
      </ColorPickerContainer>
      <Picker
        onSelect={handleSelectEmoji}
        native
        exclude={['flags', 'symbols']}
        showPreview={false}
        showSkinTones={false}
        color={colors.fidelBlue600}
        i18n={{ categories: { recent: t('create.emojiRecent') } }}
      />
    </Container>
  );

  return (
    <Tooltip
      overlay={EmojiPicker}
      trigger="click"
      overlayStyle={{ maxWidth: 'inherit' }}
      overlayInnerStyle={{ padding: 0, background: 'transparent' }}
    >
      <ProgramIcon
        size={72}
        data-testid="program-icon"
        $background={iconBackground}
        icon={<Emoji emoji={icon} size={48} native />}
      />
    </Tooltip>
  );
};

export default ChangeProgramIcon;
