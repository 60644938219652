import React, { useState, useEffect, useCallback, ElementType } from 'react';
import { shallowEqual } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid, Row, Col, Button } from 'antd';
import moment from 'moment';
import EvolutionChart from '../../../assets/illustrations/evolution-chart.png';
import {
  updateImages,
  getProfile,
  updateProfile,
} from '../../../store/account/account-actions';
import useProgressState from '../../../hooks/use-progress-state';
import { SelectedUniqueOfferPayload } from '../types';
import {
  ModalStep,
  FormStep,
  StepField,
  ModalButtonsConfig,
  ProfileFormModel,
} from './types';
import {
  tBase,
  modalButtons as buttonsConfig,
  modalSteps,
  fieldsByStep,
} from './config';
import FieldRecommendations from './components/FieldRecommendations';
import PhotoStep from './steps/PhotoStep';
import PitchStep from './steps/PitchStep';
import MultiFieldStep from './steps/MultiFieldStep';
import {
  StyledCol,
  StyledText,
  StyledImage,
  StyledDivider,
  StyledModal,
  StyledFooterWrapper,
  StyledFormWrapper,
} from './styled';
import { SecondaryLinkButton } from '../../../components/buttons';
import { dateFormat, getCurrentDate } from '../../../utils/dates';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

export interface PublisherProfileProps {
  selectedUniqueOfferPayload: SelectedUniqueOfferPayload;
}

const { useBreakpoint } = Grid;

const PublisherProfile = ({
  selectedUniqueOfferPayload,
}: PublisherProfileProps) => {
  const dispatch = useAppDispatch();

  const { profile, isUpdating } = useAppSelector(
    ({ account }) => ({
      profile: { ...account.profile.entity, photo: account.details?.imagePath },
      isUpdating: account.images.updating || account.profile.updating,
    }),
    shallowEqual,
  );

  const form = useForm<ProfileFormModel>({
    mode: 'onBlur',
    defaultValues: {
      photo: undefined,
      pitch: '',
      websiteUrl: '',
      androidAppUrl: '',
      iosAppUrl: '',
      audience: '',
      audienceSize: '',
      growthForecast: '',
    },
  });

  const profileUpdate = useProgressState(isUpdating);

  const { t } = useTranslation('offers');

  const { md: isMdBreakpoint } = useBreakpoint();

  const [visible, setVisible] = useState(!!selectedUniqueOfferPayload);
  const [step, setStep] = useState<ModalStep>('intro');

  const isIntroStep = step === 'intro';

  const stepFields = fieldsByStep[step as FormStep];

  const getDefaultStepField = useCallback(
    () => (isIntroStep ? 'photo' : stepFields[0]),
    [isIntroStep, stepFields],
  );

  const [stepField, setStepField] = useState<StepField>(getDefaultStepField());

  useEffect(() => {
    setStepField(getDefaultStepField());
  }, [getDefaultStepField]);

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  const lastDateSaved = localStorage.getItem('LAST_TIME_PROFILE_MODAL');

  const getDaysSinceLastTimeModalShowedUp = (dateFromStorage: string) => {
    const currentDate = moment(getCurrentDate(), dateFormat);
    return currentDate.diff(moment(dateFromStorage, dateFormat), 'days');
  };

  const hasFilledInAllProfileFields =
    isIntroStep && Object.values(profile).every(Boolean);

  const shouldShowModal =
    !lastDateSaved ||
    (getDaysSinceLastTimeModalShowedUp(lastDateSaved) >= 7 &&
      !hasFilledInAllProfileFields);

  if (!shouldShowModal) return null;

  const { watch, reset, formState } = form;
  const { photo, pitch, ...fields } = watch();

  function isButtonLoading(type: ModalButtonsConfig['type']) {
    return type === 'primary' && isUpdating;
  }

  function isButtonDisabled(type: ModalButtonsConfig['type']) {
    return (
      type === 'primary' &&
      step === 'links' &&
      !!Object.keys(formState.errors).length
    );
  }

  function getStepPagination(modalStep: ModalStep) {
    const currentStep = modalSteps.indexOf(modalStep);

    return {
      total: modalSteps.length - 1,
      current: currentStep,
      next: modalSteps[currentStep + 1],
      prev: modalSteps[currentStep - 1],
      progress: currentStep * 25,
    };
  }

  const stepPagination = getStepPagination(step);

  const getStepDirtyFieldsName = Object.keys(formState.dirtyFields) as Array<
    Exclude<StepField, 'photo' | 'pitch'>
  >;

  function getStepDirtyFieldsValue() {
    return getStepDirtyFieldsName.reduce(
      (dirtyFields, field) => ({
        ...dirtyFields,
        ...(fields[field] && {
          [field]: fields[field],
        }),
      }),
      {} as Record<StepField, string>,
    );
  }

  const getStepDirtyFields = getStepDirtyFieldsValue();

  function photoConfirmHandler() {
    return photo ? dispatch(updateImages(photo)) : setStep(stepPagination.next);
  }
  function pitchConfirmHandler() {
    return formState.touchedFields.pitch
      ? dispatch(updateProfile({ pitch }))
      : setStep(stepPagination.next);
  }
  function linksConfirmHandler() {
    return getStepDirtyFieldsName.some(Boolean)
      ? dispatch(updateProfile(getStepDirtyFields))
      : setStep(stepPagination.next);
  }

  function closeModal() {
    setVisible(false);
    localStorage.setItem('LAST_TIME_PROFILE_MODAL', getCurrentDate());
  }
  function onNext() {
    return {
      photo: photoConfirmHandler,
      pitch: pitchConfirmHandler,
      links: linksConfirmHandler,
    }[step as Exclude<FormStep, 'audience'>]();
  }
  function onSubmit() {
    if (getStepDirtyFieldsName.some(Boolean))
      dispatch(updateProfile(getStepDirtyFields, { isLastStep: true }));
    else setVisible(false);
  }

  const buttonsHandlers = {
    dismiss: () => closeModal(),
    complete: () => setStep(stepPagination.next),
    close: () => closeModal(),
    back: () => setStep(stepPagination.prev),
    next: () => onNext(),
    submit: () => onSubmit(),
  };

  const stepFieldsSet = {
    photo: <PhotoStep />,
    pitch: <PitchStep />,
    links: <MultiFieldStep formStep="links" setStepField={setStepField} />,
    audience: (
      <MultiFieldStep formStep="audience" setStepField={setStepField} />
    ),
  };

  profileUpdate.onFinish(() => {
    if (step === 'audience') {
      setVisible(false);
    } else {
      reset();
      setStep(stepPagination.next);
    }
  });

  const FooterButtonByType: Record<ModalButtonsConfig['type'], ElementType> = {
    primary: Button,
    link: SecondaryLinkButton,
  };

  const ModalFooter = (
    <StyledFooterWrapper
      $isIntro={isIntroStep}
      $isMdBreakpoint={!!isMdBreakpoint}
    >
      {buttonsConfig
        .filter(({ steps }) => steps.includes(step))
        .map(({ tKey, type }) => {
          const FooterButton = FooterButtonByType[type];
          return (
            <FooterButton
              key={`modal-button-${tKey}`}
              type={type}
              onClick={buttonsHandlers[tKey]}
              loading={isButtonLoading(type)}
              disabled={isButtonDisabled(type)}
            >
              {t(`${tBase}.modal.buttons.${tKey}`)}
            </FooterButton>
          );
        })}
    </StyledFooterWrapper>
  );

  return (
    <FormProvider {...form}>
      <StyledModal
        visible={visible}
        width={isIntroStep ? 424 : 800}
        closable={false}
        footer={null}
      >
        {isIntroStep && (
          <Row justify="center">
            <Col>
              <StyledImage $maxHeight={192} src={EvolutionChart} />
            </Col>
          </Row>
        )}

        <Row>
          <StyledCol xs={24} md={isIntroStep ? 24 : 15}>
            {isIntroStep || (
              <Row>
                <StyledDivider
                  orientation="right"
                  plain={false}
                  $progress={stepPagination.progress}
                >
                  {`${stepPagination.current} / ${stepPagination.total}`}
                </StyledDivider>
              </Row>
            )}

            <Row>
              <Col>
                <StyledText $fontSize={18}>
                  {t(`${tBase}.modal.${step}.title`)}
                </StyledText>
                <StyledText $fontSize={14}>
                  {t(`${tBase}.modal.${step}.description`)}
                </StyledText>
              </Col>
            </Row>

            {isIntroStep || (
              <Row>
                <Col span={24}>
                  <StyledFormWrapper>
                    {stepFieldsSet[step as FormStep]}
                  </StyledFormWrapper>
                </Col>
              </Row>
            )}

            {isMdBreakpoint && (
              <Row justify="end">
                <Col span={24}>{ModalFooter}</Col>
              </Row>
            )}
          </StyledCol>

          {isIntroStep || (
            <StyledCol xs={24} md={9} $backgroundColor="lightgrey">
              <FieldRecommendations
                formStep={step as FormStep}
                stepField={stepField}
                isMdBreakpoint={!!isMdBreakpoint}
              />
            </StyledCol>
          )}
        </Row>

        {isMdBreakpoint || (
          <Row justify="end">
            <Col span={24}>{ModalFooter}</Col>
          </Row>
        )}
      </StyledModal>
    </FormProvider>
  );
};

export default PublisherProfile;
