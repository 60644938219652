import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import { NoLocationsContainer } from '../styled/no-locations';
import { useAppSelector } from '../../../store/hooks';

interface NoLocationsProps {
  showCreateUpdateLocation: () => unknown;
}

const NoLocations = ({ showCreateUpdateLocation }: NoLocationsProps) => {
  const { t } = useTranslation('locations');
  const filtersActive = useAppSelector(
    state => state.filters.activeFilters.locations,
  );

  const message = filtersActive ? t('noLocationsFiltered') : t('noLocations');

  return (
    <NoLocationsContainer>
      <h1>{message}</h1>
      <Button type="primary" size="small" onClick={showCreateUpdateLocation}>
        {t('addLocation')}
      </Button>
    </NoLocationsContainer>
  );
};

export default NoLocations;
