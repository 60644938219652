import React, { useContext } from 'react';

import { selectProgramsAsList } from '../../../store/programs/programs-selectors';
import OnboardingCursor, { startCenter } from '../components/onboarding-cursor';
import { OnboardingTourContext } from '../onboarding-provider';
import { OnboardingStepTypes } from '../onboarding-config';
import useRedirect from '../../../hooks/use-redirect';
import useSelectedProgram from '../../../hooks/use-selected-program';
import DEMO_PROGRAM from '../demo-program';
import { useAppSelector } from '../../../store/hooks';

export const ProgramsClickStep = () => {
  const redirect = useRedirect();
  const { setStep } = useContext(OnboardingTourContext);
  const { setSelectedProgramId } = useSelectedProgram();

  const programs = useAppSelector(selectProgramsAsList)();
  const demoProgram = programs.find(program => program.name === DEMO_PROGRAM);

  return (
    <OnboardingCursor
      target="demo-program"
      action={() => {
        if (demoProgram) {
          setSelectedProgramId(demoProgram.id);
        }

        setStep(OnboardingStepTypes.LOCATIONS);
        redirect('/locations');
      }}
      {...startCenter()}
    />
  );
};
