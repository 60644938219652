import liveService from '../../services/live-service';
import { actions } from './live-reducer';
import * as BrandsActions from '../brands/brands-actions';
import * as FiltersActions from '../filters/filters-actions';
import * as LocationsActions from '../locations/locations-actions';
import * as OfferActions from '../offers';
import * as ProgramsActions from '../programs/programs-actions';
import * as TransactionsActions from '../transactions/transactions-actions';
import { Thunk } from '../types';

export const setLive =
  (live: boolean): Thunk =>
  dispatch => {
    liveService.setLive(live);
    dispatch(actions.setLive(live));

    dispatch(ProgramsActions.clear());
    dispatch(LocationsActions.clear());
    dispatch(BrandsActions.clear());
    dispatch(OfferActions.clear());
    dispatch(OfferActions.clearOffer());
    dispatch(TransactionsActions.clear());
    dispatch(FiltersActions.clear());
  };
