import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import { useForm } from 'react-hook-form';

import { inviteUser } from '../../../store/users/users-actions';
import drawerTransition, {
  DrawerVisibleProps,
} from '../../../components/drawer-transition';
import { Select } from '../../../components/select';
import Form from '../../../components/form';
import DrawerForm from '../../../components/drawer-form';
import EmailInput from '../../sign/components/EmailInput';
import { Role, RoleDescription } from '../styled/invite-user';
import useFocusField from '../../../hooks/use-focus-field';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

interface OfferActivationDrawerProps extends DrawerVisibleProps {
  onClose: () => any;
}

const InviteUser = ({
  visible,
  afterVisibleChange,
  onClose,
}: OfferActivationDrawerProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'account.inviteUser' });
  const dispatch = useAppDispatch();
  const processing = useAppSelector(state => state.users.inviting);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
  });
  const emailRef = useFocusField<Input>();

  const roles = ['account', 'developer'] as const;

  function onSubmit(values: any) {
    dispatch(inviteUser(values));
  }

  return (
    <DrawerForm
      confirmText={t('submit')}
      cancelText={t('cancel')}
      title={t('title')}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      processing={processing}
      visible={visible}
      afterVisibleChange={afterVisibleChange}
    >
      <EmailInput errors={errors} control={control} ref={emailRef} />
      <Form.ItemController
        label={t('role')}
        errors={errors}
        controller={{
          name: 'role',
          render: ({ field }) => (
            <Select {...field} placeholder={t('rolePlaceholder')}>
              {roles.map(roleKey => (
                <Select.Option key={roleKey} value={roleKey}>
                  <Role>{t(`roles.${roleKey}.title`)}</Role>
                  <RoleDescription>
                    {t(`roles.${roleKey}.description`)}
                  </RoleDescription>
                </Select.Option>
              ))}
            </Select>
          ),
          rules: { required: true },
          control,
        }}
      />
    </DrawerForm>
  );
};

export default drawerTransition(InviteUser);
