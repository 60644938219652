import {
  createCarddPayload,
  createSelectTransactionPayload,
  createStreamTransactionPayload,
} from './endpoints-helpers';
import { Endpoint, Params } from '../types';

interface EndpointsGroup {
  endpointsList: Record<string, Endpoint>;
  docsLink: string;
}

export const selectEndpoints: Record<string, EndpointsGroup> = {
  cards: {
    endpointsList: {
      create: {
        canFail: true,
        i18nKey: 'cards.create',
        referenceLink: 'create-card',
        endpoint: {
          method: 'post',
          generateUrl: ({ programId, cardFailChecked }: Params = {}) =>
            `programs/${programId || 'program_id'}/cards${
              cardFailChecked ? '?fail=true' : ''
            }`,
          formatPayload: createCarddPayload,
        },
      },
      delete: {
        hasCardSelect: true,
        i18nKey: 'cards.delete',
        referenceLink: 'delete-card',
        endpoint: {
          method: 'delete',
          generateUrl: ({ cardId }: Params = {}) =>
            `cards/${cardId || 'card_id'}`,
        },
      },
    },
    docsLink: 'cards',
  },
  transactions: {
    endpointsList: {
      create: {
        hasCardSelect: true,
        hasLocationSelect: true,
        i18nKey: 'transactions.create',
        referenceLink: 'create-transaction-test',
        endpoint: {
          method: 'post',
          generateUrl: () => 'transactions/test',
          formatPayload: createSelectTransactionPayload,
        },
      },
    },
    docsLink: 'transactions',
  },
};

export const streamEndpoints: Record<string, EndpointsGroup> = {
  cards: {
    ...selectEndpoints.cards,
  },
  transactions: {
    endpointsList: {
      create: {
        ...selectEndpoints.transactions.endpointsList.create,
        hasLocationSelect: false,
        endpoint: {
          ...selectEndpoints.transactions.endpointsList.create.endpoint,
          formatPayload: createStreamTransactionPayload,
        },
      },
    },
    docsLink: 'transactions',
  },
};
