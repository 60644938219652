import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, Input, InputNumber, Typography } from 'antd';
import { useForm, FormProvider } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { InlineButton } from '../../../../../components/buttons';
import drawerTransition from '../../../../../components/drawer-transition';
import Form from '../../../../../components/form';
import { FieldWithCurrencyWrapper } from '../../../../../components/form/styled';
import { SelectTransactionPeriod } from '../../../../../store/account/account-model';
import regex from '../../../../../utils/regex';
import CreateUpdatePeriod, {
  CreateUpdateBillingPeriodProps,
} from '../CreateUpdateBillingPeriod';
import { CreateUpdateBillingPeriodMode } from '../utils';
import Brackets, { bracketsToForm, formToBrackets } from './Brackets';
import { useAppSelector } from '../../../../../store/hooks';

interface CreateUpdateSTPeriodProps
  extends CreateUpdateBillingPeriodProps<SelectTransactionPeriod> {
  defaultBrackets: [number, number][] | undefined;
}

export interface CreateUpdateSTPeriodForm
  extends Omit<SelectTransactionPeriod, 'brackets'> {
  brackets: { key: number; value: number }[];
}

const CreateUpdateSTPeriod = ({
  mode,
  onClose,
  visible,
  selectedPeriod,
  defaultBrackets,
  afterVisibleChange,
}: CreateUpdateSTPeriodProps) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'account.contract.billingPeriods.createUpdate',
  });
  const { t: tPrograms } = useTranslation('programs', {
    keyPrefix: 'programType',
  });
  const [minFeeDisabled, setMinFeeDisabled] = useState(
    mode === CreateUpdateBillingPeriodMode.Create,
  );
  const billingConfigurations = useAppSelector(
    state => state.account.billingConfigurations.entity,
  );

  const { currency } = billingConfigurations || {};

  const form = useForm<CreateUpdateSTPeriodForm>({
    mode: 'onBlur',
    defaultValues: {
      minimumFee: selectedPeriod?.minimumFee,
      minimumTransactions: selectedPeriod?.minimumTransactions,
      disableProration: selectedPeriod?.disableProration ?? false,
      brackets: bracketsToForm(
        mode === CreateUpdateBillingPeriodMode.Create
          ? defaultBrackets
          : selectedPeriod?.brackets,
      ),
    },
  });
  const {
    control,
    getValues,
    formState: { errors },
    setValue,
    trigger,
  } = form;

  const getCalculatedMinFee = useCallback(() => {
    const { brackets, minimumTransactions } = getValues() || {};
    const { value } = brackets[0] || {};
    if (isNaN(minimumTransactions) || isNaN(value)) {
      return undefined;
    }

    return Number((value * minimumTransactions).toFixed(2));
  }, [getValues]);

  function calculateMinFee() {
    const calculatedMinFee = getCalculatedMinFee();
    if (calculatedMinFee) {
      setValue('minimumFee', calculatedMinFee);
      trigger('minimumFee');
    }
  }

  useEffect(() => {
    if (mode !== CreateUpdateBillingPeriodMode.Create) {
      setMinFeeDisabled(selectedPeriod?.minimumFee === getCalculatedMinFee());
    }
  }, [getCalculatedMinFee, selectedPeriod?.minimumFee, mode]);

  return (
    <FormProvider {...form}>
      <CreateUpdatePeriod
        title={t(`title.${mode || 'create'}`, {
          name: tPrograms('transaction-select.label'),
        })}
        form={form as any}
        mode={mode}
        periodKey="transactionPricing"
        onClose={onClose}
        visible={visible}
        selectedPeriod={selectedPeriod}
        afterVisibleChange={afterVisibleChange}
        generatePeriodToSubmit={(event: CreateUpdateSTPeriodForm) => ({
          ...event,
          invoiceNumber: Number(event.invoiceNumber),
          minimumFee: Number(event.minimumFee),
          brackets: formToBrackets(event.brackets),
        })}
      >
        <FieldWithCurrencyWrapper>
          <Form.ItemController
            label={t('selectTransaction.minFee.label')}
            errors={errors}
            style={{ marginBottom: '8px' }}
            controller={{
              name: 'minimumFee',
              render: ({ field }) => (
                <Input
                  {...field}
                  min={0}
                  prefix={currency}
                  disabled={minFeeDisabled}
                />
              ),
              rules: {
                required: true,
                min: 0,
                validate: {
                  numeric: value => regex.positiveDecimalNumeric.test(value),
                },
              },
              control,
            }}
            itemDescription={
              <Typography.Paragraph type="secondary">
                <Trans
                  i18nKey={`account.contract.billingPeriods.createUpdate.selectTransaction.minFee.description.${
                    minFeeDisabled ? 'set' : 'auto'
                  }`}
                  components={[
                    <InlineButton
                      data-testid="disable-enable-min-fee"
                      type="link"
                      style={{ fontSize: '13px' }}
                      onClick={() => {
                        setMinFeeDisabled(!minFeeDisabled);
                        calculateMinFee();
                      }}
                    />,
                  ]}
                />
              </Typography.Paragraph>
            }
          />
        </FieldWithCurrencyWrapper>
        <Form.ItemController
          label={t('selectTransaction.minTransactions.label')}
          errors={errors}
          style={{ marginBottom: '8px' }}
          controller={{
            name: 'minimumTransactions',
            render: ({ field: { onChange, ...rest } }) => (
              <InputNumber
                {...rest}
                min={0}
                onChange={value => {
                  onChange(value);
                  if (minFeeDisabled) calculateMinFee();
                }}
              />
            ),
            rules: { required: true, min: 0 },
            control,
          }}
          itemDescription={
            <Typography.Paragraph type="secondary">
              {t('selectTransaction.minTransactions.description')}
            </Typography.Paragraph>
          }
        />
        <Form.ItemController
          errors={errors}
          controller={{
            name: 'disableProration',
            render: ({ field: { value, onChange, ...rest } }) => (
              <Checkbox
                {...rest}
                data-testid="disable-proration"
                checked={value}
                onChange={e => onChange(e.target.checked)}
                style={{ marginRight: '8px' }}
              />
            ),
            control,
          }}
        >
          {t('selectTransaction.disableProration')}
        </Form.ItemController>
        <Typography.Paragraph style={{ marginBottom: 0 }}>
          {t('selectTransaction.brackets.label')}
        </Typography.Paragraph>
        <Typography.Paragraph type="secondary">
          <Trans
            i18nKey="account.contract.billingPeriods.createUpdate.selectTransaction.brackets.description"
            components={[<strong style={{ fontWeight: 500 }} />]}
          />
        </Typography.Paragraph>
        <Brackets
          currency={currency}
          calculateMinFee={() => {
            if (minFeeDisabled) calculateMinFee();
          }}
        />
      </CreateUpdatePeriod>
    </FormProvider>
  );
};

export default drawerTransition(CreateUpdateSTPeriod);
