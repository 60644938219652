import UserApiService from './user-api-service';
import { namespaces } from '../../config';
import { getAccount } from '../account/account-actions';
import { actions } from './user-reducer';
import { Thunk } from '../types';

const userApiService = new UserApiService();

export const getUserFromIds =
  (userId: string | undefined, accountId?: string | undefined): Thunk =>
  async dispatch => {
    if (accountId) localStorage.setItem(namespaces.ACCOUNT, accountId);
    else localStorage.removeItem(namespaces.ACCOUNT);

    dispatch(actions.getUser());

    try {
      const response = await userApiService.getUser(userId);
      const user = response.data.items[0];
      dispatch(getUserFromData(user));
      if (accountId) {
        dispatch(getAccount(accountId));
      }
    } catch (error) {
      dispatch(actions.getUserError({ error }));
    }
  };

export const getUserFromData =
  (data: any, loading?: boolean): Thunk =>
  dispatch => {
    if (loading) {
      dispatch(actions.getUser());
      localStorage.setItem(namespaces.ACCOUNT, data.accountId);
    }

    dispatch(actions.getUserSuccess(data));
  };

export const { clear } = actions;
