import React, { ReactElement } from 'react';
import Avatar from '../../../../../../../components/avatar';
import Ellipsis from '../../../../../../../components/ellipsis';
import {
  Brand,
  UniqueBrand,
} from '../../../../../../../store/brands/brands-model';
import { Program } from '../../../../../../../store/programs/programs-reducer';
import {
  SelectedBrandBox,
  SelectedBrandInfo,
  SelectedBrandName,
  SelectedBrandWebsite,
} from '../../../styled';

export interface AddLocationsBrandCardProps {
  brand: Brand | UniqueBrand;
  program?: Program;
  locationsInfo?: ReactElement;
}

export default function AddLocationsBrandCard({
  brand,
  program,
  locationsInfo,
}: AddLocationsBrandCardProps) {
  return (
    <SelectedBrandBox>
      <Avatar size={88} src={brand.logoURL} data-brand>
        {brand.name}
      </Avatar>

      {!!program && (
        <Avatar
          color={{
            background: program.iconBackground,
            border: 'lightgrey',
          }}
          emoji={program.icon}
          data-secondary
        >
          {program.name}
        </Avatar>
      )}

      <SelectedBrandInfo>
        <SelectedBrandName>{brand.name}</SelectedBrandName>

        {brand.websiteURL && (
          <SelectedBrandWebsite>
            <Ellipsis>{brand.websiteURL}</Ellipsis>
          </SelectedBrandWebsite>
        )}

        {locationsInfo}
      </SelectedBrandInfo>
    </SelectedBrandBox>
  );
}
