import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';

import { State } from '../../../store';

import { CityAndPostcode } from '../styled/add-location';
import { Select } from '../../../components/select';
import { states } from '../../../utils/states';
import { supportedCountries } from '../../../utils/countries';
import { Location } from '../../../store/locations/locations-model';
import { RequiredFormItemController } from '../../../components/form/styled';
import { Schemes } from '../../../utils/schemes';
import MerchantIdsSubForm from './MerchantIdsSubForm';
import { Brand } from '../../../store/brands/brands-model';
import { Program } from '../../../store/programs/programs-reducer';
import AddLocationsBrandCard from '../../brands/components/create-update-brand/components/steps/add-unique-locations/AddLocationsBrandCard';
import { useAppSelector } from '../../../store/hooks';

const { Option } = Select;

export interface MerchantIdsSubForModel {
  scheme: Schemes | null;
  id: string;
}

export interface CreateUpdateLocationFormModel {
  brandId: string;
  address: string;
  city: string;
  postcode: string;
  countryCode: string;
  stateCode?: string;
  merchantIds: MerchantIdsSubForModel[];
}

interface CreateUpdateLocationFormProps {
  selectedLocation?: Location;
  selectedBrand?: Brand;
  selectedProgram?: Program;
  disableFields?: (keyof CreateUpdateLocationFormModel)[];
}

const CreateUpdateLocationForm = ({
  selectedLocation,
  selectedBrand,
  selectedProgram,
  disableFields,
}: CreateUpdateLocationFormProps) => {
  const { t } = useTranslation('locations', {
    keyPrefix: 'createLocation.form',
  });
  const processing = useAppSelector(
    (state: State) => state.locations.creating || state.locations.updating,
  );

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const countryOptions = supportedCountries
    .map(country => ({
      label: country.name,
      value: country.code,
    }))
    .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));

  const stateOptions = states.map(state => ({
    label: state.name,
    value: state.code,
  }));

  const countryCode = useWatch({
    control,
    name: 'countryCode',
    defaultValue: selectedLocation?.countryCode,
  });

  function disableField(name: keyof CreateUpdateLocationFormModel) {
    return processing || disableFields?.includes(name);
  }

  return (
    <>
      {!!selectedBrand && (
        <AddLocationsBrandCard
          brand={selectedBrand}
          program={selectedProgram}
        />
      )}

      <RequiredFormItemController
        label={t('address')}
        errors={errors}
        validateCharacters
        controller={{
          name: 'address',
          render: ({ field }) => (
            <Input
              {...field}
              placeholder={t('addressPlaceholder')}
              disabled={disableField('address')}
            />
          ),
          rules: { required: true, minLength: 2, maxLength: 100 },
          control,
        }}
      />

      <CityAndPostcode>
        <RequiredFormItemController
          label={t('city')}
          errors={errors}
          validateCharacters
          controller={{
            name: 'city',
            render: ({ field }) => (
              <Input
                {...field}
                placeholder={t('cityPlaceholder')}
                disabled={disableField('city')}
                pattern="^[a-zA-Z-'’ \u00C0-\u00D6\u00D8-\u00F6\u00F8-\u017F]+$"
              />
            ),
            rules: { required: true, minLength: 2, maxLength: 100 },
            control,
          }}
        />

        <RequiredFormItemController
          label={t('postcode')}
          errors={errors}
          validateCharacters
          controller={{
            name: 'postcode',
            render: ({ field }) => (
              <Input
                {...field}
                placeholder={t('postcode')}
                disabled={disableField('postcode')}
              />
            ),
            rules: { required: true, minLength: 4, maxLength: 20 },
            control,
          }}
        />
      </CityAndPostcode>

      <RequiredFormItemController
        label={t('country')}
        errors={errors}
        controller={{
          name: 'countryCode',
          render: ({ field }) => (
            <Select
              {...field}
              data-name="countryCode"
              placeholder={t('countryPlaceholder')}
              data-value={
                countryCode
                /* a data attribute is the only way we can pass value to the mock select */
              }
              disabled={disableField('countryCode')}
            >
              {countryOptions.map(({ label, value }) => (
                <Option key={value} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          ),
          rules: { required: true },
          control,
        }}
      />

      {countryCode === 'USA' && (
        <RequiredFormItemController
          label={t('state')}
          errors={errors}
          controller={{
            name: 'stateCode',
            render: ({ field }) => (
              <Select
                {...field}
                data-name="state"
                placeholder={t('state')}
                disabled={disableField('stateCode')}
              >
                {stateOptions.map(({ label, value }) => (
                  <Option key={value} value={value}>
                    {label}
                  </Option>
                ))}
              </Select>
            ),
            rules: { required: true },
            control,
          }}
        />
      )}

      <MerchantIdsSubForm />
    </>
  );
};

export default CreateUpdateLocationForm;
