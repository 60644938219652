import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Typography } from 'antd';
import { Offer } from '../../../../store/offers/offers-model';
import CardActivation from '../../create-update-offer/offer-form/components/CardActivation';
import { HelpMessage } from '../../create-update-offer/styled/offer-form';

interface SupplierInfoProps {
  uniqueOffer: Offer;
}

export default function OptionalCustomization({
  uniqueOffer,
}: SupplierInfoProps) {
  const { t } = useTranslation('offers', {
    keyPrefix: 'marketplace.addOffer.steps.optionalCustomization',
  });

  const customizationsConfig = [
    {
      component: <CardActivation key="card-activation-customization" />,
      shouldBeRendered: !uniqueOffer.activation.enabled,
    },
  ];

  const customizationsList = customizationsConfig.filter(
    ({ shouldBeRendered }) => shouldBeRendered,
  );

  if (!customizationsList.length) return null;

  const Customizations = customizationsList.map(({ component }, idx) => (
    <Row key={`customization-${idx}`} style={{ marginTop: 12 }}>
      <Col>{component}</Col>
    </Row>
  ));

  return (
    <>
      <Row>
        <Col span={24}>
          <Row>
            <Col>
              <Typography.Paragraph style={{ marginBottom: 8 }}>
                {t('title')}
              </Typography.Paragraph>
            </Col>
          </Row>
          <Row>
            <Col>
              <HelpMessage>{t('warning')}</HelpMessage>
            </Col>
          </Row>

          {Customizations}
        </Col>
      </Row>
    </>
  );
}
