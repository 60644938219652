import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import CopyableTag from '../../../components/tag/copyable-tag';
import Link from '../../../components/link';
import { StyledTag } from '../../../components/tag/copyable-tag/styled';
import { Subsection, SubsectionTitle } from '../styled';
import { KeysTable } from '../styled/key-set';

interface KeySetType {
  test: string;
  live: string;
}

interface KeySetProps {
  keySet?: KeySetType;
  type: string;
  setGoLiveVisible: (value: boolean) => void;
}

const KeySet = ({ keySet, type, setGoLiveVisible }: KeySetProps) => {
  const { t } = useTranslation();

  function renderKey(key: any) {
    if (key) {
      return (
        <CopyableTag
          showTooltip={false}
          successMessage={t('copyable.copied')}
          text={key}
        />
      );
    }

    return (
      <StyledTag onClick={() => setGoLiveVisible(true)}>
        <span>{t('account.keysTable.goLive')}</span>
      </StyledTag>
    );
  }

  const keysColumns = [
    {
      title: t('account.keysTable.header.mode'),
      dataIndex: 'mode',
      key: 'mode',
      width: 100,
    },
    {
      title: t('account.keysTable.header.keys'),
      dataIndex: 'keys',
      key: 'keys',
      render: (key: any) => renderKey(key),
    },
  ];

  return (
    <>
      <Subsection>
        <SubsectionTitle>
          {/* TODO Fix 'any' belows on OFF-1406 */}
          <h2>{t(`account.${type}Keys.title` as any)}</h2>
        </SubsectionTitle>
        <p>
          <Trans
            i18nKey={`account.${type}Keys.subtitle` as any}
            components={[<Link to="https://fidelapi.com/docs" />]}
          />
        </p>
        <KeysTable
          dataSource={Object.entries(keySet || {})
            .reverse()
            .map(([mode, keys]) => ({
              mode: t(`account.keysTable.rows.${mode}` as any),
              keys,
              key: mode,
            }))}
          columns={keysColumns}
          bordered
          pagination={false}
          scroll={{ x: 0 }}
        />
      </Subsection>
    </>
  );
};

export default KeySet;
