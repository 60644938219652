import React from 'react';
import { RoleCheck } from '../../components/role-check';
import NewFinalGoLiveModal from './NewFinalGoLiveModal';

export const FinalGoLiveModal = (props: { onClose?: () => void }) => (
  <RoleCheck disabledForModerator>
    <NewFinalGoLiveModal {...props} />
  </RoleCheck>
);

export default FinalGoLiveModal;
