import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'antd';

import { OnboardingStepTypes } from '../onboarding-config';
import { OnboardingTourContext } from '../onboarding-provider';
import Modal from '../../modal';
import { GreyDropdownButton } from '../../buttons';

export const SkipTourStep = () => {
  const { t } = useTranslation('common', { keyPrefix: 'onboarding.skip' });

  const { setStep, lastTourStep, toggleOnboardingOff } = useContext(
    OnboardingTourContext,
  );

  return (
    <Modal
      title={t('title')}
      description={
        <div>
          <p>
            <Trans
              i18nKey="onboarding.skip.description.paragraph1"
              components={[
                <a // eslint-disable-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
                  href="https://dashboard.fidel.uk/playground"
                  target="_blank"
                  rel="noopener noreferrer"
                />,
              ]}
            />
          </p>
          <p>
            <Trans
              i18nKey="onboarding.skip.description.paragraph2"
              components={[
                <a // eslint-disable-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
                  href="https://fidelapi.com/docs/"
                  target="_blank"
                  rel="noopener noreferrer"
                />,
                <a // eslint-disable-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
                  href="https://reference.fidel.uk/reference"
                  target="_blank"
                  rel="noopener noreferrer"
                />,
              ]}
            />
          </p>
        </div>
      }
      buttons={[
        <Button
          type="primary"
          onClick={() => {
            setStep(OnboardingStepTypes.EXIT_TOUR);
            toggleOnboardingOff();
          }}
          size="small"
          data-testid="modal-button-0"
          key="modal-button-0"
        >
          {t('buttons.gotIt')}
        </Button>,
        <GreyDropdownButton
          onClick={() => {
            setStep(lastTourStep);
          }}
          size="small"
          data-testid="modal-button-1"
          key="modal-button-1"
        >
          {t('buttons.backToTour')}
        </GreyDropdownButton>,
      ]}
      visible
    />
  );
};
