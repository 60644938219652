import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import OnboardingPopover from '../components/onboarding-popover';
import { OnboardingTourContext } from '../onboarding-provider';
import { OnboardingStepTypes } from '../onboarding-config';

export const CardsStep = () => {
  const { t } = useTranslation();

  const { completeOnboardingStep, setStep } = useContext(OnboardingTourContext);

  return (
    <OnboardingPopover
      placement="bottom"
      target="card-0"
      index={2}
      title={t('onboarding.cards.title')}
      description={
        <span>
          <Trans
            i18nKey="onboarding.cards.description"
            components={[<strong />]}
          />
        </span>
      }
      buttonText={t('onboarding.buttons.next')}
      action={() => {
        completeOnboardingStep('dashboardCards');
        setStep(OnboardingStepTypes.CARDS_CLICK_1);
      }}
      showSkipTour
    />
  );
};
