import styled from 'styled-components';
import { Typography } from 'antd';

import { breakpoints, colors } from '../../../../../theme';
import virtualCardImage from '../assets/virtual-card.png';
import { InlineButton } from '../../../../../components/buttons';

export const NewLetter = styled.div`
  display: inline-block;
  background: ${colors.yellow2};
  font-size: 12px;
  color: ${colors.darkYellow};
  font-weight: 700;
  margin-right: 4px;
  padding: 1px 2px;
  width: 16px;
  text-align: center;
`;

export const NoCardContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  border: 1px solid ${colors.grey2};
  border-radius: 4px;
  margin-bottom: 16px;
  h2 {
    font-size: 18px;
    font-weight: 500;
    margin-top: 4px;
  }
  p {
    font-size: 14px;
  }
  @media (min-width: ${breakpoints.tabletLandscape}px) {
    flex-direction: row;
  }
`;

export const Left = styled.div`
  width: 100%;
  padding: 32px 40px;
  @media (min-width: ${breakpoints.tabletLandscape}px) {
    width: 46%;
  }
`;

export const Right = styled.div`
  width: 100%;
  height: 200px;
  background: url(${virtualCardImage});
  background-size: cover;
  background-position: bottom;
  @media (min-width: ${breakpoints.tabletLandscape}px) {
    width: 54%;
    height: auto;
    background-position: center;
  }
`;

export const ModalIllustration = styled.div`
  height: 200px;
  width: 100%;
  margin-bottom: -30px;
  background: url(${virtualCardImage});
  background-size: cover;
  background-position: bottom;
`;

export const ModalButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: -10px 0;
`;

export const ModalDismissButton = styled(InlineButton)`
  background: none;
  color: ${colors.fidelBlue600};
  border: none;
  text-align: center;
  padding: 1.2rem 1.5rem 0;
  cursor: pointer;
  font-size: 0.8rem;
`;

export const ModalCaption = styled(Typography.Paragraph).attrs(() => ({
  type: 'secondary',
}))`
  text-align: center;
  margin-top: 16px;
  margin-bottom: 0;
  font-size: 12px;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: ${breakpoints.tabletPortrait}px) {
    flex-direction: row;
    align-items: normal;
  }
`;

export const Card = styled.div`
  position: relative;
  background: black;
  color: white;
  border-radius: 12px;
  width: 300px;
  height: 180px;
  @media (min-width: ${breakpoints.tabletPortrait}px) {
    padding-top: 30%;
    height: 0;
    width: 100%;
  }
`;

export const CardElements = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  padding: 8px 16px;
  bottom: 0;
`;

export const CardInfo = styled.div`
  width: 100%;
  margin-top: 40px;
  @media (min-width: ${breakpoints.tabletPortrait}px) {
    padding-left: 20px;
    margin-top: 0;
  }
  @media (min-width: ${breakpoints.tabletLandscape}px) {
    padding-left: 10%;
  }
`;

export const CardInfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;

export const CardNumbers = styled.p`
  color: white;
  font-size: 14px;
  margin-bottom: 0;
  @media (min-width: ${breakpoints.tabletLandscape}px) {
    font-size: 18px;
  }
`;

export const CardScheme = styled.div`
  display: flex;
  justify-content: flex-end;
  svg {
    width: 80%;
  }
  @media (min-width: ${breakpoints.tabletLandscape}px) {
    margin-bottom: 8px;
    svg {
      width: 100%;
    }
  }
`;
