import React, { useState, useEffect } from 'react';

export interface DrawerVisibleProps {
  visible: boolean;
  afterVisibleChange: (visible: boolean) => unknown;
}

export default function drawerTransition<P extends DrawerVisibleProps>(
  Component: React.ComponentType<P>,
) {
  const TransitionedComponent = ({
    visible,
    ...props
  }: Omit<P, 'afterVisibleChange'>) => {
    const [render, setRender] = useState(false);
    const [visibleAfterRender, setVisibleAfterRender] = useState(false);

    useEffect(() => {
      if (visible && !render) {
        setRender(true);
      }
    }, [visible, render]);

    useEffect(() => {
      if (render) {
        setVisibleAfterRender(visible);
      }
    }, [render, visible]);

    return render ? (
      <Component
        {...(props as P)}
        visible={visibleAfterRender}
        afterVisibleChange={() => {
          if (!visible) {
            setRender(false);
          }
        }}
      />
    ) : null;
  };

  TransitionedComponent.displayName = `DrawerTransition(${
    Component.displayName || Component.name
  })`;

  return TransitionedComponent;
}
