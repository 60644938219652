import React from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { CardContainer } from '../../../../../components/tables/styled/helpers';
import {
  UniqueBrandsCreateText,
  UniqueBrandsCreateSubtext,
  StyledBrandsPlusIcon,
} from '../styled/create-brand-card';
import { BlackButton } from '../../../../../components/buttons';

interface CreateBrandCardProps {
  handleCreateBrand: () => void;
}

const CreateBrandCard = ({ handleCreateBrand }: CreateBrandCardProps) => {
  const { t } = useTranslation('brands', { keyPrefix: 'addBrand.search.info' });

  return (
    <CardContainer
      role="button"
      data-testid="create-brand-card-container"
      style={{ padding: '16px 0' }}
    >
      <Row align="middle" justify="space-between">
        <Col>
          <StyledBrandsPlusIcon />
          <div style={{ display: 'flex', flexFlow: 'column' }}>
            <UniqueBrandsCreateText>{t('text')}</UniqueBrandsCreateText>
            <UniqueBrandsCreateSubtext>
              {t('subText')}
            </UniqueBrandsCreateSubtext>
          </div>
        </Col>
        <Col>
          <BlackButton onClick={handleCreateBrand}>
            {t('createButton')}
          </BlackButton>
        </Col>
      </Row>
    </CardContainer>
  );
};

export default CreateBrandCard;
