import React from 'react';
import { Color } from '../../theme';

export interface FidelLogoProps {
  size: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
  type: 'solo' | 'full';
  color: Extract<Color, 'black' | 'white' | 'gray'>;
}

const getLogoSize = (
  size: FidelLogoProps['size'],
  type: FidelLogoProps['type'],
) => {
  let logoSize: {
    width: React.CSSProperties['width'];
  };

  switch (size) {
    case 'xs':
      if (type === 'full') {
        logoSize = {
          width: 80,
        };
        break;
      }

      logoSize = {
        width: 12,
      };
      break;

    case 's':
      if (type === 'full') {
        logoSize = {
          width: 100,
        };
        break;
      }

      logoSize = {
        width: 16,
      };
      break;

    case 'm':
      if (type === 'full') {
        logoSize = {
          width: 200,
        };
        break;
      }

      logoSize = {
        width: 24,
      };
      break;

    case 'l':
      if (type === 'full') {
        logoSize = {
          width: 300,
        };
        break;
      }

      logoSize = {
        width: 32,
      };
      break;

    case 'xl':
      if (type === 'full') {
        logoSize = {
          width: 400,
        };
        break;
      }

      logoSize = {
        width: 40,
      };
      break;

    case 'xxl':
      if (type === 'full') {
        logoSize = {
          width: 500,
        };
        break;
      }

      logoSize = {
        width: 64,
      };
      break;
  }

  return logoSize;
};

function FidelLogo({ size, type, color }: FidelLogoProps) {
  const { width } = getLogoSize(size, type);

  if (type === 'solo')
    return (
      <svg
        width={width}
        viewBox="0 0 378 378"
        preserveAspectRatio="xMinYMin meet"
      >
        <title>Fidel API logo</title>
        <rect width="378" height="378" rx="42" fill={color} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M76 152.284V301.799L108.113 302C108.366 290.455 113.135 279.467 121.399 271.392C129.663 263.317 140.764 258.797 152.325 258.799H196.094C196.548 258.799 197.001 258.78 197.451 258.742H212.189V236.142H212.171V183.359H285.923C286.103 183.359 286.283 183.356 286.462 183.35H302V76H152.365C132.112 76 112.688 84.037 98.3668 98.3429C84.0456 112.649 76 132.052 76 152.284ZM121.103 196.335C129.394 188.052 140.639 183.399 152.365 183.399H180.017V226.68H152.325C137.104 226.629 122.22 231.147 109.601 239.648L108.154 240.612V227.563C108.154 215.85 112.812 204.617 121.103 196.335ZM121.103 121.095C129.394 112.813 140.639 108.16 152.365 108.16H269.846V151.28H152.325C137.085 151.259 122.194 155.835 109.601 164.409L108.154 165.372V152.324C108.154 140.611 112.812 129.377 121.103 121.095Z"
          fill={color === 'white' ? 'black' : 'white'}
        />
      </svg>
    );

  return (
    <svg width={width} viewBox="0 0 1550 256" fill="none">
      <title>Fidel API logo</title>
      <path
        d="M25.3676 25.3868C9.10136 41.4716 0 63.564 0 86.4315V255.806L36.4054 256C36.5991 243.016 42.0212 230.419 51.5098 221.311C60.8048 212.203 73.3918 206.97 86.5598 206.97H154.336V121.508H256V0H86.3661C63.5159 0 41.4402 9.10825 25.3676 25.3868ZM117.737 170.731H86.3661C69.1316 170.731 52.2844 175.77 37.9546 185.459L36.4054 186.622V171.894C36.4054 158.716 41.6339 145.926 51.1225 136.624C60.4175 127.322 73.1982 121.896 86.5598 121.896H117.93V170.731H117.737ZM219.401 85.2687H86.3661C69.1316 85.2687 52.2844 90.5011 37.9546 100.191L36.4054 101.354V86.6253C36.4054 73.4474 41.6339 60.6571 51.1225 51.355C60.6112 42.053 73.1982 36.6268 86.5598 36.6268H219.595V85.2687H219.401Z"
        fill={color}
      />
      <path
        d="M417 192H453.795V144.387H519.638V121.161H453.795V93.0968H533V66H417V192Z"
        fill={color}
      />
      <path d="M596 66H559V192H596V66Z" fill={color} />
      <path
        d="M692.411 66H628V192H698.054C751.568 192 772 169.355 772 129.097C772 93.2903 751.957 66 692.411 66ZM696.887 164.71H664.973V93.0968H694.357C724.13 93.0968 735.027 108.968 735.027 128.903C735.027 152.129 724.324 164.71 696.887 164.71Z"
        fill={color}
      />
      <path
        d="M835.784 139.355H898.898V117.871H835.784V93.0968H918.064V66H799V192H920V164.71H835.784V139.355Z"
        fill={color}
      />
      <path d="M983.944 66H947V192H1059V162.774H983.944V66Z" fill={color} />
      <path
        d="M1212.6 66L1144 192H1181.98L1194.97 166.258H1269.19L1282.17 192H1325L1256.4 66H1212.6V66ZM1205.63 144.774L1231.59 93.4839H1231.79L1257.95 144.774H1205.63Z"
        fill={color}
      />
      <path
        d="M1424.98 66H1349V192H1385.83V154.839H1424.01C1468.39 154.839 1487 139.935 1487 111.29C1487 81.0968 1468.01 66 1424.98 66ZM1423.23 131.419H1385.83V93.0968H1423.43C1443.97 93.0968 1452.11 99.871 1452.11 112.258C1452.11 125.419 1442.81 131.419 1423.23 131.419Z"
        fill={color}
      />
      <path d="M1550 66H1513V192H1550V66Z" fill={color} />
    </svg>
  );
}

export default FidelLogo;
