import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import ExportService from '../../../services/export-service';
import ExportDropdown from '../../../components/export-dropdown';
import useSelectedProgram from '../../../hooks/use-selected-program';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getExportableLocations } from '../../../store/locations/locations-actions';

interface LocationsFilterProps {
  selectedIds?: string[];
}

function locationsToArray(items: any) {
  return Object.values(items)
    .map(item => (item as any).items)
    .flat(1);
}

const LocationsExport = ({ selectedIds }: LocationsFilterProps) => {
  const dispatch = useAppDispatch();
  const { selectedProgram } = useSelectedProgram();
  const { t } = useTranslation('locations');
  const dateRange = { from: '', to: '' };
  const { locationsByProgram, exportableLocations } = useAppSelector(
    state => ({
      locationsByProgram: state.locations.locations,
      exportableLocations: state.locations.exportableLocations,
    }),
    shallowEqual,
  );

  const locations = locationsToArray(locationsByProgram);

  const [selectFormatVisible, setSelectFormatVisible] = useState(false);
  const [fileFormat, setFileFormat] = useState('pdf');
  const [shouldExport, setShouldExport] = useState(false);

  function exportLocations(format: string) {
    const exportService = new ExportService(t);
    const columnsOptions = [
      { key: 'id', title: t('export.id') },

      { key: 'address', title: t('export.address') },
      { key: 'brandId', title: t('export.brandId') },
      { key: 'brand', title: t('export.brand') },
      { key: 'countryCode', title: t('export.countryCode') },
      { key: 'visa', title: t('export.visa') },
      { key: 'mastercard', title: t('export.mastercard') },
      { key: 'amex', title: t('export.amex') },
      { key: 'created', title: t('export.created') },
    ];

    const locationsToExport = (
      selectedIds ? locations : exportableLocations
    ).filter(location => !selectedIds || selectedIds.includes(location.id));

    exportService.exportLocations({
      options: columnsOptions,
      format,
      raw: locationsToExport,
      programName: selectedProgram.name,
      dateRange,
    });

    setShouldExport(false);
  }

  useEffect(() => {
    if (exportableLocations.length > 0 && shouldExport && selectedProgram) {
      exportLocations(fileFormat);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportableLocations, shouldExport, selectedProgram]);

  function handleClickExport(format: string) {
    setFileFormat(format);
    setSelectFormatVisible(false);
    setShouldExport(true);

    if (selectedIds) {
      exportLocations(format);
      return;
    }

    dispatch(
      getExportableLocations({
        programId: selectedProgram?.id,
      }),
    );
  }

  return (
    <ExportDropdown
      label={selectedIds ? t('export.selectedLabel') : t('export.label')}
      disabled={locations.length === 0}
      loading={shouldExport}
      handleClickExport={handleClickExport}
      showSelectFormat={setSelectFormatVisible}
      selectFileFormatOpen={selectFormatVisible}
    />
  );
};

export default LocationsExport;
