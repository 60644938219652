import React from 'react';

import {
  OnboardingStepsIndicatorWrapper,
  OnboardingStepIndicator,
} from './styled';

interface OnboardingStepsIndicatorProps {
  total: number;
  current: number;
}

export const OnboardingStepsIndicator = ({
  total,
  current,
}: OnboardingStepsIndicatorProps) => (
  <OnboardingStepsIndicatorWrapper>
    {Array.from({ length: total }, (_, i) => i).map(index => (
      <OnboardingStepIndicator
        key={`onboardingStep__${index}`}
        active={index === current}
      />
    ))}
  </OnboardingStepsIndicatorWrapper>
);
