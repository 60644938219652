import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import OnboardingPopover from '../../../../components/onboarding-tour/components/onboarding-popover';
import { OffersMarketplaceOnboardingContext } from '../context/OffersMarketplaceOnboardingContext';
import { OfferMarketplaceOnboardingStepTypes } from '../config';

export const AddToProgramStep = () => {
  const { t } = useTranslation(['common', 'offers']);

  const { setStep } = useContext(OffersMarketplaceOnboardingContext);

  return (
    <OnboardingPopover
      placement="leftBottom"
      target="marketplace-add-to-program"
      title={t('offers:marketplace.onboarding.addToProgramStep.title')}
      description={
        <span>
          {t('offers:marketplace.onboarding.addToProgramStep.description')}
        </span>
      }
      buttonText={t('common:onboarding.buttons.done')}
      action={() => {
        setStep(OfferMarketplaceOnboardingStepTypes.BLANK);
      }}
      showSkipTour={false}
      hideOverlay
    />
  );
};
