import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { FixedSizeList as List } from 'react-window';
import { Checkbox, Col, Input, Row, Skeleton, Typography, Steps } from 'antd';

import {
  getAllBrands,
  createBrand,
  updateBrand,
  requestConsent,
  getUniqueBrandByBrandId,
  resetUniqueBrandByBrandId,
} from '../../../../store/brands/brands-actions';
import {
  Brand,
  BrandConsent,
  BrandLogo,
  UniqueBrand,
  UpdateBrandPayload,
} from '../../../../store/brands/brands-model';
import {
  createLocation,
  updateLocation,
  mapUniqueLocationsToProgram,
} from '../../../../store/locations/locations-actions';
import { getPrograms } from '../../../../store/programs/programs-actions';
import { Program } from '../../../../store/programs/programs-reducer';
import { selectProgramsAsList } from '../../../../store/programs/programs-selectors';
import regex from '../../../../utils/regex';
import Form from '../../../../components/form';
import DrawerForm from '../../../../components/drawer-form';
import Ellipsis from '../../../../components/ellipsis';
import HorizontalMenu from '../../../../components/horizontal-menu';
import Link from '../../../../components/link';
import ContentStateHandler from '../../../../components/tables/base/ContentStateHandler';
import { Cell } from '../../../../components/tables/styled/base';
import drawerTransition, {
  DrawerVisibleProps,
} from '../../../../components/drawer-transition';
import Avatar from '../../../../components/avatar';
import {
  SelectedDetails,
  SelectedName,
  SelectedURL,
} from '../unique-brands-search/styled/selected-brand';
import useProgressState from '../../../../hooks/use-progress-state';
import useDebounce from '../../../../hooks/use-debounce';
import { ConsentDescription, ConsentTabContent, StyledStep } from './styled';
import {
  RequiredFormItem,
  RequiredFormItemController,
} from '../../../../components/form/styled';
import { UniqueLocation } from '../../../../store/locations/locations-model';
import SelectBrandRow from './components/SelectBrandRow';
import SearchSuffix from '../../../../components/search-suffix';
import { ListTableWrapper, SearchInput } from './components/styled';
import CreateUpdateLocationForm, {
  CreateUpdateLocationFormModel,
} from '../../../locations/components/CreateUpdateLocationForm';
import { ReactComponent as SearchIcon } from '../../../../assets/search.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/plus.svg';
import { CreateLocationPayload } from '../../../../store/locations/locations-api-service';
import CreateEditBrand from './components/CreateEditBrandForm';
import SelectProgram from './components/SelectProgram';

import AddUniqueLocation from './components/steps/add-unique-locations';
import {
  merchantIdsToSearchBy,
  searchByToMerchantIds,
} from '../../../locations/utils/transform-form-model';
import { FormSteps, LocationsFormSteps } from '../../types';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

const { Paragraph } = Typography;

type ConsentTab = 'invitation' | 'auto';

interface CreateUpdateBrandModel {
  brandName: string;
  brandLogo?: BrandLogo;
  websiteUrl?: string;
  programId: string;
  email: string;
  nameFirst: string;
  nameLast: string;
  title: string;
  skipInvite: boolean;
}

interface CreateLocationModel {
  address: string;
  city: string;
  postcode: string;
  countryCode: string;
}

export interface CreateUpdateBrandProps extends DrawerVisibleProps {
  program?: Program;
  location?: any;
  handleClose: () => any;
  brand?: Brand | null;
  uniqueBrand?: UniqueBrand | null;
  defaultStep?: FormSteps;
}

interface SelectedBrandProps {
  account: Brand | null;
  unique?: UniqueBrand | null;
}

const defaultSelectedBrand: SelectedBrandProps = {
  account: null,
  unique: null,
};

const CreateUpdateBrand = ({
  program,
  location,
  brand,
  uniqueBrand,
  handleClose,
  visible,
  afterVisibleChange,
  defaultStep,
}: CreateUpdateBrandProps) => {
  const dispatch = useAppDispatch();
  const {
    account,
    isLive,
    loading,
    error,
    createBrandStatus,
    updateBrandStatus,
    requestConsentStatus,
    brands,
    creatingLocation,
    updatingLocation,
    accountBrand,
    accountDetails,
    user,
  } = useAppSelector(
    state => ({
      account: state.account.details,
      isLive: state.live,
      loading: state.brands.loading,
      error: state.brands.error,
      createBrandStatus: state.brands.createBrandStatus,
      updateBrandStatus: state.brands.updateBrandStatus,
      requestConsentStatus: state.brands.requestConsentStatus,
      brands: state.brands.brands,
      creatingLocation: state.locations.creating,
      updatingLocation: state.locations.updating,
      accountBrand: state.brands.getUniqueBrandByBrandId,
      accountDetails: state.account?.details,
      user: state.user.details,
    }),
    shallowEqual,
  );
  const programs = useAppSelector(selectProgramsAsList)();
  const { t } = useTranslation(['common', 'brands']);

  const [formState, setFormState] = useState<Record<string, string>>({});
  const [creating, setCreating] = useState(false);
  const [selectedConsentTab, setSelectedConsentTab] =
    useState<ConsentTab>('invitation');
  const [step, setStep] = useState<FormSteps>(getDefaultStep());
  const [selectedUniqueLocations, setSelectedUniqueLocations] = useState<
    UniqueLocation[]
  >([]);
  const [previousValues, setPreviousValues] = useState<any>({});
  const creatingProgress = useProgressState(createBrandStatus.loading);
  const updatingProgress = useProgressState(updateBrandStatus.loading);
  const requestConsentProgress = useProgressState(requestConsentStatus.loading);
  const creatingLocationProgress = useProgressState(creatingLocation);
  const updatingLocationProgress = useProgressState(updatingLocation);

  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedBrands, setSelectedBrands] = useState(defaultSelectedBrand);

  const selectedProgram =
    program ||
    programs.find(
      ({ id }: Program) => createBrandStatus?.createdBrand?.programId === id,
    );

  const selectedBrand = brand || selectedBrands?.account;
  const selectedUniqueBrand = uniqueBrand || selectedBrands?.unique;

  const debouncedSearchTerm = useDebounce(searchValue, 500);

  const filteredBrands = filterBrands(brands, debouncedSearchTerm);

  const parsedMerchantIds = searchByToMerchantIds(location, accountDetails);

  const formDefaultValues = {
    brandId: createBrandStatus?.createdBrand?.brandId || selectedBrand?.id,
    brandName:
      createBrandStatus?.createdBrand?.brandName || selectedBrand?.name,
    brandLogo: undefined,
    websiteURL: selectedBrand?.websiteURL,
    address: location?.address,
    city: location?.city,
    countryCode: location?.countryCode,
    postcode: location?.postcode,
    stateCode: location?.stateCode,
    ...parsedMerchantIds,
  };

  const form = useForm<CreateUpdateBrandModel & CreateUpdateLocationFormModel>({
    mode: 'onBlur',
    defaultValues: formDefaultValues,
    shouldUnregister: true,
  });

  const {
    formState: { errors },
    getValues,
    control,
    trigger,
    reset,
  } = form;

  useEffect(() => {
    if (step === FormSteps.ADD_NEW_LOCATIONS) {
      reset({ brandId: formDefaultValues.brandId });
    }
  }, [formDefaultValues.brandId, reset, step]);

  useEffect(() => {
    if (step === FormSteps.SELECT_BRAND) {
      dispatch(getAllBrands());
    }
  }, [dispatch, step]);

  useEffect(() => {
    if (step === FormSteps.SELECT_BRAND) {
      if (selectedBrands.account?.id && selectedBrands.unique !== undefined) {
        dispatch(getUniqueBrandByBrandId(selectedBrands.account.id));
      }
      if (selectedBrands.unique || selectedBrands.unique === undefined) {
        setStep(FormSteps.ADD_LOCATIONS);
      }
    }
  }, [step, selectedBrands, dispatch]);

  useEffect(() => {
    if (step === FormSteps.SELECT_BRAND) {
      if (selectedBrands.account && accountBrand.success) {
        setSelectedBrands({
          ...selectedBrands,
          unique: accountBrand.uniqueBrand,
        });
      }
      if (accountBrand.success !== undefined) {
        dispatch(resetUniqueBrandByBrandId());
      }
    }
  }, [step, accountBrand, selectedBrands, dispatch]);

  useEffect(() => {
    if (step === FormSteps.CREATE) dispatch(getPrograms());
  }, [dispatch, isLive, step]);

  useEffect(() => {
    if (creating && error) setCreating(false);
    else if (creating && !loading) handleClose();
  }, [loading, error, creating, handleClose]);

  useEffect(() => {
    if (
      step === FormSteps.ADD_LOCATIONS &&
      !selectedBrands.account &&
      defaultStep === FormSteps.SELECT_BRAND
    ) {
      setStep(FormSteps.SELECT_BRAND);
    }
  }, [step, selectedBrands.account, defaultStep]);

  creatingProgress.onFinish(() => {
    if (isLive) setStep(FormSteps.BRAND_CONSENT);
    else handleClose();
  });

  updatingProgress.onFinish(() => handleClose());

  requestConsentProgress.onFinish(() => {
    if (uniqueBrand) setStep(FormSteps.ADD_LOCATIONS);
    else handleClose();
  });

  creatingLocationProgress.onFinish(() => {
    setStep(FormSteps.ADD_LOCATIONS);
  });

  updatingLocationProgress.onFinish(() => {
    handleClose();
  });

  function getDefaultStep(): FormSteps {
    if (defaultStep) return defaultStep;
    if (brand || uniqueBrand) return FormSteps.BRAND_CONSENT;
    return FormSteps.CREATE;
  }

  function filterBrands(brandsList: Brand[], searchTerm: string) {
    return searchTerm
      ? brandsList.filter(({ name }) =>
          searchTerm
            .toLowerCase()
            .split(' ')
            .every(term => name.toLowerCase().includes(term)),
        )
      : brandsList;
  }

  function getConsent({
    email,
    nameFirst,
    nameLast,
    programId,
    skipInvite,
    title,
  }: any) {
    return {
      email,
      nameFirst,
      nameLast,
      programId,
      skipInvite,
      title,
    } as BrandConsent;
  }

  function requestBrandConsent(consent: BrandConsent) {
    dispatch(requestConsent({ ...consent, brandId: brand?.id }));
  }

  function saveBrand(values: Record<string, any>, consent: BrandConsent) {
    const brandToSave: any = {};

    if (brand) {
      requestBrandConsent(consent);
    } else {
      if (uniqueBrand) {
        if (uniqueBrand?.name) brandToSave.name = uniqueBrand?.name;
        if (uniqueBrand?.websiteURL)
          brandToSave.websiteURL = uniqueBrand?.websiteURL;
      } else {
        if (values.brandName) brandToSave.name = values.brandName.trim();
        if (values.brandLogo) {
          const { image, croppedImage } = values.brandLogo;
          brandToSave.image = image;
          brandToSave.croppedImage = croppedImage;
        }
        if (values.websiteURL) brandToSave.websiteURL = values.websiteURL;
      }

      dispatch(
        createBrand({
          brand: brandToSave as Brand,
          programId: values?.programId,
          programName: values?.programName,
          consent: isLive ? consent : undefined,
          live: isLive,
        }),
      );
    }
  }

  function fieldsToValidate(): (
    | keyof CreateUpdateBrandModel
    | keyof CreateLocationModel
  )[] {
    if (!isLive) return ['brandName'];

    if (step === FormSteps.CREATE) return ['brandName', 'programId'];

    if (step === FormSteps.EDIT_BRAND) return ['websiteUrl'];

    if (step === FormSteps.ADD_LOCATIONS) return [];

    if (
      step === FormSteps.ADD_NEW_LOCATIONS ||
      step === FormSteps.EDIT_LOCATION
    )
      return ['address', 'city', 'postcode', 'countryCode'];

    const isUniqueBrand: (keyof CreateUpdateBrandModel)[] = uniqueBrand
      ? ['programId']
      : [];

    if (selectedConsentTab === 'invitation')
      return ['email', 'nameFirst', 'nameLast', 'title', ...isUniqueBrand];

    return ['skipInvite', ...isUniqueBrand];
  }

  async function onSubmit() {
    const values = getValues();

    const valid = await trigger(fieldsToValidate());

    if (valid) {
      setPreviousValues({ ...previousValues, ...values });

      switch (step) {
        case FormSteps.CREATE:
          return onSubmitBrandCreate(values);
        case FormSteps.EDIT_BRAND:
          return onSubmitBrandEdit(values);
        case FormSteps.BRAND_CONSENT:
          return onSubmitBrandConsent(values);
        case FormSteps.ADD_LOCATIONS:
          return onSubmitAddLocations();
        case FormSteps.ADD_NEW_LOCATIONS:
        case FormSteps.EDIT_LOCATION:
          return onSubmitCreateUpdateLocation(values);
      }
    }
    if (step === FormSteps.SELECT_BRAND) onClose();
    return null;
  }

  function onSubmitBrandCreate(values: any) {
    if (isLive) {
      setFormState({ ...formState, ...values });
      setStep(FormSteps.BRAND_CONSENT);
    } else {
      saveBrand(values, getConsent(values as BrandConsent));
    }
  }

  function onSubmitBrandEdit(values: Record<string, any>) {
    const payload: UpdateBrandPayload = {};

    if (values.brandLogo) {
      const { image, croppedImage } = values.brandLogo;
      payload.image = image;
      payload.croppedImage = croppedImage;
    }

    payload.websiteURL = values.websiteURL;

    dispatch(updateBrand(selectedBrand?.id || '', payload));
  }

  function onSubmitBrandConsent(values: any) {
    let newValues = {
      ...values,
      ...formState,
    };
    newValues.programName = programs.find(
      ({ id }) => id === newValues.programId,
    )?.name as string;

    if (selectedConsentTab === 'auto') {
      const { nameFirst, nameLast, email } = user as any;
      const { title } = account as any;
      newValues = { ...newValues, nameFirst, nameLast, email, title };
    } else {
      newValues = { ...newValues, skipInvite: false };
    }

    saveBrand(newValues, getConsent(newValues as BrandConsent));
  }

  function onSubmitAddLocations() {
    const programId = createBrandStatus?.createdBrand?.programId || program?.id;
    const brandId =
      createBrandStatus?.createdBrand?.brandId || selectedBrand?.id;
    if (
      programId &&
      brandId &&
      selectedUniqueBrand?.id &&
      selectedUniqueLocations.length
    ) {
      dispatch(
        mapUniqueLocationsToProgram({
          programId,
          brandId,
          uniqueBrandId: selectedUniqueBrand?.id,
          uniqueLocationIds: selectedUniqueLocations.map(item => item.id),
        }),
      );
    } else {
      onClose();
    }
  }

  function onSubmitCreateUpdateLocation(values: CreateUpdateLocationFormModel) {
    const {
      brandId,
      address,
      city,
      postcode,
      countryCode,
      stateCode,
      merchantIds,
    } = values;

    const payload: CreateLocationPayload = {
      address,
      city,
      postcode,
      countryCode,
      stateCode,
      searchBy: merchantIdsToSearchBy(merchantIds),
    };

    const action = !location
      ? createLocation(selectedProgram?.id || '', payload)
      : updateLocation(selectedProgram?.id || '', location.id, payload);

    if (!location) {
      payload.brandId = brandId;
    }

    if (selectedProgram) dispatch(action);
  }

  function renderTitle(): string | JSX.Element {
    if (step === FormSteps.SELECT_BRAND)
      return t('brands:createUpdate.title.selectBrand');
    if (step === FormSteps.ADD_LOCATIONS)
      return t('brands:createUpdate.title.addLocations');
    if (step === FormSteps.EDIT_BRAND)
      return t('brands:createUpdate.title.editBrand');
    if (step === FormSteps.ADD_NEW_LOCATIONS)
      return t('brands:createUpdate.title.createLocation');
    if (step === FormSteps.EDIT_LOCATION)
      return t('brands:createUpdate.title.editLocation');
    if (
      !brand &&
      !uniqueBrand &&
      isLive &&
      (step === FormSteps.CREATE || step === FormSteps.BRAND_CONSENT)
    )
      return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>{t('brands:createUpdate.title.create')}</span>
          <Steps
            current={step === FormSteps.CREATE ? 0 : 1}
            size="small"
            style={{ width: '200px' }}
          >
            <StyledStep title={t('brands:createUpdate.title.details')} />
            <StyledStep title={t('brands:createUpdate.title.consent')} />
          </Steps>
        </div>
      );
    if (!brand && !uniqueBrand && !isLive)
      return t('brands:createUpdate.title.create');
    if (brand) return t('brands:createUpdate.title.addAccount');
    if (uniqueBrand) {
      if (step === FormSteps.BRAND_CONSENT)
        return t('brands:createUpdate.title.brandConsentRequired');
    }

    return t('brands:createUpdate.title.create');
  }

  function setAddLocationsConfirmText(selectedUniqueLocationsLength: number) {
    if (!selectedUniqueLocationsLength) {
      return t(`brands:createUpdate.confirm.selectBrand`);
    }

    return t(`brands:createUpdate.confirm.addLocations`, {
      numOfLocations: selectedUniqueLocationsLength,
      count: selectedUniqueLocationsLength,
    });
  }

  function renderConfirmText(): string {
    if (step === FormSteps.SELECT_BRAND) {
      return t('brands:createUpdate.confirm.selectBrand');
    }
    if (step === FormSteps.EDIT_BRAND)
      return t('brands:createUpdate.confirm.save');
    if (step === FormSteps.CREATE) {
      if (isLive) return t('brands:createUpdate.confirm.next');
      return t('brands:createUpdate.confirm.create');
    }
    if (!brand && !uniqueBrand && step === FormSteps.BRAND_CONSENT)
      return t('brands:createUpdate.confirm.create');
    if (step === FormSteps.BRAND_CONSENT) {
      if (selectedConsentTab === 'invitation')
        return t('brands:createUpdate.confirm.sendInvitation');
      if (selectedConsentTab === 'auto')
        return t('brands:createUpdate.confirm.autoApprove');
    }
    if (step === FormSteps.ADD_LOCATIONS) {
      return setAddLocationsConfirmText(selectedUniqueLocations.length);
    }
    if (step === FormSteps.EDIT_LOCATION) {
      return t('brands:createUpdate.confirm.editLocation');
    }

    return t('brands:createUpdate.confirm.create');
  }

  function onClose() {
    setSearchValue('');

    if (step === FormSteps.ADD_NEW_LOCATIONS)
      return setStep(FormSteps.ADD_LOCATIONS);
    if (defaultStep === FormSteps.SELECT_BRAND) {
      if (step === FormSteps.ADD_LOCATIONS) {
        return setSelectedBrands(defaultSelectedBrand);
      }
      if (step === FormSteps.CREATE) {
        return setStep(FormSteps.SELECT_BRAND);
      }
    }

    return handleClose();
  }

  function selectBrandRow({ index, style }: any) {
    return (
      <div style={{ ...style, borderTop: '1px solid #eee', fontSize: '14px' }}>
        <SelectBrandRow
          brand={filteredBrands[index]}
          onClickHandler={() => {
            setSelectedBrands({
              account: filteredBrands[index],
              unique: null,
            });
            setSearchValue('');
          }}
        />
      </div>
    );
  }

  return (
    <DrawerForm
      title={renderTitle()}
      confirmText={renderConfirmText()}
      cancelText={t('brands:createUpdate.cancel')}
      onClose={onClose}
      onSubmit={onSubmit}
      submitDisabled={
        creatingProgress.inProgress || updatingProgress.inProgress
      }
      processing={creating}
      visible={visible}
      afterVisibleChange={afterVisibleChange}
      id="create-update-brand-form"
      push={{ distance: 8 }}
    >
      <Skeleton
        loading={
          creatingProgress.inProgress ||
          updatingProgress.inProgress ||
          requestConsentProgress.inProgress ||
          creatingLocationProgress.inProgress
        }
        active
      >
        <FormProvider {...form}>
          {step === FormSteps.SELECT_BRAND && !brand && !uniqueBrand && (
            <>
              <SearchInput
                name="brandSearch"
                prefix={<SearchIcon />}
                suffix={
                  <SearchSuffix
                    label={t('brands:selectBrand.search.clear')}
                    searchValue={searchValue}
                    clearCallback={() => setSearchValue('')}
                    closeCallback={() => onClose()}
                  />
                }
                onChange={({ target }) => setSearchValue(target.value)}
                value={searchValue}
                placeholder={t('brands:selectBrand.search.placeholder')}
              />
              <ListTableWrapper>
                <ContentStateHandler
                  bordered
                  emptyText={t('common:table.empty')}
                  isLoading={loading}
                  loadingExtra={t('common:extraLoading')}
                  hasItems={!!filteredBrands.length}
                >
                  <Cell align={undefined} size={55} key={0} onClick={undefined}>
                    {t('brands:selectBrand.table.results')}
                  </Cell>
                  <div
                    style={{ borderTop: '1px solid #eee', fontSize: '14px' }}
                  >
                    <SelectBrandRow
                      title="Add a brand"
                      avatar={
                        <Avatar
                          color={{
                            color: 'grey5',
                            background: 'lightgrey2',
                          }}
                          icon={<PlusIcon />}
                        />
                      }
                      onClickHandler={() => setStep(FormSteps.CREATE)}
                    />
                  </div>
                  <List
                    width="100%"
                    height={400}
                    itemCount={filteredBrands.length}
                    itemSize={56}
                  >
                    {selectBrandRow}
                  </List>
                </ContentStateHandler>
              </ListTableWrapper>
            </>
          )}
          {step === FormSteps.CREATE && (
            <CreateEditBrand isLive={isLive} program={program} />
          )}
          {step === FormSteps.EDIT_BRAND && (
            <CreateEditBrand brand={brand} isLive={isLive} program={program} />
          )}
          {step === FormSteps.BRAND_CONSENT && isLive && (
            <>
              {uniqueBrand && (
                <>
                  <div style={{ display: 'flex' }}>
                    <Avatar
                      size={88}
                      src={uniqueBrand?.logoURL}
                      color={{ border: 'default' }}
                    >
                      {uniqueBrand?.name}
                    </Avatar>
                    <SelectedDetails style={{ justifyContent: 'center' }}>
                      <div>
                        <SelectedName>{uniqueBrand?.name}</SelectedName>
                        <SelectedURL>
                          <a
                            href={uniqueBrand?.websiteURL}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Ellipsis>{uniqueBrand?.websiteURL}</Ellipsis>
                          </a>
                        </SelectedURL>
                      </div>
                    </SelectedDetails>
                  </div>
                  <ConsentDescription>
                    <Trans
                      ns="brands"
                      i18nKey="createUpdate.info.brandConsent"
                      components={[
                        <Link to="https://fidelapi.com/docs/select/brands/#brand-consent" />,
                      ]}
                    />
                  </ConsentDescription>
                </>
              )}
              {(uniqueBrand || brand) && <SelectProgram program={program} />}
              <Typography.Paragraph>
                {t('brands:createBrand.consentTitle')}
              </Typography.Paragraph>
              <Paragraph type="secondary">
                <Trans
                  ns="brands"
                  i18nKey="createBrand.consentMessage"
                  components={[
                    <Link to="https://fidelapi.com/docs/select/brands/#brand-consent" />,
                  ]}
                />
              </Paragraph>
              <HorizontalMenu
                onClick={e => setSelectedConsentTab(e.key as ConsentTab)}
                selectedKeys={[selectedConsentTab]}
                mode="horizontal"
                style={{ marginBottom: '20px' }}
              >
                <HorizontalMenu.Item key="invitation" disabled={creating}>
                  {t('brands:createBrand.invitationConsentTab')}
                </HorizontalMenu.Item>
                <HorizontalMenu.Item key="auto" disabled={creating}>
                  {t('brands:createBrand.autoConsentTab')}
                </HorizontalMenu.Item>
              </HorizontalMenu>
              <ConsentTabContent visible={selectedConsentTab === 'invitation'}>
                <>
                  <RequiredFormItem
                    label={t('brands:createBrand.label.contactName')}
                    style={{ marginBottom: 0 }}
                    required
                  >
                    <Row justify="space-between">
                      <Col xs={{ span: 12 }}>
                        <Form.ItemController
                          errors={errors}
                          validateCharacters
                          controller={{
                            name: 'nameFirst',
                            render: ({ field }) => (
                              <Input
                                {...field}
                                placeholder={t(
                                  'brands:createBrand.placeholder.nameFirst',
                                )}
                              />
                            ),
                            rules: {
                              required: selectedConsentTab === 'invitation',
                              maxLength: 30,
                            },
                            control,
                          }}
                        />
                      </Col>
                      <Col xs={{ offset: 1, span: 11 }}>
                        <Form.ItemController
                          errors={errors}
                          validateCharacters
                          controller={{
                            name: 'nameLast',
                            render: ({ field }) => (
                              <Input
                                {...field}
                                placeholder={t(
                                  'brands:createBrand.placeholder.nameLast',
                                )}
                              />
                            ),
                            rules: {
                              required: true,
                              maxLength: 30,
                            },
                            control,
                          }}
                        />
                      </Col>
                    </Row>
                  </RequiredFormItem>
                  <RequiredFormItemController
                    label={t('brands:createBrand.label.email')}
                    errors={errors}
                    validateCharacters
                    controller={{
                      name: 'email',
                      render: ({ field }) => (
                        <Input
                          {...field}
                          placeholder={t(
                            'brands:createBrand.placeholder.email',
                          )}
                        />
                      ),
                      rules: { required: true, pattern: regex.email },
                      control,
                    }}
                  />
                  <RequiredFormItemController
                    label={t('brands:createBrand.label.title')}
                    errors={errors}
                    validateCharacters
                    controller={{
                      name: 'title',
                      render: ({ field }) => (
                        <Input
                          {...field}
                          placeholder={t(
                            'brands:createBrand.placeholder.title',
                          )}
                        />
                      ),
                      rules: { required: true, maxLength: 30 },
                      control,
                    }}
                  />
                </>
              </ConsentTabContent>
              <ConsentTabContent visible={selectedConsentTab === 'auto'}>
                <Form.Item
                  validateStatus={errors.skipInvite && 'error'}
                  help={
                    errors.skipInvite && t('common:form.validation.required')
                  }
                >
                  <div style={{ display: 'flex' }}>
                    <RequiredFormItemController
                      controller={{
                        name: 'skipInvite',
                        render: ({ field: { onChange, value, ...rest } }) => (
                          <Checkbox
                            {...rest}
                            data-testid="skip-invite"
                            onChange={e => onChange(e.target.checked)}
                            checked={value}
                          />
                        ),
                        control,
                        rules: { required: true },
                      }}
                    />
                    <Paragraph type="secondary" style={{ marginLeft: '14px' }}>
                      {t('brands:createBrand.autoApproveMessage')}
                    </Paragraph>
                  </div>
                </Form.Item>
              </ConsentTabContent>
            </>
          )}
          {step === FormSteps.ADD_LOCATIONS &&
            selectedProgram &&
            selectedBrand && (
              <AddUniqueLocation
                program={selectedProgram}
                brand={selectedBrand}
                uniqueBrand={selectedUniqueBrand}
                setStep={
                  setStep as Dispatch<SetStateAction<LocationsFormSteps>>
                }
                selectedUniqueLocations={selectedUniqueLocations}
                setSelectedUniqueLocations={setSelectedUniqueLocations}
                onClose={onClose}
                forceMappingStateReset
              />
            )}
          {step === FormSteps.ADD_NEW_LOCATIONS &&
            selectedProgram &&
            selectedBrand && (
              <CreateUpdateLocationForm
                selectedProgram={selectedProgram}
                selectedBrand={selectedBrand}
              />
            )}
          {step === FormSteps.EDIT_LOCATION && selectedProgram && (
            <CreateUpdateLocationForm selectedLocation={location} />
          )}
        </FormProvider>
      </Skeleton>
    </DrawerForm>
  );
};

export default drawerTransition(CreateUpdateBrand);
