import React from 'react';
import { Control } from 'react-hook-form';

import TaggedInput from '../../tag/tagged-input';
import { ControllerType } from '..';
import handleEmailValidation from '../../../utils/email-validation';

export function invoiceEmailsController({
  control,
  defaultValue = [],
  required = true,
}: {
  control: Control<any>;
  defaultValue?: string[];
  required?: boolean;
}): ControllerType {
  return {
    name: 'invoiceEmails',
    render: ({ field: { ref, value, ...rest } }) => (
      <TaggedInput value={value || defaultValue} {...rest} />
    ),
    rules: {
      validate: {
        required: values => (required ? !!values?.length : true),
        email: handleEmailValidation,
        maxInvoiceEmails: values => (values || []).length <= 10,
      },
    },
    control,
  };
}
