import styled from 'styled-components';
import { Modal } from 'antd';

import { colors } from '../../../theme';

export const Container = styled.div`
  p {
    margin: 6px 0 0;
    font-size: 13px;
    color: ${colors.grey4};
  }
`;

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    .ant-modal-body {
      padding: 30px 24px;
      white-space: pre-line;
    }

    .ant-modal-footer {
      padding: 20px 24px;
      background: ${colors.lightgrey};
      border: none;

      .ant-btn {
        &:first-of-type {
          background: none;
          border: none;
          font-weight: normal;
          color: ${colors.grey5};
          &:hover {
            background: ${colors.lightgrey2};
          }
        }
      }
    }
  }
`;
