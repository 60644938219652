import React, { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import { Button, Col, Row } from 'antd';

import {
  UniqueBrandsSearchContainer,
  UniqueBrandsSearchBox,
  CloseIcon,
  ClearButton,
  SearchInput,
} from './styled';
import { ReactComponent as PlusIcon } from '../../../../assets/plus.svg';
import { ReactComponent as SearchIcon } from '../../../../assets/search.svg';
import InfiniteScrollTable from '../../../../components/tables/InfiniteScrollTable';
import { TableWithRightContentWrapper } from '../../../../components/tables/styled/helpers';
import SearchBrandCard from './components/SearchBrandCard';
import { UniqueBrand } from '../../../../store/brands/brands-model';
import CreateBrandCard from './components/CreateBrandCard';
import useDebounce from '../../../../hooks/use-debounce';
import { setUniqueBrandName } from '../../../../store/brands/brands-actions';
import SelectedBrand from './components/SelectedBrand';
import useProgressState from '../../../../hooks/use-progress-state';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useUniqueBrands } from '../../hooks/useBrands';

interface UniqueBrandsSearchProps {
  handleCreateBrand: () => void;
  setSelectedBrand: (brand: any) => void;
  handleAddUniqueBrand: (uniqueBrand: UniqueBrand | null) => void;
}

const UniqueBrandsSearch = ({
  handleCreateBrand,
  setSelectedBrand,
  handleAddUniqueBrand,
}: UniqueBrandsSearchProps) => {
  const { t } = useTranslation('brands', {
    keyPrefix: 'addBrand',
  });
  const dispatch = useAppDispatch();

  const { error, createBrandStatus, isLive, requestConsentStatus } =
    useAppSelector(
      state => ({
        error: state.brands.error,
        createBrandStatus: state.brands.createBrandStatus,
        requestConsentStatus: state.brands.requestConsentStatus,
        isLive: state.live,
      }),
      shallowEqual,
    );
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const [selectedUniqueBrand, setSelectedUniqueBrand] = useState<
    UniqueBrand | undefined
  >(undefined);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const creatingProgress = useProgressState(createBrandStatus.loading);
  const requestConsentProgress = useProgressState(requestConsentStatus.loading);
  const { data, refetch, isFetching } = useUniqueBrands({
    searchTerm: debouncedSearchTerm || '',
    limit: 4,
    enabled: !!debouncedSearchTerm,
  });

  const uniqueBrands =
    debouncedSearchTerm && data?.pages.flatMap(page => page.items);

  useEffect(() => {
    window.addEventListener('click', clickedOutsideBox);
    return () => window.removeEventListener('click', clickedOutsideBox);
  }, []);

  useEffect(() => {
    dispatch(setUniqueBrandName(debouncedSearchTerm || ''));
    setSelectedUniqueBrand(undefined);
  }, [debouncedSearchTerm, dispatch]);

  useEffect(() => {
    setSearchTerm(undefined);
  }, [isLive]);

  useEffect(() => {
    if (!isOpen) setSelectedUniqueBrand(undefined);
  }, [isOpen]);

  function showDetail(brand: any) {
    if (!isEqual(selectedUniqueBrand, brand)) setSelectedUniqueBrand(brand);
    else setSelectedUniqueBrand(undefined);
  }

  function brandToRow(brand: any) {
    const contents = [
      <SearchBrandCard
        id={brand.id}
        logoURL={brand.logoURL}
        name={brand.name}
        websiteURL={brand.websiteURL}
      />,
    ];

    return {
      contents,
      active: selectedUniqueBrand?.id === brand.id,
      onClick: () => {
        showDetail(brand);
      },
    };
  }

  function reloadUniqueBrands(e: MouseEvent) {
    e.stopPropagation();
    refetch();
  }

  function clickedOutsideBox(e: any) {
    if (
      !e.target.closest('#add-brand') &&
      !e.target.closest('#create-update-brand-form') &&
      !e.target.closest('#unique-brands-search-box')
    ) {
      setIsOpen(false);
    }
  }

  creatingProgress.onFinish(() => {
    if (!error) setIsOpen(false);
  });

  requestConsentProgress.onFinish(() => {
    if (!error) setIsOpen(false);
  });

  const searchSuffix = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {searchTerm && (
        <ClearButton
          data-testid="unique-brands-search-clear"
          onClick={e => {
            e.stopPropagation();
            setSearchTerm('');
          }}
        >
          {t('search.clear')}
        </ClearButton>
      )}
      <CloseIcon onClick={() => setIsOpen(false)} />
    </div>
  );

  return (
    <>
      <UniqueBrandsSearchContainer>
        {!isOpen ? (
          <Button
            id="add-brand"
            type="primary"
            size="small"
            onClick={() => {
              setSelectedBrand(null);
              setIsOpen(true);
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <PlusIcon style={{ marginRight: '3px' }} />
              <span style={{ paddingTop: '2px' }}>{t('label')}</span>
            </div>
          </Button>
        ) : (
          <UniqueBrandsSearchBox $setExpand={!!uniqueBrands?.length}>
            <SearchInput
              name="uniqueBrandSearch"
              prefix={<SearchIcon />}
              suffix={searchSuffix}
              onChange={e => setSearchTerm(e.target.value)}
              value={searchTerm}
              placeholder={t('search.placeholder')}
            />
            <Row style={{ height: '100%' }}>
              <TableWithRightContentWrapper
                $showSideBar={!!uniqueBrands?.length}
              >
                <InfiniteScrollTable
                  columns={[{ heading: t('search.table.results') }]}
                  rows={(uniqueBrands || []).map(brand => brandToRow(brand))}
                  onReload={reloadUniqueBrands}
                  loading={isFetching}
                  emptyText={null}
                  minWidth={500}
                  stretch={false}
                />
                {!isFetching && (
                  <CreateBrandCard handleCreateBrand={handleCreateBrand} />
                )}
              </TableWithRightContentWrapper>
              {!!uniqueBrands?.length && (
                <Col>
                  <SelectedBrand
                    selectedUniqueBrand={selectedUniqueBrand}
                    setSelectedUniqueBrand={setSelectedUniqueBrand}
                    handleAddUniqueBrand={handleAddUniqueBrand}
                  />
                </Col>
              )}
            </Row>
          </UniqueBrandsSearchBox>
        )}
      </UniqueBrandsSearchContainer>
    </>
  );
};

export default UniqueBrandsSearch;
