import React, { useMemo } from 'react';
import { Input } from 'antd';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Form from '../../../../../components/form';
import { ContentProviderOfferFormModel } from '../../../types';

function getDefaultTextareaRules(type: DescriptionTextareaProps['type']) {
  const rules: RegisterOptions = {};

  switch (type) {
    case 'long':
      rules.minLength = 4;
      break;
    case 'short':
      rules.minLength = 4;
      rules.maxLength = 1000;
  }

  return rules;
}

interface DescriptionTextareaProps {
  type: 'short' | 'long';
  disabled: boolean;
}

function DescriptionTextarea({ type, disabled }: DescriptionTextareaProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext<ContentProviderOfferFormModel>();
  const { t } = useTranslation('offers');

  const { label, controllerName, rules, placeholder } = useMemo(() => {
    const description =
      type === 'short' ? 'shortDescription' : 'longDescription';

    return {
      label: t(`fields.${description}.label`),
      controllerName: description,
      rules: getDefaultTextareaRules(type),
      placeholder: t('createUpdate.optional'),
    };
  }, [t, type]);

  return (
    <Form.ItemController
      label={label}
      errors={errors}
      controller={{
        name: controllerName,
        control,
        rules,
        render: ({ field }) => (
          <Input.TextArea
            {...field}
            placeholder={placeholder}
            data-testid={controllerName}
            disabled={disabled}
          />
        ),
      }}
    />
  );
}

export default DescriptionTextarea;
