import styled from 'styled-components';
import { colors } from '../../../../../theme';

export const OnboardingStepsIndicatorWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 6px;
`;

interface OnboardingStepIndicatorProps {
  active: boolean;
}

export const OnboardingStepIndicator = styled.div<OnboardingStepIndicatorProps>`
  background: ${({ active }) => (active ? colors.black : colors.lightgrey6)};
  border-radius: 50%;
  height: 6px;
  width: 6px;
`;
