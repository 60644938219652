import styled from 'styled-components';
import { Form } from 'antd';

import { CustomCollapse } from '../../../components/collapse/styled';
import { breakpoints, colors } from '../../../theme';

export const FormItem = styled(Form.Item)`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;

  > :first-child {
    line-height: 1.5;
  }

  > :last-child {
    min-width: 400px;
  }

  label {
    white-space: normal;

    &::after {
      display: none;
    }
  }

  @media (min-width: 576px) {
    flex-flow: row nowrap;

    > :last-child {
      margin-left: 10px;
    }
  }
`;

export const WideForm = styled.form`
  max-width: 600px;
`;

export const Container = styled.div`
  max-width: 800px;
  margin: 0 auto 48px;
`;

export const ScrollingContainer = styled.div`
  height: calc(100vh - 30px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 20px;
  margin: 0 -20px;

  @media (min-width: ${breakpoints.tabletLandscape}px) {
    padding: 0 40px;
    margin: 0 -40px;
  }
`;

export const FixedSection = styled.div`
  position: sticky;
  top: -30px;
  background: white;
  z-index: 4;
  margin-top: -30px;
  padding-top: 30px;
`;

export const CollapsableSubsectionTitle = styled.span<{ number: number }>`
  :before {
    content: '${({ number }) => number}';
    display: inline-block;
    margin-right: 10px;
    border-radius: 4px;
    width: 20px;
    height: 20px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.6;
    background: ${colors.grey4};
    color: white;
  }
`;

export const CollapsePanel = styled(CustomCollapse.Panel)`
  .ant-collapse-content {
    padding: 20px 10%;
  }
`;

export const Subsection = styled.div<{
  hideBorder?: boolean;
  $includeLastBorder?: boolean;
}>`
  padding: 20px 0;
  border-bottom: ${({ hideBorder }) =>
    hideBorder ? 'none' : `1px solid ${colors.lightgrey2}`};
  font-size: 14px;
  color: ${colors.grey4};

  &:last-of-type {
    border-bottom: ${({ hideBorder, $includeLastBorder }) =>
      !hideBorder && $includeLastBorder
        ? `1px solid ${colors.lightgrey2}`
        : 'none'};
  }
`;

export const SubsectionSubtitle = styled.div``;

export const SubsectionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2,
  p {
    font-size: 16px;
    color: ${colors.black};
    font-weight: normal;
    margin-bottom: 0;
  }

  ${SubsectionSubtitle} {
    font-size: 14px;
    color: ${colors.grey4};
  }

  + p {
    margin-bottom: 20px;
  }
`;
