import styled from 'styled-components';
import { Modal } from 'antd';

import { colors } from '../../theme';

export const ConfirmModal = styled(Modal).attrs(() => ({
  closable: false,
  width: 400,
}))`
  .ant-modal-content {
    margin-top: 34px;
    font-size: 14px;

    .ant-modal-title {
      font-weight: normal;
    }
  }

  .ant-modal-header {
    padding: 24px;
    border-bottom: none;
  }

  .ant-modal-body {
    padding: 0;
    padding: 0 24px;
  }

  .ant-modal-footer {
    margin-top: 0;
    width: 100%;
    text-align: right;
    padding: 20px 24px;
    background: ${colors.lightgrey};
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;

    .ant-btn {
      height: 32px;
      &:first-of-type {
        background: none;
        border: none;
        font-weight: normal;
        color: ${colors.grey5};
        &:hover {
          background: ${colors.lightgrey2};
        }
      }
    }
  }
`;
