import styled from 'styled-components';
import { colors } from '../../../theme';

interface OnboardingOverlayProps {
  showTint?: boolean;
}
export const OnboardingOverlay = styled.div<OnboardingOverlayProps>`
  background: ${({ showTint }) =>
    showTint ? 'rgba(81, 96, 120, 0.35)' : `${colors.transparent}`};
  display: flex;
  flex-flow: column;
  left: 0;
  height: 100vh;
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 4;
`;

export const OnboardingConfettiWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
`;

export const OnboardingConfettiTarget = styled.div`
  z-index: 1010;
`;
