import { AxiosResponse } from 'axios';

type PaginatedResponse = { last?: any };
type PaginatedItemsResponse<I> = { items: I[] } & PaginatedResponse;
type PaginatedCountResponse = { count: number } & PaginatedResponse;

function underLimit(value: number, limit?: number) {
  return !limit || value < limit;
}

async function* getAll<T extends PaginatedResponse = PaginatedResponse>(
  fetchFn: (last?: any) => Promise<AxiosResponse<T>>,
) {
  let response = await fetchFn();
  yield response.data;

  while (response.data.last) {
    // eslint-disable-next-line no-await-in-loop
    response = await fetchFn(response.data.last);
    yield response.data;
  }
}

export async function getAllItems<
  I = any,
  T extends PaginatedItemsResponse<I> = PaginatedItemsResponse<I>,
>(fetchFn: (last?: any) => Promise<AxiosResponse<T>>, limit?: number) {
  const items = getAll(fetchFn);

  let result: I[] = [];
  let next = await items.next();

  while (!next.done && next.value && underLimit(result.length, limit)) {
    result = result.concat(next.value.items);
    // eslint-disable-next-line no-await-in-loop
    next = await items.next();
  }

  return result;
}

export async function getCount<
  T extends PaginatedCountResponse = PaginatedCountResponse,
>(fetchFn: (last?: any) => Promise<AxiosResponse<T>>, limit?: number) {
  const items = getAll(fetchFn);

  let result = 0;
  let next = await items.next();

  while (!next.done && next.value && underLimit(result, limit)) {
    result += next.value.count;
    // eslint-disable-next-line no-await-in-loop
    next = await items.next();
  }

  return result;
}
