import BrandUserApiService from './brand-user-api-service';

import { actions as userActions } from '../user/user-reducer';
import { actions } from './brand-user-reducer';
import { Thunk } from '../types';

const brandUserApiService = new BrandUserApiService();

export const { clear } = actions;

export const updateBusiness =
  (userId: string, data: any): Thunk =>
  async dispatch => {
    dispatch(actions.updateBusiness());

    try {
      const response = await brandUserApiService.updateBusiness(userId, data);
      const { businessInformation } = response.data.items[0];
      dispatch(actions.updateBusinessSuccess(businessInformation));
    } catch (error) {
      dispatch(actions.updateBusinessError({ error }));
    }
  };

export const getInvoices =
  (brandId: string): Thunk =>
  async dispatch => {
    dispatch(actions.getInvoices());

    try {
      const response = await brandUserApiService.getInvoices(brandId);

      dispatch(actions.getInvoicesSuccess(response.data.items));
    } catch (error) {
      dispatch(actions.getInvoicesError({ error }));
    }
  };

export const completeStep =
  (userId: string, steps: any, completedStep: string): Thunk =>
  async dispatch => {
    dispatch(actions.completeStep());

    try {
      const response = await brandUserApiService.completeStep(
        userId,
        steps,
        completedStep,
      );

      const user = response.data.items[0];

      dispatch(userActions.getUserSuccess(user));
      dispatch(actions.completeStepSuccess(user));
    } catch (error) {
      dispatch(userActions.getUserError({ error }));
      dispatch(actions.completeStepError({ error }));
    }
  };
export const updateSteps =
  (userId: string, steps: any): Thunk =>
  async dispatch => {
    dispatch(actions.updateSteps());

    try {
      const response = await brandUserApiService.updateSteps(userId, steps);

      const user = response.data.items[0];

      dispatch(userActions.getUserSuccess(user));
      dispatch(actions.updateStepsSuccess(user));
    } catch (error) {
      dispatch(userActions.getUserError({ error }));
      dispatch(actions.updateStepsError({ error }));
    }
  };
