import styled from 'styled-components';
import { colors } from '../../../../../theme';

interface OnboardingCursorProps {
  size: number;
  startX?: number;
  startY?: number;
  endX?: number;
  endY?: number;
}
export const OnboardingCursorCustom = styled.div<OnboardingCursorProps>`
  position: absolute;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
  background: ${colors.pink};
  z-index: 1050;
  top: ${({ startY }) => startY}px;
  left: ${({ startX }) => startX}px;
  animation: move 2s 0.5s, pulse 1s 2.5s;
  animation-fill-mode: forwards;

  @keyframes move {
    80% {
      top: ${({ endY }) => endY}px;
      left: ${({ endX }) => endX}px;
    }
    100% {
      top: ${({ endY }) => endY}px;
      left: ${({ endX }) => endX}px;
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 ${colors.pink};
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
`;
