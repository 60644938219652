import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';

import emailIcon from './icons/email.svg';
import { EmailSentContainer, EmailSentMessage, Title } from './styled/sign-up';
import useRedirect from '../../hooks/use-redirect';
import useHandleCognitoError from '../../hooks/use-handle-cognito-error';

interface VerifyEmailProps {
  code: string;
  email: string;
}

export const alreadyConfirmedMessage =
  'User cannot be confirmed. Current status is CONFIRMED';

const VerifyEmail = ({ email, code }: VerifyEmailProps) => {
  const handleCognitoError = useHandleCognitoError();
  const { t } = useTranslation();
  const redirect = useRedirect();
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        await Auth.confirmSignUp(email, code);
        setVerified(true);
      } catch (error) {
        if (
          error instanceof Error &&
          error.message === alreadyConfirmedMessage
        ) {
          setVerified(true);
          return;
        }

        handleCognitoError(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EmailSentContainer>
      <img alt="Email" src={emailIcon} />
      <Title>{t('signUp.emailVerify.title')}</Title>
      <EmailSentMessage>
        {verified
          ? t('signUp.emailVerify.verified')
          : t('signUp.emailVerify.verifying')}
      </EmailSentMessage>
      <Button
        data-testid="sign-in"
        disabled={!verified}
        block
        type="primary"
        onClick={() => redirect('/')}
      >
        {t('signIn.submit')}
      </Button>
    </EmailSentContainer>
  );
};

export default VerifyEmail;
