import { Area } from 'react-easy-crop/types';
import { CroppedImages, UploadedImage } from '../CroppedImageUpload';

export function getImageUrl({ originFileObj }: UploadedImage) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(originFileObj);
    reader.onload = () =>
      typeof reader.result === 'string' && resolve(reader.result);
    reader.onerror = () => reject(new Error('something bad happened'));
  });
}

export function createImage(url: string) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.onload = () => resolve(image);
    image.onerror = error => reject(error);
    image.src = url;
  });
}

function createRoundShape(
  context: CanvasRenderingContext2D,
  croppedArea: Area,
) {
  context.globalCompositeOperation = 'destination-in';
  context.beginPath();
  context.arc(
    croppedArea.width / 2 + croppedArea.x,
    croppedArea.height / 2 + croppedArea.y,
    croppedArea.height / 2,
    0,
    Math.PI * 2,
  );
  context.closePath();
  context.fill();

  return context;
}

function createCroppedImage(
  uploadedImage: UploadedImage,
  croppedArea: Area,
  shape: keyof CroppedImages,
) {
  const canvas = document.createElement('canvas');
  let context = canvas.getContext('2d');

  const { image, type } = uploadedImage;

  if (!context || !image) {
    return undefined;
  }

  const isRounded = shape === 'rounded';

  const {
    x: cropX,
    y: cropY,
    width: cropWidth,
    height: cropHeight,
  } = croppedArea;

  canvas.width = image.width;
  canvas.height = image.height;

  context.drawImage(image, 0, 0);

  if (isRounded) {
    context = createRoundShape(context, croppedArea);
  }

  const data = context.getImageData(cropX, cropY, cropWidth, cropHeight);

  canvas.width = cropWidth;
  canvas.height = cropHeight;

  context.putImageData(data, 0, 0);

  return canvas.toDataURL(isRounded ? 'image/png' : type);
}

export function getCroppedImages(
  uploadedImage: UploadedImage,
  croppedArea: Area,
) {
  return {
    squared: createCroppedImage(uploadedImage, croppedArea, 'squared'),
    rounded: createCroppedImage(uploadedImage, croppedArea, 'rounded'),
  };
}
