import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Radio } from 'antd';

import { GoLiveFormProps } from '.';
import { goLiveCountries } from '../../utils/countries';
import { Container, RadioGroup } from '../account/styled/region-form';
import { Title } from './styled';
import { ReactComponent as CAN } from './assets/flags/CAN.svg';
import { ReactComponent as FIN } from './assets/flags/FIN.svg';
import { ReactComponent as GBR } from './assets/flags/GBR.svg';
import { ReactComponent as IRL } from './assets/flags/IRL.svg';
import { ReactComponent as NOR } from './assets/flags/NOR.svg';
import { ReactComponent as SWE } from './assets/flags/SWE.svg';
import { ReactComponent as USA } from './assets/flags/USA.svg';
import { ReactComponent as ARE } from './assets/flags/ARE.svg';
import { GoLiveModel } from './use-go-live-form-state';
import SubForm from './SubForm';

const imgs: any = { CAN, FIN, GBR, IRL, NOR, SWE, USA, ARE };

const RegionForm = ({ onClick }: GoLiveFormProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<GoLiveModel>();

  const { t } = useTranslation();
  const { t: tGoLiveRegion } = useTranslation('common', {
    keyPrefix: 'account.goLive.steps.region',
  });

  return (
    <SubForm onClick={onClick}>
      <Title>{tGoLiveRegion('title')}</Title>
      <Container
        errors={errors}
        customHelp={[
          {
            type: 'required',
            message: tGoLiveRegion('validation'),
          },
        ]}
        controller={{
          name: 'countryCode',
          render: ({ field }) => (
            <RadioGroup {...field}>
              {goLiveCountries.map(({ code }) => {
                const Flag = imgs[code];
                return (
                  <Radio.Button value={code} key={code} data-testid={code}>
                    <Flag
                      style={{
                        position: 'relative',
                        top: '6px',
                        marginRight: '14px',
                        marginBottom: '-1px',
                      }}
                    />
                    {/* TODO Fix 'any' below */}
                    {t(`countries.${code}` as any)}
                  </Radio.Button>
                );
              })}
            </RadioGroup>
          ),
          rules: { required: true },
          control,
        }}
      />
    </SubForm>
  );
};

export default RegionForm;
