import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Alert, Input, Switch } from 'antd';

import { AccountStatusTypesEnum } from '@fidel.uk/types/lib/account';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { toggleAccountLock } from '../../../../store/account/account-actions';
import InlineForm from '../../../../components/inline-form';
import { AccountLockedContainer } from '../../styled/account-lock';
import Spin from '../../../../components/spin';

interface AccounLockForm {
  reason?: string;
  isAccountLocked: boolean;
}

const AccountLock = () => {
  const { t } = useTranslation('common', {
    keyPrefix: 'account.contract.accountLock.form',
  });
  const dispatch = useAppDispatch();

  const { accountDetails, loading, error } = useAppSelector(
    state => ({
      accountDetails: state.account.details,
      loading: state.account.loading,
      error: state.account.error,
    }),
    shallowEqual,
  );

  const {
    control,
    formState: { errors, isDirty },
    watch,
    resetField,
    handleSubmit,
  } = useForm<AccounLockForm>({
    mode: 'onChange',
    defaultValues: useMemo(
      () => ({
        isAccountLocked:
          accountDetails?.status === AccountStatusTypesEnum.FULLY_BLOCKED,
        reason: undefined,
      }),
      [accountDetails?.status],
    ),
  });

  const actualAccountStatus =
    accountDetails?.status || AccountStatusTypesEnum.ACTIVE;

  function onSubmit({ reason, isAccountLocked }: AccounLockForm) {
    dispatch(
      toggleAccountLock({
        reason,
        status: isAccountLocked
          ? AccountStatusTypesEnum.FULLY_BLOCKED
          : AccountStatusTypesEnum.ACTIVE,
      }),
    );
    resetField('reason');
  }

  const isAccountLocked = watch('isAccountLocked');

  const currentValue = isAccountLocked
    ? AccountStatusTypesEnum.FULLY_BLOCKED
    : AccountStatusTypesEnum.ACTIVE;

  const hasLockChanges = currentValue !== actualAccountStatus;

  return loading ? (
    <Spin />
  ) : (
    <>
      <InlineForm
        onSubmit={handleSubmit(onSubmit)}
        loading={loading}
        disabled={!hasLockChanges || !watch('reason')}
        error={error}
      >
        <AccountLockedContainer>
          <InlineForm.ItemController
            label={t('accountLock')}
            errors={errors}
            controller={{
              name: 'isAccountLocked',
              render: ({ field: { value, onChange, ...rest } }) => (
                <Switch
                  {...rest}
                  defaultChecked={
                    actualAccountStatus === AccountStatusTypesEnum.FULLY_BLOCKED
                  }
                  onChange={(checked: boolean) => onChange(checked)}
                  checked={value}
                  data-testid="account-locked-switch"
                />
              ),
              control,
            }}
          />
        </AccountLockedContainer>

        <InlineForm.ItemController
          label={t('reason')}
          errors={errors}
          controller={{
            name: 'reason',
            render: ({ field }) => (
              <Input
                {...field}
                placeholder={t('reasonPlaceholder')}
                disabled={!hasLockChanges}
              />
            ),
            control,
            rules: {
              required: true,
            },
          }}
        />

        {isDirty && hasLockChanges && (
          <Alert
            message={isAccountLocked ? t('lockWarning') : t('unlockWarning')}
            type="warning"
            showIcon
            style={{ marginTop: '1rem' }}
          />
        )}
      </InlineForm>
    </>
  );
};

export default AccountLock;
