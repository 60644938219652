import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Row, Typography } from 'antd';
import { get } from 'lodash';
import { shallowEqual } from 'react-redux';

import { getCards } from '../../store/cards/cards-actions';
import { selectProgramsAsList } from '../../store/programs/programs-selectors';
import { selectEndpoints, streamEndpoints } from './endpoints';
import { ReactComponent as ExportIcon } from '../../assets/export.svg';
import { Triangle } from '../../components/icons';
import { Endpoint } from './types';
import { colors } from '../../theme';
import LinkComponent from '../../components/link';
import Spin from '../../components/spin';
import EndpointSelector from './components/endpoint-selector';
import Payload from './components/payload';
import RequestConfig from './components/request-config';
import {
  EndpointWrapper,
  EndpointText,
  FullHeightLayout,
  HeadingWithBorder,
  Method,
  PlaygroundCol,
  PlaygroundContent,
  SendButton,
  EndpointTitle,
  ReferenceButton,
  EndpointDescription,
  CardNumber,
} from './styled';
import memoNoProps from '../../utils/memo-no-props';
import useSelectedProgram from '../../hooks/use-selected-program';
import { OnboardingTourContext } from '../../components/onboarding-tour/onboarding-provider';
import http from '../../services/http-service';
import { PlaygroundForm, defaultValues, defaultProgramType } from './form';
import { useSetStatus } from '../../hooks/use-status';
import { referenceBaseUrl } from './endpoints/endpoints-helpers';
import Intro from './components/intro';
import { ProgramType } from '../../store/programs/programs-reducer';
import createPersistedState from '../../hooks/create-persisted-state';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

interface TestCard {
  scheme: string;
  number: string;
}

const testCards: TestCard[] = [
  {
    scheme: 'Visa',
    number: '4444000000004***',
  },
  {
    scheme: 'Mastercard',
    number: '5555000000005***',
  },
  {
    scheme: 'Amex',
    number: '3400000000003**',
  },
];

const { Paragraph } = Typography;

interface PlaygroundLocalStorage {
  [accountId: string]: {
    programType: ProgramType;
  };
}

const usePlaygroundStorage = createPersistedState<PlaygroundLocalStorage>(
  'FIDEL_PLAYGROUND',
  {},
);

const Playground = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [playgroundStorage, setPlaygroundStorage] = usePlaygroundStorage();

  const { setSelectedProgramId } = useSelectedProgram();

  const programsList = useAppSelector(selectProgramsAsList)();
  const { cards, details, locations } = useAppSelector(
    state => ({
      cards: state.cards.cards,
      details: state.account.details,
      locations: state.locations.locations,
    }),
    shallowEqual,
  );

  const [payload, setPayload] = useState<Record<string, any>>({});
  const [reqUrl, setReqUrl] = useState('');
  const [resStatus, setResStatus] = useState<string>();
  const [resBody, setResBody] = useState('');
  const [loading, setLoading] = useState(false);
  const { enabled: onboardingEnabled } = useContext(OnboardingTourContext);
  const [selectedEndpoint, setEndpoint] = useState<Endpoint | 'intro'>(
    selectEndpoints.cards.endpointsList.create,
  );
  const { setStatus } = useSetStatus();

  const accountId = details?.id;

  const form = useForm<PlaygroundForm>({
    mode: 'onBlur',
    defaultValues: {
      ...defaultValues,
      programType: accountId
        ? playgroundStorage[accountId]?.programType || defaultProgramType
        : defaultProgramType,
    },
  });
  const { control, setValue } = form;

  const values = useWatch({ control });
  const { programId } = values;
  const programType = values.programType || defaultProgramType;

  const endpoints =
    programType === 'transaction-select' ? selectEndpoints : streamEndpoints;

  useEffect(() => {
    setEndpoint('intro');
    setValue('programId', undefined);

    if (accountId)
      setPlaygroundStorage({
        ...playgroundStorage,
        [accountId]: {
          ...playgroundStorage[accountId],
          programType,
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, programType, setValue]);

  useEffect(() => {
    if (onboardingEnabled) {
      if (programsList.length > 0) {
        setValue(
          'programId',
          programsList.filter(
            program => program.type !== 'transaction-stream',
          )?.[0].id,
        );
      }
      if (Object.keys(locations).length > 0) {
        setValue(
          'locationId',
          get(locations, `[${Object.keys(locations)[0]}].items[0].id`) as any,
        );
      }
      if (cards.length > 0) {
        setValue('cardId', cards[0].id);
      }

      setEndpoint(endpoints.transactions.endpointsList.create);
    }
  }, [onboardingEnabled, endpoints, programsList, locations, cards, setValue]);

  const updateRequest = useCallback(() => {
    if (selectedEndpoint === 'intro') return;

    const { endpoint } = selectedEndpoint;
    const { formatPayload, generateUrl } = endpoint;
    const defaultPayload = formatPayload && formatPayload(values);
    const url = generateUrl(values);

    setPayload(defaultPayload ? JSON.parse(defaultPayload) : {});
    setReqUrl(url || '');
  }, [values, selectedEndpoint]);

  useEffect(() => {
    updateRequest();
    setResStatus('');
    setResBody('');
  }, [selectedEndpoint, programId, updateRequest]);

  useEffect(() => {
    updateRequest();
  }, [updateRequest]);

  function handleSuccess(res: any) {
    const { status, data } = res;
    setResStatus(status);
    setResBody(JSON.stringify(data, null, 2));

    const { cardId } = values;

    if (cardId && programId) dispatch(getCards({ programId }));
    if (status === 204) setValue('cardId', undefined);

    switch (data.resource) {
      case '/v1/transactions/test':
      case '/v1d/transactions/test':
        setSelectedProgramId(programId);
        setStatus('newTransactionPlayground');
    }
  }

  function handleError(err: any) {
    const defaultError = { status: t('error.invalidEndpoint') };
    const { status, data } = err.response || defaultError;
    setResStatus(status);
    setResBody(JSON.stringify(data, null, 2));
  }

  async function handleRequest() {
    if (selectedEndpoint === 'intro') return;

    setLoading(true);
    try {
      const { endpoint } = selectedEndpoint;
      const response = await http[endpoint.method](
        endpoint.generateUrl(values),
        payload,
      );
      handleSuccess(response);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <FormProvider {...form}>
      <HeadingWithBorder>{t('playground.title')}</HeadingWithBorder>
      <FullHeightLayout>
        <EndpointSelector
          selectedEndpoint={selectedEndpoint}
          setEndpoint={setEndpoint}
        />
        <PlaygroundContent>
          <Row gutter={40} style={{ height: '100%' }}>
            {selectedEndpoint === 'intro' ? (
              <Intro programType={programType} />
            ) : (
              <>
                <PlaygroundCol span={11}>
                  <EndpointTitle>
                    {/* TODO Fix 'any' below in OFF-1405 */}
                    {t(
                      `playground.endpoint.${selectedEndpoint.i18nKey}.title` as any,
                    )}
                    <ReferenceButton size="small">
                      <LinkComponent
                        to={`${referenceBaseUrl}${selectedEndpoint.referenceLink}`}
                      >
                        {t('playground.reference')} <ExportIcon />
                      </LinkComponent>
                    </ReferenceButton>
                  </EndpointTitle>
                  <EndpointWrapper>
                    <Method
                      method={selectedEndpoint.endpoint?.method || 'post'}
                    >
                      {selectedEndpoint.endpoint?.method.toUpperCase()}
                    </Method>
                    <EndpointText>{reqUrl}</EndpointText>
                  </EndpointWrapper>
                  <EndpointDescription>
                    {/* TODO Fix 'any' on OFF-1405 */}
                    <Trans
                      i18nKey={
                        `playground.endpoint.${selectedEndpoint.i18nKey}.description.${programType}` as any
                      }
                      components={{ paragraph: <p />, code: <code /> }}
                    />
                    {selectedEndpoint.i18nKey === 'cards.create' &&
                      testCards.map(({ scheme, number }) => (
                        <CardNumber key={scheme}>
                          {scheme}: <code>{number}</code>
                        </CardNumber>
                      ))}
                  </EndpointDescription>
                  <RequestConfig
                    endpoint={selectedEndpoint}
                    programType={programType}
                  />
                  {loading && <Spin />}
                  {!loading && resStatus && (
                    <Paragraph
                      strong
                      style={{
                        color:
                          parseInt(resStatus, 10) < 300
                            ? colors.green
                            : colors.red,
                      }}
                    >
                      {t('playground.resStatus')}: {resStatus}
                    </Paragraph>
                  )}
                </PlaygroundCol>
                <PlaygroundCol span={13}>
                  <Payload
                    title={t('playground.payload')}
                    body={payload}
                    onChange={json => {
                      try {
                        setPayload(JSON.parse(json));
                      } catch (error) {
                        // do nothing
                      }
                    }}
                    data-onboarding-target="playground-request-code"
                  />
                  <Payload
                    title={t('playground.resBody')}
                    body={resBody || ''}
                    readOnly
                  />
                </PlaygroundCol>
                <SendButton
                  onClick={handleRequest}
                  data-onboarding-target="playground-run-button"
                >
                  {loading ? (
                    <Spin type="button" />
                  ) : (
                    <>
                      {t('playground.send')} <Triangle />
                    </>
                  )}
                </SendButton>
              </>
            )}
          </Row>
        </PlaygroundContent>
      </FullHeightLayout>
    </FormProvider>
  );
};

export default memoNoProps(Playground);
