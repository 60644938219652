import styled from 'styled-components';
import { Table } from 'antd';

import { colors, hiddenScrollbar } from '../../../theme';

export const KeysTable = styled(Table)`
  .ant-table-content {
    ${hiddenScrollbar}
  }
  .ant-table.ant-table-bordered {
    .ant-table-container {
      border: 1px solid ${colors.grey2};
      thead {
        background: ${colors.lightgrey};

        th {
          padding: 10px 20px;
          font-size: 12px;
          font-weight: normal;
          color: ${colors.grey4};
          border-bottom-width: 1px;
          border-right: none !important;
        }
      }

      tr {
        max-height: 42px;
        font-size: 14px;
        td {
          border-right: none !important;
        }
        :hover {
          td {
            background: white;
          }
        }
        td:first-of-type {
          text-transform: capitalize;
        }
      }
      tr:last-of-type {
        td {
          border-bottom: none;
        }
      }
    }
  }
`;
