import moment from 'moment';

import { useAppSelector } from '../store/hooks';

const OFFERS_API_VERSION = '2019-03-05';

export default function useHasOffersApi() {
  const accountApiVersion = useAppSelector(
    state => state.account.details?.apiVersion,
  );

  return (
    !!accountApiVersion &&
    moment(accountApiVersion).isSameOrAfter(OFFERS_API_VERSION)
  );
}
