import React, { useState } from 'react';
import styled from 'styled-components';
import { Input, Modal as AntdModal, Typography } from 'antd';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Form from '../../../../components/form';
import { RejectOfferButton } from '../styled';
import { colors } from '../../../../theme';

function RejectAction({
  onClick,
  isLoading,
}: {
  onClick: (reason: string) => void;
  isLoading: boolean;
}) {
  const { t } = useTranslation(['common', 'offers']);
  const [modalVisible, setModalVisible] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<{ reason: string }>({
    mode: 'onBlur',
  });

  const { reason } = watch();

  function onSubmit() {
    onClick(reason);
    setModalVisible(false);
  }

  return (
    <>
      <RejectOfferButton
        data-testid="reject-offer"
        disabled={isLoading}
        onClick={e => {
          e.stopPropagation();
          setModalVisible(true);
        }}
      >
        {t('common:moderator.review.reject')}
      </RejectOfferButton>
      <div
        role="none"
        onClick={e => e.stopPropagation()}
        onKeyDown={e => e.stopPropagation()}
      >
        <Modal
          centered
          closable={false}
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          okText={t('common:confirm.confirm')}
          width={400}
          onOk={handleSubmit(onSubmit)}
        >
          <Typography.Title style={{ fontSize: '18px', fontWeight: 400 }}>
            {t('offers:reject.title')}
          </Typography.Title>
          <Typography.Paragraph type="secondary">
            {t('offers:reject.infoMessage')}
          </Typography.Paragraph>
          <Form.ItemController
            errors={errors}
            validateCharacters
            controller={{
              name: 'reason',
              render: ({ field }) => (
                <Input.TextArea
                  {...field}
                  showCount
                  maxLength={60}
                  autoSize={{ minRows: 4 }}
                  style={{ resize: 'none', marginTop: '8px' }}
                />
              ),
              rules: { required: true },
              control,
            }}
          />
        </Modal>
      </div>
    </>
  );
}

const Modal = styled(AntdModal)`
  .ant-modal-body {
    padding: 30px 24px;

    .ant-modal-content {
      margin-top: 34px;
      font-size: 14px;
    }
  }

  .ant-modal-footer {
    margin-top: 0;
    width: 100%;
    text-align: right;
    padding: 20px 24px;
    background: ${colors.lightgrey};
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top: none;

    .ant-btn {
      height: 32px;
      &:first-of-type {
        background: none;
        border: none;
        font-weight: normal;
        color: ${colors.grey5};
        &:hover {
          background: ${colors.lightgrey2};
        }
      }
    }
  }
`;

export default RejectAction;
