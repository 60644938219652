import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { Modal, Typography } from 'antd';

import Avatar from '../../../components/avatar';
import {
  deleteBrandUser,
  getBrandUsers,
} from '../../../store/brands/brands-actions';
import { selectProgramsAsList } from '../../../store/programs/programs-selectors';
import { colors } from '../../../theme';
import {
  BrandUserDetail,
  BrandViewTitle,
  DeleteButton,
  StatusInfo,
} from '../styled/brand-view';
import { Brand } from '../../../store/brands/brands-model';
import { ProgramWithBrandUsers } from '../types';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

function addBrandUsers(users: any[], programs: Record<string, any>) {
  const usersByProgram: Record<string, ProgramWithBrandUsers> = {};
  users.forEach(({ programId, ...rest }) => {
    if (!usersByProgram[programId])
      usersByProgram[programId] = {
        ...programs.find(({ id }: any) => id === programId),
        brandUsers: [{ ...rest }],
      } as ProgramWithBrandUsers;
    else usersByProgram[programId].brandUsers.push({ ...rest });
  });
  return usersByProgram;
}

const BrandView = ({ brand }: { brand: Brand }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'brands']);
  const { brandUsers, isLive, loading } = useAppSelector(
    state => ({
      brandUsers: state.brands.brandUsers,
      isLive: state.live,
      loading: state.brands.loading,
    }),
    shallowEqual,
  );
  const programs = useAppSelector(selectProgramsAsList)();
  const [programsWithBrandUsers, setProgramsWithBrandUsers] = useState<
    Record<string, ProgramWithBrandUsers>
  >({});

  useEffect(() => {
    if (isLive) {
      dispatch(getBrandUsers(brand.id));
    }
    return () => {
      setProgramsWithBrandUsers({});
    };
  }, [brand.id, dispatch, isLive]);

  useEffect(() => {
    setProgramsWithBrandUsers(addBrandUsers(brandUsers, programs));
  }, [brandUsers, programs]);

  if (loading) return null;

  return (
    <div data-testid="brand-view">
      {Object.keys(programsWithBrandUsers).map(programId => {
        const { name, brandUsers: users } = programsWithBrandUsers[programId];
        const title = (
          <BrandViewTitle>
            <Avatar
              size={24}
              color={{ border: 'lightgrey2' }}
              style={{ marginRight: '10px' }}
            >
              {name || programId}
            </Avatar>
            <Typography.Title level={4}>{name || programId}</Typography.Title>
          </BrandViewTitle>
        );

        return (
          <div key={programId}>
            {title}
            {users.map(({ created, email, nameFirst, nameLast, status }) => (
              <BrandUserDetail key={created}>
                <div style={{ fontWeight: 500 }}>
                  {nameFirst} {nameLast}
                </div>
                <div style={{ color: colors.grey4 }}>{email}</div>
                <StatusInfo status={status}>
                  {t(`brands:status.${status}`)}
                </StatusInfo>
                <DeleteButton
                  size="small"
                  type="link"
                  danger
                  onClick={() =>
                    Modal.confirm({
                      title: t('brands:brandUsers.delete.title'),
                      content: t('brands:brandUsers.delete.message'),
                      onOk: () => {
                        dispatch(deleteBrandUser(brand.id, created));
                      },
                      okButtonProps: { danger: true },
                      okText: t('common:confirm.delete'),
                      maskClosable: true,
                      icon: null,
                    })
                  }
                >
                  {t('brands:detail.delete')}
                </DeleteButton>
              </BrandUserDetail>
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default BrandView;
