import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Input } from 'antd';
import Form from '../../../../../components/form';
import { CreateUpdateOfferFormModel } from '../../../types';
import createUpdateOfferRules from '../../validation';

interface OfferNameProps {
  disabled: boolean;
}

export default function OfferName({ disabled }: OfferNameProps) {
  const { t } = useTranslation('offers');

  const {
    control,
    formState: { errors },
  } = useFormContext<CreateUpdateOfferFormModel>();

  return (
    <Form.ItemController
      label={t('fields.offer.label')}
      errors={errors}
      validateCharacters
      controller={{
        name: 'offerName',
        render: ({ field }) => (
          <Input
            {...field}
            disabled={disabled}
            placeholder={t('fields.offer.placeholder')}
          />
        ),
        rules: createUpdateOfferRules.offerName,
        control,
      }}
    />
  );
}
