import { Params } from '../types';

export const referenceBaseUrl = 'https://reference.fidel.uk/reference/';
export const docsBaseUrl = 'https://fidelapi.com/docs/';

export const createCarddPayload = () => `{
  "number": "4444000000004001",
  "expMonth": 10,
  "expYear": 2030,
  "countryCode": "GBR",
  "termsOfUse": true
}`;

export const createSelectTransactionPayload = ({
  cardId,
  locationId,
}: Params = {}) => `{
  "amount": 10,
  "cardId": "${cardId || 'card_id'}",
  "locationId": "${locationId || 'location_id'}"
}`;

export const createStreamTransactionPayload = ({ cardId }: Params = {}) => `{
  "amount": 10,
  "billingAmount": 8,
  "billingCurrency": "EUR",
  "brandName": "Custom Brand",
  "cardId": "${cardId || 'card_id'}",
  "city": "Lisbon",
  "countryCode": "PT",
  "currency": "USD",
  "merchantDescriptor": "Purchase",
  "merchantCategoryCode":"1234",
  "postcode": "1234-567",
  "state": ""
}`;
