import styled from 'styled-components';

export const BottomElement = styled.div`
  display: flex;
  position: sticky;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 42px;
  z-index: 3;
  background: white;
`;
