import React from 'react';
import { Slider as AntSlider, SliderSingleProps } from 'antd';
import styled from 'styled-components';
import { colors } from '../../theme';
import { ReactComponent as PlusIcon } from '../../assets/plus.svg';
import { ReactComponent as MinusIcon } from '../../assets/minus.svg';

interface SliderProps extends SliderSingleProps {
  marginTop?: number;
  hasIcons?: boolean;
}

const SliderWrapper = styled.span<{
  $marginTop?: number;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 24px;
  margin-top: ${({ $marginTop }) => `${$marginTop}px` ?? '0'};
`;

const StyledAntSlider = styled(AntSlider)<{
  $hasIcons: boolean;
}>`
  width: 100%;
  margin: ${({ $hasIcons }) => ($hasIcons ? '0 8px' : '0')};
  padding: 5px 0;

  .ant-slider-rail {
    height: 2px;
  }

  &.ant-slider-disabled {
    .ant-slider-handle {
      border-color: ${colors.white} !important;
    }
  }
`;

const elementsStyles = (disabled: boolean) => {
  const backgroundColor = colors[disabled ? 'grey4' : 'fidelBlue600'];

  return {
    track: {
      height: '2px',
      borderRadius: '2px',
      backgroundColor,
    },
    handle: {
      width: '24px',
      height: '24px',
      marginTop: '-11px',
      backgroundColor,
      border: `4px solid ${colors.white}`,
    },
    icon: { color: colors.grey4 },
  };
};

const Slider = ({ marginTop, hasIcons = true, ...props }: SliderProps) => {
  const { icon, track, handle } = elementsStyles(!!props.disabled);

  return (
    <SliderWrapper $marginTop={marginTop}>
      {hasIcons && <MinusIcon style={icon} />}
      <StyledAntSlider
        $hasIcons={hasIcons}
        trackStyle={track}
        handleStyle={handle}
        {...props}
      />
      {hasIcons && <PlusIcon style={icon} />}
    </SliderWrapper>
  );
};

export default Slider;
