import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { shallowEqual } from 'react-redux';
import { Trans } from 'react-i18next';
import { Button } from 'antd';

import {
  getInvoices,
  getPaymentMethods,
} from '../../store/account/account-actions';
import { selectPaymentFailedInvoices } from '../../store/account/account-selectors';
import { StyledAlert } from './styled';
import { roleCheck } from '../role-check';
import useRedirect from '../../hooks/use-redirect';
import { PaymentMethod } from '../../store/account/account-model';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

type AlertMessage =
  | 'failedPayment'
  | 'reenterPayment'
  | 'cardToExpire'
  | 'maintenance';

interface AlertProps {
  maintenance?: {
    date: string;
    from: string;
    to: string;
  };
}

function isCardToExpire(card: PaymentMethod | undefined): boolean {
  if (!card) return false;

  const expDate = moment({
    day: 1,
    month: card.expMonth - 1,
    year: card.expYear,
  });

  const daysToExpire = expDate.diff(moment(), 'days');

  return daysToExpire <= 30 && daysToExpire >= -30;
}

const Alert = ({ maintenance }: AlertProps) => {
  const redirect = useRedirect();
  const dispatch = useAppDispatch();
  const [message, setMessage] = useState<AlertMessage | null>(null);
  const failedInvoices = useAppSelector(selectPaymentFailedInvoices);
  const { hasLiveAgreement, invoices, paymentMethods, requiresStripe3ds } =
    useAppSelector(
      state => ({
        hasLiveAgreement: !!state.account.details?.liveAgreement,
        invoices: state.account.invoices.entities,
        paymentMethods: state.account.paymentMethods.entities,
        requiresStripe3ds: state.account.details?.requiresStripe3ds,
      }),
      shallowEqual,
    );

  const defaultPaymentCard = (paymentMethods || []).filter(
    card => card.isDefault,
  )[0];

  useEffect(() => {
    if (!invoices) dispatch(getInvoices());
    if (hasLiveAgreement && !paymentMethods) dispatch(getPaymentMethods());
  }, [hasLiveAgreement, invoices, paymentMethods, dispatch]);

  useEffect(() => {
    setMessage(null);
    if ((failedInvoices || []).length > 0) {
      setMessage('failedPayment');
    }

    if (requiresStripe3ds) {
      setMessage('reenterPayment');
    }

    if (isCardToExpire(defaultPaymentCard)) {
      setMessage('cardToExpire');
    }

    if (maintenance) {
      setMessage('maintenance');
    }
  }, [failedInvoices, requiresStripe3ds, defaultPaymentCard, maintenance]);

  if (!message) return null;

  const { date, from, to } = maintenance || {};

  return (
    <StyledAlert
      type="info"
      showIcon={false}
      banner
      message={
        <Trans
          i18nKey={`alert.${message}`}
          values={{
            date,
            from,
            to,
          }}
          components={{
            'cta-link': (
              <Button
                data-testid="alert-cta"
                type="link"
                onClick={() => {
                  redirect('/account/billing', {
                    state: ['reenterPayment', 'cardToExpire'].some(
                      key => key === message,
                    )
                      ? { addPayment: true }
                      : null,
                  });
                }}
              />
            ),
          }}
        />
      }
    />
  );
};

export default roleCheck(
  { disabledForModerator: true, requiresAccountAdmin: true },
  Alert,
);
