import React from 'react';
import { InfoCircleFilled } from '@ant-design/icons';
import { Trans } from 'react-i18next';
import { InfoMessage } from '../../styled/add-locations-form';
import { colors } from '../../../../../theme';
import { BoldText } from '../../../../../components/styled/light-bold-text';
import { Location } from '../../../../../store/locations/locations-model';
import { SelectedRowsType } from '../../../../../hooks/use-selected-rows';

interface LocationsInfoMessageProps {
  numProgramLocations: number;
  numLinkedOfferLocations: number;
  selectedLocations: SelectedRowsType<Location>;
}

export default function LocationsInfoMessage({
  numProgramLocations,
  numLinkedOfferLocations,
  selectedLocations,
}: LocationsInfoMessageProps) {
  const locationsLabel =
    numProgramLocations === numLinkedOfferLocations ||
    selectedLocations.count === numProgramLocations
      ? 'allLocations'
      : 'locationsOutOfTotal';

  return (
    <InfoMessage>
      <InfoCircleFilled style={{ color: colors.fidelBlue600 }} />
      <span>
        <Trans
          i18nKey={`createUpdate.locations.infoBox.${locationsLabel}`}
          ns="offers"
          components={{
            bold: <BoldText />,
          }}
          load
          values={{
            numLocations:
              numLinkedOfferLocations === 0
                ? selectedLocations.count
                : numLinkedOfferLocations,
            numProgramLocations,
          }}
        />
      </span>
    </InfoMessage>
  );
}
