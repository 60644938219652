import React, { useEffect, useRef, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Input } from 'antd';
import { shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';

import * as ProgramActions from '../../../store/programs/programs-actions';
import { Program, ProgramType } from '../../../store/programs/programs-reducer';
import DrawerForm from '../../../components/drawer-form';
import Form from '../../../components/form';
import ChangeProgramIcon from './ChangeProgramIcon';
import SelectProgramType from './SelectProgramType';
import drawerTransition from '../../../components/drawer-transition';
import { programColors } from '../../../theme';
import { Title } from '../styled/select-program-type';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

interface CreateProgramProps {
  visible: boolean;
  onClose: (args?: any) => any;
  program?: Program;
  programType?: ProgramType;
  afterVisibleChange: any;
}

export interface ProgramIconType {
  icon: string;
  iconBackground: string;
}

const CreateUpdateProgram = ({
  visible,
  onClose,
  program,
  programType = 'transaction-select',
  afterVisibleChange,
}: CreateProgramProps) => {
  const { t } = useTranslation(['common', 'programs']);
  const dispatch = useAppDispatch();
  const { live, processing } = useAppSelector(
    state => ({
      live: state.live,
      processing: state.programs.creating,
    }),
    shallowEqual,
  );
  const [programIcon, setProgramIcon] = useState<ProgramIconType>({
    icon: ':wave:',
    iconBackground: programColors.background.red,
  });
  const mode = useRef<'update' | 'create'>(program ? 'update' : 'create');

  const { icon, iconBackground } = programIcon;

  const form = useForm({
    mode: 'onBlur',
    defaultValues: { name: program?.name, programType },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = form;

  const buttonText = program
    ? t('common:confirm.save')
    : t('programs:create.create');

  const title =
    mode.current === 'update'
      ? t('programs:create.editProgram')
      : t('programs:create.createNew');

  const showTypeSelector = mode.current === 'create' && !live;

  function onSubmit({ name, programType: type }: any) {
    const payload = {
      name,
      icon,
      iconBackground,
    };

    if (program) {
      dispatch(ProgramActions.updateProgram(program.id, payload));
      return;
    }

    if (live) {
      dispatch(ProgramActions.createProgram(payload));
    } else {
      dispatch(ProgramActions.createProgram({ ...payload, type }));
    }
  }

  useEffect(() => {
    if (program?.icon && program.iconBackground) {
      setProgramIcon({
        icon: program.icon,
        iconBackground: program.iconBackground,
      });
    }
  }, [program]);

  return (
    <DrawerForm
      title={title}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      confirmText={buttonText}
      processing={processing}
      visible={visible}
      afterVisibleChange={afterVisibleChange}
    >
      <FormProvider {...form}>
        <div style={{ display: 'flex' }}>
          <ChangeProgramIcon
            programIcon={programIcon}
            setProgramIcon={setProgramIcon}
          />
          <Form.ItemController
            label={t('programs:create.label')}
            errors={errors}
            style={{ marginLeft: '24px', flexGrow: 1 }}
            validateCharacters
            controller={{
              name: 'name',
              render: ({ field }) => (
                <Input
                  {...field}
                  data-testid="program-name"
                  placeholder={t('programs:create.placeholder')}
                />
              ),
              rules: {
                minLength: 4,
                maxLength: 50,
                required: true,
              },
              control,
            }}
          />
        </div>
        {showTypeSelector && (
          <>
            <Title>{t('programs:create.type.title')}</Title>
            <SelectProgramType />
          </>
        )}
      </FormProvider>
    </DrawerForm>
  );
};

export default drawerTransition(CreateUpdateProgram);
