import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { actionToError } from '../utils/transform';

interface Permission {
  role: string;
}

interface Invite {
  id: string;
  role: string;
}

export interface User {
  id: string;
  nameFirst: string;
  nameLast: string;
  email: string;
  permission: Permission | Permission[] | undefined;
  invite: Invite | undefined;
}

export interface UsersState {
  loading: boolean;
  inviting: boolean;
  revoking: boolean;
  cancelingUserInvite: boolean;
  error?: any;
  users?: User[];
}

const initialState: UsersState = {
  loading: false,
  inviting: false,
  revoking: false,
  cancelingUserInvite: false,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    clear() {
      return initialState;
    },

    getUsers(state) {
      state.loading = true;
      state.error = undefined;
    },

    getUsersSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.users = action.payload;
    },

    getUsersError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },

    inviteUser(state) {
      state.inviting = true;
    },
    inviteUserSuccess(state) {
      state.inviting = false;
    },
    inviteUserError(state, action: PayloadAction<any>) {
      state.inviting = false;
      state.error = actionToError(action);
    },

    revokeUser(state) {
      state.revoking = true;
    },
    revokeUserSuccess(state) {
      state.revoking = false;
    },
    revokeUserError(state, action: PayloadAction<any>) {
      state.revoking = false;
      state.error = actionToError(action);
    },

    cancelUserInvite(state) {
      state.cancelingUserInvite = true;
    },
    cancelUserInviteSuccess(state) {
      state.cancelingUserInvite = false;
    },
    cancelUserInviteError(state, action: PayloadAction<any>) {
      state.cancelingUserInvite = false;
      state.error = actionToError(action);
    },
  },
});

export const { actions } = usersSlice;
export default usersSlice.reducer;
