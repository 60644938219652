import { AxiosError } from 'axios';
import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import http from '../../../services/http-service';
import { useSetStatus } from '../../../hooks/use-status';
import { parseAxiosError } from '../../../store/utils/transform';
import { Webhook } from './types';
import { ApiResponse } from '../../../types';

async function deleteWebhook(hookIds: string | string[]) {
  const arrayHookIds = Array.isArray(hookIds) ? hookIds : [hookIds];

  await Promise.all(
    arrayHookIds.map(hookId =>
      http.delete<ApiResponse<Webhook>>(`hooks/${hookId}`),
    ),
  );
}

export function useDeleteWebhook(programId: string) {
  const queryClient = useQueryClient();
  const { setStatus } = useSetStatus();

  return useMutation(
    async (id: string) => {
      await deleteWebhook(id);

      return id;
    },
    {
      onSuccess: (id: string) => {
        const cachedData = queryClient.getQueryData<
          InfiniteData<ApiResponse<Webhook>>
        >(['webhooks', programId]);

        const newCachedPages = cachedData?.pages.map(page => {
          const newCachedItems = page.items.filter(
            (item: Webhook) => item.id !== id,
          );
          return { ...page, items: newCachedItems };
        });

        const newData = { ...cachedData, pages: newCachedPages };

        queryClient.setQueryData(['webhooks', programId], newData);
      },
      onError: (error: AxiosError) => {
        const { code, message } = parseAxiosError(error);

        setStatus(code, { message });
      },
      onSettled: () => {
        queryClient.invalidateQueries(['webhooks']);
      },
    },
  );
}
