import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { ReactComponent as SearchIcon } from '../../../assets/search.svg';
import { OverlayProps } from './FilterDropdown';
import {
  ApplyButton,
  ApplyButtonContainer,
  ListItem,
  Search,
  StyledCheckbox,
  StyledList,
  StyledOverlayWrapper,
} from '../styled';
import Form from '../../form';
import { colors } from '../../../theme';
import Ellipsis from '../../ellipsis';

function OverlayMultiple({
  options,
  search,
  setVisible,
  setSelected,
}: Omit<OverlayProps, 'selected' | 'name'>) {
  const { t } = useTranslation('common');
  const { control, watch } = useFormContext();
  const { searchTerm, setSearchTerm, onLoadMore } = search || {};

  const values = watch();

  const selected = Object.entries(values)
    .filter(([_, value]) => value)
    .map(([key]) => key);

  return (
    <StyledOverlayWrapper>
      {search && setSearchTerm && (
        <Search
          name="search"
          autoFocus
          placeholder={t('search')}
          defaultValue={searchTerm}
          prefix={<SearchIcon style={{ color: colors.grey4 }} />}
          data-testid="search-input"
          type="text"
          bordered={false}
          onChange={({ target }) => setSearchTerm(target.value)}
        />
      )}
      <StyledList style={{ border: 'none' }}>
        <InfiniteLoader
          loadMoreItems={() => onLoadMore?.()}
          itemCount={Infinity}
          isItemLoaded={(index: number) => index < options.length}
        >
          {({ onItemsRendered, ref }) => (
            <FixedSizeList
              height={Math.min(192, options.length * 42)}
              width={300}
              itemCount={options.length}
              itemSize={42}
              onItemsRendered={onItemsRendered}
              ref={ref}
            >
              {({ index, style }) => {
                const { id, name } = options[index];
                return (
                  <Form.ItemController
                    key={id}
                    style={{ marginBottom: 0, ...style }}
                    controller={{
                      name: id,
                      control,
                      render: ({ field: { onChange, value, ...rest } }) => (
                        <ListItem $active={!!value}>
                          <StyledCheckbox
                            {...rest}
                            onChange={({ target }) => onChange(target.checked)}
                            checked={value}
                          >
                            <div>
                              <Ellipsis>{name}</Ellipsis>
                            </div>
                          </StyledCheckbox>
                        </ListItem>
                      ),
                    }}
                  />
                );
              }}
            </FixedSizeList>
          )}
        </InfiniteLoader>
      </StyledList>

      <ApplyButtonContainer>
        <ApplyButton
          onClick={() => {
            setSelected(selected);
            setVisible(false);
            setSearchTerm?.('');
          }}
        >
          {`${t('apply')} ${selected.length ? selected.length : ''}`}
        </ApplyButton>
      </ApplyButtonContainer>
    </StyledOverlayWrapper>
  );
}

export default OverlayMultiple;
