import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Typography } from 'antd';
import { useForm } from 'react-hook-form';

import { addVirtualCard } from '../../../../store/account/account-actions';
import drawerTransition, {
  DrawerVisibleProps,
} from '../../../../components/drawer-transition';
import DrawerForm from '../../../../components/drawer-form';
import Form from '../../../../components/form';
import EmailInput from '../../../sign/components/EmailInput';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

interface OfferActivationDrawerProps extends DrawerVisibleProps {
  onClose: () => any;
}

interface CreateVirtualCardForm {
  [index: string]: any;
  name: string;
  email: string;
}

const CreateVirtualCard = ({
  visible,
  afterVisibleChange,
  onClose,
}: OfferActivationDrawerProps) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'account.virtualCard.form',
  });
  const dispatch = useAppDispatch();
  const processing = useAppSelector(state => state.account.virtualCards.adding);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateVirtualCardForm>({
    mode: 'onBlur',
  });

  function onSubmit(values: CreateVirtualCardForm) {
    dispatch(addVirtualCard(values));
  }

  return (
    <DrawerForm
      title={t('title')}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      processing={processing}
      visible={visible}
      afterVisibleChange={afterVisibleChange}
    >
      <Typography.Paragraph type="secondary" style={{ marginBottom: '32px' }}>
        {t('description')}
      </Typography.Paragraph>
      <Form.ItemController
        label={t('cardholder')}
        errors={errors}
        validateCharacters
        controller={{
          name: 'name',
          render: ({ field }) => <Input {...field} />,
          rules: { required: true, minLength: 4, maxLength: 100 },
          control,
        }}
      />
      <EmailInput errors={errors} control={control} />
      <Typography.Paragraph type="secondary">
        {t('disclaimer')}
      </Typography.Paragraph>
    </DrawerForm>
  );
};

export default drawerTransition(CreateVirtualCard);
