import React from 'react';
import { Button, Drawer } from 'antd';
import { useTranslation } from 'react-i18next';

import drawerTransition, {
  DrawerVisibleProps,
} from '../../../../../components/drawer-transition';
import BaseTable from '../../../../../components/tables/BaseTable';
import { programLinkStatuses } from '../../../../../store/offers/offers-model';
import { StatusDescription } from '../../../../locations/styled/sync-locations';
import StatusTag, { Status } from '../../../components/StatusTag';

interface ProgramStatusDescriptionsProps extends DrawerVisibleProps {
  onClose: () => unknown;
}

function ProgramStatusDescriptions({
  onClose,
  visible,
  afterVisibleChange,
}: ProgramStatusDescriptionsProps) {
  const { t } = useTranslation(['common', 'offers']);

  const statuses: Status[] = ['available', ...programLinkStatuses];

  const rows = statuses
    .filter(status => status !== 'pending')
    .map(status => ({
      contents: [
        <StatusTag style={{ marginTop: '14px' }} status={status} />,
        <StatusDescription>
          {t(`offers:status.${status}.description`)}
        </StatusDescription>,
      ],
    }));

  return (
    <Drawer
      title={t('offers:detail.programStatus')}
      onClose={onClose}
      visible={visible}
      closeIcon={null}
      width={440}
      footer={
        <Button
          data-testid="close-descriptions"
          type="primary"
          size="small"
          onClick={() => onClose()}
        >
          {t('common:confirm.done')}
        </Button>
      }
      afterVisibleChange={afterVisibleChange}
    >
      <BaseTable
        columns={[
          {
            heading: t('offers:fields.status.label'),
            verticalAlign: 'flex-start',
          },
          {
            heading: t('offers:description'),
            size: 1.5,
          },
        ]}
        rows={rows}
        padding={false}
        hideScrollIndicators
        stretch
      />
    </Drawer>
  );
}

export default drawerTransition(ProgramStatusDescriptions);
