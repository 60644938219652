import React, { useState } from 'react';
import { Button, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

import WarningRow from '../../../../components/warning-row';
import { ProgramType } from '../../../../store/programs/programs-reducer';
import CreateUpdateProgram from '../../../programs/components/CreateUpdateProgram';
import { EndpointTitle, PlaygroundCol } from '../../styled';
import { MissingProgram } from './styled';
import { selectProgramsAsList } from '../../../../store/programs/programs-selectors';
import { useAppSelector } from '../../../../store/hooks';

const { Paragraph } = Typography;

const Intro = ({ programType }: { programType: ProgramType }) => {
  const { t } = useTranslation('programs');
  const { t: tPlayground } = useTranslation('common', {
    keyPrefix: 'playground.endpoint.intro',
  });
  const programs = useAppSelector(selectProgramsAsList)(programType);
  const loading = useAppSelector(state => state.programs.loading);
  const [showCreateProgram, setShowCreateProgram] = useState(false);

  return (
    <>
      <PlaygroundCol style={{ maxWidth: '460px' }}>
        <EndpointTitle>{tPlayground('title')}</EndpointTitle>
        <Paragraph style={{ fontSize: '14px', marginTop: '24px' }}>
          {tPlayground('common')}
        </Paragraph>
        <Trans
          i18nKey={`playground.endpoint.intro.${programType}`}
          components={[<Paragraph style={{ fontSize: '14px' }} />]}
        />
        <WarningRow>{tPlayground('note')}</WarningRow>
        {!loading && !programs.length && (
          <MissingProgram>
            <Paragraph type="secondary">
              {tPlayground('missingProgram', {
                program: t(`programType.${programType}.label`),
              })}
            </Paragraph>
            <Button
              size="small"
              type="primary"
              onClick={() => setShowCreateProgram(true)}
            >
              {tPlayground('createProgram')}
            </Button>
          </MissingProgram>
        )}
      </PlaygroundCol>
      <CreateUpdateProgram
        visible={showCreateProgram}
        onClose={() => setShowCreateProgram(false)}
        programType={programType}
      />
    </>
  );
};

export default Intro;
