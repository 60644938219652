import React, { useEffect, useState, useCallback } from 'react';
import { shallowEqual } from 'react-redux';
import { Col, notification, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { uniqueId } from 'lodash';

import {
  approveBrand,
  clear,
  deleteBrand,
  getBrands,
  getBrandsCount,
} from '../../store/brands/brands-actions';
import CreateUpdateBrand from './components/create-update-brand';
import useOnLiveChange from '../../hooks/use-on-live-change';
import memoNoProps from '../../utils/memo-no-props';
import { Brand, UniqueBrand } from '../../store/brands/brands-model';
import { CheckCircle } from '../../components/icons';
import useSelectedProgram from '../../hooks/use-selected-program';
import useRedirect from '../../hooks/use-redirect';
import { InlineButton } from '../../components/buttons';
import useRawDetail from '../../components/raw-detail/hooks/use-raw-detail';
import useCopyWithNotification from '../../hooks/use-copy-with-notification';
import UniqueBrandsSearch from './components/unique-brands-search';
import BrandDetail from './components/BrandDetail';
import useProgressState from '../../hooks/use-progress-state';
import { BrandsContext } from './context/brands-context';
import useActions from './hooks/useActions';
import { FormSteps } from './types';
import Header from '../../components/header';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import { Search } from './styled/brands-list';
import { colors } from '../../theme';
import useDebounce from '../../hooks/use-debounce';
import useBrandToRow from './hooks/useBrandToRow';
import { TableWithRightContentWrapper } from '../../components/tables/styled/helpers';
import InfiniteScrollTable from '../../components/tables/InfiniteScrollTable';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

const Brands = () => {
  const { t } = useTranslation(['common', 'brands']);
  const { t: tAddBrands } = useTranslation('brands', {
    keyPrefix: 'addBrand.success',
  });
  const { t: tAddLocations } = useTranslation('brands', {
    keyPrefix: 'addLocations.success',
  });
  const dispatch = useAppDispatch();
  const redirect = useRedirect();
  const { setSelectedProgramId } = useSelectedProgram();

  const { rawDetail, openRawDetail } = useRawDetail('brands');
  const copyWithNotification = useCopyWithNotification();

  const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);
  const [selectedUniqueBrand, setSelectedUniqueBrand] =
    useState<UniqueBrand | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editBrand, setEditBrand] = useState(false);
  const [editedBrandsIds, setEditedBrandsIds] = useState<{
    [key: string]: string;
  }>({});

  const {
    isLive,
    brands,
    createBrandItem,
    createBrandSuccess,
    lastBrand,
    loading,
    mapUniqueLocationsStatus,
    updateBrandStatus,
  } = useAppSelector(
    state => ({
      isLive: state.live,
      brands: state.brands.brands,
      createBrandItem: state.brands.createBrandStatus.createdBrand,
      createBrandSuccess: state.brands.createBrandStatus.success,
      lastBrand: state.brands.lastBrand,
      loading: state.brands.loading,
      mapUniqueLocationsStatus:
        state.locations.mapUniqueLocationsToProgramStatus,
      updateBrandStatus: state.brands.updateBrandStatus,
    }),
    shallowEqual,
  );

  const [filteredBrands, setFilteredBrands] = useState(brands);
  const [filterValue, setFilterValue] = useState('');
  const debouncedFilterValue = useDebounce(filterValue, 500);

  useEffect(() => {
    setFilteredBrands(
      brands.filter(brand =>
        brand.name
          .toLocaleLowerCase()
          .includes(debouncedFilterValue.toLocaleLowerCase()),
      ),
    );
  }, [brands, debouncedFilterValue]);

  useEffect(() => {
    setFilterValue('');
  }, [isLive]);

  const updateBrandProgress = useProgressState(updateBrandStatus.loading);

  function handleAddBrandUser(brand: Brand) {
    setSelectedBrand(brand);
    setEditBrand(false);
    setIsModalOpen(true);
  }

  function handleAddUniqueBrand(uniqueBrand: UniqueBrand | null) {
    setSelectedBrand(null);
    setSelectedUniqueBrand(uniqueBrand);
    setEditBrand(false);
    setIsModalOpen(true);
  }

  function handleCreateBrand() {
    setSelectedBrand(null);
    setSelectedUniqueBrand(null);
    setEditBrand(false);
    setIsModalOpen(true);
  }

  function handleEditBrand(brand: Brand) {
    setSelectedBrand(brand);
    setSelectedUniqueBrand(null);
    setEditBrand(true);
    setIsModalOpen(true);
  }

  function showDetail(brand: Brand) {
    if (selectedBrand?.id !== brand.id) setSelectedBrand(brand);
    else setSelectedBrand(null);
  }

  const actionsHandlers = {
    addUser: (brand: Brand) => handleAddBrandUser(brand),
    addUniqueBrand: (brand: Brand) => handleAddUniqueBrand(brand),
    approve: (brand: Brand) => {
      dispatch(approveBrand(brand.id, true));
    },
    copy: (brand: Brand) => copyWithNotification(brand.id),
    edit: (brand: Brand) => handleEditBrand(brand),
    delete: (brand: Brand) => {
      dispatch(deleteBrand(brand.id));
      if (brand.id === selectedBrand?.id) setSelectedBrand(null);
    },
    reject: (brand: Brand) => {
      dispatch(approveBrand(brand.id, false));
    },
    viewRawDetail: (brand: Brand) => openRawDetail(brand.id)(),
  };

  const actions = useActions(actionsHandlers);
  const brandToRow = useBrandToRow(actions, showDetail, selectedBrand);
  const rows = filteredBrands.map((brand, index) => brandToRow(brand, index));

  const { success: mapUniqueLocationsSuccess, selectedLocationsToMapCount } =
    mapUniqueLocationsStatus;

  const loadBrands = useCallback(
    ({ clearFirst }: { clearFirst: boolean } = { clearFirst: false }) => {
      if (clearFirst) dispatch(clear());

      dispatch(getBrands());
      dispatch(getBrandsCount());
    },
    [dispatch],
  );

  useEffect(() => {
    loadBrands();

    return () => {
      dispatch(clear());
    };
  }, [dispatch, loadBrands]);

  useEffect(() => {
    if (createBrandSuccess) {
      const { brandName, programId, programName } = createBrandItem || {};

      notification.open({
        key: 'brand-consent-notification',
        type: 'success',
        icon: <CheckCircle />,
        message: tAddBrands('title'),
        description: (
          <div style={{ display: 'flex', flexFlow: 'column' }}>
            <span>
              {tAddBrands(isLive ? 'description' : 'descriptionTest', {
                brandName,
                programName,
              })}
            </span>
            {isLive && (
              <InlineButton
                type="link"
                onClick={() => {
                  setSelectedProgramId(programId);
                  redirect('/locations');
                }}
                style={{ width: 'fit-content', marginTop: '4px' }}
              >
                {tAddBrands('link', {
                  programName,
                })}
              </InlineButton>
            )}
          </div>
        ),
        duration: 4,
      });
    }

    if (mapUniqueLocationsSuccess) {
      notification.open({
        type: 'success',
        icon: <CheckCircle />,
        message: tAddLocations('title', {
          selectedLocationsToMapCount,
        }),
        description: tAddLocations('description'),
        duration: 4,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createBrandSuccess, mapUniqueLocationsSuccess]);

  useEffect(() => {
    if (!isModalOpen) {
      notification.close('brand-consent-notification');
    }
  }, [isModalOpen]);

  useOnLiveChange(() => {
    loadBrands({ clearFirst: true });
  });

  updateBrandProgress.onFinish(() => {
    if (
      updateBrandStatus.success &&
      selectedBrand?.id &&
      selectedBrand.logoURL
    ) {
      setEditedBrandsIds({
        ...editedBrandsIds,
        [selectedBrand.id]: uniqueId(),
      });
    }
  });

  return (
    <BrandsContext.Provider value={{ editedBrandsIds }}>
      <Row style={{ height: '110px' }}>
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <Header
                heading={t('brands:title')}
                bottomLeft={
                  <Search
                    name="brandSearch"
                    data-testid="search-input"
                    type="text"
                    placeholder={t('common:search')}
                    value={filterValue}
                    onChange={({ target }) => setFilterValue(target.value)}
                    prefix={<SearchIcon style={{ color: colors.grey4 }} />}
                    $hasInput={!!filterValue}
                    allowClear
                  />
                }
              />
            </Col>
            <Col>
              <Row justify="end">
                <UniqueBrandsSearch
                  handleCreateBrand={handleCreateBrand}
                  setSelectedBrand={setSelectedBrand}
                  handleAddUniqueBrand={handleAddUniqueBrand}
                />
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row
        justify="space-between"
        gutter={24}
        style={{ flexWrap: 'nowrap', height: 'calc(100% - 110px)' }}
      >
        <TableWithRightContentWrapper $showSideBar={!!selectedBrand}>
          <InfiniteScrollTable
            columns={[
              { heading: t('brands:name'), size: 0.7 },
              { heading: t('brands:consent'), size: 0.35 },
              { heading: t('brands:created'), size: 0.3, sortedBy: 'unset' },
            ]}
            rows={rows}
            total={filteredBrands.length}
            onReload={() => loadBrands({ clearFirst: true })}
            onBottom={() => {
              if (lastBrand && !loading) {
                dispatch(getBrands({ last: lastBrand }));
              }
            }}
            loading={loading}
            emptyText={t('brands:empty')}
          />
        </TableWithRightContentWrapper>
        <Col>
          {!!selectedBrand && (
            <BrandDetail
              brand={selectedBrand}
              onClear={() => setSelectedBrand(null)}
              actions={actions(selectedBrand, true)}
            />
          )}
        </Col>
      </Row>
      {rawDetail}
      <CreateUpdateBrand
        visible={isModalOpen}
        brand={selectedBrand}
        uniqueBrand={selectedUniqueBrand}
        handleClose={() => setIsModalOpen(false)}
        defaultStep={editBrand ? FormSteps.EDIT_BRAND : undefined}
      />
    </BrandsContext.Provider>
  );
};

export default memoNoProps(Brands);
