import {
  neutrals,
  greyColors,
  blues,
  cobalts,
  greens,
  oranges,
  reds,
  pinks,
  purples,
  yellows,
  others as otherColors,
  moderatorColors,
  productColors,
  logoColors,
} from './colors';

export const colors = {
  ...neutrals,
  ...greyColors,
  ...blues,
  ...cobalts,
  ...greens,
  ...oranges,
  ...reds,
  ...pinks,
  ...purples,
  ...yellows,
  ...otherColors,
  ...logoColors,
  moderator: moderatorColors,
  product: productColors,
};

export type ColorsProps = typeof colors;

export type Color = {
  [K in keyof ColorsProps]-?: ColorsProps[K] extends string ? K : never;
}[keyof ColorsProps];

export { programColors } from './colors';

export const translucentBorder = `
  border-color: rgba(0, 0, 0, 0.1);
  background-clip: padding-box !important;
`;

export const boxShadow = '0px 4px 20px rgba(0, 0, 0, 0.08)';

export const breakpoints = {
  xs: 440,
  tabletPortrait: 600,
  tabletLandscape: 1024,
  sidebarCollapse: 768,
};

export const fonts = {
  default: `Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
  Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
  sans-serif`,
  monospace: 'Roboto Mono, Monaco, Consolas, monospace',
};

export const fontSize = {
  small: '14px',
};

export const padding = {
  s: 5,
  m: 10,
  l: 20,
  xl: 40,
};

export const hiddenScrollbar = `
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    display: none;
  }
`;
