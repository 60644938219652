import React from 'react';
import styled from 'styled-components';
import { Spin as AntdSpin } from 'antd';

import spinGrey from '../../assets/spin_grey.png';
import spinWhite from '../../assets/spin_white.png';

type SpinType = 'table' | 'button' | 'fullPage';

interface SpinProps {
  type?: SpinType;
  style?: React.CSSProperties;
}

const Spin = ({ type = 'table', style }: SpinProps) => (
  <StyledSpin
    data-testid="loader-spin"
    style={style}
    type={type}
    indicator={<Loading type={type} />}
  />
);

export const BaseSpin = () => <AntdSpin indicator={<Loading />} />;

export const StyledSpin = styled(AntdSpin)<{ type: SpinType }>`
  width: 100%;
  padding: ${({ type }) => (type === 'table' ? '100px 0 0' : '0 10px')};
  height: ${({ type }) => (type === 'fullPage' ? '100vh' : 'initial')};
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none !important;
`;

export const InlineStyledSpin = styled(AntdSpin)`
  padding: 0;
  appearance: none !important;
`;

export const Loading = styled.div.attrs(() => ({
  className: 'anticon-spin',
}))<{ type?: SpinType }>`
  font-size: 16px;
  background: ${({ type }) =>
    `url(${type === 'button' ? spinWhite : spinGrey})`};
  background-size: cover;
`;

export default Spin;
