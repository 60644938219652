import React, { useEffect, ReactElement } from 'react';
import { notification as globalNotification } from 'antd';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import {
  actions as statusActions,
  DefaultStatusCode,
  Status,
  StatusCode,
} from '../store/status/status-reducer';
import {
  defaultNotifications,
  notificationTemplates,
  NotificationCode,
} from '../components/notifications';
import { CheckCircle, ErrorIcon, Info, X } from '../components/icons';
import { useAppDispatch, useAppSelector } from '../store/hooks';

globalNotification.config({
  closeIcon: <X />,
  placement: 'bottomRight',
  bottom: 48,
});

export function useSetStatus() {
  const dispatch = useAppDispatch();

  const status = (...args: Parameters<typeof statusActions.status>) => {
    dispatch(statusActions.status(args[0]));
  };

  return {
    setStatus: (code: StatusCode, options?: Partial<Status>) =>
      status({ code, ...options }),
    setErrorMessage: (message: string) =>
      status({ code: DefaultStatusCode.error, message }),
    setSuccessMessage: (message: string) =>
      status({ code: DefaultStatusCode.success, message }),
    setInfoMessage: (message: string) =>
      status({ code: DefaultStatusCode.info, message }),
  };
}

const icons: Record<string, ReactElement> = {
  success: <CheckCircle />,
  info: <Info />,
  error: <ErrorIcon />,
};

export default function useStatus() {
  const [notification, contextHolder] = globalNotification.useNotification();

  const { t } = useTranslation('notifications');
  const { status, counter } = useAppSelector(
    state => state.status,
    shallowEqual,
  );

  useEffect(() => {
    if (!status || status.skip) return;

    const { code, message, onClose } = status;
    const key = `status-${counter}`;

    const template =
      notificationTemplates[code as NotificationCode] ||
      defaultNotifications(code, message);

    const payload = {
      key,
      message: t(template.title),
      description: template.description || null,
      duration: template.duration ?? 4.5,
      icon: icons[template.level],
      onClose,
    };

    notification[template.level](payload);
  }, [counter, notification, status, t]);

  return contextHolder;
}

export const useManualStatus = () => {
  const { t } = useTranslation('notifications');

  return {
    notify: (templateCode: NotificationCode) => {
      const { level, title, description } = notificationTemplates[templateCode];

      return globalNotification[level]({
        message: t(title),
        description,
        icon: icons[level],
      });
    },
  };
};
