import styled from 'styled-components';
import { Button, Typography } from 'antd';

import BaseTable from '../../../components/tables/BaseTable';
import { Cell, Row } from '../../../components/tables/styled/base';
import { ConfirmModal } from '../../../components/modal/confirm-modal';

import { colors } from '../../../theme';

const { Text } = Typography;

export const ReviewField = styled.div`
  p {
    font-size: 14px;
  }
`;

export const FieldsGroup = styled.div`
  border-bottom: 1px solid ${colors.lightgrey2};
  padding-bottom: 30px;
  margin-bottom: 30px;

  ${ReviewField} {
    :last-of-type {
      p {
        margin-bottom: 0;
      }
    }
  }
`;

export const FieldsGroupContainer = styled.div`
  ${FieldsGroup} {
    :last-of-type {
      border-bottom: none;
      margin-bottom: 0;
    }
  }
`;

export const Table = styled(BaseTable).attrs(() => ({ bordered: true }))`
  margin-bottom: 30px;
  ${Row} {
    justify-content: space-between;

    ${Cell} {
      :last-of-type {
        min-width: max-content;
        padding-right: 0;
      }

      .ant-typography {
        margin-bottom: 0;
      }
    }
  }
`;

export const Label = styled(Text).attrs(() => ({
  type: 'secondary',
}))`
  font-weight: 500;
`;

export const DownloadButton = styled(Button).attrs({ type: 'link' })`
  font-weight: normal;
  background: none;
  padding: 0;

  :hover {
    background: none;
  }
`;

export const StyledModal = styled(ConfirmModal)`
  .ant-btn-primary:not(.ant-btn-dangerous) {
    background-color: ${colors.darkgreen};
    border-color: ${colors.darkgreen};
    &:hover {
      background-color: ${colors.green};
      border-color: ${colors.green};
    }
  }
`;
