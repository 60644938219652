import { actions as statusActions } from '../status/status-reducer';
import BrandApiService from './brands-api-service';
import { actions } from './brands-reducer';
import {
  Brand,
  BrandConsent,
  CreateBrandOptions,
  BrandOptions,
  UpdateBrandPayload,
  BrandsNotification,
} from './brands-model';
import { Thunk } from '../types';

const brandApiService: BrandApiService = new BrandApiService();

export const { clear } = actions;

export const brandStatus =
  (code: BrandsNotification): Thunk =>
  async dispatch => {
    dispatch(statusActions.status({ code }));
  };

export const createBrand =
  ({
    brand,
    consent,
    live,
    programName,
    programId,
  }: CreateBrandOptions): Thunk =>
  async dispatch => {
    dispatch(actions.createBrand());

    try {
      const response = await brandApiService.createBrand(brand);

      if (live && consent) {
        dispatch(
          requestConsent({
            ...consent,
            brandId: (response.data?.items?.[0] || {}).id,
          }),
        );
      }

      dispatch(getBrands());
      dispatch(getBrandsCount());
      dispatch(
        actions.createBrandSuccess({
          brandId: (response.data?.items?.[0] || {}).id,
          brandName: brand.name,
          programId,
          programName,
        }),
      );
    } catch (error) {
      dispatch(actions.createBrandError({ error }));
    }
  };

export const updateBrand =
  (brandId: string, brand: UpdateBrandPayload): Thunk =>
  async dispatch => {
    dispatch(actions.updateBrand());

    try {
      await brandApiService.updateBrand(brandId, brand);
      dispatch(actions.updateBrandSuccess());
      dispatch(brandStatus(BrandsNotification.UPDATE_BRAND));
      dispatch(getBrands());
    } catch (error) {
      dispatch(actions.updateBrandError({ error }));
    }
  };

export const resetCreateBrandStatus = (): Thunk => dispatch => {
  dispatch(actions.resetCreateBrandStatus());
};

export const deleteBrand =
  (brandId: string): Thunk =>
  async dispatch => {
    dispatch(actions.deleteBrand());

    try {
      await brandApiService.deleteBrand(brandId);
      dispatch(getBrands());
      dispatch(getBrandsCount());
    } catch (error) {
      dispatch(actions.deleteBrandError({ error }));
    }
  };

export const deleteBrandUser =
  (brandId: string, created: string): Thunk =>
  async dispatch => {
    dispatch(actions.deleteBrandUser());

    try {
      await brandApiService.deleteBrandUser(brandId, created);
      dispatch(getBrandUsers(brandId));
    } catch (error) {
      dispatch(actions.deleteBrandUserError({ error }));
    }
  };

export const getAllBrands =
  (options?: BrandOptions): Thunk =>
  async dispatch => {
    dispatch(actions.getAllBrands());

    try {
      const response = await brandApiService.getAllBrands(options);
      dispatch(actions.getAllBrandsSuccess(response));
    } catch (error) {
      dispatch(actions.getAllBrandsError({ error }));
    }
  };

export const getBrands =
  (options?: BrandOptions): Thunk =>
  async dispatch => {
    if (options?.last) dispatch(actions.getMoreBrands());
    else dispatch(actions.getBrands());

    try {
      const { data } = await brandApiService.getBrands(options);
      if (options?.last) dispatch(actions.getMoreBrandsSuccess(data));
      else dispatch(actions.getBrandsSuccess(data));
    } catch (error) {
      if (options?.last) dispatch(actions.getMoreBrandsError({ error }));
      else dispatch(actions.getBrandsError({ error }));
    }
  };

export const getBrandUsers =
  (brandId: string | undefined): Thunk =>
  async dispatch => {
    dispatch(actions.getBrandUsers());

    try {
      const response = await brandApiService.getBrandUsers(brandId);
      dispatch(
        actions.getBrandUsersSuccess({ brandId, items: response.data.items }),
      );
    } catch (error) {
      dispatch(actions.getBrandUsersError({ error }));
    }
  };

export const getBrandsCount = (): Thunk => async dispatch => {
  dispatch(actions.getBrandsCount());

  try {
    const response = await brandApiService.getBrandsCount();
    dispatch(actions.getBrandsCountSuccess(response));
  } catch (error) {
    dispatch(actions.getBrandsCountError({ error }));
  }
};

export const resetRequestConsentStatus = (): Thunk => dispatch => {
  dispatch(actions.resetRequestConsentStatus());
};

export const requestConsent =
  (consent: BrandConsent): Thunk =>
  async dispatch => {
    dispatch(actions.requestConsent());

    try {
      await brandApiService.requestConsent(consent);
      dispatch(actions.requestConsentSuccess());
      dispatch(getBrands());
      dispatch(getBrandsCount());
      dispatch(getBrandUsers(consent.brandId));
    } catch (error) {
      dispatch(actions.requestConsentError({ error }));
    }
  };

export const approveBrand =
  (brandId: string, approveOrReject: boolean): Thunk =>
  async dispatch => {
    dispatch(actions.approveBrand());

    try {
      await brandApiService.approveBrand(brandId, approveOrReject);
      dispatch(actions.approveBrandSuccess());
      dispatch(getBrands());
      dispatch(getBrandsCount());
    } catch (error) {
      dispatch(actions.approveBrandError());
    }
  };

export const filterBrands =
  (filter: (brand: Brand) => boolean): Thunk =>
  dispatch => {
    dispatch(actions.filterBrands());

    try {
      dispatch(actions.filterBrandsSuccess(filter));
    } catch (error) {
      dispatch(actions.filterBrandsError());
    }
  };

export const setUniqueBrandName =
  (searchTerm: string): Thunk =>
  async dispatch => {
    dispatch(actions.setUniqueBrandName(searchTerm));
  };

export const getUniqueBrandByBrandId =
  (brandId: string): Thunk =>
  async dispatch => {
    dispatch(actions.getUniqueBrandByBrandId());

    try {
      const { data } = await brandApiService.getUniqueBrandByBrandId(brandId);
      dispatch(actions.getUniqueBrandByBrandIdSuccess(data.items?.[0]));
    } catch (error) {
      dispatch(actions.getUniqueBrandByBrandIdError());
    }
  };

export const resetUniqueBrandByBrandId = (): Thunk => dispatch => {
  dispatch(actions.resetUniqueBrandByBrandId());
};
