import { useEffect, useRef } from 'react';

import { useAppSelector } from '../store/hooks';

export default function useOnLiveChange(
  callback: (isLive: boolean) => unknown,
) {
  const isLive = useAppSelector(state => state.live);

  const ref = useRef(isLive);

  useEffect(() => {
    ref.current = isLive;
  });

  if (ref.current !== isLive) {
    callback(isLive);
    ref.current = isLive;
  }
}
