import styled from 'styled-components';
import { blues } from '../../../theme/colors';

export const Container = styled.div`
  background-color: ${blues.lightBlue6};
  max-width: 745px;
  padding: 2rem;
  border-radius: 21px;
`;

export const OuterContainer = styled.div`
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
`;

export const NextContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  margin-top: 1rem;
  padding: 1rem;
`;
