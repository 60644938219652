import { onAction } from '../utils/action-listen-middleware';

import { actions } from './status-reducer';
import { actionToError } from '../utils/transform';

export const statusError = onAction(
  actionType => actionType.endsWith('Error'),
  action =>
    actions.status({
      ...actionToError(action),
    }),
);
