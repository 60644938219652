import styled from 'styled-components';
import { Button, Typography } from 'antd';
import { colors, fontSize } from '../../../theme';

export const EstimationLabel = styled(Typography.Text)`
  font-size: 14px;
  color: ${colors.grey4};
`;

export const StyledHeadingTitle = styled.span`
  overflow: hidden;
  padding-right: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ProgramNameText = styled(Typography.Text)`
  width: 100%;
  height: 20px;
  overflow: hidden;
  padding-inline-end: 0.3rem;
`;

export const RejectOfferButton = styled(Button)`
  background-color: ${colors.fidelBlue50};
  color: ${colors.fidelBlue600};
  font-weight: 600;
  font-size: ${fontSize.small};
  height: 32px;
  border: none;

  :hover {
    background-color: ${colors.fidelBlue200};
  }
`;
