import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'antd';
import {
  CardElement as StripeCardElement,
  CardNumberElement as StripeCardNumberElement,
  CardCvcElement as StripeCardCvcElement,
  CardExpiryElement as StripeCardExpiryElement,
} from '@stripe/react-stripe-js';

import stripeWrapper from './StripeWrapper';
import { colors } from '../../theme';

const inputStyle = `
  background-color: white;
  height: 38px;
  padding: 11px 8px;
  border-radius: 5px;
  border: 2px solid ${colors.lightgrey2};
`;

export const CardElement = styled(StripeCardElement)`
  ${inputStyle}
`;

export const CardNumberElement = styled(StripeCardNumberElement)`
  ${inputStyle}
`;

export const CardCvcElement = styled(StripeCardCvcElement)`
  ${inputStyle}
`;

export const CardExpiryElement = styled(StripeCardExpiryElement)`
  ${inputStyle}
`;

export const CardForm = () => {
  const { t } = useTranslation();

  return (
    <>
      <Form.Item label={t('account.payment.card.number')}>
        <CardNumberElement options={{ showIcon: true }} />
      </Form.Item>
      <Row justify="space-between">
        <Col xs={{ span: 15 }}>
          <Form.Item label={t('account.payment.card.expiry')}>
            <CardExpiryElement />
          </Form.Item>
        </Col>
        <Col xs={{ offset: 1, span: 8 }}>
          <Form.Item label={t('account.payment.card.cvc')}>
            <CardCvcElement />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export { stripeWrapper };
