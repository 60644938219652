import React, { useState, useEffect, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'antd';
import { DropDownProps } from 'antd/lib/dropdown';
import { useInView } from 'react-intersection-observer';

import { StyledMenu, StyledMenuItem } from '../menu';
import { ActionsMenuButton, ActionMenuInView, Dots, New } from './styled';

type Placement = DropDownProps['placement'];

export interface Action {
  label: string | ReactElement;
  disabled?: boolean;
  callback: (event: React.MouseEvent) => unknown;
  danger?: boolean;
  hidden?: boolean;
  isNewAction?: boolean;
}

interface ActionsMenuProps {
  actions: Action[];
  placement?: Placement;
}

const ActionsMenu = ({
  actions,
  placement = 'bottomRight',
}: ActionsMenuProps) => {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [calculatedPlacement, setCalculatedPlacement] = useState(placement);
  const [actionMenuBottom, bottomInView] = useInView({
    threshold: 1,
  });
  const [actionMenuRight, rightInView] = useInView({
    threshold: 1,
  });

  useEffect(() => {
    if (!bottomInView) {
      setCalculatedPlacement('topRight');
    } else {
      setCalculatedPlacement('bottomRight');
    }

    if (!rightInView) {
      setMenuOpen(false);
    }
  }, [bottomInView, rightInView]);

  const menu = (
    <StyledMenu data-testid={`actions-panel-${calculatedPlacement}`}>
      {actions
        .filter(action => !action.hidden)
        .map(({ label, callback, disabled, danger, isNewAction }, idx) => (
          <StyledMenuItem
            key={idx}
            onClick={({ domEvent }) => {
              domEvent.stopPropagation();
              callback(domEvent);
              setMenuOpen(false);
            }}
            disabled={disabled}
            danger={danger}
          >
            {label} {isNewAction ? <New>{t('actions.new')}</New> : null}
          </StyledMenuItem>
        ))}
    </StyledMenu>
  );

  return (
    /* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
    <div style={{ position: 'relative' }} onClick={e => e.stopPropagation()}>
      <Dropdown
        visible={menuOpen}
        onVisibleChange={setMenuOpen}
        trigger={['click']}
        overlay={menu}
        placement={calculatedPlacement}
        getPopupContainer={(trigger: any) => trigger.parentNode}
      >
        <ActionsMenuButton data-testid="actions-menu-btn" $open={menuOpen}>
          <ActionMenuInView ref={actionMenuRight} style={{ right: 0 }} />
          <Dots />
          <ActionMenuInView
            ref={actionMenuBottom}
            style={{ bottom: '-80px' }}
            data-testid="actions-menu-bottom"
          />
        </ActionsMenuButton>
      </Dropdown>
    </div>
  );
};

export default ActionsMenu;
