import React, { useEffect, useState } from 'react';
import { Col, Divider, Row, Typography, Tooltip } from 'antd';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { QuestionMark } from '../../../../components/icons';
import { TooltipWrapper } from '../../create-update-offer/styled';
import KeyValuePairs, {
  KeyValuePairsDefaultFields,
} from '../../../../components/form/components/KeyValuePairs';
import { Offer } from '../../../../store/offers/offers-model';

interface SupplierRequiredFieldsProps {
  uniqueOffer: Offer;
}

export default function SupplierRequiredFields({
  uniqueOffer,
}: SupplierRequiredFieldsProps) {
  const [supplierRequiredFieldsValues, setSupplierRequiredFieldsValues] =
    useState<KeyValuePairsDefaultFields | null>();

  const { t } = useTranslation('offers');
  const {
    formState: { errors },
  } = useFormContext();

  const { supplier, supplierRequiredFields } = uniqueOffer;

  const tSupplierFields = 'marketplace.addOffer.steps.supplierRequiredFields';

  useEffect(() => {
    if (supplierRequiredFields) {
      const currSupplierInfoDefaultFields: KeyValuePairsDefaultFields = [];

      supplierRequiredFields.forEach(fieldKey => {
        currSupplierInfoDefaultFields.push({
          key: fieldKey,
          value: '',
        });
      });

      setSupplierRequiredFieldsValues(currSupplierInfoDefaultFields);
    } else {
      setSupplierRequiredFieldsValues(null);
    }
  }, [supplierRequiredFields]);

  if (!supplierRequiredFields) return null;

  const supplierRequiredInfo = supplierRequiredFields.length
    ? supplierRequiredFields.join(' & ')
    : supplierRequiredFields;

  return (
    <>
      <Row>
        <Col span={24}>
          <Typography.Text>
            {t(`${tSupplierFields}.title`, {
              supplier,
            })}
          </Typography.Text>

          <TooltipWrapper>
            <Tooltip
              title={t(`${tSupplierFields}.tooltip.requiredFieldsInputs`, {
                count: supplierRequiredFields.length,
                supplier,
                supplierRequiredInfo,
              })}
              placement="bottomRight"
            >
              <QuestionMark />
            </Tooltip>
          </TooltipWrapper>
        </Col>
      </Row>

      <Row>
        <Col>
          <KeyValuePairs
            errors={errors?.supplierInfo}
            name="supplierInfo"
            showKeyValueLabel={false}
            fixedFields
            defaultFields={supplierRequiredFieldsValues}
            valueField={{
              rules: {
                required: true,
              },
            }}
          />
        </Col>
      </Row>

      <Divider />
    </>
  );
}
