import styled from 'styled-components';
import { Upload as UploadAntd } from 'antd';
import { colors } from '../../../theme';
import { ReactComponent as TrashIcon } from '../../../assets/trash.svg';

export const DocumentContainer = styled.div`
  padding: 12px;
  display: flex;
  border: 2px solid ${colors.grey2};
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
`;

export const DocumentContainerSection = styled.div`
  display: flex;
  align-items: center;
`;

export const DownloadAndRemove = styled(DocumentContainerSection)`
  color: ${colors.grey4};
`;

export const UploadStyled = styled(UploadAntd)`
  .ant-upload {
    display: block;
    .ant-btn {
      border-width: 2px;
      height: 55px;
      color: ${colors.grey4} !important;
    }
  }
`;

export const ImageCropperStyled = styled.div<{
  $isUploaded: boolean;
}>`
  display: ${({ $isUploaded }) => ($isUploaded ? 'block' : 'none')};
`;

export const TrashIconStyled = styled(TrashIcon)`
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: ${colors.white};
  border-radius: 4px;
  transition: 150ms opacity ease-out;

  &:hover {
    opacity: 0.75;
    cursor: pointer;
  }
`;

export const ImageUploadStyled = styled(UploadAntd).attrs(() => ({
  'data-testid': 'upload-image-with-crop',
}))<{
  height?: number;
  $isError?: boolean;
  $isUploaded: boolean;
}>`
  .ant-upload-select-picture {
    width: 100%;
    height: ${({ height }) => `${height}px` ?? '100%'};

    .ant-upload {
      display: ${({ $isUploaded }) => ($isUploaded ? 'none' : 'flex')};
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 24px;
      border: 2px dashed
        ${({ $isError }) => colors[$isError ? 'red' : 'lightgrey2']};
      border-radius: 4px;
      text-align: center;
      color: ${({ $isError }) => colors[$isError ? 'red' : 'fidelBlue600']};
      transition: 150ms border-color ease-out;

      &:hover,
      &:focus {
        cursor: pointer;
        border-color: ${({ $isError }) =>
          colors[$isError ? 'red2' : 'fidelBlue600']};
      }
    }
  }
`;
