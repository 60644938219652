import styled from 'styled-components';
import { Button } from 'antd';
import { colors } from '../../../theme';
import { ReactComponent as XIcon } from '../../../assets/x.svg';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CloseIcon = styled(XIcon).attrs(() => ({
  'data-testid': 'closeUniqueBrandsSearch',
}))`
  color: ${colors.grey4};

  &:hover {
    cursor: pointer;
  }
`;

export const ClearButton = styled(Button).attrs(() => ({
  type: 'text',
  size: 'small',
}))`
  margin-right: 10px;
  color: ${colors.grey4};
`;
