import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import './style.css';
import { boxShadow, colors, translucentBorder } from '../../theme';

const FeedbackTriggerButton = ({
  style,
  ...props
}: {
  style?: CSSProperties;
}) => {
  const { t } = useTranslation();

  return (
    <FeedbackButton {...props} style={style}>
      {t('feedback')}
    </FeedbackButton>
  );
};

const FeedbackButton = styled(Button).attrs(() => ({
  type: 'primary',
  size: 'small',
  icon: (
    <span aria-label="feedback-icon" role="img" className="ant-icon">
      🗣️
    </span>
  ),
}))`
  background: ${colors.white};
  border-radius: 50px;
  color: ${colors.black};
  box-shadow: ${boxShadow};
  font-size: 15px;
  ${translucentBorder};
  .ant-icon {
    font-size: 16px;
    display: inline-block;
    margin-right: 4px;
    position: relative;
    top: -1px;
  }
  :hover,
  :focus {
    ${translucentBorder};
    background: ${colors.lightgrey2};
    color: ${colors.black};
  }
`;

export default FeedbackTriggerButton;
