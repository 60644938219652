export const neutrals = {
  white: '#ffffff',
  black: '#1b2733',
  transparent: 'rgba(0, 0, 0, 0)',
};

export const greys = {
  grey1: '#98a0af',
  grey2: '#e6e8eb',
  grey3: '#c4c4c4',
  grey4: '#6c788b',
  grey5: '#516078',
  grey6: '#6e7b8a',
  grey50: '#f9fafb',
  grey100: '#f2f4f7',
};

export const lightgreys = {
  lightgrey: '#f4f5f7',
  lightgrey2: '#ebecf0',
  lightgrey3: '#f2f2f2',
  lightgrey4: '#fdfdfd',
  lightgrey5: '#ecedf0',
  lightgrey6: '#dedede',
  lightgrey7: '#f3f4f5',
  lightgrey8: '#e6e6e6',
  lightGrey9: '#dadada',
};

export const greyColors = { ...greys, ...lightgreys };

export const blues = {
  blue2: '#519dfc',
  lightblue: '#e8f0fe',
  lightblue2: '#c5deff',
  lightblue3: '#b3e5fc',
  lightblue4: '#81d4fA',
  lightblue5: '#d6E7fe',
  lightBlue6: '#F5F9FF',
  darkblue: '#283747',
  darkblue2: '#060d88',
  fidelBlue50: '#e6f2ff',
  fidelBlue200: '#b4d4fa',
  fidelBlue600: '#105efb',
  fidelBlue700: '#003cc7',
  fidelBlue800: '#002999',
  fidelBlue900: '#001b74',
};

export const cobalts = {
  cobalt: '#b1b7bf',
  lightcobalt: '#cfd8dc',
};

export const greens = {
  green: '#21b115',
  green2: '#a5d6a7',
  lightgreen: '#c8e6c9',
  darkgreen: '#18832a',
  green600: '#2c9f51',
};

export const oranges = {
  orange: '#fd6a26',
  orange2: '#ffca28',
  lightorange: '#ffe082',
  lightorange2: '#fb9689',
  darkOrange: '#803700',
};

export const reds = {
  red: '#f73106',
  red2: '#ef9a9a',
  lightred: '#fedcd7',
};

export const pinks = {
  pink: '#fc3768',
  pink2: '#ce93d8',
  lightpink: '#e1bee7',
};

export const purples = {
  purple: '#b39ddb',
  lightpurple: '#d1c4e9',
};

export const yellows = {
  yellow: '#ffda40',
  yellow2: '#ffe79e',
  yellow3: '#fffbd6',
  yellow4: '#fff59d',
  darkYellow: '#664d00',
  darkYellow2: '#f4dd11',
};

export const others = {
  beige: '#f5f2eb',
  brown: '#d7ccc8',
};

export const programColors = {
  background: {
    white: neutrals.white,
    red: reds.lightred,
    yellow: yellows.yellow4,
    orange: oranges.lightorange,
    green: greens.lightgreen,
    blue: blues.lightblue3,
    purple: purples.lightpurple,
    pink: pinks.lightpink,
    brown: others.brown,
    cobalt: cobalts.lightcobalt,
  },
  border: {
    white: greys.grey2,
    red: reds.red2,
    yellow: yellows.darkYellow2,
    orange: oranges.orange2,
    green: greens.green2,
    blue: blues.lightblue4,
    purple: purples.purple,
    pink: pinks.pink2,
    brown: cobalts.cobalt,
    cobalt: cobalts.cobalt,
  },
};

export const moderatorColors = {
  subscribed: '#3edc61',
  liveActive: '#3edc61',
  rejected: '#ff5825',
  reviewed: '#06a7ea',
  review: '#ffa800',
  test: '#cacaca',
  live: '#6920d5',
};

export const productColors = {
  transactionStream: '#1a803c',
  selectTransaction: '#5012aa',
  transactionDistroOnly: '#3995e8',
};

export const logoColors = {
  ...neutrals,
  gray: '#6c788b',
};
