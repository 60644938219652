import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Checkbox, Divider } from 'antd';

import Form from '../../../../components/form';
import { LinkButton } from '../../../../components/buttons';
import Link from '../../../../components/link';
import { RequiredFormItemController } from '../../../../components/form/styled';

import { Offer } from '../../../../store/offers/offers-model';

interface MarketplaceTermsProps {
  uniqueOffer: Offer;
  setShowUniqueOfferTerms: (showUniqueOfferTerms: boolean) => void;
}

export default function MarketplaceTerms({
  uniqueOffer,
  setShowUniqueOfferTerms,
}: MarketplaceTermsProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const uniqueOfferTermsLabel = useMemo(() => {
    const offersMarketplaceTerms = {
      'offers-marketplace-terms': (
        <Link to="https://fidelapi.com/legal/offers-marketplace-terms" />
      ),
    };

    return {
      included: {
        'unique-offer-terms': (
          <LinkButton
            type="link"
            data-testid="unique-offer-terms"
            onClick={() => setShowUniqueOfferTerms(true)}
          />
        ),
        ...offersMarketplaceTerms,
      },
      notIncluded: offersMarketplaceTerms,
    };
  }, [setShowUniqueOfferTerms]);

  const uniqueOfferTerms: {
    status: 'included' | 'notIncluded';
    components: Record<string, JSX.Element>;
  } = useMemo(() => {
    const termsStatus = uniqueOffer.additionalTerms
      ? 'included'
      : 'notIncluded';
    return {
      status: termsStatus,
      components: uniqueOfferTermsLabel?.[termsStatus],
    };
  }, [uniqueOffer.additionalTerms, uniqueOfferTermsLabel]);

  return (
    <div style={{ marginTop: 'auto' }}>
      <Divider style={{ margin: '18px 0' }} />
      <Form.Item>
        <RequiredFormItemController
          errors={errors}
          style={{ marginBottom: 0 }}
          controller={{
            name: 'addUniqueOfferTerms',
            render: ({ field: { onChange, value, ...rest } }) => (
              <Checkbox
                {...rest}
                data-testid="add-unique-offer-terms"
                onChange={({ target }) => onChange(target.checked)}
                checked={value}
              >
                <span
                  style={{
                    fontSize: '12px',
                  }}
                >
                  <Trans
                    i18nKey={`createUpdate.terms.add.label.${uniqueOfferTerms.status}`}
                    ns="offers"
                    components={uniqueOfferTerms.components}
                  />
                </span>
              </Checkbox>
            ),
            control,
            rules: {
              required: true,
            },
          }}
        />
      </Form.Item>
    </div>
  );
}
