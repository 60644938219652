import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RocketIcon } from '../../assets/rocket.svg';
import AnnouncementModal from '../../components/modal/AnnouncementModal';
import { RoleCheck } from '../../components/role-check';

interface GoLiveModalProps {
  onClose?: () => void;
}

export const SubmittedGoLiveModal = ({ onClose }: GoLiveModalProps) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'account.goLive.steps.finish',
  });

  return (
    <RoleCheck disabledForModerator>
      <AnnouncementModal
        image={<RocketIcon />}
        title={t('title')}
        description={
          <div>
            <p>{t('descriptionFirst')}</p>
            <p>{t('descriptionSecond')}</p>
          </div>
        }
        okText={t('cta')}
        onOk={onClose}
        visible
      />
    </RoleCheck>
  );
};
