import styled, { createGlobalStyle } from 'styled-components';
import { Button, Checkbox, Input, Menu } from 'antd';

import { colors, translucentBorder } from '../../../theme';

export const DropdownButton = styled(Button).attrs({ size: 'small' })<{
  $active: boolean;
}>`
  border: 2px solid ${colors.grey2};
  color: ${colors.darkblue};
  background: ${colors.grey50};
  font-weight: 400;

  :hover,
  :focus {
    color: ${colors.darkblue};
    border-color: ${colors.fidelBlue800};
  }

  ${({ $active }) =>
    $active &&
    `
    border: 2px solid ${colors.fidelBlue800};
    color: ${colors.white};
    background: ${colors.fidelBlue800};
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    :hover, 
    :focus {
      color: ${colors.white};
      border-color: ${colors.fidelBlue700};
      background: ${colors.fidelBlue700};
    }
  `}

  transition: all 0.3s ease;
`;

export const MoreFiltersButton = styled(DropdownButton).attrs({
  $active: false,
})`
  color: ${colors.fidelBlue600};
  display: flex;
  align-items: center;

  svg {
    width: 18px;
    height: 18px;
    position: relative;
    left: -4px;
  }

  :hover,
  :focus {
    color: ${colors.fidelBlue600};
  }

  &.ant-dropdown-open {
    border-color: ${colors.fidelBlue800};
  }
`;

export const FilterNumber = styled.span`
  display: inline-block;
  background: ${colors.fidelBlue50};
  color: ${colors.fidelBlue800};
  margin-left: 4px;
  padding: 0 6px;
  border-radius: 2px;
`;

export const ClearButton = styled(Button).attrs({ size: 'small' })`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-color: ${colors.fidelBlue900};
  background: ${colors.fidelBlue900};
  color: ${colors.white};
  padding: 1.4px 8px;

  :hover,
  :focus {
    color: ${colors.white};
    border-color: ${colors.fidelBlue700};
    background: ${colors.fidelBlue700};
  }
`;

const menuStyle = `
  overflow: scroll;
  padding: 0;
  margin-bottom: 0;
  border: 1px solid ${colors.black};
  ${translucentBorder};
  max-width: 300px;
`;

export const StyledOverlayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 280px;
  background: ${colors.white};
  border: 1px solid ${colors.black};
  ${translucentBorder};
  border-radius: 4px;
`;

export const StyledList = styled.ul`
  ${menuStyle};
`;

export const StyledMenu = styled(Menu)`
  ${menuStyle};
`;

const menuItemStyle = (active?: boolean) => `
  font-size: 14px;
  padding: 8px 16px;
  cursor: pointer;
  width: 100%;
  background: ${active ? colors.fidelBlue50 : colors.white};

  :hover {
    background: ${colors.grey100};
  }

  :active,
  :focus {
    background: ${colors.fidelBlue50};
  }
`;

export const ListItem = styled.li<{ $active: boolean }>`
  ${({ $active }) => menuItemStyle($active)};
`;

export const SubMenuItem = styled(Menu.SubMenu).attrs({
  popupClassName: 'menu-container',
  popupOffset: [0, -5],
  icon: null,
})`
  &.ant-dropdown-menu-submenu-open {
    div {
      background: ${colors.fidelBlue50};
    }
  }

  div {
    ${menuItemStyle()};
    padding-right: 64px;
  }

  svg {
    display: none;
  }
`;

export const SubmenuContainerStyle = createGlobalStyle`
  .menu-container {
    ul {
      ${menuStyle};
      overflow-x: hidden;
    }

    ${StyledList} {
      width: 100%;
      margin-left: 0;
      border: none;
    }
  }

`;

export const ApplyButtonContainer = styled.div`
  border-top: 1px solid ${colors.lightgrey2};
  padding: 8px;
  position: sticky;
  bottom: 0;
  background: ${colors.white};
  box-shadow: 0 -5px 5px ${colors.white};
`;

export const ApplyButton = styled(Button).attrs({
  type: 'primary',
  size: 'small',
})`
  width: 100%;
`;

export const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 0;
  width: 100%;
  color: ${colors.black};

  &.ant-checkbox-wrapper .ant-checkbox + span {
    display: inline-block;
    width: 100%;
  }
`;

export const Search = styled(Input.Search)`
  position: sticky;
  top: 0;
  border: none;
  border-bottom: 1px solid ${colors.lightgrey2};
  padding: 4px 14px;
  input.antd-input {
    font-size: 14px;
  }
  & .ant-input-group-addon:last-child {
    display: none;
  }
  & .ant-input-affix-wrapper {
    padding: 0;
  }
`;
