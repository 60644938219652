import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import OnboardingPopover from '../components/onboarding-popover';
import { OnboardingTourContext } from '../onboarding-provider';
import { OnboardingStepTypes } from '../onboarding-config';

export const ProgramsStep = () => {
  const { t } = useTranslation();

  const { completeOnboardingStep, setStep } = useContext(OnboardingTourContext);

  return (
    <OnboardingPopover
      placement="bottomLeft"
      target="demo-program"
      index={0}
      title={t('onboarding.programs.title')}
      description={
        <span>
          <Trans
            i18nKey="onboarding.programs.description"
            components={[<strong />]}
          />
        </span>
      }
      buttonText={t('onboarding.buttons.next')}
      action={() => {
        completeOnboardingStep('dashboardPrograms');
        setStep(OnboardingStepTypes.PROGRAMS_CLICK);
      }}
      showSkipTour
    />
  );
};
