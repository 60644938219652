import styled from 'styled-components';
import { colors } from '../../../../theme';

export const Card = styled.div`
  font-size: 12px;
  color: ${colors.grey4};

  margin: 1em 0;
  padding: 10px 14px;
  border-radius: 4px;
  width: 100%;
`;

export const DividerHeading = styled.p`
  width: 100%;
  font-size: 12px;
  color: ${colors.grey4};
  padding-bottom: 2px;
  border-bottom: 1px solid ${colors.lightgrey2};

  /* Sticky header workaround to avoid poke-through */
  position: sticky;
  top: -20px;
  margin-top: -10px;
  padding-top: 20px;
  background: white;
  z-index: 1;
`;

export const Line = styled.p`
  margin: 0;
  display: flex;
  justify-content: space-between;
`;

export const Currency = styled.span`
  font-size: 14px;
`;

export const Amount = styled(Currency)`
  color: ${colors.black};
  font-weight: 500;
`;

export const TransactionContainer = styled(Card)<{ $selected: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background: ${p => (p.$selected ? 'rgba(33, 177, 21, 0.1)' : colors.white)};
  border: 2px solid
    ${p => (p.$selected ? 'rgba(33, 177, 21, 1)' : colors.grey2)};

  cursor: pointer;
  user-select: none;
`;

export const DateTime = styled.span`
  color: ${colors.black};
  font-size: 14px;
`;

export const BrandLogo = styled.div`
  width: 42px;
  height: 42px;
  border: 1px solid ${colors.lightgrey2};
  border-radius: 3px;
  margin-right: 8px;
  padding: 2px;
`;

export const NoTransactions = styled.p`
  text-align: center;
  padding: 80px 0;
`;
