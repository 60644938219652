import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Row, Col } from 'antd';
import { FormProvider, useForm } from 'react-hook-form';
import { shallowEqual } from 'react-redux';

import { Offer } from '../../../store/offers/offers-model';
import {
  parseSupplierInfoToObject,
  transformOfferToDetailTitle,
} from '../utils';
import DetailTitle from '../../../components/detail/components/DetailTitle';
import UniqueOfferTerms from '../components/OfferTerms';

import ProgramSelection from './components/ProgramSelection';
import OptionalCustomization from './components/OptionalCustomization';
import SupplierRequiredFields from './components/SupplierRequiredFields';
import MarketplaceTerms from './components/MarketplaceTerms';
import DrawerForm from '../../../components/drawer-form';
import { AddUniqueOfferFormModel, SelectedUniqueOfferPayload } from '../types';
import { addUniqueOffer } from '../../../store/offers';
import { OffersMarketplaceOnboardingContext } from '../offers-marketplace-onboarding/context/OffersMarketplaceOnboardingContext';
import { OfferMarketplaceOnboardingStepTypes } from '../offers-marketplace-onboarding/config';
import drawerTransition, {
  DrawerVisibleProps,
} from '../../../components/drawer-transition';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { queryClient } from '../../../react-query';

export interface AddUniqueOfferProps extends DrawerVisibleProps {
  uniqueOffer: Offer;
  onClose: () => void;
  setSelectedUniqueOfferPayload: (payload: SelectedUniqueOfferPayload) => void;
}

function AddUniqueOffer({
  uniqueOffer,
  onClose,
  visible,
  afterVisibleChange,
  setSelectedUniqueOfferPayload,
}: AddUniqueOfferProps) {
  const { t } = useTranslation('offers');
  const dispatch = useAppDispatch();
  const [showUniqueOfferTerms, setShowUniqueOfferTerms] = useState(false);

  const { loading, error } = useAppSelector(
    state => ({
      loading: state.offer.loading || state.offers.loading,
      error: state.offer.error,
    }),
    shallowEqual,
  );

  const form = useForm<AddUniqueOfferFormModel>({
    mode: 'onBlur',
    defaultValues: {},
  });

  const { handleSubmit } = form;

  const { id, transactionSource } = uniqueOffer;

  const {
    completeOnboardingStep,
    setStep: setOnboardingStep,
    step: onboardingStep,
  } = useContext(OffersMarketplaceOnboardingContext);

  function onSubmit({
    programIds,
    supplierInfo,
    qualifiedTransactionsLimit: cardActivationLimit,
  }: AddUniqueOfferFormModel) {
    const supplierFields = parseSupplierInfoToObject(supplierInfo);

    if (id && programIds?.length) {
      const profileModalShouldBeDisplayed =
        !error && !!uniqueOffer.requiresApproval;

      const payload = {
        id,
        programIds,
        supplierFields,
        cardActivationLimit,
        transactionSource,
      };

      if (profileModalShouldBeDisplayed) {
        setSelectedUniqueOfferPayload(payload);
      }

      const onFinishHandler = () => {
        if (
          onboardingStep ===
          OfferMarketplaceOnboardingStepTypes.OFFER_REQUESTS_TAB
        )
          completeOnboardingStep('dashboardOffersMarketplaceRequestsTab');
        if (onboardingStep === OfferMarketplaceOnboardingStepTypes.BLANK)
          setOnboardingStep(
            OfferMarketplaceOnboardingStepTypes.OFFER_REQUESTS_TAB,
          );

        onClose();
        queryClient.invalidateQueries(['offers']);
      };

      dispatch(addUniqueOffer(payload, onFinishHandler));
    }
  }

  return (
    <DrawerForm
      title={t('createUpdate.title.add')}
      confirmText={t(
        `createUpdate.confirmText.${
          uniqueOffer.requiresApproval ? 'submit' : 'add'
        }`,
      )}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      submitDisabled={loading}
      processing={loading}
      visible={visible}
      afterVisibleChange={afterVisibleChange}
    >
      <FormProvider {...form}>
        <Row>
          <Col span={24}>
            <DetailTitle
              {...transformOfferToDetailTitle(uniqueOffer)}
              description={t('createUpdate.locations.detail.unique', {
                brandCreationDate: moment(uniqueOffer.created).format(
                  'MMM DD, YYYY HH:mm',
                ),
              })}
            />
          </Col>
        </Row>

        <ProgramSelection uniqueOffer={uniqueOffer} />

        <SupplierRequiredFields uniqueOffer={uniqueOffer} />

        <OptionalCustomization uniqueOffer={uniqueOffer} />

        <MarketplaceTerms
          uniqueOffer={uniqueOffer}
          setShowUniqueOfferTerms={setShowUniqueOfferTerms}
        />

        <UniqueOfferTerms
          offerTerms={uniqueOffer.additionalTerms}
          visible={showUniqueOfferTerms}
          setVisible={setShowUniqueOfferTerms}
        />
      </FormProvider>
    </DrawerForm>
  );
}

export default drawerTransition(AddUniqueOffer);
