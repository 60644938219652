import React, { useState } from 'react';
import { range } from 'lodash';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox, Input } from 'antd';

import { updateBillingConfigurations } from '../../../../store/account/account-actions';
import SelectTransactionBillingPeriods from './billing-periods-tables/SelectTransactionBillingPeriods';
import AdjustmentBillingPeriods from './billing-periods-tables/AdjustmentBillingPeriods';
import TransactionStreamBillingPeriods from './billing-periods-tables/TransactionStreamBillingPeriods';
import ContractForm from './ContractForm';
import {
  ItemDescription,
  InputWithSideText,
} from '../../../../components/inline-form';
import { FieldWithCurrencyWrapper } from '../../../../components/form/styled';
import regex from '../../../../utils/regex';
import { FeatureCheck } from '../../../../features';
import { Subsection, SubsectionSubtitle, SubsectionTitle } from '../../styled';
import { useInvoiceInterval } from '../create-update-billing-period/utils';
import useCurrentPeriod from '../../../../hooks/use-current-period';
import { Select } from '../../../../components/select';
import { numberOfFuturePeriodsToShow } from '../create-update-billing-period/CreateUpdateBillingPeriod';
import { FilterPeriodType } from './billing-periods-tables/BillingPeriodsTables';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

interface FeesAndBillingForm {
  invoiceDay: number;
  onboardingFee: number;
  pricingModel: string;
  invoicesDisabled: boolean;
}

const FeesAndBilling = () => {
  const { t } = useTranslation('common', {
    keyPrefix: 'account.contract.billingPeriods',
  });
  const dispatch = useAppDispatch();
  const { getInvoiceInterval } = useInvoiceInterval();
  const currentInvoice = useCurrentPeriod();
  const [filteredInvoice, setFilteredInvoice] =
    useState<FilterPeriodType>('all');
  const billingConfigurations = useAppSelector(
    state => state.account.billingConfigurations.entity,
  );

  const { currency, invoiceDay: currentInvoiceDay } =
    billingConfigurations || {};

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FeesAndBillingForm>({
    mode: 'onBlur',
    defaultValues: {
      invoiceDay: currentInvoiceDay,
      onboardingFee: billingConfigurations?.onboardingFee,
      pricingModel: t('form.pricingModelValue'),
      invoicesDisabled: billingConfigurations?.invoicesDisabled,
    },
  });

  function onSubmit({
    invoiceDay,
    onboardingFee,
    invoicesDisabled,
  }: FeesAndBillingForm) {
    dispatch(
      updateBillingConfigurations({
        invoiceDay: Number(invoiceDay),
        onboardingFee: Number(onboardingFee),
        invoicesDisabled,
      }),
    );
  }

  const filterOptions = range(numberOfFuturePeriodsToShow).map(month => {
    const isPastMonth = month < currentInvoice;

    const { end } = getInvoiceInterval(month - currentInvoice);
    if (month === 0)
      return (
        <Select.Option key="all" value="all">
          {t('createUpdate.invoiceNumberOptionFilter.all')}
        </Select.Option>
      );

    return (
      <Select.Option key={month} value={month} data-testid={`filter-${month}`}>
        {t(
          `createUpdate.invoiceNumberOptionFilter.${
            isPastMonth ? 'past' : 'rest'
          }`,
          {
            invoiceNumber: month,
            end,
          },
        )}
      </Select.Option>
    );
  });

  return (
    <>
      <Subsection style={{ paddingBottom: '40px' }}>
        <ContractForm onSubmit={handleSubmit(onSubmit)}>
          <ContractForm.ItemController
            label={t('form.invoiceDay')}
            itemDescription={
              <ItemDescription warning>
                {t('form.invoiceDayDescription')}
              </ItemDescription>
            }
            errors={errors}
            controller={{
              name: 'invoiceDay',
              render: ({ field: { ref, ...rest } }) => (
                <InputWithSideText {...rest} text={t('form.ofTheMonth')} />
              ),
              rules: {
                required: true,
                min: 1,
                max: 31,
                validate: { numeric: value => regex.numeric.test(value) },
              },
              control,
            }}
          />
          <FieldWithCurrencyWrapper>
            <ContractForm.ItemController
              label={t('form.onboardingFee')}
              errors={errors}
              controller={{
                name: 'onboardingFee',
                render: ({ field }) => (
                  <Input
                    {...field}
                    style={{ maxWidth: '176px' }}
                    min={0}
                    prefix={currency}
                  />
                ),
                rules: {
                  required: true,
                  validate: { numeric: value => regex.numeric.test(value) },
                },
                control,
              }}
            />
          </FieldWithCurrencyWrapper>
          <FeatureCheck feature="invoicesDisabled">
            <ContractForm.ItemController
              wrapperCol={{ sm: { offset: 10 } }}
              itemDescription={
                <ItemDescription warning>
                  {t('form.invoicesDisabledDescription')}
                </ItemDescription>
              }
              style={{ margin: 0 }}
              controller={{
                name: 'invoicesDisabled',
                render: ({ field: { value, onChange, ...rest } }) => (
                  <Checkbox
                    {...rest}
                    data-testid="disable-invoices"
                    defaultChecked={billingConfigurations?.invoicesDisabled}
                    onChange={({ target: { checked } }) => onChange(checked)}
                    checked={value}
                  >
                    {t('form.invoicesDisabled')}
                  </Checkbox>
                ),
                control,
              }}
            />
          </FeatureCheck>
        </ContractForm>
      </Subsection>
      <Subsection>
        <SubsectionTitle>
          <h2>{t('title.main')}</h2>
          <Select
            dropdownMatchSelectWidth={false}
            onChange={value =>
              setFilteredInvoice(value === 'all' ? 'all' : Number(value))
            }
            defaultValue="all"
            style={{ width: '260px' }}
          >
            {filterOptions}
          </Select>
        </SubsectionTitle>
        {currentInvoiceDay && currentInvoice && (
          <SubsectionSubtitle>
            {t('description', {
              currentPeriod: currentInvoice,
              date: getInvoiceInterval().end,
            })}
          </SubsectionSubtitle>
        )}
      </Subsection>
      <SelectTransactionBillingPeriods
        showingPeriod={filteredInvoice}
        currentInvoice={currentInvoice}
      >
        <ContractForm.ItemController
          label={t('form.pricingModel')}
          style={{ marginBottom: '38px' }}
          controller={{
            name: 'pricingModel',
            render: ({ field }) => <Input {...field} disabled />,
            control,
          }}
        />
      </SelectTransactionBillingPeriods>
      <TransactionStreamBillingPeriods
        showingPeriod={filteredInvoice}
        currentInvoice={currentInvoice}
      />
      <AdjustmentBillingPeriods
        showingPeriod={filteredInvoice}
        currentInvoice={currentInvoice}
      />
    </>
  );
};

export default React.memo(FeesAndBilling);
