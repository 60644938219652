import styled from 'styled-components';

import { Steps as AntdSteps } from 'antd';
import { breakpoints, colors } from '../../../theme';

export const GoLiveContainer = styled.div<{ fullWidth: boolean }>`
  max-width: 80%;
  margin: 0 auto;

  ${props =>
    !props.fullWidth &&
    `
    width: 408px;
    padding: 24px 24px 40px 24px;
  `}

  @media (min-width: ${breakpoints.tabletPortrait}px) {
    max-width: 100%;
  }
`;

export const IntroDescription = styled.p`
  margin-top: 12px;
  white-space: pre-line;
  color: ${colors.grey4};
`;

export const Description = styled.p`
  white-space: pre-line;
  margin-bottom: 8px;
`;

export const Title = styled.h2`
  margin: 50px 0 36px;
`;

export const Steps = styled(AntdSteps)`
  width: max-content;
  position: fixed;
  left: 30px;
  top: 180px;

  .ant-steps-item-title {
    display: none;
  }

  .ant-steps-ItemController-tail {
    display: none !important;
  }

  .ant-steps-item-icon {
    background: ${colors.grey2};
    color: ${colors.grey4};
    border: none;

    & > .ant-steps-icon {
      top: 1px;
      font-weight: 500;
    }
  }

  .ant-steps-item-active {
    .ant-steps-item-title {
      color: ${colors.fidelBlue600} !important;
      font-weight: normal;
    }

    .ant-steps-item-icon {
      background: ${colors.fidelBlue600};
      color: ${colors.white};
    }
  }

  @media (min-width: ${breakpoints.tabletPortrait + 60}px) {
    .ant-steps-item-title {
      display: block;
    }
  }
`;

export const PoweredByStripe = styled.div`
  margin-top: 60px;
  padding: 10px;

  border-top: 1px solid ${colors.lightgrey2};

  text-align: center;
  font-size: 12px;
  color: #a3a3a3;

  img {
    height: 20px;
  }
`;
