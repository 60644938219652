import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormReturn } from 'react-hook-form';
import { Row, Col, Typography, Button, Input } from 'antd';
import { omit } from 'lodash';
import {
  updateAccountSecurity,
  updateProfile,
} from '../../../../store/account/account-actions';
import useProgressState from '../../../../hooks/use-progress-state';
import regex from '../../../../utils/regex';
import Form from '../../../../components/form';
import { InlineButton } from '../../../../components/buttons';
import { Subsection } from '../../styled';
import { FieldProps, ProfileFormModel } from './types';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

interface FieldSectionProps {
  fieldProps: FieldProps;
  form: UseFormReturn<ProfileFormModel>;
  isMobile: boolean;
}

const FieldSection = ({ fieldProps, form, isMobile }: FieldSectionProps) => {
  const { t } = useTranslation();
  const { t: tPublisherProfile } = useTranslation('common', {
    keyPrefix: 'account.publisherProfile',
  });
  const dispatch = useAppDispatch();
  const { storedProfile, companyName, updatingProfile, updatingCompanyName } =
    useAppSelector(({ account }) => ({
      storedProfile: account.profile.entity,
      companyName: account.details?.name,
      updatingProfile: account.profile.updating,
      updatingCompanyName: account.loading,
    }));

  const [isActive, setIsActive] = useState(false);
  const [showActionButton, setShowActionButton] = useState(isMobile);

  const { onFinish: onUpdateFinish } = useProgressState(
    updatingProfile || updatingCompanyName,
  );

  const { name, type, validation } = fieldProps;
  const { formState, control, setValue, watch } = form;

  const currentValue = watch(name);
  const storedValue = name === 'company' ? companyName : storedProfile[name];

  const hasErrors = !!formState.errors[name];
  const isDisabled =
    (name.endsWith('Url') && !currentValue) ||
    currentValue === storedValue ||
    hasErrors;
  const isLoading = !!currentValue && (updatingProfile || updatingCompanyName);

  const actionLabel = storedValue ? 'edit' : 'add';

  const FieldElement = {
    input: Input,
    textarea: Input.TextArea,
  }[type];

  const urlValidation = {
    validate: {
      invalidUrl: (url: string) => !url || regex.httpsUrl.test(url),
    },
  };

  function addEditClickHandler() {
    setIsActive(true);

    if (storedValue) setValue(name, storedValue);
  }
  function saveClickHandler() {
    if (name === 'company')
      dispatch(updateAccountSecurity({ companyName: currentValue }));
    else {
      const profile = omit(storedProfile, ['accountId', 'created', 'updated']);
      dispatch(updateProfile({ ...profile, [name]: currentValue }));
    }
  }
  function cancelClickHandler() {
    setIsActive(false);
  }
  function sectionMouseEnterHandler() {
    !isMobile && setShowActionButton(true);
  }
  function sectionMouseLeaveHandler() {
    !isMobile && showActionButton && setShowActionButton(false);
  }

  useEffect(() => {
    setShowActionButton(isMobile);
  }, [isMobile]);

  onUpdateFinish(() => setIsActive(false));

  return (
    <Subsection
      $includeLastBorder
      onMouseEnter={sectionMouseEnterHandler}
      onMouseLeave={sectionMouseLeaveHandler}
      data-testid={`${name}-section`}
    >
      <Row>
        <Col span={24}>
          <Row gutter={16}>
            <Col xs={24} sm={6}>
              <Typography.Paragraph>
                {tPublisherProfile(`fields.labels.${name}`)}
              </Typography.Paragraph>
            </Col>

            <Col xs={20} sm={16}>
              {isActive ? (
                <>
                  <Row>
                    <Col span={24}>
                      <Form.ItemController
                        errors={formState.errors}
                        validateCharacters={!fieldProps.validation}
                        controller={{
                          name,
                          render: ({ field }) => (
                            <FieldElement
                              {...field}
                              rows={6}
                              data-testid={`${name}-input`}
                            />
                          ),
                          control,
                          rules: validation && urlValidation,
                        }}
                      />
                    </Col>
                  </Row>

                  <Row align="middle" gutter={16}>
                    <Col>
                      <Button
                        type="primary"
                        disabled={isDisabled}
                        loading={isLoading}
                        onClick={saveClickHandler}
                        data-testid={`save-${name}`}
                      >
                        {t('confirm.save')}
                      </Button>
                    </Col>

                    <Col>
                      <InlineButton
                        type="link"
                        onClick={cancelClickHandler}
                        data-testid={`cancel-${name}`}
                      >
                        {t('confirm.cancel')}
                      </InlineButton>
                    </Col>
                  </Row>
                </>
              ) : (
                <Typography.Paragraph>
                  {storedValue || tPublisherProfile('fields.placeholder')}
                </Typography.Paragraph>
              )}
            </Col>

            <Col xs={4} sm={2}>
              {isActive ||
                ((showActionButton || !storedValue) && (
                  <InlineButton type="link" onClick={addEditClickHandler}>
                    {tPublisherProfile(`actions.${actionLabel}`)}
                  </InlineButton>
                ))}
            </Col>
          </Row>
        </Col>
      </Row>
    </Subsection>
  );
};

export default FieldSection;
