import { getAllItems } from '../utils/pagination';
import http from '../../services/http-service';

export default class UserApiService {
  public getUser(userId: string | undefined) {
    return http.get(`users/${userId}`);
  }

  public inviteUser({ email, role }: { email: string; role: string }) {
    return http.post('users', { email, role });
  }

  public revokeUser({ userId }: { userId: string }) {
    return http.patch(`users/${userId}`, {
      permission: [],
    });
  }

  public cancelUserInvite({ userId }: { userId: string }) {
    return http.delete(`users/${userId}`);
  }

  public async getUsers() {
    return getAllItems(last =>
      http.get('users', {
        params: {
          start: JSON.stringify(last),
        },
      }),
    );
  }
}
