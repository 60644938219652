import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Link from '../../components/link';
import AnnouncementModal from '../../components/modal/AnnouncementModal';

const BigPartyPopper = styled.span`
  font-size: 48px;
`;

const NewFinalGoLiveModal = ({ onClose }: { onClose?: () => void }) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'account.goLive.steps.live',
  });

  return (
    <AnnouncementModal
      confetti
      image={
        // eslint-disable-next-line jsx-a11y/accessible-emoji
        <BigPartyPopper role="img" aria-label="party popper">
          🎉
        </BigPartyPopper>
      }
      title={t('title')}
      description={
        <div>
          <p>{t('descriptionFirst')}</p>
          <p>
            <Trans
              i18nKey="account.goLive.steps.live.descriptionSecond"
              components={[
                <Link to="https://fidelapi.com/docs/" />,
                <Link to="https://fidellimited.zendesk.com/hc/en-gb" />,
              ]}
            />
          </p>
        </div>
      }
      okText={t('cta')}
      onOk={onClose}
      visible
    />
  );
};

export default NewFinalGoLiveModal;
