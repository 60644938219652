import { Amplify, Auth } from 'aws-amplify';
import http from './services/http-service';
import { cognito } from './config';

// eslint-disable-next-line consistent-return
export async function currentAuthSession() {
  try {
    const session = await Auth.currentSession();
    if (session) {
      return session;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export function bootstrapAuth() {
  Amplify.configure({
    Auth: {
      region: 'eu-west-1',
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      userPoolId: cognito.POOL_ID,
      userPoolWebClientId: cognito.CLIENT_ID,
    },
  });

  http.addRequestInterceptor(async req => {
    const authSession = await currentAuthSession();

    if (authSession && !req.url?.includes(cognito.DOMAIN_URL)) {
      (req.headers as any).Authorization = `Bearer ${authSession
        .getIdToken()
        .getJwtToken()}`;
    }

    return req;
  });
}
