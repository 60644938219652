import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, Select } from 'antd';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ContentProviderOfferFormModel } from '../../../types';
import Form from '../../../../../components/form';
import createUpdateOfferRules from '../../validation';

export interface CustomerRecencyProps {
  disabled: boolean;
}

function CustomerRecency({ disabled }: CustomerRecencyProps) {
  const { t } = useTranslation('offers', {
    keyPrefix: 'fields.customerRecency',
  });
  const {
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useFormContext<ContentProviderOfferFormModel>();

  const recencyValue = useMemo(() => {
    const { customerRecencyEnabled } = getValues();

    return customerRecencyEnabled !== undefined
      ? customerRecencyEnabled
      : false;
  }, [getValues]);

  const [customerRecency, setCustomerRecency] = useState(recencyValue);

  const recencyOptions = [
    { label: 1, value: 30 },
    { label: 2, value: 60 },
    { label: 3, value: 90 },
  ];
  const selectOptions = recencyOptions.map(({ label, value }, index) => (
    <Select.Option key={`recency-option-${index}`} value={value}>
      {t('select.option', { count: label })}
    </Select.Option>
  ));

  useEffect(() => {
    setValue('customerRecencyEnabled', customerRecency);
  }, [customerRecency, setValue]);

  function onChangeHandler({ target }: CheckboxChangeEvent) {
    setCustomerRecency(target.checked);
  }

  return (
    <Form.Item>
      <Checkbox
        data-testid="customer-recency"
        checked={customerRecency}
        onChange={onChangeHandler}
        disabled={disabled}
      >
        {t('label')}
      </Checkbox>
      {customerRecency && (
        <Form.ItemController
          label={t('select.label')}
          errors={errors}
          controller={{
            name: 'customerRecencyDays',
            render: ({ field }) => (
              <Select
                {...field}
                data-name="customer-recency-days"
                disabled={disabled}
                placeholder={t('select.placeholder')}
              >
                {selectOptions}
              </Select>
            ),
            rules: createUpdateOfferRules.customerRecency,
            control,
          }}
        />
      )}
    </Form.Item>
  );
}

export default CustomerRecency;
