import React, { ReactNode, useContext, useEffect } from 'react';
import { shallowEqual } from 'react-redux';

import { getPrograms, clear } from '../store/programs/programs-actions';
import { selectProgramsAsList } from '../store/programs/programs-selectors';
import createPersistedState from './create-persisted-state';
import { Program } from '../store/programs/programs-reducer';
import { createSetStateContext } from '../utils/create-set-state-context';
import { useAppDispatch, useAppSelector } from '../store/hooks';

const useSelectedProgramIdState = createPersistedState<string | null>(
  'FIDEL_SELECTED_PROGRAM_ID',
  null,
);

const defaultState: Program = {} as Program;

const SelectedProgramContext = createSetStateContext(defaultState);

function useOnLiveChange(...args: any[]) {
  // There is an issue in the load order for mocking use-is-signed-in

  const useOnLiveChangeFromModule = require('./use-on-live-change').default;
  return useOnLiveChangeFromModule(...args);
}

function useIsSignedIn(...args: any[]) {
  // There is an issue in the load order for mocking use-is-signed-in

  const useIsSignedInFromModule = require('./use-is-signed-in').default;
  return useIsSignedInFromModule(...args);
}

export const SelectedProgramProvider = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const [selectedProgramId, setSelectedProgramId] = useSelectedProgramIdState();

  const { signedIn } = useIsSignedIn();
  const programs = useAppSelector(selectProgramsAsList)();
  const { hasAccount, selectedProgram } = useAppSelector(
    state => ({
      hasAccount: !!state.account.details,
      selectedProgram: state.programs.programs[selectedProgramId || ''],
    }),
    shallowEqual,
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (signedIn && hasAccount) dispatch(getPrograms());
    else {
      dispatch(clear());
    }
  }, [dispatch, signedIn, hasAccount]);

  useOnLiveChange(() => {
    setSelectedProgramId(null);
    dispatch(getPrograms());
  });

  // Set to either stored program or first program
  useEffect(() => {
    if (signedIn && !selectedProgramId && programs.length > 0) {
      setSelectedProgramId(programs[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programs, signedIn]);

  // Reset to first program if cannot find selected program
  useEffect(() => {
    if (selectedProgramId && !selectedProgram && programs.length > 0) {
      setSelectedProgramId(programs[0].id);
    }
  }, [programs, selectedProgram, selectedProgramId, setSelectedProgramId]);

  return (
    <SelectedProgramContext.Provider
      value={[selectedProgram, setSelectedProgramId]}
    >
      {children}
    </SelectedProgramContext.Provider>
  );
};

export default function useSelectedProgram() {
  const [selectedProgram, setSelectedProgramId] = useContext(
    SelectedProgramContext,
  );

  return { selectedProgram, setSelectedProgramId };
}
