import React, { useContext } from 'react';
import OnboardingCursor from '../components/onboarding-cursor';
import { OnboardingTourContext } from '../onboarding-provider';
import { OnboardingStepTypes } from '../onboarding-config';
import useRedirect from '../../../hooks/use-redirect';

export const CardsClickStep2 = () => {
  const redirect = useRedirect();
  const { setStep, lastCursorPosition } = useContext(OnboardingTourContext);

  return (
    <OnboardingCursor
      target="menu-item-brands"
      startX={lastCursorPosition.x}
      startY={lastCursorPosition.y}
      action={() => {
        setStep(OnboardingStepTypes.BRANDS);
        redirect('/brands');
      }}
    />
  );
};
