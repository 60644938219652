import React from 'react';
import { Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import styled from 'styled-components';
import Avatar, { BadgeProps } from '../../../components/avatar';
import { Offer, OfferType } from '../../../store/offers/offers-model';
import { CardContainer } from '../../../components/tables/styled/helpers';
import { isUniqueOffer } from '../utils';
import { ReactComponent as MarketBadge } from '../../../assets/market-badge.svg';
import Ellipsis from '../../../components/ellipsis';
import OfferLabels from './OfferLabels';

export interface OfferCardProps {
  offer: Offer;
}

export default function OfferCard({ offer }: OfferCardProps) {
  const { name, brandName, brandLogoURL } = offer;

  const { t } = useTranslation('offers');
  const { type: offerType } = useParams<{ type: OfferType }>();

  const AvatarBadge =
    offerType !== 'marketplace' && isUniqueOffer(offer)
      ? ({
          icon: <MarketBadge />,
          color: { background: 'fidelBlue600' },
          tooltip: {
            title: t('marketplace.avatarBadgeTooltip'),
            placement: 'rightTop',
          },
        } as BadgeProps)
      : undefined;

  return (
    <CardContainer
      role="button"
      data-testid="offer-card-container"
      data-onboarding-target={
        offerType === 'marketplace' ? 'demo-marketplace-offer' : ''
      }
    >
      <Row align="middle" wrap={false}>
        <Col>
          <Avatar
            src={brandLogoURL}
            color={{ border: 'lightgrey2' }}
            badge={AvatarBadge}
            size={48}
          >
            {brandName}
          </Avatar>
        </Col>
        <StyledCol $widthShrink={50}>
          <OfferLabels offer={offer} />

          <Row align="middle" style={{ maxWidth: '100%' }}>
            <StyledCol>
              <Ellipsis>{brandName}</Ellipsis>
            </StyledCol>
          </Row>

          <Row style={{ maxWidth: '100%' }}>
            <Typography.Text style={{ fontSize: 14, width: '100%' }}>
              <Ellipsis>{name}</Ellipsis>
            </Typography.Text>
          </Row>
        </StyledCol>
      </Row>
    </CardContainer>
  );
}

const StyledCol = styled(Col)<{
  $widthShrink?: number;
}>`
  flex-grow: 1;
  max-width: ${({ $widthShrink }) =>
    $widthShrink ? `calc(100% - ${$widthShrink}px)` : '100%'};
`;
