const blacklistedWebhookHeaders = new Set([
  'A-IM',
  'ACCEPT',
  'ACCEPT-CHARSET',
  'ACCEPT-ENCODING',
  'ACCEPT-LANGUAGE',
  'ACCEPT-DATETIME',
  'ACCESS-CONTROL-REQUEST-METHOD',
  'ACCESS-CONTROL-REQUEST-HEADERS',
  'CACHE-CONTROL',
  'CONNECTION',
  'CONTENT-LENGTH',
  'CONTENT-TYPE',
  'COOKIE',
  'DATE',
  'EXPECT',
  'FORWARDED',
  'FROM',
  'HOST',
  'IF-MATCH',
  'IF-MODIFIED-SINCE',
  'IF-NONE-MATCH',
  'IF-RANGE',
  'IF-UNMODIFIED-SINCE',
  'MAX-FORWARDS',
  'ORIGIN',
  'PRAGMA',
  'PROXY-AUTHORIZATION',
  'RANGE',
  'REFERER',
  'TE',
  'TRANSFER-ENCODING',
  'USER-AGENT',
  'UPGRADE',
  'VIA',
  'WARNING',
  'FIDEL-ACCOUNT',
  'FIDEL-KEY',
  'FIDEL-LIVE',
  'FIDEL-REQUEST-ID',
  'X-FIDEL-SIGNATURE',
  'X-FIDEL-TIMESTAMP',
  'FIDEL-USER',
]);

export default function isHeaderAllowed(header: string) {
  return !header || !blacklistedWebhookHeaders.has(header.toUpperCase());
}
