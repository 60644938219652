import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { colors } from '../../theme';
import { WarningCircle } from '../icons';

interface WarningRowProps {
  style?: React.CSSProperties;
  children: ReactNode;
}

const WarningRow = ({ style, children }: WarningRowProps) => (
  <WarningRowStyled style={style}>
    <WarningCircle style={{ marginRight: '8px' }} />
    {children}
  </WarningRowStyled>
);

const WarningRowStyled = styled.div`
  color: ${colors.darkOrange};
  background: ${colors.yellow3};
  font-size: 13px;
  padding: 8px 19px;
  display: flex;
  align-items: center;

  a {
    display: contents;
  }
`;

export default WarningRow;
