import styled, { css } from 'styled-components';
import { Card, Row } from 'antd';

import { StyledMenu } from '../../menu';
import { colors, hiddenScrollbar } from '../../../theme';
import { ActionsMenuButton } from '../../actions-menu/styled';

export const ActionsMenuContainer = styled.div`
  padding-left: 7px;
  ${ActionsMenuButton} {
    opacity: 1;
    border-radius: 4px;
    background: ${colors.lightgrey3};
  }

  ${StyledMenu} {
    right: 0;
  }
`;

export const DetailCard = styled(Card)`
  width: 100%;

  .ant-card-head {
    border-bottom: none;
    padding: 0;

    .ant-card-head-title {
      white-space: normal;
      word-break: break-all;
    }
  }

  .ant-card-body {
    padding: 0;

    .ant-spin {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }
  }
`;

export const DetailCardFooter = styled(Row)`
  position: sticky;
  bottom: 0;
  padding-top: 20px;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 70%,
    rgba(0, 212, 255, 0) 100%
  );

  .ant-card-body {
    padding: 0;
  }

  @media (max-width: 1200px) {
    padding: 20px 0;
  }
`;

export const DetailOverlay = styled.div`
  background: rgba(0, 0, 0, 0.15);
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -1;
`;

export const DetailContainer = styled.div<{ visible: boolean }>`
  position: sticky;
  overflow: auto;
  top: 0;
  width: 360px;
  height: calc(100vh - 150px);
  ${hiddenScrollbar}

  @media (max-width: 1200px) {
    display: none;
    margin-top: 30px;
    height: auto;
    ${({ visible }) =>
      visible &&
      css`
        align-items: center;
        display: flex;
        justify-content: center;
        position: fixed;
        background: transparent;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        z-index: 3;
        min-width: 100vw;

        ${DetailOverlay} {
          display: block;
        }

        .ant-card {
          max-width: 380px;
          max-height: 100%;
          height: 600px;
          margin: 24px;
          position: relative;
          overflow: auto;

          .ant-card-head {
            position: sticky;
            top: 0;
            z-index: 2;

            .ant-card-head-title {
              padding-top: 0;
            }
          }

          .ant-card-body {
            max-height: 400px;
            padding: 24px;
          }
        }
      `}
  }
`;
