import React, { useMemo, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isBoolean } from 'lodash';
import { CreateUpdateOfferFormModel } from '../../../types';

import regex from '../../../../../utils/regex';
import createUpdateOfferRules from '../../validation';

import { CustomCollapse } from '../../../../../components/collapse/styled';
import KeyValuePairs from '../../../../../components/form/components/KeyValuePairs';
import {
  protectedMetadataPropsList,
  ProtectedMetadataPropsValues,
} from '../../../../../store/offers/offers-model';

interface MetadataFieldsProps {
  storedMetadata: CreateUpdateOfferFormModel['metadata'] | undefined;
}

export default function MetadataFields({
  storedMetadata,
}: MetadataFieldsProps) {
  const { t } = useTranslation('offers');

  const { current: defaultMetadata } = useRef(storedMetadata);

  const {
    control,
    formState: { errors },
  } = useFormContext<CreateUpdateOfferFormModel>();

  const metadata = useWatch({
    control,
    name: 'metadata',
    defaultValue: storedMetadata,
  });

  const metadataFields = useMemo(
    () =>
      defaultMetadata?.map(({ key, value }) => ({
        key,
        value: isBoolean(value) ? `${value}` : value,
      })),
    [defaultMetadata],
  );

  function alphanumericDashes(value: string) {
    return regex.alphanumericDashes.test(value);
  }

  function invalidKey(value: ProtectedMetadataPropsValues) {
    return !protectedMetadataPropsList.includes(value);
  }

  const keyField = {
    rules: {
      required: true,
      minLength: 5,
      maxLength: 40,
      validate: {
        alphanumericDashes,
        invalidKey,
      },
    },
  };

  const collapseKey = 'collapse-panel-metadata';
  const defaultActiveKey = [metadata?.length ? collapseKey : ''];

  return (
    <CustomCollapse defaultActiveKey={defaultActiveKey}>
      <CustomCollapse.Panel
        header={t('fields.metadata.label')}
        key={collapseKey}
      >
        <KeyValuePairs
          name="metadata"
          errors={errors?.metadata}
          fixedFields={false}
          defaultFields={metadataFields}
          keyField={keyField}
          valueField={{
            rules: createUpdateOfferRules.metadata,
          }}
        />
      </CustomCollapse.Panel>
    </CustomCollapse>
  );
}
