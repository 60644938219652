import { Input } from 'antd';
import styled from 'styled-components';
import { colors } from '../../../theme';

export const Search = styled(Input)<{ $hasInput?: boolean }>`
  align-items: center;
  height: 32px;
  max-width: ${({ $hasInput }) => ($hasInput ? '200px' : '150px')};
  padding: 5px;

  &.ant-input-affix-wrapper-focused {
    max-width: 200px;
  }

  input {
    font-size: 14px;
    &::placeholder {
      color: ${colors.grey4};
    }
  }
`;
