import React from 'react';
import { shallowEqual } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import ImageUpload, {
  CroppedImages,
} from '../../../../components/upload/CroppedImageUpload';
import { ProfileFormModel } from '../types';
import { resources } from '../../../../config';
import { useAppSelector } from '../../../../store/hooks';

const PhotoStep = () => {
  const { setValue } = useFormContext<ProfileFormModel>();

  const { accountImage, accountDetails } = useAppSelector(
    ({ account }) => ({
      accountImage: account.images.entities.squared,
      accountDetails: account.details,
    }),
    shallowEqual,
  );

  function onImagesCrop({ squared, rounded }: CroppedImages) {
    setValue('photo', { image: squared, croppedImage: rounded });
  }

  function getAccountImage() {
    const baseAccountImagePath = accountImage || accountDetails?.imagePath;
    let accountImg = undefined;

    if (baseAccountImagePath)
      accountImg = `${resources.BASE_URL}${baseAccountImagePath}`;

    return accountImg;
  }

  return (
    <ImageUpload
      height={256}
      onImagesCrop={onImagesCrop}
      storedImage={getAccountImage()}
      maxSize={3000000}
    />
  );
};

export default PhotoStep;
