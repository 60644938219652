import { Tooltip } from 'antd';
import React from 'react';
import styled from 'styled-components';

const DottedText = styled.span`
  border-bottom: 1px dotted;
  color: inherit !important;
`;

interface DottedTooltipProps {
  tooltip?: string;
  children?: React.ReactNode;
}

// TODO: refactor & merge with ConditionalTooltip
const DottedTooltip = ({ tooltip, children }: DottedTooltipProps) => (
  <Tooltip title={tooltip} placement="bottom">
    <DottedText>{children}</DottedText>
  </Tooltip>
);

export default DottedTooltip;
