import { api } from '../../config';

export interface GetPollingConfigProps {
  itemsCountToFetch: number;
  requestsCount: number;
  isLoading: boolean;
  stopCondition?: boolean;
}

export function getPollingConfig({
  itemsCountToFetch,
  requestsCount,
  isLoading,
  stopCondition,
}: GetPollingConfigProps) {
  const DEFAULT_INTERVAL = 2000;

  const interval = Math.ceil(itemsCountToFetch / api.LIMIT) * DEFAULT_INTERVAL;

  const isTooMuchData = interval > DEFAULT_INTERVAL * 5;

  const isPolling =
    isLoading && !!interval && !isTooMuchData && requestsCount < 10;

  const shouldStop = stopCondition || isTooMuchData;

  const hasFinished = !isLoading && shouldStop;

  return {
    interval,
    isPolling,
    shouldStop,
    hasFinished,
  };
}
