import { shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Modal } from 'antd';

import { Action } from '../../../components/actions-menu';
import useIsSignedIn from '../../../hooks/use-is-signed-in';

import { BrandAction } from '../types';

import { Brand } from '../../../store/brands/brands-model';
import { useAppSelector } from '../../../store/hooks';

export default function useActions(
  handlers: Record<BrandAction, (brand: Brand) => void>,
) {
  const { t } = useTranslation(['common', 'brands']);
  const { isModerator } = useIsSignedIn();
  const { isLive, brandUsers, loading } = useAppSelector(
    state => ({
      isLive: state.live,
      brandUsers: state.brands.brandUsers,
      loading: state.brands.loading,
    }),
    shallowEqual,
  );

  return (brand: Brand, detailActions = false) => {
    const actions: Action[] = [];

    actions.push({
      label: t('common:actions.edit'),
      callback: () => handlers.edit(brand),
    });

    if (brand && detailActions && isLive) {
      actions.push({
        label: t('brands:actions.newAccount'),
        callback: () => handlers.addUser(brand),
        disabled: loading || brandUsers?.length > 0,
      });
    }

    if (!brand?.consent) {
      actions.push({
        label: t('brands:actions.delete'),
        callback: () =>
          Modal.confirm({
            title: t('brands:delete.title'),
            content: t('brands:delete.message'),
            onOk: () => handlers.delete(brand),
            okButtonProps: { danger: true },
            okText: t('common:confirm.delete'),
            maskClosable: true,
            icon: null,
          }),
      });
    }

    actions.push(
      {
        label: t('common:actions.viewRaw'),
        callback: () => handlers.viewRawDetail(brand),
      },
      {
        label: t('common:actions.copyId'),
        callback: () => handlers.copy(brand),
      },
    );

    if (isLive && isModerator) {
      actions.push(
        brand?.consent
          ? {
              label: t('brands:actions.reject'),
              callback: () => handlers.reject(brand),
            }
          : {
              label: t('brands:actions.approve'),
              callback: () => handlers.approve(brand),
            },
      );
    }

    return actions;
  };
}
