import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Input } from 'antd';
import Form from '../../../../../components/form';
import { CreateUpdateOfferFormModel } from '../../../types';
import createUpdateOfferRules from '../../validation';

export interface TermsTextareaProps {
  disabled: boolean;
}

export default function TermsTextarea({ disabled }: TermsTextareaProps) {
  const { t } = useTranslation('offers');

  const {
    control,
    formState: { errors },
  } = useFormContext<CreateUpdateOfferFormModel>();

  return (
    <Form.ItemController
      label={t('fields.terms.label')}
      errors={errors}
      validateCharacters
      controller={{
        name: 'terms',
        render: ({ field }) => (
          <Input.TextArea
            {...field}
            placeholder={t('fields.terms.placeholder')}
            data-testid="offer-terms"
            disabled={disabled}
          />
        ),
        rules: createUpdateOfferRules.terms,
        control,
      }}
    />
  );
}
