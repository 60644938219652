import React from 'react';
import styled from 'styled-components';
import { Select as AntdSelect } from 'antd';

import { Caron } from '../../components/icons';

export interface RefSelectProps {
  focus: () => void;
  blur: () => void;
}

export type AntdSelectType = typeof AntdSelect & RefSelectProps;

export const Select = styled(AntdSelect).attrs(() => ({
  suffixIcon: <Caron style={{ fontSize: '10px' }} />,
}))`
  &.ant-select-open .anticon-down {
    transform: rotateZ(180deg);
  }

  .ant-select-selection-overflow-item-rest {
    .ant-select-selection-item {
      padding-inline-end: 8px;

      .ant-select-selection-item-content {
        margin-right: 0;
      }
    }
  }
`;
