import React from 'react';

import { StyledHeadingTitle } from '../styled';
import ConditionalTooltip from '../../../../components/conditional-tooltip';

interface HeadingProps {
  title: string;
  tooltip?: string;
}

export default function Heading({ title, tooltip }: HeadingProps) {
  const HeadingContainer = ({ display = 'flex' }: { display?: string }) => (
    <span style={{ display }}>
      <StyledHeadingTitle>{title}</StyledHeadingTitle>
    </span>
  );

  return (
    <ConditionalTooltip
      condition={!!tooltip}
      title={tooltip}
      trigger={['hover']}
      placement="bottomRight"
    >
      <HeadingContainer display="inherit" />
    </ConditionalTooltip>
  );
}
