import styled from 'styled-components';
import { colors } from '../../../theme';

export const GoogleRecaptchaDisclaimerWrapper = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${colors.black};
  text-align: left;
`;
