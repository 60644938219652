import styled from 'styled-components';

import { Modal } from 'antd';
import { X } from '../../icons';

import { colors, padding } from '../../../theme';

export const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ModalCustom = styled(Modal)<{
  illustration?: boolean;
  $maxWidth: number;
  $direction: 'horizontal' | 'vertical';
}>`
  width: 100% !important;
  max-width: ${({ illustration, $maxWidth }) =>
    illustration ? 900 : $maxWidth}px;

  .ant-modal-body {
    padding: 0;
    border-radius: 5px;
  }

  .ant-modal-content {
    overflow: hidden;
    width: 100%;
  }

  ${({ $direction, $maxWidth }) =>
    $direction === 'vertical' &&
    `
    max-width: ${$maxWidth}px;
    ${ModalPanelWrapper} {
      flex-direction: column-reverse;
    }

    ${ModalLeftPanel} {
      width: 100%;
    }

    ${ModalRightPanel} {
      width: 100%;
      padding-top: 0;
    }
  `}
`;

export const ModalPanelWrapper = styled.div`
  display: flex;
`;

export const ModalLeftPanel = styled.div<{ illustration?: boolean }>`
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: ${padding.xl}px;
  width: ${({ illustration }) => (illustration ? '40%' : '100%')};
  color: ${colors.grey5};
`;

export const ModalPanelImage = styled.div`
  text-align: center;
`;

export const ModalPanelTitle = styled.div<{ $centered?: boolean }>`
  text-align: ${p => (p.$centered ? 'center' : 'left')};
  font-size: 24px;
  color: ${colors.black};
  margin-bottom: 12px;
`;

export const ModalPanelDescription = styled.div`
  font-size: 18px;
`;

export const ModalPanelTip = styled.div`
  color: ${colors.grey4};
  font-size: 12px;
`;

export const ModalPanelActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 34px;
`;

export const ModalPanelButtonsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
`;

export const ModalRightPanel = styled.div`
  background: linear-gradient(
    to bottom right,
    #ffffff 0%,
    #cff3f1 14%,
    #a7e8e5 28%,
    #8be1dc 41%,
    #79ddd7 52%,
    #73dbd5 60%,
    #6097f7 100%
  );
  display: flex;
  justify-content: center;
  padding: 0;
  padding-top: 50px;
  position: relative;
  width: 60%;
`;

export const ConfettiWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 1010;
`;

export const CloseButton = styled(X)`
  position: absolute;
  top: 12px;
  right: 20px;
  font-size: 28px;
  svg path {
    stroke-width: 1.5;
  }
`;
