import { createSelector } from '@reduxjs/toolkit';
import { State } from '..';

export const selectHideLastNumbers = createSelector(
  (state: State) => state.account,
  account =>
    (account.details?.config?.security?.removeProperties || []).includes(
      'lastNumbers',
    ) || false,
);

export const selectPaymentFailedInvoices = createSelector(
  (state: State) => state.account.invoices.entities,
  invoices => invoices?.filter(invoice => invoice.paymentFailed),
);
