import { useEffect, useState, useRef } from 'react';
import { getPollingConfig } from './config';

export interface UseShortPollingProps {
  shouldStart: boolean;
  itemsCountToFetch: number;
  actionCallback: () => void;
  stopCondition?: boolean;
}

export default function useShortPolling({
  shouldStart,
  itemsCountToFetch,
  actionCallback,
  stopCondition,
}: UseShortPollingProps) {
  const requestsCount = useRef(0);

  const [isLoading, setIsLoading] = useState(false);

  const { interval, isPolling, shouldStop, hasFinished } = getPollingConfig({
    itemsCountToFetch,
    requestsCount: requestsCount.current,
    isLoading,
    stopCondition,
  });

  useEffect(() => {
    if (shouldStart) {
      setIsLoading(true);
    }
  }, [shouldStart]);

  useEffect(() => {
    const actionCallbackInterval = setInterval(() => {
      if (isPolling) {
        actionCallback();
        requestsCount.current++;
      }
    }, interval);

    return () => {
      clearInterval(actionCallbackInterval);
    };
  }, [isPolling, actionCallback, interval]);

  useEffect(() => {
    if (shouldStop && isLoading) {
      setIsLoading(false);
    }
  }, [shouldStop, isLoading, setIsLoading]);

  return {
    isLoading,
    hasFinished,
  };
}
