import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

import { Program } from '../../../store/programs/programs-reducer';
import Ellipsis from '../../../components/ellipsis';
import Avatar from '../../../components/avatar';
import useRecentPrograms from '../../../hooks/use-recent-programs';
import {
  Info,
  RecentProgram,
  RecentProgramsContainer,
} from '../styled/recent-programs';
import ProgramOptionInfo from '../../../components/program-option-info';
import { useAppSelector } from '../../../store/hooks';

const RecentPrograms = ({
  programs,
  onClick,
}: {
  programs: Program[];
  onClick: (program: Program) => void;
}) => {
  const { t } = useTranslation('programs');
  const recentPrograms = useRecentPrograms();
  const loading = useAppSelector(state => state.programs.loading);

  const [first, second, third] = recentPrograms || [];

  return (
    <>
      {first && !loading && (
        <>
          <Typography.Paragraph type="secondary">
            {t('recents')}
          </Typography.Paragraph>
          <RecentProgramsContainer>
            {[
              ...programs.filter(({ id }) => id === first),
              ...programs.filter(({ id }) => id === second),
              ...programs.filter(({ id }) => id === third),
            ].map((program, idx) => {
              const { id, name, icon, iconBackground, type } = program;
              return (
                <RecentProgram
                  key={id}
                  align="middle"
                  position={idx}
                  data-testid="recent-program"
                  onClick={() => onClick(program)}
                >
                  <Avatar
                    size={72}
                    color={{
                      background: iconBackground,
                      border: 'lightgrey',
                    }}
                    emoji={icon}
                  >
                    {name}
                  </Avatar>
                  <Info>
                    <Ellipsis gutter={0}>{name}</Ellipsis>
                    <Typography.Paragraph type="secondary">
                      <ProgramOptionInfo id={id} type={type} />
                    </Typography.Paragraph>
                  </Info>
                </RecentProgram>
              );
            })}
          </RecentProgramsContainer>
        </>
      )}
    </>
  );
};

export default RecentPrograms;
