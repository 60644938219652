import React, { useContext, useEffect } from 'react';
import OnboardingCursor, { startCenter } from '../components/onboarding-cursor';
import { OnboardingTourContext } from '../onboarding-provider';
import { OnboardingStepTypes } from '../onboarding-config';
import { useOnboardingTarget } from '../hooks/use-onboarding-target';

export const CardsClickStep1 = () => {
  const { setStep, setLastCursorPosition } = useContext(OnboardingTourContext);
  const targetElement = useOnboardingTarget('menu-go-back');

  useEffect(() => {
    const targetElemRect = targetElement?.getBoundingClientRect();
    setLastCursorPosition({
      x: (targetElemRect?.x || 0) + (targetElemRect?.width || 0) - 45,
      y: (targetElemRect?.y || 0) + (targetElemRect?.height || 0) / 2 - 15,
    });
  }, [setLastCursorPosition, targetElement]);

  return (
    <OnboardingCursor
      target="menu-go-back"
      action={() => {
        targetElement.click();
        setTimeout(() => {
          setStep(OnboardingStepTypes.CARDS_CLICK_2);
        }, 200);
      }}
      {...startCenter()}
    />
  );
};
