import React, { ReactElement, useEffect, useLayoutEffect } from 'react';
import { shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getApiKeys } from '../../../store/account/account-actions';
import useProgramLocationsStatuses from '../../../hooks/use-program-locations-statuses';
import ConditionalTooltip from '../../../components/conditional-tooltip';
import buildSDKScript from '../../../utils/sdk';
import useTransactionStream from '../../../hooks/use-transaction-stream';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

interface AddCardProps {
  loadCards: (opts?: { clearFirst: boolean }) => void;
  button: ReactElement;
  programId: string;
  programType: string;
}

const AddCard = ({
  loadCards,
  button,
  programId,
  programType,
}: AddCardProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { apiKeys, isLive } = useAppSelector(
    state => ({ apiKeys: state.account.apiKeys, isLive: state.live }),
    shallowEqual,
  );
  const { hasSyncedLocations } = useProgramLocationsStatuses(programId);
  const { hasProgramTransactionStream } = useTransactionStream();

  useLayoutEffect(() => {
    const script = buildSDKScript();

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  });

  useEffect(() => {
    if (!apiKeys) dispatch(getApiKeys());
  }, [dispatch, apiKeys]);

  function openSdk() {
    const fidelKey =
      apiKeys && (isLive ? apiKeys.public.live : apiKeys.public.test);

    return (window as any).Fidel?.openForm({
      companyName: 'Fidel API',
      sdkKey: fidelKey,
      programType,
      programId,
      callback() {
        loadCards();
      },
    });
  }

  const disableAddNewCard = !hasProgramTransactionStream
    ? !hasSyncedLocations && isLive
    : false;

  return (
    <ConditionalTooltip
      condition={disableAddNewCard}
      trigger="click"
      placement="left"
      title={t('cards.addNewCardTooltip')}
    >
      {React.cloneElement(button, {
        disabled: disableAddNewCard,
        onClick: openSdk,
      })}
    </ConditionalTooltip>
  );
};

export default AddCard;
