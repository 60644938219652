import styled from 'styled-components';
import { Radio as AntdRadio } from 'antd';

import Form from '../../../components/form';
import { colors } from '../../../theme';

export const Container = styled(Form.ItemController)`
  &.ant-form-item-has-error {
    margin-bottom: 16px;
  }
`;

export const RadioGroup = styled(AntdRadio.Group)`
  &.ant-radio-group {
    width: 100%;
  }

  .ant-radio-button-wrapper {
    display: block;
    border-radius: 5px;
    border-left-width: 2px;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    padding: 0 8px;
    :hover {
      border-color: ${colors.fidelBlue600};
    }
    :before {
      display: none;
    }
  }
`;

export const Flag = styled.img`
  position: relative;
  top: -2px;
  margin-right: 14px;
`;
