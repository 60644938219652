import { Button } from 'antd';
import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import Spin from '../../../../components/spin';
import { useSetStatus } from '../../../../hooks/use-status';
import { useConfirmPayment } from '../../../../hooks/use-stripe-hooks';
import { retryPayment } from '../../../../store/account/account-actions';
import { Invoice } from '../../../../store/account/account-model';
import { Subsection, SubsectionSubtitle, SubsectionTitle } from '../../styled';
import { getAmount } from './index';
import { PayNowAmount } from './styled';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

const PayNow = ({ invoice }: { invoice: Invoice }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { setSuccessMessage } = useSetStatus();
  const { submit: confirmPayment, loading: confirming } =
    useConfirmPayment(invoice);
  const { accountDetails, paymentMethods, retryingPayment, retrySuccess } =
    useAppSelector(
      ({ account }) => ({
        accountDetails: account.details,
        paymentMethods: account.paymentMethods.entities,
        retryingPayment: account.paymentMethods.retrying,
        retrySuccess: account.paymentMethods.retrySuccess,
      }),
      shallowEqual,
    );

  useEffect(() => {
    if (isRetry && retrySuccess) {
      setSuccessMessage(t('account.duePayment.paidInvoiceSuccess'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retrySuccess]);

  const { currency, stripePaymentError } = invoice;
  const defaultPaymentMethod = paymentMethods?.find(
    ({ isDefault }) => isDefault === true,
  );
  const retrying = retryingPayment.some(invoiceId => invoiceId === invoice.id);
  const isRetry =
    stripePaymentError?.paymentMethodId !== defaultPaymentMethod?.cardId;

  const retryPaymentHandler = () => {
    dispatch(retryPayment(accountDetails?.id, invoice.id));
  };

  const payNowHandler = () =>
    isRetry ? retryPaymentHandler() : confirmPayment();

  return (
    <Subsection key={invoice.id}>
      <SubsectionTitle>
        <div>
          <h2>{t('account.duePayment.title')}</h2>
          <SubsectionSubtitle>
            <PayNowAmount>
              <span>{currency.toUpperCase()}</span>{' '}
              <span>{getAmount(accountDetails, invoice)}</span>
            </PayNowAmount>
            {t('account.duePayment.description')}
          </SubsectionSubtitle>
        </div>
        <Button
          data-testid="pay-now-button"
          type="primary"
          size="small"
          onClick={payNowHandler}
        >
          {confirming || retrying ? (
            <Spin type="button" />
          ) : (
            t('account.duePayment.cta')
          )}
        </Button>
      </SubsectionTitle>
    </Subsection>
  );
};

export default PayNow;
