import React from 'react';

import ChangePassword from './ChangePassword';
import ResetPassword from './ResetPassword';
import useSignUpParameters from './use-sign-up-parameters';

const ResetPasswordRouter = () => {
  const { code, email } = useSignUpParameters();

  return code && email ? (
    <ChangePassword code={code} email={email} />
  ) : (
    <ResetPassword />
  );
};

export default ResetPasswordRouter;
