import { ReactElement } from 'react';
import { isArray } from 'lodash';
import { FieldError, RegisterOptions } from 'react-hook-form';

export type ErrorMessage = {
  type?: string | string[];
  message?: string | ReactElement;
};

const showConditionalError = (
  error: FieldError | undefined,
  errorMessages: ErrorMessage[],
  rules: RegisterOptions | undefined = undefined,
): string | ReactElement | undefined => {
  const arrayRules = Object.keys({ ...rules, ...rules?.validate });

  return errorMessages.find(({ type }) => {
    const arrayType = isArray(type) ? type : [type];
    return arrayType.some(
      key =>
        key === error?.type &&
        arrayType.every(t => arrayRules.some(r => r === t)),
    );
  })?.message;
};

export default showConditionalError;
