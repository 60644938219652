import styled from 'styled-components';
import { Input } from 'antd';

import { colors } from '../../../theme';
import { CloseCircle } from '../../icons';
import Form from '..';

export const KeyValuePairsWrapper = styled.div<{
  hasKeyValueLabel: boolean | undefined;
}>`
  display: flex;

  .ant-form-item {
    max-width: calc(50% - 25px);
  }

  .ant-form-item-no-colon {
    color: ${props => (props.hasKeyValueLabel ? 'inherit' : 'transparent')};
  }
`;

export const Equals = styled.span.attrs(() => ({ children: '=' }))`
  color: ${colors.grey4};
  font-weight: bold;
  margin: 0 5px;
  position: relative;
  top: 32px;
`;

export const RemoveKeyValuePair = styled(CloseCircle)`
  margin: 0 0 0 14px;
  position: relative;
  top: 34px;
  color: transparent;
  font-size: 24px;
  :hover {
    color: ${colors.lightgrey3};
  }
  transition: color 0.2s ease-in;
`;

const requiredItemStyle = `
  .ant-form-item-label label {
    :before {
      display: none !important;
    }
    :after {
      display: inline-block;
      margin-right: 4px;
      color: ${colors.red};
      font-size: 16px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
  }
`;
export const RequiredFormItem = styled(Form.Item)`
  ${requiredItemStyle};
`;

export const RequiredFormItemController = styled(Form.ItemController)`
  ${requiredItemStyle};
`;

export const FieldWithCurrencyWrapper = styled.div`
  width: 100%;
  user-select: none;

  .ant-input-affix-wrapper {
    padding: 0;
    height: 40px;

    .ant-input-prefix {
      background-color: ${colors.lightgrey3};
      border-right: 2px solid ${colors.grey2};
      color: ${colors.grey4};
      justify-content: center;
      width: 52px;
      margin: 0;
      line-height: 1;
    }

    input {
      height: 36px;
      padding: 0 11px;
    }
  }

  > .ant-form-item {
    margin-bottom: 0;
  }
`;

export const HiddenInput = styled(Input).attrs(() => ({ readOnly: true }))`
  position: absolute;
  left: -100000px;
`;
