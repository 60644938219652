import { emojiIndex } from 'emoji-mart';

import { colors } from '../../theme';
import regex from '../../utils/regex';

import {
  ThemeColor,
  ColorProps,
  Color,
  Sizes,
  FontSizes,
  Emoji,
  AvatarProps,
  AvatarDefaults,
} from './types';

export const avatarDefaults: AvatarDefaults = {
  shape: 'square',
  size: 36,
  color: {
    color: 'white',
    background: 'black',
    border: 'black',
  },
  emoji: '',
  textFormat: 'first',
};

const darkColorSchema = ['white', 'light', 'yellow', 'transparent'];

export function getColor(key: ColorProps, color?: Color) {
  const value = color?.[key] || avatarDefaults.color[key];
  if (regex.hex.test(value)) return value;
  return colors[(value as ThemeColor) || avatarDefaults.color[key]];
}

export function textColor({ color }: AvatarProps) {
  return darkColorSchema.includes(color?.background as string)
    ? 'black'
    : getColor('color', color);
}

export function bgColor({ src, imageSrc, color }: AvatarProps) {
  return src || imageSrc ? 'transparent' : getColor('background', color);
}

export function border({ color }: AvatarProps) {
  return color?.border ? `1px solid ${getColor('border', color)}` : 'none';
}

export function hoverCursor({ onClick }: AvatarProps) {
  return onClick ? 'pointer' : 'none';
}

const fontBySize: Record<Sizes, FontSizes> = {
  24: 12,
  36: 14,
  48: 16,
  64: 18,
  72: 24,
  88: 36,
};

export function fontSize({ size }: AvatarProps) {
  return fontBySize[(size as Sizes) || 36];
}

export function isEmoji(emoji: Emoji) {
  return emojiIndex
    .search(
      regex.emoji.exec(typeof emoji === 'string' ? emoji : '')?.groups?.emoji ||
        '',
    )
    ?.find(({ colons }) => colons === emoji);
}
