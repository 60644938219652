import * as OfferActions from './offers';
import * as TransactionActions from './transactions/transactions-actions';
import * as UserActions from './user/user-actions';
import * as UsersActions from './users/users-actions';
import * as ProgramActions from './programs/programs-actions';
import * as AccountActions from './account/account-actions';
import * as BrandUserActions from './brand-user/brand-user-actions';
import * as LocationActions from './locations/locations-actions';
import * as FiltersActions from './filters/filters-actions';
import { Thunk } from './types';
import { queryClient } from '../react-query';

const clearAll = (): Thunk => dispatch => {
  dispatch(OfferActions.clear());
  dispatch(OfferActions.clearOffer());
  dispatch(TransactionActions.clear());
  dispatch(UserActions.clear());
  dispatch(UsersActions.clear());
  dispatch(ProgramActions.clear());
  dispatch(AccountActions.clear());
  dispatch(BrandUserActions.clear());
  dispatch(LocationActions.clear());
  dispatch(FiltersActions.clear());
  queryClient.removeQueries();
};

export default clearAll;
