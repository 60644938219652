import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Menu } from 'antd';
import { GreyDropdownButton } from '../buttons';
import { Caron } from '../../components/icons';

interface ExportDropdownProps {
  label: string;
  disabled: boolean;
  handleClickExport: (format: 'pdf' | 'csv') => any;
  showSelectFormat: (shouldShow: boolean) => any;
  selectFileFormatOpen: boolean;
  loading?: boolean;
}

const ExportDropdown = ({
  label,
  disabled,
  handleClickExport,
  showSelectFormat,
  selectFileFormatOpen,
  loading,
}: ExportDropdownProps) => {
  const { t } = useTranslation();

  return (
    <Dropdown
      placement="bottomLeft"
      trigger={['click']}
      overlay={
        <Menu>
          <Menu.Item key="pdf" onClick={() => handleClickExport('pdf')}>
            {t('export.pdf')}
          </Menu.Item>
          <Menu.Item key="csv" onClick={() => handleClickExport('csv')}>
            {t('export.csv')}
          </Menu.Item>
        </Menu>
      }
    >
      <GreyDropdownButton
        $disableAnimation
        disabled={disabled || loading}
        loading={loading}
        onClick={() => showSelectFormat(!selectFileFormatOpen)}
      >
        {label}
        <Caron />
      </GreyDropdownButton>
    </Dropdown>
  );
};

export default ExportDropdown;
