import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Descriptions, Popover, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Spin from '../../../components/spin';
import { Transaction } from '../../../store';
import { Offer, OfferOptions } from '../../../store/offers/offers-model';
import { Caron } from '../../../components/icons';
import { colors } from '../../../theme';
import useTransactionOffer from '../hooks/use-transaction-offer';
import OfferCashback from './OfferCashback';

interface OfferPopoverProps {
  children: ReactElement;
  transaction: Transaction;
  loading: boolean;
}

const CashbackContainer = styled.div`
  max-width: 180px;
  padding: 8px 14px;

  .ant-descriptions-item {
    &:last-of-type {
      padding-bottom: 0;
    }

    &-label {
      color: ${colors.grey4};
      display: block;
      font-size: 12px;
    }

    &-content {
      font-size: 14px;
    }
  }
`;

const Dotted = styled(Space).attrs(() => ({
  size: 6.5,
}))<{ $visible: boolean }>`
  border-bottom: 1px dotted;
  display: inline-flex;
  border-bottom-color: ${({ $visible }) =>
    $visible ? 'transparent' : colors.grey4};

  .anticon {
    color: ${colors.grey4};
    font-size: 8px;
  }
  margin-right: -14px;
`;

function getOfferStatus(offer: Offer): OfferOptions['status'] {
  const now = moment();

  if (
    offer?.locationsTotal &&
    offer?.locationsTotal > 0 &&
    moment(offer?.startDate).isAfter(now)
  ) {
    return 'upcoming';
  }
  if (
    (moment(offer?.startDate).isBefore(now) && !offer.endDate) ||
    (moment(offer?.startDate).isBefore(now) &&
      moment(offer?.endDate).isAfter(now))
  ) {
    return 'live';
  }
  if (moment(offer?.endDate).isBefore(now)) {
    return 'expired';
  }
  return 'requests';
}

const TransactionOfferDetail = ({
  loading,
  offer,
  transaction,
}: {
  loading: boolean;
  offer?: Offer;
  transaction: Transaction;
}) => {
  const { t } = useTranslation(['common', 'offers']);

  let items = [
    {
      label: t('offers:linkedOffer'),
      content: (
        <span>{t('common:transactions.qualifiedWithDeletedOffer')}</span>
      ),
    },
  ];
  if (offer) {
    items = [
      {
        label: t('offers:linkedOffer'),
        content: (
          <NavLink
            to={{
              pathname: `/offers/${getOfferStatus(offer)}`,
              state: { offer },
            }}
          >
            {offer.name}
          </NavLink>
        ),
      },
      {
        label: t('offers:cashback'),
        content: <OfferCashback offer={offer} transaction={transaction} />,
      },
    ];
  }

  return (
    <CashbackContainer data-testid="transaction-offer-popover-content">
      {loading ? (
        <Spin style={{ padding: '10px 20px' }} />
      ) : (
        <Descriptions column={1}>
          {items.map(({ label, content }) => (
            <Descriptions.Item label={label} key={label}>
              {content}
            </Descriptions.Item>
          ))}
        </Descriptions>
      )}
    </CashbackContainer>
  );
};

const OfferPopover = ({
  children,
  transaction,
  loading,
}: OfferPopoverProps) => {
  const [visible, setVisible] = useState(false);
  const { id: offerId } = (transaction.offer as { id: string }) ?? {};
  const offer = useTransactionOffer(offerId);

  if ((transaction.offer as { qualified: boolean })?.qualified)
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
      <div
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Popover
          placement="bottomRight"
          onVisibleChange={isVisible => setVisible(isVisible)}
          content={
            <TransactionOfferDetail
              transaction={transaction}
              offer={offer}
              loading={loading}
            />
          }
        >
          <Dotted $visible={visible}>
            {children}
            <Caron />
          </Dotted>
        </Popover>
      </div>
    );
  return children;
};

export default OfferPopover;
