import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import OnboardingPopover from '../components/onboarding-popover';
import { OnboardingTourContext } from '../onboarding-provider';
import { useOnboardingTarget } from '../hooks/use-onboarding-target';
import { OnboardingStepTypes } from '../onboarding-config';
import {
  OnboardingPopoverCustomButtonWrapper,
  OnboardingPopoverYellowButton,
} from '../components/onboarding-popover/styled';
import { Triangle } from '../../icons';
import { OnboardingOverlay } from '../styled';

export const PlaygroundStep = () => {
  const { t } = useTranslation();
  const [showStep, setShowStep] = useState(true);

  const { completeOnboardingStep, setStep } = useContext(OnboardingTourContext);
  const runButtonTarget = useOnboardingTarget('playground-run-button');
  const notificationTarget = useOnboardingTarget('playground-notification');

  useEffect(() => {
    let timer: number;
    if (notificationTarget) {
      timer = window.setTimeout(
        () => setStep(OnboardingStepTypes.PLAYGROUND_CLICK),
        1000,
      );
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [notificationTarget, setStep]);

  return showStep ? (
    <OnboardingPopover
      placement="bottom"
      target="playground-request-code"
      index={4}
      title={t('onboarding.playground.step1.title')}
      description={
        <Trans
          i18nKey="onboarding.playground.step1.description"
          components={[<strong />]}
        />
      }
      customButton={({ action, buttonText }) => (
        <OnboardingPopoverYellowButton
          onClick={() => action()}
          data-testid="onboarding-popover-custom-button"
        >
          <OnboardingPopoverCustomButtonWrapper>
            {buttonText}
            <Triangle />
          </OnboardingPopoverCustomButtonWrapper>
        </OnboardingPopoverYellowButton>
      )}
      buttonText={t('onboarding.buttons.run')}
      action={() => {
        completeOnboardingStep('dashboardPlayground');
        setShowStep(false);
        runButtonTarget.click();
      }}
      showSkipTour
      wrapperStyle={{ position: 'initial' }}
    />
  ) : (
    <OnboardingOverlay />
  );
};
