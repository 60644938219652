import { currentAuthSession } from '../auth';
import CognitoApiService from '../services/cognito-api-service';

const cognitoApiService = new CognitoApiService();

export default function useRevokeCognitoToken() {
  return async function revokeRefreshToken() {
    const authSession = await currentAuthSession();

    if (!authSession) return Promise.resolve();

    const refreshToken = authSession.getRefreshToken().getToken();

    return cognitoApiService.revokeToken(refreshToken);
  };
}
