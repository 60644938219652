import styled from 'styled-components';
import { colors } from '../../../theme';

export const Role = styled.p`
  font-size: 14px;
  margin-bottom: 0;
`;

export const RoleDescription = styled.p`
  white-space: normal;
  font-size: 12px;
  margin-bottom: 0;
  color: ${colors.grey4};
`;
