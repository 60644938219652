import React, { useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { Auth } from 'aws-amplify';
import { Input } from 'antd';

import { updateApiVersion } from '../../../store/account/account-actions';
import Form from '../../../components/form';
import Link from '../../../components/link';
import { Container, StyledModal } from '../styled/upgrade-api-modal';
import EmailInput from '../../sign/components/EmailInput';
import useHandleCognitoError from '../../../hooks/use-handle-cognito-error';
import { SignInForm } from '../../sign/SignIn';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import useCaptchaFormSubmit from '../../../hooks/use-captcha-form-submit';

interface UpdateApiModalProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  onCancel?: () => void;
}

const UpdateApiModal = ({
  showModal,
  setShowModal,
  onCancel,
}: UpdateApiModalProps) => {
  const { t } = useTranslation();
  const { t: tApiVersionUpgrade } = useTranslation('common', {
    keyPrefix: 'account.apiVersion.upgrade',
  });
  const dispatch = useAppDispatch();
  const handleCognitoError = useHandleCognitoError();
  const [loginStep, setLoginStep] = useState(false);
  const { userEmail, currentApiVersion, lastApiVersion } = useAppSelector(
    state => ({
      userEmail: state.user.details?.email,
      currentApiVersion: state.account.details?.apiVersion,
      lastApiVersion: state.account.version?.id,
    }),
    shallowEqual,
  );
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<SignInForm>({
    mode: 'onBlur',
    defaultValues: {
      email: userEmail,
    },
  });

  const { onSubmit } = useCaptchaFormSubmit(submitSignIn);

  async function submitSignIn({ email, password, token }: SignInForm) {
    try {
      await Auth.signIn(email, password, { captcha: token });
      setShowModal(false);
      dispatch(updateApiVersion(lastApiVersion));
    } catch (error) {
      handleCognitoError(error);
    }
  }

  function onOk() {
    if (loginStep) {
      handleSubmit(onSubmit)();
    } else {
      setLoginStep(true);
    }
  }

  return (
    <>
      <StyledModal
        title={null}
        visible={showModal}
        closable={false}
        okText={tApiVersionUpgrade('done')}
        onOk={onOk}
        okButtonProps={{ size: 'small' }}
        cancelButtonProps={{ size: 'small' }}
        onCancel={() => {
          setShowModal(false);
          if (onCancel) onCancel();
        }}
        afterClose={() => setLoginStep(false)}
        zIndex={1100}
      >
        <Container>
          <div>{tApiVersionUpgrade(!loginStep ? 'info' : 'infoLogin')}</div>
          {!loginStep ? (
            <p>
              <Trans
                i18nKey="account.apiVersion.upgrade.tip"
                values={{ versionDate: currentApiVersion }}
                components={[
                  <Link
                    to={`https://reference.fidel.uk/docs/changelog#${lastApiVersion}`}
                  />,
                ]}
              />
            </p>
          ) : (
            <Form layout="vertical">
              <EmailInput errors={errors} control={control} />
              <Form.ItemController
                errors={errors}
                label={t('signIn.password')}
                controller={{
                  name: 'password',
                  render: ({ field }) => (
                    <Input.Password {...field} style={{ marginBottom: 0 }} />
                  ),
                  rules: { required: true },
                  control,
                }}
              />
            </Form>
          )}
        </Container>
      </StyledModal>
    </>
  );
};

export default UpdateApiModal;
