import { Location } from '../locations/locations-model';
import { Brand, UniqueBrand } from '../brands/brands-model';

function sanitizeAddress(location: Location) {
  const address = location?.address || '';
  const cityIndex = address
    .toLowerCase()
    .indexOf((location?.city || '').toLowerCase());

  return cityIndex > 0
    ? address
        .substring(0, cityIndex)
        .replace(/(,[^,]*)$/, '')
        .trim()
    : address;
}

export function sanitizeLocations(items: Location[]) {
  return (items || []).map(location => ({
    ...location,
    address: sanitizeAddress(location),
  }));
}

export function sanitizeBrand({ name, ...brand }: Brand | UniqueBrand) {
  return { ...brand, ...(name && { name: name.trim() }) };
}
