import {
  Transaction,
  TransactionOptions,
  transformToTransaction,
} from './transactions-model';
import { State } from '../create-store';
import { actions } from './transactions-reducer';
import { Thunk } from '../types';
import TransactionApiService from './transactions-api-service';
import transactionMatchesFilter from '../utils/transaction-matches-filter';

const transactionApiService = new TransactionApiService();

const getFilters = (state: State) => state.filters.transactions;

export const { clear } = actions;

export const clearTransaction =
  (transactionId: string): Thunk =>
  async dispatch => {
    dispatch(actions.clearTransaction());

    try {
      await transactionApiService.clearTransaction(transactionId);
      dispatch(actions.clearTransactionSuccess());
    } catch (error) {
      dispatch(actions.clearTransactionError({ error }));
    }
  };

export const getTransactions =
  (options: TransactionOptions): Thunk =>
  async (dispatch, getState) => {
    if (options.last) dispatch(actions.getMoreTransactions());
    else dispatch(actions.getTransactions());

    try {
      const filters = getFilters(getState());
      const { qualifiedTransactions } = filters;
      const response = await transactionApiService.getTransactions(
        options,
        filters,
      );
      const { items, last } = response.data;
      let transactions = items
        .map(transformToTransaction)
        .filter((transaction: Transaction) =>
          transactionMatchesFilter(transaction, filters),
        );

      if (qualifiedTransactions)
        transactions = transactions.filter(
          (transaction: Transaction) => transaction.offer?.qualified,
        );

      if (options.last)
        dispatch(actions.getMoreTransactionsSuccess({ transactions, last }));
      else dispatch(actions.getTransactionsSuccess({ transactions, last }));
    } catch (error) {
      if (options.last) dispatch(actions.getMoreTransactionsError({ error }));
      else dispatch(actions.getTransactionsError({ error }));
    }
  };

export const getTransactionsCount =
  (options: TransactionOptions): Thunk =>
  async (dispatch, getState) => {
    dispatch(actions.getTransactionsCount());

    try {
      const filters = getFilters(getState());
      const response = await transactionApiService.getTransactionsCount(
        options,
        filters,
      );
      dispatch(actions.getTransactionsCountSuccess(response));
    } catch (error) {
      dispatch(actions.getTransactionsCountError({ error }));
    }
  };

export const getExportableTransactions =
  (options: TransactionOptions): Thunk =>
  async (dispatch, getState) => {
    dispatch(actions.getExportableTransactions());

    try {
      const filters = getFilters(getState());
      const { qualifiedTransactions } = filters;
      const response = await transactionApiService.getAllTransactions(
        options,
        filters,
      );
      let transactions = response
        .map(transformToTransaction)
        .filter((transaction: Transaction) =>
          transactionMatchesFilter(transaction, filters),
        );

      if (qualifiedTransactions)
        transactions = transactions.filter(
          transaction => transaction?.offer?.qualified,
        );

      dispatch(actions.getExportableTransactionsSuccess(transactions));
    } catch (error) {
      dispatch(actions.getExportableTransactionsError({ error }));
    }
  };
