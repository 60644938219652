import React from 'react';

import styled from 'styled-components';
import { Button, Popover } from 'antd';
import { colors } from '../../../../../theme';

const WrappedPopover = ({ className, ...props }: any) => (
  <Popover {...props} overlayClassName={className} />
);
export const OnboardingStyledPopover = styled(WrappedPopover)`
  .ant-popover-arrow {
    display: initial;
  }
  .ant-popover-inner {
    border: none;
  }
`;

export const OnboardingPopoverContent = styled.div`
  display: flex;
  flex-flow: column;
  padding: 25px 30px;
`;

export const OnboardingPopoverTitle = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 14px;
  justify-content: space-between;
  align-items: center;
  line-height: 22px;
  margin-bottom: 10px;
`;

export const OnboardingPopoverTitleLink = styled.a`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: ${colors.fidelBlue600};
`;

export const OnboardingPopoverDescription = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 22px;
  color: ${colors.grey4};
`;

export const OnboardingPopoverFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const OnboardingPopoverCustomButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const OnboardingPopoverYellowButton = styled(Button).attrs(() => ({
  size: 'small',
}))`
  background: ${colors.yellow};
  border-color: ${colors.yellow};

  &:hover,
  &:focus {
    background: ${colors.yellow};
    opacity: 0.9;
    border-color: ${colors.yellow};
    color: inherit;
  }
`;
