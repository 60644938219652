import styled from 'styled-components';

import { colors } from '../../../theme';

export const ActionsBlock = styled.div`
  flex: 0;
  position: relative;
  margin: 0 0 0 auto;
`;

export const ActionsMenuButton = styled.button<{ $open: boolean }>`
  width: 100%;
  height: 32px;
  border: none;
  border-radius: 5px;
  background: ${({ $open }) => ($open ? colors.grey2 : 'none')};
  cursor: pointer;
  opacity: ${({ $open }) => ($open ? 1 : 0.75)};
  transition: opacity 0.3s ease;
  padding: 0 7px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:active,
  &:focus,
  &:hover {
    opacity: 1;
    outline: none;
    background: ${colors.grey2};
  }
`;

export const ActionMenuInView = styled.div`
  position: absolute;
  height: 1px;
`;

const dotSize = 3;

const dotsCommonStyle = `
  width: ${dotSize}px;
  height: ${dotSize}px;
  left: -${dotSize * 2 - 1}px;
  border-radius: 50%;
  background: ${colors.grey5};
`;

export const Dots = styled.div`
  ${dotsCommonStyle}
  position: relative;

  &::before,
  &::after {
    ${dotsCommonStyle}
    content: '';
    display: block;
    position: absolute;
  }

  &::before {
    left: ${dotSize + 2}px;
  }

  &::after {
    left: ${(dotSize + 2) * 2}px;
  }
`;

export const New = styled.span`
  display: inline-block;
  background: ${colors.yellow2};
  font-size: 10px;
  color: ${colors.darkYellow};
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 2px;
  line-height: 1.2;
  padding: 2px;
  margin-left: 6px;
`;
