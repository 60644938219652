import styled from 'styled-components';
import { Caron } from '../../../../../components/icons';

export const TitleWrapper = styled.span`
  display: flex;
  align-items: center;
`;

export const BackCaron = styled(Caron)`
  font-size: 14px;
  transform: rotate(90deg) translateY(4px);
`;
