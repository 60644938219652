import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormStep, StepField } from '../types';
import { tBase, recommendationsListStyle as listStyle } from '../config';
import {
  StyledText,
  StyledRecommendationsWrapper,
  StyledLampIcon,
  StyledList,
} from '../styled';

interface FieldRecommendationsProps {
  formStep: FormStep;
  stepField: StepField;
  isMdBreakpoint: boolean;
}

const FieldRecommendations = ({
  formStep,
  stepField,
  isMdBreakpoint,
}: FieldRecommendationsProps) => {
  const { t, i18n } = useTranslation('offers');

  const tRecommendationsBase = `${tBase}.modal.${formStep}.fields.${stepField}.recommendations`;

  if (!i18n.exists(`offers:${tRecommendationsBase}`)) return null;

  // TODO - Fix 'any' belows by enhancing tRecommendationsBase type with possible values
  const getRecommendationRules = Array.from(
    t(`${tRecommendationsBase}.rules` as any, {
      returnObjects: true,
    }),
  ).map((recommendation: any, idx) => (
    <li key={`${stepField}-rule-${idx}`}>
      <StyledText $fontSize={14}>{recommendation}</StyledText>
    </li>
  ));

  return (
    <StyledRecommendationsWrapper $isMdBreakpoint={isMdBreakpoint}>
      <StyledLampIcon />

      {i18n.exists(`offers:${tRecommendationsBase}.title`) && (
        <StyledText $fontSize={14} $fontWeight={500}>
          {t(`${tRecommendationsBase}.title` as any)}
        </StyledText>
      )}

      <StyledList $styleType={listStyle[stepField]}>
        {getRecommendationRules}
      </StyledList>
    </StyledRecommendationsWrapper>
  );
};

export default FieldRecommendations;
