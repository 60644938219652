import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { useForm } from 'react-hook-form';

import { updateBillingConfigurations } from '../../../../store/account/account-actions';
import { BillingConfigurations } from '../../../../store/account/account-model';
import { Select } from '../../../../components/select';
import ContractForm from './ContractForm';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

const paymentMethods = ['stripe', 'bank-transfer'] as const;

type PaymentMethodForm = Required<
  Pick<BillingConfigurations, 'paymentMethodType'>
>;

const PaymentMethod = () => {
  const { t } = useTranslation('common', {
    keyPrefix: 'account.contract.paymentMethod',
  });
  const dispatch = useAppDispatch();
  const { billingConfigurations } = useAppSelector(
    state => ({
      billingConfigurations: state.account.billingConfigurations.entity,
      accountDetails: state.account.details,
    }),
    shallowEqual,
  );
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<PaymentMethodForm>({
    mode: 'onBlur',
    defaultValues: {
      paymentMethodType: billingConfigurations?.paymentMethodType,
    },
  });

  function onSubmit({ paymentMethodType }: PaymentMethodForm) {
    dispatch(updateBillingConfigurations({ paymentMethodType }));
  }

  return (
    <ContractForm onSubmit={handleSubmit(onSubmit)}>
      <ContractForm.ItemController
        label={t('title')}
        errors={errors}
        controller={{
          name: 'paymentMethodType',
          render: ({ field }) => (
            <Select
              {...field}
              defaultValue={billingConfigurations?.paymentMethodType}
            >
              {paymentMethods.map(paymentMethod => (
                <Select.Option key={paymentMethod} value={paymentMethod}>
                  {t(
                    `form.paymentMethods.${
                      paymentMethod === 'bank-transfer'
                        ? 'bankTransfer'
                        : paymentMethod
                    }`,
                  )}
                </Select.Option>
              ))}
            </Select>
          ),
          rules: { required: true },
          control,
        }}
      />
    </ContractForm>
  );
};

export default PaymentMethod;
