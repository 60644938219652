import styled from 'styled-components';
import { Button, Col, Layout, Tag, Typography } from 'antd';

import { colors, breakpoints, padding, fonts } from '../../../theme';
import { Method as MethodType } from '../types';

const { Text } = Typography;

export const FullHeightLayout = styled(Layout)`
  min-height: calc(100% - 68px);
`;

export const EndpointTitle = styled.h2`
  margin: 20px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ReferenceButton = styled(Button).attrs(() => ({
  size: 'small',
}))`
  svg {
    color: ${colors.grey2};
    position: relative;
    margin-left: 8px;
    top: 2px;
  }
`;

export const EndpointDescription = styled.div`
  font-size: 14px;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid ${colors.lightgrey2};

  p:last-of-type {
    margin-bottom: 0;
  }

  code {
    background: ${colors.lightgrey2};
    color: ${colors.black};
    font-family: ${fonts.monospace};
    padding: 4px;
    border-radius: 4px;
    line-height: 1;
  }
`;

export const CardNumber = styled.p`
  :first-of-type {
    margin-top: 8px;
  }

  margin-bottom: 4px;
`;

export const EndpointWrapper = styled.div`
  margin: 12px 0 24px;
  display: flex;
  align-items: top;
`;

export const EndpointText = styled(Text).attrs(() => ({ type: 'secondary' }))`
  left: 0 !important;
  overflow-wrap: anywhere;
  flex-grow: 1;
  line-height: 1.7;
`;

export const HeadingWithBorder = styled.h2`
  border-bottom: 1px solid ${colors.lightgrey2};
  margin: -30px -20px 0;
  padding: 30px 20px;

  @media (min-width: ${breakpoints.tabletLandscape}px) {
    margin: -30px -40px 0;
    padding: 30px 40px;
  }
`;

const methodBackground: Record<MethodType, string> = {
  post: colors.fidelBlue600,
  delete: colors.red,
};

export const Method = styled(Tag)<{ method: MethodType }>`
  background: ${({ method }) => methodBackground[method]};
  border-radius: 4px;
  height: 22px;
  padding: 2px 4px;
  color: white;
  font-size: 14px;
  transition: none;
`;

export const PlaygroundCol = styled(Col)`
  max-height: calc(100vh - 98px);
`;

export const PlaygroundContent = styled(Layout.Content)`
  position: relative;
  margin-right: -${padding.l}px;

  @media (min-width: ${breakpoints.tabletLandscape}px) {
    margin-right: -${padding.xl}px;
  }
`;

export const SendButton = styled(Button).attrs(() => ({
  size: 'small',
}))`
  position: absolute;
  top: 20px;
  right: 40px;
  background: ${colors.yellow};
  border-color: ${colors.yellow};
  z-index: 3;

  &:hover,
  &:focus {
    background: ${colors.yellow};
    opacity: 0.9;
    border-color: ${colors.yellow};
    color: inherit;
  }
`;
