import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import { Button, Input } from 'antd';
import { Auth } from 'aws-amplify';

import Logo from './components/Logo';
import { CenteredContainer, TopBar, Title, Subtitle } from './styled/sign-in';
import Form from '../../components/form';
import useRedirect from '../../hooks/use-redirect';
import useHandleCognitoError from '../../hooks/use-handle-cognito-error';
import { useSetStatus } from '../../hooks/use-status';
import regex from '../../utils/regex';

enum FormState {
  Start,
  Submitted,
  PasswordChanged,
}

interface ChangePasswordProps {
  code: string;
  email: string;
}

const ChangePassword = ({ email, code }: ChangePasswordProps) => {
  const handleCognitoError = useHandleCognitoError();
  const { setSuccessMessage } = useSetStatus();
  const redirect = useRedirect();
  const { t } = useTranslation();
  const [formState, setFormState] = useState<FormState>(FormState.Start);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
  });

  async function onSubmit({ password }: any) {
    try {
      setFormState(FormState.Submitted);
      await Auth.forgotPasswordSubmit(email, code, password);
      setFormState(FormState.PasswordChanged);
      setSuccessMessage(t('resetPassword.passwordChangedNotification'));
    } catch (error) {
      setFormState(FormState.Start);
      handleCognitoError(error);
    }
  }

  if (formState === FormState.PasswordChanged) {
    return <Redirect to="/sign-in" push />;
  }

  return (
    <>
      <TopBar />
      <CenteredContainer>
        <Logo centered />
        <Title>{t('resetPassword.title')}</Title>
        <Subtitle>{t('resetPassword.changePasswordSubtitle')}</Subtitle>
        <Form layout="vertical" onSubmit={handleSubmit(onSubmit)}>
          <Form.ItemController
            key="password"
            errors={errors}
            label={t('resetPassword.password')}
            controller={{
              name: 'password',
              render: ({ field }) => (
                <Input.Password data-testid="reset-pw-password" {...field} />
              ),
              rules: {
                required: true,
                minLength: 8,
                maxLength: 30,
                validate: {
                  password: (value: string) => regex.password.test(value),
                },
              },
              control,
            }}
          />
          <Form.Item>
            <Button
              block
              type="primary"
              htmlType="submit"
              data-testid="submit-change-pw"
            >
              {t('resetPassword.submitChangePassword')}
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="link"
              block
              onClick={() => redirect('/reset-password')}
            >
              {t('resetPassword.goBack')}
            </Button>
          </Form.Item>
        </Form>
      </CenteredContainer>
    </>
  );
};

export default ChangePassword;
