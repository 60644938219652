import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'antd';

import Spin from '../../components/spin';

const SubForm = ({
  lastStep,
  onClick,
  submitting,
  children,
}: {
  lastStep?: boolean;
  onClick: () => void;
  submitting?: boolean;
  children?: ReactNode;
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Form layout="vertical">{children}</Form>
      <Button
        type="primary"
        block
        onClick={onClick}
        style={{ marginTop: '12px' }}
        disabled={submitting}
      >
        {submitting ? (
          <Spin type="button" />
        ) : (
          t(`account.goLive.${lastStep ? 'submit' : 'next'}`)
        )}
      </Button>
    </div>
  );
};

export default SubForm;
