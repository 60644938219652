import { useLocation } from 'react-router-dom';

function fixEmailWithPlus(email: string | null) {
  return email && email.replace(/\s/g, '+');
}

function useSignUpParameters(): {
  code?: string;
  invite?: string;
  email?: string;
} {
  const queryParams = new URLSearchParams(useLocation().search);

  const code = queryParams.get('code') || undefined;
  const invite = queryParams.get('invite') || undefined;
  const email = fixEmailWithPlus(queryParams.get('email')) || undefined;

  return {
    email,
    code,
    invite,
  };
}

export default useSignUpParameters;
