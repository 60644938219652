import React from 'react';
import { useTranslation } from 'react-i18next';

import { AdjustmentPeriod } from '../../../../../store/account/account-model';
import { Cell } from './styled';
import BillingPeriods, { useShowForm } from './BillingPeriodsTables';
import CreateUpdateAdjustmentPeriod from '../../create-update-billing-period/adjustment';
import { withCurrency } from '../../../../../utils/transform';
import { ReactComponent as AdjustmentIcon } from './assets/adjustment.svg';
import { useAppSelector } from '../../../../../store/hooks';
import { BillingPeriodsProps } from './SelectTransactionBillingPeriods';

const AdjustmentBillingPeriods = ({
  showingPeriod,
  currentInvoice,
}: BillingPeriodsProps) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'account.contract.billingPeriods',
  });
  const billingConfigurations = useAppSelector(
    state => state.account.billingConfigurations.entity,
  );
  const form = useShowForm<AdjustmentPeriod>();
  const { formVisible, formMode, formPeriod, closeForm } = form;

  const { currency, adjustment } = billingConfigurations || {};
  const { periods } = adjustment || {};

  const columns = [{ heading: t('createUpdate.adjustment.label') }];

  function periodToRow(period: AdjustmentPeriod, isCurrentInvoice: boolean) {
    const { amount } = period;

    return [
      <Cell $bold highlighted={isCurrentInvoice}>
        {withCurrency(amount, currency, false, 'code')}
      </Cell>,
    ];
  }

  return (
    <>
      <BillingPeriods
        {...form}
        columns={columns}
        rowGenerator={periodToRow}
        accountPeriods={periods}
        periodKey="adjustment"
        title={{
          text: t('title.adjustment'),
          icon: <AdjustmentIcon style={{ position: 'relative', top: '3px' }} />,
        }}
        showingPeriod={showingPeriod}
        currentInvoice={currentInvoice}
      />
      <CreateUpdateAdjustmentPeriod
        selectedPeriod={formPeriod}
        visible={formVisible}
        mode={formMode}
        onClose={() => {
          closeForm();
        }}
      />
    </>
  );
};

export default AdjustmentBillingPeriods;
