import styled from 'styled-components';

import { colors } from '../../../theme';

export const Description = styled.p`
  font-size: 14px;
  margin-bottom: 30px;
`;

export const StatusDescription = styled.p`
  font-size: 12px;
  margin: 0;
  padding: 12px 0;
  color: ${colors.grey5};
`;

export const DontShowAgainContainer = styled.div`
  margin-top: auto;
  padding-bottom: 20px;
`;
