import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import {
  Invoices,
  transformToBusinessInfo,
  transformToInvoices,
  transformToBrandUserDetails,
  BrandUserDetails,
  BusinessInformation,
  Program,
  MerchantAccount,
  transformToPrograms,
  transformToMerchantAccount,
} from './brand-user-model';
import { actionToError } from '../utils/transform';

export interface BrandUserState {
  loading: boolean;
  businessUpdated: boolean;
  businessInformation?: BusinessInformation;
  error?: any;
  programs?: Program[];
  merchantAccount?: MerchantAccount;
  invoices?: Invoices;
  details?: BrandUserDetails;
}

const initialState: BrandUserState = {
  loading: false,
  businessUpdated: false,
};

function putUserIntoState(state: BrandUserState, user: any) {
  state.details = transformToBrandUserDetails(user);

  if (!isEmpty(user.businessInformation)) {
    state.businessInformation = transformToBusinessInfo(
      user.businessInformation,
    );
  }

  state.programs = transformToPrograms(user.programs);
  state.merchantAccount = transformToMerchantAccount(user);
}

const brandUserSlice = createSlice({
  name: 'brandUser',
  initialState,
  reducers: {
    clear: () => initialState,

    completeStep(state) {
      state.loading = true;
      state.error = undefined;
    },

    completeStepSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      putUserIntoState(state, action.payload);
    },

    completeStepError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },

    updateSteps(state) {
      state.loading = true;
      state.error = undefined;
    },

    updateStepsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      putUserIntoState(state, action.payload);
    },

    updateStepsError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },

    getUser(state) {
      state.loading = true;
      state.error = undefined;
    },

    getUserSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      putUserIntoState(state, action.payload);
    },

    getUserError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },

    updateBusiness(state) {
      state.loading = true;
      state.error = undefined;
    },

    updateBusinessSuccess(state, action: PayloadAction<any>) {
      state.businessInformation = transformToBusinessInfo(action.payload);
      state.businessUpdated = true;
      state.loading = false;
    },

    updateBusinessError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },

    getInvoices(state) {
      state.loading = true;
      state.error = undefined;
    },

    getInvoicesSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.invoices = transformToInvoices(action.payload);
    },

    getInvoicesError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },
  },
});

export default brandUserSlice.reducer;
export const { actions } = brandUserSlice;
