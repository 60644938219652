import { AccountProfile } from '../../../store/account/account-model';
import { initialState } from '../../../store/account/account-reducer';
import { ModalButtonsConfigList, ListStyleConfig, FieldProps } from './types';

export const tBase = 'marketplace.publisher-profile';

export const formSteps = ['photo', 'pitch', 'links', 'audience'] as const;
export const modalSteps = ['intro', ...formSteps] as const;

export const modalButtons: ModalButtonsConfigList = [
  {
    tKey: 'dismiss',
    type: 'link',
    steps: ['intro'],
  },
  {
    tKey: 'complete',
    type: 'primary',
    steps: ['intro'],
  },
  {
    tKey: 'close',
    type: 'link',
    steps: [...formSteps],
  },
  {
    tKey: 'back',
    type: 'link',
    steps: formSteps.filter(step => step !== 'photo'),
  },
  {
    tKey: 'next',
    type: 'primary',
    steps: formSteps.filter(step => step !== 'audience'),
  },
  {
    tKey: 'submit',
    type: 'primary',
    steps: ['audience'],
  },
];

export const fieldsByStep = {
  photo: ['photo'] as const,
  pitch: ['pitch'] as const,
  links: ['websiteUrl', 'androidAppUrl', 'iosAppUrl'] as const,
  audience: ['audience', 'audienceSize', 'growthForecast'] as const,
};

export const recommendationsListStyle: ListStyleConfig = {
  photo: 'disc',
  pitch: 'disc',
  websiteUrl: undefined,
  androidAppUrl: 'decimal',
  iosAppUrl: 'decimal',
  audience: 'disc',
  audienceSize: 'disc',
  growthForecast: 'disc',
};

const linksFields = ({
  websiteUrl,
  androidAppUrl,
  iosAppUrl,
}: AccountProfile): FieldProps[] => [
  {
    name: 'websiteUrl',
    label: 'Website',
    storedValue: websiteUrl,
  },
  {
    name: 'androidAppUrl',
    label: 'Android app',
    storedValue: androidAppUrl,
  },
  {
    name: 'iosAppUrl',
    label: 'IOS app',
    storedValue: iosAppUrl,
  },
];

const audienceFields = ({
  audience,
  audienceSize,
  growthForecast,
}: AccountProfile): FieldProps[] => [
  {
    name: 'audience',
    label: 'Audience',
    storedValue: audience,
    height: 64,
  },
  {
    name: 'audienceSize',
    label: 'Audience size',
    storedValue: audienceSize,
    height: 64,
  },
  {
    name: 'growthForecast',
    label: 'Growth forecast (next 6 months)',
    storedValue: growthForecast,
    height: 36,
  },
];

export const fieldsConfig = {
  links: linksFields,
  audience: audienceFields,
};

export const fieldsDefaults = {
  photo: undefined,
  ...initialState.profile.entity,
};
