import React, { Ref } from 'react';
import { Col, InputNumber, Row } from 'antd';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Form from '../../../../../components/form';
import { PublisherOfferFormModel } from '../../../types';
import createUpdateOfferRules, { useOfferFormRef } from '../../validation';
import { OfferFormProps } from '..';
import DiscountType from './DiscountType';

interface DiscountInputProps {
  isUpdating: OfferFormProps['isUpdating'];
  isMarketplaceOffer: boolean;
}

function DiscountInput({ isUpdating, isMarketplaceOffer }: DiscountInputProps) {
  const { t } = useTranslation('offers');
  const {
    control,
    formState: { errors },
  } = useFormContext<PublisherOfferFormModel>();

  const fields: Record<string, Ref<any>> = useOfferFormRef();

  const rules = {
    ...createUpdateOfferRules.discountAmount,
    max: undefined,
  };

  return (
    <Form.Item
      validateStatus={(errors.discountAmount || errors.discountType) && 'error'}
    >
      <Row justify="space-between">
        <Col xs={{ span: 15 }}>
          <Form.ItemController
            label={t('fields.reward.label')}
            style={{ width: '100%' }}
            errors={errors}
            data-testid="discount-amount"
            controller={{
              name: 'discountAmount',
              render: ({ field }) => (
                <InputNumber
                  {...field}
                  ref={fields.discountAmount}
                  type="number"
                  placeholder={t('createUpdate.enterValue')}
                  step={0.01}
                  min={0.01}
                  disabled={isMarketplaceOffer}
                />
              ),
              rules,
              control,
            }}
          />
        </Col>

        <Col xs={{ offset: 1, span: 8 }}>
          <DiscountType disabled={isUpdating} />
        </Col>
      </Row>
    </Form.Item>
  );
}

export default DiscountInput;
