import React from 'react';
import { Namespace, Trans } from 'react-i18next';
import { SelectionContainer, SelectionText, Selected } from './styled';

interface SelectionIndicatorProps {
  count: number;
  actions?: React.ReactNode;
  ns?: Namespace;
  i18nKey: 'selected' | 'cards.selected' | 'transactions.selected';
}

const SelectionIndicator = ({
  count,
  actions,
  ns = 'common',
  i18nKey,
}: SelectionIndicatorProps) => {
  if (count === 0) {
    return null;
  }

  return (
    <SelectionContainer $hasActions={!!actions}>
      <SelectionText>
        <Trans
          components={[<Selected />]}
          count={count}
          ns={ns}
          i18nKey={i18nKey}
        />
      </SelectionText>
      {actions}
    </SelectionContainer>
  );
};

export default SelectionIndicator;
