import { Typography } from 'antd';
import styled from 'styled-components';

import Form from '../../../../components/form';
import { colors } from '../../../../theme';

export const Schedule = styled(Form.ItemController)`
  .ant-checkbox-group {
    align-items: center;
    background: ${colors.white};
    border: none;
    display: flex;
    justify-content: space-between;

    .ant-checkbox-group-item {
      align-items: center;
      border: 2px solid ${colors.grey2};
      border-radius: 50%;
      color: ${colors.grey5};
      display: flex;
      flex: 1 0 auto;
      font-size: 14px;
      height: 40px;
      justify-content: center;
      line-height: 34px;
      margin: 0;
      max-width: 40px;
      text-align: center;
      ${props => props.errors?.schedule && `border: 2px solid red;`};

      .ant-checkbox {
        display: block; /* Please do not use display:none as it doesn't trigger validation */
        height: 0;
        overflow: hidden;
        width: 0;
      }

      &.ant-checkbox-wrapper-checked {
        background: ${colors.lightblue};
        border: none;
        color: ${colors.fidelBlue600};
        font-weight: 500;
      }

      &:hover {
        &:not(&.ant-checkbox-wrapper-disabled) {
          color: ${colors.fidelBlue600};
          border: 2px solid ${colors.fidelBlue600};
          font-weight: 500;
        }
      }
    }
  }
`;

export const Schemes = styled(Form.ItemController)`
  .ant-checkbox-group-item {
    align-items: center;
    background: ${colors.lightgrey3};
    border-radius: 4px;
    display: inline-flex;
    height: 40px;
    margin: 0;
    max-width: calc(33% - 5px);
    padding: 0 10px;
    width: 88px;

    ${props =>
      props.errors?.schemes &&
      `
        border: 2px solid red;
      `};

    &:not(:last-of-type) {
      margin-right: 8px;
    }

    span {
      padding: 0;
      top: 0;
    }

    img {
      margin-left: 15px;
    }

    [alt='amex'] {
      height: 8px;
    }
  }
`;

export const HelpMessage = styled(Typography.Paragraph)`
  font-size: 12px;
  line-height: 18px;
  color: ${colors.grey4};
`;
