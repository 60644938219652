import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import memoNoProps from '../../utils/memo-no-props';
import Link from '../link';
import { StyledMenu, StyledMenuItem } from '../menu';
import Navigation from './components/Navigation';
import AccountMenu from './components/AccountMenu';
import LiveToggle from './components/LiveToggle';
import {
  Container,
  Bottom,
  BottomLink,
  BottomLinkDot,
  ReleaseWidgetStyle,
  HelpDropdown,
  LogoContainer,
  BottomRow,
} from './styled';
import FidelLogo from '../fidel-logo';
import useIsAccountLocked from '../../hooks/useIsAccountLocked';

const SideBar = () => {
  const { t } = useTranslation();

  const isAccountLocked = useIsAccountLocked();

  function initReleaseNotes() {
    const { Headway } = window as any;
    if (Headway) {
      const config = {
        selector: '#release-notes',
        account: 'yZGXVx',
      };
      Headway.init(config);
    } else {
      window.setTimeout(() => {
        initReleaseNotes();
      }, 50);
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(initReleaseNotes, []);

  const helpMenu = (
    <StyledMenu style={{ left: '-10px', top: '-10px' }}>
      <StyledMenuItem>
        <Link to="https://fidelapi.com/docs/">
          {t('nav.help.apiDocumentation')}
        </Link>
      </StyledMenuItem>
      <StyledMenuItem>
        <Link to="https://reference.fidel.uk/reference">
          {t('nav.help.apiReference')}
        </Link>
      </StyledMenuItem>
      <StyledMenuItem>
        <Link to="https://fidellimited.zendesk.com/">
          {t('nav.help.contactSupport')}
        </Link>
      </StyledMenuItem>
    </StyledMenu>
  );

  return (
    <Container>
      <ReleaseWidgetStyle />
      <AccountMenu />
      {!isAccountLocked && (
        <>
          <Navigation />
          <Bottom>
            <LiveToggle />
            <BottomRow align="middle" justify="space-between">
              <LogoContainer to="/">
                <FidelLogo type="solo" size="m" color="gray" />
              </LogoContainer>
              <BottomLink
                id="release-notes"
                href="https://releasenotes.fidel.uk"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('nav.releaseNotes')}
              </BottomLink>
              <BottomLinkDot>•</BottomLinkDot>
              <HelpDropdown overlay={helpMenu} trigger={['click']}>
                <span>{t('nav.help.dropdown')}</span>
              </HelpDropdown>
            </BottomRow>
          </Bottom>
        </>
      )}
    </Container>
  );
};

export default memoNoProps(SideBar);
