import { api } from '../../config';
import { getAllItems, getCount } from '../utils/pagination';
import http from '../../services/http-service';
import { validateStatus } from '../utils/validate-status';
import {
  AccountUniqueBrand,
  Brand,
  BrandConsent,
  BrandOptions,
  CreateBrandOptions,
  UniqueBrand,
  UniqueBrandOptions,
  UpdateBrandPayload,
} from './brands-model';
import { ApiResponse } from '../../types';

class BrandApiService {
  createBrand(brand: CreateBrandOptions['brand']) {
    return http.post('brands', brand);
  }

  updateBrand(brandId: string | undefined, brand: UpdateBrandPayload) {
    return http.patch(`brands/${brandId}`, brand);
  }

  deleteBrand(brandId: string) {
    return http.delete(`brands/${brandId}`);
  }

  deleteBrandUser(brandId: string, created: string) {
    return http.delete(`brands/${brandId}/users/${created}`);
  }

  getBrandUsers(brandId: string | undefined) {
    return http.get(`brands/${brandId}/users`);
  }

  getBrands({ name, limit, last, consent }: BrandOptions = {}) {
    const params: any = {};

    if (name) params.name = name;
    if (consent) params.consent = true;
    if (limit) params.limit = limit;
    if (last) params.start = JSON.stringify(last);

    return http.get<ApiResponse<Brand>>('brands', { params });
  }

  public getAllBrands(options?: BrandOptions) {
    return getAllItems(last => this.getBrands({ ...options, last }));
  }

  public getBrandsCount() {
    return getCount(last => {
      const params: any = { select: 'count' };
      if (last) params.start = JSON.stringify(last);
      return http.get('brands', { params });
    }, api.MAX_BRANDS_COUNT);
  }

  public requestConsent({
    brandId,
    programId,
    nameFirst,
    nameLast,
    email,
    title,
    skipInvite,
  }: BrandConsent) {
    const body = { nameFirst, nameLast, email, title, skipInvite };
    return http.post(`brands/${brandId}/programs/${programId}/users`, body);
  }

  public approveBrand(brandId: string, consent: boolean) {
    const body = { consent };
    return http.patch(`brands/${brandId}/consent`, body);
  }

  getUniqueBrands({ name, limit, last }: UniqueBrandOptions = {}) {
    const params: any = {};

    if (name) params.name = name;
    if (limit) params.limit = limit;
    if (last) params.start = JSON.stringify(last);

    return http.get<ApiResponse<UniqueBrand>>('unique-brands', { params });
  }

  getAccountUniqueBrands({
    accountId,
    name,
    last,
  }: {
    accountId: string | undefined;
    name: string;
    last: any;
  }) {
    const params = new URLSearchParams({ uniqueBrandName: name });
    if (!name) params.delete('uniqueBrandName');

    if (last) params.append('start', JSON.stringify(last));

    return http.get<ApiResponse<AccountUniqueBrand>>(
      `accounts/${accountId}/marketplace/unique-brands`,
      {
        params,
      },
    );
  }

  getUniqueBrandByBrandId(brandId: string) {
    return http.get(`brands/${brandId}/unique-brands`, {
      validateStatus,
    });
  }
}

export default BrandApiService;
