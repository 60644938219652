import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { Modal } from 'antd';

import {
  getUsers,
  revokeUser,
  cancelUserInvite,
} from '../../../store/users/users-actions';
import useIsSignedIn from '../../../hooks/use-is-signed-in';
import { Subsection, SubsectionTitle } from '../styled';
import { CurrentUser, InviteUsers, Invited } from '../styled/users';
import Ellipsis from '../../../components/ellipsis';
import Avatar from '../../../components/avatar';
import { InlineButton } from '../../../components/buttons';
import BaseTable from '../../../components/tables/BaseTable';
import InviteUser from './InviteUser';
import { User } from '../../../store/users/users-reducer';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

enum Rights {
  View = 'view',
  ViewAndEdit = 'viewEdit',
}

function findRoleFromInviteOnly(user: User): string | null {
  if (user.permission) return null;
  if (!user.invite) return null;

  return user.invite.role;
}

function findRoleFromPermission(user: User): string | null {
  if (!user.permission) return null;

  const permissions = Array.isArray(user.permission)
    ? user.permission
    : [user.permission];

  const firstPermission = permissions.find(
    (permission: any) => permission.role !== 'brand',
  );

  return firstPermission?.role ?? null;
}

interface ButtonProps {
  userId: string;
}

const CancelButton = ({ userId }: ButtonProps) => {
  const { isAccountAdmin } = useIsSignedIn();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  if (!isAccountAdmin) return <span />;

  return (
    <InlineButton
      type="link"
      onClick={() => {
        Modal.confirm({
          title: t('account.cancel.title'),
          content: (
            <Trans
              i18nKey="account.cancel.message"
              components={{ p: <p />, strong: <strong /> }}
            />
          ),
          okText: t('account.cancel.ok'),
          okButtonProps: { danger: true },
          onOk() {
            dispatch(cancelUserInvite({ userId }));
          },
        });
      }}
    >
      {t('account.users.cancel')}
    </InlineButton>
  );
};

const RevokeButton = ({ userId }: ButtonProps) => {
  const { user: currentUser, isAccountAdmin } = useIsSignedIn();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  if (!isAccountAdmin || currentUser?.userId === userId) return <span />;

  return (
    <InlineButton
      type="link"
      onClick={() => {
        Modal.confirm({
          title: t('account.revoke.title'),
          content: (
            <Trans
              i18nKey="account.revoke.message"
              components={{ p: <p />, strong: <strong /> }}
            />
          ),
          okText: t('account.revoke.ok'),
          okButtonProps: { danger: true },
          onOk() {
            dispatch(revokeUser({ userId }));
          },
        });
      }}
    >
      {t('account.users.revoke')}
    </InlineButton>
  );
};

const Users = () => {
  const { t } = useTranslation();
  const { user: currentUser, isAccountAdmin } = useIsSignedIn();
  const dispatch = useAppDispatch();
  const { email, loading, users } = useAppSelector(
    state => ({
      email: state.user.details?.email,
      loading: state.users.loading,
      users: state.users.users,
    }),
    shallowEqual,
  );
  const [showInviteUser, setShowInviteUser] = useState(false);

  const rights = isAccountAdmin ? Rights.ViewAndEdit : Rights.View;

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const actualUsersRows = (users || [])
    .filter((user: any) => findRoleFromPermission(user))
    .map((user: any) => ({
      contents: [
        <span>
          <Avatar size={24} shape="circle" style={{ marginRight: '10px' }}>
            {user.nameFirst}
          </Avatar>
          {user.nameFirst} {user.nameLast}
          {user.id === currentUser?.userId && (
            <CurrentUser> {t('account.users.currentUser')}</CurrentUser>
          )}
        </span>,
        <Ellipsis showTooltip>{user.email}</Ellipsis>,
        // TODO Fix 'any' belows on OFF-1406
        t(`account.role.${findRoleFromPermission(user)}` as any),
        <RevokeButton userId={user.id} />,
      ],
    }));

  const invitedUsersRows = (users || [])
    .filter((user: any) => findRoleFromInviteOnly(user))
    .map((user: any) => ({
      contents: [
        <Invited>{t('account.users.invited')}</Invited>,
        <Ellipsis showTooltip>{user.email}</Ellipsis>,
        t(`account.role.${findRoleFromInviteOnly(user)}` as any),
        <CancelButton userId={user.id} />,
      ],
    }));

  const usersRows = [...actualUsersRows, ...invitedUsersRows];

  const columns = [
    { heading: t('account.users.name'), size: 0.32 },
    { heading: t('account.users.email'), size: 0.32 },
    { heading: t('account.users.account'), size: 0.29 },
    { heading: '', size: 0.1 }, // Revoke
  ];

  if (!currentUser) return null;

  return (
    <>
      <Subsection>
        <SubsectionTitle>
          <h2>{t('account.info.email')}</h2>
          <p>{email}</p>
        </SubsectionTitle>
      </Subsection>
      <Subsection>
        <SubsectionTitle>
          <h2>{t('account.info.type')}</h2>
          <p>{t(`account.role.${currentUser?.role}` as any)}</p>
        </SubsectionTitle>
      </Subsection>
      <Subsection>
        <SubsectionTitle>
          <h2>{t('account.info.rights')}</h2>
          <p>{t(`account.rights.${rights}`)}</p>
        </SubsectionTitle>
      </Subsection>
      <Subsection>
        <SubsectionTitle>
          <h2>{t('account.users.title')}</h2>
        </SubsectionTitle>
        <p>{t('account.users.subtitle')}</p>
        <BaseTable
          bordered
          minWidth={500}
          columns={columns}
          rows={usersRows}
          loading={loading}
          bottomRowElement={
            usersRows.length > 0 &&
            isAccountAdmin && (
              <InviteUsers>
                <InlineButton
                  type="link"
                  onClick={() => setShowInviteUser(true)}
                >
                  {t('account.users.invite')}
                </InlineButton>
                <InviteUser
                  visible={showInviteUser}
                  onClose={() => setShowInviteUser(false)}
                />
              </InviteUsers>
            )
          }
          emptyText={t('account.users.noUsers')}
        />
      </Subsection>
    </>
  );
};

export default Users;
