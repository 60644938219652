import styled from 'styled-components';

export const DefaultOverlay = styled.div.attrs(() => ({
  'data-testid': 'default-overlay',
}))<{ mediaMaxWidth: string }>`
  background: rgba(0, 0, 0, 0.15);
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -1;

  @media (max-width: ${props => props.mediaMaxWidth}) {
    display: block;
  }
`;
