// Default notifications cross app
import common from './common.json';

// Read notifications from each module
import offers from '../offers/notifications.json';
import brands from '../brands/notifications.json';
import locations from '../locations/notifications.json';
import transactions from '../transactions/notifications.json';
import account from '../account/notifications.json';
import playground from '../playground/notifications.json';

const notificationsTranslations = {
  common,
  offers,
  brands,
  locations,
  transactions,
  account,
  playground,
};

export default notificationsTranslations;
