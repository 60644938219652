import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Input } from 'antd';

import { updateBillingConfigurations } from '../../../../store/account/account-actions';
import { Currency } from '../../../../store/account/account-model';
import { Select } from '../../../../components/select';
import ContractForm from './ContractForm';
import { invoiceEmailsController } from '../../../../components/form/components/invoice-emails';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

const currencies = ['GBP', 'USD', 'CAD', 'EUR', 'AED'] as const;

interface CustomerInfoForm {
  nameFirst: string;
  nameLast: string;
  accountId: string;
  companyNumber: string;
  vatNumber: string;
  currency: Currency;
  invoiceEmails: string[];
}

const CustomerInfo = () => {
  const { t } = useTranslation('common', {
    keyPrefix: 'account.contract.customerInfo.form',
  });
  const dispatch = useAppDispatch();
  const { accountDetails, billingConfigurations, userDetails } = useAppSelector(
    state => ({
      accountDetails: state.account.details,
      billingConfigurations: state.account.billingConfigurations.entity,
      userDetails: state.user.details,
    }),
    shallowEqual,
  );
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<CustomerInfoForm>({
    mode: 'onBlur',
    defaultValues: {
      nameFirst: userDetails?.nameFirst,
      nameLast: userDetails?.nameLast,
      accountId: accountDetails?.id,
      companyNumber: accountDetails?.companyNumber,
      vatNumber: accountDetails?.vatNumber,
      currency: billingConfigurations?.currency,
      invoiceEmails: billingConfigurations?.invoiceEmails,
    },
  });

  function onSubmit({ currency, invoiceEmails }: CustomerInfoForm) {
    dispatch(
      updateBillingConfigurations({
        currency,
        invoiceEmails,
      }),
    );
  }

  return (
    <ContractForm onSubmit={handleSubmit(onSubmit)}>
      <ContractForm.ItemController
        label={t('nameFirst')}
        controller={{
          name: 'nameFirst',
          render: ({ field }) => <Input {...field} disabled />,
          control,
        }}
      />
      <ContractForm.ItemController
        label={t('nameLast')}
        controller={{
          name: 'nameLast',
          render: ({ field }) => <Input {...field} disabled />,
          control,
        }}
      />
      {accountDetails?.countryCode === 'GBR' && (
        <ContractForm.ItemController
          label={t('companyNumber')}
          controller={{
            name: 'companyNumber',
            render: ({ field }) => <Input {...field} disabled />,
            control,
          }}
        />
      )}
      <ContractForm.ItemController
        label={t('accountId')}
        controller={{
          name: 'accountId',
          render: ({ field }) => <Input {...field} disabled />,
          control,
        }}
      />
      {accountDetails?.countryCode === 'GBR' && (
        <ContractForm.ItemController
          label={t('vatNumber')}
          errors={errors}
          validateCharacters
          controller={{
            name: 'vatNumber',
            render: ({ field }) => <Input {...field} disabled />,
            control,
          }}
        />
      )}
      <ContractForm.ItemController
        label={t('currency')}
        errors={errors}
        controller={{
          name: 'currency',
          render: ({ field }) => (
            <Select
              {...field}
              defaultValue={billingConfigurations?.currency as string}
            >
              {currencies.map(currency => (
                <Select.Option key={currency} value={currency}>
                  {t(`currencies.${currency}`)}
                </Select.Option>
              ))}
            </Select>
          ),
          rules: { required: true },
          control,
        }}
      />
      <ContractForm.ItemController
        label={t('invoiceEmails')}
        errors={errors}
        controller={invoiceEmailsController({ control, required: false })}
      />
    </ContractForm>
  );
};

export default CustomerInfo;
