import React from 'react';
import { Route, Redirect, Switch } from 'react-router';

import ResetPasswordRouter from '../modules/sign/ResetPasswordRouter';
import SignIn from '../modules/sign/SignIn';
import SignUp from '../modules/sign/SignUp';
import memoNoProps from '../utils/memo-no-props';

const PublicRoutes = () => (
  <Switch>
    <Route exact path="/sign-up" component={SignUp} />
    <Route exact path="/sign-in" component={SignIn} />
    <Route exact path="/reset-password" component={ResetPasswordRouter} />
    <Route render={props => <Redirect to="/sign-in" />} />
  </Switch>
);

export default memoNoProps(PublicRoutes);
