import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, Col, Input, Typography } from 'antd';

import Form from '../form';
import { useSetStatus } from '../../hooks/use-status';
import useProgressState from '../../hooks/use-progress-state';
import { colors } from '../../theme';

interface InlineFormProps {
  onSubmit: (...args: any[]) => void;
  loading: boolean;
  error: any;
  disabled?: boolean;
  submitLabel?: string;
  children?: ReactNode;
}

interface InlineFormComponentType extends InlineFormProps {
  ItemController?: typeof Form.ItemController;
}

const InlineForm = ({
  onSubmit,
  loading,
  error,
  disabled = false,
  submitLabel,
  children,
}: InlineFormComponentType) => {
  const { t } = useTranslation();
  const { setSuccessMessage } = useSetStatus();
  const updateProgress = useProgressState(loading);

  updateProgress.onFinish(() => {
    if (!error) {
      setSuccessMessage(t('form.changesSaved'));
    }
  });

  return (
    <Form>
      {children}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '24px',
        }}
      >
        <Button
          type="primary"
          disabled={disabled}
          onClick={onSubmit}
          size="small"
        >
          {submitLabel || t('form.saveChanges')}
        </Button>
      </div>
    </Form>
  );
};

export const InputWithSideText = ({ text, ...rest }: { text: string }) => (
  <div>
    <Input {...rest} style={{ maxWidth: '100px' }} />
    <Typography.Text style={{ marginLeft: '12px' }}>{text}</Typography.Text>
  </div>
);

interface ItemDescriptionProps {
  warning?: boolean;
  children?: ReactNode;
}

export const ItemDescription = ({
  warning,
  children,
}: ItemDescriptionProps) => (
  <Col sm={{ offset: 10 }} xs={{ offset: 0 }}>
    <WarningDescription warning={!!warning}>
      <Typography.Paragraph type="secondary">{children}</Typography.Paragraph>
    </WarningDescription>
  </Col>
);

const InlineFormItemControler = ({
  ...props
}: React.ComponentProps<typeof Form.ItemController>) => (
  <StyledItemController {...props} labelCol={{ span: 10 }} />
);

const StyledItemController = styled(Form.ItemController)`
  .ant-form-item-explain {
    margin-top: 0;
    margin-bottom: 8px;
  }
`;

const WarningDescription = styled.div<{ warning: boolean }>`
  margin-bottom: 2em;
  ${({ warning }) =>
    warning &&
    `
    background: ${colors.yellow3};
    font-size: 13px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    border-radius: 4px;

    .ant-typography {
      color: ${colors.darkOrange};
      margin-bottom: 0;
    }
  `};
`;

InlineForm.ItemController = InlineFormItemControler;

export default InlineForm;
