import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Image, Button } from 'antd';
import { uniqueId } from 'lodash';

interface ImageCropVisualizerProps {
  imagePath?: string;
  onChangeImage?: () => void;
  generateUniqueId?: boolean;
}

const ImageCropVisualizer = ({
  imagePath,
  onChangeImage,
  generateUniqueId = true,
}: ImageCropVisualizerProps) => {
  const { t } = useTranslation();

  function getImageSrc() {
    if (generateUniqueId) return `${imagePath}?id=${uniqueId()}`;

    return imagePath;
  }

  return (
    <Row gutter={16} align="middle">
      {imagePath && (
        <Col span={16}>
          <Image src={getImageSrc()} preview={false} />
        </Col>
      )}

      {onChangeImage && (
        <Col push={2}>
          <Button type="link" onClick={onChangeImage}>
            {t('components.imageWithCrop.actions.change')}
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default ImageCropVisualizer;
