import React from 'react';
import { useFormContext } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import Form from '../../../../../components/form';
import { Select } from '../../../../../components/select';
import { currencyBySupportedCountries } from '../../../../../utils/countries';
import { CreateUpdateOfferFormModel } from '../../../types';
import createUpdateOfferRules from '../../validation';

interface DiscountTypeProps {
  disabled: boolean;
}

export default function DiscountType({ disabled }: DiscountTypeProps) {
  const { t } = useTranslation('offers');

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<CreateUpdateOfferFormModel>();

  return (
    <Form.ItemController
      label={t('fields.reward.attribute')}
      style={{ width: '100%' }}
      errors={errors}
      data-testid="discount-attribute"
      controller={{
        name: 'discountType',
        render: ({ field }) => (
          <Select {...field} disabled={disabled} style={{ width: '100%' }}>
            <Select.Option value="percentage">
              {t('fields.reward.percentage')}
            </Select.Option>
            <Select.Option value="exact">
              {currencyBySupportedCountries[watch('country')] ||
                t('fields.reward.exact')}
            </Select.Option>
          </Select>
        ),
        rules: createUpdateOfferRules.discountType,
        control,
      }}
    />
  );
}
