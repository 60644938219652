import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { useLocation } from 'react-router';

import {
  deletePaymentMethod,
  getPaymentMethods,
  setDefaultPaymentMethod,
} from '../../../../store/account/account-actions';
import { selectPaymentFailedInvoices } from '../../../../store/account/account-selectors';
import { ReactComponent as Amex } from '../../../../assets/amex_icon.svg';
import { ReactComponent as Mastercard } from '../../../../assets/mastercard_icon.svg';
import { ReactComponent as Visa } from '../../../../assets/visa_icon.svg';
import { CardCell } from './styled';
import PayNow from './PayNow';
import AddPayment from '../AddPayment';
import { Subsection, SubsectionTitle } from '../../styled';
import { InlineButton } from '../../../../components/buttons';
import BaseTable from '../../../../components/tables/BaseTable';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

function getIcon(scheme: string) {
  const icons: any = {
    amex: <Amex />,
    'american express': <Amex />,
    mastercard: <Mastercard />,
    visa: <Visa />,
  };

  return icons[scheme];
}

export interface Card {
  isDefault: boolean;
  lastNumbers: string;
  scheme: string;
  cardId: string;
}

const PaymentMethods = ({
  paymentMethodType,
}: {
  paymentMethodType: string | undefined;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation<{ addPayment?: boolean }>();
  const [addPaymentOpen, setAddPaymentOpen] = useState(
    !!location.state?.addPayment,
  );
  const failedInvoices = useAppSelector(selectPaymentFailedInvoices);

  const { hasLiveAgreement, loadingPaymentMethods, paymentMethods } =
    useAppSelector(
      state => ({
        hasLiveAgreement: !!state.account.details?.liveAgreement,
        loadingPaymentMethods: state.account.paymentMethods.loading,
        paymentMethods: state.account.paymentMethods.entities,
      }),
      shallowEqual,
    );

  useEffect(() => {
    if (hasLiveAgreement && !paymentMethods) dispatch(getPaymentMethods());
  }, [hasLiveAgreement, dispatch, paymentMethods]);

  const cardToRow = ({ scheme, lastNumbers, isDefault, cardId }: Card) => {
    const actions = [
      {
        label: t('account.payment.actions.delete'),
        callback: () =>
          Modal.confirm({
            title: t('account.payment.delete.title'),
            content: t('account.payment.delete.message'),
            onOk: () => {
              dispatch(deletePaymentMethod(cardId));
            },
            okButtonProps: { danger: true },
            okText: t('confirm.delete'),
            maskClosable: true,
            icon: null,
          }),
        disabled: isDefault,
      },
    ];

    if (!isDefault) {
      actions.push({
        label: t('account.payment.actions.setDefault'),
        callback: () => dispatch(setDefaultPaymentMethod(cardId)),
        disabled: isDefault,
      } as any);
    }

    const contents = [
      getIcon(scheme.toLowerCase()),
      <CardCell>{lastNumbers}</CardCell>,
      isDefault && t('account.payment.default'),
    ];

    return { contents, actions };
  };

  return (
    <>
      {failedInvoices?.map(invoice => (
        <PayNow key={invoice.id} invoice={invoice} />
      ))}
      <Subsection>
        <SubsectionTitle style={{ marginBottom: '20px' }}>
          <h2>{t('account.paymentMethod.title')}</h2>
          {paymentMethodType && (
            <>
              <p>
                {t(
                  `account.paymentMethod.${
                    paymentMethodType === 'stripe' ? 'card' : 'bankTransfer'
                  }`,
                )}
                {paymentMethodType === 'stripe' && (
                  <InlineButton
                    type="link"
                    data-testid="add-card-btn"
                    onClick={() => setAddPaymentOpen(true)}
                  >
                    {t('addNew.paymentCard')}
                  </InlineButton>
                )}
              </p>
            </>
          )}
          <AddPayment
            visible={addPaymentOpen}
            onClose={() => setAddPaymentOpen(false)}
          />
        </SubsectionTitle>
        <BaseTable
          bordered
          minWidth={500}
          columns={[
            { heading: t('cards.label.scheme'), size: 0.25 },
            { heading: t('cards.label.lastNumbers'), size: 0.4 },
            { heading: '' },
          ]}
          loading={loadingPaymentMethods}
          rows={(paymentMethods || []).map((card: Card) => cardToRow(card))}
          emptyText={t('cards.empty')}
        />
      </Subsection>
    </>
  );
};

export default PaymentMethods;
