import { useEffect, useRef, useState } from 'react';

enum State {
  NotStarted,
  Started,
  Finished,
}

type OnFinishCallback = () => unknown;

export default function useProgressState(value: boolean) {
  const [state, setState] = useState(State.NotStarted);
  const onFinishCallback = useRef<OnFinishCallback>(() => {});

  useEffect(() => {
    if (state === State.NotStarted && value === true) {
      setState(State.Started);
    }

    if (state === State.Started && value === false) {
      setState(State.Finished);
    }

    if (state === State.Finished && value === true) {
      setState(State.Started);
    }
  }, [state, value]);

  useEffect(() => {
    if (state === State.Finished) {
      onFinishCallback.current();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return {
    inProgress: state === State.Started,
    hasFinished: state === State.Finished,
    onFinish(callback: OnFinishCallback) {
      onFinishCallback.current = callback;
    },
    reset() {
      setState(State.NotStarted);
    },
  };
}
