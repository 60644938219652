import React, { ReactNode, useContext, useState } from 'react';
import jwtDecode from 'jwt-decode';

import clearAll from '../store/clear-all';
import { useAppDispatch } from '../store/hooks';

export interface User {
  role: string;
  userId: string;
  accountId?: string;
  isAccountManager: boolean;
  isAccountManagerAdmin: boolean;
  isModerator: boolean;
}

const SignedInContext = React.createContext<{
  signedIn: boolean;
  isModerator: boolean;
  isAccountAdmin: boolean;
  isAccountManagerAdmin: boolean;
  isNewSession: boolean;
  signIn: (user: User, newSession?: boolean) => unknown;
  signOut: () => unknown;
  user?: User;
}>({
  signedIn: false,
  isAccountAdmin: false,
  isAccountManagerAdmin: false,
  isModerator: false,
  isNewSession: false,
  signIn: () => {},
  signOut: () => {},
});

export function sessionToUser(session: any): User {
  const {
    userId,
    permission,
    isAccountManager,
    isAccountManagerAdmin,
    isModerator,
  } = jwtDecode<any>(session.getIdToken().getJwtToken());
  const { accountId, role } = JSON.parse(permission);

  return {
    role,
    accountId,
    userId,
    isAccountManager: JSON.parse(isAccountManager),
    isAccountManagerAdmin: JSON.parse(isAccountManagerAdmin),
    isModerator: JSON.parse(isModerator),
  };
}

export const SignedInContextProvider = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const [isNewSession, setIsNewSession] = useState(false);
  const [user, setUser] = useState<User>();
  const dispatch = useAppDispatch();

  const { isModerator, isAccountManager, isAccountManagerAdmin } = user || {};

  const isModeratorUser = [
    !!isModerator,
    !!isAccountManager,
    !!isAccountManagerAdmin,
  ].some(role => role);

  const contextInterface = {
    user,
    isModerator: isModeratorUser,
    isAccountAdmin: isModeratorUser || user?.role === 'account',
    isAccountManagerAdmin: !!isAccountManagerAdmin,
    isNewSession,
    signedIn: !!user,
    signIn: (signedInUser: User, newSession = true) => {
      dispatch(clearAll());
      setIsNewSession(newSession);
      setUser(signedInUser);
    },
    signOut: () => {
      dispatch(clearAll());
      setUser(undefined);
    },
  };

  return (
    <SignedInContext.Provider value={contextInterface}>
      {children}
    </SignedInContext.Provider>
  );
};

export default function useIsSignedIn() {
  return useContext(SignedInContext);
}
