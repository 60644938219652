import regex from './regex';

export default function handleEmailValidation(values: string[] | string) {
  if (Array.isArray(values)) {
    return (values || []).every((value: string) => regex.email.test(value));
  }

  if (values && values.length > 0) return regex.email.test(values);
  return true;
}
