import styled from 'styled-components';

import { AmountCell } from '../../../../../components/tables/styled/helpers';
import { colors } from '../../../../../theme';

export const CardCell = styled.div`
  font-family: 'IBM Plex Mono', 'Menlo', monospace;
  color: ${colors.grey4};
`;

export const DownloadCell = styled.div`
  width: 100%;
  padding-right: 40px;
`;

export const PayNowAmount = styled(AmountCell)`
  font-size: 24px;
`;
