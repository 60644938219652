import React, { ReactElement, useEffect, useState } from 'react';

import { shallowEqual } from 'react-redux';
import { get } from 'lodash';
import { OffersMarketplaceOnboardingContext } from './context/OffersMarketplaceOnboardingContext';
import {
  getOffersMarketplaceOnboardingStepComponent,
  offerMarketplaceOnboardingKeys,
  OfferMarketplaceOnboardingKeys,
  OfferMarketplaceOnboardingStepTypes,
} from './config';
import { updateSteps } from '../../../store/brand-user/brand-user-actions';
import { onboardingKeys } from '../../../components/onboarding-tour/onboarding-config';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

interface OffersMarketplaceOnboardingProps {
  children: ReactElement;
  defaultStep?: OfferMarketplaceOnboardingStepTypes;
}

export const OffersMarketplaceOnboardingTour = ({
  children,
  defaultStep,
}: OffersMarketplaceOnboardingProps) => {
  const dispatch = useAppDispatch();

  const { userDetails, hasMarketplaceAccess } = useAppSelector(
    state => ({
      userDetails: state.user.details,
      hasMarketplaceAccess: state.account.marketplace.hasMarketplaceAccess,
    }),
    shallowEqual,
  );
  const [enabled, setEnabled] = useState(false);
  const [step, setStep] = useState(
    defaultStep || OfferMarketplaceOnboardingStepTypes.EXIT_TOUR,
  );

  useEffect(() => {
    let generalOnboardingEnabled = false;
    let shouldBeEnabled = false;

    if (!enabled && userDetails) {
      Object.keys(onboardingKeys).forEach(onboardingKey => {
        if (!get(userDetails, `stepsCompleted[${onboardingKey}]`)) {
          generalOnboardingEnabled = true;
        }
      });

      if (!generalOnboardingEnabled) {
        Object.keys(offerMarketplaceOnboardingKeys).forEach(onboardingKey => {
          if (
            hasMarketplaceAccess &&
            !shouldBeEnabled &&
            !get(userDetails, `stepsCompleted[${onboardingKey}]`)
          ) {
            shouldBeEnabled = true;
            setStep(
              offerMarketplaceOnboardingKeys[
                onboardingKey as OfferMarketplaceOnboardingKeys
              ],
            );
          }
        });
      }
    }

    setEnabled(shouldBeEnabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  function completeOnboardingStep(
    stepToComplete: OfferMarketplaceOnboardingKeys,
  ) {
    let shouldStepBeEnabled = true;
    const completedSteps: Record<string, boolean> = {};
    Object.keys(offerMarketplaceOnboardingKeys).forEach(key => {
      completedSteps[key] = shouldStepBeEnabled;

      if (key === stepToComplete) {
        shouldStepBeEnabled = false;
      }
    });
    dispatch(updateSteps(userDetails?.id as string, completedSteps));
  }

  return (
    <OffersMarketplaceOnboardingContext.Provider
      value={{
        enabled,
        step,
        setStep,
        completeOnboardingStep,
      }}
    >
      {getOffersMarketplaceOnboardingStepComponent(step)}
      {children}
    </OffersMarketplaceOnboardingContext.Provider>
  );
};
