import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, Row } from 'antd';

import drawerTransition, {
  DrawerVisibleProps,
} from '../../../components/drawer-transition';
import { Description } from '../styled/sync-locations';
import { SyncLocationsTable } from './SyncLocations';

interface LocationStatusHelperProps extends DrawerVisibleProps {
  onClose: () => unknown;
}

const LocationStatusHelper = ({
  onClose,
  visible,
}: LocationStatusHelperProps) => {
  const { t } = useTranslation('locations');

  return (
    <Drawer
      title={t('locationsHelper.title')}
      closable={false}
      destroyOnClose
      width={440}
      onClose={onClose}
      visible={visible}
      footer={
        <Row style={{ flex: '1 0 auto' }}>
          <div style={{ flex: '1 0 auto', textAlign: 'right' }}>
            <Button type="primary" onClick={onClose} size="small">
              {t('locationsHelper.close')}
            </Button>
          </div>
        </Row>
      }
    >
      <Description>{t('syncLocations.form.description')}</Description>
      <SyncLocationsTable />
    </Drawer>
  );
};

export default drawerTransition(LocationStatusHelper);
