import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { isEqual } from 'lodash';
import { Col, Row } from 'antd';
import {
  getAllUniqueLocations,
  getAllLinkedLocations,
  resetMapUniqueLocationsToProgramStatus,
} from '../../../../../../../store/locations/locations-actions';
import { supportedCountries } from '../../../../../../../utils/countries';
import { buildAddress } from '../../../../../../../utils/transform';
import { Select } from '../../../../../../../components/select';
import TextHighlighter from '../../../../../../../components/text-highlighter';
import useProgressState from '../../../../../../../hooks/use-progress-state';
import useDebounce from '../../../../../../../hooks/use-debounce';
import { useSetStatus } from '../../../../../../../hooks/use-status';
import { VirtualizedTable } from '../../../../../../../components/tables/VirtualizedTable';
import { Column } from '../../../../../../../components/tables/BaseTable';
import { Program } from '../../../../../../../store/programs/programs-reducer';
import {
  Location,
  UniqueLocation,
} from '../../../../../../../store/locations/locations-model';
import {
  Brand,
  UniqueBrand,
} from '../../../../../../../store/brands/brands-model';
import { FormSteps, LocationsFormSteps } from '../../../../../types';
import { CustomCollapse } from '../../../../../../../components/collapse/styled';
import { LocationsInfo } from '../../LocationsInfo';
import SearchSuffix from '../../../../../../../components/search-suffix';
import { SearchInput, SearchFilters, LocationIconWrapper } from '../../styled';
import { ReactComponent as SearchIcon } from '../../../../../../../assets/search.svg';
import { ReactComponent as LocationMarker } from '../../../../../../../assets/location_marker.svg';
import AddLocationsBrandCard from './AddLocationsBrandCard';
import { CountryCode } from '../../../../../../../types';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../store/hooks';

export type SearchFilterOptions =
  | 'address'
  | 'city'
  | 'countryCode'
  | 'postCode'
  | 'stateCode';

type SelectedUniqueLocation = Pick<UniqueLocation, 'id' | 'address'>;

export interface AddUniqueLocationsProps {
  program: Program;
  brand: Brand | null;
  uniqueBrand: UniqueBrand | null | undefined;
  setStep: Dispatch<SetStateAction<LocationsFormSteps>>;
  selectedUniqueLocations: any;
  setSelectedUniqueLocations: any;
  onClose: () => void;
  defaultSearchFilter?: SearchFilterOptions;
  defaultSearchValue?: CountryCode | string;
  hideFilters?: boolean;
  forceLoading?: boolean;
  forceMappingStateReset?: boolean;
}

export default function AddUniqueLocations({
  program,
  brand,
  uniqueBrand,
  setStep,
  selectedUniqueLocations,
  setSelectedUniqueLocations,
  onClose,
  defaultSearchFilter,
  defaultSearchValue,
  hideFilters,
  forceLoading,
  forceMappingStateReset,
}: AddUniqueLocationsProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'offers', 'notifications']);
  const { t: tBrands } = useTranslation('brands', {
    keyPrefix: 'addLocations',
  });

  const {
    createBrandStatus,
    uniqueLocations,
    programLocations,
    mapUniqueLocationsToProgramStatus,
  } = useAppSelector(
    state => ({
      createBrandStatus: state.brands.createBrandStatus,
      uniqueLocations: state.locations.uniqueLocations,
      programLocations: state.locations.locations,
      mapUniqueLocationsToProgramStatus:
        state.locations.mapUniqueLocationsToProgramStatus,
    }),
    shallowEqual,
  );

  const [searchValue, setSearchValue] = useState<string>(
    defaultSearchValue ?? '',
  );
  const debouncedSearchTerm = useDebounce(searchValue, 500);

  const defaultSearchFilterValue = defaultSearchFilter ?? 'address';
  const [searchFilter, setSearchFilter] = useState<SearchFilterOptions>(
    defaultSearchFilterValue,
  );

  const previousSearchFilter = React.useRef(searchFilter);

  const [addedFromSelection, setAddedFromSelection] = useState<
    SelectedUniqueLocation[]
  >([]);

  const accountBrandId = brand?.id || createBrandStatus.createdBrand?.brandId;

  const mapUniqueLocationsToProgramProgress = useProgressState(
    mapUniqueLocationsToProgramStatus.loading,
  );
  const { setSuccessMessage, setErrorMessage } = useSetStatus();

  const loadingLocations =
    uniqueLocations[uniqueBrand?.id ?? '']?.loading ||
    programLocations[program.id]?.loading ||
    mapUniqueLocationsToProgramProgress.inProgress ||
    forceLoading;

  const defaultCountryCode = supportedCountries
    .map(country => country.code)
    .includes(searchValue)
    ? searchValue
    : supportedCountries[0].code;

  const searchFiltersOptions = [
    {
      label: 'Address',
      value: 'address',
    },
    {
      label: 'City',
      value: 'city',
    },
    {
      label: 'Country code',
      value: 'countryCode',
    },
    {
      label: 'Post code',
      value: 'postcode',
    },
    {
      label: 'State code',
      value: 'stateCode',
    },
  ];

  const linkedLocationsTableColumns: Column[] = [
    {
      id: 'id',
      heading: 'id',
      hidden: true,
    },
    {
      id: 'address',
      heading: 'address',
    },
  ];
  const uniqueLocationsTableColumns: Column[] = [
    {
      id: 'id',
      heading: 'id',
      hidden: true,
    },
    {
      id: 'address',
      heading: tBrands('search.table.available.header', {
        numOfSelectedLocations: selectedUniqueLocations.length,
      }),
    },
  ];

  function getBrandLocations(programLocationsList: Location[]) {
    return programLocationsList
      .filter(({ brandId }) => brandId?.id === accountBrandId)
      .map(locationProps => ({
        ...locationProps,
        address: buildAddress(locationProps),
      }));
  }
  function getAvailableUniqueLocations(
    addedLocationsList: any[],
    uniqueLocationsList: UniqueLocation[],
  ) {
    return uniqueLocationsList
      .filter(
        ({ geolocation }) =>
          !addedLocationsList.find(locationProps =>
            isEqual(locationProps.geolocation, geolocation),
          ),
      )
      .filter(({ id }) => !addedFromSelection.find(props => props.id === id));
  }
  function filteredList(list: any[], searchTerm: string, compareProp: string) {
    return searchTerm
      ? list.filter((item: any) =>
          searchTerm
            .toLowerCase()
            .split(' ')
            .every(term => item[compareProp].toLowerCase().includes(term)),
        )
      : list;
  }
  function sanitizeSelectedLocations(
    selectedLocations: string[],
  ): SelectedUniqueLocation[] {
    return selectedLocations.map(({ address, ...props }: any) => ({
      ...props,
      address: address.props.text,
    }));
  }

  const uniqueLocationItems = (
    uniqueLocations[uniqueBrand?.id || '']?.items || []
  ).map((locationProps: UniqueLocation) => ({
    ...locationProps,
    address: buildAddress(locationProps),
  }));

  const brandLocations = getBrandLocations(
    programLocations[program.id]?.items || [],
  );
  const addedLocations = [...brandLocations, ...addedFromSelection];
  const availableLocations = getAvailableUniqueLocations(
    addedLocations,
    uniqueLocationItems,
  );

  const filteredAddedLocations = filteredList(
    addedLocations,
    debouncedSearchTerm,
    'address',
  );

  useEffect(() => {
    accountBrandId &&
      dispatch(getAllLinkedLocations(program.id, accountBrandId));
  }, [program.id, accountBrandId, dispatch]);

  useEffect(() => {
    if (uniqueBrand) {
      if (
        debouncedSearchTerm &&
        searchFilter === previousSearchFilter.current
      ) {
        dispatch(
          getAllUniqueLocations(uniqueBrand.id, {
            [searchFilter]: debouncedSearchTerm,
          }),
        );
      }
      if (!debouncedSearchTerm && searchFilter !== 'countryCode')
        dispatch(getAllUniqueLocations(uniqueBrand.id));
    }
  }, [
    debouncedSearchTerm,
    searchFilter,
    previousSearchFilter,
    uniqueBrand,
    dispatch,
  ]);

  useEffect(() => {
    previousSearchFilter.current = searchFilter;

    setSearchValue(searchFilter === 'countryCode' ? defaultCountryCode : '');
  }, [searchFilter, defaultCountryCode]);

  mapUniqueLocationsToProgramProgress.onFinish(() => {
    if (mapUniqueLocationsToProgramStatus.success) {
      if (selectedUniqueLocations.length) {
        setAddedFromSelection([
          ...addedFromSelection,
          ...selectedUniqueLocations,
        ]);

        setSelectedUniqueLocations([]);
      }

      if (forceMappingStateReset)
        dispatch(resetMapUniqueLocationsToProgramStatus());

      setSuccessMessage(
        t('notifications:brands.linkAllUniqueLocations.success'),
      );
    }
    if (mapUniqueLocationsToProgramStatus.error) {
      setErrorMessage(t('notifications:common.error.message'));
    }
  });

  const CollapseLocationsCount = ({
    locationsList,
  }: {
    locationsList: (Location | UniqueLocation)[];
  }) => <span>{!loadingLocations && locationsList.length}</span>;

  function virtualizedTableItems(locationsList: (Location | UniqueLocation)[]) {
    return locationsList.map(({ id, address }) => [
      id,
      <TextHighlighter text={address} searchTerm={debouncedSearchTerm} />,
    ]);
  }

  function addNewLocationCallback(event: React.MouseEvent) {
    event.stopPropagation();
    setStep(FormSteps.ADD_NEW_LOCATIONS);
  }

  return (
    <>
      <Row>
        <Col span={24}>
          {brand && (
            <AddLocationsBrandCard
              brand={uniqueBrand ?? brand}
              program={program}
              locationsInfo={
                <LocationsInfo
                  added={addedLocations.length}
                  total={addedLocations.length + availableLocations.length}
                  loading={!!loadingLocations}
                  callback={addNewLocationCallback}
                />
              }
            />
          )}
        </Col>
      </Row>

      {hideFilters || (
        <Row
          justify="space-between"
          gutter={8}
          style={{ margin: '0 -4px 32px' }}
          data-testid="unique-locations-filters"
        >
          <Col span={16}>
            {searchFilter === 'countryCode' ? (
              <Select
                data-testid="countries-select"
                defaultValue={defaultCountryCode}
                style={{ width: '100%' }}
                onChange={value => setSearchValue(value as string)}
              >
                {supportedCountries.map(({ name, code }) => (
                  <Select.Option key={code} value={code}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <SearchInput
                prefix={<SearchIcon />}
                suffix={
                  <SearchSuffix
                    label={tBrands('search.clear')}
                    searchValue={searchValue}
                    clearCallback={() => setSearchValue('')}
                    closeCallback={onClose}
                  />
                }
                onChange={({ target }) => setSearchValue(target.value)}
                value={searchValue}
                placeholder={tBrands('search.placeholder')}
              />
            )}
          </Col>

          <Col span={8}>
            <SearchFilters
              data-testid="search-filters-select"
              defaultValue={defaultSearchFilterValue}
              style={{ width: '100%' }}
              onChange={value => setSearchFilter(value as SearchFilterOptions)}
            >
              {searchFiltersOptions.map(({ label, value }) => (
                <Select.Option key={value} value={value}>
                  {label}
                </Select.Option>
              ))}
            </SearchFilters>
          </Col>
        </Row>
      )}

      <Row>
        <Col span={24}>
          <CustomCollapse defaultActiveKey={['collapse-panel-available']}>
            <CustomCollapse.Panel
              header={t('offers:createUpdate.locations.alreadyAdded')}
              key="collapse-panel-alreadyAdded"
              extra={
                <CollapseLocationsCount
                  locationsList={filteredAddedLocations}
                />
              }
            >
              <VirtualizedTable
                width="100%"
                height="313px"
                items={virtualizedTableItems(filteredAddedLocations)}
                icon={
                  <LocationIconWrapper>
                    <LocationMarker width={12} height={12} />
                  </LocationIconWrapper>
                }
                itemSize={40}
                columns={linkedLocationsTableColumns}
                loading={loadingLocations}
              />
            </CustomCollapse.Panel>

            {uniqueBrand && (
              <CustomCollapse.Panel
                header={t('offers:createUpdate.locations.available')}
                key="collapse-panel-available"
                extra={
                  <CollapseLocationsCount locationsList={availableLocations} />
                }
              >
                <VirtualizedTable
                  width="100%"
                  height="313px"
                  items={virtualizedTableItems(availableLocations)}
                  itemSize={40}
                  columns={uniqueLocationsTableColumns}
                  onSelectAll={values =>
                    setSelectedUniqueLocations(
                      sanitizeSelectedLocations(values),
                    )
                  }
                  loading={loadingLocations}
                  resetSelection={!selectedUniqueLocations.length}
                />
              </CustomCollapse.Panel>
            )}
          </CustomCollapse>
        </Col>
      </Row>
    </>
  );
}
