import React from 'react';
import { useTranslation } from 'react-i18next';
import Ellipsis from '../../../../../components/ellipsis';
import { buildAddress } from '../../../../../utils/transform';
import { Location } from '../../../../../store/locations/locations-model';
import { SelectedRowsType } from '../../../../../hooks/use-selected-rows';
import { InfiniteScrollTableStyled } from '../../styled/add-locations-form';

interface LinkedLocationsListProps {
  selectedLocations: SelectedRowsType<Location>;
  programLocations: Location[];
}

export default function LinkedLocationsList({
  selectedLocations,
  programLocations,
}: LinkedLocationsListProps) {
  const { t } = useTranslation('offers', {
    keyPrefix: 'createUpdate.locations',
  });

  const locationToRow = (location: Location) => ({
    contents: [<Ellipsis showTooltip>{buildAddress(location)}</Ellipsis>],
    selected: selectedLocations.isSelected(location),
    onSelect: selectedLocations.onSelect(location),
  });

  return (
    <InfiniteScrollTableStyled
      columns={[
        {
          heading: t('address'),
          sortedBy: 'unset',
        },
      ]}
      rows={programLocations.map(locationToRow)}
      total={programLocations.length}
      onSelectAll={selectedLocations.onSelectAll(programLocations)}
      stretch={false}
      minWidth={360}
      emptyText={t('empty')}
    />
  );
}
