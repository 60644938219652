import { AxiosError } from 'axios';
import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import http from '../../../services/http-service';
import { useSetStatus } from '../../../hooks/use-status';
import { parseAxiosError } from '../../../store/utils/transform';
import { Webhook, WebhookPayload } from './types';
import { ApiResponse } from '../../../types';

async function updateWebhook(
  hookId: string,
  webhook: WebhookPayload,
  programId: string,
) {
  const response = await http.patch<ApiResponse<Webhook>>(`hooks/${hookId}`, {
    ...webhook,
    ...(webhook.event !== 'brand.consent' ? { programId } : {}),
  });

  return response.data;
}

export function useUpdateWebhook(programId: string) {
  const queryClient = useQueryClient();
  const { setStatus } = useSetStatus();

  return useMutation(
    async ({ id, webhook }: { id: string; webhook: WebhookPayload }) =>
      updateWebhook(id, webhook, programId),
    {
      onSuccess: (
        data: ApiResponse<Webhook>,
        { id }: { id: string; webhook: WebhookPayload },
      ) => {
        const updatedWebhook = data.items[0];

        const cachedData = queryClient.getQueryData<
          InfiniteData<ApiResponse<Webhook>>
        >(['webhooks', programId]);

        const newCachedPages = cachedData?.pages.map(page => {
          const newCachedItems = page.items.map((item: Webhook) =>
            item.id === id ? { ...item, ...updatedWebhook } : item,
          );
          return { ...page, items: newCachedItems };
        });

        const newData = { ...cachedData, pages: newCachedPages };
        queryClient.setQueryData(['webhooks'], newData);
      },
      onError: (error: AxiosError) => {
        const { code, message } = parseAxiosError(error);

        setStatus(code, { message });
      },
      onSettled: () => {
        queryClient.invalidateQueries(['webhooks']);
      },
    },
  );
}
