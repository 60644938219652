import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../store/hooks';
import useSelectedProgram from './use-selected-program';

function useTransactionDistroOnly() {
  const { selectedProgram } = useSelectedProgram();

  const { products } = useAppSelector(
    state => ({
      products: state.account.details?.products,
      isLive: state.live,
    }),
    shallowEqual,
  );

  return {
    hasProgramTransactionDistroOnly:
      selectedProgram?.type === 'transaction-distro-only',
    hasAccountTransactionDistroOnly: products?.transactionDistroOnly === true,
  };
}

export default useTransactionDistroOnly;
