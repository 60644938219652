import styled from 'styled-components';
import { colors } from '../../../../../theme';
import { ReactComponent as BrandsPlusIcon } from '../../../../../assets/brands-plus.svg';

export const UniqueBrandsCreateText = styled.span`
  font-size: 14px;
`;
export const UniqueBrandsCreateSubtext = styled.span`
  font-size: 12px;
  color: ${colors.grey4};
`;
export const StyledBrandsPlusIcon = styled(BrandsPlusIcon)`
  margin-right: 14px;
  margin-left: 20px;
`;
