import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Input, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import Form from '../../components/form';
import { GoLiveFormProps } from '.';
import { Title } from './styled';
import { CityAndPostcode } from '../locations/styled/add-location';
import regex from '../../utils/regex';
import { GoLiveModel } from './use-go-live-form-state';
import { RequiredFormItemController } from '../../components/form/styled';
import { invoiceEmailsController } from '../../components/form/components/invoice-emails';
import SubForm from './SubForm';

const CompanyForm = ({ onClick }: GoLiveFormProps) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<GoLiveModel>();

  const { t } = useTranslation('common', {
    keyPrefix: 'account.goLive.steps.company',
  });

  const countryCode = watch('countryCode');

  return (
    <SubForm onClick={onClick}>
      <Title>{t('title')}</Title>
      <RequiredFormItemController
        label={t('fields.name')}
        errors={errors}
        validateCharacters
        controller={{
          name: 'name',
          render: ({ field }) => <Input {...field} />,
          rules: { required: true, minLength: 2, maxLength: 100 },
          control,
        }}
      />
      <RequiredFormItemController
        label={t('fields.legalName')}
        errors={errors}
        validateCharacters
        controller={{
          name: 'legalName',
          render: ({ field }) => <Input {...field} />,
          rules: { required: true, minLength: 2, maxLength: 100 },
          control,
        }}
      />
      <RequiredFormItemController
        label={t('fields.address')}
        errors={errors}
        validateCharacters
        controller={{
          name: 'address',
          render: ({ field }) => <Input {...field} />,
          rules: { required: true, minLength: 2, maxLength: 100 },
          control,
        }}
      />
      <Form.ItemController
        label={t('fields.address2')}
        errors={errors}
        validateCharacters
        controller={{
          name: 'address2',
          render: ({ field }) => <Input {...field} />,
          control,
        }}
      />
      <CityAndPostcode>
        <RequiredFormItemController
          label={t('fields.city')}
          errors={errors}
          validateCharacters
          controller={{
            name: 'city',
            render: ({ field }) => <Input {...field} />,
            rules: {
              required: true,
              minLength: 2,
              maxLength: 100,
              validate: { cityName: value => regex.cityName.test(value) },
            },
            control,
          }}
        />
        <RequiredFormItemController
          label={t('fields.postcode')}
          errors={errors}
          validateCharacters
          controller={{
            name: 'postcode',
            render: ({ field }) => <Input {...field} />,
            rules: { required: true, minLength: 4, maxLength: 20 },
            control,
          }}
        />
      </CityAndPostcode>
      {countryCode === 'GBR' && (
        <>
          <RequiredFormItemController
            label={t('fields.companyNumber')}
            errors={errors}
            validateCharacters
            controller={{
              name: 'companyNumber',
              render: ({ field }) => <Input {...field} />,
              rules: {
                required: true,
                minLength: 4,
                maxLength: 100,
                validate: { numeric: value => regex.numeric.test(value) },
              },
              control,
            }}
          />
          <RequiredFormItemController
            label={t('fields.vatNumber')}
            errors={errors}
            validateCharacters
            controller={{
              name: 'vatNumber',
              render: ({ field }) => <Input {...field} />,
              rules: {
                required: true,
                minLength: 4,
                maxLength: 100,
                validate: { vatNumber: value => regex.vatNumber.test(value) },
              },
              control,
            }}
          />
        </>
      )}
      <Form.ItemController
        label={t('fields.poNumber')}
        errors={errors}
        validateCharacters
        controller={{
          name: 'poNumber',
          render: ({ field }) => <Input {...field} />,
          rules: {
            required: false,
            minLength: 2,
            maxLength: 100,
          },
          control,
        }}
      />
      <RequiredFormItemController
        label={t('fields.invoiceEmails')}
        errors={errors}
        controller={invoiceEmailsController({
          control,
        })}
      >
        <Typography.Paragraph type="secondary" style={{ margin: '7px 0' }}>
          {t('invoiceEmailsHelp')}
        </Typography.Paragraph>
      </RequiredFormItemController>
    </SubForm>
  );
};

export default CompanyForm;
