import moment from 'moment';

import { BillingConfigurations } from '../../../../store/account/account-model';
import { useAppSelector } from '../../../../store/hooks';

export type PeriodKey = keyof Pick<
  BillingConfigurations,
  'transactionPricing' | 'adjustment' | 'transactionStreamPricing'
>;

export enum CreateUpdateBillingPeriodMode {
  Create = 'create',
  Update = 'update',
  Duplicate = 'duplicate',
}

export function findNextVoid(
  futureInvoices: number[],
  currentInvoice: number,
): number {
  if (!futureInvoices.length) return currentInvoice;

  for (
    let idx = 0, nextVoid = currentInvoice;
    idx < futureInvoices.length;
    idx++, nextVoid++
  ) {
    if (futureInvoices[idx] !== nextVoid) return nextVoid;
  }

  return futureInvoices?.[futureInvoices.length - 1] + 1;
}

export function useInvoiceInterval() {
  const billingConfigurations = useAppSelector(
    state => state.account.billingConfigurations.entity,
  );
  const { commencementDate, invoiceDay } = billingConfigurations || {};

  function getInvoiceInterval(relativeMonth = 0) {
    if (!invoiceDay)
      return {
        startDate: undefined,
        endDate: undefined,
      };

    const monthsUntilCommencementDate = moment(commencementDate).diff(
      moment().startOf('month'),
      'months',
    );

    const relativeMonthUntilCommencementDate =
      monthsUntilCommencementDate > 0
        ? relativeMonth + monthsUntilCommencementDate
        : relativeMonth;

    const commencementDateDay = moment(commencementDate).utc().date();

    const firstDateOfTheMonth = moment({
      year: moment().utc().year(),
      month: moment().utc().month(),
      day: 1,
    }).add(
      commencementDateDay > invoiceDay
        ? relativeMonthUntilCommencementDate + 1
        : relativeMonthUntilCommencementDate,
      'months',
    );

    const invoiceMonthNumberOfDays = firstDateOfTheMonth.daysInMonth();

    const endDate = firstDateOfTheMonth
      .clone()
      .add(
        invoiceMonthNumberOfDays > invoiceDay
          ? invoiceDay - 1
          : invoiceMonthNumberOfDays - 1,
        'days',
      );

    const startDate = endDate
      .clone()
      .subtract(1, 'months')
      .add(
        invoiceMonthNumberOfDays > invoiceDay
          ? 1
          : invoiceDay - invoiceMonthNumberOfDays + 1,
        'days',
      );

    return {
      start: startDate.format('DD/MM/YYYY'),
      end: endDate.format('DD/MM/YYYY'),
    };
  }

  return { getInvoiceInterval };
}
