import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Typography } from 'antd';

import Form from '../../components/form';
import Upload from '../../components/upload';
import {
  HiddenInput,
  RequiredFormItemController,
} from '../../components/form/styled';
import { GoLiveFormProps } from '.';
import { Title, Description } from './styled';
import { GoLiveModel } from './use-go-live-form-state';
import SubForm from './SubForm';

type LegalFields =
  | 'termsAndConditionsDocumentUrl'
  | 'privacyPolicyDocumentUrl'
  | 'faqDocumentUrl'
  | 'pciDssAoCDocumentUrl'
  | 'pciExplanation';

const LegalForm = ({ onClick }: GoLiveFormProps) => {
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<GoLiveModel>();

  const { t } = useTranslation('common', {
    keyPrefix: 'account.goLive.steps.legal',
  });

  function uploadFormItem(
    name: LegalFields,
    required: boolean,
    after?: React.ReactNode,
  ) {
    const FormItem = required
      ? RequiredFormItemController
      : Form.ItemController;
    const defaultValue = watch(name) as string;
    const defaultDocument = defaultValue
      ? {
          title: t(`fields.${name}`),
          url: defaultValue,
        }
      : undefined;

    return (
      <FormItem
        key={name}
        label={t(`fields.${name}`)}
        errors={errors}
        controller={{
          name,
          render: ({ field }) => <HiddenInput {...field} />,
          rules: { required },
          control,
        }}
      >
        <Upload
          defaultDocument={defaultDocument}
          documentId={name}
          setValue={setValue}
        />
        {after}
      </FormItem>
    );
  }

  return (
    <SubForm onClick={onClick}>
      <Title>{t('title')}</Title>
      <Description>{t('description')}</Description>
      {uploadFormItem('termsAndConditionsDocumentUrl', true)}
      {uploadFormItem('privacyPolicyDocumentUrl', true)}
      {uploadFormItem('faqDocumentUrl', false)}
      {uploadFormItem(
        'pciDssAoCDocumentUrl',
        false,
        <Typography.Paragraph type="secondary" style={{ margin: '7px 0' }}>
          <Trans
            i18nKey="account.goLive.steps.legal.fields.pciExplanation"
            components={[
              // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
              <a href="/" target="_blank" rel="noopener noreferrer" />,
            ]}
          />
        </Typography.Paragraph>,
      )}
    </SubForm>
  );
};

export default LegalForm;
