import React, { ReactNode, useContext, useEffect } from 'react';
import moment from 'moment';
import { Button, Col, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { OfferTransactionSource } from '@fidel.uk/types/lib/offer';
import { clear, getContentProviders } from '../../../store/offers';
import { Offer, OfferType } from '../../../store/offers/offers-model';
import { OfferActions, OnSelectProps } from '../types';
import {
  amountWithUnit,
  metadataHasProgramInfo,
  numOfAddedAndAvailablePrograms,
} from '../utils';
import useIsSignedIn from '../../../hooks/use-is-signed-in';
import useActions from './hooks/useActions';
import Header from '../../../components/header';
import { TableWithRightContentWrapper } from '../../../components/tables/styled/helpers';
import InfiniteScrollTable from '../../../components/tables/InfiniteScrollTable';
import { OffersMarketplaceOnboardingContext } from '../offers-marketplace-onboarding/context/OffersMarketplaceOnboardingContext';
import { OfferMarketplaceOnboardingStepTypes } from '../offers-marketplace-onboarding/config';
import { Column } from '../../../components/tables/BaseTable';
import Heading from './components/Heading';
import { DOUBLE_MINUS, MINUS } from '../../../utils/special-character';
import OfferStatus from './components/OfferStatus';
import OfferCard from '../components/OfferCard';
import { ProgramNameText } from './styled';
import { RowData } from '../../../components/tables/base/Row';
import OfferDetails from '../offer-details';
import Filters from '../../../components/filters';
import { useOffers, useOffersCount } from '../api/useOffers';
import { useSortWithParams } from '../../../hooks/use-sort-with-params';
import { OfferHeading } from './config';
import { getFilters, getTableContentByTab } from './utils';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import RejectAction from './components/RejectAction';
import { useApproveRejectOffer } from '../api/useApproveRejectOffer';
import { selectProgramsAsList } from '../../../store/programs/programs-selectors';

interface OffersListProps {
  onSelect: OnSelectProps;
}

const OffersList = ({ onSelect }: OffersListProps) => {
  const { type } = useParams<{ type: OfferType }>();
  const sortBy = useSortWithParams();
  const { data, refetch, isFetching, hasNextPage, fetchNextPage } = useOffers();
  const { data: offersCount } = useOffers({ count: true });
  const { data: totalOffersCount } = useOffersCount();
  const { mutate: approveRejectOffer, isLoading: isMutating } =
    useApproveRejectOffer();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'offers']);
  const { step } = useContext(OffersMarketplaceOnboardingContext);

  const { programsByCountry, isContentProvider } = useAppSelector(state => ({
    programsByCountry: state.programs.programsByCountry,
    isContentProvider: !!state.account.contentProviderInfo,
  }));

  const distroOnlyPrograms = useAppSelector(selectProgramsAsList)(
    'transaction-distro-only',
  );

  const { isModerator } = useIsSignedIn();

  const {
    rowActions: offerActions,
    rawDetail,
    detailActions,
    selectedOffer,
    setSelectedOffer,
  } = useActions(type, onSelect);

  const isAwaitingApprovalType = type === 'awaitingApproval';

  useEffect(() => {
    setSelectedOffer(undefined);
  }, [data, setSelectedOffer]);

  // fetch results if the BE returns a positive count but no items (pagination)
  useEffect(() => {
    const hasNoItems = data?.pages.flatMap(item => item.items).length === 0;
    if (hasNoItems && hasNextPage) {
      fetchNextPage();
    }
  }, [data?.pages, fetchNextPage, hasNextPage]);

  function reloadHandler() {
    dispatch(clear());
    refetch();
    if (isModerator) dispatch(getContentProviders());
  }

  const shouldLockTableScroll =
    step === OfferMarketplaceOnboardingStepTypes.MARKETPLACE_OFFER;

  const columns: Record<OfferHeading, Column> = {
    brandName: {
      heading: t('offers:brandOfferHeading'),
      size: 2,
      ...sortBy('brandName'),
    },
    created: {
      heading: t('offers:fields.createdDate.label'),
      ...sortBy('created'),
    },
    reward: {
      heading: (
        <Heading
          title={t('offers:fields.reward.label')}
          tooltip={t('offers:fields.reward.tooltip')}
        />
      ),
      ...sortBy('reward'),
    },
    locationsTotal: {
      heading: t('offers:fields.locations.label'),
      ...sortBy('locationsTotal'),
    },
    returnPeriod: {
      heading: t('offers:fields.returnPeriod.label'),
      ...sortBy('returnPeriod'),
    },
    endDate: {
      heading: t('offers:fields.endDate.label'),
      ...sortBy('endDate'),
    },
    requestedDate: {
      heading: t('offers:fields.requestedDate.label'),
      ...sortBy('created'),
    },
    status: {
      heading: t('offers:fields.status.label'),
    },
    inlineActions: {
      size: 1.5,
      heading: t('offers:actionsOfferHeading'),
    },
    programName: {
      heading: t('offers:fields.program.label'),
      ...sortBy('programName'),
    },
    programsLinkCount: {
      heading: (
        <Heading
          title={t('offers:fields.programs.label')}
          tooltip={t('offers:fields.programs.tooltip')}
        />
      ),
      ...sortBy('programsLinkCount'),
    },
    transactionSource: {
      size: 1.5,
      heading: t('offers:transactionSourceHeading'),
    },
  };

  const offerColumns = getTableContentByTab(type, columns, isContentProvider);

  function offerToRow(offer: Offer) {
    const {
      created,
      endDate,
      id,
      returnPeriod,
      requestedDate,
      programId,
      locationsTotal,
      publisherInfo,
      type: offerType,
      transactionSource = OfferTransactionSource.SELECT,
    } = offer;
    const { id: selectedOfferId, programId: selectedOfferProgramId } =
      selectedOffer || {};

    const { accountId: publisherId } = publisherInfo || {};

    const programName =
      offer.programName ??
      (metadataHasProgramInfo(offer.metadata) &&
        offer.metadata.programInfo.programName);

    const contents: Record<OfferHeading, ReactNode> = {
      brandName: (
        <OfferCard key={programId ? `${id}-${programId}` : id} offer={offer} />
      ),
      created: (created && moment(created).format('ll')) || DOUBLE_MINUS,
      reward: amountWithUnit(offer, offerType.value),
      locationsTotal: locationsTotal >= 0 ? locationsTotal : MINUS,
      endDate: (endDate && moment(endDate).format('ll')) || DOUBLE_MINUS,
      requestedDate: isAwaitingApprovalType
        ? moment(requestedDate || created).format('ll')
        : moment(created).fromNow(),
      returnPeriod: returnPeriod || DOUBLE_MINUS,
      status: <OfferStatus offer={offer} />,
      inlineActions: (
        <Space style={{ zIndex: '5' }}>
          <Button
            type="primary"
            size="small"
            data-testid="approve-offer"
            disabled={isMutating}
            onClick={e => {
              e.stopPropagation();
              approveRejectOffer({
                publisherId,
                programId,
                uniqueOfferId: id,
                status: 'approve',
              });
            }}
          >
            {t('common:moderator.review.approve')}
          </Button>
          <RejectAction
            isLoading={isMutating}
            onClick={(reason: string) =>
              approveRejectOffer({
                publisherId,
                programId,
                uniqueOfferId: id,
                status: 'reject',
                reason,
              })
            }
          />
        </Space>
      ),
      programName: (
        <ProgramNameText ellipsis={{ tooltip: true }}>
          {programName ?? DOUBLE_MINUS}
        </ProgramNameText>
      ),
      programsLinkCount: numOfAddedAndAvailablePrograms(
        offer,
        programsByCountry,
        distroOnlyPrograms,
      ),
      transactionSource: t(
        `offers:offerTransactionSource.${
          transactionSource as OfferTransactionSource
        }.shortTitle`,
      ),
    };

    const actions = offerActions(offer);

    const row: RowData = {
      contents: getTableContentByTab(type, contents, isContentProvider),
      actions,
      onClick: () =>
        selectedOffer?.id !== offer.id
          ? setSelectedOffer(offer)
          : setSelectedOffer(undefined),
      active: selectedOfferId === id && selectedOfferProgramId === programId,
    };

    return row;
  }

  const rows = data?.pages.flatMap(page => page.items.map(offerToRow)) || [];

  const emptyText =
    totalOffersCount?.[type].count > 0
      ? t('offers:emptyFilters')
      : t('offers:empty');

  return (
    <>
      <Row style={{ height: '60px' }}>
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <Header heading={t(`common:nav.${type}`)} />
            </Col>
            <Col>
              {type !== 'marketplace' && type !== 'awaitingApproval' && (
                <Button
                  type="primary"
                  size="small"
                  data-testid="create-offer"
                  onClick={() => onSelect.set.action(OfferActions.CREATE)}
                >
                  {t('common:addNew.offer')}
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      <Row
        justify="space-between"
        gutter={24}
        style={{ flexWrap: 'nowrap', height: 'calc(100% - 60px)' }}
      >
        <TableWithRightContentWrapper $showSideBar={!!selectedOffer}>
          <InfiniteScrollTable
            key={type}
            topContent={<Filters {...getFilters(type, isContentProvider)} />}
            emptyIllustration={
              isAwaitingApprovalType ? 'offersAwaitingApproval' : 'offers'
            }
            columns={offerColumns}
            rows={rows}
            onBottom={() => {
              if (hasNextPage) fetchNextPage();
            }}
            onReload={reloadHandler}
            total={offersCount?.pages[0]?.count}
            loading={isFetching || isMutating}
            emptyText={
              isAwaitingApprovalType
                ? t('offers:emptyAwaitingApproval')
                : emptyText
            }
            lockScroll={shouldLockTableScroll}
          />
        </TableWithRightContentWrapper>

        <Col>
          {selectedOffer && (
            <OfferDetails
              offer={selectedOffer}
              onSelect={onSelect}
              actions={detailActions(selectedOffer)}
              onClear={() => setSelectedOffer(undefined)}
            />
          )}
        </Col>

        {rawDetail}
      </Row>
    </>
  );
};

export default OffersList;
