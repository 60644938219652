import React from 'react';
import { useTranslation } from 'react-i18next';
import { InlineButton } from '../../../../components/buttons';

import { useSetStatus } from '../../../../hooks/use-status';
import InvoiceService from '../../../../services/invoice-service';
import downloadDocument from '../../../../utils/download-document';
import showFirst from '../../../../utils/show-first';
import { DownloadCell } from './styled';
import { useAppSelector } from '../../../../store/hooks';

interface DownloadProps {
  id: string;
  pdfUrl?: string;
  csvUrl?: string;
}

const Download = ({ id, pdfUrl, csvUrl }: DownloadProps) => {
  const { t } = useTranslation();
  const { setErrorMessage } = useSetStatus();
  const accountDetails = useAppSelector(state => state.account.details);
  const invoiceService = new InvoiceService(t, (error: string) =>
    setErrorMessage(error),
  );

  return (
    <DownloadCell>
      {showFirst([
        pdfUrl &&
          csvUrl &&
          ['pdf', 'csv'].map(format => (
            <span key={format}>
              <InlineButton
                type="link"
                onClick={() =>
                  downloadDocument(format === 'pdf' ? pdfUrl : csvUrl, () => {
                    invoiceService.download(id, accountDetails);
                  })
                }
              >
                {/* TODO Fix 'any' below on OFF-1406 */}
                {t(`account.billingHistory.table.${format}` as any)}
              </InlineButton>
              {format === 'pdf' && ' / '}
            </span>
          )),
        <InlineButton
          type="link"
          onClick={() =>
            downloadDocument(pdfUrl || csvUrl, () => {
              invoiceService.download(id, accountDetails);
            })
          }
        >
          {t('account.billingHistory.table.downloadInvoice')}
        </InlineButton>,
      ])}
    </DownloadCell>
  );
};

export default Download;
