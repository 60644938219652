import { includes } from 'lodash';

export const allCountries = [
  { name: 'Australia', code: 'AUS', code2: 'AU' },
  { name: 'Canada', code: 'CAN', code2: 'CA' },
  { name: 'Finland', code: 'FIN', code2: 'FI' },
  { name: 'Ireland', code: 'IRL', code2: 'IE' },
  { name: 'Japan', code: 'JPN', code2: 'JP' },
  { name: 'Norway', code: 'NOR', code2: 'NO' },
  { name: 'South Africa', code: 'ZAF', code2: 'ZA' },
  { name: 'Sweden', code: 'SWE', code2: 'SE' },
  {
    name: 'United Kingdom',
    code: 'GBR',
    code2: 'GB',
    alt: ['Great Britain', 'England', 'UK'],
  },
  {
    name: 'United States',
    code: 'USA',
    code2: 'US',
    alt: ['United States of America', 'USA'],
  },
  { name: 'Afghanistan', code: 'AFG', code2: 'AF' },
  { name: 'Albania', code: 'ALB', code2: 'AL' },
  { name: 'Algeria', code: 'DZA', code2: 'DZ' },
  { name: 'American Samoa', code: 'ASM', code2: 'AS' },
  { name: 'Andorra', code: 'AND', code2: 'AD' },
  { name: 'Angola', code: 'AGO', code2: 'AO' },
  { name: 'Anguilla', code: 'AIA', code2: 'AI' },
  { name: 'Antarctica', code: 'ATA', code2: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'ATG', code2: 'AG' },
  { name: 'Argentina', code: 'ARG', code2: 'AR' },
  { name: 'Armenia', code: 'ARM', code2: 'AM' },
  { name: 'Aruba', code: 'ABW', code2: 'AW' },
  { name: 'Austria', code: 'AUT', code2: 'AT' },
  { name: 'Azerbaijan', code: 'AZE', code2: 'AZ' },
  { name: 'Bahamas', code: 'BHS', code2: 'BS' },
  { name: 'Bahrain', code: 'BHR', code2: 'BH' },
  { name: 'Bangladesh', code: 'BGD', code2: 'BD' },
  { name: 'Barbados', code: 'BRB', code2: 'BB' },
  { name: 'Belarus', code: 'BLR', code2: 'BY' },
  { name: 'Belgium', code: 'BEL', code2: 'BE' },
  { name: 'Belize', code: 'BLZ', code2: 'BZ' },
  { name: 'Benin', code: 'BEN', code2: 'BJ' },
  { name: 'Bermuda', code: 'BMU', code2: 'BM' },
  { name: 'Bhutan', code: 'BTN', code2: 'BT' },
  { name: 'Bolivia', code: 'BOL', code2: 'BO' },
  { name: 'Bosnia and Herzegovina', code: 'BIH', code2: 'BA' },
  { name: 'Botswana', code: 'BWA', code2: 'BW' },
  { name: 'Brazil', code: 'BRA', code2: 'BR' },
  { name: 'Brunei', code: 'BRN', code2: 'BN' },
  { name: 'Bulgaria', code: 'BGR', code2: 'BG' },
  { name: 'Burkina Faso', code: 'BFA', code2: 'BF' },
  { name: 'Burundi', code: 'BDI', code2: 'BI' },
  { name: 'Cambodia', code: 'KHM', code2: 'KH' },
  { name: 'Cameroon', code: 'CMR', code2: 'CM' },
  { name: 'Cape Verde', code: 'CPV', code2: 'CV' },
  { name: 'Cayman Islands', code: 'CYM', code2: 'KY' },
  { name: 'Central African Republic', code: 'CAF', code2: 'CF' },
  { name: 'Chad', code: 'TCD', code2: 'TD' },
  { name: 'Chile', code: 'CHL', code2: 'CL' },
  { name: 'China', code: 'CHN', code2: 'CN' },
  { name: 'Colombia', code: 'COL', code2: 'CO' },
  { name: 'Comoros', code: 'COM', code2: 'KM' },
  {
    name: 'Congo (Democratic Republic)',
    code: 'COD',
    code2: 'CD',
    alt: ['DRC'],
  },
  { name: 'Congo (Republic)', code: 'COG', code2: 'CG' },
  { name: 'Costa Rica', code: 'CRI', code2: 'CR' },
  { name: 'Côte D’Ivoire', code: 'CIV', code2: 'CI', alt: ['Ivory Coast'] },
  { name: 'Croatia', code: 'HRV', code2: 'HR' },
  { name: 'Cuba', code: 'CUB', code2: 'CU' },
  { name: 'Cyprus', code: 'CYP', code2: 'CY' },
  { name: 'Czech Republic', code: 'CZE', code2: 'CZ' },
  { name: 'Denmark', code: 'DNK', code2: 'DK' },
  { name: 'Djibouti', code: 'DJI', code2: 'DJ' },
  { name: 'Dominica', code: 'DMA', code2: 'DM' },
  { name: 'Dominican Republic', code: 'DOM', code2: 'DO' },
  { name: 'Ecuador', code: 'ECU', code2: 'EC' },
  { name: 'Egypt', code: 'EGY', code2: 'EG' },
  { name: 'El Salvador', code: 'SLV', code2: 'SV' },
  { name: 'Equatorial Guinea', code: 'GNQ', code2: 'GQ' },
  { name: 'Eritrea', code: 'ERI', code2: 'ER' },
  { name: 'Estonia', code: 'EST', code2: 'EE' },
  { name: 'Eswatini', code: 'SWZ', code2: 'SZ', alt: ['Swaziland'] },
  { name: 'Ethiopia', code: 'ETH', code2: 'ET' },
  { name: 'Fiji', code: 'FJI', code2: 'FJ' },
  { name: 'France', code: 'FRA', code2: 'FR' },
  { name: 'French Guiana', code: 'GUF', code2: 'GF' },
  { name: 'Gabon', code: 'GAB', code2: 'GA' },
  { name: 'Gambia', code: 'GMB', code2: 'GM' },
  { name: 'Georgia', code: 'GEO', code2: 'GE' },
  { name: 'Germany', code: 'DEU', code2: 'DE' },
  { name: 'Ghana', code: 'GHA', code2: 'GH' },
  { name: 'Greece', code: 'GRC', code2: 'GR' },
  { name: 'Greenland', code: 'GRL', code2: 'GL' },
  { name: 'Grenada', code: 'GRD', code2: 'GD' },
  { name: 'Guatemala', code: 'GTM', code2: 'GT' },
  { name: 'Guinea', code: 'GIN', code2: 'GN' },
  { name: 'Guinea-Bissau', code: 'GNB', code2: 'GW' },
  { name: 'Guyana', code: 'GUY', code2: 'GY' },
  { name: 'Haiti', code: 'HTI', code2: 'HT' },
  { name: 'Vatican', code: 'VAT', code2: 'VA', alt: ['Holy See'] },
  { name: 'Honduras', code: 'HND', code2: 'HN' },
  { name: 'Hong Kong', code: 'HKG', code2: 'HK' },
  { name: 'Hungary', code: 'HUN', code2: 'HU' },
  { name: 'Iceland', code: 'ISL', code2: 'IS' },
  { name: 'India', code: 'IND', code2: 'IN' },
  { name: 'Indonesia', code: 'IDN', code2: 'ID' },
  { name: 'Iran', code: 'IRN', code2: 'IR' },
  { name: 'Iraq', code: 'IRQ', code2: 'IQ' },
  { name: 'Isle of Man', code: 'IMN', code2: 'IM' },
  { name: 'Israel', code: 'ISR', code2: 'IL' },
  { name: 'Italy', code: 'ITA', code2: 'IT' },
  { name: 'Jamaica', code: 'JAM', code2: 'JM' },
  { name: 'Jordan', code: 'JOR', code2: 'JO' },
  { name: 'Kazakhstan', code: 'KAZ', code2: 'KZ' },
  { name: 'Kenya', code: 'KEN', code2: 'KE' },
  { name: 'Kiribati', code: 'KIR', code2: 'KI' },
  {
    name: 'North Korea',
    code: 'PRK',
    code2: 'KP',
    alt: ['Democratic People’s Republic of Korea', 'DPRK'],
  },
  { name: 'South Korea', code: 'KOR', code2: 'KR', alt: ['Republic of Korea'] },
  { name: 'Kuwait', code: 'KWT', code2: 'KW' },
  { name: 'Kyrgyzstan', code: 'KGZ', code2: 'KG' },
  {
    name: 'Laos',
    code: 'LAO',
    code2: 'LA',
    alt: ['Lao People’s Democratic Republic'],
  },
  { name: 'Latvia', code: 'LVA', code2: 'LV' },
  { name: 'Lebanon', code: 'LBN', code2: 'LB' },
  { name: 'Lesotho', code: 'LSO', code2: 'LS' },
  { name: 'Liberia', code: 'LBR', code2: 'LR' },
  { name: 'Libya', code: 'LBY', code2: 'LY', alt: ['Libyan Arab Jamahiriya'] },
  { name: 'Liechtenstein', code: 'LIE', code2: 'LI' },
  { name: 'Lithuania', code: 'LTU', code2: 'LT' },
  { name: 'Luxembourg', code: 'LUX', code2: 'LU' },
  { name: 'Macao', code: 'MAC', code2: 'MO' },
  {
    name: 'Macedonia',
    code: 'MKD',
    code2: 'MK',
    alt: ['The Former Yugoslav Republic of Macedonia'],
  },
  { name: 'Madagascar', code: 'MDG', code2: 'MG' },
  { name: 'Malawi', code: 'MWI', code2: 'MW' },
  { name: 'Malaysia', code: 'MYS', code2: 'MY' },
  { name: 'Maldives', code: 'MDV', code2: 'MV' },
  { name: 'Mali', code: 'MLI', code2: 'ML' },
  { name: 'Malta', code: 'MLT', code2: 'MT' },
  { name: 'Marshall Islands', code: 'MHL', code2: 'MH' },
  { name: 'Martinique', code: 'MTQ', code2: 'MQ' },
  { name: 'Mauritania', code: 'MRT', code2: 'MR' },
  { name: 'Mauritius', code: 'MUS', code2: 'MU' },
  { name: 'Mayotte', code: 'MYT', code2: 'YT' },
  { name: 'Mexico', code: 'MEX', code2: 'MX' },
  {
    name: 'Micronesia',
    code: 'FSM',
    code2: 'FM',
    alt: ['Federated States of Micronesia'],
  },
  { name: 'Moldova', code: 'MDA', code2: 'MD', alt: ['Republic of Moldova'] },
  { name: 'Monaco', code: 'MCO', code2: 'MC' },
  { name: 'Mongolia', code: 'MNG', code2: 'MN' },
  { name: 'Montenegro', code: 'MNE', code2: 'ME' },
  { name: 'Morocco', code: 'MAR', code2: 'MA' },
  { name: 'Mozambique', code: 'MOZ', code2: 'MZ' },
  { name: 'Myanmar', code: 'MMR', code2: 'MM', alt: ['Burma'] },
  { name: 'Namibia', code: 'NAM', code2: 'NA' },
  { name: 'Nauru', code: 'NRU', code2: 'NR' },
  { name: 'Nepal', code: 'NPL', code2: 'NP' },
  { name: 'Netherlands', code: 'NLD', code2: 'NL' },
  { name: 'New Zealand', code: 'NZL', code2: 'NZ' },
  { name: 'Nicaragua', code: 'NIC', code2: 'NI' },
  { name: 'Niger', code: 'NER', code2: 'NE' },
  { name: 'Nigeria', code: 'NGA', code2: 'NG' },
  { name: 'Oman', code: 'OMN', code2: 'OM' },
  { name: 'Pakistan', code: 'PAK', code2: 'PK' },
  { name: 'Palau', code: 'PLW', code2: 'PW' },
  { name: 'Panama', code: 'PAN', code2: 'PA' },
  { name: 'Papua New Guinea', code: 'PNG', code2: 'PG' },
  { name: 'Paraguay', code: 'PRY', code2: 'PY' },
  { name: 'Peru', code: 'PER', code2: 'PE' },
  { name: 'Philippines', code: 'PHL', code2: 'PH' },
  { name: 'Poland', code: 'POL', code2: 'PL' },
  { name: 'Portugal', code: 'PRT', code2: 'PT' },
  { name: 'Puerto Rico', code: 'PRI', code2: 'PR' },
  { name: 'Qatar', code: 'QAT', code2: 'QA' },
  { name: 'Romania', code: 'ROU', code2: 'RO' },
  { name: 'Russia', code: 'RUS', code2: 'RU', alt: ['Russian Federation'] },
  { name: 'Rwanda', code: 'RWA', code2: 'RW' },
  { name: 'St Helena', code: 'SHN', code2: 'SH', alt: ['Saint Helena'] },
  {
    name: 'St Kitts & Nevis',
    code: 'KNA',
    code2: 'KN',
    alt: ['Saint Kitts and Nevis'],
  },
  { name: 'St Lucia', code: 'LCA', code2: 'LC', alt: ['St Lucia'] },
  {
    name: 'St Vincent & Grenadines',
    code: 'VCT',
    code2: 'VC',
    alt: ['Saint Vincent and the Grenadines'],
  },
  { name: 'Samoa', code: 'WSM', code2: 'WS' },
  { name: 'San Marino', code: 'SMR', code2: 'SM' },
  {
    name: 'São Tomé & Príncipe',
    code: 'STP',
    code2: 'ST',
    alt: ['Sao Tome and Principe'],
  },
  { name: 'Saudi Arabia', code: 'SAU', code2: 'SA' },
  { name: 'Senegal', code: 'SEN', code2: 'SN' },
  { name: 'Serbia', code: 'SRB', code2: 'RS' },
  { name: 'Seychelles', code: 'SYC', code2: 'SC' },
  { name: 'Sierra Leone', code: 'SLE', code2: 'SL' },
  { name: 'Singapore', code: 'SGP', code2: 'SG' },
  { name: 'Slovakia', code: 'SVK', code2: 'SK' },
  { name: 'Slovenia', code: 'SVN', code2: 'SI' },
  { name: 'Solomon Islands', code: 'SLB', code2: 'SB' },
  { name: 'Somalia', code: 'SOM', code2: 'SO' },
  { name: 'South Sudan', code: 'SSD', code2: 'SS' },
  { name: 'Spain', code: 'ESP', code2: 'ES' },
  { name: 'Sri Lanka', code: 'LKA', code2: 'LK' },
  { name: 'Sudan', code: 'SDN', code2: 'SD' },
  { name: 'Suriname', code: 'SUR', code2: 'SR' },
  { name: 'Switzerland', code: 'CHE', code2: 'CH' },
  { name: 'Syria', code: 'SYR', code2: 'SY' },
  { name: 'Taiwan', code: 'TWN', code2: 'TW' },
  { name: 'Tajikistan', code: 'TJK', code2: 'TJ' },
  { name: 'Tanzania', code: 'TZA', code2: 'TZ' },
  { name: 'Thailand', code: 'THA', code2: 'TH' },
  { name: 'Timor-Leste', code: 'TLS', code2: 'TL', alt: ['East Timor'] },
  { name: 'Togo', code: 'TGO', code2: 'TG' },
  { name: 'Tonga', code: 'TON', code2: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TTO', code2: 'TT' },
  { name: 'Tunisia', code: 'TUN', code2: 'TN' },
  { name: 'Turkey', code: 'TUR', code2: 'TR' },
  { name: 'Turkmenistan', code: 'TKM', code2: 'TM' },
  { name: 'Tuvalu', code: 'TUV', code2: 'TV' },
  { name: 'Uganda', code: 'UGA', code2: 'UG' },
  { name: 'Ukraine', code: 'UKR', code2: 'UA' },
  { name: 'United Arab Emirates', code: 'ARE', code2: 'AE', alt: ['UAE'] },
  { name: 'Uruguay', code: 'URY', code2: 'UY' },
  { name: 'Uzbekistan', code: 'UZB', code2: 'UZ' },
  { name: 'Vanuatu', code: 'VUT', code2: 'VU' },
  { name: 'Venezuela', code: 'VEN', code2: 'VE' },
  { name: 'Vietnam', code: 'VNM', code2: 'VN' },
  { name: 'Western Sahara', code: 'ESH', code2: 'EH' },
  { name: 'Yemen', code: 'YEM', code2: 'YE' },
  { name: 'Zambia', code: 'ZMB', code2: 'ZM' },
  { name: 'Zimbabwe', code: 'ZWE', code2: 'ZW' },
];

const supportedCountriesCodes = [
  'ARE',
  'CAN',
  'FIN',
  'GBR',
  'IRL',
  'JPN',
  'NOR',
  'SWE',
  'USA',
] as const;
export type SupportedCountriesType = typeof supportedCountriesCodes[number];

export const supportedCountries = allCountries
  .filter(({ code }) => includes(supportedCountriesCodes, code))
  .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

export const goLiveCountries = supportedCountries
  .filter(({ code }) => code !== 'JPN')
  .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

export function isVatCountry(
  countryCode: string | undefined,
  vatNumber: string | undefined,
) {
  if (countryCode === 'GBR') return true;
  if (['CAN', 'USA'].some(code => code === countryCode)) return false;
  if (vatNumber) {
    return false;
  }
  return true;
}

export const currencies = [
  'AED',
  'AUD',
  'CAD',
  'CHF',
  'EUR',
  'GBP',
  'JPY',
  'NOK',
  'NZD',
  'SEK',
  'USD',
] as const;
export type CurrencyType = typeof currencies[number];

export const currencyBySupportedCountries: Record<string, CurrencyType> = {
  CAN: 'CAD',
  FIN: 'EUR',
  GBR: 'GBP',
  IRL: 'EUR',
  JPN: 'JPY',
  NOR: 'NOK',
  SWE: 'SEK',
  USA: 'USD',
  CHE: 'CHF',
  NZL: 'NZD',
  ARE: 'AED',
};
