import styled from 'styled-components';
import { colors } from '../../../theme';

export const Description = styled.p`
  color: ${colors.grey4};
`;

export const Brand = styled.h2`
  font-weight: normal;
`;
