import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormSetValue } from 'react-hook-form';
import { Button } from 'antd';
import { RcFile } from 'antd/lib/upload';

import DocumentsApiService from '../../store/documents/documents-api-service';
import {
  DocumentContainer,
  DocumentContainerSection,
  DownloadAndRemove,
  UploadStyled,
} from './styled';
import { useSetStatus } from '../../hooks/use-status';
import { Download, X } from '../icons';
import downloadDocument from '../../utils/download-document';
import showFirst from '../../utils/show-first';
import { ReactComponent as DocumentIcon } from '../../assets/document.svg';
import { useAppSelector } from '../../store/hooks';

export const supportedFileTypes = [
  '.doc',
  '.docx',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];
export interface UploadProps {
  defaultDocument:
    | {
        title: string;
        url: string;
      }
    | undefined;
  documentId: string;
  setValue: UseFormSetValue<any>;
  accept?: string[];
}

const Upload = ({
  defaultDocument,
  documentId,
  setValue,
  accept = supportedFileTypes,
}: UploadProps) => {
  const [filelist, setFilelist] = useState<any[]>([]);
  const [uploading, setUploading] = useState(false);
  const account = useAppSelector(state => state.account);
  const documentsApiService = new DocumentsApiService();
  const { setErrorMessage } = useSetStatus();
  const { t } = useTranslation('common', { keyPrefix: 'form.upload' });

  const { uploadDocument } = documentsApiService;

  function removeDocument() {
    setFilelist([]);
    setValue(documentId, '');
  }

  async function upload({ file }: any) {
    setUploading(true);

    const form = new FormData();
    form.append(documentId, file);

    try {
      const response = await uploadDocument(account.details?.id ?? '', form);
      setValue(documentId, response.data.items[0].url, {
        shouldValidate: true,
      });
      setFilelist([file]);
      setUploading(false);
    } catch (e) {
      setErrorMessage(t('uploadingError'));
      setUploading(false);
    }
  }

  return (
    <>
      {showFirst([
        defaultDocument && (
          <DocumentContainer>
            <DocumentContainerSection>
              <DocumentIcon />{' '}
              <span style={{ marginLeft: '8px', marginTop: '2px' }}>
                {defaultDocument.title}
              </span>
            </DocumentContainerSection>
            <DownloadAndRemove>
              <Download
                data-testid="download"
                style={{ marginRight: '16px', cursor: 'pointer' }}
                onClick={() =>
                  downloadDocument(defaultDocument.url, () =>
                    setErrorMessage(t('downloadError')),
                  )
                }
              />
              <X data-testid="remove" onClick={removeDocument} />
            </DownloadAndRemove>
          </DocumentContainer>
        ),
        <UploadStyled
          data-testid={`upload-${documentId}`}
          fileList={filelist}
          multiple={false}
          customRequest={upload}
          onRemove={removeDocument}
          beforeUpload={(file: RcFile): boolean | PromiseLike<void> => {
            if (!accept.includes(file.type)) {
              return false;
            }
            return true;
          }}
          accept={accept.join(',')}
        >
          <Button
            style={{ textAlign: 'left' }}
            block
            disabled={uploading || filelist.length > 0}
          >
            {uploading ? t('uploadingFile') : t('chooseFile')}
          </Button>
        </UploadStyled>,
      ])}
    </>
  );
};

export default Upload;
