import styled from 'styled-components';

export const Title = styled.div`
  svg {
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 0;
  }

  text-align: center;
  font-weight: 400;
`;
