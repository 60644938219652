import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import GoLive from '../../go-live';
import KeySet from './KeySet';
import Schemes from './Schemes';
import UpdateApiModal from './UpdateApiModal';
import Link from '../../../components/link';
import Spin from '../../../components/spin';
import { Subsection, SubsectionTitle, SubsectionSubtitle } from '../styled';
import useIsSignedIn from '../../../hooks/use-is-signed-in';
import { getApiKeys, getVersion } from '../../../store/account/account-actions';
import { GreyDropdownButton } from '../../../components/buttons';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

const Plan = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isAccountAdmin } = useIsSignedIn();
  const [goLiveVisible, setGoLiveVisible] = useState(false);
  const [showUpgradeApiModal, setShowUpgradeApiModal] = useState(false);

  const { details, apiKeys, lastApiVersion } = useAppSelector(
    state => ({
      details: state.account.details,
      apiKeys: state.account.apiKeys,
      lastApiVersion: state.account.version?.id,
    }),
    shallowEqual,
  );

  const { liveActive, apiVersion } = details || {};

  const showUpgradeApi =
    isAccountAdmin && lastApiVersion && apiVersion !== lastApiVersion;

  useEffect(() => {
    if (!apiKeys) dispatch(getApiKeys());
  }, [dispatch, apiKeys]);

  useEffect(() => {
    if (!lastApiVersion) dispatch(getVersion());
  }, [dispatch, lastApiVersion]);

  return (
    <>
      {details && apiKeys && (
        <>
          {apiVersion && (
            <Subsection>
              <SubsectionTitle>
                <div>
                  <h2>{t('account.apiVersion.title')}</h2>
                  <SubsectionSubtitle>
                    {t('account.apiVersion.description', {
                      versionDate: apiVersion,
                    })}
                    <Link
                      style={{ marginLeft: '6px' }}
                      to="https://reference.fidel.uk/docs/changelog"
                    >
                      {t('account.seeChangelog')}
                    </Link>
                  </SubsectionSubtitle>
                </div>
                {showUpgradeApi && (
                  <>
                    <GreyDropdownButton
                      size="small"
                      onClick={() => setShowUpgradeApiModal(true)}
                    >
                      {t('account.apiVersion.upgrade.cta')}
                    </GreyDropdownButton>{' '}
                    <UpdateApiModal
                      showModal={showUpgradeApiModal}
                      setShowModal={setShowUpgradeApiModal}
                    />
                  </>
                )}
              </SubsectionTitle>
            </Subsection>
          )}
          {liveActive && isAccountAdmin && (
            <Subsection>
              <SubsectionTitle>
                <h2>{t('account.schemes.title')}</h2>
              </SubsectionTitle>
              <p>{t('account.schemes.subtitle')}</p>
              <Schemes />
            </Subsection>
          )}
          {apiKeys?.secret && (
            <KeySet
              type="api"
              keySet={apiKeys?.secret}
              setGoLiveVisible={setGoLiveVisible}
            />
          )}
          {apiKeys?.public && (
            <KeySet
              type="sdk"
              keySet={apiKeys?.public}
              setGoLiveVisible={setGoLiveVisible}
            />
          )}
        </>
      )}
      {(!details || !apiKeys) && <Spin />}
      <GoLive
        visible={goLiveVisible}
        onClose={() => {
          setGoLiveVisible(false);
        }}
      />
    </>
  );
};

export default Plan;
