import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import { getExportableTransactions } from '../../../store/transactions/transactions-actions';
import { selectHideLastNumbers } from '../../../store/account/account-selectors';
import ExportDropdown from '../../../components/export-dropdown';
import useSelectedProgram from '../../../hooks/use-selected-program';

import ExportService from '../../../services/export-service';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

interface TransactionsExportProps {
  selectedIds?: string[];
}

const TransactionsExport = ({ selectedIds }: TransactionsExportProps) => {
  const { t } = useTranslation();
  const { selectedProgram } = useSelectedProgram();
  const dispatch = useAppDispatch();
  const { transactions, exportableTransactions, filters } = useAppSelector(
    state => ({
      transactions: state.transactions.transactions,
      exportableTransactions: state.transactions.exportableTransactions,
      filters: state.filters.transactions,
    }),
    shallowEqual,
  );
  const [fileFormat, setFileFormat] = useState('pdf');
  const [selectFormatVisible, setSelectFormatVisible] = useState(false);
  const [shouldExport, setShouldExport] = useState(false);
  const disabledExportDropdown = transactions?.length === 0;
  const hideLastNumbers = useAppSelector(selectHideLastNumbers);

  function exportTransactions(format: string) {
    const exportService = new ExportService(t);

    const columnsOptions = [
      { key: 'id', title: t('transactions.label.id') },
      { key: 'card', title: t('transactions.label.card') },
      { key: 'cardId', title: t('transactions.label.cardId') },
      { key: 'scheme', title: t('transactions.label.scheme') },
      {
        key: 'amount',
        title: t('transactions.label.amount'),
      },
      ...(format == 'csv'
        ? [{ key: 'currency', title: t('transactions.label.currency') }]
        : []),
      { key: 'brand', title: t('transactions.label.brand') },
      { key: 'address', title: t('transactions.label.location') },
      { key: 'locationId', title: t('transactions.label.locationId') },
      { key: 'merchantId', title: t('transactions.label.merchantId') },
      {
        key: 'date',
        title: t('transactions.label.date'),
      },
    ];

    const transactionsToExport = (
      selectedIds ? transactions : exportableTransactions
    ).filter(
      transaction => !selectedIds || selectedIds.includes(transaction.id),
    );

    exportService.exportTransactions({
      options: columnsOptions.filter(column =>
        hideLastNumbers ? column.key !== 'card' : column.key !== 'cardId',
      ),
      format,
      raw: transactionsToExport,
      programName: selectedProgram?.name,
      dateRange: filters.dateRange,
    });

    setShouldExport(false);
  }

  useEffect(() => {
    if (shouldExport && selectedProgram && exportableTransactions?.length > 0) {
      exportTransactions(fileFormat);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportableTransactions, selectedProgram, shouldExport]);

  function handleClickExport(format: string) {
    setFileFormat(format);
    setSelectFormatVisible(false);
    setShouldExport(true);

    if (selectedIds) {
      exportTransactions(format);
      return;
    }

    dispatch(
      getExportableTransactions({
        programId: selectedProgram?.id,
        ...filters,
      }),
    );
  }

  return (
    <ExportDropdown
      label={selectedIds ? t('export.selectedLabel') : t('export.label')}
      disabled={disabledExportDropdown}
      handleClickExport={handleClickExport}
      showSelectFormat={setSelectFormatVisible}
      selectFileFormatOpen={selectFormatVisible}
    />
  );
};

export default TransactionsExport;
