import React, { useEffect, useState, ReactElement } from 'react';
import Confetti from 'react-dom-confetti';

import {
  ModalWrapper,
  ModalPanelWrapper,
  ModalRightPanel,
  ModalLeftPanel,
  ModalCustom,
  ModalPanelActions,
  ModalPanelButtonsWrapper,
  ModalPanelDescription,
  ModalPanelTip,
  ModalPanelTitle,
  ConfettiWrapper,
  CloseButton,
} from './styled';

export interface ModalProps {
  title: string | ReactElement;
  description: ReactElement;
  direction?: 'horizontal' | 'vertical';
  tip?: ReactElement;
  buttons: ReactElement[];
  visible: boolean;
  illustration?: ReactElement;
  maxWidth?: number;
  confetti?: boolean;
  destroyOnClose?: boolean;
  onClose?: () => void;
  buttonsWrapperStyle?: Record<string, any>;
  rightPanelStyle?: Record<string, any>;
}

const confettiConfig = {
  angle: 270,
  spread: 190,
  startVelocity: 40,
  elementCount: 200,
  dragFriction: 0.12,
  duration: 3000,
  stagger: 3,
  width: '10px',
  height: '10px',
  perspective: '800px',
};

const Modal = ({
  title,
  description,
  direction = 'horizontal',
  buttons,
  visible,
  illustration,
  tip,
  maxWidth = 380,
  confetti = false,
  destroyOnClose,
  onClose,
  buttonsWrapperStyle,
  rightPanelStyle,
}: ModalProps) => {
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    let timer: number;

    if (confetti && visible) {
      timer = window.setTimeout(() => {
        setShowConfetti(true);
      }, 1000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [setShowConfetti, confetti, visible]);

  return (
    <>
      <ModalWrapper>
        <ConfettiWrapper>
          <Confetti config={confettiConfig} active={showConfetti} />
        </ConfettiWrapper>
        <ModalCustom
          title={null}
          footer={null}
          closable={!!onClose}
          visible={visible}
          zIndex={1020}
          maskStyle={{ zIndex: 1000 }}
          illustration={!!illustration}
          centered
          $maxWidth={maxWidth}
          $direction={direction}
          destroyOnClose={destroyOnClose}
          closeIcon={<CloseButton />}
          onCancel={() => onClose?.()}
          maskClosable={false}
        >
          <ModalPanelWrapper data-testid="modal-panel-wrapper">
            <ModalLeftPanel illustration={!!illustration}>
              <ModalPanelTitle>{title}</ModalPanelTitle>
              <ModalPanelDescription>{description}</ModalPanelDescription>
              <ModalPanelTip>{tip}</ModalPanelTip>
              <ModalPanelActions>
                <ModalPanelButtonsWrapper style={buttonsWrapperStyle}>
                  {buttons}
                </ModalPanelButtonsWrapper>
              </ModalPanelActions>
            </ModalLeftPanel>
            {illustration && (
              <ModalRightPanel
                data-testid="modal-right-panel"
                style={rightPanelStyle}
              >
                {illustration}
              </ModalRightPanel>
            )}
          </ModalPanelWrapper>
        </ModalCustom>
      </ModalWrapper>
    </>
  );
};

export default Modal;
