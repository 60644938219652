import styled from 'styled-components';
import { Button } from 'antd';

import { colors } from '../../../theme';
import { BrandStatus } from '../types';

export const DeleteButton = styled(Button)`
  border: none;
  display: none;
  font-size: 12px;
  height: 24px;
  line-height: 2.08;
  margin: 0;
  padding: 0;
`;
export const StatusInfo = styled.div<{ status: BrandStatus }>`
  color: ${p => (p.status === 'pending' ? colors.orange : colors.green)};
`;

export const BrandViewTitle = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 7px;

  h4 {
    font-size: 14px;
    font-weight: normal;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
  }
`;

export const BrandUserDetail = styled.div`
  border-left: 2px solid ${colors.lightgrey2};
  font-size: 12px;
  line-height: 2.08;
  margin: 0 0 14px 34px;
  padding: 0 10px;

  &:hover {
    ${StatusInfo} {
      display: none;
    }

    ${DeleteButton} {
      display: initial;
    }
  }
`;
