import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ApiKeys,
  BillingConfigurations,
  PaymentMethod,
  AccountDetails,
  transformToApiKeys,
  transformToPaymentMethod,
  Invoice,
  VirtualCard,
  PricingTemplate,
  AccountProfile,
  ContentProviderInfo,
} from './account-model';
import { actionToError } from '../utils/transform';
import { CountryCode } from '../../types';

export interface AccountState {
  error?: any;
  loading: boolean;
  apiKeys?: ApiKeys;
  paymentMethods: {
    entities?: PaymentMethod[];
    adding: boolean;
    confirming: string[];
    retrying: string[];
    retrySuccess: boolean;
    deleting: boolean;
    loading: boolean;
    settingDefault: boolean;
  };
  invoices: {
    entities?: Invoice[];
    loading: boolean;
    last?: Invoice;
  };
  version?: any;
  details?: AccountDetails;
  billingConfigurations: {
    entity?: BillingConfigurations;
    loading: boolean;
    updating: boolean;
  };
  pricingTemplate: {
    entity?: PricingTemplate;
    loading: boolean;
  };
  virtualCards: {
    entities: VirtualCard[];
    adding: boolean;
    loading: boolean;
  };
  marketplace: {
    hasMarketplaceAccess: boolean;
    countryCodes: CountryCode[];
    suppliers: string[];
  };
  contentProviderInfo: ContentProviderInfo | undefined;
  images: {
    updating: boolean;
    entities: {
      squared: string | undefined;
      rounded: string | undefined;
    };
  };
  profile: {
    loading: boolean;
    updating: boolean;
    entity: AccountProfile;
  };
}

export const initialState: AccountState = {
  loading: false,
  billingConfigurations: {
    loading: false,
    updating: false,
  },
  paymentMethods: {
    adding: false,
    deleting: false,
    retrying: [],
    retrySuccess: false,
    confirming: [],
    loading: false,
    settingDefault: false,
  },
  invoices: {
    loading: false,
  },
  virtualCards: {
    entities: [],
    adding: false,
    loading: false,
  },
  pricingTemplate: {
    loading: false,
  },
  marketplace: {
    hasMarketplaceAccess: false,
    countryCodes: [],
    suppliers: [],
  },
  contentProviderInfo: undefined,
  images: {
    updating: false,
    entities: {
      squared: undefined,
      rounded: undefined,
    },
  },
  profile: {
    loading: false,
    updating: false,
    entity: {
      pitch: undefined,
      websiteUrl: undefined,
      androidAppUrl: undefined,
      iosAppUrl: undefined,
      audience: undefined,
      audienceSize: undefined,
      growthForecast: undefined,
    },
  },
};

type AccountPayload<T extends keyof AccountState> = PayloadAction<
  AccountState[T]
>;

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    clear: () => initialState,
    getAccount(state) {
      state.loading = true;
    },
    getAccountSuccess(state, action: AccountPayload<'details'>) {
      state.loading = false;
      state.details = action.payload;
    },
    getAccountError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },

    updateAccount(state) {
      state.loading = true;
    },
    updateAccountSuccess(state, action: AccountPayload<'details'>) {
      state.loading = false;
      state.details = action.payload;
    },
    updateAccountError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },

    toggleAccountLock(state) {
      state.loading = true;
    },
    toggleAccountLockSuccess(state) {
      state.loading = false;
    },
    toggleAccountLockError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },

    getVersion(state) {
      state.loading = true;
    },
    getVersionSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.version = action.payload;
    },
    getVersionError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },

    getApiKeys(state) {
      state.loading = true;
    },
    getApiKeysSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.apiKeys = transformToApiKeys(action.payload);
    },
    getApiKeysError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },

    sendGoLiveRequest(state) {
      state.loading = true;
      state.error = undefined;
    },
    sendGoLiveRequestSuccess(state, action: AccountPayload<'details'>) {
      state.loading = false;
      state.details = action.payload;
    },
    sendGoLiveRequestError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },

    updateAccountSecurity(state) {
      state.loading = true;
      state.error = undefined;
    },
    updateAccountSecuritySuccess(state, action: AccountPayload<'details'>) {
      state.loading = false;
      state.details = action.payload;
    },
    updateAccountSecurityError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },

    getInvoices(state) {
      state.invoices.loading = true;
      state.paymentMethods.retrySuccess = false;
    },
    getInvoicesSuccess(state, action: PayloadAction<any>) {
      state.invoices.loading = false;
      state.invoices.entities = action.payload.items;
    },
    getInvoicesError(state, action: PayloadAction<any>) {
      state.invoices.loading = false;
      state.error = actionToError(action);
    },

    getPaymentMethods(state) {
      state.paymentMethods.loading = true;
    },
    getPaymentMethodsSuccess(state, action: PayloadAction<any>) {
      state.paymentMethods.loading = false;
      state.paymentMethods.entities = action.payload.items.map(
        transformToPaymentMethod,
      );
    },
    getPaymentMethodsError(state) {
      state.paymentMethods.loading = false;
    },

    addPaymentMethod(state) {
      state.paymentMethods.adding = true;
    },
    addPaymentMethodSuccess(state) {
      state.paymentMethods.adding = false;
    },
    addPaymentMethodError(state) {
      state.paymentMethods.adding = false;
    },

    deletePaymentMethod(state) {
      state.paymentMethods.deleting = true;
    },
    deletePaymentMethodSuccess(state) {
      state.paymentMethods.deleting = false;
    },
    deletePaymentMethodError(state) {
      state.paymentMethods.deleting = false;
    },

    setDefaultPaymentMethod(state) {
      state.paymentMethods.settingDefault = true;
    },
    setDefaultPaymentMethodSuccess(state) {
      state.paymentMethods.settingDefault = false;
    },
    setDefaultPaymentMethodError(state) {
      state.paymentMethods.settingDefault = false;
    },

    updateApiVersion(state) {
      state.loading = true;
    },
    updateApiVersionSuccess(state, action: AccountPayload<'details'>) {
      state.loading = false;
      state.details = action.payload;
    },
    updateApiVersionError(state) {
      state.loading = false;
    },

    confirmPayment(state, action: PayloadAction<string>) {
      state.paymentMethods.confirming.push(action.payload);
    },
    confirmPaymentSuccess(state, action: PayloadAction<Invoice['id']>) {
      state.paymentMethods.confirming = state.paymentMethods.confirming.filter(
        invoiceId => invoiceId !== action.payload,
      );
    },
    confirmPaymentError(state, action: PayloadAction<Invoice['id']>) {
      state.paymentMethods.confirming = state.paymentMethods.confirming.filter(
        invoiceId => invoiceId !== action.payload,
      );
    },

    retryPayment(state, action: PayloadAction<string>) {
      state.paymentMethods.retrySuccess = false;
      state.paymentMethods.loading = true;
      state.paymentMethods.retrying.push(action.payload);
    },
    retryPaymentSuccess(state, action: PayloadAction<Invoice['id']>) {
      state.paymentMethods.loading = false;
      state.paymentMethods.retrySuccess = true;
      state.paymentMethods.retrying = state.paymentMethods.retrying.filter(
        invoiceId => invoiceId !== action.payload,
      );
    },
    retryPaymentError(state, action: PayloadAction<Invoice['id']>) {
      state.paymentMethods.loading = false;
      state.paymentMethods.retrying = state.paymentMethods.retrying.filter(
        invoiceId => invoiceId !== action.payload,
      );
    },

    getLastInvoice(state) {
      state.invoices.loading = true;
    },
    getLastInvoiceSuccess(state, action: PayloadAction<Invoice>) {
      state.invoices.loading = false;
      state.invoices.last = action.payload;
    },
    getLastInvoiceError(state, action: PayloadAction<any>) {
      state.invoices.loading = false;
      state.error = actionToError(action);
    },

    getBillingConfigurations(state) {
      state.billingConfigurations.loading = true;
    },
    getBillingConfigurationsSuccess(
      state,
      action: PayloadAction<BillingConfigurations>,
    ) {
      state.billingConfigurations.loading = false;
      state.billingConfigurations.entity = action.payload;
    },
    getBillingConfigurationsError(state, action: PayloadAction<any>) {
      state.billingConfigurations.loading = false;
      state.error = actionToError(action);
    },

    updateBillingConfigurations(state) {
      state.error = undefined;
      state.billingConfigurations.updating = true;
    },
    updateBillingConfigurationsSuccess(
      state,
      action: PayloadAction<BillingConfigurations>,
    ) {
      state.billingConfigurations.updating = false;
      state.billingConfigurations.entity = action.payload;
    },
    updateBillingConfigurationsError(state, action: PayloadAction<any>) {
      state.billingConfigurations.updating = false;
      state.error = actionToError(action);
    },

    getPricingTemplate(state) {
      state.pricingTemplate.loading = true;
      state.error = undefined;
    },
    getPricingTemplateSuccess(state, action: PayloadAction<PricingTemplate>) {
      state.pricingTemplate.loading = false;
      state.pricingTemplate.entity = action.payload;
    },
    getPricingTemplateError(state, action: PayloadAction<any>) {
      state.pricingTemplate.loading = false;
      state.error = actionToError(action);
    },

    getVirtualCards(state) {
      state.virtualCards.loading = true;
    },
    getVirtualCardsSuccess(state, action: PayloadAction<VirtualCard[]>) {
      state.virtualCards.loading = false;
      state.virtualCards.entities = action.payload ?? [];
    },
    getVirtualCardsError(state, action: PayloadAction<any>) {
      state.virtualCards.loading = false;
      state.error = actionToError(action);
    },

    addVirtualCard(state) {
      state.virtualCards.adding = true;
    },
    addVirtualCardSuccess(state, action: PayloadAction<VirtualCard[]>) {
      state.virtualCards.adding = false;
      state.virtualCards.entities = action.payload;
    },
    addVirtualCardError(state, action: PayloadAction<any>) {
      state.virtualCards.adding = false;
      state.error = action.payload;
    },

    checkMarketplaceAccess(state) {
      state.loading = true;
    },
    checkMarketplaceAccessSuccess(
      state,
      action: AccountPayload<'marketplace'>,
    ) {
      state.loading = false;
      state.marketplace = { ...state.marketplace, ...action.payload };
    },
    checkMarketplaceAccessError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },

    checkIsContentProvider(state) {
      state.loading = true;
    },
    checkIsContentProviderSuccess(
      state,
      action: AccountPayload<'contentProviderInfo'>,
    ) {
      state.loading = false;
      state.contentProviderInfo = action.payload || undefined;
    },
    checkIsContentProviderError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },

    updateImages(state) {
      state.images.updating = true;
    },
    updateImagesSuccess(state, action: AccountPayload<'details'>) {
      state.images.updating = false;
      state.images.entities = {
        squared: action.payload?.imagePath,
        rounded: action.payload?.croppedImagePath,
      };
    },
    updateImagesError(state, action: PayloadAction<any>) {
      state.images.updating = false;
      state.error = action.payload;
    },

    getProfile(state) {
      state.profile.loading = true;
    },
    getProfileSuccess(state, action: PayloadAction<AccountProfile>) {
      state.profile.loading = false;
      state.profile.entity = { ...state.profile.entity, ...action.payload };
    },
    getProfileError(state, action: PayloadAction<any>) {
      state.profile.loading = false;
      state.error = action.payload;
    },

    updateProfile(state) {
      state.profile.updating = true;
    },
    updateProfileSuccess(state, action: PayloadAction<AccountProfile>) {
      state.profile.updating = false;
      state.profile.entity = { ...state.profile.entity, ...action.payload };
    },
    updateProfileError(state, action: PayloadAction<any>) {
      state.profile.updating = false;
      state.error = action.payload;
    },
  },
});

export const { actions } = accountSlice;
export default accountSlice.reducer;
