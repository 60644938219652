import { Typography } from 'antd';
import styled from 'styled-components';
import InfiniteScrollTable from '../../../../components/tables/InfiniteScrollTable';
import { colors } from '../../../../theme';

export const ManageLocationsBoxContainer = styled.div`
  border: 2px solid ${colors.grey2};
  border-radius: 4px;
  padding: 8px;
`;

export const ManageButtonStyled = styled.div`
  display: flex;
  justify-content: center;
  padding: 22px 0 14px 0;

  button {
    font-weight: 500;

    &:focus span {
      display: inline;
      box-shadow: 0 0 0 2px ${colors.fidelBlue600};
    }
  }
`;

export const InfoMessage = styled.div`
  background: linear-gradient(0deg, #ebf2ff, #ebf2ff), #f4f5f7;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 12px;
  font-size: 14px;
  color: ${colors.grey5};

  & > span:nth-child(1) {
    font-size: 20px;
    margin-right: 12px;
  }
`;

export const Label = styled(Typography.Text)`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.grey4};
`;

export const InfiniteScrollTableStyled = styled(InfiniteScrollTable)`
  > :last-child {
    position: inherit;
  }
`;
