import styled from 'styled-components';
import { Alert } from 'antd';

import { colors } from '../../../theme';

const infoStatusMixin = (type: string | undefined) =>
  type === 'info'
    ? `background: #020288;
      .ant-alert-message {
        color: ${colors.white};
        text-align: center;
      }`
    : ``;

export const StyledAlert = styled(Alert)`
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  ${({ type }) => infoStatusMixin(type)};

  .ant-btn-link {
    padding: 0;
    color: #fb9689;
    height: auto;
    :hover {
      background: none;
      color: #ffa89d;
    }
  }
`;
