import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RequiredFormItemController } from '../../../../../components/form/styled';
import { Select } from '../../../../../components/select';
import { Program } from '../../../../../store/programs/programs-reducer';
import { selectProgramsAsList } from '../../../../../store/programs/programs-selectors';
import { useAppSelector } from '../../../../../store/hooks';

interface SelectProgramProps {
  program?: Program;
  defaultProgram?: string;
}

const SelectProgram = ({ program, defaultProgram }: SelectProgramProps) => {
  const { t } = useTranslation('brands', { keyPrefix: 'createBrand' });
  const programs = useAppSelector(selectProgramsAsList)('transaction-select');
  const distroOnlyPrograms = useAppSelector(selectProgramsAsList)(
    'transaction-distro-only',
  );
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const programOptions = useMemo(
    () =>
      (program ? [program] : programs.concat(distroOnlyPrograms)).map(
        ({ id, name }) => ({
          label: name,
          value: id,
        }),
      ),
    [program, programs, distroOnlyPrograms],
  );

  function getDefaultProgram() {
    if (defaultProgram) return defaultProgram;

    return programOptions.length === 1 ? programOptions[0].value : undefined;
  }

  return (
    <RequiredFormItemController
      label={t('label.program')}
      errors={errors}
      validateCharacters
      controller={{
        name: 'programId',
        render: ({ field }) => (
          <Select
            {...field}
            placeholder={t('placeholder.program')}
            getPopupContainer={element => element.parentElement}
          >
            {programOptions.map(({ label, value }) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        ),
        control,
        rules: { required: true },
        defaultValue: getDefaultProgram(),
      }}
    />
  );
};

export default SelectProgram;
