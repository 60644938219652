import styled from 'styled-components';
import { Row } from 'antd';

import { colors, fontSize } from '../../../theme';
import { ReactComponent as Tick } from '../assets/tick.svg';

export const GreenTick = styled(Tick)`
  fill: ${colors.green};
  width: 20px;
`;

export const Label = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: ${fontSize.small};

  span {
    display: inline-block;
    margin-left: 8px;
  }
`;

export const SchemeCard = styled(Row)`
  background: ${colors.lightgrey};
  color: ${colors.grey5};
  padding: 15px 20px;
  border: 1px solid ${colors.grey2};
  .ant-col {
    display: flex;
    align-items: center;
    line-height: 0;
  }

  :first-of-type {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: none;
  }

  :last-of-type {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: none;
  }
`;

export const SchemeName = styled.span`
  display: inline-block;
  margin-left: 12px;
  color: ${colors.black};
`;
