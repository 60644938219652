import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import DrawerForm from '../../../../components/drawer-form';
import ExpandingCaption from '../../../../components/expanding-caption';
import { submitVirtualCardTransaction } from '../../../../store/virtual-card/virtual-card-actions';
import LocationSummary from './LocationSummary';
import VirtualCardTransactions from './VirtualCardTransactions';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

interface LinkVirtualCardTransactionProps {
  visible: boolean;
  location: any;
  onClose: () => unknown;
}

const LinkVirtualCardTransaction = ({
  onClose,
  location,
  visible,
}: LinkVirtualCardTransactionProps) => {
  const dispatch = useAppDispatch();
  const [selectedTransactionId, setSelectedTransactionId] = useState<string>();
  const { t } = useTranslation('locations', {
    keyPrefix: 'linkVirtualCardTransaction',
  });

  const { submitting } = useAppSelector(
    state => ({
      submitting: state.virtualCard.submittingTransaction,
      error: state.virtualCard.error,
    }),
    shallowEqual,
  );
  return (
    <DrawerForm
      title={t('title')}
      processing={submitting}
      onClose={onClose}
      onSubmit={() => {
        dispatch(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          submitVirtualCardTransaction(location.id, selectedTransactionId!),
        );
      }}
      visible={visible}
      submitDisabled={!selectedTransactionId}
    >
      <ExpandingCaption
        ns="locations"
        i18nKey="linkVirtualCardTransaction.description"
      />
      <LocationSummary location={location} />
      <VirtualCardTransactions
        selectedTransactionId={selectedTransactionId}
        onSelect={setSelectedTransactionId}
      />
    </DrawerForm>
  );
};

export default LinkVirtualCardTransaction;
