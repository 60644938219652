import { Brand } from '../../../store/brands/brands-model';
import { BrandsContextProps } from '../context/brands-context';

export function getBrandLogoUrl(
  b: Brand,
  editedBrandsIds: BrandsContextProps['editedBrandsIds'],
) {
  return b.id && editedBrandsIds[b.id]
    ? `${b.logoURL}?id=${editedBrandsIds[b.id]}`
    : b.logoURL;
}
