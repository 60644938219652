import 'intersection-observer';
import './antd.css';
import React from 'react';
import ReactDOM from 'react-dom';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import { Spin } from 'antd';
import { Provider } from 'react-redux';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { store } from './store';
import { bootstrapAuth } from './auth';
import { SelectedProgramProvider } from './hooks/use-selected-program';
import { RecentProgramsProvider } from './hooks/use-recent-programs';
import { SignedInContextProvider } from './hooks/use-is-signed-in';
import App from './app';
import './i18n';
import { reCaptcha } from './config';

if (process.env.NODE_ENV === 'development') {
  require('./why-did-you-render');
}

bootstrapAuth();

Spin.setDefaultIndicator(<LoadingOutlined style={{ fontSize: 24 }} spin />);

ReactDOM.render(
  <Provider store={store}>
    <GoogleReCaptchaProvider
      reCaptchaKey={reCaptcha.SITE_KEY}
      scriptProps={{
        async: false,
        defer: false,
        appendTo: 'head',
        nonce: undefined,
      }}
    >
      <SignedInContextProvider>
        <SelectedProgramProvider>
          <RecentProgramsProvider>
            <App />
          </RecentProgramsProvider>
        </SelectedProgramProvider>
      </SignedInContextProvider>
    </GoogleReCaptchaProvider>
  </Provider>,
  document.getElementById('root'),
);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready.then(registration => {
    registration.unregister();
  });
}
