import React from 'react';
import { Col, Row } from 'antd';
import { CardContainer } from '../../../../../components/tables/styled/helpers';
import { BrandName, BrandWebsite } from '../styled/search-brand-card';
import { UniqueBrand } from '../../../../../store/brands/brands-model';
import Avatar from '../../../../../components/avatar';
import Ellipsis from '../../../../../components/ellipsis';

const SearchBrandCard = ({ logoURL, name, websiteURL }: UniqueBrand) => (
  <CardContainer role="button" data-testid="search-brand-card-container">
    <Row align="middle" style={{ flexWrap: 'nowrap' }}>
      <Col style={{ marginRight: '14px' }}>
        <Avatar src={logoURL} color={{ border: 'lightgrey2' }}>
          {name}
        </Avatar>
      </Col>
      <Col
        style={{
          width: '100%',
          display: 'flex',
          flexFlow: 'row',
          alignItems: 'center',
        }}
      >
        <BrandName>{name}</BrandName>
        {websiteURL && (
          <Ellipsis>
            <BrandWebsite>{websiteURL}</BrandWebsite>
          </Ellipsis>
        )}
      </Col>
    </Row>
  </CardContainer>
);

export default SearchBrandCard;
