import React, { useEffect } from 'react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Input, Tag, Tooltip } from 'antd';

import {
  getBillingConfigurations,
  getInvoices,
  updateBillingConfigurations,
  updateAccount,
} from '../../../../store/account/account-actions';
import {
  AccountDetails,
  Invoice,
} from '../../../../store/account/account-model';
import BaseTable from '../../../../components/tables/BaseTable';
import InlineForm from '../../../../components/inline-form';
import { AmountCell } from '../../../../components/tables/styled/helpers';
import Spin from '../../../../components/spin';
import { Subsection, SubsectionTitle } from '../../styled';
import { stripeWrapper } from '../../../../components/stripe';
import memoNoProps from '../../../../utils/memo-no-props';
import { isVatCountry } from '../../../../utils/countries';
import { toDecimal } from '../../../../utils/transform';
import { invoiceEmailsController } from '../../../../components/form/components/invoice-emails';
import PaymentMethods from './PaymentMethods';
import Download from './Download';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

const dateFormat = 'MMM D, YYYY';

export function getAmount(
  details: AccountDetails | undefined,
  invoice: Invoice,
) {
  const { countryCode, vatNumber } = details || {};
  const { amount, amountVAT } = invoice;

  return toDecimal(isVatCountry(countryCode, vatNumber) ? amountVAT : amount);
}

interface BillingInformationForm {
  companyName: string;
  legalName: string;
  invoiceEmails: string[];
  poNumber?: string;
}

const Billing = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const {
    accountDetails,
    billingConfigurations,
    loadingBillingConfigurations,
    updatingBillingConfigurations,
    invoices,
    loading,
    error,
  } = useAppSelector(
    state => ({
      accountDetails: state.account.details,
      billingConfigurations: state.account.billingConfigurations.entity,
      loadingBillingConfigurations: state.account.billingConfigurations.loading,
      updatingBillingConfigurations:
        state.account.billingConfigurations.updating,
      confirmingPayment: state.account.paymentMethods.confirming,
      invoices: state.account.invoices.entities,
      loading: state.account.loading,
      error: state.account.error,
    }),
    shallowEqual,
  );

  const { liveActive, liveAgreement } = accountDetails || {};

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<BillingInformationForm>({
    mode: 'onBlur',
    defaultValues: {
      companyName: accountDetails?.name,
      legalName: accountDetails?.legalName,
      poNumber: accountDetails?.poNumber,
      invoiceEmails: billingConfigurations?.invoiceEmails,
    },
  });

  useEffect(() => {
    if (!invoices) dispatch(getInvoices());

    if (!billingConfigurations && liveAgreement)
      dispatch(getBillingConfigurations());
  }, [dispatch, billingConfigurations, liveAgreement, invoices]);

  useEffect(() => {
    reset(
      { invoiceEmails: billingConfigurations?.invoiceEmails },
      { keepDefaultValues: true },
    );
  }, [billingConfigurations, reset]);

  const { paymentMethodType } = billingConfigurations || {};

  const isStripe = paymentMethodType === 'stripe';

  const chargedInvoices = (invoices || []).filter(
    invoice => invoice.status === 'completed' && invoice.charged,
  );

  function onSubmit({
    companyName,
    legalName,
    invoiceEmails,
    poNumber,
  }: BillingInformationForm) {
    dispatch(
      updateAccount({
        name: companyName,
        legalName,
        poNumber,
      }),
    );
    dispatch(
      updateBillingConfigurations({
        invoiceEmails,
      }),
    );
  }

  const invoiceToRow = (invoice: Invoice) => {
    const { charged, issuedDate, amount, amountVAT, currency, status } =
      invoice;
    const decimalAmount = getAmount(accountDetails, {
      amount,
      amountVAT,
    } as Invoice);

    const invoiceTag = `account.billingHistory.table.invoiceTag.${
      status === 'open' ? 'open' : charged ? 'charged' : 'pending'
    }`;

    const contents = [
      moment(issuedDate).format(dateFormat),
      status === 'completed' && <Download {...invoice} />,
      <AmountCell>
        <span>{currency.toUpperCase()}</span> <span>{decimalAmount}</span>
      </AmountCell>,
      isStripe && (
        // TODO Fix 'any' belows on OFF-1406
        <Tooltip
          title={t(`${invoiceTag}.description` as any)}
          placement="topRight"
        >
          <Tag
            color={status === 'open' ? 'default' : charged ? 'green' : 'orange'}
          >
            {t(`${invoiceTag}.title` as any)}
          </Tag>
        </Tooltip>
      ),
    ];

    return { contents };
  };

  return (
    <>
      {liveAgreement && (
        <PaymentMethods paymentMethodType={paymentMethodType} />
      )}
      <Subsection>
        <SubsectionTitle>
          <h2>{t('account.billingPeriod.title')}</h2>
          <p>{t('account.billingPeriod.description')}</p>
        </SubsectionTitle>
      </Subsection>
      {liveActive && (
        <Subsection>
          <SubsectionTitle>
            <h2>{t('account.billingInfo.title')}</h2>
          </SubsectionTitle>
          <p>{t('account.billingInfo.description')}</p>
          {loadingBillingConfigurations ? (
            <Spin />
          ) : (
            <InlineForm
              onSubmit={handleSubmit(onSubmit)}
              loading={updatingBillingConfigurations}
              error={error}
            >
              <InlineForm.ItemController
                label={t('account.billingInfo.companyName')}
                errors={errors}
                controller={{
                  name: 'companyName',
                  render: ({ field }) => <Input {...field} />,
                  rules: { minLength: 2, maxLength: 100, required: true },
                  control,
                }}
              />
              <InlineForm.ItemController
                label={t('account.billingInfo.legalName')}
                errors={errors}
                controller={{
                  name: 'legalName',
                  render: ({ field }) => <Input {...field} />,
                  rules: { minLength: 2, maxLength: 100, required: true },
                  control,
                }}
              />
              <InlineForm.ItemController
                label={t('account.billingInfo.invoiceEmails')}
                errors={errors}
                controller={invoiceEmailsController({
                  control,
                  defaultValue: billingConfigurations?.invoiceEmails,
                })}
              />
              <InlineForm.ItemController
                label={t('account.billingInfo.poNumber')}
                errors={errors}
                controller={{
                  name: 'poNumber',
                  render: ({ field }) => <Input {...field} />,
                  rules: { minLength: 2, maxLength: 100, required: false },
                  control,
                }}
              />
            </InlineForm>
          )}
        </Subsection>
      )}
      {invoices && (
        <>
          {paymentMethodType === 'stripe' && (
            <Subsection>
              <SubsectionTitle>
                <h2>{t('account.lastPayment.title')}</h2>
                {chargedInvoices.length ? (
                  <p>
                    {moment(chargedInvoices[0]?.chargeDate).format(dateFormat)}
                  </p>
                ) : (
                  <p>
                    {t(
                      `account.lastPayment.subtitle.${
                        invoices.length ? 'noPaidInvoices' : 'noInvoices'
                      }`,
                    )}
                  </p>
                )}
              </SubsectionTitle>
            </Subsection>
          )}
          <Subsection>
            <SubsectionTitle>
              <h2>{t('account.billingHistory.title')}</h2>
            </SubsectionTitle>
            <p>{t('account.billingHistory.subtitle')}</p>
            <BaseTable
              bordered
              minWidth={600}
              columns={[
                { heading: t('account.billingHistory.table.date'), size: 0.7 },
                { heading: '', size: 0.3 },
                {
                  heading: t('account.billingHistory.table.amount'),
                  size: 0.3,
                  align: isStripe ? 'left' : 'right',
                },
                {
                  heading: '',
                  size: isStripe ? 0.2 : 0,
                  align: 'right',
                },
              ]}
              rows={invoices.map((invoice: Invoice) => invoiceToRow(invoice))}
              loading={loading}
              emptyText={t('account.lastPayment.subtitle.noInvoices')}
            />
          </Subsection>
        </>
      )}
    </>
  );
};

export default memoNoProps(stripeWrapper(Billing));
