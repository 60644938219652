import React, { useState } from 'react';
import { Button, Drawer, Row, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { difference } from 'lodash';

import BaseTable from '../../../../../components/tables/BaseTable';
import { Offer } from '../../../../../store/offers/offers-model';
import StatusTag, { Status } from '../../../components/StatusTag';
import { selectCountryProgramsByType } from '../../../../../store/programs/programs-selectors';
import ProgramStatusDescriptions from './ProgramStatusDescriptions';
import { useAppSelector } from '../../../../../store/hooks';

interface AvailableProgramsDrawerProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  uniqueOffer: Offer;
}

function AvailableProgramsDrawer({
  visible,
  setVisible,
  uniqueOffer,
}: AvailableProgramsDrawerProps) {
  const { countryCode, programsLinkStatus } = uniqueOffer;

  const { t } = useTranslation(['common', 'offers']);
  const [showStatusDescriptions, setShowStatusDescriptions] = useState(false);
  const offersPrograms = useAppSelector(selectCountryProgramsByType)(
    countryCode,
  );
  const addedPrograms = offersPrograms.filter(program =>
    programsLinkStatus?.some(({ id }) => id === program.id),
  );
  const availablePrograms = difference(offersPrograms, addedPrograms);

  return (
    <>
      <Drawer
        title={
          <Row justify="space-between" align="middle">
            {t('offers:fields.programs.label')}
            <Typography.Text
              type="secondary"
              style={{ cursor: 'pointer' }}
              data-testid="open-descriptions"
              onClick={() => setShowStatusDescriptions(true)}
            >
              <InfoCircleOutlined /> {t('common:learnMore')}
            </Typography.Text>
          </Row>
        }
        visible={visible}
        closeIcon={null}
        onClose={() => setVisible(false)}
        width={440}
        footer={
          <Button type="primary" size="small" onClick={() => setVisible(false)}>
            {t('common:confirm.done')}
          </Button>
        }
      >
        <BaseTable
          columns={[
            { heading: t('offers:fields.programs.label') },
            {
              heading: t('offers:fields.status.label'),
              size: 0.75,
              align: 'right',
            },
          ]}
          rows={[...addedPrograms, ...availablePrograms].map(({ id, name }) => {
            const status: Status =
              programsLinkStatus?.find(program => program.id === id)?.status ||
              'available';

            return {
              contents: [
                <div style={{ padding: '12px 0' }}>{name}</div>,
                status ? <StatusTag status={status} /> : '',
              ],
            };
          })}
          hideScrollIndicators
          padding={false}
        />
      </Drawer>
      <ProgramStatusDescriptions
        visible={showStatusDescriptions}
        onClose={() => setShowStatusDescriptions(false)}
      />
    </>
  );
}

export default AvailableProgramsDrawer;
