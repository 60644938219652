import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

import regex from '../../utils/regex';
import { Select } from '../../components/select';
import { GoLiveFormProps } from '.';
import { Title } from './styled';
import { GoLiveModel } from './use-go-live-form-state';
import { RequiredFormItemController } from '../../components/form/styled';
import SubForm from './SubForm';

const monthlyActiveUsersRange = [
  '0 - 100,000',
  '100,000 - 500,000',
  '500,000 - 1,500,000',
  '1,500,000+',
];

const monthlyTransactionsRange = [
  '0 - 25,000 pm',
  '25,001 - 100,00 pm',
  '100,001 - 500,000 pm',
  '500,001 - 1,000,000 pm',
  '1,000,000+ pm',
];

const cardsOnFileRange = [
  '0 - 100,000',
  '100,000 - 500,000',
  '500,000 - 1,500,000',
  '1,500,000+',
];

const BusinessForm = ({ onClick }: GoLiveFormProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<GoLiveModel>();

  const { t } = useTranslation('common', {
    keyPrefix: 'account.goLive.steps.business',
  });

  return (
    <SubForm onClick={onClick}>
      <Title>{t('title')}</Title>
      <RequiredFormItemController
        label={t('fields.website')}
        errors={errors}
        controller={{
          name: 'website',
          render: ({ field }) => (
            <Input {...field} placeholder={t('websitePlaceholder')} />
          ),
          rules: {
            required: true,
            validate: { invalidUrl: value => regex.httpsUrl.test(value) },
          },
          control,
        }}
      />
      <RequiredFormItemController
        label={t('fields.appName')}
        errors={errors}
        validateCharacters
        controller={{
          name: 'appName',
          render: ({ field }) => <Input {...field} />,
          rules: { required: true, minLength: 4, maxLength: 100 },
          control,
        }}
      />
      <RequiredFormItemController
        label={t('fields.monthlyActiveUsersRange')}
        errors={errors}
        controller={{
          name: 'monthlyActiveUsersRange',
          render: ({ field }) => (
            <Select {...field} placeholder={t('selectPlaceholder')}>
              {monthlyActiveUsersRange.map(value => (
                <Select.Option key={value} value={value}>
                  {value}
                </Select.Option>
              ))}
            </Select>
          ),
          rules: { required: true },
          control,
        }}
      />
      <RequiredFormItemController
        label={t('fields.monthlyTransactionsRange')}
        errors={errors}
        controller={{
          name: 'monthlyTransactionsRange',
          render: ({ field }) => (
            <Select {...field} placeholder={t('selectPlaceholder')}>
              {monthlyTransactionsRange.map(value => (
                <Select.Option key={value} value={value}>
                  {value}
                </Select.Option>
              ))}
            </Select>
          ),
          rules: { required: true },
          control,
        }}
      />
      <RequiredFormItemController
        label={t('fields.cardsOnFileRange')}
        errors={errors}
        controller={{
          name: 'cardsOnFileRange',
          render: ({ field }) => (
            <Select {...field} placeholder={t('selectPlaceholder')}>
              {cardsOnFileRange.map(value => (
                <Select.Option key={value} value={value}>
                  {value}
                </Select.Option>
              ))}
            </Select>
          ),
          rules: { required: true },
          control,
        }}
      />
    </SubForm>
  );
};

export default BusinessForm;
