import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoWrapper } from './styled/locations-info';
import { ReactComponent as LocationMarker } from '../../../../../assets/location_marker.svg';
import { LinkButton } from '../../../../../components/buttons';
import { BaseSpin } from '../../../../../components/spin';

interface LocationsInfoProps {
  total: number;
  added: number;
  callback: (event: React.MouseEvent) => void;
  loading: boolean;
}

export const LocationsInfo = ({
  total,
  added,
  callback,
  loading,
}: LocationsInfoProps) => {
  const { t } = useTranslation('locations');

  return (
    <InfoWrapper>
      {!loading ? (
        <>
          <LocationMarker />
          <div>
            <span>{`${added}/${total} |`}</span>
            <LinkButton style={{ marginLeft: '6px' }} onClick={callback}>
              {t('addNewLocations')}
            </LinkButton>
          </div>
        </>
      ) : (
        <BaseSpin data-testid="locations-info-loading" />
      )}
    </InfoWrapper>
  );
};
