import React, { useEffect, useState } from 'react';

export const indicatorsToShow = (scrollLeft: number, maxScrollLeft: number) => [
  scrollLeft > 0,
  scrollLeft < maxScrollLeft,
];

const useShowIndicators = (ref: React.RefObject<HTMLDivElement>) => {
  const { current } = ref || {};
  const [showIndicators, setShowIndicators] = useState([false, true]);

  const [showLeftIndicator, showRightIndicator] = showIndicators;

  const maxScrollLeft =
    (current?.scrollWidth || 0) - (current?.clientWidth || 0);

  const onScroll = () => {
    const [newLeft, newRight] = indicatorsToShow(
      current?.scrollLeft ?? 0,
      maxScrollLeft,
    );

    if (newLeft !== showLeftIndicator) {
      setShowIndicators([newLeft, showRightIndicator]);
    }

    if (newRight !== showRightIndicator) {
      setShowIndicators([showLeftIndicator, newRight]);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', onScroll);

    return () => window.removeEventListener('resize', onScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxScrollLeft]);

  return { showIndicators, onScroll };
};

export default useShowIndicators;
