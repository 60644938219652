import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from 'antd';

import { Left, NewLetter, NoCardContainer, Right } from './styled';
import CreateVirtualCard from './CreateVirtualCard';
import ExpandingCaption from '../../../../components/expanding-caption';

const NoCard = () => {
  const { t } = useTranslation('common', { keyPrefix: 'account.virtualCard' });
  const [showCreateVirtualCard, setShowCreateVirtual] = useState(false);

  return (
    <>
      <NoCardContainer>
        <Left>
          <div>
            {t('noCard.new')
              .split('')
              .map((letter, idx) => (
                <NewLetter key={idx}>{letter}</NewLetter>
              ))}
          </div>
          <h2>{t('noCard.title')}</h2>
          <Typography.Paragraph>{t('noCard.description')}</Typography.Paragraph>
          <Button
            type="primary"
            size="small"
            onClick={() => setShowCreateVirtual(true)}
          >
            {t('noCard.cta')}
          </Button>
        </Left>
        <Right />
      </NoCardContainer>
      <ExpandingCaption i18nKey="account.virtualCard.caption" />
      <CreateVirtualCard
        visible={showCreateVirtualCard}
        onClose={() => setShowCreateVirtual(false)}
      />
    </>
  );
};

export default NoCard;
