import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Divider, Typography } from 'antd';

import { SchemeStatus } from '../../../store/locations/locations-model';
import { syncProgram } from '../../../store/programs/programs-actions';
import DrawerForm from '../../../components/drawer-form';
import BaseTable from '../../../components/tables/BaseTable';
import drawerTransition, {
  DrawerVisibleProps,
} from '../../../components/drawer-transition';
import CardSchemeStatusTag, { RealTimeType } from './StatusTag';
import {
  StatusDescription,
  Description,
  DontShowAgainContainer,
} from '../styled/sync-locations';
import useShowSyncHelpState from '../show-sync-help';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

type EntryType = {
  status: SchemeStatus;
  realTime?: RealTimeType;
  description?: 'activeTested' | 'realTimeActiveTested';
  days?: number;
};

interface SyncLocationsProps extends DrawerVisibleProps {
  programId: string;
  onClose: () => unknown;
}

export const SyncLocationsTable = React.memo(() => {
  const { t } = useTranslation('locations');

  const tableEntries: EntryType[] = [
    {
      status: 'inactive',
    },
    {
      status: 'sync',
      days: 3,
    },
    {
      status: 'active',
    },
    {
      status: 'active',
      realTime: RealTimeType.ACTIVE_TESTED,
      description: 'activeTested',
    },
    {
      status: 'active',
      realTime: RealTimeType.REAL_TIME_ACTIVE_TESTED,
      description: 'realTimeActiveTested',
    },
    {
      status: 'failed',
    },
  ];

  const rows = tableEntries.map(({ status, realTime, description, days }) => ({
    contents: [
      <CardSchemeStatusTag
        status={status}
        realTime={realTime}
        days={days}
        style={{ marginTop: '14px' }}
      />,
      <StatusDescription>
        {description
          ? t(`status.${description}.description`)
          : t(`status.${status}.description`)}
      </StatusDescription>,
    ],
  }));

  return (
    <BaseTable
      columns={[
        {
          heading: t('syncLocations.table.status'),
          verticalAlign: 'flex-start',
        },
        {
          heading: t('syncLocations.table.description'),
          size: 2,
        },
      ]}
      rows={rows}
      padding={false}
      hideScrollIndicators
      stretch
    />
  );
});

const SyncLocations = ({
  programId,
  onClose,
  visible,
  afterVisibleChange,
}: SyncLocationsProps) => {
  const { t } = useTranslation('locations');
  const dispatch = useAppDispatch();
  const processing = useAppSelector(state => state.programs.syncing);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [, setShowSyncHelp] = useShowSyncHelpState();

  const onSubmit = () => {
    dispatch(syncProgram(programId));
    setShowSyncHelp(!dontShowAgain);
  };

  return (
    <DrawerForm
      title={t('syncLocations.title')}
      confirmText={t('syncLocations.form.confirm')}
      onClose={onClose}
      onSubmit={onSubmit}
      processing={processing}
      visible={visible}
      afterVisibleChange={afterVisibleChange}
    >
      <Description>{t('syncLocations.form.description')}</Description>
      <SyncLocationsTable />
      <DontShowAgainContainer>
        <Divider />
        <Checkbox
          data-testid="dont-show-checkbox"
          checked={dontShowAgain}
          onChange={e => {
            setDontShowAgain(e.target.checked);
          }}
        >
          <Typography.Text type="secondary">
            {t('syncLocations.table.dontShowAgain')}
          </Typography.Text>
        </Checkbox>
      </DontShowAgainContainer>
    </DrawerForm>
  );
};

export default drawerTransition(SyncLocations);
