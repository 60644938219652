import moment from 'moment';

import { api } from '../../config';
import { getAllItems, getCount } from '../utils/pagination';
import http from '../../services/http-service';
import { Card, CardsOptions } from './cards-types';
import { filterGetByIdCall } from '../utils/transform';

export default class CardsApiService {
  private buildParams(params: any, { last, from, to, limit }: CardsOptions) {
    if (from && to) {
      params.from = from;
      params.to = to;
    }
    if (last) params.start = JSON.stringify(last);
    if (limit) params.limit = limit;
  }

  public async getCards({
    programId,
    last,
    from,
    to,
    limit,
    id,
  }: CardsOptions) {
    if (id) {
      return filterGetByIdCall(id, programId, this.getCard, [
        (card: Card) =>
          from && to
            ? moment(card.created).isSameOrAfter(from) &&
              moment(card.created).isSameOrBefore(to)
            : true,
      ]);
    }

    const url = `programs/${programId}/cards`;
    const params: any = {
      projection: 'lastNumbers,expDate,scheme,created,id',
    };

    this.buildParams(params, { last, from, to, limit } as CardsOptions);

    return http.get(url, { params });
  }

  private getCard(cardId: string) {
    return http.get(`cards/${cardId}`);
  }

  public getAllCards(options: CardsOptions) {
    return getAllItems(last => this.getCards({ ...options, last }));
  }

  public async getCardsCount({ programId, from, to, limit }: CardsOptions) {
    return getCount(last => {
      const url = `programs/${programId}/cards`;
      const params: any = {
        select: 'count',
      };

      this.buildParams(params, { last, from, to, limit } as CardsOptions);

      return http.get(url, { params });
    }, api.MAX_CARDS_COUNT);
  }

  public async deleteCard(cardId: string) {
    return http.delete(`cards/${cardId}`);
  }
}
