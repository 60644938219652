import styled from 'styled-components';
import { Row } from 'antd';

import { breakpoints, colors } from '../../../theme';

export const Info = styled.div`
  max-width: calc(100% - 82px);
  padding-left: 20px;
  p,
  .ant-typography {
    margin-bottom: 0;
  }
`;

export const RecentProgram = styled(Row)<{ position: number }>`
  background: ${colors.lightgrey};
  padding: 14px 24px 14px 14px;
  border-radius: 4px;
  max-width: 340px;
  cursor: pointer;
  z-index: 0;
  width: calc(33% - 40px);
  margin-right: 40px;
  flex-grow: 1;
  &:last-of-type {
    margin-right: 0;
  }
  :hover {
    background: ${colors.grey2};
  }

  @media (max-width: ${breakpoints.tabletLandscape}px) {
    display: ${({ position }) => (position === 2 ? 'none' : 'inherit')};
    margin-right: 0;
    &:first-of-type {
      margin-right: 20px;
    }
    max-width: 50%;
  }

  @media (max-width: ${breakpoints.tabletPortrait}px) {
    display: ${({ position }) => (position === 0 ? 'inherit' : 'none')};
    max-width: 66%;
  }
`;

export const RecentProgramsContainer = styled(Row)`
  flex-flow: row;
  justify-content: flex-start;
  overflow: hidden;
  margin-bottom: 34px;
`;
