import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { Button, Input, Row, Col } from 'antd';
import { Auth } from 'aws-amplify';

import { Field } from '../../types';
import regex from '../../utils/regex';
import Form from '../../components/form';
import {
  NewsletterCheckbox,
  Terms,
  MethodTitle,
  GoBackButton,
  Title,
  EmailSentContainer,
  EmailSentMessage,
  Email,
} from './styled/sign-up';
import EmailInput from './components/EmailInput';
import useHandleCognitoError from '../../hooks/use-handle-cognito-error';
import useRedirect from '../../hooks/use-redirect';
import { useSetStatus } from '../../hooks/use-status';
import arrowLeft from './icons/arrow-left.svg';
import emailIcon from './icons/email.svg';
import useCaptchaFormSubmit from '../../hooks/use-captcha-form-submit';
import GoogleRecaptchaDisclaimer from './GoogleRecaptchaDisclaimer';

interface SignUpWithEmailProps {
  goBack?: () => unknown;
  email?: string;
  invite?: string;
}

enum FormState {
  Start,
  Submitted,
  SignedUp,
}

export const SignUpWithEmail = ({
  goBack,
  email: emailParam,
  invite,
}: SignUpWithEmailProps) => {
  const handleCognitoError = useHandleCognitoError();
  const { t } = useTranslation();
  const [signedUpEmail, setSignedUpEmail] = useState<string>();
  const [formState, setFormState] = useState<FormState>(FormState.Start);
  const redirect = useRedirect();
  const { setSuccessMessage } = useSetStatus();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      email: emailParam,
    },
  });

  const { onSubmit } = useCaptchaFormSubmit(submitSignUp);

  async function submitSignUp({
    nameFirst,
    nameLast,
    companyName,
    email,
    password,
    token,
  }: any) {
    setFormState(FormState.Submitted);

    try {
      const params: any = {
        username: email,
        password,
        attributes: {
          name: nameFirst,
          family_name: nameLast,
        },
        validationData: {
          captcha: token,
        },
      };

      if (companyName) {
        params.attributes['custom:companyName'] = companyName;
      }

      if (invite) {
        params.attributes['custom:inviteId'] = invite;
      }

      await Auth.signUp(params);

      setSignedUpEmail(email);

      if (invite) {
        redirect('/sign-in');
        setSuccessMessage(t('signUp.signedUpNotification'));
      } else {
        setFormState(FormState.SignedUp);
      }
    } catch (error) {
      setFormState(FormState.Start);
      handleCognitoError(error);
    }
  }

  if (formState === FormState.SignedUp) {
    return (
      <EmailSentContainer>
        <img alt="Email" src={emailIcon} />
        <Title>
          <Trans i18nKey="signUp.emailSent.title" />
        </Title>
        <EmailSentMessage>
          <Trans
            i18nKey="signUp.emailSent.message"
            components={[<Email />]}
            values={{
              email: signedUpEmail,
            }}
          />
        </EmailSentMessage>
      </EmailSentContainer>
    );
  }

  function formItem({
    name,
    type,
    rules,
    element,
    defaultValue,
    validateCharacters = true,
    disabled,
  }: Field) {
    return (
      <Form.ItemController
        key={name}
        errors={errors}
        // TODO Fix 'any' below in OFF-1413
        label={t(`signUp.${name}` as any)}
        validateCharacters={validateCharacters}
        controller={{
          name,
          render: ({ field }) =>
            element ? (
              React.cloneElement(element, {
                ...field,
                type: type || 'text',
                defaultValue,
                disabled,
              })
            ) : (
              <Input
                {...field}
                type={type || 'text'}
                defaultValue={defaultValue}
                disabled={disabled}
              />
            ),
          rules,
          control,
        }}
      />
    );
  }

  return (
    <>
      <MethodTitle>
        {goBack && (
          <GoBackButton data-testid="go back" onClick={() => goBack()}>
            <img src={arrowLeft} alt="Go back" />
          </GoBackButton>
        )}
        {invite ? t('signUp.invited') : t('signUp.methods.email')}
      </MethodTitle>
      <Form layout="vertical" onSubmit={handleSubmit(onSubmit)}>
        <EmailInput
          errors={errors}
          control={control}
          defaultValue={emailParam}
          disabled={!!emailParam}
        />
        {formItem({
          name: 'password',
          type: 'password',
          validateCharacters: false,
          rules: {
            required: true,
            minLength: 8,
            maxLength: 30,
            validate: {
              password: (value: string) => regex.password.test(value),
            },
          },
          element: (
            <Input.Password
              name="password"
              placeholder={t('signUp.password')}
            />
          ),
        })}
        <Row justify="space-between">
          <Col xs={{ span: 11 }}>
            {formItem({
              name: 'nameFirst',
              rules: { required: true, maxLength: 30 },
            })}
          </Col>
          <Col xs={{ offset: 1, span: 12 }}>
            {formItem({
              name: 'nameLast',
              rules: { required: true, maxLength: 30 },
            })}
          </Col>
        </Row>
        {!invite &&
          formItem({
            name: 'companyName',
            rules: { required: true, minLength: 2, maxLength: 50 },
          })}
        <NewsletterCheckbox>{t('signUp.newsletter')}</NewsletterCheckbox>
        <Button
          block
          type="primary"
          htmlType="submit"
          disabled={formState !== FormState.Start}
          data-testid="submit"
        >
          {t('signUp.submit')}
        </Button>
      </Form>
      <Terms>
        <Trans
          i18nKey="signUp.terms"
          components={[
            // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
            <a href="https://fidelapi.com/legal/" />,
            // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
            <a href="https://fidelapi.com/legal/privacy/" />,
          ]}
        />
      </Terms>
      <GoogleRecaptchaDisclaimer />
    </>
  );
};

export default SignUpWithEmail;
