import { api } from '../../config';
import http from '../../services/http-service';
import {
  LocationsFilter,
  UniqueLocationsFilters,
} from '../filters/filters-reducer';
import { getAllItems, getCount } from '../utils/pagination';
import { filterGetByIdCall } from '../utils/transform';
import { Location } from './locations-model';
import schemes from '../../utils/schemes';

export interface MerchantIds {
  visa?: string[];
  mastercard?: string[];
  amex?: string[];
}

export interface UpdateLocationPayload {
  address: string;
  city: string;
  postcode: string;
  countryCode: string;
  stateCode?: string;
  searchBy?: {
    merchantIds?: MerchantIds;
  };
}

export interface CreateLocationPayload extends UpdateLocationPayload {
  brandId?: string;
}

export interface LocationsOptions {
  programId: string;
  filters?: LocationsFilter;
  last?: any;
}

export interface UniqueLocationsOptions {
  uniqueBrandId: string;
  filters?: UniqueLocationsFilters;
  last?: any;
}

export interface MapUniqueLocationsToProgramOptions {
  programId: string;
  uniqueBrandId: string;
  brandId: string;
  uniqueLocationIds: string[];
}

function filterByStatus(
  status: string | undefined,
  location: Location,
): boolean {
  if (!status) return true;
  if (location.action === 'deleted' && status === 'delete') return true;

  return schemes
    .map(scheme => location[scheme]?.status === status)
    .some(bool => bool);
}

class LocationsApiService {
  public async getLocations({ programId, filters, last }: LocationsOptions) {
    const { id, brandId, postcode, status } = filters || {};

    if (id) {
      return filterGetByIdCall(id, programId, this.getLocation, [
        (location: Location) =>
          brandId ? location.brandId.id === brandId : true,
        (location: Location) =>
          postcode ? location.postcode === postcode : true,
        (location: Location) => filterByStatus(status, location),
      ]);
    }

    const url = filters?.brandId
      ? `brands/${filters.brandId}/programs/${programId}/locations`
      : `programs/${programId}/locations`;

    const params: any = { expand: 'brandId', ...filters };

    // brandId is special filter and appears in path not in query
    delete params.brandId;

    if (last) params.start = JSON.stringify(last);

    return http.get(url, { params });
  }

  private getLocation(locationId: string) {
    const params = { expand: 'brandId' };
    return http.get(`locations/${locationId}`, { params });
  }

  public getAllLocations({ programId, filters }: LocationsOptions) {
    return getAllItems(last => this.getLocations({ programId, filters, last }));
  }

  public getLocationsCount(
    programId: string,
    locationFilters?: LocationsFilter,
  ) {
    const { brandId, countryCode } = locationFilters ?? {};

    return getCount(last => {
      const url = brandId
        ? `brands/${brandId}/programs/${programId}/locations`
        : `programs/${programId}/locations`;

      const params: any = {};
      if (countryCode) params.countryCode = countryCode;
      params.select = 'count';
      if (last) params.start = JSON.stringify(last);

      return http.get(url, { params });
    }, api.MAX_LOCATIONS_COUNT);
  }

  public async createLocation(
    programId: string,
    location: CreateLocationPayload,
  ) {
    return http.post(`programs/${programId}/locations`, location);
  }

  public async deleteLocation(locationId: string) {
    return http.delete(`locations/${locationId}`);
  }

  public async updateLocation(
    locationId: string | undefined,
    location: UpdateLocationPayload,
  ) {
    return http.patch(`locations/${locationId}`, location);
  }

  public async getUniqueLocations({
    uniqueBrandId,
    filters,
    last,
  }: UniqueLocationsOptions) {
    const params: any = { limit: 1000 };
    if (last) params.start = JSON.stringify(last);
    if (filters?.address) params.address = filters.address;
    if (filters?.city) params.city = filters.city;
    if (filters?.countryCode) params.countryCode = filters.countryCode;
    if (filters?.postcode) params.postcode = filters.postcode;
    if (filters?.stateCode) params.stateCode = filters.stateCode;

    return http.get(`unique-brands/${uniqueBrandId}/unique-locations`, {
      params,
    });
  }

  public getAllUniqueLocations({
    uniqueBrandId,
    filters,
  }: UniqueLocationsOptions) {
    return getAllItems(last =>
      this.getUniqueLocations({ uniqueBrandId, filters, last }),
    );
  }

  public mapUniqueLocationsToProgram({
    programId,
    uniqueBrandId,
    brandId,
    uniqueLocationIds,
  }: MapUniqueLocationsToProgramOptions) {
    return http.post(
      `programs/${programId}/unique-brands/${uniqueBrandId}/locations`,
      { brandId, uniqueLocationIds },
    );
  }
}

export default LocationsApiService;
