import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { shallowEqual } from 'react-redux';
import { DefaultOverlay } from '../../../../../components/styled/overlay';
import {
  SelectedBox,
  SelectedContainer,
  SelectedDetails,
  UnselectedDetails,
  SelectedName,
  SelectedURL,
  SelectedDetailsWrapper,
  AddBrandDisabled,
} from '../styled/selected-brand';
import { UniqueBrand } from '../../../../../store/brands/brands-model';
import Ellipsis from '../../../../../components/ellipsis';
import Avatar from '../../../../../components/avatar';
import { Check } from '../../../../../components/icons';
import { createBrand } from '../../../../../store/brands/brands-actions';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';

interface SelectBrandProps {
  selectedUniqueBrand?: UniqueBrand;
  setSelectedUniqueBrand: (uniqueBrand: UniqueBrand | undefined) => void;
  handleAddUniqueBrand: (uniqueBrand: UniqueBrand | null) => void;
}

const SelectedBrand = ({
  selectedUniqueBrand,
  setSelectedUniqueBrand,
  handleAddUniqueBrand,
}: SelectBrandProps) => {
  const { t } = useTranslation('brands', {
    keyPrefix: 'addBrand.search.selectedBrand',
  });
  const dispatch = useAppDispatch();
  const { brands, isLive } = useAppSelector(
    state => ({
      brands: state.brands.brands,
      isLive: state.live,
    }),
    shallowEqual,
  );

  const { name, websiteURL, logoURL } = selectedUniqueBrand || {};

  function handleClickAddBrand() {
    if (isLive) {
      handleAddUniqueBrand(selectedUniqueBrand || null);
    } else {
      const brand = {
        name: name as string,
        ...(websiteURL && { websiteURL }),
        ...(logoURL && { logoURL }),
      };
      dispatch(
        createBrand({
          brand,
          consent: {} as any,
          live: isLive,
        }),
      );
    }
  }

  return (
    <SelectedContainer isBrandSelected={!!selectedUniqueBrand}>
      <DefaultOverlay
        mediaMaxWidth="1200px"
        onClick={() => setSelectedUniqueBrand(undefined)}
      />
      <SelectedBox>
        {selectedUniqueBrand ? (
          <SelectedDetailsWrapper>
            <Avatar size={88} src={logoURL}>
              {name}
            </Avatar>
            <SelectedDetails>
              <div>
                <SelectedName>{name}</SelectedName>
                <SelectedURL>
                  <a
                    href={websiteURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Ellipsis>{websiteURL}</Ellipsis>
                  </a>
                </SelectedURL>
              </div>
              {!brands.find(brand => brand.name === name) ? (
                <Button
                  type="primary"
                  size="small"
                  onClick={handleClickAddBrand}
                >
                  {t('action.label')}
                </Button>
              ) : (
                <AddBrandDisabled>
                  <Check />
                  <span style={{ paddingLeft: '12px' }}>
                    {t('action.disabled')}
                  </span>
                </AddBrandDisabled>
              )}
            </SelectedDetails>
          </SelectedDetailsWrapper>
        ) : (
          <UnselectedDetails>{t('placeholder')}</UnselectedDetails>
        )}
      </SelectedBox>
    </SelectedContainer>
  );
};

export default SelectedBrand;
