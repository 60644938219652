import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationCode } from '../../components/notifications';

export enum DefaultStatusCode {
  'error',
  'success',
  'info',
}

export type StatusCode = NotificationCode | DefaultStatusCode;

export interface Status {
  code: StatusCode;
  message?: string;
  values?: Record<string, any>;
  skip?: boolean;
  onClose?: () => void;
}

export interface StatusState {
  counter: number;
  status: Status | null;
}

const initialState: StatusState = {
  status: null,
  counter: 0,
};

const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    clear(state) {
      state.status = null;
    },
    status(state, action: PayloadAction<Status>) {
      state.counter++;
      state.status = action.payload;
    },
  },
});

export default statusSlice.reducer;
export const { actions } = statusSlice;
