import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Location } from './locations-model';
import { Program } from '../programs/programs-reducer';
import { actionToError } from '../utils/transform';
import { sanitizeLocations } from '../utils/sanitizers';

export interface LastCursor {
  created: string;
  id: string;
  programIdDel: string;
}

export interface LocationsState {
  counts: Record<string, { loading: boolean; count?: number; error?: true }>;
  locations: {
    [programId: string]: {
      loading: boolean;
      error?: any;
      items?: Location[];
      last?: Partial<LastCursor>;
    };
  };
  linkedLocations: {
    [programId: string]: {
      loading: boolean;
      error?: any;
      items?: Location[];
    };
  };
  creating: boolean;
  updating: boolean;
  deleting: boolean;
  error?: any;
  uniqueLocations: {
    [uniqueBrandId: string]: {
      loading: boolean;
      error?: any;
      items: Location[];
      last?: Partial<LastCursor>;
    };
  };
  mapUniqueLocationsToProgramStatus: {
    loading: boolean;
    success?: boolean;
    error?: any;
    selectedLocationsToMapCount?: number;
  };
  exportableLocations: Location[];
  exporting: boolean;
}

const initialState: LocationsState = {
  counts: {},
  locations: {},
  linkedLocations: {},
  creating: false,
  exportableLocations: [],
  exporting: false,
  updating: false,
  deleting: false,
  uniqueLocations: {},
  mapUniqueLocationsToProgramStatus: {
    loading: false,
  },
};

const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    clear: () => initialState,
    createLocation(state) {
      state.creating = true;
    },
    createLocationSuccess(state) {
      state.creating = false;
    },
    createLocationError(state, action: PayloadAction<any>) {
      state.creating = false;
      state.error = actionToError(action);
    },

    updateLocation(state) {
      state.updating = true;
    },
    updateLocationSuccess(state) {
      state.updating = false;
    },
    updateLocationError(state, action: PayloadAction<any>) {
      state.updating = false;
      state.error = actionToError(action);
    },

    deleteLocation(state) {
      state.deleting = true;
    },
    deleteLocationSuccess(state) {
      state.deleting = false;
    },
    deleteLocationError(state, action: PayloadAction<any>) {
      state.deleting = false;
      state.error = actionToError(action);
    },

    getAllLocations(state, action: PayloadAction<Program[]>) {
      action.payload.forEach(({ id }) => {
        state.locations[id] = { loading: true };
      });
    },

    getExportableLocations(state) {
      state.exporting = true;
      state.exportableLocations = [];
    },
    getExportableLocationsSuccess(
      state,
      action: PayloadAction<{ locations: Location[] }>,
    ) {
      const { locations } = action.payload;
      state.exporting = false;
      state.exportableLocations = locations;
    },
    getExportableLocationsError(state, action: PayloadAction<{ error: any }>) {
      state.error = actionToError(action);
      state.exporting = false;
    },
    getAllLinkedLocations(state, action: PayloadAction<{ programId: string }>) {
      const { programId } = action.payload;

      state.linkedLocations[programId] = { loading: true, items: [] };
    },
    getAllLinkedLocationsSuccess(
      state,
      action: PayloadAction<{ programId: string; items: any[] }>,
    ) {
      const { programId, items } = action.payload;
      state.linkedLocations[programId] = {
        loading: false,
        items: sanitizeLocations(items),
      };
    },
    getAllLinkedLocationsError(
      state,
      action: PayloadAction<{ programId: string; error: any }>,
    ) {
      const { programId, error } = action.payload;

      state.linkedLocations[programId] = { loading: false, error };
      state.error = actionToError(action);
    },
    getAllLocationsDone(state, action: PayloadAction<any[]>) {
      action.payload.forEach(({ programId, items, error }) => {
        if (error) state.locations[programId] = { loading: false, error };
        else {
          state.locations[programId] = {
            loading: false,
            items: sanitizeLocations(items),
          };
        }
      });
    },

    getLocations(state, action: PayloadAction<string>) {
      state.locations = {
        [action.payload]: { loading: true },
      };
    },
    getLocationsSuccess(state, action: PayloadAction<any>) {
      const { programId, last, items } = action.payload;
      state.locations[programId] = {
        items: sanitizeLocations(items),
        last,
        loading: false,
      };
    },
    getLocationsError(state, action: PayloadAction<any>) {
      const { programId, error } = action.payload;
      state.locations[programId].loading = false;
      state.locations[programId].error = error;
    },

    getMoreLocations(state, action: PayloadAction<string>) {
      state.locations[action.payload].loading = true;
    },
    getMoreLocationsSuccess(state, action: PayloadAction<any>) {
      const { programId, last, items } = action.payload;
      const currentItems = state.locations[programId].items || [];

      state.locations[programId].loading = false;
      state.locations[programId].last = last;
      state.locations[programId].items = sanitizeLocations([
        ...currentItems,
        ...items,
      ]);
    },
    getMoreLocationsError(state, action: PayloadAction<any>) {
      const { programId, error } = action.payload;
      state.locations[programId].loading = false;
      state.locations[programId].error = error;
    },

    getLocationsCount(state, action: PayloadAction<{ programId: string }>) {
      const { programId } = action.payload;
      state.counts[programId] = {
        loading: true,
      };
    },
    getLocationsCountSuccess(
      state,
      action: PayloadAction<{ count: number; programId: string }>,
    ) {
      const { programId, count } = action.payload;

      state.counts[programId] = {
        count,
        loading: false,
      };
    },
    getLocationsCountError(
      state,
      action: PayloadAction<{ programId: string }>,
    ) {
      const { programId } = action.payload;
      state.counts[programId] = {
        loading: false,
        error: true,
      };
    },
    getUniqueLocations(state, action: PayloadAction<any>) {
      const { uniqueBrandId } = action.payload;
      state.uniqueLocations[uniqueBrandId] = { loading: true, items: [] };
    },
    getUniqueLocationsSuccess(state, action: PayloadAction<any>) {
      const { uniqueBrandId, last, items } = action.payload;
      state.uniqueLocations[uniqueBrandId] = {
        items: sanitizeLocations(items),
        last,
        loading: false,
      };
    },
    getUniqueLocationsError(state, action: PayloadAction<any>) {
      const { uniqueBrandId, error } = action.payload;
      state.uniqueLocations[uniqueBrandId] = {
        loading: false,
        items: [],
        error,
      };
    },
    getAllUniqueLocations(state, action: PayloadAction<any>) {
      const { uniqueBrandId } = action.payload;
      state.uniqueLocations[uniqueBrandId] = { loading: true, items: [] };
    },
    getAllUniqueLocationsSuccess(state, action: PayloadAction<any>) {
      const { uniqueBrandId, items } = action.payload;
      state.uniqueLocations[uniqueBrandId] = {
        items: sanitizeLocations(items),
        loading: false,
      };
    },
    getAllUniqueLocationsError(state, action: PayloadAction<any>) {
      const { uniqueBrandId, error } = action.payload;
      state.uniqueLocations[uniqueBrandId] = {
        error,
        loading: false,
        items: [],
      };
    },
    resetMapUniqueLocationsToProgram(state) {
      state.mapUniqueLocationsToProgramStatus = {
        loading: false,
      };
    },
    mapUniqueLocationsToProgram(state) {
      state.mapUniqueLocationsToProgramStatus = {
        loading: true,
      };
    },
    mapUniqueLocationsToProgramSuccess(
      state,
      action: PayloadAction<{ selectedLocationsToMapCount: number }>,
    ) {
      const { selectedLocationsToMapCount } = action.payload;
      state.mapUniqueLocationsToProgramStatus = {
        loading: false,
        success: true,
        selectedLocationsToMapCount,
      };
    },
    mapUniqueLocationsToProgramError(state, action: PayloadAction<any>) {
      const { error } = action.payload;
      state.mapUniqueLocationsToProgramStatus = {
        loading: false,
        error,
      };
    },
  },
});

export default locationsSlice.reducer;
export const { actions } = locationsSlice;
