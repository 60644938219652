import i18n from 'i18next';
import { Thunk } from '../types';
import {
  actions as statusActions,
  DefaultStatusCode,
} from '../status/status-reducer';
import VirtualCardApiService from './virtual-card-api-service';
import { actions } from './virtual-card-reducer';

const api = new VirtualCardApiService();

export const { clear } = actions;

export const getVirtualCardTransactions = (): Thunk => async dispatch => {
  dispatch(actions.getVirtualCardTransactions());

  try {
    const items = await api.getVirtualCardTransactions();
    dispatch(actions.getVirtualCardTransactionsSuccess(items));
  } catch (error) {
    dispatch(actions.getVirtualCardTransactionsError({ error }));
  }
};

export const submitVirtualCardTransaction =
  (locationId: string, transactionId: string): Thunk =>
  async dispatch => {
    dispatch(actions.submitVirtualCardTransaction());

    try {
      await api.submitVirtualCardTransaction(locationId, transactionId);
      dispatch(actions.submitVirtualCardTransactionSuccess());
      dispatch(
        statusActions.status({
          code: DefaultStatusCode.success,
          message: i18n.t(
            'programs.locations.linkVirtualCardTransaction.success',
          ),
        }),
      );
    } catch (error) {
      dispatch(actions.submitVirtualCardTransactionError({ error }));
    }
  };
