import React from 'react';
import { Trans } from 'react-i18next';

import { NotificationPayload } from '.';
import { CTAButton } from './styled';

export const newDashboardVersion: NotificationPayload = {
  level: 'info',
  duration: 0,
  title: 'common.info.newVersion.title',
  description: (
    <Trans
      ns="notifications"
      i18nKey="common.info.newVersion.description"
      components={[
        <CTAButton type="link" onClick={() => window.location.reload()} />,
      ]}
    />
  ),
};
