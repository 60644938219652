import React, { ReactNode } from 'react';

const features = [
  'test',
  'allFeaturesTest',
  'socialSignIn',
  'avatarBadge',
  'invoicesDisabled',
  'programTypeSelectionGoLive',
] as const;

type Feature = typeof features[number];

let featureSettings: Partial<Record<Feature, boolean>> = JSON.parse(
  localStorage.getItem('FIDEL_FEATURES') || '{}',
);

export function isFeatureEnabled(feature: Feature) {
  return featureSettings[feature] || false;
}

export function enableFeature(feature: Feature, enable = true) {
  featureSettings[feature] = enable;
  localStorage.setItem('FIDEL_FEATURES', JSON.stringify(featureSettings));
}

export function enableFeatureOnce(feature: Feature, enable = true) {
  if (featureSettings[feature] === undefined) {
    enableFeature(feature, enable);
  }
}

export function enableAllFeatures(enable = true) {
  features.forEach(feature => enableFeature(feature, enable));
}

export function resetAllFeatures() {
  featureSettings = {};
  localStorage.setItem('FIDEL_FEATURES', JSON.stringify(featureSettings));
}

interface FeatureCheckProps {
  feature: Feature;
  children: ReactNode;
}

export const FeatureCheck = ({ feature, children }: FeatureCheckProps) =>
  isFeatureEnabled(feature) ? <>{children}</> : null;

(window as any).enableFidelFeature = enableFeature;
(window as any).enableAllFidelFeature = enableAllFeatures;
