import { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

const useAccountLockedGuard = (isAccountLocked: boolean) => {
  const history = useHistory();

  useEffect(() => {
    if (isAccountLocked) {
      history.push('/account-locked');
    }
  }, [isAccountLocked, history]);
};

export default useAccountLockedGuard;
