import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import { Card } from '../../../store/cards/cards-types';
import { Offer } from '../../../store/offers/offers-model';
import { linkCardToOffer } from '../../../store/offers';
import DrawerForm from '../../../components/drawer-form';
import drawerTransition, {
  DrawerVisibleProps,
} from '../../../components/drawer-transition';
import { OfferDropdown } from '../../../components/dropdowns';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

interface OfferActivationDrawerProps extends DrawerVisibleProps {
  onClose: () => any;
  card?: Card;
}

const OfferActivationDrawer = ({
  afterVisibleChange,
  card,
  onClose,
  visible,
}: OfferActivationDrawerProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [offer, setOffer] = useState<Offer>();
  const { error, loading } = useAppSelector(
    state => ({
      error: state.offer.error,
      loading: state.offer.loading,
    }),
    shallowEqual,
  );

  function onSubmit() {
    dispatch(linkCardToOffer(card?.id as string, offer?.id as string));
  }

  function filterOffer({ activation, schemes }: Offer) {
    return activation?.enabled && card && schemes?.includes(card.scheme);
  }

  return (
    <DrawerForm
      confirmText={t('cards.label.activate')}
      title={t('cards.activateOfferOnCard')}
      onClose={onClose}
      onSubmit={onSubmit}
      visible={visible}
      afterVisibleChange={afterVisibleChange}
      submitDisabled={loading || !offer}
      hasFinished={!loading && !error}
      processing={loading}
    >
      <OfferDropdown
        formItemProps={{
          label: t('cards.label.offers'),
        }}
        disabled={loading}
        onChange={setOffer}
        onFilter={filterOffer}
      />
    </DrawerForm>
  );
};

export default drawerTransition(OfferActivationDrawer);
