import React, { ReactNode } from 'react';
import { Col, Row } from 'antd';

import { Brand } from '../../../../../store/brands/brands-model';

import {
  StyledCardContainer,
  StyledBrandCol,
  StyledCaronIcon,
  StyledLocationsCol,
  StyledBrandWebsite,
} from './styled/select-brand-row';
import Avatar from '../../../../../components/avatar';
import Ellipsis from '../../../../../components/ellipsis';

export interface SelectBrandRowProps {
  brand?: Brand;
  title?: string;
  avatar?: ReactNode;
  onClickHandler: () => void;
}

const SelectBrandRow = ({
  brand,
  title,
  avatar,
  onClickHandler,
}: SelectBrandRowProps) => (
  <StyledCardContainer onClick={onClickHandler}>
    <Row align="middle">
      <Col>
        {avatar || (
          <Avatar src={brand?.logoURL} color={{ border: 'lightgrey2' }}>
            {brand?.name}
          </Avatar>
        )}
      </Col>
      <StyledBrandCol>
        <Ellipsis>
          <span data-testid="select-brand-row-title">
            {title || brand?.name}
          </span>
        </Ellipsis>

        {brand?.websiteURL && (
          <Ellipsis>
            <StyledBrandWebsite>{brand?.websiteURL}</StyledBrandWebsite>
          </Ellipsis>
        )}
      </StyledBrandCol>
      <StyledLocationsCol>
        <StyledCaronIcon />
      </StyledLocationsCol>
    </Row>
  </StyledCardContainer>
);

export default SelectBrandRow;
