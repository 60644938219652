import { useTranslation } from 'react-i18next';

import { useSetStatus } from './use-status';

const useCopyWithNotification = (successMessage?: string) => {
  const { t } = useTranslation();
  const { setSuccessMessage } = useSetStatus();

  async function copyWithNotification(text: string) {
    try {
      await navigator.clipboard.writeText(text);
      setSuccessMessage(successMessage || t('actions.idCopied'));
    } catch (error) {
      // eslint-disable-next-line no-alert
      window.prompt(text);
    }
  }

  return copyWithNotification;
};

export default useCopyWithNotification;
