import React, { useEffect } from 'react';

import { OnboardingCursorCustom } from './styled';
import { useOnboardingTarget } from '../../hooks/use-onboarding-target';
import { OnboardingOverlay } from '../../styled';

interface OnboardingCursorProps {
  startX?: number;
  startY?: number;
  target: string;
  action?: () => void;
}

export const cursorSize = 30;
export function startCenter() {
  return {
    startX: window.innerWidth / 2 - cursorSize / 2,
    startY: window.innerHeight / 2 - cursorSize / 2,
  };
}

export const OnboardingCursor = ({
  startX,
  startY,
  target,
  action,
}: OnboardingCursorProps) => {
  const targetElement = useOnboardingTarget(target);

  useEffect(() => {
    let timer: number;

    if (targetElement && action) {
      timer = window.setTimeout(action, 3000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [action, targetElement]);

  const { x, y, width, height } = targetElement
    ? targetElement.getBoundingClientRect()
    : {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      };

  return (
    targetElement && (
      <>
        <OnboardingOverlay
          onClick={e => e.stopPropagation()}
          data-testid="onboarding-overlay"
        />
        <OnboardingCursorCustom
          data-testid="onboarding-cursor"
          size={cursorSize}
          startX={startX}
          startY={startY}
          endX={x + width - cursorSize - cursorSize / 2}
          endY={y + height / 2 - cursorSize / 2}
        />
      </>
    )
  );
};

export default OnboardingCursor;
