import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Detail from '../../../components/detail';
import { Brand } from '../../../store/brands/brands-model';
import { useBrands } from '../context/brands-context';
import { getBrandLogoUrl } from '../utils';
import BrandView from './BrandView';
import { Action } from '../../../components/actions-menu';
import { useAppSelector } from '../../../store/hooks';

export interface BrandDetailProps {
  brand: Brand;
  onClear: () => void;
  actions: Action[];
}

function BrandDetail({ brand, onClear, actions }: BrandDetailProps) {
  const brands = useAppSelector(state => state.brands.brands);
  const { t } = useTranslation('brands');
  const { editedBrandsIds } = useBrands();

  function getLogo() {
    // Gets updated state from brands store
    const currentBrand = brands.find(b => b.id === brand.id);

    return currentBrand
      ? getBrandLogoUrl(currentBrand, editedBrandsIds)
      : undefined;
  }

  return (
    <Detail
      actions={actions}
      clear={onClear}
      emptyMessage={t('detail.empty')}
      title={{
        logo: getLogo(),
        name: brand.name,
        title: brand.name,
        description: t('detail.createdAt', {
          date: moment(brand.created).format('DD/MM/YYYY HH:mm'),
        }),
      }}
    >
      {!brand.autoApproved && <BrandView brand={brand} />}
    </Detail>
  );
}

export default BrandDetail;
