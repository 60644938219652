import styled from 'styled-components';

export const CityAndPostcode = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: baseline;

  > *:nth-child(1) {
    margin-right: 3px;
    flex: 2;
  }

  > *:nth-child(2) {
    flex: 1.2;
    margin-left: 3px;

    label {
      white-space: nowrap;
    }
  }
`;
