import styled from 'styled-components';
import { breakpoints, colors } from '../../../theme';
import { X } from '../../icons';

export const CloseButton = styled(X)`
  position: absolute;
  top: 12px;
  right: 12px;
  font-size: 18px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }

  svg path {
    stroke-width: 2;
    color: white;
  }
`;

export const Heading = styled.p`
  margin: 0;
  font-size: 12px;
  color: ${colors.lightorange2};
`;

export const PopoverContainer = styled.div`
  .ant-popover-content {
    display: none;
    background: ${colors.darkblue2};
    border-radius: 6px;
    width: 300px;

    .ant-popover-inner {
      position: relative;
      background: ${colors.darkblue2};
      border-radius: 6px;
      padding: 16px 24px;

      .ant-popover-title {
        padding: 0;
        margin: 0;
        border-bottom: none;
      }

      .ant-popover-inner-content {
        color: white;
        font-size: 14px;
      }
    }

    .ant-popover-arrow {
      display: block;
      border-bottom-color: ${colors.darkblue2};
      border-left-color: ${colors.darkblue2};
    }
  }

  @media (min-width: ${breakpoints.sidebarCollapse}px) {
    .ant-popover-content {
      display: inherit;
    }
  }
`;

export const PopoverTitle = styled.h2`
  font-size: 14px;
  font-weight: 500;
  color: white;
  margin-bottom: 8px;
`;
