import React, { useEffect } from 'react';
import { shallowEqual } from 'react-redux';

import { getVirtualCards } from '../../../../store/account/account-actions';
import WithoutCard from './NoCard';
import WithCard from './VirtualCards';
import Spin from '../../../../components/spin';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

const VirtualCards = () => {
  const dispatch = useAppDispatch();
  const { loading, virtualCard } = useAppSelector(
    state => ({
      loading: state.account.virtualCards.loading,
      virtualCard: state.account.virtualCards.entities?.[0],
    }),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(getVirtualCards());
  }, [dispatch]);

  if (loading) return <Spin />;

  if (!virtualCard) return <WithoutCard />;

  return <WithCard card={virtualCard} />;
};

export default VirtualCards;
