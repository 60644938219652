import React from 'react';

import { Container, Separator } from './styled';

interface CombinedSchemesProps {
  icons: {
    visa: string;
    mastercard: string;
  };
}

export default function CombinedSchemes({ icons }: CombinedSchemesProps) {
  return (
    <Container data-testid="combined-schemes">
      <img alt="visa" src={icons.visa} role="presentation" />
      <Separator />
      <img alt="mastercard" src={icons.mastercard} role="presentation" />
    </Container>
  );
}
