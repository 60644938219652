import React from 'react';

import Ellipsis from '../../ellipsis';
import { RelativeContainer, StyledTag } from './styled';
import useCopyWithNotification from '../../../hooks/use-copy-with-notification';

interface CopyableTagProps {
  width?: string;
  showTooltip?: boolean;
  successMessage: string;
  text: string;
}

const CopyableTag = ({
  width,
  showTooltip,
  successMessage,
  text,
  ...props
}: CopyableTagProps) => {
  const copyWithNotification = useCopyWithNotification(successMessage);

  return (
    <RelativeContainer {...props}>
      <StyledTag
        color="blue"
        onClick={e => {
          e.stopPropagation();
          copyWithNotification(text);
        }}
        $width={width}
      >
        <Ellipsis gutter={0} showTooltip={showTooltip}>
          {text}
        </Ellipsis>
      </StyledTag>
    </RelativeContainer>
  );
};

export default CopyableTag;
