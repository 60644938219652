import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './locales/en';

export const defaultNS = 'common';
export const resources = {
  en,
} as const;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    ns: Object.keys(resources.en),
    defaultNS,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    parseMissingKeyHandler(key) {
      const message = `Missing translation key: ${key} (${i18n.language})`;
      // eslint-disable-next-line no-console
      console.warn(message);

      return key;
    },
  });

export default i18n;
