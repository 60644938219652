import styled from 'styled-components';

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  white-space: nowrap;
  font-size: 12px;
  padding-bottom: 6px;

  & > div {
    display: flex;
    align-items: center;
    margin-left: 6px;
  }
`;
