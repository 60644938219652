import http from '../../services/http-service';
import { getAllItems } from '../utils/pagination';
import { VirtualCardTransaction } from './virtual-card-model';

export default class VirtualCardApiService {
  async getVirtualCardTransactions() {
    return getAllItems<VirtualCardTransaction>(last =>
      http.get('vcards/vtransactions', {
        params: { start: JSON.stringify(last) },
      }),
    );
  }

  async submitVirtualCardTransaction(
    locationId: string,
    transactionId: string,
  ) {
    return http.patch(`vcards/vtransactions/${transactionId}`, {
      locationId,
      status: 'solved',
    });
  }
}
