import React from 'react';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

import { ProgramLinkStatus } from '../../../store/offers/offers-model';

export type Status = ProgramLinkStatus['status'] | 'available';

interface StatusTagProps extends React.HTMLAttributes<HTMLSpanElement> {
  status: Status;
}

function StatusTag({ status, ...props }: StatusTagProps) {
  const { t } = useTranslation('offers');

  const colorsMap: { [status in Status]: string } = {
    linked: 'green',
    processing_approval: 'orange',
    processing_configuration: 'blue',
    pending: 'yellow',
    rejected: 'red',
    available: 'default',
  };

  return (
    <Tag
      color={colorsMap[status]}
      {...props}
      style={{ marginRight: 0, ...props.style }}
    >
      {t(`status.${status}.label`)}
    </Tag>
  );
}

export default StatusTag;
