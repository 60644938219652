import styled from 'styled-components';
import { colors } from '../../../theme';

export const DetailTitleContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  padding: 2rem 2rem 1.5rem;
  background-color: ${colors.lightgrey};
  border-bottom: none;
  border-radius: 0.25rem;

  .ant-row {
    .ant-col {
      text-align: center;
      line-height: 1.15rem;
    }

    &:not(&.subtitle) {
      margin-top: 0.25rem;
    }

    &.subtitle {
      margin-top: 0.25rem;
    }
  }

  .ant-avatar {
    color: ${colors.white};
    margin-bottom: 0.5rem;
  }

  .title {
    font-size: 14px;
    margin-top: 1rem;
    gap: 0.5rem;

    strong {
      font-weight: 500;
    }
  }

  .subtitle {
    font-size: 12px;
  }

  .description {
    line-height: 1rem;

    span {
      font-size: 0.75rem;
      color: ${colors.grey4};
    }
  }
`;
