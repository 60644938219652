import { useSearchParams } from 'react-router-dom-v5-compat';
import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import { useSetStatus } from '../../../hooks/use-status';
import http from '../../../services/http-service';
import { Offer, OfferType } from '../../../store/offers/offers-model';
import { setQueryData } from './utils';
import { parseAxiosError } from '../../../store/utils/transform';
import { ApiResponse } from '../../../types';

interface ApproveRejectOffer {
  publisherId: string | undefined;
  programId: string | undefined;
  uniqueOfferId: string | undefined;
  status: 'approve' | 'reject';
  reason?: string;
}

function approveRejectOffer({
  publisherId,
  programId,
  uniqueOfferId,
  status,
  reason,
}: ApproveRejectOffer) {
  return http.patch(
    `accounts/${publisherId}/programs/${programId}/unique-offers/${uniqueOfferId}/status`,
    { status, ...(status === 'reject' && { reason }) },
  );
}

export function useApproveRejectOffer() {
  const { t } = useTranslation('notifications');
  const [searchParams] = useSearchParams();
  const { setStatus, setSuccessMessage } = useSetStatus();
  const queryClient = useQueryClient();

  return useMutation(
    ({
      publisherId,
      programId,
      uniqueOfferId,
      status,
      reason,
    }: ApproveRejectOffer) =>
      approveRejectOffer({
        publisherId,
        programId,
        uniqueOfferId,
        status,
        reason,
      }),
    {
      onSuccess: async (_, { uniqueOfferId, programId, status }) => {
        const previousCount = await setQueryData<
          Record<OfferType, ApiResponse<Offer>>
        >({
          queryKey: ['offers', 'count'],
          updateFn: previous => ({
            ...previous,
            awaitingApproval: {
              ...previous.awaitingApproval,
              count: Math.max(0, (previous.awaitingApproval?.count || 0) - 1),
            },
          }),
          queryClient,
        });
        const previousAwaitingApproval = await setQueryData<
          InfiniteData<ApiResponse<Offer>>
        >({
          queryKey: ['offers', 'awaitingApproval', searchParams.toString()],
          updateFn: previous => ({
            ...previous,
            pages: previous?.pages.map(page => ({
              ...page,
              items: page.items.filter(
                ({ id, programId: pId }) =>
                  !(id === uniqueOfferId && pId === programId),
              ),
            })),
          }),
          queryClient,
        });
        const previousAwaitingApprovalCount = await setQueryData<
          InfiniteData<ApiResponse<Offer>>
        >({
          queryKey: [
            'offers',
            'awaitingApproval-count',
            searchParams.toString(),
          ],
          updateFn: previous => ({
            ...previous,
            pages: previous?.pages.map(page => ({
              ...page,
              count: Math.max(0, (page?.count || 0) - 1),
            })),
          }),
          queryClient,
        });

        setSuccessMessage(t(`offers.approval.${status}`));

        return {
          previousCount,
          previousAwaitingApproval,
          previousAwaitingApprovalCount,
        };
      },
      onError: (error: AxiosError) => {
        const { code, message } = parseAxiosError(error);

        setStatus(code, { message });
      },
    },
  );
}
