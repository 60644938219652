import React, { ReactNode, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import { Modal } from 'antd';
import { Card } from '../../../store/cards/cards-types';
import * as CardsActions from '../../../store/cards/cards-actions';
import { selectHideLastNumbers } from '../../../store/account/account-selectors';
import CopyableTag from '../../../components/tag/copyable-tag';
import Ellipsis from '../../../components/ellipsis';
import useRawDetail from '../../../components/raw-detail/hooks/use-raw-detail';
import useProgramLocationsStatuses from '../../../hooks/use-program-locations-statuses';
import useSelectedProgram from '../../../hooks/use-selected-program';
import useTransactionStream from '../../../hooks/use-transaction-stream';
import useCopyWithNotification from '../../../hooks/use-copy-with-notification';
import OfferActivationDrawer from './OfferActivationDrawer';

import { fonts } from '../../../theme';
import { icons } from '../../../utils/schemes';
import InfiniteScrollTable from '../../../components/tables/InfiniteScrollTable';
import SelectionIndicator from '../../../components/selection-indicator';
import CardsExport from './CardsExport';
import useProgressState from '../../../hooks/use-progress-state';
import { GreyDropdownButton } from '../../../components/buttons';
import { Actions } from '../../../components/tables/base/Row';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

interface CardsListProps {
  header: ReactNode;
  cards: Card[];
  loadCards: (opts?: { clearFirst: boolean }) => void;
  selectedRows: any;
}

const CardsList = ({
  header,
  cards,
  loadCards,
  selectedRows,
}: CardsListProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { selectedProgram } = useSelectedProgram();
  const programId = selectedProgram?.id;
  const { hasSyncedLocations } = useProgramLocationsStatuses(programId);
  const { isLive, count, last, loading, deleting, activeFilters } =
    useAppSelector(
      state => ({
        accountId: state.account.details?.id,
        isLive: state.live,
        count: state.cards.count,
        loading: state.cards.loading,
        last: state.cards.last,
        deleting: state.cards.deleting,
        activeFilters: state.filters.activeFilters.cards,
        billingConfigurations: state.account.billingConfigurations.entity,
      }),
      shallowEqual,
    );
  const { rawDetail, openRawDetail } = useRawDetail('cards');
  const [cardToLink, setCardToLink] = useState<Card>();
  const hideLastNumbers = useAppSelector(selectHideLastNumbers);
  const deletingProgress = useProgressState(deleting);
  const { hasProgramTransactionStream } = useTransactionStream();
  const copyWithNotification = useCopyWithNotification();

  const showEmptyNoLocations =
    !hasSyncedLocations && isLive && !hasProgramTransactionStream;

  function deleteCards(cardIds: string[]) {
    Modal.confirm({
      title: t('cards.delete.title'),
      content: t('cards.delete.message'),
      onOk: () => {
        dispatch(CardsActions.deleteCard(cardIds));

        if (cardIds.length > 1) {
          selectedRows.clear();
        }
      },
      okButtonProps: { danger: true },
      okText: t('confirm.delete'),
      maskClosable: true,
      icon: null,
    });
  }

  function cardToRow(card: Card, index: number) {
    const { scheme, expDate, lastNumbers, id, created } = card;
    const icon = <img alt={scheme} src={icons[scheme]} role="presentation" />;
    const contents = [
      hideLastNumbers ? null : <CardCell>{lastNumbers}</CardCell>,
      icon,
      <CopyableTag
        text={id}
        successMessage={t('copyable.cardId')}
        data-onboarding-target={`card-${index}`}
      />,
      moment.utc(expDate).format('MM/YYYY'),
      <Ellipsis>{moment.utc(created).format('MMM D, YYYY h:mm A')}</Ellipsis>,
    ];

    const actions: Actions = [
      {
        label: t('actions.viewRaw'),
        callback: openRawDetail(id),
      },
      {
        label: t('actions.copyId'),
        callback: () => copyWithNotification(id),
      },
      {
        label: t('cards.label.activateOffer'),
        callback: () => setCardToLink(card),
        hidden: hasProgramTransactionStream,
      },
      {
        label: t('cards.label.delete'),
        callback: () => deleteCards([id]),
      },
    ];

    return {
      contents,
      actions,
      onClick: openRawDetail(id),
      selected: selectedRows.isSelected(card),
      onSelect: selectedRows.onSelect(card),
    };
  }

  function updateLastCard() {
    if (last && !loading && selectedProgram)
      dispatch(CardsActions.getCards({ programId, last }));
  }

  deletingProgress.onFinish(() => loadCards());

  const rows = cards.map((card, index) => cardToRow(card, index));

  return (
    <>
      <InfiniteScrollTable
        minWidth={900}
        onSelectAll={selectedRows.onSelectAll(cards)}
        columns={[
          {
            heading: hideLastNumbers ? null : t('cards.label.lastNumbers'),
            size: hideLastNumbers ? 0 : 0.1,
          },
          {
            heading: t('cards.label.scheme'),
            size: 0.2,
            align: hideLastNumbers ? 'left' : 'center',
          },
          { heading: t('cards.label.cardId'), size: 0.5 },
          { heading: t('cards.label.expDate'), size: 0.2 },
          { heading: t('cards.label.added'), size: 0.3, sortedBy: 'unset' },
        ]}
        rows={rows}
        onBottom={updateLastCard}
        onReload={() => loadCards({ clearFirst: true })}
        total={count}
        loading={loading}
        activeFilters={activeFilters}
        emptyText={
          showEmptyNoLocations ? (
            <Trans
              i18nKey="cards.emptyNoLocations"
              components={[
                <NavLink data-testid="locations-link" to="/locations" />,
              ]}
            />
          ) : activeFilters ? (
            t('cards.emptyFiltered')
          ) : (
            t('cards.empty')
          )
        }
        topContent={
          <>
            {header}
            <SelectionIndicator
              count={selectedRows.count}
              i18nKey="cards.selected"
              actions={
                <>
                  <CardsExport selectedIds={selectedRows.items} />
                  <GreyDropdownButton
                    onClick={() => deleteCards(selectedRows.items)}
                  >
                    {t('cards.label.deleteSelected')}
                  </GreyDropdownButton>
                </>
              }
            />
          </>
        }
      />
      <div>
        <OfferActivationDrawer
          card={cardToLink}
          onClose={() => setCardToLink(undefined)}
          visible={!!cardToLink}
        />
        {rawDetail}
      </div>
    </>
  );
};

const CardCell = styled.div`
  font-family: ${fonts.monospace};
`;

export default CardsList;
