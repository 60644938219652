import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Button, Input } from 'antd';
import { Auth } from 'aws-amplify';

import { setLive } from '../../store/live/live-actions';
import memoNoProps from '../../utils/memo-no-props';
import Logo from './components/Logo';
import EmailInput from './components/EmailInput';
import {
  TopBar,
  Title,
  Subtitle,
  CenteredContainer,
  SignUpBox,
} from './styled/sign-in';
import Form from '../../components/form';
import useIsSignedIn, { sessionToUser } from '../../hooks/use-is-signed-in';
import useHandleCognitoError from '../../hooks/use-handle-cognito-error';
import { useAppDispatch } from '../../store/hooks';
import useCaptchaFormSubmit from '../../hooks/use-captcha-form-submit';
import GoogleRecaptchaDisclaimer from './GoogleRecaptchaDisclaimer';

export type SignInForm = {
  email: string;
  password: string;
  token: string;
};

const SignIn = () => {
  const handleCognitoError = useHandleCognitoError();
  const { signIn } = useIsSignedIn();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<SignInForm>({
    mode: 'onBlur',
    defaultValues: {
      email: undefined,
      password: undefined,
    },
  });

  const { onSubmit } = useCaptchaFormSubmit(submitSignIn);

  async function submitSignIn({ email, password, token }: SignInForm) {
    try {
      const result = await Auth.signIn(email, password, {
        captcha: token,
      });

      dispatch(setLive(false));
      signIn(sessionToUser(result.getSignInUserSession()));
    } catch (error) {
      handleCognitoError(error);
    }
  }

  return (
    <>
      <TopBar />
      <CenteredContainer>
        <Logo centered />
        <Title>{t('signIn.title')}</Title>
        <Subtitle>{t('signIn.subtitle')}</Subtitle>
        <Form layout="vertical" onSubmit={handleSubmit(onSubmit)}>
          <EmailInput errors={errors} control={control} />
          <Form.ItemController
            errors={errors}
            label={t('signIn.password')}
            controller={{
              name: 'password',
              render: ({ field }) => (
                <Input.Password {...field} placeholder={t('signIn.password')} />
              ),
              rules: {
                required: true,
              },
              control,
            }}
          />
          <Form.Item>
            <GoogleRecaptchaDisclaimer />
          </Form.Item>
          <Form.Item>
            <Button
              block
              type="primary"
              htmlType="submit"
              data-testid="sign-in"
            >
              {t('signIn.submit')}
            </Button>
          </Form.Item>
          <NavLink to="/reset-password">
            {t('signIn.forgottenPassword')}
          </NavLink>
          <SignUpBox>
            <Trans
              i18nKey="signIn.dontHaveAccount"
              components={[<NavLink to="/sign-up" />]}
            />
          </SignUpBox>
        </Form>
      </CenteredContainer>
    </>
  );
};

export default memoNoProps(SignIn);
