import { useRef, useState, useEffect } from 'react';

import http from '../services/http-service';

export default function usePendingRequests() {
  const count = useRef(0);
  const [isPendingRequests, setIsPendingRequests] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changeCount = (modifier: number) => {
    count.current += modifier;
    setIsPendingRequests(count.current > 0);
  };

  useEffect(() => {
    const removeRequestInterceptor = http.addRequestInterceptor(config => {
      changeCount(1);
      return config;
    });

    const removeResponseInterceptor = http.addResponseInterceptor(
      response => {
        changeCount(-1);
        return response;
      },
      error => {
        changeCount(-1);
        throw error;
      },
    );

    return () => {
      removeRequestInterceptor();
      removeResponseInterceptor();
    };
  }, [changeCount]);

  return isPendingRequests;
}
