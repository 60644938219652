import { useCallback, useEffect, useMemo } from 'react';
import useSet, { SetType } from './use-set';

function arrayToRecord<T>(arr: T[], mapId: (item: T) => string) {
  const index: Record<string, true> = {};

  arr.forEach(item => {
    index[mapId(item)] = true;
  });

  return index;
}

function useSupersetFiltering<T>(
  superset: T[] | Record<string, T> | undefined,
  set: ReturnType<typeof useSet>,
  mapId: (item: T) => string,
) {
  const supersetIndex = useMemo(() => {
    if (!superset) return null;
    if (!Array.isArray(superset)) return superset;

    return arrayToRecord(superset, mapId);
  }, [mapId, superset]);

  const existsInSuperset = useCallback(
    (id: string) => {
      if (!supersetIndex) return true;
      return supersetIndex[id];
    },
    [supersetIndex],
  );

  useEffect(() => {
    set.items.filter(id => !existsInSuperset(id)).forEach(id => set.remove(id));
  }, [existsInSuperset, mapId, set, set.items]);
}

export interface SelectedRowsType<T> {
  onSelect: (item: T) => (checked: boolean) => void;
  onSelectAll: (items: T[]) => (checked: boolean) => void;
  isSelected: (item: T) => boolean;
  count: number;
}

type UseSetTypes = Pick<SetType, 'items' | 'remove' | 'clear' | 'setRecord'>;

export default function useSelectedRows<T>(
  mapId: (item: T) => string,
  superset?: T[] | Record<string, T>,
): SelectedRowsType<T> & UseSetTypes {
  const set = useSet();

  useSupersetFiltering(superset, set, mapId);

  function onSelect(item: T) {
    return (checked: boolean) => {
      const id = mapId(item);
      if (checked) {
        set.add([id]);
      } else {
        set.remove(id);
      }
    };
  }

  function onSelectAll(items: T[]) {
    return (checked: boolean) => {
      if (checked) {
        set.add(items.map(mapId));
      } else {
        set.clear();
      }
    };
  }

  function isSelected(item: T) {
    return set.has(mapId(item));
  }

  const { items, clear, setRecord, remove } = set;
  const count = items.length;

  return {
    onSelect,
    onSelectAll,
    isSelected,
    count,
    items,
    remove,
    clear,
    setRecord,
  };
}
