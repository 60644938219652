import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { colors } from '../../theme';
import { IllustrationsKeys, illustrations } from './illustrations';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #5f6d83;
  font-size: 14px;

  > span {
    width: 300px;
  }

  svg {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }

  @media (min-height: 600px) {
    margin-top: 40px;
    svg {
      width: inherit;
      height: inherit;
    }
  }
`;

interface EmptyProps {
  className?: string;
  children?: ReactNode;
  illustrationKey?: IllustrationsKeys;
}

const Empty = ({
  children,
  className,
  illustrationKey = 'default',
}: EmptyProps) => (
  <Container className={className} data-testid="empty">
    {illustrations[illustrationKey]}
    <span>{children}</span>
  </Container>
);

export const EmptyText = styled.span`
  font-size: 14px;
  color: ${colors.grey5};
  white-space: pre-line;
`;

export default styled(Empty)``;
