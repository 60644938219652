import React, { useEffect } from 'react';
import { Drawer, Button, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router';
import { Offer, OfferType } from '../../../../store/offers/offers-model';
import {
  getAllLocations,
  getAllUniqueLocations,
} from '../../../../store/offers';
import InfiniteScrollTable from '../../../../components/tables/InfiniteScrollTable';
import { Location } from '../../../../store/locations/locations-model';
import { buildAddress } from '../../../../utils/transform';
import drawerTransition, {
  DrawerVisibleProps,
} from '../../../../components/drawer-transition';
import { isUniqueOffer } from '../../utils';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

export interface LocationsDrawerProps extends DrawerVisibleProps {
  offer: Offer;
  onClose: () => void;
}

function LocationsDrawer({
  offer,
  visible,
  afterVisibleChange,
  onClose,
}: LocationsDrawerProps) {
  const { t } = useTranslation(['common', 'offers', 'locations', 'programs']);
  const { type: offerType } = useParams<{ type: OfferType }>();
  const dispatch = useAppDispatch();

  const isMarketplaceOffer = isUniqueOffer(offer);
  const shouldFetchUniqueLocations =
    offerType === 'marketplace' ||
    offerType === 'awaitingApproval' ||
    (offerType === 'requests' && isMarketplaceOffer);

  const locations = useAppSelector(
    state =>
      state.offer[shouldFetchUniqueLocations ? 'uniqueLocations' : 'locations'][
        `${offer.id}`
      ],
  );

  useEffect(() => {
    if (offer.id)
      dispatch(
        shouldFetchUniqueLocations
          ? getAllUniqueLocations(offer.id)
          : getAllLocations(offer.id),
      );
  }, [offer.id, dispatch, shouldFetchUniqueLocations]);

  const locationsToRow = (locations?.items || []).map((location: Location) => ({
    contents: [
      <Typography.Text key={location.id}>
        {buildAddress(location, false)}
      </Typography.Text>,
      null,
    ],
  }));

  return (
    <Drawer
      data-testid="offer-locations-drawer"
      closable={false}
      width={440}
      visible={visible}
      title={t('offers:fields.locations.label')}
      afterVisibleChange={afterVisibleChange}
      onClose={onClose}
      footer={
        <Row>
          <Button type="primary" onClick={onClose} size="small">
            {t('common:confirm.done')}
          </Button>
        </Row>
      }
    >
      <InfiniteScrollTable
        loading={locations?.loading}
        columns={[
          { heading: t('locations:address') },
          {
            heading: t('programs:locationsCount', {
              count: offer.locationsTotal,
            }),
            size: 0.25,
          },
        ]}
        minWidth={100}
        rows={locationsToRow}
      />
    </Drawer>
  );
}

export default drawerTransition(LocationsDrawer);
