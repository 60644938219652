import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom-v5-compat';

import { Sort } from '../components/tables/BaseTable';
import { isValidSort } from '../components/filters/utils';

export function useSortWithParams() {
  const [searchParams, setSearchParams] = useSearchParams();
  const column = searchParams.get('sort');
  const direction = searchParams.get('order');

  useEffect(() => {
    if (!isValidSort(searchParams)) {
      setSearchParams(prev => {
        const params = new URLSearchParams(prev);
        params.delete('sort');
        params.delete('order');
        return params.toString();
      });
    }
  }, [searchParams, setSearchParams]);

  function sortedBy(name: string): Sort {
    const columnIsSorted = column === name;

    if (!columnIsSorted) return 'unset';

    if (direction === 'desc') return 'desc';

    return 'asc';
  }

  function onSort(name: string) {
    return (sortBy: Sort) => {
      setSearchParams(prev => {
        const params = new URLSearchParams(prev);

        if (sortBy === 'unset') {
          params.delete('sort');
          params.delete('order');
        } else {
          params.set('sort', name);
          params.set('order', sortBy);
        }

        return params.toString();
      });
    };
  }

  return (name: string) => ({ sortedBy: sortedBy(name), onSort: onSort(name) });
}
