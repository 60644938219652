import React from 'react';
import styled from 'styled-components';
import { Row } from 'antd';

interface HeaderProps {
  heading: string;
  topRight?: React.ReactNode;
  bottomLeft?: React.ReactNode;
  bottomRight?: React.ReactNode;
}

const TopRow = styled(Row)`
  margin-bottom: 40px;

  h2 {
    margin: 0;
  }
`;

const Header = ({
  heading,
  topRight,
  bottomLeft,
  bottomRight,
}: HeaderProps) => (
  <>
    <TopRow justify="space-between">
      <h2>{heading}</h2>
      {topRight}
    </TopRow>
    <Row justify="space-between">
      {bottomLeft}
      {bottomRight}
    </Row>
  </>
);

export default Header;
