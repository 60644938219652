import React, { ReactNode } from 'react';
import useIsSignedIn from '../../hooks/use-is-signed-in';

interface RoleCheckProps {
  disabledForModerator?: boolean;
  requiresAccountAdmin?: boolean;
  children?: ReactNode;
}

const RoleCheck = ({
  disabledForModerator,
  requiresAccountAdmin,
  children,
}: RoleCheckProps) => {
  const { isModerator, isAccountAdmin } = useIsSignedIn();

  if (disabledForModerator && isModerator) {
    return null;
  }
  if (requiresAccountAdmin && !isAccountAdmin) {
    return null;
  }

  return <>{children}</>;
};

function roleCheck<P>(
  options: RoleCheckProps,
  Component: React.ComponentType<P>,
) {
  const WrappedComponent: React.ComponentType<P> = (props: P) => (
    <RoleCheck {...options}>
      <Component {...(props as P)} />
    </RoleCheck>
  );

  const name = Component.displayName ?? Component.name;
  WrappedComponent.displayName = `RoleCheck(${name})`;

  return WrappedComponent;
}

export { RoleCheck, roleCheck };
