import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { CreateUpdateOfferFormModel } from '../../../types';
import Form from '../../../../../components/form';
import { Select } from '../../../../../components/select';
import { Search } from '../../../../../components/filters/styled';
import { colors } from '../../../../../theme';
import { ReactComponent as SearchIcon } from '../../../../../assets/search.svg';
import { useBrands } from '../../../../brands/hooks/useBrands';
import useDebounce from '../../../../../hooks/use-debounce';
import { Brand } from '../../../../../store/brands/brands-model';

interface BrandSelectProps {
  isUpdating: boolean;
  setSelectedBrand: (b: Brand | undefined) => void;
}

export default function BrandSelect({
  isUpdating,
  setSelectedBrand,
}: BrandSelectProps) {
  const { t } = useTranslation(['common', 'offers']);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const { data } = useBrands({ searchTerm: debouncedSearchTerm });

  const {
    formState: { errors },
    control,
  } = useFormContext<CreateUpdateOfferFormModel>();

  const options = data?.pages.flatMap(page => page.items) || [];

  return (
    <Form.ItemController
      label={t('offers:fields.brand.label')}
      errors={errors}
      controller={{
        name: 'brandId',
        control,
        render: ({ field: { onChange, ...field } }) => (
          <Select
            {...field}
            data-name="brandId"
            data-testid="brands-dropdown"
            placeholder={t('common:transactions.label.selectBrand')}
            disabled={isUpdating}
            onChange={brandId => {
              onChange(brandId);
              setSelectedBrand(options.find(({ id }) => id === brandId));
            }}
            dropdownRender={menu => (
              <>
                <Search
                  name="search"
                  autoFocus
                  placeholder={t('common:search')}
                  prefix={<SearchIcon style={{ color: colors.grey4 }} />}
                  data-testid="search-input"
                  type="text"
                  bordered={false}
                  style={{ padding: '4px 8px 8px' }}
                  onChange={({ target }) => setSearchTerm(target.value)}
                />
                {menu}
              </>
            )}
          >
            {options.map(({ id, name }) => (
              <Select.Option key={id} value={id}>
                {name}
              </Select.Option>
            ))}
          </Select>
        ),
        rules: {
          required: true,
        },
      }}
    />
  );
}
