import React, { useContext } from 'react';
import OnboardingCursor, { startCenter } from '../components/onboarding-cursor';
import { OnboardingTourContext } from '../onboarding-provider';
import { OnboardingStepTypes } from '../onboarding-config';
import useRedirect from '../../../hooks/use-redirect';

export const LocationsClickStep = () => {
  const redirect = useRedirect();
  const { setStep } = useContext(OnboardingTourContext);

  return (
    <OnboardingCursor
      target="menu-item-cards"
      action={() => {
        setStep(OnboardingStepTypes.CARDS);
        redirect('/cards');
      }}
      {...startCenter()}
    />
  );
};
