import React from 'react';
import { Typography, Popover } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';

import { Offer } from '../../../../store/offers/offers-model';
import Link from '../../../../components/link';
import { EstimationLabel } from '../styled';
import { DOUBLE_MINUS } from '../../../../utils/special-character';
import { isUniqueOffer } from '../../utils';
import StatusTag from '../../components/StatusTag';

interface OfferStatusProps {
  offer: Offer;
}

const DATE_FORMAT = 'll - h:mm a';

function OfferStatus(props: OfferStatusProps) {
  const { offer } = props;
  const { t } = useTranslation('offers');

  const isMarketplaceOffer = isUniqueOffer(offer);
  const offerRequiresApproval = !!offer.requiresApproval;
  const marketplaceOfferStatus = offerRequiresApproval
    ? 'processing_approval'
    : 'processing_configuration';

  const offerEstimationDate =
    offer[
      offerRequiresApproval ? 'estimationApprovalDate' : 'estimationLiveDate'
    ];
  const applicationDate = offer.requestedDate || offer.created;

  const popoverContent = (
    <div style={{ padding: '24px' }}>
      <span>
        <EstimationLabel>
          {t(`status.${marketplaceOfferStatus}.popover.start.label`)}
        </EstimationLabel>
        <Typography.Paragraph>
          {moment(applicationDate).format(DATE_FORMAT)}
        </Typography.Paragraph>
      </span>
      <span>
        <EstimationLabel>
          {t(`status.${marketplaceOfferStatus}.popover.end.label`)}
        </EstimationLabel>
        <Typography.Paragraph>
          {offerEstimationDate
            ? moment(offerEstimationDate).format(DATE_FORMAT)
            : DOUBLE_MINUS}
        </Typography.Paragraph>
      </span>
      <EstimationLabel>
        <Trans
          i18nKey="status.processing_configuration.popover.footer"
          ns="offers"
          components={{
            'support-contact': <Link to="mailto:support@fidel.uk" />,
          }}
        />
      </EstimationLabel>
    </div>
  );

  return isMarketplaceOffer && offer.isAccountProgramUniqueOffer ? (
    <Popover placement="bottomRight" content={popoverContent} trigger="hover">
      <StatusTag status={marketplaceOfferStatus} />
    </Popover>
  ) : (
    <StatusTag status="pending" />
  );
}

export default OfferStatus;
