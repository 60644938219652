import React from 'react';
import { ListItem, StyledList } from '../styled';
import { OverlayProps } from './FilterDropdown';

function Overlay({ selected, options, setSelected, setVisible }: OverlayProps) {
  function selectOption(option: string) {
    if (selected.includes(option)) return;

    setSelected([option]);
  }

  return (
    <StyledList>
      {options.map(({ id, name }) => (
        <ListItem
          key={id}
          onClick={() => {
            selectOption(id);
            setVisible(false);
          }}
          style={{ paddingRight: '64px' }}
          $active={selected.includes(id)}
        >
          {name}
        </ListItem>
      ))}
    </StyledList>
  );
}

export default Overlay;
