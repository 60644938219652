import { useEffect, useState } from 'react';
import axios from 'axios';

import { useSetStatus } from './use-status';
import usePrevious from './use-previous';

const checkForUpdatesInterval = 60000;

function useDashboardVersion() {
  const [etag, setEtag] = useState<string>();
  const oldEtag = usePrevious(etag);
  const [showNotification, setShowNotification] = useState(true);
  const { setStatus } = useSetStatus();

  useEffect(() => {
    async function fetchHead() {
      try {
        const response = await axios.head('/index.html');
        const newEtag = response.headers.etag;

        if (newEtag) {
          setEtag(newEtag);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }

    fetchHead();

    const interval = setInterval(() => fetchHead(), checkForUpdatesInterval);

    return () => clearInterval(interval);
  }, [setEtag, setStatus]);

  useEffect(() => {
    if (etag && oldEtag && etag !== oldEtag && showNotification) {
      setStatus('newDashboardVersion');
      setShowNotification(false);
    }
  }, [etag, oldEtag, setStatus, showNotification]);
}

export default useDashboardVersion;
