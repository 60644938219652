import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'antd';

import { ReactComponent as Illustration1Vector } from '../assets/illustration1.svg';
import { OnboardingStepTypes } from '../onboarding-config';
import { OnboardingTourContext } from '../onboarding-provider';
import Modal from '../../modal';

export const WelcomeStep = () => {
  const { t } = useTranslation('common', { keyPrefix: 'onboarding.welcome' });

  const { completeOnboardingStep, setStep } = useContext(OnboardingTourContext);

  return (
    <Modal
      title={t('title')}
      description={
        <div>
          <p>
            <Trans
              i18nKey="onboarding.welcome.description.paragraph1"
              components={[<strong />]}
            />
          </p>
          <p>
            <Trans
              i18nKey="onboarding.welcome.description.paragraph2"
              components={[<strong />]}
            />
          </p>
        </div>
      }
      buttons={[
        <Button
          type="primary"
          onClick={() => {
            completeOnboardingStep('dashboardWelcome');
            setStep(OnboardingStepTypes.PROGRAMS);
          }}
          size="small"
          data-testid="modal-button-0"
          key="modal-button-0"
        >
          {t('button')}
        </Button>,
      ]}
      illustration={<Illustration1Vector />}
      visible
    />
  );
};
