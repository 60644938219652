import { useCallback, useEffect } from 'react';

import createPersistedState from '../../hooks/create-persisted-state';
import { useAppSelector } from '../../store/hooks';

export enum Step {
  START = 0,
  REGION = 1,
  COMPANY = 2,
  BUSINESS = 3,
  PROGRAM = 4,
  LEGAL = 5,
  BILLING = 6,
  FINISH = 7,
  REVIEW = 8,
  LIVE = 9,
  DONE = 10,
}

export interface GoLiveModel {
  [index: string]: string | string[] | undefined;

  // Region
  countryCode: string;

  // Company
  address: string;
  address2?: string;
  city: string;
  name: string;
  legalName: string;
  vatNumber?: string;
  companyNumber?: string;
  poNumber?: string;
  postcode: string;
  invoiceEmails: string[];

  // Business
  appName: string;
  cardsOnFileRange: string;
  monthlyActiveUsersRange: string;
  monthlyTransactionsRange: string;
  website: string;

  // Program
  programType: 'transaction-stream' | 'transaction-select';
  description: string;

  // Billing
  setupIntentId: string;

  // Legal
  faqDocumentUrl: string;
  pciDssAoCDocumentUrl?: string;
  privacyPolicyDocumentUrl: string;
  termsAndConditionsDocumentUrl: string;
}

interface AccountGoLiveFormState {
  step: Step;
  form: Partial<GoLiveModel>;
}

interface AllGoLiveFormState {
  [accountId: string]: AccountGoLiveFormState;
}

const useAllGoLiveFormState = createPersistedState<AllGoLiveFormState>(
  'FIDEL_GO_LIVE_FORM_V3',
  {},
);

const defaultGoLiveValues: AccountGoLiveFormState = {
  step: Step.START,
  form: {
    programType: 'transaction-select',
  },
};

function useGoLiveFormState(): [
  AccountGoLiveFormState,
  (value: AccountGoLiveFormState) => void,
] {
  const details = useAppSelector(state => state.account.details);
  const [allGoLiveFormState, setAllGoLiveFormState] = useAllGoLiveFormState();

  const accountId = details?.id;

  const { form: accountForm, step: accountStep } =
    allGoLiveFormState[accountId || ''] || defaultGoLiveValues;

  const setAccountGoLiveState = useCallback(
    ({ step, form }: { step: Step; form: Partial<GoLiveModel> }) => {
      if (!accountId) return;

      setAllGoLiveFormState({
        ...allGoLiveFormState,
        [accountId]: {
          form,
          step,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId, setAllGoLiveFormState],
  );

  useEffect(() => {
    if (!accountId) return;

    const { form, step } = allGoLiveFormState[accountId] || defaultGoLiveValues;

    setAccountGoLiveState({
      form,
      step,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, setAccountGoLiveState]);

  return [{ form: accountForm, step: accountStep }, setAccountGoLiveState];
}

export default useGoLiveFormState;
