import React, { ReactElement, useState, useEffect, useMemo } from 'react';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import Row from './base/Row';
import { defaultSettings, Column } from './BaseTable';
import { Cell, CheckboxWrapper, StyledHeadings, Sorted } from './styled/base';
import ContentStateHandler from './base/ContentStateHandler';

export interface VirtualizedTableProps {
  width: string | number;
  height: string | number;
  minHeight?: string | number;
  maxHeight?: string | number;
  items: any[][];
  itemSize: number;
  onSelectAll?: (values: any) => void;
  columns: Column[];
  loading?: boolean;
  emptyText?: string | ReactElement | null;
  resetSelection?: boolean;
  icon?: ReactElement;
}

export const VirtualizedTable = ({
  width,
  height,
  minHeight,
  maxHeight,
  items,
  itemSize,
  onSelectAll,
  columns,
  loading,
  emptyText,
  resetSelection,
  icon,
}: VirtualizedTableProps) => {
  const { t } = useTranslation();

  const defaultSelectedCheckboxes = useMemo(
    () => Array.from({ length: items.length }, () => false),
    [items],
  );

  const [selectedCheckboxes, setSelectedCheckboxes] = useState(
    defaultSelectedCheckboxes,
  );

  useEffect(() => {
    if (resetSelection) setSelectedCheckboxes(defaultSelectedCheckboxes);
  }, [resetSelection, defaultSelectedCheckboxes]);

  const areAllSelected =
    selectedCheckboxes.length > 0 &&
    selectedCheckboxes.findIndex(item => !item) === -1;

  function selectAll() {
    const newSelectedCheckboxes = Array.from(
      { length: items.length },
      () => !areAllSelected,
    );

    setSelectedCheckboxes(newSelectedCheckboxes);
    if (onSelectAll) onSelectAll(getSelectedItems(newSelectedCheckboxes));
  }

  function getSelectedItems(newSelectedCheckboxes: boolean[]) {
    return items.reduce((acc: any[], item: any, index: number) => {
      if (newSelectedCheckboxes[index]) {
        const itemObj = {};
        item.forEach((itemValue: any, itemValueIndex: number) => {
          const columnName =
            columns[itemValueIndex].id || `prop${itemValueIndex}`;
          if (columns[itemValueIndex].id) {
            Object.assign(itemObj, { [columnName]: itemValue });
          }
        });
        acc.push(itemObj);
      }
      return acc;
    }, []);
  }

  const headings = (
    <StyledHeadings padding>
      {onSelectAll && (
        <CheckboxWrapper>
          <Checkbox
            data-testid="select-all-checkbox"
            checked={areAllSelected}
            onChange={selectAll}
          />
        </CheckboxWrapper>
      )}
      {columns
        .filter(({ hidden }) => !hidden)
        .map(({ heading, align, size, sortedBy }, i) => (
          <Cell
            align={align ?? defaultSettings.align}
            size={size ?? defaultSettings.size}
            key={i}
          >
            {heading}
            {sortedBy && (
              <Sorted direction={sortedBy} data-testid={`sort-${sortedBy}`} />
            )}
          </Cell>
        ))}
    </StyledHeadings>
  );

  const row = ({
    index,
    style,
  }: {
    index: number;
    style: Record<string, any>;
  }) => (
    <div style={style}>
      <Row
        key={`row-${index}`}
        forceRightPadding={false}
        contents={items[index].reduce((acc, item, accIndex) => {
          if (!columns[accIndex].hidden) acc.push(item);
          return acc;
        }, [])}
        columnSettings={columns.map(() => defaultSettings)}
        selected={!resetSelection && onSelectAll && selectedCheckboxes[index]}
        onSelect={
          onSelectAll
            ? isSelected => {
                const newSelectedCheckboxes = [...selectedCheckboxes];
                newSelectedCheckboxes[index] = isSelected;
                setSelectedCheckboxes(newSelectedCheckboxes);

                if (onSelectAll)
                  onSelectAll(getSelectedItems(newSelectedCheckboxes));
              }
            : undefined
        }
        icon={icon}
        showEllipsis
      />
      {index === items.length - 1 && <div style={{ height: itemSize }} />}
    </div>
  );

  return (
    <div
      style={{
        width,
        height,
        minHeight,
        maxHeight,
      }}
    >
      <ContentStateHandler
        bordered={false}
        emptyText={emptyText === null ? null : emptyText || t('table.empty')}
        isLoading={loading || false}
        loadingExtra={t('extraLoading')}
        hasItems={items.length > 0}
      >
        {headings}
        <AutoSizer>
          {({ height: autoHeight, width: autoWidth }) => (
            <List
              width={autoWidth}
              height={autoHeight}
              itemCount={items.length}
              itemSize={itemSize}
            >
              {row}
            </List>
          )}
        </AutoSizer>
      </ContentStateHandler>
    </div>
  );
};
