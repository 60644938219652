import ProgramApiService, { ProgramPayload } from './programs-api-service';
import { getLocations } from '../locations/locations-actions';
import { actions } from './programs-reducer';
import { Thunk } from '../types';
import { CountryCode } from '../../types';

const programApiService: ProgramApiService = new ProgramApiService();

export const { clear } = actions;

export const getProgram =
  (programId: string): Thunk =>
  async dispatch => {
    dispatch(actions.getProgram());

    try {
      const response = await programApiService.getProgram(programId);
      dispatch(actions.getProgramSuccess(response.data));
    } catch (error) {
      dispatch(actions.getProgramError({ error }));
    }
  };

export const getPrograms =
  (options?: any): Thunk =>
  async dispatch => {
    dispatch(actions.getPrograms());

    try {
      const response = await programApiService.getPrograms(options);
      dispatch(actions.getProgramsSuccess(response.data));
    } catch (error) {
      dispatch(actions.getProgramsError({ error }));
    }
  };

export const getProgramsByCountry =
  (countries: CountryCode[]): Thunk =>
  async dispatch => {
    dispatch(actions.getProgramsByCountry());

    try {
      const response = await Promise.all(
        countries.map(countryCode =>
          programApiService.getPrograms({
            countryCode,
          }),
        ),
      );
      dispatch(
        actions.getProgramsByCountrySuccess(
          response.reduce(
            (acc, elem, idx) => ({
              ...acc,
              [countries[idx]]: elem.data.items,
            }),
            {},
          ),
        ),
      );
    } catch (error) {
      dispatch(actions.getProgramsByCountryError({ error }));
    }
  };

export const createProgram =
  (program: ProgramPayload): Thunk =>
  async dispatch => {
    dispatch(actions.createProgram());

    try {
      await programApiService.createProgram(program);
      dispatch(actions.createProgramSuccess());
      dispatch(getPrograms());
    } catch (error) {
      dispatch(actions.createProgramError({ error }));
    }
  };

export const updateProgram =
  (programId: string, program: ProgramPayload): Thunk =>
  async dispatch => {
    dispatch(actions.updateProgram());

    try {
      await programApiService.updateProgram(programId, program);
      dispatch(actions.updateProgramSuccess());
      dispatch(getPrograms());
    } catch (error) {
      dispatch(actions.updateProgramError({ error }));
    }
  };

export const syncProgram =
  (programId: string): Thunk =>
  async dispatch => {
    dispatch(actions.syncProgram());
    try {
      const response = await programApiService.syncProgram(programId);
      dispatch(actions.syncProgramSuccess(response.data));
      dispatch(getLocations(programId));
    } catch (error) {
      dispatch(actions.syncProgramError({ error }));
    }
  };
