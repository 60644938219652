import React, { useEffect, ElementType } from 'react';
import { shallowEqual } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { getProfile } from '../../../../store/account/account-actions';
import useProgressState from '../../../../hooks/use-progress-state';
import regex from '../../../../utils/regex';
import Form from '../../../../components/form';
import { ProfileFormModel, MultiFieldStepProps } from '../types';
import { fieldsConfig } from '../config';
import {
  StyledText,
  FieldsWrapper,
  StyledSpin,
  StyledInput,
  StyledTextArea,
} from '../styled';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

const MultiFieldStep = ({ formStep, setStepField }: MultiFieldStepProps) => {
  const dispatch = useAppDispatch();

  const { profile, gettingProfile } = useAppSelector(
    ({ account }) => ({
      profile: account.profile.entity,
      gettingProfile: account.profile.loading,
    }),
    shallowEqual,
  );

  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<ProfileFormModel>();

  const profileUpdate = useProgressState(gettingProfile);

  const fields = fieldsConfig[formStep](profile);

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch, formStep]);

  profileUpdate.onFinish(() => {
    fields.forEach(({ name, storedValue }) => {
      storedValue && !getValues(name) && setValue(name, storedValue);
    });
  });

  const linksRules = {
    validate: {
      invalidUrl: (url: string) => !url || regex.httpsUrl.test(url),
    },
  };

  const fieldRules = {
    links: linksRules,
    audience: undefined,
  }[formStep];

  const FieldElement: ElementType = {
    links: StyledInput,
    audience: StyledTextArea,
  }[formStep];

  return (
    <FieldsWrapper>
      {gettingProfile && <StyledSpin size="small" $align="topRight" />}

      {fields.map(({ name, label, height }) => (
        <Form.ItemController
          key={name}
          errors={errors}
          validateCharacters={!fieldRules}
          controller={{
            name,
            render: ({ field }) => (
              <>
                <StyledText $fontSize={12} $fontWeight={500}>
                  {label}
                </StyledText>
                <FieldElement
                  {...field}
                  data-testid={name}
                  height={height}
                  disabled={gettingProfile}
                  onFocus={() => setStepField(name)}
                />
              </>
            ),
            control,
            rules: fieldRules,
          }}
        />
      ))}
    </FieldsWrapper>
  );
};

export default MultiFieldStep;
