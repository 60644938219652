import React, { ReactElement, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'antd';

import { updateSteps } from '../../../../store/brand-user/brand-user-actions';
import Modal from '../../../../components/modal';
import { FeatureContainer, FeatureDescription, IconContainer } from './styled';
import { ReactComponent as NewUI } from './assets/new_ui.svg';
import { ReactComponent as LocationSync } from './assets/location_sync.svg';
import { ReactComponent as UserManagement } from './assets/user_management.svg';
import { GreyDropdownButton } from '../../../buttons';
import { OnboardingTourContext } from '../../onboarding-provider';
import { OnboardingStepTypes } from '../../onboarding-config';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

interface Feature {
  icon: ReactElement;
  description: string;
}

const features: Feature[] = [
  {
    icon: <NewUI />,
    description: 'newUi',
  },
  {
    icon: <LocationSync />,
    description: 'locationSync',
  },
  {
    icon: <UserManagement />,
    description: 'userManagement',
  },
];

export const OnboardingExistingUserWelcomeModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const userDetails = useAppSelector(state => state.user.details);
  const { setStep, toggleOnboardingOff } = useContext(OnboardingTourContext);

  const onClick = (skipsTour: boolean) => {
    if (skipsTour) {
      toggleOnboardingOff();
      setStep(OnboardingStepTypes.EXIT_TOUR);
    } else {
      dispatch(
        updateSteps(userDetails?.id as string, {
          dashboardExistingUser: true,
          dashboardWelcome: true,
        }),
      );
      setStep(OnboardingStepTypes.PROGRAMS);
    }
  };

  return (
    <Modal
      title={t('welcome.title')}
      maxWidth={530}
      visible
      description={
        <>
          <p>{t('welcome.subtitle')}</p>
          {features.map(({ icon, description }) => (
            <FeatureContainer key={description}>
              <IconContainer>{icon}</IconContainer>
              <FeatureDescription>
                <Trans
                  i18nKey={`welcome.${description}` as any}
                  components={[<b />]}
                />
              </FeatureDescription>
            </FeatureContainer>
          ))}
          <FeatureDescription>
            <Trans i18nKey="welcome.feedback" components={[<b />]} />
          </FeatureDescription>
        </>
      }
      buttons={[
        <Button
          type="primary"
          size="small"
          onClick={() => onClick(false)}
          data-testid="modal-button-0"
          key="modal-button-0"
        >
          {t('welcome.cta')}
        </Button>,
        <GreyDropdownButton
          onClick={() => onClick(true)}
          data-testid="modal-button-1"
          key="modal-button-1"
        >
          {t('welcome.skip')}
        </GreyDropdownButton>,
      ]}
    />
  );
};
