import React, { useState } from 'react';
import Tooltip, { TooltipPropsWithTitle } from 'antd/lib/tooltip';

interface ConditionalTooltipProps
  extends Omit<TooltipPropsWithTitle, 'visible' | 'onVisibleChange'> {
  condition: boolean;
}

const ConditionalTooltip = ({
  children,
  condition,
  ...tooltipProps
}: ConditionalTooltipProps) => {
  const [visible, setVisible] = useState(false);

  function onVisibleChange(newVisible: boolean) {
    setVisible(condition && newVisible);
  }

  return (
    <Tooltip
      {...tooltipProps}
      visible={condition && visible}
      onVisibleChange={onVisibleChange}
    >
      <span>{children}</span>
    </Tooltip>
  );
};

export default ConditionalTooltip;
