import styled from 'styled-components';

import { StyledSpin } from '../../spin';
import Empty from '../../empty';

export const Container = styled.div<{ bordered?: boolean }>`
  text-align: center;

  ${({ bordered }) =>
    bordered &&
    `
  ${StyledSpin} {
    padding-top: 20px;
  }
    
  ${Empty} {
    @media (min-height: 600px) {
      margin: 0 0 20px;
      svg {
        width: 100px;
        height: 100px;
      }
    }
  }
  `}
`;

export const ContentDelayMessage = styled.div`
  padding-top: 24px;
`;
