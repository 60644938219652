import React from 'react';
import { useTranslation } from 'react-i18next';

import { SchemeStatus } from '../../../store/locations/locations-model';
import { Active, RealTimeActive } from '../styled/card-scheme-status-tag';
import { ClickableTag, Caron } from '../../../components/tag/clickable-tag';
import ConditionalTooltip from '../../../components/conditional-tooltip';
import Ellipsis from '../../../components/ellipsis';

export enum RealTimeType {
  ACTIVE_TESTED = 'activeTested',
  REAL_TIME_ACTIVE_TESTED = 'realTimeActiveTested',
}

const colorsMap: { [status in SchemeStatus]: string } = {
  active: 'green',
  sync: 'blue',
  failed: 'red',
  delete: 'orange',
  inactive: 'default',
  ready: 'orange',
};

const StatusTag = ({
  status,
  realTime,
  clickable,
  days,
  ...props
}: {
  status: SchemeStatus;
  days?: number;
  realTime?: RealTimeType | null;
  clickable?: boolean;
  style?: React.CSSProperties;
}) => {
  const { t } = useTranslation('locations');

  const tooltipText = realTime ? t(`status.${realTime}.title`) : '';

  const displayDays = days !== undefined && days < 1 ? 0 : days;

  const renderActiveStatusIcon = () => {
    if (realTime === RealTimeType.ACTIVE_TESTED) {
      return <Active />;
    }

    if (realTime === RealTimeType.REAL_TIME_ACTIVE_TESTED) {
      return <RealTimeActive data-testid="rt-icon" />;
    }

    return null;
  };

  return (
    <ConditionalTooltip
      condition={!!realTime}
      mouseEnterDelay={0}
      placement="right"
      title={tooltipText}
      getPopupContainer={(trigger: any) => trigger.parentNode}
    >
      <ClickableTag
        data-testid="sync-status"
        color={colorsMap[status]}
        $clickable={clickable}
        {...props}
      >
        <span style={{ display: 'flex', alignItems: 'baseline' }}>
          <span>{renderActiveStatusIcon()}</span>
          <Ellipsis gutter={0}>
            {displayDays ?? t(`status.${status}.title`)}
          </Ellipsis>
          {clickable && <Caron style={{ left: 3, top: 1 }} />}
        </span>
      </ClickableTag>
    </ConditionalTooltip>
  );
};

export default StatusTag;
