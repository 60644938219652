import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import OnboardingPopover from '../components/onboarding-popover';
import { OnboardingTourContext } from '../onboarding-provider';
import { OnboardingStepTypes } from '../onboarding-config';

export const LocationsStep = () => {
  const { t } = useTranslation();

  const { completeOnboardingStep, setStep } = useContext(OnboardingTourContext);

  return (
    <OnboardingPopover
      placement="bottom"
      target="location-0-visa"
      index={1}
      title={t('onboarding.locations.title')}
      description={
        <span>
          <Trans
            i18nKey="onboarding.locations.description"
            components={[<strong />]}
          />
        </span>
      }
      buttonText={t('onboarding.buttons.next')}
      action={() => {
        completeOnboardingStep('dashboardLocations');
        setStep(OnboardingStepTypes.LOCATIONS_CLICK);
      }}
      showSkipTour
    />
  );
};
