import React, { ReactElement, ReactNode } from 'react';
import { Checkbox } from 'antd';

import { AlignSizeStyle, Highlight } from '../BaseTable';
import {
  Cell,
  CheckboxWrapper,
  IconWrapper,
  Row as StyledRow,
  RowContent,
} from '../styled/base';
import ActionsMenu from '../../actions-menu';
import showFirst from '../../../utils/show-first';
import Ellipsis from '../../ellipsis';

export type Actions = React.ComponentProps<typeof ActionsMenu>['actions'];

export interface RowData {
  key?: string;
  active?: boolean;
  contents: ReactNode[];
  actions?: Actions;
  selected?: boolean;
  onClick?: () => unknown;
  onSelect?: (e: any) => unknown;
  showEllipsis?: boolean;
  highlight?: Highlight;
  disabled?: boolean;
  style?: React.CSSProperties;
  padding?: boolean;
}

interface RowProps extends RowData {
  columnSettings: AlignSizeStyle[];
  forceRightPadding: boolean;
  icon?: ReactElement;
}

const Row = ({
  active,
  columnSettings,
  contents,
  actions,
  highlight,
  onClick,
  onSelect,
  selected,
  forceRightPadding,
  icon,
  showEllipsis,
  disabled = false,
  style,
  padding,
}: RowProps) => (
  <StyledRow
    data-testid="row"
    onClick={onClick}
    active={!!active}
    selected={!!selected}
    highlight={highlight}
    disabled={disabled}
    padding={padding}
    style={style}
  >
    {onSelect && (
      <CheckboxWrapper
        onClick={e => {
          // Click dead-zone to avoid clicking row
          e.stopPropagation();
        }}
      >
        <Checkbox
          data-testid="checkbox"
          checked={selected}
          onClick={e => {
            e.stopPropagation();
          }}
          onChange={e => onSelect(e.target.checked)}
        />
      </CheckboxWrapper>
    )}
    {contents.map((cell, j) => {
      const {
        align,
        verticalAlign,
        size,
        style: columnStyle,
      } = columnSettings[j];

      return (
        <Cell
          align={align}
          verticalAlign={verticalAlign || 'center'}
          size={size}
          key={j}
          style={columnStyle}
        >
          <RowContent hasIcon={!!icon}>
            {icon}
            {showEllipsis ? <Ellipsis showTooltip>{cell}</Ellipsis> : cell}
          </RowContent>
        </Cell>
      );
    })}

    {showFirst([
      actions && (
        <IconWrapper>
          <ActionsMenu actions={actions} />
        </IconWrapper>
      ),
      forceRightPadding && <IconWrapper />,
    ])}
  </StyledRow>
);

export default Row;
