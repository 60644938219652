import { Modal as AntdModal } from 'antd';
import styled from 'styled-components';

const FullScreenModal = styled(AntdModal).attrs(() => ({
  width: '100%',
  footer: null,
}))`
  &.ant-modal {
    top: 0;
    margin: 0 auto;
    border-radius: 0;
    padding-bottom: 0;
    overflow: hidden;
  }

  .ant-modal-header {
    position: fixed;
    z-index: 1;
    width: 100%;
    border-radius: 0;
  }

  .ant-modal-close-x {
    position: fixed;
    right: 16px;
    top: 20px;
  }

  .ant-modal-content {
    border-radius: 0;
    min-height: 100vh;
  }

  .ant-modal-footer {
    border-radius: 0;
  }

  .ant-modal-body {
    padding: 0;
    margin: 0;
  }
`;

export default FullScreenModal;
