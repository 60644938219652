import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { Switch } from 'antd';

import ConditionalTooltip from '../../conditional-tooltip';
import { setLive } from '../../../store/live/live-actions';
import AccountStatus from '../../account-status';
import memoNoProps from '../../../utils/memo-no-props';
import usePendingRequests from '../../../hooks/use-pending-requests';

import {
  Divider,
  LiveToggleContainer,
  LiveToggleSwitch,
  SwitchContainer,
} from '../styled';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

const LiveToggle = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [disabled, setDisabled] = useState(false);
  const dispatch = useAppDispatch();
  const { accountDetails, isLive } = useAppSelector(
    state => ({ accountDetails: state.account?.details, isLive: state.live }),
    shallowEqual,
  );
  const pendingRequests = usePendingRequests();

  const { liveActive } = accountDetails || {};

  function handleToggle() {
    if (liveActive) dispatch(setLive(!isLive));
    queryClient.removeQueries();
  }

  useEffect(() => {
    let timer: any;
    if (pendingRequests) {
      setDisabled(true);
    } else {
      timer = window.setTimeout(() => {
        setDisabled(false);
      }, 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [pendingRequests]);

  const switchComponent = (
    <SwitchContainer $disabled={disabled}>
      <Switch
        checked={isLive}
        onClick={handleToggle}
        disabled={disabled}
        data-testid="live-toggle"
      />
    </SwitchContainer>
  );

  return (
    <>
      <Divider />
      <LiveToggleContainer>
        {liveActive && (
          <LiveToggleSwitch>
            <span>{t('pageActions.toggle.label')}</span>
            <ConditionalTooltip
              condition={disabled}
              mouseEnterDelay={0}
              trigger="click"
              title={t('pageActions.toggle.pendingRequests')}
            >
              {switchComponent}
            </ConditionalTooltip>
          </LiveToggleSwitch>
        )}
        <AccountStatus />
      </LiveToggleContainer>
    </>
  );
};

export default memoNoProps(LiveToggle);
