import React, { ReactNode } from 'react';
import { shallowEqual } from 'react-redux';

import InlineForm from '../../../../components/inline-form';
import { useAppSelector } from '../../../../store/hooks';

interface ContactFormProps {
  onSubmit: (...args: any[]) => void;
  children?: ReactNode;
}

interface ContractFormComponentProps extends ContactFormProps {
  ItemController?: typeof InlineForm.ItemController;
}

const ContractForm = ({ onSubmit, children }: ContractFormComponentProps) => {
  const { updatingBillingConfigurations, error } = useAppSelector(
    state => ({
      updatingBillingConfigurations:
        state.account.billingConfigurations.updating,
      error: state.account.error,
    }),
    shallowEqual,
  );

  return (
    <InlineForm
      onSubmit={onSubmit}
      loading={updatingBillingConfigurations}
      error={error}
    >
      {children}
    </InlineForm>
  );
};

ContractForm.ItemController = InlineForm.ItemController;

export default ContractForm;
