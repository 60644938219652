import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { colors, fonts } from '../../theme';
import { InlineButton } from '../buttons';

export const CTAButton = styled(InlineButton)`
  display: block;
  padding: 0;
  height: auto;
  margin-top: 8px;
  :hover {
    background: none;
  }
`;

export const CTALink = styled.a.attrs(() => ({
  rel: 'noopener noreferrer',
  target: '_blank',
}))`
  display: block;
  margin-top: 8px;
  font-weight: 500;
`;

export const NavLinkBlock = styled(NavLink)`
  display: block;
  font-weight: 500;
`;

export const ServerMessage = styled.span`
  display: block;
  background: ${colors.lightgrey3};
  font-family: ${fonts.monospace};
  color: ${colors.black};
  padding: 8px;
  border-radius: 4px;
  margin: 10px 0;
`;
