import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import Avatar from '../../components/avatar';

import SignUpWithEmail from './SignUpWithEmail';
import VerifyEmail from './VerifyEmail';
import useSignUpParameters from './use-sign-up-parameters';

import {
  TitleWithMargin,
  Features,
  FeatureText,
  Customers,
  Divider,
  RecommendedMethodButton,
  SignIn,
  Left,
  Right,
} from './styled/sign-up';
import Logo from './components/Logo';
import { FormButton } from '../../components/buttons';
import { Subtitle } from './styled/sign-in';

import apiIcon from './icons/api.svg';
import realDataIcon from './icons/real.svg';
import sandboxIcon from './icons/sandbox.svg';
import rbcImage from './icons/rbc.svg';
import perkboxImage from './icons/perkbox.png';
import aviosImage from './icons/avios.png';
import googleImage from './icons/google.png';
import githubImage from './icons/github.png';
import { isFeatureEnabled } from '../../features';
import PageSplit from '../../components/page-split';
import showFirst from '../../utils/show-first';

const Feature = ({
  color,
  text,
  icon,
}: {
  color: string;
  icon: string;
  text: string;
}) => (
  <li>
    <div>
      <Avatar
        src={icon}
        shape="circle"
        style={{ backgroundColor: color, padding: '5px' }}
      />
    </div>
    <FeatureText>{text}</FeatureText>
  </li>
);

export const SignUp = () => {
  const { t } = useTranslation();
  const { code, invite, email } = useSignUpParameters();
  const goStraightToEmail =
    !isFeatureEnabled('socialSignIn') || !!(invite && email);
  const [signUpWithEmail, setSignUpWithEmail] = useState(goStraightToEmail);

  const signUpMethods = (
    <>
      <TitleWithMargin>{t('signUp.title')}</TitleWithMargin>
      <RecommendedMethodButton
        icon={<img alt="Google" src={googleImage} />}
        block
      >
        {t('signUp.methods.google')}
      </RecommendedMethodButton>
      <RecommendedMethodButton
        icon={<img alt="Github" src={githubImage} />}
        block
      >
        {t('signUp.methods.github')}
      </RecommendedMethodButton>
      <Divider>OR</Divider>
      <FormButton block onClick={() => setSignUpWithEmail(true)}>
        {t('signUp.methods.email')}
      </FormButton>
    </>
  );

  return (
    <PageSplit>
      <Left>
        <Logo />
        <TitleWithMargin>
          <Trans i18nKey="signUp.intro.title" />
        </TitleWithMargin>
        <Subtitle>
          <Trans i18nKey="signUp.intro.subtitle" />
        </Subtitle>
        <Features>
          <Feature
            icon={apiIcon}
            color="#26c6be"
            text={t('signUp.features.api')}
          />
          <Feature
            icon={sandboxIcon}
            color="#4b50e4"
            text={t('signUp.features.sandbox')}
          />
          <Feature
            icon={realDataIcon}
            color="#f69708"
            text={t('signUp.features.real')}
          />
        </Features>
        <Customers>
          <img alt="RBC" src={rbcImage} width="40px" height="auto" />
          <img alt="Google" src={googleImage} width="120px" height="auto" />
          <img alt="Avios" src={aviosImage} width="40px" height="auto" />
          <img alt="Perkbox" src={perkboxImage} width="80px" height="auto" />
        </Customers>
      </Left>
      <Right>
        {showFirst([
          code && email && <VerifyEmail code={code} email={email} />,
          signUpWithEmail && (
            <SignUpWithEmail
              goBack={
                goStraightToEmail ? undefined : () => setSignUpWithEmail(false)
              }
              email={email}
              invite={invite}
            />
          ),
          signUpMethods,
        ])}
        <SignIn>
          <Trans
            i18nKey="signUp.alreadyHaveAccount"
            components={[<NavLink to="/sign-in" />]}
          />
        </SignIn>
      </Right>
    </PageSplit>
  );
};

export default SignUp;
