import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { useLocation } from 'react-router';

import { sendGoLiveRequest } from '../../store/account/account-actions';
import { SendGoLiveRequestPayload } from '../../store/account/account-api-service';
import { getAccounts } from '../../store/moderator/moderator-actions';
import BillingForm from './BillingForm';
import BusinessForm from './BusinessForm';
import CompanyForm from './CompanyForm';
import LegalForm from './LegalForm';
import RegionForm from './RegionForm';
import Start from './Start';
import { GoLiveContainer, Steps } from './styled';
import { ReactComponent as CloseIcon } from '../../assets/x.svg';
import useGoLiveFormState, {
  GoLiveModel,
  Step,
} from './use-go-live-form-state';
import FullScreenModal from '../../components/full-screen-modal';

import ProgramForm from './ProgramForm';
import { SubmittedGoLiveModal } from './SubmittedGoLiveModal';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

interface GoLiveProps {
  onClose: () => unknown;
  visible: boolean;
}

export interface GoLiveFormProps {
  onClick: () => void;
  submitting?: boolean;
}

const GoLive = ({ onClose, visible }: GoLiveProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'account.goLive.steps' });
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { loading, error } = useAppSelector(
    state => ({ loading: state.account.loading, error: state.account.error }),
    shallowEqual,
  );
  const [submitting, setSubmitting] = useState(false);
  const [goLiveFormState, setGoLiveFormState] = useGoLiveFormState();

  const { form: localStorageForm, step: localStorageStep } =
    goLiveFormState ?? {};

  const form = useForm<GoLiveModel>({
    mode: 'onBlur',
    defaultValues: localStorageForm,
  });

  const currentStep = localStorageStep;

  function getFormValuesForSubmission(
    values: GoLiveModel,
  ): SendGoLiveRequestPayload {
    const result = {
      ...values,
      address: `${values.address} ${values.address2 ?? ''}`,
    };

    Object.entries(result).forEach(([key, value]) => {
      if (value?.length === 0) delete (result as any)[key];
    });

    delete result.address2;

    return result;
  }

  const getFormValues = useCallback(
    () =>
      ({
        ...goLiveFormState.form,
        ...form.getValues(),
      } as GoLiveModel),
    [form, goLiveFormState],
  );

  const goToNextStep = useCallback(
    async (step = 1) => {
      if (await form.trigger()) {
        const formValues = getFormValues();
        setGoLiveFormState({
          step: currentStep + step,
          form: formValues,
        });
        form.reset(formValues);
      }
    },
    [currentStep, form, getFormValues, setGoLiveFormState],
  );

  useEffect(() => {
    if (submitting && !loading) {
      setSubmitting(false);
      if (!error) {
        goToNextStep();
        if (pathname === '/accounts') {
          dispatch(getAccounts());
        }
      }
    }
  }, [submitting, loading, goToNextStep, error, pathname, dispatch]);

  async function submitForm() {
    if (await form.trigger()) {
      const formValues = getFormValuesForSubmission(getFormValues());
      dispatch(sendGoLiveRequest(formValues));
      setSubmitting(true);
    }
  }

  if (visible && (currentStep === Step.FINISH || currentStep === Step.REVIEW)) {
    return (
      <SubmittedGoLiveModal
        onClose={() => {
          setGoLiveFormState({
            form: {},
            step: Step.REVIEW,
          });
          onClose();
        }}
      />
    );
  }

  return (
    <>
      <FormProvider {...form}>
        <FullScreenModal
          visible={visible && currentStep !== Step.LIVE}
          onCancel={onClose}
          closeIcon={<CloseIcon />}
        >
          <GoLiveContainer fullWidth={currentStep === Step.START}>
            {![Step.START, Step.FINISH, Step.REVIEW].includes(currentStep) && (
              <Steps
                current={currentStep - 1}
                onChange={current => {
                  setGoLiveFormState({
                    ...goLiveFormState,
                    step: current + 1,
                  });
                }}
                size="small"
                direction="vertical"
              >
                {(
                  [
                    'region',
                    'company',
                    'business',
                    'program',
                    'legal',
                    'billing',
                  ] as const
                ).map((step, idx) => (
                  <Steps.Step
                    data-testid={
                      currentStep === idx + 1 ? 'active' : 'inactive'
                    }
                    key={step}
                    title={t(`${step}.stepTitle`)}
                    disabled={currentStep <= idx}
                  />
                ))}
              </Steps>
            )}
            {currentStep === Step.START && (
              <Start onClick={() => goToNextStep()} />
            )}
            {currentStep === Step.REGION && (
              <RegionForm onClick={() => goToNextStep()} />
            )}
            {currentStep === Step.COMPANY && (
              <CompanyForm onClick={() => goToNextStep()} />
            )}
            {currentStep === Step.BUSINESS && (
              <BusinessForm onClick={() => goToNextStep()} />
            )}
            {currentStep === Step.PROGRAM && (
              <ProgramForm onClick={() => goToNextStep()} />
            )}
            {currentStep === Step.LEGAL && (
              <LegalForm onClick={() => goToNextStep()} />
            )}
            {currentStep === Step.BILLING && (
              <BillingForm onClick={submitForm} submitting={submitting} />
            )}
          </GoLiveContainer>
        </FullScreenModal>
      </FormProvider>
    </>
  );
};

export default GoLive;
