import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox } from 'antd';

import { CreateUpdateOfferFormModel } from '../../../types';
import Form from '../../../../../components/form';
import DottedTooltip from '../../../components/DottedTooltip';
import { useAppSelector } from '../../../../../store/hooks';
import { Brand } from '../../../../../store/brands/brands-model';

interface SelfFundedCheckboxProps {
  isUpdating: boolean;
  isMarketplaceOffer: boolean;
  selectedBrand?: Brand;
}

export default function SelfFundedCheckbox({
  isUpdating,
  isMarketplaceOffer,
  selectedBrand,
}: SelfFundedCheckboxProps) {
  const { t } = useTranslation('offers');
  const isLive = useAppSelector(state => state.live);

  const { control, setValue, watch } =
    useFormContext<CreateUpdateOfferFormModel>();
  const brandIsAutoApproved = !!selectedBrand?.autoApproved;

  const [disableFundingDetails, setDisableFundingDetails] = useState(false);

  useEffect(() => {
    setDisableFundingDetails(brandIsAutoApproved);
    if (isLive) setValue('selfFunded', brandIsAutoApproved);
  }, [setValue, brandIsAutoApproved, isLive]);

  const disabled =
    !isLive || isUpdating || disableFundingDetails || isMarketplaceOffer;

  return (
    <Form.Item>
      <Controller
        name="selfFunded"
        render={({ field: { value, onChange, ...rest } }) => (
          <>
            <Checkbox
              {...rest}
              data-testid="self-funded-checkbox"
              onChange={({ target }) => onChange(target.checked)}
              checked={value}
              disabled={disabled}
            >
              {disableFundingDetails ? (
                <Trans
                  i18nKey="createUpdate.selfFunded.label"
                  ns="offers"
                  components={[
                    <DottedTooltip
                      tooltip={t('createUpdate.selfFunded.tooltip')}
                    />,
                  ]}
                />
              ) : (
                <Trans
                  i18nKey="createUpdate.selfFunded.label"
                  ns="offers"
                  components={[<span />]}
                />
              )}
            </Checkbox>
          </>
        )}
        control={control}
      />
      {!watch('selfFunded') && selectedBrand && !brandIsAutoApproved ? (
        <p style={{ marginBottom: 0 }}>
          {t('createUpdate.notifyBrand', { brand: selectedBrand?.name })}
        </p>
      ) : (
        <>{/* we need an empty element that is not null, to satisfy antd */}</>
      )}
    </Form.Item>
  );
}
