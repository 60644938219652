import styled from 'styled-components';
import { Avatar } from 'antd';

import { Check } from '../../../components/icons';
import { colors, fonts, translucentBorder } from '../../../theme';
import search from '../../../assets/search.svg';

export const Container = styled.div`
  position: relative;
  background: white;
  padding: 22px;
  transition: height 0.2s ease;

  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${colors.lightgrey2};
  border-radius: 4px;
  ${translucentBorder};

  &:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    top: -8px;
    border: 1px solid ${colors.lightgrey2};
    border-radius: 4px;
    ${translucentBorder};
    transform: rotate(45deg);
    background: white;
    z-index: 0;
  }

  &:after {
    content: '';
    position: absolute;
    width: 30px;
    height: 20px;
    top: 0;
    background: white;
    z-index: 0;
  }

  .emoji-mart {
    font-family: ${fonts.default};
    background: none;
    border: none;
    .emoji-mart-bar {
      display: none;
    }
    .emoji-mart-search {
      :before {
        content: url(${search});
        position: absolute;
        top: 5px;
        left: 10px;
      }
      input {
        border: 2px solid ${colors.grey2};
        border-radius: 4px;
        padding: 6px 28px;
        color: ${colors.grey4};
        ::placeholder {
          color: ${colors.grey4};
        }
      }
      .emoji-mart-search-icon {
        display: none;
      }
    }
    .emoji-mart-category-label {
      span {
        font-size: 12px;
        font-weight: normal;
        color: ${colors.grey5};
        padding-top: 10px;
      }
    }
    .emoji-mart-emoji {
      border-radius: 4px;
      span {
        cursor: pointer;
      }
      :before {
        background: none;
      }
      :hover,
      :focus {
        outline: none;
        background: ${colors.lightgrey2};
      }
    }
  }
`;

export const ProgramIcon = styled(Avatar).attrs(() => ({
  shape: 'square',
}))<{ $background: string; onClick?: () => void; size: number }>`
  position: relative;
  cursor: pointer;
  background: ${({ $background }) => $background};
  display: inline-block;

  &:hover {
    opacity: 0.8;
  }
`;

export const ColorPickerContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 10px;
`;

export const ColorPicker = styled.div<{
  $background: string;
  $borderColor: string;
  $selected: boolean;
}>`
  display: inline-flex;
  width: 24px;
  height: 24px;
  background: ${({ $background }) => $background};
  border: 1px solid ${({ $borderColor }) => $borderColor};
  border-radius: 50%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  ${Check} {
    display: ${({ $selected }) => ($selected ? 'initial' : 'none')};
    font-size: 14px;
    color: ${colors.grey4};
  }
  :hover {
    ${Check} {
      display: block;
    }
  }
`;

export const programIconColors = {
  background: {
    white: '#ffffff',
    red: '#fedcd7',
    yellow: '#fff59d',
    orange: '#ffe082',
    green: '#c8e6c9',
    blue: '#b3e5fc',
    purple: '#d1c4e9',
    pink: '#e1bee7',
    brown: '#d7ccc8',
    cobalt: '#cfd8dc',
  },
  border: {
    white: '#e6e8eb',
    red: '#ef9a9a',
    yellow: '#f4dd11',
    orange: '#ffca28',
    green: '#a5d6a7',
    blue: '#81d4fA',
    purple: '#b39ddb',
    pink: '#ce93d8',
    brown: '#b1b7bf',
    cobalt: '#b1b7bf',
  },
};
