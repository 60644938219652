import styled from 'styled-components';
import { colors, translucentBorder } from '../../theme';

const DropdownBox = styled.div`
  background: white;
  width: 310px;
  padding: 14px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border: 1px solid ${colors.lightgrey2};
  border-radius: 4px;
  ${translucentBorder};
`;

export const DropdownText = styled.div`
  font-size: 12px;
  color: ${colors.grey4};
`;

export default DropdownBox;
