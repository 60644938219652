import { Button } from 'antd';
import React, { useEffect, useState, ReactElement } from 'react';
import Confetti from 'react-dom-confetti';

import {
  ModalWrapper,
  ModalPanelWrapper,
  ModalLeftPanel,
  ModalCustom,
  ModalPanelActions,
  ModalPanelTitle,
  ConfettiWrapper,
  ModalPanelDescription,
  ModalPanelImage,
} from './styled';

export interface ModalProps {
  title: string;
  description: ReactElement;
  okText: string;
  onOk?: () => void;
  visible: boolean;
  image?: ReactElement;
  maxWidth?: number;
  confetti?: boolean;
  destroyOnClose?: boolean;
}

const confettiConfig = {
  angle: 270,
  spread: 190,
  startVelocity: 40,
  elementCount: 200,
  dragFriction: 0.12,
  duration: 3000,
  stagger: 3,
  width: '10px',
  height: '10px',
  perspective: '800px',
};

const AnnouncementModal = ({
  title,
  description,
  visible,
  image,
  maxWidth = 480,
  confetti = false,
  destroyOnClose,
  okText,
  onOk,
}: ModalProps) => {
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    let timer: number;

    if (confetti && visible) {
      timer = window.setTimeout(() => {
        setShowConfetti(true);
      }, 1000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [setShowConfetti, confetti, visible]);

  return (
    <>
      <ModalWrapper>
        <ConfettiWrapper>
          <Confetti config={confettiConfig} active={showConfetti} />
        </ConfettiWrapper>
        <ModalCustom
          title={null}
          footer={null}
          closable={false}
          visible={visible}
          zIndex={1020}
          maskStyle={{ zIndex: 1000 }}
          centered
          $maxWidth={maxWidth}
          $direction="horizontal"
          destroyOnClose={destroyOnClose}
        >
          <ModalPanelWrapper data-testid="modal-panel-wrapper">
            <ModalLeftPanel>
              <ModalPanelImage>{image}</ModalPanelImage>
              <ModalPanelTitle $centered>{title}</ModalPanelTitle>
              <ModalPanelDescription>{description}</ModalPanelDescription>
              <ModalPanelActions>
                <Button
                  type="primary"
                  onClick={() => onOk && onOk()}
                  size="small"
                  data-testid="modal-button-0"
                  key="modal-button-0"
                  block
                >
                  {okText}
                </Button>
              </ModalPanelActions>
            </ModalLeftPanel>
          </ModalPanelWrapper>
        </ModalCustom>
      </ModalWrapper>
    </>
  );
};

export default AnnouncementModal;
