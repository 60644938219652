import React from 'react';
import styled from 'styled-components';
import { Collapse as AntdCollapse } from 'antd';
import { Caron } from '../../../../../../components/icons';
import { InlineButton } from '../../../../../../components/buttons';
import DropdownBox from '../../../../../../components/dropdown-box';
import { colors } from '../../../../../../theme';
import { ReactComponent as Visa } from '../../../../../../assets/visa_icon.svg';

export const PlusIconContainer = styled.div`
  border-radius: 50%;
  height: 24px;
  width: 24px;
  color: ${colors.grey6};
  transition: background 0.2s ease-in;
`;

export const AddPeriod = styled(InlineButton)`
  background: none !important;
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${PlusIconContainer} {
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  }
  :hover {
    ${PlusIconContainer} {
      background: ${({ disabled }) => (disabled ? 'none' : colors.lightgrey7)};
    }
  }
`;

export const Cell = styled.span<{ highlighted: boolean; $bold?: boolean }>`
  color: ${({ highlighted }) => (highlighted ? colors.black : colors.grey4)};
  font-weight: ${({ $bold }) => ($bold ? 500 : 400)};
`;

export const TiersContainer = styled(DropdownBox)`
  width: auto;
  min-width: 170px;
`;

export const TierTitle = styled.p`
  font-size: 13px;
  font-weight: 500;
  color: ${colors.grey4};
  margin-bottom: 0;
`;

export const TierDescription = styled.p`
  margin-bottom: 6px;
  font-size: 14px;
`;

export const Initials = styled.div<{ color?: string }>`
  color: white;
  width: 24px;
  height: 24px;
  background: ${({ color }) => color || colors.grey4};
  display: inline-block;
  margin-right: 8px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 500;
  line-height: 2;
`;

export const StyledCustomCollapse = styled(AntdCollapse).attrs(() => ({
  bordered: false,
  expandIconPosition: 'right',
  expandIcon: ({ isActive }) => (
    <Caron
      style={{
        transform: `rotate(${isActive ? 180 : 0}deg)`,
        color: colors.grey4,
        marginTop: `${isActive ? '37' : '27'}px`,
      }}
    />
  ),
}))`
  background: white;

  .ant-collapse-content {
    padding: 0;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
    padding-bottom: 40px;
  }
  &.ant-collapse:last-of-type {
    .ant-collapse-item:last-of-type {
      border: none;
    }
  }
`;

export const VisaCardIcon = styled(Visa)`
  width: 48px;
  height: 32px;
  padding: 9px 5px;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
`;
