import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import StartIllustration from './StartIllustration';
import { breakpoints } from '../../theme';
import { IntroDescription } from './styled';

interface StartProps {
  onClick: () => unknown;
}

const Start = ({ onClick }: StartProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'account.goLive.steps' });

  return (
    <Container>
      <StartIllustration />
      <TextWrapper>
        <Text>
          <h2>{t('intro.title')}</h2>
          <IntroDescription>{t('intro.description')}</IntroDescription>
          <Button
            type="primary"
            onClick={onClick}
            style={{ marginTop: '14px' }}
          >
            {t('intro.cta')}
          </Button>
        </Text>
      </TextWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  min-height: 100vh;

  @media (min-width: ${breakpoints.tabletLandscape}px) {
    flex-flow: row nowrap;
  }
`;

const TextWrapper = styled.div`
  padding: 30px 20px 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${breakpoints.tabletPortrait}px) {
    padding: 20px 100px 100px;
  }

  @media (min-width: ${breakpoints.tabletLandscape}px) {
    padding-bottom: 20px;
  }
`;

const Text = styled.div`
  @media (min-width: ${breakpoints.tabletLandscape}px) {
    max-width: 438px;
  }
`;

export default Start;
