import { useCallback, useEffect, useState } from 'react';

export function useOnboardingTarget(target: string) {
  const targetQuery = useCallback(
    () => document.querySelector(`[data-onboarding-target="${target}"]`),
    [target],
  );

  const [targetElement, setTargetElement] = useState<HTMLElement>(targetQuery);

  useEffect(() => {
    const mutationObserver = new MutationObserver(() => {
      const newTargetElement = targetQuery();
      setTargetElement(newTargetElement);
    });

    mutationObserver.observe(document.body, {
      attributeFilter: ['data-onboarding-target'],
      childList: true,
      subtree: true,
    });

    return () => {
      mutationObserver.disconnect();
    };
  }, [targetQuery]);

  return targetElement;
}
