import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useFormContext } from 'react-hook-form';
import DetailTitle from '../../../../components/detail/components/DetailTitle';
import { ProgramDropdown } from '../../../../components/dropdowns';
import { SelectedRowsType } from '../../../../hooks/use-selected-rows';
import { Offer } from '../../../../store/offers/offers-model';
import { transformOfferToDetailTitle } from '../../utils';
import { Location } from '../../../../store/locations/locations-model';
import Spin from '../../../../components/spin';
import ManageLocationsBox from './components/ManageLocationsBox';
import { FormSteps as LocationsFormSteps } from '../../../brands/types';
import AddCreateLocations from './add-create-locations';
import { getAllLocations as getProgramLocations } from '../../../../store/locations/locations-actions';
import { Program } from '../../../../store/programs/programs-reducer';
import {
  getAllLocations,
  getProgramsWithLinkedLocations,
} from '../../../../store/offers';
import { SecondaryButton } from '../../../../components/buttons';
import Empty from './components/Empty';
import Link from '../../../../components/link';
import { CreateUpdateOfferFormModel } from '../../types';
import { Brand } from '../../../../store/brands/brands-model';
import { getUniqueBrandByBrandId } from '../../../../store/brands/brands-actions';
import { selectCountryProgramsByType } from '../../../../store/programs/programs-selectors';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

export interface ManageLocationFormProps {
  offer: Partial<Offer>;
  offerLocations: Location[];
  selectedLocations: SelectedRowsType<Location>;
}

export default function LocationsForm({
  offer,
  offerLocations,
  selectedLocations,
}: ManageLocationFormProps) {
  const { t } = useTranslation(['common', 'offers']);

  const dispatch = useAppDispatch();
  const eligiblePrograms = useAppSelector(selectCountryProgramsByType)(
    offer.countryCode,
  );
  const {
    programsWithLinkedLocations,
    brands,
    uniqueBrand,
    locationsByProgram,
    offerLinkedLocations,
  } = useAppSelector(({ programs, offers, locations, ...state }) => ({
    programsWithLinkedLocations:
      offers.programsWithLinkedLocations[offer.id ?? ''] ?? [],
    brands: state.brands.brands,
    uniqueBrand: state.brands.getUniqueBrandByBrandId.uniqueBrand,
    locationsByProgram: locations.locations,
    offerLinkedLocations: state.offer.locations,
  }));

  const { register, setValue } = useFormContext<CreateUpdateOfferFormModel>();

  const { id: offerId, brandId, countryCode } = offer;

  const brand = useMemo<Brand>(
    () => brands.find(({ id }) => id === brandId) ?? ({} as Brand),
    [brandId, brands],
  );

  const [locationsFormVisible, setLocationsFormVisible] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState<Program | undefined>(
    eligiblePrograms.length === 1 ? eligiblePrograms[0] : undefined,
  );

  const isLoadingLocations = [locationsByProgram, offerLinkedLocations].some(
    locationsState =>
      Object.values(locationsState).some(({ loading }) => loading),
  );

  const programLocations =
    locationsByProgram[selectedProgram?.id ?? '']?.items ?? [];

  useEffect(() => {
    if (offerId) dispatch(getAllLocations(offerId));
  }, [offerId, dispatch]);

  useEffect(() => {
    if (brand?.id) dispatch(getUniqueBrandByBrandId(brand.id));
  }, [brand?.id, dispatch]);

  useEffect(() => {
    if (selectedProgram) {
      register('programsToLinkLocationsFrom');
      setValue('programsToLinkLocationsFrom', [selectedProgram.id]);

      dispatch(
        getProgramLocations([selectedProgram], {
          brandId,
          countryCode,
        }),
      );
    }
  }, [selectedProgram, dispatch, register, setValue, brandId, countryCode]);

  useEffect(() => {
    if (offerId && programsWithLinkedLocations.length) {
      setSelectedProgram(
        eligiblePrograms.find(program =>
          programsWithLinkedLocations.includes(program.id),
        ),
      );
    }
  }, [offerId, eligiblePrograms, programsWithLinkedLocations]);

  useEffect(() => {
    const programsIds = eligiblePrograms.map(program => program.id);

    if (offerId && programsIds.length) {
      dispatch(getProgramsWithLinkedLocations(offerId, programsIds));
    }
  }, [dispatch, offerId, eligiblePrograms]);

  function renderManageLocations() {
    if (selectedProgram && programLocations.length === 0) {
      const initialLocationsFormStep =
        LocationsFormSteps[uniqueBrand ? 'ADD_LOCATIONS' : 'ADD_NEW_LOCATIONS'];

      return (
        <>
          <Empty
            illustration="locations"
            message={t(
              'offers:createUpdate.locations.infoBox.noLinkedLocations',
            )}
          >
            <SecondaryButton
              style={{ marginTop: 22 }}
              onClick={() => setLocationsFormVisible(true)}
            >
              {t('common:addNew.location')}
            </SecondaryButton>
          </Empty>

          <AddCreateLocations
            offer={offer}
            visible={locationsFormVisible}
            setVisible={setLocationsFormVisible}
            initialFormStep={initialLocationsFormStep}
            selectedProgram={selectedProgram}
            selectedBrand={brand}
            selectedUniqueBrand={uniqueBrand}
          />
        </>
      );
    }

    if (selectedProgram && programLocations.length) {
      return (
        <ManageLocationsBox
          offerLocations={offerLocations}
          programLocations={programLocations}
          selectedLocations={selectedLocations}
        />
      );
    }

    return null;
  }

  return (
    <>
      <DetailTitle
        {...transformOfferToDetailTitle({
          ...offer,
          brandLogoURL: brand.logoURL,
          brandName: brand.name,
        })}
      />

      {eligiblePrograms.length ? (
        <>
          <ProgramDropdown
            formItemProps={{
              label: t('offers:createUpdate.locations.program.default'),
              style: { marginTop: 16, marginBottom: 14 },
            }}
            selector={() => eligiblePrograms}
            programType="transaction-select"
            onChange={setSelectedProgram}
            value={selectedProgram?.id}
            defaultValue={selectedProgram?.id}
          />

          {isLoadingLocations ? (
            <Spin type="button" />
          ) : (
            renderManageLocations()
          )}
        </>
      ) : (
        <Empty
          message={
            <Trans
              i18nKey="createUpdate.locations.program.empty"
              ns="offers"
              values={{ countryCode: offer.countryCode }}
              components={{
                'support-contact': <Link to="mailto:cs-team@fidel.uk" />,
              }}
            />
          }
        />
      )}
    </>
  );
}
