import React, { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom-v5-compat';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { updateSteps } from '../../store/brand-user/brand-user-actions';

export const surveyTypes = ['NPS', 'CSAT'] as const;

type SurveyType = typeof surveyTypes[number];

export const npsFrequency = 3;
export const csatFrequency = 6;

function getSurveyStepKey(
  survey: SurveyType,
): 'npsFormShown' | 'csatFormShown' | undefined {
  return survey === 'NPS'
    ? 'npsFormShown'
    : survey === 'CSAT'
    ? 'csatFormShown'
    : undefined;
}

function randomNumberFromRange(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function isMoreThanXMonthsAgo(date: string | undefined, survey: SurveyType) {
  if (!date) return false;

  const monthsAgo = new Date();
  monthsAgo.setMonth(
    monthsAgo.getMonth() - (survey === 'NPS' ? npsFrequency : csatFrequency),
  );
  return new Date(date) < monthsAgo;
}

const AnalyticsForm = ({ survey }: { survey: SurveyType }) => {
  const dispatch = useAppDispatch();
  const userDetails = useAppSelector(state => state.user.details);
  const [searchParams, setSearchParams] = useSearchParams();

  const { id: userId, stepsCompleted } = userDetails || {};

  const surveyKey = getSurveyStepKey(survey);
  const surveyDate = surveyKey && stepsCompleted?.[surveyKey];

  const updateSurveyStepsCompleted = useCallback(() => {
    if (!surveyKey || !userId) return;

    if (!surveyDate) {
      const currentMonth = new Date().getMonth() + 1; // Adding 1 because months are zero-based

      const randomMonth = randomNumberFromRange(1, 6);
      const randomDay = randomNumberFromRange(1, 28); // Because all months have 28 days

      const randomDate = new Date();
      randomDate.setMonth(currentMonth + randomMonth, randomDay);

      dispatch(
        updateSteps(userId, {
          [surveyKey]: randomDate.toISOString(),
        }),
      );
      return;
    }

    dispatch(updateSteps(userId, { [surveyKey]: new Date().toISOString() }));
  }, [dispatch, userId, surveyKey, surveyDate]);

  useEffect(() => {
    if (!surveyKey || !userId) return;

    if (!surveyDate) {
      updateSurveyStepsCompleted();
      return;
    }

    if (!isMoreThanXMonthsAgo(surveyDate, survey)) return;

    // Append survey key to URL, showing the current survey form
    setSearchParams(() => {
      const params = new URLSearchParams();
      params.append(survey, 'true');
      return params.toString();
    });
  }, [
    userId,
    surveyKey,
    setSearchParams,
    survey,
    updateSurveyStepsCompleted,
    surveyDate,
  ]);

  useEffect(() => {
    if (!isMoreThanXMonthsAgo(surveyDate, survey) && searchParams.has(survey)) {
      // Remove previous survey from URL, hiding the previous survey
      setSearchParams(prev => {
        const params = new URLSearchParams(prev);
        params.delete(survey);
        return params.toString();
      });
    }
  }, [searchParams, setSearchParams, survey, surveyDate]);

  useEffect(() => {
    if (isMoreThanXMonthsAgo(surveyDate, survey)) updateSurveyStepsCompleted();
  }, [survey, updateSurveyStepsCompleted, surveyDate]);

  return null;
};

export const AnalyticsForms = () => (
  <>
    {surveyTypes.map(survey => (
      <AnalyticsForm key={survey} survey={survey} />
    ))}
  </>
);
