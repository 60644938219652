import React, { useEffect, useState } from 'react';
import { range, sortBy } from 'lodash';

import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-json';
import './prism-fidel.css';

import { Code, Editor, LineNumberWrapper } from './styled';

function sortObjectAlphabetically(item: any): any {
  if (typeof item === 'object' && item !== null) {
    if (Array.isArray(item))
      return item.map((value: any) => sortObjectAlphabetically(value));

    const ordered: any = {};
    Object.keys(item)
      .sort()
      .forEach(key => {
        ordered[key] = sortObjectAlphabetically(item[key]);
      });

    return ordered;
  }
  return item;
}

export interface JsonProps {
  body: any;
  onChange?: (value: string) => unknown;
  readOnly?: boolean;
}

const JsonPreview = ({
  body = '',
  onChange = () => {},
  readOnly,
  ...props
}: JsonProps) => {
  const [jsonString, setJsonString] = useState('');
  const [lines, setLines] = useState<string[]>([]);

  useEffect(() => {
    const json =
      typeof body === 'string'
        ? body
        : JSON.stringify(sortObjectAlphabetically(body), undefined, 2);

    setLines(json.split('\n'));
    setJsonString(json);
  }, [body]);

  const LineNumbers = () => {
    const lineCount = lines.length;

    return (
      <LineNumberWrapper>
        {range(1, lineCount + 1).map(lineNumber => (
          <span key={lineNumber}>{lineNumber}</span>
        ))}
      </LineNumberWrapper>
    );
  };

  function getContentWidth() {
    /* "width: fit-content" is not wide enough for the ghost textarea to expand
     * to the whole width of the highlighted code block, so we manually check
     * what the approx. width of the longest line will be. */
    const maxLineWidth = sortBy(lines, line => -line.length)[0]?.length ?? 0;
    return `${maxLineWidth * 0.55}rem`;
  }

  return (
    <Code contentWidth={getContentWidth()} {...props}>
      <LineNumbers />
      <Editor
        value={jsonString}
        onValueChange={code => {
          setJsonString(code);
          onChange(code);
        }}
        highlight={code => highlight(code, languages.json, 'json')}
        padding={7}
        data-testid={readOnly ? 'res-body' : 'req-body'}
        readOnly={readOnly}
      />
    </Code>
  );
};

export default JsonPreview;
