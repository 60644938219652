import styled from 'styled-components';

export const NoLocationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  h1 {
    font-size: 16px;
    margin-bottom: 20px;
  }
`;
