import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'antd';
import styled from 'styled-components';

import { TransactionStreamPeriod } from '../../../../../store/account/account-model';
import { Cell, VisaCardIcon } from './styled';
import BillingPeriods, { useShowForm } from './BillingPeriodsTables';
import CreateUpdateTSPeriod from '../../create-update-billing-period/transaction-stream';
import { ReactComponent as VisaInline } from '../../../../../assets/visa_icon.svg';
import { ReactComponent as Mastercard } from '../../../assets/mastercard_icon.svg';
import { toDecimal, withCurrency } from '../../../../../utils/transform';
import {
  ClickableTag,
  Caron,
} from '../../../../../components/tag/clickable-tag';
import DropdownBox from '../../../../../components/dropdown-box';
import { colors } from '../../../../../theme';
import { useAppSelector } from '../../../../../store/hooks';
import { BillingPeriodsProps } from './SelectTransactionBillingPeriods';

type SchemeFeesProps =
  TransactionStreamPeriod['cardPricing']['cardVerifiedFee'] & {
    currency: string | undefined;
  };
const SchemeFees = ({ visa, mastercard, currency }: SchemeFeesProps) => {
  const overlay = (
    <SchemeFeesDropdown>
      <div>
        <VisaCardIcon />
        {withCurrency(visa || 0, currency, true, 'code')}
      </div>
      <div>
        <Mastercard />
        {withCurrency(mastercard || 0, currency, true, 'code')}
      </div>
    </SchemeFeesDropdown>
  );

  return (
    <Dropdown
      trigger={['click']}
      overlay={overlay}
      getPopupContainer={(trigger: any) => trigger.parentNode}
    >
      <ClickableTag $clickable color="default">
        <VisaInline />
        <SchemeFeeAmount>{toDecimal(visa || 0)}</SchemeFeeAmount>
        <Caron style={{ left: 3 }} />
      </ClickableTag>
    </Dropdown>
  );
};

const TransactionStreamBillingPeriods = ({
  showingPeriod,
  currentInvoice,
}: BillingPeriodsProps) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'account.contract.billingPeriods',
  });
  const { t: tPrograms } = useTranslation('programs');

  const billingConfigurations = useAppSelector(
    state => state.account.billingConfigurations.entity,
  );
  const { currency, transactionStreamPricing } = billingConfigurations || {};
  const { periods } = transactionStreamPricing || {};

  const form = useShowForm<TransactionStreamPeriod>();
  const { formVisible, formMode, formPeriod, closeForm } = form;

  const columns = [
    { heading: t('table.transactionStream.minFee'), size: 0.8 },
    { heading: t('table.transactionStream.verifiedCardFee'), size: 1.2 },
    { heading: t('table.transactionStream.verificationAttemptFee'), size: 1.2 },
  ];

  function periodToRow(
    period: TransactionStreamPeriod,
    isCurrentInvoice: boolean,
  ) {
    const { minimumFee, cardPricing } = period;

    return [
      <Cell $bold highlighted={isCurrentInvoice}>
        {withCurrency(minimumFee, currency, false, 'code')}
      </Cell>,
      <SchemeFees currency={currency} {...cardPricing.cardVerifiedFee} />,
      <Cell $bold highlighted={isCurrentInvoice}>
        {withCurrency(cardPricing.cardVerificationFee, currency, true, 'code')}
      </Cell>,
    ];
  }

  return (
    <>
      <BillingPeriods
        {...form}
        color={colors.product.transactionStream}
        columns={columns}
        rowGenerator={periodToRow}
        accountPeriods={periods}
        periodKey="transactionStreamPricing"
        title={{
          text: tPrograms('programType.transaction-stream.label'),
        }}
        showingPeriod={showingPeriod}
        currentInvoice={currentInvoice}
      />
      <CreateUpdateTSPeriod
        selectedPeriod={formPeriod}
        visible={formVisible}
        mode={formMode}
        onClose={() => {
          closeForm();
        }}
      />
    </>
  );
};

const SchemeFeeAmount = styled.span`
  margin-left: 10px;
`;

const SchemeFeesDropdown = styled(DropdownBox)`
  width: auto;
  min-width: 170px;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  div:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export default TransactionStreamBillingPeriods;
