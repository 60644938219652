import http from '../../services/http-service';
import { ProgramType } from './programs-reducer';

export interface ProgramPayload {
  name: string;
  icon: string;
  iconBackground: string;
  type?: ProgramType;
}

class ProgramApiService {
  public async getProgram(programId: string) {
    return http.get(`programs/${programId}`);
  }

  public async getPrograms(options?: any) {
    return http.get('programs', {
      params: {
        projection: 'id,name,active,sync,created,icon,iconBackground,type',
        ...options,
      },
    });
  }

  public async createProgram(program: ProgramPayload) {
    return http.post('programs', program);
  }

  public async updateProgram(programId: string, program: ProgramPayload) {
    return http.patch(`programs/${programId}`, program);
  }

  public async syncProgram(programId: string) {
    return http.post(`programs/${programId}/sync`, {});
  }
}

export default ProgramApiService;
