import React from 'react';

interface TextHighlighterProps {
  text: string;
  searchTerm: string;
  highLightStyle?: string;
}

const TextHighlighter = ({
  text,
  searchTerm,
  highLightStyle,
}: TextHighlighterProps) => (
  <span>
    {searchTerm
      ? text.split(new RegExp(`(${searchTerm})`, 'gi')).map((chunk, index) =>
          chunk.toLowerCase() === searchTerm.toLowerCase() ? (
            <mark
              key={`${text}-${index}`}
              className={highLightStyle || undefined}
              style={{ fontWeight: 'bold', backgroundColor: 'yellow' }}
            >
              {chunk}
            </mark>
          ) : (
            chunk
          ),
        )
      : text}
  </span>
);

export default TextHighlighter;
