import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import Modal from '../../../../components/modal';
import { OffersMarketplaceOnboardingContext } from '../context/OffersMarketplaceOnboardingContext';
import { OfferMarketplaceOnboardingStepTypes } from '../config';
import { ReactComponent as StarRowItemsIllustration } from '../assets/star-row-items.svg';

export const WelcomeStep = () => {
  const { t } = useTranslation('offers');

  const { completeOnboardingStep, setStep } = useContext(
    OffersMarketplaceOnboardingContext,
  );

  return (
    <Modal
      title={t('marketplace.onboarding.welcomeStep.title')}
      maxWidth={424}
      visible
      description={
        <>
          <p style={{ fontSize: 12 }}>
            {t('marketplace.onboarding.welcomeStep.description')}
          </p>
        </>
      }
      buttons={[
        <Button
          type="primary"
          size="small"
          onClick={() => {
            completeOnboardingStep('dashboardOffersMarketplaceWelcome');
            setStep(OfferMarketplaceOnboardingStepTypes.MARKETPLACE_OFFER);
          }}
          data-testid="modal-button-0"
          key="modal-button-0"
          block
        >
          {t('marketplace.onboarding.welcomeStep.button')}
        </Button>,
      ]}
      direction="vertical"
      illustration={<StarRowItemsIllustration />}
      buttonsWrapperStyle={{
        width: '100%',
      }}
      rightPanelStyle={{
        background: 'unset',
        paddingTop: '32px',
      }}
    />
  );
};
