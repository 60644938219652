import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Radio } from 'antd';

import Form from '../../../components/form';
import { ProgramTypeRadio } from '../styled/select-program-type';
import Avatar from '../../../components/avatar';
import { colors } from '../../../theme';
import { programTypes } from '../../../store/programs/programs-reducer';

const SelectProgramType = () => {
  const { t } = useTranslation('programs');

  const { control } = useFormContext();

  return (
    <Form.ItemController
      controller={{
        name: 'programType',
        render: ({ field }) => (
          <Radio.Group {...field} defaultValue="transaction-select">
            {programTypes.map(type => (
              <ProgramTypeRadio
                key={type}
                value={type}
                $active={type === field.value}
              >
                <header>
                  {t(`programType.${type}.label`)}
                  <Avatar
                    size={24}
                    textFormat="all"
                    shape="circle"
                    color={{
                      background:
                        type === 'transaction-stream'
                          ? colors.product.transactionStream
                          : colors.product.selectTransaction,
                    }}
                  >
                    {t(`programType.${type}.label`)}
                  </Avatar>
                </header>
                <p>{t(`programType.${type}.description`)}</p>
              </ProgramTypeRadio>
            ))}
          </Radio.Group>
        ),
        rules: { required: true },
        control,
      }}
    />
  );
};

export default SelectProgramType;
