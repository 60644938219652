import React from 'react';
import {
  Route,
  Redirect,
  Switch,
  RouteProps as ReactRouterRouteProps,
} from 'react-router';
import { CompatRouter } from 'react-router-dom-v5-compat';

import Accounts from '../modules/moderator';
import Account from '../modules/account';
import Brands from '../modules/brands';
import Cards from '../modules/cards';
import Offers from '../modules/offers';
import Playground from '../modules/playground';
import Programs from '../modules/programs';
import Locations from '../modules/locations';
import Transactions from '../modules/transactions';
import AccountLocked from '../modules/account-locked';
import Webhooks from '../modules/webhooks';
import memoNoProps from '../utils/memo-no-props';
import useIsSignedIn from '../hooks/use-is-signed-in';
import { useAppSelector } from '../store/hooks';
import useAccountLockedGuard from '../hooks/use-account-locked-guard';
import useIsAccountLocked from '../hooks/useIsAccountLocked';

function isSubpath(path: string) {
  return path.match(/\/[\w-]+\/[\w-]+/);
}

const defaultRoute = '/programs';
const defaultModeratorRoute = '/accounts';

interface RouteProps extends ReactRouterRouteProps {
  component: React.ComponentClass<any> | React.FunctionComponent<any>;
}

interface RouteOrRedirectProps extends RouteProps {
  condition: boolean;
  redirectPath: string;
}

const RouteOrRedirect = ({
  component: Component,
  condition,
  redirectPath,
  ...routeProps
}: RouteOrRedirectProps) => (
  <Route
    {...routeProps}
    render={({ location }) =>
      condition ? (
        <Component />
      ) : (
        <Redirect
          to={{
            pathname: redirectPath,
            state: { from: location },
          }}
        />
      )
    }
  />
);

const PrivateRoute = ({ component: Component, ...routeProps }: RouteProps) => {
  const { isModerator } = useIsSignedIn();
  const hasAccount = useAppSelector(state => !!state.account.details);
  const isAccountLocked = useIsAccountLocked();

  useAccountLockedGuard(isAccountLocked);

  const hasAccountLoaded = !isModerator || hasAccount;

  return (
    <RouteOrRedirect
      condition={hasAccountLoaded}
      redirectPath={defaultModeratorRoute}
      component={Component}
      {...routeProps}
    />
  );
};

const PrivateRoutes = () => {
  const { isModerator } = useIsSignedIn();
  const isAccountLocked = useIsAccountLocked();
  const isLive = useAppSelector(state => state.live);

  return (
    <CompatRouter>
      <Switch>
        <Route
          exact
          path="/"
          render={({ location }) => {
            if (location.hash) {
              const redirectPath = location.hash.toString().slice(1);
              return <Redirect to={redirectPath} />;
            }
            return <Redirect to={isModerator ? '/accounts' : defaultRoute} />;
          }}
        />
        {isModerator && <Route path="/accounts" component={Accounts} />}
        <PrivateRoute exact path="/programs" component={Programs} />
        <PrivateRoute exact path="/brands" component={Brands} />
        <PrivateRoute exact path="/locations" component={Locations} />
        <PrivateRoute exact path="/transactions" component={Transactions} />
        <PrivateRoute exact path="/cards" component={Cards} />
        <PrivateRoute exact path="/account" component={Account} />
        {isAccountLocked && (
          <PrivateRoute
            exact
            path="/account-locked"
            component={AccountLocked}
          />
        )}
        <PrivateRoute exact path="/account/:section" component={Account} />
        <PrivateRoute exact path="/webhooks" component={Webhooks} />
        <PrivateRoute exact path="/offers/:type" component={Offers} />
        {!isLive && (
          <PrivateRoute exact path="/playground" component={Playground} />
        )}
        <Route
          render={props => {
            const { pathname } = props.location;
            if (isSubpath(pathname)) {
              const basePath = pathname.substr(1).split('/')[0];
              return <Redirect to={`/${basePath}`} />;
            }
            return (
              <Redirect
                to={isModerator ? defaultModeratorRoute : defaultRoute}
              />
            );
          }}
        />
      </Switch>
    </CompatRouter>
  );
};

export default memoNoProps(PrivateRoutes);
