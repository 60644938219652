import React from 'react';
import { Col, InputNumber, Row } from 'antd';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Form from '../../../../../components/form';
import { ContentProviderOfferFormModel } from '../../../types';
import DiscountType from './DiscountType';
import { OfferFormProps } from '..';

interface GrossComissionInputProps {
  isUpdating: OfferFormProps['isUpdating'];
}

function GrossComissionInput({ isUpdating }: GrossComissionInputProps) {
  const { t } = useTranslation('offers');
  const {
    formState: { errors },
    control,
  } = useFormContext<ContentProviderOfferFormModel>();

  return (
    <Row justify="space-between">
      <Col xs={{ span: 15 }}>
        <Form.ItemController
          label={t('fields.reward.label')}
          validateStatus={errors.grossCommission && 'error'}
          errors={errors}
          controller={{
            name: 'grossCommission',
            control,
            rules: {
              required: true,
            },
            render: ({ field }) => (
              <InputNumber
                {...field}
                data-testid="grossCommission"
                placeholder={t('createUpdate.enterValue')}
              />
            ),
          }}
        />
      </Col>

      <Col xs={{ offset: 1, span: 8 }}>
        <DiscountType disabled={isUpdating} />
      </Col>
    </Row>
  );
}

export default GrossComissionInput;
