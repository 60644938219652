import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { supportedCountries } from '../../../../../utils/countries';
import Form from '../../../../../components/form';
import { CreateUpdateOfferFormModel } from '../../../types';
import createUpdateOfferRules from '../../validation';
import { getProgramsByCountry } from '../../../../../store/programs/programs-actions';
import { Select } from '../../../../../components/select';
import { useAppDispatch } from '../../../../../store/hooks';

interface CountrySelectProps {
  isUpdating: boolean;
}

export default function CountrySelect({ isUpdating }: CountrySelectProps) {
  const { t } = useTranslation('offers');
  const dispatch = useAppDispatch();

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<CreateUpdateOfferFormModel>();

  const countryOptions = supportedCountries.map(({ name, code }) => (
    <Select.Option key={code} value={code}>
      {name}
    </Select.Option>
  ));

  const selectedCountry = watch('country');

  useEffect(() => {
    dispatch(getProgramsByCountry([selectedCountry]));
  }, [dispatch, selectedCountry]);

  return (
    <Form.ItemController
      label={t('fields.country.label')}
      errors={errors}
      controller={{
        name: 'country',
        render: ({ field }) => (
          <Select
            {...field}
            data-name="country"
            disabled={isUpdating}
            style={{ width: '100%' }}
            placeholder={t('fields.country.placeholder')}
          >
            {countryOptions}
          </Select>
        ),
        rules: createUpdateOfferRules.country,
        control,
      }}
    />
  );
}
