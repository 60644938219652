import React, { useState, useMemo } from 'react';
import { Button, Modal, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import CreateUpdateWebhook from './components/CreateUpdateWebhook';
import useSelectedProgram from '../../hooks/use-selected-program';
import useOnLiveChange from '../../hooks/use-on-live-change';
import useCopyWithNotification from '../../hooks/use-copy-with-notification';
import memoNoProps from '../../utils/memo-no-props';
import InfiniteScrollTable from '../../components/tables/InfiniteScrollTable';
import { Column, RowType } from '../../components/tables/BaseTable';
import Header from '../../components/header';
import Ellipsis from '../../components/ellipsis';
import useRawDetail from '../../components/raw-detail/hooks/use-raw-detail';
import CopyableTag from '../../components/tag/copyable-tag';
import { useWebhooks } from './api/useWebhooks';
import { useDeleteWebhook } from './api/useDeleteWebhook';
import { Webhook } from './api/types';

const Webhooks = () => {
  const { t } = useTranslation();

  const { openRawDetail, rawDetail } = useRawDetail('hooks');
  const copyWithNotification = useCopyWithNotification();
  const [creatingWebhook, setCreatingWebhook] = useState(false);
  const [updatingWebhook, setUpdatingWebhook] = useState<any>(null);
  const { selectedProgram } = useSelectedProgram();
  const programId = selectedProgram?.id;

  const {
    isLoading,
    data,
    refetch,
    isFetching,
    isRefetching,
    fetchNextPage,
    hasNextPage,
    remove,
  } = useWebhooks(programId);

  const deleteWebhook = useDeleteWebhook(programId);

  useOnLiveChange(() => remove());

  const columns: Column[] = [
    {
      heading: t('webhooks.headings.event'),
      size: 0.5,
      sortedBy: 'unset',
    },
    { heading: t('webhooks.headings.secretKey'), size: 0.5 },
    { heading: t('webhooks.headings.url'), size: 0.5 },
    { heading: t('webhooks.headings.created'), size: 0.3 },
  ];

  const rows: RowType[] = useMemo(() => {
    if (isLoading || !data) return [];

    const transformedRows = data.pages.flatMap(page =>
      page.items.map((webhook: Webhook) => ({
        onClick: openRawDetail(webhook.id),
        contents: [
          <Ellipsis>{webhook.event}</Ellipsis>,
          <CopyableTag
            text={webhook.secretKey}
            successMessage={t('copyable.copied')}
            width="150px"
          />,
          <Ellipsis gutter={30} showTooltip>
            {webhook.url}
          </Ellipsis>,
          <Typography.Text type="secondary">
            {moment(webhook.created).fromNow()}
          </Typography.Text>,
        ],
        actions: [
          {
            label: t('webhooks.actions.edit'),
            callback: () => setUpdatingWebhook(webhook),
          },
          {
            label: t('actions.viewRaw'),
            callback: openRawDetail(webhook.id),
          },
          {
            label: t('actions.copyId'),
            callback: () => copyWithNotification(webhook.id),
          },
          {
            label: t('webhooks.actions.delete'),
            callback: () =>
              Modal.confirm({
                title: t('webhooks.delete.title'),
                content: t('webhooks.delete.message'),
                onOk: () => deleteWebhook.mutate(webhook.id),
                okButtonProps: { danger: true },
                maskClosable: true,
                icon: null,
              }),
          },
        ],
      })),
    );
    return transformedRows;
  }, [isLoading, data, deleteWebhook, copyWithNotification, openRawDetail, t]);

  const topContent = (
    <Header
      heading={t('webhooks.title')}
      topRight={
        <Button
          type="primary"
          size="small"
          onClick={() => setCreatingWebhook(true)}
        >
          {t('addNew.webhook')}
        </Button>
      }
    />
  );

  return (
    <>
      <InfiniteScrollTable
        columns={columns}
        rows={rows}
        onReload={refetch}
        onBottom={() => {
          if (hasNextPage) fetchNextPage();
        }}
        loading={isFetching}
        refetching={isRefetching}
        topContent={topContent}
        emptyText={t('webhooks.empty')}
        showBottomPadding
      />
      {rawDetail}
      <CreateUpdateWebhook
        visible={creatingWebhook}
        onClose={() => setCreatingWebhook(false)}
      />
      <CreateUpdateWebhook
        visible={!!updatingWebhook}
        webhook={updatingWebhook}
        onClose={() => setUpdatingWebhook(null)}
      />
    </>
  );
};

export default memoNoProps(Webhooks);
