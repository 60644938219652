import styled from 'styled-components';
import { Divider as AntdDivider, Button, Checkbox } from 'antd';
import { colors } from '../../../theme';
import PageSplit from '../../../components/page-split';

export const Title = styled.h2`
  font-weight: normal;
  line-height: 1.75em;
`;

export const TitleWithMargin = styled(Title)`
  margin-top: 40px;

  ${PageSplit.isColumn} {
    margin-top: 20px;
  }
`;

export const Left = styled(PageSplit.Left)`
  ${PageSplit.isRow} {
    padding-top: 30px;
  }

  ${PageSplit.isColumn} {
    padding-bottom: 30px;
  }
`;

export const Right = styled(PageSplit.Right)`
  ${PageSplit.isRow} {
    padding-top: 90px;
  }

  ${PageSplit.isColumn} {
    padding-top: 30px;
  }
`;

export const Subtitle = styled.h3`
  margin-top: 20px;
  font-weight: normal;
  line-height: 1.75em;
`;

export const Features = styled.ul`
  margin-top: 50px;
  margin-bottom: 60px;
  list-style-type: none;
  padding-left: 0;

  > li {
    margin-bottom: 1.5em;
    display: flex;
  }
`;

export const FeatureText = styled.div`
  margin-left: 1em;
  color: ${colors.grey5};
`;

export const Customers = styled.div`
  max-width: 18rem;
  align-items: center;
  display: flex;
  justify-content: space-between;

  > img {
    margin: 0 1rem;

    &:first-child {
      margin-left: 0;
    }
  }
`;

export const Divider = styled(AntdDivider)`
  color: #6c788b !important;
  font-size: 10px !important;
  border-top-color: #ebecf0 !important;
`;

export const RecommendedMethodButton = styled(Button)`
  margin-top: 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08) !important;

  > img {
    max-height: 100%;
    max-width: 100%;
    padding-right: 0.5em;
  }
`;

export const SignIn = styled.p`
  text-align: center;
  margin-top: 40px;
  font-size: 14px;
  color: #6c788b;
`;

export const NewsletterCheckbox = styled(Checkbox)`
  font-size: 12px !important;
  margin-bottom: 2em !important;
`;

export const Terms = styled.p`
  margin-top: 2em;
  font-size: 12px;
`;

export const MethodTitle = styled.h3`
  font-weight: normal;
  color: ${colors.grey5};
  text-align: center;
  font-size: 15px;
  margin-bottom: 2em;

  > img {
    float: left;
  }
`;

export const GoBackButton = styled.button`
  float: left;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
`;

export const EmailSentContainer = styled.div`
  margin-top: 30%;

  > img {
    margin-bottom: 20px;
  }
`;

export const EmailSentMessage = styled.p`
  color: ${colors.grey5};
`;

export const Email = styled.span`
  color: ${colors.fidelBlue600};
`;
