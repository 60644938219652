/* eslint-disable jsx-a11y/anchor-has-content */
/* We do this because lint doesn’t know that <Trans components=[{<a />}] />
 * will output <a>{content}</a> */

import React from 'react';
import { Col } from 'antd';
import { useTranslation, Trans } from 'react-i18next';

import { GreenTick, Label, SchemeCard, SchemeName } from '../styled/schemes';
import { ReactComponent as Amex } from '../assets/amex_icon.svg';
import { ReactComponent as Mastercard } from '../assets/mastercard_icon.svg';
import { ReactComponent as Visa } from '../assets/visa_icon.svg';
import memoNoProps from '../../../utils/memo-no-props';
import { getEnabledSchemesForAccount } from '../../../utils/schemes';
import { useAppSelector } from '../../../store/hooks';

interface Scheme {
  scheme: string;
  live: boolean;
  icon: any;
}

const Schemes = () => {
  const accountDetails = useAppSelector(state => state.account.details);
  const { t } = useTranslation();
  const enabledSchemes =
    accountDetails && getEnabledSchemesForAccount(accountDetails);

  const schemes: Scheme[] = enabledSchemes
    ? [
        { scheme: 'Visa', live: enabledSchemes.visa, icon: <Visa /> },
        {
          scheme: 'Mastercard',
          live: enabledSchemes.mastercard,
          icon: <Mastercard />,
        },
        {
          scheme: 'American Express',
          live: enabledSchemes.amex,
          icon: <Amex />,
        },
      ]
    : [];

  return (
    <>
      <div>
        {schemes.map(({ scheme, live, icon }) => (
          <SchemeCard justify="space-between" align="middle" key={scheme}>
            <Col>
              {icon} <SchemeName>{scheme}</SchemeName>
            </Col>
            <div>
              {live ? (
                <Label>
                  <GreenTick /> <span>{t('account.scheme.enabled')}</span>
                </Label>
              ) : (
                <Trans
                  i18nKey="account.scheme.enableScheme"
                  // eslint-disable-next-line
                  components={[<a href="mailto:support@fidel.uk" />]}
                />
              )}
            </div>
          </SchemeCard>
        ))}
      </div>
    </>
  );
};

export default memoNoProps(Schemes);
