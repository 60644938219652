import { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Stripe, StripeError } from '@stripe/stripe-js';
import {
  useElements,
  useStripe,
  CardNumberElement as StripeCardNumberElement,
  CardElement as StripeCardElement,
} from '@stripe/react-stripe-js';
import {
  addPaymentMethod,
  confirmPayment,
} from '../store/account/account-actions';
import { Invoice } from '../store/account/account-model';
import StripeApiService from '../store/stripe/stripe-api-service';

import usePrevious from './use-previous';
import { useSetStatus } from './use-status';
import { useAppDispatch, useAppSelector } from '../store/hooks';

function useAddPayment(
  callback?: (stripeSuccess: any) => void,
  customLoading?: boolean,
) {
  const stripe = useStripe();
  const dispatch = useAppDispatch();
  const stripeElements = useElements();
  const stripeApiService = new StripeApiService();
  const { accountId, addingPaymentMethod } = useAppSelector(
    state => ({
      accountId: state.account.details?.id,
      addingPaymentMethod: state.account.paymentMethods.adding,
    }),
    shallowEqual,
  );

  const { setStripeIntent } = stripeApiService;

  async function stripeCommand() {
    const cardElement =
      stripeElements?.getElement(StripeCardNumberElement) ||
      stripeElements?.getElement(StripeCardElement);

    if (stripe && cardElement && accountId) {
      const response = await setStripeIntent(accountId);
      const stripeClientSecret = response.client_secret;
      const { error, setupIntent } = await stripe.confirmCardSetup(
        stripeClientSecret,
        {
          payment_method: {
            card: cardElement,
          },
        },
      );

      const { id: success } = setupIntent || {};

      return { error, success };
    }

    return {};
  }

  const { submit, loading, done } = useStripeWithCallback({
    stripe,
    storeLoading:
      typeof customLoading !== 'undefined'
        ? customLoading
        : addingPaymentMethod,
    callback: success =>
      callback ? callback(success) : dispatch(addPaymentMethod(success)),
    stripeCommand,
    customErrorMessage: 'setupStripeIntent',
  });

  return { submit, loading, done };
}

function useConfirmPayment(invoice: Invoice) {
  const stripe = useStripe();
  const dispatch = useAppDispatch();
  const confirmingPayment = useAppSelector(
    state => state.account.paymentMethods.confirming,
  );

  const { id, stripePaymentError } = invoice;

  const invoiceIsLoading = confirmingPayment.some(
    invoiceId => invoiceId === id,
  );

  async function stripeCommand() {
    if (stripe && stripePaymentError) {
      const { paymentMethodId, paymentIntentClientSecret } =
        stripePaymentError || {};

      const { error } = await stripe.confirmCardPayment(
        paymentIntentClientSecret,
        {
          payment_method: paymentMethodId,
        },
      );

      return { error, success: id };
    }

    return {};
  }

  const { submit, loading, done } = useStripeWithCallback({
    stripe,
    storeLoading: invoiceIsLoading,
    callback: success => dispatch(confirmPayment(success)),
    stripeCommand,
    customErrorMessage: 'confirmPayment',
  });

  return { submit, loading, done };
}

function useStripeWithCallback({
  stripe,
  storeLoading,
  stripeCommand,
  callback,
  customErrorMessage = '',
}: {
  stripe: Stripe | null;
  storeLoading: boolean;
  stripeCommand: () => Promise<{
    error?: StripeError;
    success?: any;
  }>;
  callback: (stripeSuccess: any) => void;
  customErrorMessage: string;
}) {
  const { setErrorMessage } = useSetStatus();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const previousStoreLoading = usePrevious(storeLoading);

  useEffect(() => {
    if (previousStoreLoading === true && storeLoading === false) {
      setLoading(false);
      setDone(true);
    }
  }, [storeLoading, previousStoreLoading]);

  async function submit() {
    if (stripe) {
      try {
        setLoading(true);
        const { error, success } = await stripeCommand();

        if (error || !success) {
          setLoading(false);
          setErrorMessage(
            error?.message ||
              t(`account.payment.error.${customErrorMessage}` as any),
          );
        } else {
          callback(success);
        }
      } catch (error) {
        setLoading(false);
        if (error instanceof Error) setErrorMessage(error.message);
      }
    } else {
      setErrorMessage(t('account.payment.error.loadingStripe'));
    }
  }

  return { submit, loading, done };
}

export { useAddPayment, useConfirmPayment };
