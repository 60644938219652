import React, { ReactElement, ReactNode, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Divider,
  Scrollable,
  ScrollIndicator,
  StickyElement,
  Table,
  TableContainer,
  TopRowElementContainer,
} from './styled/base';
import Row, { RowData } from './base/Row';
import ContentStateHandler from './base/ContentStateHandler';
import sameLength from '../../utils/same-length';
import useShowIndicators from './hooks/use-show-indicators';
import Headings from './base/Headings';
import { IllustrationsKeys } from '../empty/illustrations';

export type Align = 'left' | 'right' | 'center';

export type Sort = 'asc' | 'desc' | 'unset';

export type Column = {
  id?: string;
  heading: ReactNode;
  align?: Align;
  verticalAlign?: React.CSSProperties['alignSelf'];
  size?: number;
  sortedBy?: Sort;
  onSort?: (sortBy: Sort) => void;
  hidden?: boolean;
  style?: React.CSSProperties;
};

export type AlignSizeStyle = Pick<
  Column,
  'align' | 'verticalAlign' | 'size' | 'style'
>;

export const defaultSettings: AlignSizeStyle = {
  align: 'left',
  size: 1,
};

export interface Highlight {
  color: string;
}

export interface DividerType {
  text?: ReactNode;
  highlight?: Highlight;
}

export type RowType = RowData | DividerType;

export interface BaseTableProps {
  bordered?: boolean;
  bottomRowElement?: ReactNode;
  className?: string;
  columns: Column[];
  emptyText?: string | ReactElement | null;
  loading?: boolean;
  loadingExtra?: string;
  refetching?: boolean;
  minWidth?: number;
  onReload?: (e: any) => unknown;
  onSelectAll?: (checked: boolean) => unknown;
  rows: RowType[];
  stretch?: boolean;
  topRowElement?: ReactNode;
  show?: 'headings' | 'rows' | 'all';
  lockScroll?: boolean;
  disabled?: boolean;
  hideScrollIndicators?: boolean;
  padding?: boolean;
  emptyIllustration?: IllustrationsKeys;
}

function isRowData(row: RowData | DividerType): row is RowData {
  return !!(row as RowData).contents;
}

const BaseTable = ({
  bordered,
  bottomRowElement,
  className,
  columns,
  emptyText,
  loading,
  loadingExtra,
  minWidth,
  onReload,
  onSelectAll,
  rows,
  stretch = false,
  topRowElement,
  show = 'all',
  lockScroll = false,
  disabled = false,
  hideScrollIndicators = false,
  padding = true,
  emptyIllustration,
}: BaseTableProps) => {
  const { t } = useTranslation();
  const scrollRef = useRef<HTMLDivElement>(null);
  const { showIndicators, onScroll } = useShowIndicators(scrollRef);
  const [showLeftIndicator, showRightIndicator] = showIndicators;

  const rowsOnly: RowData[] = rows.filter(isRowData);

  const columnSettings = columns.map(column => {
    const { align, verticalAlign, size, style } = column || defaultSettings;

    return {
      align,
      verticalAlign,
      size,
      style,
    };
  });

  const hasItems = rows.length > 0;
  const isFreshLoading = !!loading && !hasItems;

  if (hasItems && !sameLength(rowsOnly[0]?.contents, columns)) {
    throw new Error('Headings and contents must be the same size');
  }

  const hasActionMenu = useMemo(
    () => rowsOnly.some(row => row.actions),
    [rowsOnly],
  );

  return (
    <ContentStateHandler
      bordered={bordered}
      emptyText={emptyText === null ? null : emptyText || t('table.empty')}
      isLoading={isFreshLoading}
      loadingExtra={loadingExtra}
      hasItems={hasItems}
      emptyIllustration={emptyIllustration}
    >
      <Scrollable
        ref={scrollRef}
        onScroll={onScroll}
        stretch={stretch}
        bordered={bordered}
        hasItems={hasItems}
        hasBottomRowElement={!!bottomRowElement}
        className={className}
        emptyText={emptyText}
        lockScroll={lockScroll}
      >
        <TableContainer>
          {!hideScrollIndicators && (
            <ScrollIndicator
              $bordered={bordered}
              $placement="left"
              $show={showLeftIndicator}
            />
          )}
          <Table
            minWidth={show === 'all' ? minWidth : 0}
            hideScrollIndicators={hideScrollIndicators}
          >
            {show !== 'rows' && (
              <>
                <Headings
                  columns={columns}
                  rowsOnly={rowsOnly}
                  onSelectAll={onSelectAll}
                  hasActionMenu={hasActionMenu}
                  onReload={onReload}
                  padding={padding}
                  hasItems={hasItems}
                />
                {topRowElement && (
                  <StickyElement maxWidth={scrollRef.current?.clientWidth}>
                    <TopRowElementContainer hasItems={hasItems}>
                      {topRowElement}
                    </TopRowElementContainer>
                  </StickyElement>
                )}
              </>
            )}
            {show !== 'headings' &&
              rows.map((row, i) =>
                isRowData(row) ? (
                  <Row
                    key={row.key || `${i}`}
                    columnSettings={columnSettings}
                    forceRightPadding={!!(hasActionMenu || onReload)}
                    disabled={disabled}
                    padding={padding}
                    style={
                      i === rows.length - 1 ? { borderBottom: 'none' } : {}
                    }
                    {...row}
                  />
                ) : (
                  <StickyElement
                    maxWidth={scrollRef.current?.clientWidth}
                    key={i}
                  >
                    <Divider {...row}>{row.text}</Divider>
                  </StickyElement>
                ),
              )}
          </Table>
          {!hideScrollIndicators && (
            <ScrollIndicator
              $bordered={bordered}
              $placement="right"
              $show={showRightIndicator}
            />
          )}
        </TableContainer>
        {show !== 'headings' && bottomRowElement}
      </Scrollable>
    </ContentStateHandler>
  );
};

export default BaseTable;
