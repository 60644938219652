import React, { useState } from 'react';
import moment from 'moment';
import { Dropdown } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

import {
  Location,
  SchemeType,
  SchemeStatus,
} from '../../../store/locations/locations-model';
import CardSchemeStatusTag, { RealTimeType } from './StatusTag';
import { New, TagButton } from '../styled/card-scheme-status-tag';
import DropdownBox, { DropdownText } from '../../../components/dropdown-box';
import { GreyDropdownButton } from '../../../components/buttons';
import templateSchemes, { Scheme } from '../../../utils/schemes';
import filterFalsy from '../../../utils/filter-falsy';
import { DOUBLE_MINUS } from '../../../utils/special-character';
import useIsSignedIn from '../../../hooks/use-is-signed-in';
import useTransactionStream from '../../../hooks/use-transaction-stream';
import { useAppSelector } from '../../../store/hooks';

interface CardSchemeStatusProps {
  scheme: Scheme;
  onSubmitMerchantId: () => unknown;
  onLinkVirtualCardTransaction: () => unknown;
  location: Location;
}

function getRealTimeType(scheme: SchemeType) {
  if (scheme.status === 'active') {
    if (scheme.auth && scheme.authTransactionId) {
      return RealTimeType.REAL_TIME_ACTIVE_TESTED;
    }

    if (scheme.clearingTransactionId) {
      return RealTimeType.ACTIVE_TESTED;
    }
  }
  return null;
}

function estimatedSyncingDays(estimatedActivationDate: string) {
  return moment(estimatedActivationDate).diff(moment(), 'days') + 1;
}

const getFailedSchemes = (location: Location) =>
  Object.entries(location)
    .filter(
      ([key, value]) =>
        templateSchemes.includes(key as Scheme) && value.status === 'failed',
    )
    .map(([key]) => key as Scheme);

interface StatusActionsProps {
  status: SchemeStatus;
  schemeName: Scheme;
  failedSchemes: Scheme[];
  onSubmitMerchantId: () => unknown;
  onLinkVirtualCardTransaction: () => unknown;
  close: () => unknown;
  countryCode?: string;
}

interface StatusAction {
  title: 'submitMerchantId' | 'linkVirtualCardTransaction';
  action: () => void;
  isNew: boolean;
}

const StatusActionsDropdown = ({
  status,
  schemeName,
  failedSchemes,
  onSubmitMerchantId,
  onLinkVirtualCardTransaction,
  close,
  countryCode,
}: StatusActionsProps) => {
  const { t } = useTranslation();
  const { t: tLocations } = useTranslation('locations', {
    keyPrefix: 'status.actions.dropdown',
  });
  const { isAccountAdmin } = useIsSignedIn();
  const { hasAccountTransactionStream } = useTransactionStream();

  const disableSubmitMid =
    ['USA', 'CAN'].includes(countryCode || '') && schemeName === 'visa';

  const actions: StatusAction[] = filterFalsy([
    !disableSubmitMid && {
      title: 'submitMerchantId',
      action: onSubmitMerchantId,
      isNew: false,
    },
    isAccountAdmin &&
      !hasAccountTransactionStream &&
      schemeName !== 'amex' && {
        title: 'linkVirtualCardTransaction',
        action: onLinkVirtualCardTransaction,
        isNew: true,
      },
  ]);

  const renderDropdownText = () => {
    if (failedSchemes.length > 1) {
      const schemesText = failedSchemes
        .filter((_element, index) => index < failedSchemes.length - 1)
        .map(scheme => t(`schemes.${scheme}`))
        .join(', ');

      const lastSchemeText = t(
        `schemes.${failedSchemes[failedSchemes.length - 1]}`,
      );

      return (
        <Trans
          i18nKey="status.actions.dropdown.actionAllSchemes"
          ns="locations"
          values={{
            schemes: schemesText,
            lastScheme: lastSchemeText,
          }}
          components={{ p: <p /> }}
        />
      );
    }

    if (status === 'failed') {
      return (
        <Trans
          i18nKey="status.actions.dropdown.action"
          ns="locations"
          values={{ scheme: t(`schemes.${schemeName}`) }}
          components={{ p: <p /> }}
        />
      );
    }

    return (
      <p>
        <Trans
          type="p"
          i18nKey="status.actions.dropdown.additionalMerchantId"
          ns="locations"
        />
      </p>
    );
  };

  return (
    <DropdownBox>
      <DropdownText>{renderDropdownText()}</DropdownText>
      {actions.map(({ title, action, isNew }, idx) => (
        <GreyDropdownButton
          key={title}
          block
          onClick={() => {
            action();
            close();
          }}
          style={{ marginTop: idx !== 0 ? '8px' : 0 }}
        >
          {tLocations(title)}
          {isNew && <New>{tLocations('new')}</New>}
        </GreyDropdownButton>
      ))}
    </DropdownBox>
  );
};

const SyncingDropdown = ({ days }: { days: number }) => {
  const { t } = useTranslation('locations');
  return (
    <DropdownBox>
      <DropdownText>{t('status.sync.dropdown.heading')}</DropdownText>
      <p>
        <Trans
          i18nKey="status.sync.dropdown.daysLeft"
          ns="locations"
          components={[<CardSchemeStatusTag status="sync" days={days} />]}
        />
      </p>
      {days < 1 && (
        <DropdownText>
          <Trans
            i18nKey="status.sync.dropdown.support"
            ns="locations"
            // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
            components={[<a href="mailto:support@fidel.uk" />]}
          />
        </DropdownText>
      )}
    </DropdownBox>
  );
};

const CardSchemeStatus = ({
  location,
  scheme: schemeName,
  onSubmitMerchantId,
  onLinkVirtualCardTransaction,
  ...props
}: CardSchemeStatusProps) => {
  const isLive = useAppSelector(state => state.live);
  const [showDropdown, setShowDropdown] = useState(false);
  const scheme = location[schemeName];
  if (!scheme) {
    return <>{DOUBLE_MINUS}</>;
  }

  const statusWithActions: SchemeStatus[] = ['failed', 'active'];

  const status = location.action === 'delete' ? 'delete' : scheme.status;
  const realTimeType = getRealTimeType(scheme);

  const days =
    status === 'sync' && scheme.estimatedActivationDate
      ? estimatedSyncingDays(scheme.estimatedActivationDate)
      : undefined;

  const clickable = (isLive && statusWithActions.includes(status)) || !!days;

  const tag = (
    <TagButton tabIndex={clickable ? 0 : -1}>
      <CardSchemeStatusTag
        status={status}
        days={days}
        realTime={realTimeType}
        clickable={clickable}
        {...props}
      />
    </TagButton>
  );

  const tagWithDropdown = (
    <Dropdown
      getPopupContainer={(trigger: any) => trigger.parentNode}
      visible={showDropdown}
      onVisibleChange={setShowDropdown}
      trigger={['click']}
      overlay={
        <>
          {statusWithActions.includes(status) && (
            <StatusActionsDropdown
              status={status}
              close={() => setShowDropdown(false)}
              countryCode={location.countryCode}
              failedSchemes={getFailedSchemes(location)}
              schemeName={schemeName}
              onSubmitMerchantId={onSubmitMerchantId}
              onLinkVirtualCardTransaction={onLinkVirtualCardTransaction}
            />
          )}
          {days && <SyncingDropdown days={days} />}
        </>
      }
    >
      {tag}
    </Dropdown>
  );

  return (
    /* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
    <div onClick={e => e.stopPropagation()}>
      {clickable ? tagWithDropdown : tag}
    </div>
  );
};

export default CardSchemeStatus;
