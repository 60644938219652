import axios, { AxiosRequestConfig } from 'axios';
import { api, cognito, namespaces } from '../config';
import liveService from './live-service';

export const requestInterceptor = axios.interceptors.request;
export const responseInterceptor = axios.interceptors.response;

type requestInterceptorArgs = Parameters<typeof axios.interceptors.request.use>;
type responseInterceptorArgs = Parameters<
  typeof axios.interceptors.response.use
>;

export class HttpService {
  private baseUrl: string = api.BASE_URL;
  private apiVersion: string | null = api.DEFAULT_VERSION;

  constructor() {
    this.addRequestInterceptor(req => {
      if (req.url?.includes(cognito.DOMAIN_URL)) {
        return req;
      }

      const contentType =
        (req.headers || {})['Content-Type'] ?? 'application/json';

      let headers: any = {
        ...req.headers,
        'Content-Type': contentType,
        'fidel-key': api.KEY,
        'fidel-live': liveService.isLive(),
      };

      const account = localStorage.getItem(namespaces.ACCOUNT);

      if (account) {
        headers = {
          ...headers,
          'fidel-account': account,
        };
      }
      if (this.apiVersion && !headers['fidel-version']) {
        headers = {
          ...headers,
          'fidel-version': this.apiVersion,
        };
      }

      // TODO : remove this once clients have migrated to latest API version
      if (req.url?.includes('transactions')) {
        headers['fidel-version'] = '2022-07-13';
      }

      req.headers = headers;

      return req;
    });
  }

  addRequestInterceptor(...args: requestInterceptorArgs) {
    const interceptor = axios.interceptors.request.use(...args);
    return () => axios.interceptors.request.eject(interceptor);
  }

  addResponseInterceptor(...args: responseInterceptorArgs) {
    const interceptor = axios.interceptors.response.use(...args);
    return () => axios.interceptors.response.eject(interceptor);
  }

  setApiVersion(version: string | null) {
    this.apiVersion = version;
  }

  get<T = any>(url: string, options: AxiosRequestConfig = {}) {
    return axios.get<T>(`${this.baseUrl}${url}`, options);
  }

  post<T = any>(url: string, data: any, options: AxiosRequestConfig = {}) {
    if (url.includes(cognito.DOMAIN_URL)) {
      return axios.post<T>(url, data, options);
    }
    return axios.post<T>(`${this.baseUrl}${url}`, data, options);
  }

  put<T = any>(url: string, data: any, options: AxiosRequestConfig = {}) {
    return axios.put<T>(`${this.baseUrl}${url}`, data, options);
  }

  patch<T = any>(url: string, data: any, options: AxiosRequestConfig = {}) {
    return axios.patch<T>(`${this.baseUrl}${url}`, data, options);
  }

  delete<T = any>(url: string) {
    return axios.delete<T>(`${this.baseUrl}${url}`, {
      data: { workaround: 'content-type in axios' },
    });
  }
}

export default new HttpService();
