import styled from 'styled-components';
import { Steps } from 'antd';
import { colors } from '../../../../theme';

const { Step } = Steps;

export const ConsentTabContent = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

export const ConsentDescription = styled.div`
  margin-top: 38px;
  margin-bottom: 33px;
  font-size: 14px;
  color: ${colors.grey4};
`;

export const StyledStep = styled(Step)`
  .ant-steps-item-icon {
    width: 20px;
    height: 20px;
    line-height: 19px;
    font-size: 12px;
  }
  .ant-steps-item-title {
    font-size: 14px;
    color: ${colors.grey4};
    font-weight: 400;
  }
`;

export const SelectedBrandBox = styled.div`
  background: ${colors.lightgrey7};
  display: flex;
  position: relative;
  padding: 24px;
  margin-bottom: 24px;

  .ant-avatar[data-secondary] {
    position: absolute;
    left: 76px;
    bottom: 24px;
  }
`;

export const SelectedBrandInfo = styled.div`
  display: flex;
  flex-flow: column;
  margin-left: 24px;
  max-width: 200px;
`;

export const SelectedBrandName = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: ${colors.black};
`;

export const SelectedBrandWebsite = styled.span`
  font-weight: 400;
  font-size: 12px;
  color: ${colors.grey4};
`;
