import React, { ComponentProps } from 'react';
import { Col } from 'antd';

import ActionsMenu, { Action } from '../../components/actions-menu';
import { FormButton } from '../../components/buttons';
import DetailTitle from './components/DetailTitle';
import {
  DetailCard,
  DetailCardFooter,
  DetailContainer,
  DetailOverlay,
  ActionsMenuContainer,
} from './styled/detail';

interface DetailProps {
  actions: Action[];
  clear: () => void;
  title?: ComponentProps<typeof DetailTitle>;
  emptyMessage?: string;
  children?: React.ReactNode;
}

const Detail = React.forwardRef<HTMLDivElement, DetailProps>(
  (
    { actions: [mainAction, ...actions], children, clear, emptyMessage, title },
    ref,
  ) => (
    <DetailContainer visible={!!title} ref={ref}>
      <DetailOverlay
        onClick={() => clear()}
        role="button"
        aria-hidden="true"
        data-testid="detail-overlay"
      />
      <DetailCard
        data-testid="detail-card"
        bordered={false}
        title={<DetailTitle {...(title || {})} emptyMessage={emptyMessage} />}
      >
        <div>{title && children}</div>
        {!!title && mainAction && (
          <DetailCardFooter>
            <Col span={actions?.length ? 20 : 24}>
              <FormButton
                size="small"
                block
                htmlType="submit"
                onClick={mainAction.callback}
                disabled={mainAction.disabled}
              >
                {mainAction.label}
              </FormButton>
            </Col>
            {!!actions?.length && (
              <Col xs={{ span: 4 }}>
                <ActionsMenuContainer>
                  <ActionsMenu actions={actions} />
                </ActionsMenuContainer>
              </Col>
            )}
          </DetailCardFooter>
        )}
      </DetailCard>
    </DetailContainer>
  ),
);

export default Detail;
