import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { FeedbackForm } from 'feedback-fish';

import './style.css';
import TriggerComponent from './FeedbackTriggerButton';
import { useAppSelector } from '../../store/hooks';

const Feedback = ({ right = 20 }: { right?: number }) => {
  const location = useLocation();
  const email = useAppSelector(state => state.user.details?.email);

  const { innerWidth, innerHeight } = window;

  return (
    <Wrapper right={right}>
      <FeedbackForm
        projectId="4545b4f4152b34"
        triggerComponent={TriggerComponent}
        userId={`DASH – ${email}`}
        metadata={{
          location: location.pathname,
          screenSize: `${innerWidth} x ${innerHeight}`,
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div<{ right: number }>`
  position: fixed;
  right: ${({ right }) => right}px;
  bottom: 20px;
  z-index: 3;

  @media (max-width: calc(150px + ${({ right }) => right}px)) {
    display: none;
  }
`;

export default Feedback;
