import React from 'react';
import styled from 'styled-components';
import { Collapse as AntdCollapse } from 'antd';
import { Caron } from '../../icons';
import { colors } from '../../../theme';

export const CustomCollapse = styled(AntdCollapse).attrs(() => ({
  bordered: false,
  expandIconPosition: 'right',
  expandIcon: ({ isActive }) => (
    <Caron
      style={{
        transform: `rotate(${isActive ? 180 : 0}deg)`,
        color: colors.grey4,
        marginTop: `${isActive ? '18' : '7'}px`,
      }}
    />
  ),
}))`
  background: white;

  .ant-collapse-item > .ant-collapse-header {
    padding-left: 0 !important;
    padding-top: 17px;
    padding-bottom: 17px;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding-left: 0 !important;
  }
`;
