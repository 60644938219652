import styled from 'styled-components';

import { StyledSpin } from '../../../components/spin';
import { colors } from '../../../theme';
import { Scrollable } from './base';

export const Content = styled.div<{ stretch: boolean }>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 -20px;
  max-height: 100%;

  ${Scrollable} {
    height: ${({ stretch }) => (stretch ? '100vh' : 'auto')};
  }
`;

const countBase = `
  position: sticky;
  bottom: 0;
  left: 20px;
  z-index: 3;
  background: white;
  :before,
  :after {
    content: '';
    position: absolute;
    top: 0;
    width: 20px;
    height: 100%;
    background: ${colors.white};
  }
  :before {
    left: -20px;
  }
  :after {
    right: -20px;
  }
`;

export const NoCountPadding = styled.div`
  ${countBase};
  padding: 20px;
  left: 0;
`;

export const Count = styled.div`
  font-size: 12px;
  margin: -1px 20px 0;
  color: #6c788b;
  border-top: 1px solid ${colors.lightgrey2};
  padding: 10px;
  ${countBase};
`;

export const SpinContainer = styled.div`
  ${StyledSpin} {
    position: absolute;
    padding: 0;
    top: 12px;
    z-index: -1;
  }
`;
