import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ExportIcon } from '../../../../assets/export.svg';
import Form from '../../../../components/form';
import Link from '../../../../components/link';
import { Select } from '../../../../components/select';
import { programTypes } from '../../../../store/programs/programs-reducer';

import { selectEndpoints, streamEndpoints } from '../../endpoints';
import { docsBaseUrl } from '../../endpoints/endpoints-helpers';
import { PlaygroundForm } from '../../form';
import { Endpoint } from '../../types';
import PlaygroundPopover from '../playground-popover';

import {
  EndpointButton,
  GroupContainer,
  GroupTitle,
  Sider,
  SiderTrigger,
} from './styled';

interface EndpointSelectorProps {
  selectedEndpoint: Endpoint | 'intro';
  setEndpoint: (endpoint: Endpoint | 'intro') => void;
}

const EndpointSelector = ({
  selectedEndpoint,
  setEndpoint,
}: EndpointSelectorProps) => {
  const { t } = useTranslation(['common', 'programs']);
  const { control, watch } = useFormContext<PlaygroundForm>();
  const programType = watch('programType');

  const endpoints =
    programType === 'transaction-select' ? selectEndpoints : streamEndpoints;

  return (
    <Sider
      theme="light"
      collapsible
      collapsedWidth={1 /* because we can’t customise if 0 */}
      trigger={<SiderTrigger />}
    >
      <PlaygroundPopover isNew>
        <Form.ItemController
          controller={{
            name: 'programType',
            render: ({ field }) => (
              <Select
                {...field}
                data-testid="program-type-selector"
                style={{ marginTop: '18px' }}
              >
                {programTypes.map(value => (
                  <Select.Option key={value} value={value}>
                    {t(`programs:programType.${value}.label`)}
                  </Select.Option>
                ))}
              </Select>
            ),
            control,
          }}
        />
      </PlaygroundPopover>
      <EndpointButton
        key="intro"
        type="link"
        onClick={() => setEndpoint('intro')}
        selected={selectedEndpoint === 'intro'}
        style={{ marginTop: '18px' }}
      >
        {t('common:playground.endpoint.intro.title')}
      </EndpointButton>
      {Object.entries(endpoints).map(([group, { endpointsList, docsLink }]) => (
        <GroupContainer key={group}>
          <GroupTitle>
            <span>{group}</span>
            <Link to={`${docsBaseUrl}${docsLink}`}>
              <ExportIcon />
            </Link>
          </GroupTitle>
          {Object.entries(endpointsList).map(([name, value]) => (
            <EndpointButton
              type="link"
              key={name}
              onClick={() => setEndpoint(value)}
              selected={
                selectedEndpoint !== 'intro' &&
                selectedEndpoint.endpoint.generateUrl() ===
                  value.endpoint.generateUrl()
              }
            >
              {/* TODO Fix 'any' on OFF-1405 */}
              {t(`common:playground.endpoint.${value.i18nKey}.title` as any)}
            </EndpointButton>
          ))}
        </GroupContainer>
      ))}
    </Sider>
  );
};

export default EndpointSelector;
