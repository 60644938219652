import React from 'react';
import { Row, Col, Checkbox, InputNumber, Tooltip } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import createUpdateOfferRules from '../../validation';
import { QuestionMark } from '../../../../../components/icons';
import { TooltipWrapper } from '../../styled';
import Form from '../../../../../components/form';
import { CreateUpdateOfferFormModel } from '../../../types';
import { colors } from '../../../../../theme';

interface CardActivationProps {
  isUpdating?: boolean;
}

export default function CardActivation({ isUpdating }: CardActivationProps) {
  const { t } = useTranslation(['common', 'offers']);

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<CreateUpdateOfferFormModel>();

  const { qualifiedTransactionsLimit } = createUpdateOfferRules;
  const minTransactions = qualifiedTransactionsLimit.min ?? 1;

  return (
    <Row>
      <Col>
        <Form.Item>
          <Controller
            name="activation"
            render={({ field: { value, onChange, ...rest } }) => (
              <Row>
                <Col>
                  <Checkbox
                    {...rest}
                    onChange={({ target }) => onChange(target.checked)}
                    checked={value}
                    data-testid="offer-activation"
                    disabled={isUpdating}
                  >
                    <Trans
                      i18nKey="createUpdate.offerCardActivation.enable"
                      ns="offers"
                    />
                  </Checkbox>
                </Col>

                <Col>
                  <TooltipWrapper>
                    <Tooltip
                      title={t('offers:createUpdate.activation.tooltip')}
                      placement="bottomRight"
                    >
                      <QuestionMark />
                    </Tooltip>
                  </TooltipWrapper>
                </Col>
              </Row>
            )}
            control={control}
          />

          {watch('activation') && (
            <Row>
              <Col>
                <StyledActivationInput
                  label={
                    <StyledActivationLabel>
                      {t(
                        'offers:createUpdate.offerCardActivation.qualifiedTransAmount',
                      )}
                    </StyledActivationLabel>
                  }
                  wrapperCol={{ span: 12 }}
                  validateStatus={errors.qualifiedTransactionsLimit && 'error'}
                  help={
                    errors.qualifiedTransactionsLimit &&
                    t('common:form.validation.min', {
                      min: minTransactions,
                    })
                  }
                >
                  <Controller
                    name="qualifiedTransactionsLimit"
                    render={({ field }) => (
                      <InputNumber
                        {...field}
                        placeholder={t('offers:createUpdate.enterValue')}
                        disabled={isUpdating}
                        min={+minTransactions}
                      />
                    )}
                    control={control}
                    rules={qualifiedTransactionsLimit}
                  />
                </StyledActivationInput>
              </Col>
            </Row>
          )}
        </Form.Item>
      </Col>
    </Row>
  );
}

const StyledActivationLabel = styled.span`
  margin-top: 24px;
  font-size: 12px;
  color: ${colors.black};
`;

const StyledActivationInput = styled(Form.Item)`
  padding-left: 16px;
  font-size: 12px;
`;
