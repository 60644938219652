import React, { ReactNode } from 'react';
import { Tooltip, Typography } from 'antd';
import styled from 'styled-components';
import { TextProps } from 'antd/lib/typography/Text';

interface EllipsisProps extends TextProps {
  className?: string;
  gutter?: number;
  showTooltip?: boolean;
  children?: ReactNode;
}

const Ellipsis = ({
  className,
  gutter,
  showTooltip = false,
  children,
  ...props
}: EllipsisProps) =>
  showTooltip ? (
    <Tooltip title={children} mouseEnterDelay={1}>
      <EllipsisContainer gutter={gutter}>
        <StyledEllipsisText
          ellipsis
          gutter={0}
          className={className}
          {...props}
        >
          {children}
        </StyledEllipsisText>
      </EllipsisContainer>
    </Tooltip>
  ) : (
    <StyledEllipsisText
      ellipsis
      gutter={gutter}
      className={className}
      {...props}
    >
      {children}
    </StyledEllipsisText>
  );

const EllipsisContainer = styled.div<{ gutter?: number }>`
  width: ${({ gutter }) =>
    gutter === 0 ? '100%' : `calc(100% - ${gutter ?? 10}px)`};
`;

const StyledEllipsisText = styled(Typography.Text)<{ gutter?: number }>`
  width: ${({ gutter }) =>
    gutter === 0 ? '100%' : `calc(100% - ${gutter ?? 10}px)`};
  vertical-align: middle;
  color: inherit;
`;

export default Ellipsis;
