import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Input, Select } from 'antd';
import { shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Form from '../../../components/form';
import { RemoveKeyValuePair } from '../../../components/form/styled';
import { FormButton } from '../../../components/buttons';
import { MerchantGroupForm } from '../styled/merchantGroupForm';
import { getAllSchemeOptions } from '../../../utils/schemes';
import { CreateUpdateLocationFormModel } from './CreateUpdateLocationForm';
import { useAppSelector } from '../../../store/hooks';

const MerchantIdsSubForm = () => {
  const { processing, accountDetails } = useAppSelector(
    state => ({
      processing: state.locations.creating || state.locations.updating,
      accountDetails: state.account?.details,
    }),
    shallowEqual,
  );

  const { t } = useTranslation('locations', {
    keyPrefix: 'createLocation.form',
  });
  const {
    control,
    formState: { errors },
  } = useFormContext<CreateUpdateLocationFormModel>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'merchantIds',
  });

  const cardSchemes = accountDetails && getAllSchemeOptions(accountDetails);

  return (
    <>
      {fields.map((item, index) => {
        const hasError = errors?.merchantIds?.[index]?.id;

        return (
          <Form.Item
            key={item.id}
            label={t('merchantId', { index: index + 1 })}
          >
            <MerchantGroupForm>
              <Form.ItemController
                controller={{
                  name: `merchantIds[${index}].scheme`,
                  render: ({ field }) => (
                    <Select
                      {...field}
                      defaultValue={item.scheme}
                      data-testid="merchant-schemes"
                      placeholder={t('scheme')}
                      disabled={processing}
                    >
                      {cardSchemes?.map(({ label, value }) => (
                        <Select.Option key={value} value={value}>
                          {label}
                        </Select.Option>
                      ))}
                    </Select>
                  ),

                  control,
                }}
              />

              <Form.ItemController
                errors={{ [`merchantIds[${index}].id`]: hasError }}
                validateCharacters
                controller={{
                  name: `merchantIds[${index}].id`,
                  render: ({ field }) => (
                    <Input
                      {...field}
                      defaultValue={item.id}
                      placeholder={t('merchantIdPlaceholder')}
                      suffix={
                        <RemoveKeyValuePair
                          style={{ top: 0 }}
                          onClick={() => remove(index)}
                          data-testid="remove"
                        />
                      }
                      disabled={processing}
                    />
                  ),
                  rules: {
                    validate: {
                      alphanumeric: id => /[a-zA-Z0-9]+/.test(id),
                    },
                  },
                  control,
                }}
              />
            </MerchantGroupForm>
          </Form.Item>
        );
      })}
      <FormButton
        size="small"
        block
        onClick={() =>
          append({
            scheme: null,
            id: '',
          })
        }
        disabled={processing}
      >
        {fields.length === 0 ? t('addMerchantId') : t('addMoreMerchantId')}
      </FormButton>
    </>
  );
};

export default MerchantIdsSubForm;
