import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, ConfigProvider, Typography } from 'antd';
import { useFormContext } from 'react-hook-form';

import {
  CardDropdown,
  LocationDropdown,
  ProgramDropdown,
} from '../../../../components/dropdowns';
import Form from '../../../../components/form';
import { Endpoint } from '../../types';
import { ProgramType } from '../../../../store/programs/programs-reducer';
import { PlaygroundForm } from '../../form';
import { selectHideLastNumbers } from '../../../../store/account/account-selectors';
import AddCard from '../../../cards/components/AddCard';
import { CustomEmptyState } from './styled';
import useLoadCards from '../../../../hooks/use-load-cards';
import { FormButton } from '../../../../components/buttons';
import CreateUpdateProgram from '../../../programs/components/CreateUpdateProgram';
import { useAppSelector } from '../../../../store/hooks';

const { Paragraph } = Typography;

interface RequestConfigProps {
  endpoint: Endpoint;
  programType: ProgramType;
}

const RequestConfig = ({ endpoint, programType }: RequestConfigProps) => {
  const { t } = useTranslation(['common', 'programs']);
  const hideLastNumbers = useAppSelector(selectHideLastNumbers);
  const { control, watch, setValue } = useFormContext<PlaygroundForm>();
  const programId = watch('programId');
  const loadCards = useLoadCards(programId);
  const [showCreateProgram, setShowCreateProgram] = useState(false);

  const renderCardsEmptyState = (selectedProgramId: string) => (
    <CustomEmptyState>
      <Paragraph type="secondary">
        {t('common:playground.endpoint.transactions.noCards')}
      </Paragraph>
      <AddCard
        programId={selectedProgramId}
        programType={programType}
        loadCards={loadCards}
        button={
          <FormButton size="small" data-testid="playground-create-card-btn">
            {t('common:playground.endpoint.transactions.addCard')}
          </FormButton>
        }
      />
    </CustomEmptyState>
  );

  const CardErrorCheckbox = () => (
    <Form.ItemController
      controller={{
        name: 'cardFailChecked',
        render: ({ field: { onChange, value, ...rest } }) => (
          <Checkbox
            {...rest}
            checked={value}
            onChange={e => onChange(e.target.checked)}
            data-testid="card-fail-checkbox"
          >
            {t('common:playground.cardErrorOption')}
          </Checkbox>
        ),
        control,
      }}
    />
  );

  return (
    <Form layout="vertical">
      <Form.ItemController
        controller={{
          name: 'programId',
          render: ({ field: { onChange, ref, ...rest } }) => (
            <>
              <ConfigProvider
                renderEmpty={() => (
                  <CustomEmptyState>
                    <Paragraph type="secondary">
                      {t('common:playground.missingProgram.description', {
                        program: t(`programs:programType.${programType}.label`),
                      })}
                    </Paragraph>
                    <FormButton
                      size="small"
                      onClick={() => setShowCreateProgram(true)}
                    >
                      {t('common:playground.missingProgram.createProgram')}
                    </FormButton>
                  </CustomEmptyState>
                )}
              >
                <ProgramDropdown
                  {...rest}
                  formItemProps={{
                    label: t('common:webhooks.createUpdate.program.label'),
                  }}
                  onChange={program => onChange(program?.id)}
                  programType={programType}
                />
              </ConfigProvider>
              <CreateUpdateProgram
                visible={showCreateProgram}
                onClose={() => setShowCreateProgram(false)}
                programType={programType}
              />
            </>
          ),
          control,
        }}
      />
      {endpoint.canFail && <CardErrorCheckbox />}
      {programId && endpoint.hasLocationSelect && (
        <Form.ItemController
          controller={{
            name: 'locationId',
            render: ({ field: { onChange, ref, ...rest } }) => (
              <LocationDropdown
                {...rest}
                formItemProps={{
                  label: t('common:transactions.label.location'),
                }}
                onChange={location => onChange(location?.id)}
                programId={programId}
              />
            ),
            control,
          }}
        />
      )}
      {programId && endpoint.hasCardSelect && (
        <Form.ItemController
          controller={{
            name: 'cardId',
            render: ({ field: { onChange, ref, ...rest } }) => (
              <ConfigProvider
                renderEmpty={() => renderCardsEmptyState(programId)}
              >
                <CardDropdown
                  {...rest}
                  formItemProps={{
                    label: t('common:transactions.label.card'),
                  }}
                  onChange={card => {
                    setValue('cardLastNumbers', card.lastNumbers);
                    onChange(card?.id);
                  }}
                  programId={programId}
                  hideCardNumbers={hideLastNumbers}
                />
              </ConfigProvider>
            ),
            control,
          }}
        />
      )}
    </Form>
  );
};

export default RequestConfig;
