import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { Auth } from 'aws-amplify';

import memoNoProps from '../../utils/memo-no-props';
import Logo from './components/Logo';
import { CenteredContainer, TopBar, Title, Subtitle } from './styled/sign-in';
import Form from '../../components/form';
import EmailInput from './components/EmailInput';
import useHandleCognitoError from '../../hooks/use-handle-cognito-error';
import { useSetStatus } from '../../hooks/use-status';
import GoogleRecaptchaDisclaimer from './GoogleRecaptchaDisclaimer';
import useCaptchaFormSubmit from '../../hooks/use-captcha-form-submit';

enum FormState {
  Start,
  Submitted,
  EmailSent,
}

const ResetPassword = () => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: 'onBlur',
  });
  const [isCancelled, setIsCancelled] = useState(false);
  const [formState, setFormState] = useState<FormState>(FormState.Start);
  const handleCognitoError = useHandleCognitoError();
  const { setSuccessMessage } = useSetStatus();

  const { onSubmit } = useCaptchaFormSubmit(resetPasswordSubmit);

  async function resetPasswordSubmit({ email, token }: any) {
    try {
      setFormState(FormState.Submitted);
      await Auth.forgotPassword(email, { captcha: token });
      setFormState(FormState.EmailSent);
      setSuccessMessage(t('resetPassword.emailSentNotification'));
    } catch (error) {
      setFormState(FormState.Start);
      handleCognitoError(error);
    }
  }

  if (formState === FormState.EmailSent || isCancelled) {
    return <Redirect to="/sign-in" push />;
  }

  return (
    <>
      <TopBar />
      <CenteredContainer>
        <Logo centered />
        <Title>{t('resetPassword.title')}</Title>
        <Subtitle>{t('resetPassword.sendEmailSubtitle')}</Subtitle>
        <Form layout="vertical" onSubmit={handleSubmit(onSubmit)}>
          <EmailInput errors={errors} control={control} />
          <Form.Item>
            <Button
              block
              type="primary"
              htmlType="submit"
              disabled={formState !== FormState.Start}
              data-testid="submit-reset"
            >
              {t('resetPassword.submitSendEmail')}
            </Button>
          </Form.Item>
          <Form.Item>
            <Button block type="link" onClick={() => setIsCancelled(true)}>
              {t('resetPassword.cancelSendEmail')}
            </Button>
          </Form.Item>
          <GoogleRecaptchaDisclaimer />
        </Form>
      </CenteredContainer>
    </>
  );
};

export default memoNoProps(ResetPassword);
