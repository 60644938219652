import React from 'react';
import styled from 'styled-components';
import { Button, Layout } from 'antd';

import { colors, breakpoints, padding } from '../../../../theme';
import { ReactComponent as ArrowIcon } from '../../assets/arrow-left.svg';

const triggerButtonSize = 22;

export const EndpointButton = styled(Button)<{ selected: boolean }>`
  display: block;
  font-size: 14px;
  font-weight: 400;
  padding: 8px;
  height: 25px;
  margin-bottom: 8px;
  width: 100%;
  text-align: left;
  background-color: ${({ selected }) =>
    selected ? colors.lightgrey : 'white'};
  color: ${({ selected }) => (selected ? colors.fidelBlue600 : colors.grey4)};

  :hover,
  :focus {
    background-color: ${colors.lightgrey};
    color: ${colors.fidelBlue600};
  }
`;

export const GroupContainer = styled.div`
  :hover {
    a {
      opacity: 1;
    }
  }
`;

export const GroupTitle = styled.h4`
  font-size: 14px;
  font-weight: 500;
  margin: 20px 0 6px;
  text-transform: uppercase;
  padding: 0 8px;
  display: flex;
  justify-content: space-between;

  a {
    opacity: 0;
    transition: 0.3s ease opacity;
    color: ${colors.grey2};

    :hover {
      color: ${colors.grey4};
    }
  }
`;

const TriggerButton = styled(Button)`
  min-width: ${triggerButtonSize}px; /* Because antd sets min-width too */
  width: ${triggerButtonSize}px;
  height: ${triggerButtonSize}px;
  display: flex;
  justify-content: center;
  padding: 5px 0;
`;

export const Sider = styled(Layout.Sider)`
  margin-left: -${padding.l}px;
  margin-right: ${padding.l}px;
  border-right: 1px solid ${colors.lightgrey2};
  background: ${colors.lightgrey4};
  position: relative;

  &:not(.ant-layout-sider-collapsed) {
    min-width: 240px !important;
  }

  @media (min-width: ${breakpoints.tabletLandscape}px) {
    margin-left: -${padding.xl}px;
  }

  .ant-layout-sider-children {
    overflow-x: hidden;
    padding: 0 ${padding.l}px;
    transition: 0.2s ease padding;
  }

  ${TriggerButton} svg {
    position: relative;
    left: -1px;
    transition: transform 0.3s ease;
  }

  .ant-layout-sider-trigger {
    position: absolute;
    bottom: 26px;
    left: calc(100% - ${triggerButtonSize / 2}px);
    background: transparent;
    width: auto !important;
    height: auto !important;
    color: ${colors.grey5};
  }

  &.ant-layout-sider-collapsed {
    border-right-color: transparent; /* with none, it would show a flicker of a black border */

    .ant-layout-sider-trigger {
      left: calc(100% + 4px);
    }

    ${TriggerButton} svg {
      left: 0;
      transform: rotateZ(180deg);
    }

    .ant-layout-sider-children {
      padding: 0;
    }
  }
`;

export const SiderTrigger = () => (
  <TriggerButton shape="circle">
    <ArrowIcon />
  </TriggerButton>
);
