import React from 'react';

import JsonPreview, {
  JsonProps,
} from '../../../../components/raw-detail/JsonPreview';
import { PayloadTitle, PayloadWrapper } from './styled';

interface PayloadProps extends JsonProps {
  title: string;
}

const Payload = ({ title, ...codeProps }: PayloadProps) => (
  <PayloadWrapper>
    <PayloadTitle>{title}</PayloadTitle>
    <JsonPreview {...codeProps} />
  </PayloadWrapper>
);

export default Payload;
