import styled from 'styled-components';
import { Col } from 'antd';
import { colors } from '../../../../../../theme';
import { CardContainer } from '../../../../../../components/tables/styled/helpers';
import { Caron } from '../../../../../../components/icons';

export const StyledCardContainer = styled(CardContainer).attrs(() => ({
  role: 'button',
  'data-testid': 'search-brand-card-container',
}))`
  padding: 8px;
  transition: 150ms background-color ease-out;

  .ant-row {
    height: 40px;
  }

  &:hover {
    cursor: pointer;
    background-color: ${colors.grey50};
  }
`;

export const StyledBrandCol = styled(Col)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  max-width: 250px;
`;

export const StyledLocationsSpan = styled.span`
  color: ${colors.grey4};
  margin-right: 20px;
`;

export const StyledCaronIcon = styled(Caron)`
  color: ${colors.grey4};
  font-size: 10px;
  transform: rotate(-90deg) translateY(-1px);
`;

export const StyledLocationsCol = styled(Col)`
  margin-left: auto;

  &.ant-col {
    &:last-of-type {
      flex-flow: wrap row;
    }
  }
`;

export const StyledBrandWebsite = styled.span.attrs(() => ({
  'data-testid': 'select-brand-row-website',
}))`
  color: ${colors.grey4};
  font-size: 12px;
`;
