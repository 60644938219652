import React from 'react';
import { Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { NotificationPayload } from '.';
import Link from '../link';
import { CTALink, ServerMessage } from './styled';

export const defaultError = (message?: string): NotificationPayload => ({
  level: 'error',
  title: 'common.error.server',
  description: (
    <Trans
      ns="notifications"
      i18nKey="common.error.message"
      values={{ message }}
      components={[
        <ServerMessage />,
        <Link
          data-testid="request-link"
          to="https://fidellimited.zendesk.com/hc/en-gb/requests/new"
        />,
      ]}
    />
  ),
});

export const brandUserLoginError: NotificationPayload = {
  level: 'error',
  title: 'common.error.login',
  description: (
    <Trans
      ns="notifications"
      i18nKey="brands.brandUser.description"
      components={[<CTALink href="https://clo.fidel.uk" />]}
    />
  ),
};

export const invalidCredentials: NotificationPayload = {
  level: 'error',
  title: 'common.error.login',
  description: <Trans ns="notifications" i18nKey="common.error.credentials" />,
};

export const userNotFoundException: NotificationPayload = {
  level: 'error',
  title: 'common.error.userNotFoundException',
};

export const offerApiUnavailable: NotificationPayload = {
  level: 'info',
  title: 'offers.unavailable.title',
  description: (
    <Trans
      ns="notifications"
      i18nKey="offers.unavailable.message"
      components={[<NavLink to="account" />]}
    />
  ),
};
