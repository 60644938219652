import styled from 'styled-components';
import { Tag } from 'antd';

import DropdownBox from '../../../../components/dropdown-box';
import { GreyDropdownButton } from '../../../../components/buttons';

export const Container = styled.div`
  position: relative;
  z-index: 0;
`;

export const StyledDropdownBox = styled(DropdownBox)`
  width: 245px;
  display: flex;
  flex-flow: column;
  text-align: left;

  ${GreyDropdownButton} {
    width: 100%;
  }
`;

export const StyledTag = styled(Tag)`
  max-width: 100%;
  margin-right: 0;
`;
