import styled from 'styled-components';
import SimpleCodeEditor from 'react-simple-code-editor';
import { Drawer } from 'antd';

import { colors, fonts, hiddenScrollbar } from '../../../theme';

export const Code = styled.div<{ contentWidth: string }>`
  flex-grow: 1;
  background: ${colors.black};
  border-radius: 0 !important;
  font-family: ${fonts.monospace};
  font-size: 12px;
  border: none;
  border-bottom: 1px solid ${colors.darkblue};
  ${hiddenScrollbar}

  textarea {
    margin-top: -1px;
    caret-color: white;

    &:focus {
      outline: none;
    }
  }

  pre,
  textarea {
    white-space: pre !important;
    width: ${props => props.contentWidth} !important;
  }

  pre,
  code,
  textarea {
    line-height: 1.9 !important;
  }
`;

export const Editor = styled(SimpleCodeEditor)`
  overflow: scroll !important;
  ${hiddenScrollbar}
`;

export const LineNumberWrapper = styled.code`
  background: ${colors.darkblue};
  color: ${colors.grey4};
  float: left;
  padding: 7px 24px;
  display: flex;
  flex-direction: column;
`;

export const RawDetailDrawer = styled(Drawer).attrs(({ ...props }) => ({
  width: 570,
  drawerStyle: {
    background: colors.black,
  },
  headerStyle: {
    background: colors.black,
    color: 'white',
    padding: '40px',
  },
  bodyStyle: {
    padding: 0,
  },
  ...props,
}))`
  .ant-drawer-body {
    ${hiddenScrollbar}
  }

  .ant-drawer-title {
    color: white;
  }

  .ant-drawer-close {
    padding: 40px 30px;
    &:hover,
    &:focus {
      color: white;
    }
  }
`;
