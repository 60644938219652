import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Form from '../../components/form';
import { HiddenInput } from '../../components/form/styled';
import { CardForm, stripeWrapper } from '../../components/stripe';
import { GoLiveFormProps } from '.';
import { Title, Description, PoweredByStripe } from './styled';
import { GoLiveModel } from './use-go-live-form-state';
import { useAddPayment } from '../../hooks/use-stripe-hooks';
import stripeIcon from './assets/stripe.svg';
import SubForm from './SubForm';

const BillingForm = ({ onClick, submitting }: GoLiveFormProps) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'account.goLive.steps.billing',
  });
  const { control, setValue, watch } = useFormContext<GoLiveModel>();
  const [customLoading, setCustomLoading] = useState(false);
  const [readyToSubmit, setReadyToSubmit] = useState(true);
  const { submit, loading, done } = useAddPayment(
    (setupIntentId: string) => setValue('setupIntentId', setupIntentId),
    customLoading,
  );

  const setupIntentId = watch('setupIntentId');

  function onSubmit() {
    setCustomLoading(true);
    submit();
  }

  useEffect(() => {
    if (customLoading && setupIntentId) {
      setCustomLoading(false);
    }
  }, [customLoading, setupIntentId]);

  useEffect(() => {
    if (readyToSubmit && done) {
      setReadyToSubmit(false);
      onClick();
    }
  }, [done, onClick, readyToSubmit]);

  return (
    <>
      <SubForm onClick={onSubmit} submitting={submitting || loading} lastStep>
        <Title>{t('title')}</Title>
        <Description>{t('description')}</Description>
        <CardForm />
        <Form.ItemController
          controller={{
            name: 'setupIntentId',
            render: ({ field }) => <HiddenInput {...field} />,
            control,
          }}
        />
      </SubForm>
      <PoweredByStripe style={{ marginTop: '40px' }}>
        <p>
          {t('poweredBy')}
          <img alt="stripe" src={stripeIcon} />
        </p>
      </PoweredByStripe>
    </>
  );
};

export default stripeWrapper(BillingForm);
