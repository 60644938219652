import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { Col, Row, Input, InputNumber } from 'antd';

import Form from '../../../../../components/form';
import {
  FieldWithCurrencyWrapper,
  RemoveKeyValuePair,
} from '../../../../../components/form/styled';
import { FormButton } from '../../../../../components/buttons';
import regex from '../../../../../utils/regex';
import { CreateUpdateSTPeriodForm } from '.';

export function bracketsToForm(brackets?: number[][]) {
  if (!brackets) return [];
  return Object.entries(brackets).map(([, [key, value]]) => ({ key, value }));
}

export function formToBrackets(
  brackets: { key: number; value: number }[],
): number[][] {
  return brackets.reduce(
    (acc, { key, value }) => [...acc, [key, Number(value)]],
    [] as number[][],
  );
}

interface BracketsProps {
  currency: string | undefined;
  calculateMinFee: () => void;
}

const Brackets = ({ currency, calculateMinFee }: BracketsProps) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'account.contract.billingPeriods.createUpdate.selectTransaction',
  });
  const {
    control,
    formState: { errors },
  } = useFormContext<CreateUpdateSTPeriodForm>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'brackets',
  });

  useEffect(() => {
    if (fields.length === 0) append({});
  }, [append, fields.length]);

  return (
    <>
      {fields.map((field, idx) => {
        const getError = (name: string) => {
          const currentFieldErrors =
            errors.brackets && (errors.brackets as any)[idx]?.[name];

          return { [`brackets.${idx}.${name}`]: currentFieldErrors };
        };

        const min = fields[idx - 1]?.key + 1 || 0;

        return (
          <Row key={field.id} justify="space-between">
            <Col span={11}>
              <Form.ItemController
                label={idx === 0 ? t('numTransactions') : null}
                errors={getError('key')}
                controller={{
                  name: `brackets.${idx}.key`,
                  render: ({ field: { ...rest } }) => (
                    <InputNumber {...rest} min={min} defaultValue={field.key} />
                  ),
                  rules: { required: true, min },
                  control,
                }}
              />
            </Col>
            <Col span={10}>
              <FieldWithCurrencyWrapper>
                <Form.ItemController
                  label={idx === 0 ? t('priceTransaction') : null}
                  errors={getError('value')}
                  controller={{
                    name: `brackets.${idx}.value`,
                    render: ({ field: { onChange, ...rest } }) => (
                      <Input
                        {...rest}
                        min={0}
                        prefix={currency}
                        defaultValue={field.value}
                        onChange={value => {
                          onChange(value);
                          if (idx === 0) {
                            calculateMinFee();
                          }
                        }}
                      />
                    ),
                    rules: {
                      required: true,
                      min: 0,
                      validate: {
                        numeric: value =>
                          regex.positiveDecimalNumeric.test(value),
                      },
                    },
                    control,
                  }}
                />
              </FieldWithCurrencyWrapper>
            </Col>
            <Col span={2}>
              {idx > 0 && (
                <RemoveKeyValuePair
                  style={{ top: '9px', marginLeft: '4px' }}
                  onClick={() => remove(idx)}
                  data-testid={`remove-${idx}`}
                />
              )}
            </Col>
          </Row>
        );
      })}
      <FormButton size="small" block onClick={() => append({})}>
        {t('addBracket')}
      </FormButton>
    </>
  );
};

export default Brackets;
