import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { TransactionStatus } from '../../../store/transactions/transactions-model';
import Ellipsis from '../../../components/ellipsis';
import { StyledTag } from './styled/transaction-tags';

const TransactionTag = ({
  color,
  children,
}: {
  color: string;
  children?: ReactNode;
}) => (
  <StyledTag color={color}>
    <Ellipsis gutter={0}>{children}</Ellipsis>
  </StyledTag>
);

export const StatusTag = ({ status }: { status: TransactionStatus }) => {
  const { t } = useTranslation();

  const colorByStatus: Record<TransactionStatus, string> = {
    auth: 'orange',
    cleared: 'green',
    refund: 'red',
    qualified: 'default',
    rejected: 'red',
    pending: 'orange',
  };

  return (
    <TransactionTag color={colorByStatus[status]}>
      {/* TODO Fix 'any' below in OFF-1412 */}
      {t(`transactions.status.${status?.toLowerCase()}` as any)}
    </TransactionTag>
  );
};
