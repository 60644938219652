import React from 'react';

import { useAppSelector } from '../../../store/hooks';

export default function ContentProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const isContentProvider = useAppSelector(
    ({ account }) => !!account.contentProviderInfo,
  );

  if (!isContentProvider) {
    return null;
  }

  return <>{children}</>;
}
