import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Row, Checkbox, Col, Tooltip } from 'antd';
import Form from '../../../../../components/form';
import { CreateUpdateOfferFormModel } from '../../../types';
import createUpdateOfferRules from '../../validation';
import { colors } from '../../../../../theme';
import { QuestionMark } from '../../../../../components/icons';
import { TooltipWrapper } from '../../styled';
import PercentageSliderWithInput from './PercentageSliderWithInput';
import { Label } from '../../styled/add-locations-form';

export default function FeeCustomisationInput() {
  const { t } = useTranslation('offers');

  const { watch } = useFormContext<CreateUpdateOfferFormModel>();

  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext<CreateUpdateOfferFormModel>();

  const customiseFees = watch('customiseFees');

  const fidelFee = watch('fidelFee');
  const publisherFee = watch('publisherFee');

  const formatCardholderRewardCalculation = () => {
    // if not defined initially, defaults to 25, otherwise use value or 0
    const publisherFeeCommission = publisherFee == 0 ? 0 : publisherFee ?? 25;
    // if not defined initially, defaults to 25, otherwise use value or 0
    const fidelFeeCommission = fidelFee == 0 ? 0 : fidelFee ?? 25;

    const cardholderReward = Math.max(
      0,
      100 - publisherFeeCommission - fidelFeeCommission,
    ).toFixed(2);
    return `${cardholderReward}${t('fields.reward.percentage')}`;
  };

  const hasError = !!errors.publisherFee || !!errors.fidelFee;

  return (
    <>
      <Form.ItemController
        errors={errors}
        controller={{
          name: 'customiseFees',
          render: ({ field: { value, onChange, ...rest } }) => (
            <Row>
              <Col>
                <Checkbox
                  {...rest}
                  onChange={({ target }) => onChange(target.checked)}
                  checked={value}
                  data-testid="customise-fees"
                >
                  <Trans
                    i18nKey="createUpdate.customiseFees.title"
                    ns="offers"
                  />
                </Checkbox>
              </Col>

              <Col>
                <TooltipWrapper>
                  <Tooltip
                    title={t('createUpdate.customiseFees.tooltip')}
                    placement="bottomRight"
                  >
                    <QuestionMark />
                  </Tooltip>
                </TooltipWrapper>
              </Col>
            </Row>
          ),
          control,
        }}
      />
      {customiseFees ? (
        <Row style={{ display: 'flex', marginTop: '0.5rem' }}>
          <div style={{ width: '100%' }}>
            <Form.ItemController
              label={
                <span
                  style={{
                    fontSize: '14px',
                    letterSpacing: '0.3px',
                    color: colors.black,
                  }}
                >
                  {t('createUpdate.customiseFees.fidelFee.label')}
                </span>
              }
              errors={errors}
              controller={{
                name: 'fidelFee',
                render: ({ field: { value, onChange } }) => (
                  <PercentageSliderWithInput
                    min={0}
                    max={100}
                    step={0.01}
                    value={value}
                    name="fidelFee"
                    onChange={target => {
                      onChange(target);
                    }}
                    onBlur={() => trigger('publisherFee')}
                  />
                ),
                rules: {
                  ...createUpdateOfferRules.fidelFee,
                  required: customiseFees,
                  validate: {
                    feesCustomisationSurpass: value =>
                      value + publisherFee < 100,
                  },
                },
                control,
              }}
            />
          </div>
          <div style={{ width: '100%' }}>
            <Form.ItemController
              label={
                <span
                  style={{
                    fontSize: '14px',
                    letterSpacing: '0.3px',
                    color: colors.black,
                  }}
                >
                  {t('createUpdate.customiseFees.publisherFee.label')}
                </span>
              }
              errors={errors}
              controller={{
                name: 'publisherFee',
                render: ({ field: { value, onChange } }) => (
                  <PercentageSliderWithInput
                    min={0}
                    max={100}
                    step={0.01}
                    value={value}
                    name="publisherFee"
                    onChange={target => {
                      onChange(target);
                    }}
                    onBlur={() => trigger('fidelFee')}
                  />
                ),
                rules: {
                  ...createUpdateOfferRules.publisherFee,
                  required: customiseFees,
                  validate: {
                    feesCustomisationSurpass: value => value + fidelFee < 100,
                  },
                },
                control,
              }}
            />
          </div>
          {!hasError && (
            <div style={{ width: '100%' }}>
              <Label>
                {t('createUpdate.customiseFees.cardholderCashbackLabel')}
                {formatCardholderRewardCalculation()}
              </Label>
            </div>
          )}
        </Row>
      ) : (
        <>{/* we need an empty element that is not null, to satisfy antd */}</>
      )}
    </>
  );
}
