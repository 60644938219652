import { Checkbox, Input } from 'antd';
import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import drawerTransition from '../../../../../components/drawer-transition';
import Form from '../../../../../components/form';
import { FieldWithCurrencyWrapper } from '../../../../../components/form/styled';
import { TransactionStreamPeriod } from '../../../../../store/account/account-model';
import regex from '../../../../../utils/regex';
import CreateUpdatePeriod, {
  CreateUpdateBillingPeriodProps,
} from '../CreateUpdateBillingPeriod';
import Schemes from './Schemes';
import { useAppSelector } from '../../../../../store/hooks';

const CreateUpdateTSPeriod = ({
  mode,
  onClose,
  visible,
  selectedPeriod,
  afterVisibleChange,
}: CreateUpdateBillingPeriodProps<TransactionStreamPeriod>) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'account.contract.billingPeriods.createUpdate',
  });
  const { t: tPrograms } = useTranslation('programs', {
    keyPrefix: 'programType',
  });
  const billingConfigurations = useAppSelector(
    state => state.account.billingConfigurations.entity,
  );

  const { currency } = billingConfigurations || {};

  const form = useForm<TransactionStreamPeriod>({
    mode: 'onBlur',
    defaultValues: {
      minimumFee: selectedPeriod?.minimumFee,
      disableProration: selectedPeriod?.disableProration ?? false,
      cardPricing: {
        cardVerificationFee: selectedPeriod?.cardPricing.cardVerificationFee,
        cardVerifiedFee: {
          visa: selectedPeriod?.cardPricing.cardVerifiedFee.visa,
          mastercard: selectedPeriod?.cardPricing.cardVerifiedFee.mastercard,
        },
      },
    },
  });
  const {
    control,
    formState: { errors },
  } = form;

  return (
    <FormProvider {...form}>
      <CreateUpdatePeriod
        title={t(`title.${mode || 'create'}`, {
          name: tPrograms('transaction-stream.label'),
        })}
        form={form}
        mode={mode}
        periodKey="transactionStreamPricing"
        onClose={onClose}
        visible={visible}
        selectedPeriod={selectedPeriod}
        afterVisibleChange={afterVisibleChange}
        generatePeriodToSubmit={(event: TransactionStreamPeriod) => {
          const cardVerifiedFee: Record<string, number> = {};
          (['visa', 'mastercard'] as const).forEach(scheme => {
            const value = event.cardPricing.cardVerifiedFee[scheme];
            if (!value) return;

            cardVerifiedFee[scheme] = Number(value);
          });

          return {
            ...event,
            invoiceNumber: Number(event.invoiceNumber),
            minimumFee: Number(event.minimumFee),
            cardPricing: {
              cardVerificationFee: Number(
                event.cardPricing.cardVerificationFee,
              ),
              cardVerifiedFee,
            },
          };
        }}
      >
        <FieldWithCurrencyWrapper>
          <Form.ItemController
            label={t('transactionStream.minFee.label')}
            errors={errors}
            controller={{
              name: 'minimumFee',
              render: ({ field }) => (
                <Input
                  {...field}
                  min={0}
                  prefix={currency}
                  placeholder={t('transactionStream.minFee.placeholder')}
                />
              ),
              rules: {
                required: true,
                min: 0,
                validate: {
                  numeric: value => regex.positiveDecimalNumeric.test(value),
                },
              },
              control,
            }}
          />
        </FieldWithCurrencyWrapper>
        <FieldWithCurrencyWrapper>
          <Form.ItemController
            label={t('transactionStream.cardVerificationFee.label')}
            errors={errors}
            controller={{
              name: 'cardPricing.cardVerificationFee',
              render: ({ field }) => (
                <Input
                  {...field}
                  min={0}
                  prefix={currency}
                  placeholder={t(
                    'transactionStream.cardVerificationFee.placeholder',
                  )}
                />
              ),
              rules: {
                required: true,
                min: 0,
                validate: {
                  numeric: value => regex.positiveDecimalNumeric.test(value),
                },
              },
              control,
            }}
          />
        </FieldWithCurrencyWrapper>
        <Form.ItemController
          errors={errors}
          controller={{
            name: 'disableProration',
            render: ({ field: { value, onChange, ...rest } }) => (
              <Checkbox
                {...rest}
                data-testid="disable-proration"
                checked={value}
                onChange={e => onChange(e.target.checked)}
                style={{ marginRight: '8px' }}
              />
            ),
            control,
          }}
        >
          {t('transactionStream.disableProration')}
        </Form.ItemController>
        <Schemes currency={currency} />
      </CreateUpdatePeriod>
    </FormProvider>
  );
};

export default drawerTransition(CreateUpdateTSPeriod);
