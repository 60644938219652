import { useEffect, useState } from 'react';

import { Offer } from '../../../store/offers/offers-model';
import { useAppSelector } from '../../../store/hooks';

export default function useTransactionOffer(offerId: string) {
  const offers = useAppSelector(state => state.offers.offers.all ?? []);
  const [offer, setOffer] = useState<Offer | undefined>();

  useEffect(() => {
    if (offers?.length > 0)
      setOffer(offers.find(currentOffer => currentOffer.id === offerId));
  }, [offerId, offers]);

  return offer;
}
