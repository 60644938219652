import { ReactElement } from 'react';
import styled from 'styled-components';

import { AlignSizeStyle, Highlight, Sort } from '../BaseTable';
import { StyledSpin } from '../../../components/spin';
import { ReactComponent as Arrow } from '../../../assets/arrow.svg';
import { colors } from '../../../theme';
import Empty from '../../empty';

interface CellProps extends AlignSizeStyle {
  onClick?: any;
  bold?: boolean;
}

export const Cell = styled.span<CellProps>`
  align-self: ${({ verticalAlign }) => verticalAlign};
  min-width: 0;
  flex: ${({ size }) => size} 1 0;
  text-align: ${({ align }) => align};
  ${({ onClick }) => onClick && 'cursor: pointer;'};
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  color: ${({ bold }) => (bold ? colors.grey5 : 'inherit')};
`;

export const Divider = styled.span<{
  highlight?: Highlight;
}>`
  position: sticky;
  left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  font-size: 13px;
  background-color: ${({ highlight }) => highlight?.color || colors.lightgrey7};
  width: calc(100% + 20px);
  border-bottom: 1px solid ${colors.lightgrey2};
`;

export const StyledHeadings = styled.div<{ padding: boolean }>`
  display: flex;
  align-items: center;
  background-color: white;
  z-index: 2;
  position: sticky;
  top: 0;

  font-size: 12px;
  color: #6c788b;

  border-bottom: 1px solid ${colors.lightgrey2};
  padding: ${({ padding }) => (padding ? '8px 4px 8px 10px' : '8px 0 8px 0')};

  .ant-checkbox-wrapper {
    margin-bottom: 0;
  }
`;

export const Table = styled.div<{
  minWidth?: number;
  hideScrollIndicators?: boolean;
}>`
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : 'auto')};
  padding-left: ${({ hideScrollIndicators }) =>
    hideScrollIndicators ? 0 : '20px'};
  width: 100%;
`;

export const TableContainer = styled.div`
  display: flex;
  align-items: stretch;
`;

export const IconWrapper = styled.div`
  font-size: 20px;
  text-align: center;
  width: 41px;
  margin: auto 0 auto auto;
  cursor: pointer;
`;

export const Sorted = styled(Arrow)<{
  direction?: Sort;
  $active?: boolean;
}>`
  color: ${colors.grey5};
  margin-left: 5px;
  ${({ direction }) => direction === 'asc' && 'transform: rotateZ(180deg);'}
  stroke-width: ${({ $active }) => ($active ? 1.5 : 1)}px;
`;

export const CheckboxWrapper = styled.div`
  margin: auto 15px auto 0;
`;

export const Row = styled.div<{
  active: boolean;
  disabled: boolean;
  selected: boolean;
  highlight?: Highlight;
  padding?: boolean;
}>`
  display: flex;

  cursor: ${props => (props.onClick ? 'pointer' : 'default')};

  transition: background-color 0.3s ease;
  background-color: ${({ highlight }) => highlight?.color || 'white'};
  ${({ disabled }) =>
    disabled &&
    `
        background-color: ${colors.grey50};
        color: ${colors.lightGrey9};
  `};
  min-height: 40px;

  font-size: 14px;
  padding: ${({ padding }) => (padding ? '0 4px 0 10px' : 0)};

  border-bottom: 1px solid ${colors.lightgrey2};

  ${props =>
    props.onClick &&
    `
      :hover {
        background-color: ${colors.grey50};

      }
    `}

  ${props =>
    (props.active || props.selected) &&
    `
      background-color: ${colors.lightblue};
    `}

  .ant-checkbox-wrapper {
    margin-bottom: 0;
  }

  .ant-dropdown::before {
    position: unset;
  }
`;

export const RowContent = styled.div<{ hasIcon: boolean }>`
  display: ${({ hasIcon }) => (hasIcon ? 'flex' : 'inline-block')};
  align-items: center;
  width: ${({ hasIcon }) => (hasIcon ? '95%' : '100%')};
`;

export const SortAsc = styled(Arrow)`
  margin-left: 5px;
  transform: rotateZ(180deg);
`;

export const SortDesc = styled(Arrow)`
  margin-left: 5px;
`;

export const AmountCell = styled.div`
  color: ${colors.grey4};

  span:last-of-type {
    color: ${colors.black};
    font-weight: 500;
  }
`;

const bar = `
  min-width: 20px;
  position: sticky;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px 0px;
  z-index: 3;
  left: 0;
  right: 0;
  transition: opacity 0.2s ease 0s;
`;

export const ScrollIndicator = styled.div<{
  $placement: 'left' | 'right';
  $show: boolean;
  $bordered?: boolean;
}>`
  margin-left: ${({ $placement }) => ($placement === 'left' ? -20 : 0)}px;
  background: ${colors.white};
  opacity: ${({ $show }) => ($show ? 1 : 0)};
  ${bar};

  ${({ $bordered, $placement }) =>
    $bordered &&
    `
    margin-left: ${$placement === 'left' ? '0' : 20}px;
    box-shadow: rgb(0 0 0 / 6%) 0px 2px 4px 4px;
    min-width: 0;
  `}
`;

interface BorderedProps {
  bordered?: boolean;
  hasItems?: boolean;
  hasBottomRowElement?: boolean;
  stretch: boolean;
  emptyText?: string | ReactElement | null;
  lockScroll?: boolean;
}

export const Scrollable = styled.div<BorderedProps>`
  overflow: ${({ lockScroll, stretch }) =>
    lockScroll ? 'hidden' : stretch ? 'visible' : 'auto'};
  width: 100%;
  margin-top: 16px;
  height: auto;

  ${({ bordered, hasBottomRowElement, hasItems, emptyText }) =>
    bordered &&
    `color: ${colors.black};
  margin: 0;
  border: ${
    !hasItems && emptyText !== null ? 'none' : `1px solid ${colors.grey2}`
  };
  border-radius: 4px;
  margin-top: 0;
  margin-right: -20px;

  ${StyledHeadings} {
    margin: 0;
    background: ${colors.lightgrey};
    padding: 10px 20px;
    font-size: 12px;
    font-weight: normal;
    color: ${colors.grey4};
    width: calc(100% + 20px);
  }

  ${Table} {
    padding-left: 0;
  }

  ${StyledSpin} {
    padding-top: 20px;
  }

  ${Row} {
    width: calc(100% + 20px);
    padding: 0 20px;

    &:last-of-type {
      ${!hasBottomRowElement && 'border-bottom: none'};
    }
  }

  ${Empty} {
    @media (min-height: 600px) {
      margin: 0 0 20px;
      svg {
        width: 100px;
        height: 100px;
      }
    }
  }`}
`;

export const TopRowElementContainer = styled.div<{ hasItems: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  border-bottom: ${({ hasItems }) =>
    hasItems ? `1px solid ${colors.lightgrey2}` : 'none'};
  margin: 0 -20px;
`;

export const StickyElement = styled.div<{ maxWidth?: number }>`
  position: sticky;
  left: 0;
  margin-left: -20px;
  max-width: ${({ maxWidth }) =>
    maxWidth ? `calc(${maxWidth}px - 20px)` : '100%'};
`;
