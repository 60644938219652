import React, { useEffect, useState, useCallback } from 'react';
import { shallowEqual } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import {
  getAccounts,
  getAccountsCount,
} from '../../store/moderator/moderator-actions';
import ReviewAccount from './components/ReviewAccount';
import InfiniteScrollTable from '../../components/tables/InfiniteScrollTable';
import Ellipsis from '../../components/ellipsis';
import Spin from '../../components/spin';
import {
  AccountStatusType,
  getAccountStatus,
} from '../../utils/account-status';
import memoNoProps from '../../utils/memo-no-props';
import { ThinBlackButton, ThinGreyButton } from '../../components/buttons';
import useLoadAccountAndRedirect from './use-load-account-and-redirect';
import showFirst from '../../utils/show-first';
import { colors } from '../../theme';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { AccountDetails } from '../../store/account/account-model';

const Status = ({ status }: { status: AccountStatusType }) => {
  const { t } = useTranslation();
  const message = t(`moderator.status.${status}`);

  return (
    <>
      <StatusDot status={status} />
      <span>{message}</span>
    </>
  );
};

const Moderator = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [accountReviewing, setAccountReviewing] = useState<any>(null);
  const { accounts, count, loading, loadingAccount } = useAppSelector(
    state => ({
      loading: state.moderator.accounts.loading,
      loadingAccount: state.account.loading,
      accounts: state.moderator.accounts.items,
      count: state.moderator.accounts.count,
    }),
    shallowEqual,
  );
  const dispatch = useAppDispatch();
  const loadAccountAndRedirect = useLoadAccountAndRedirect();

  const loadAccounts = useCallback(() => {
    dispatch(getAccounts());
    dispatch(getAccountsCount());
  }, [dispatch]);

  useEffect(() => loadAccounts(), [loadAccounts]);

  const columns = [
    { heading: t('moderator.headings.companyName') },
    { heading: t('moderator.headings.name') },
    { heading: t('moderator.headings.email') },
    { heading: t('moderator.headings.status'), size: 0.75 },
    { heading: t('moderator.headings.lastActive'), size: 0.75 },
    { heading: '', size: 0.6 },
  ];

  const accountToRow = (account: AccountDetails) => {
    const user = account.userId;
    const status = getAccountStatus(account);

    const actionButton = showFirst([
      status === AccountStatusType.REVIEW && (
        <ThinBlackButton
          type="primary"
          onClick={e => {
            e.stopPropagation();
            setAccountReviewing(account);
          }}
        >
          {t('moderator.reviewAccount')}
        </ThinBlackButton>
      ),
      status !== AccountStatusType.TEST &&
        status !== AccountStatusType.REJECTED && (
          <ThinGreyButton
            type="primary"
            onClick={e => {
              e.stopPropagation();
              loadAccountAndRedirect(account, '/account/admin');
            }}
          >
            {t('moderator.editBilling')}
          </ThinGreyButton>
        ),
    ]);

    return {
      onClick: () => {
        loadAccountAndRedirect(account, '/programs');
        queryClient.removeQueries();
      },
      contents: [
        <Ellipsis>{account.name}</Ellipsis>,
        <Ellipsis>{`${user.nameFirst} ${user.nameLast}`}</Ellipsis>,
        <Ellipsis showTooltip>{user.email}</Ellipsis>,
        <Status status={status} />,
        moment(account.lastActive).fromNow(),
        actionButton,
      ],
    };
  };

  const topContent = (
    <>
      <h2>{t('moderator.title')}</h2>
      <p>{t('moderator.accountsCount', { count })}</p>
    </>
  );

  const tableOrSpinner = loadingAccount ? (
    <>
      {topContent}
      <Spin />
    </>
  ) : (
    <InfiniteScrollTable
      topContent={topContent}
      columns={columns}
      rows={accounts.map(accountToRow)}
      onBottom={() => {
        if (!loading) {
          dispatch(getAccounts(true));
        }
      }}
      onReload={() => loadAccounts()}
      loading={loading}
      total={count}
    />
  );

  return (
    <>
      {tableOrSpinner}
      <ReviewAccount
        account={accountReviewing}
        onClose={() => setAccountReviewing(null)}
        visible={accountReviewing}
      />
    </>
  );
};

const { moderator } = colors;

const statusColor = {
  [AccountStatusType.SUBSCRIBED]: moderator.subscribed,
  [AccountStatusType.REJECTED]: moderator.rejected,
  [AccountStatusType.REVIEW]: moderator.review,
  [AccountStatusType.TEST]: moderator.test,
  [AccountStatusType.LIVE]: moderator.live,
  [AccountStatusType.UNDEFINED]: moderator.test,
};

const StatusDot = styled.span<{ status: AccountStatusType }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;

  background-color: ${p => statusColor[p.status]};
`;

export default memoNoProps(Moderator);
