import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import OnboardingPopover from '../../../../components/onboarding-tour/components/onboarding-popover';
import { OffersMarketplaceOnboardingContext } from '../context/OffersMarketplaceOnboardingContext';
import { OfferMarketplaceOnboardingStepTypes } from '../config';

export const OfferRequestsTabStep = () => {
  const { t } = useTranslation(['common', 'offers']);

  const { completeOnboardingStep, setStep } = useContext(
    OffersMarketplaceOnboardingContext,
  );

  return (
    <OnboardingPopover
      placement="bottomLeft"
      target="menu-item-offers/requests"
      title={t('offers:marketplace.onboarding.offerRequestsTabStep.title')}
      description={
        <span>
          {t('offers:marketplace.onboarding.offerRequestsTabStep.description')}
        </span>
      }
      buttonText={t('common:onboarding.buttons.done')}
      action={() => {
        completeOnboardingStep('dashboardOffersMarketplaceRequestsTab');
        setStep(OfferMarketplaceOnboardingStepTypes.EXIT_TOUR);
      }}
      showSkipTour={false}
      hideOverlay
      wrapperStyle={{}}
    />
  );
};
