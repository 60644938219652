import React, { useContext, useEffect, useCallback, ReactNode } from 'react';
import { shallowEqual } from 'react-redux';

import { selectProgramsAsList } from '../store/programs/programs-selectors';
import useSelectedProgram from './use-selected-program';
import createPersistedState from './create-persisted-state';
import { useAppSelector } from '../store/hooks';

interface StorageRecentPrograms {
  [accountId: string]: {
    live?: string[];
    test?: string[];
  };
}

const useRecentProgramsState = createPersistedState<StorageRecentPrograms>(
  'FIDEL_RECENT_PROGRAMS',
  {},
);
const RecentProgramsContext = React.createContext([{}, () => {}] as any);

export const RecentProgramsProvider = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const [recentPrograms, setRecentPrograms] = useRecentProgramsState();
  const programs = useAppSelector(selectProgramsAsList)();
  const { accountId, isLive } = useAppSelector(
    state => ({ accountId: state.account.details?.id, isLive: state.live }),
    shallowEqual,
  );
  const { selectedProgram } = useSelectedProgram();

  const liveOrTest = isLive ? 'live' : 'test';

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const recentProgramsList =
    ((accountId && recentPrograms[accountId]) || {})[liveOrTest] || [];

  const setRecentProgramsFromList = useCallback(
    (newRecentProgams: string[]) => {
      if (!accountId) return;

      setRecentPrograms({
        ...recentPrograms,
        [accountId]: {
          ...recentPrograms[accountId],
          [liveOrTest]: newRecentProgams,
        },
      });
    },
    [accountId, liveOrTest, recentPrograms, setRecentPrograms],
  );

  useEffect(() => {
    if (!recentProgramsList[0] && programs[0]?.id) {
      setRecentProgramsFromList([
        programs[0]?.id,
        recentProgramsList[1],
        recentProgramsList[2],
      ]);
    }
  }, [programs, recentProgramsList, setRecentProgramsFromList]);

  useEffect(() => {
    const [first, second, third] = recentProgramsList || [];

    if (selectedProgram && first !== selectedProgram.id) {
      if (selectedProgram.id === second) {
        setRecentProgramsFromList([second, first, third]);
        return;
      }

      if (selectedProgram.id === third) {
        setRecentProgramsFromList([third, first, second]);
        return;
      }
      setRecentProgramsFromList([selectedProgram.id, first, second]);
    }
  }, [recentProgramsList, selectedProgram, setRecentProgramsFromList]);

  return (
    <RecentProgramsContext.Provider value={[recentProgramsList]}>
      {children}
    </RecentProgramsContext.Provider>
  );
};

export default function useRecentPrograms() {
  const [recentPrograms] = useContext(RecentProgramsContext);

  return recentPrograms;
}
