import React, { useContext } from 'react';

export interface BrandsContextProps {
  editedBrandsIds: { [key: string]: string };
}

const BrandsContext = React.createContext<BrandsContextProps>({
  editedBrandsIds: {},
});

function useBrands() {
  return useContext(BrandsContext);
}

export { BrandsContext, useBrands };
