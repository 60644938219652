import React from 'react';
import moment from 'moment';

import { VirtualCardTransaction } from '../../../store/virtual-card/virtual-card-model';
import {
  Amount,
  BrandLogo,
  Currency,
  DateTime,
  Line,
  TransactionContainer,
} from './styled/transaction-card';
import Avatar from '../../../components/avatar';
import { buildAddress } from '../../../utils/transform';

interface TransactionProps extends VirtualCardTransaction {
  selected: boolean;
  onClick: (e: unknown) => unknown;
  brand?: {
    name: string;
    logoURL: string;
  };
  date?: string;
}

const TransactionCard = ({
  amount,
  currency,
  date,
  dateTime,
  city,
  postcode,
  merchantName,
  brand,
  onClick,
  selected,
}: TransactionProps) => (
  <TransactionContainer onClick={onClick} $selected={selected}>
    <Line>
      <span>
        <Currency>{currency.toUpperCase()} </Currency>
        <Amount>{amount.toFixed(2)}</Amount>
      </span>
      <DateTime>{moment(dateTime || date).format('DD/MM/YYYY HH:mm')}</DateTime>
    </Line>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {brand && (
        <BrandLogo>
          <Avatar src={brand?.logoURL}>{brand?.name}</Avatar>
        </BrandLogo>
      )}
      <div>
        {merchantName && <Line>{merchantName}</Line>}
        <Line>{buildAddress({ city, postcode })}</Line>
      </div>
    </div>
  </TransactionContainer>
);

export default TransactionCard;
