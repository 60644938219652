import { useEffect, useMemo } from 'react';
import { shallowEqual } from 'react-redux';

import { getLocations } from '../store/locations/locations-actions';
import { AccountDetails } from '../store/account/account-model';
import { Location } from '../store/locations/locations-model';
import schemes, { getEnabledSchemesForAccount } from '../utils/schemes';
import useTransactionStream from './use-transaction-stream';
import { useAppDispatch, useAppSelector } from '../store/hooks';

function getLocationsSchemesStatuses(
  locations: Location[] | undefined,
  accountDetails: AccountDetails,
) {
  const accountSchemeStatus = getEnabledSchemesForAccount(accountDetails);

  return (locations || [])
    .map(location =>
      schemes
        .filter(scheme => accountSchemeStatus[scheme])
        .map(scheme => location[scheme]?.status),
    )
    .reduce((result, statuses) => [...result, ...statuses], []);
}

const useProgramLocationsStatuses = (programId: string) => {
  const dispatch = useAppDispatch();
  const { hasAccountTransactionStream } = useTransactionStream();
  const locations = useAppSelector(state => {
    const programLocations = state.locations.locations[programId];
    if (!programLocations) return [];

    return programLocations.items;
  }, shallowEqual);
  const accountDetails = useAppSelector(state => state.account.details);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const locationsStatuses = accountDetails
    ? getLocationsSchemesStatuses(locations, accountDetails)
    : [];

  const hasSyncedLocations = useMemo(
    () =>
      locationsStatuses.some(
        status => status === 'active' || status === 'sync',
      ),
    [locationsStatuses],
  );

  const hasIdleLocations = useMemo(
    () => locationsStatuses.some(status => status === 'inactive'),
    [locationsStatuses],
  );

  useEffect(() => {
    if (programId && !hasAccountTransactionStream) {
      dispatch(getLocations(programId));
    }
  }, [dispatch, programId, hasAccountTransactionStream]);

  return {
    hasSyncedLocations,
    hasIdleLocations,
  };
};

export default useProgramLocationsStatuses;
