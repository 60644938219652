import { useEffect, useCallback } from 'react';
import { shallowEqual } from 'react-redux';

import {
  getAllLocations,
  getLocationsCount,
} from '../../../../../../store/locations/locations-actions';
import { Offer } from '../../../../../../store/offers/offers-model';
import { Program } from '../../../../../../store/programs/programs-reducer';
import useShortPolling from '../../../../../../hooks/use-short-polling';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';

export default function useMapUniqueLocationsToProgram(
  { brandId, countryCode }: Partial<Offer>,
  selectedProgram: Program,
) {
  const dispatch = useAppDispatch();

  const {
    isRequestingToMap,
    selectedLocationsToMapCount,
    programLocationsCount,
  } = useAppSelector(
    ({ locations }) => ({
      isRequestingToMap: locations.mapUniqueLocationsToProgramStatus.loading,
      selectedLocationsToMapCount:
        locations.mapUniqueLocationsToProgramStatus
          .selectedLocationsToMapCount ?? 0,
      programLocationsCount: locations.counts[selectedProgram.id]?.count ?? 0,
    }),
    shallowEqual,
  );

  const pollingStopCondition =
    selectedLocationsToMapCount > 0 &&
    selectedLocationsToMapCount === programLocationsCount;

  const fetchLocationsCount = useCallback(() => {
    dispatch(
      getLocationsCount(selectedProgram.id, {
        brandId,
        countryCode,
      }),
    );
  }, [selectedProgram.id, brandId, countryCode, dispatch]);

  const { isLoading, hasFinished } = useShortPolling({
    shouldStart: isRequestingToMap,
    itemsCountToFetch: selectedLocationsToMapCount,
    actionCallback: fetchLocationsCount,
    stopCondition: pollingStopCondition,
  });

  useEffect(() => {
    if (isRequestingToMap) fetchLocationsCount();
  }, [isRequestingToMap, fetchLocationsCount]);

  useEffect(() => {
    if (hasFinished) {
      dispatch(getAllLocations([selectedProgram], { brandId, countryCode }));
      dispatch(getLocationsCount(selectedProgram.id));
    }
  }, [hasFinished, selectedProgram, dispatch, brandId, countryCode]);

  return {
    isLoading,
    hasFinished,
  };
}
