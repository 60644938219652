import { useLayoutEffect, useRef } from 'react';
import { Input, Select } from 'antd';

import { RefSelectProps } from '../components/select';

type FocusableField = Input | (typeof Select & RefSelectProps);

function useFocusField<T extends FocusableField>() {
  const fieldRef = useRef<T>(null);

  useLayoutEffect(() => {
    const immediate = setTimeout(() => fieldRef?.current?.focus());
    return () => {
      clearTimeout(immediate);
    };
  }, [fieldRef]);

  return fieldRef;
}

export default useFocusField;
