import { memoize } from 'lodash';
import { useState, useEffect } from 'react';

export default function createPersistedState<T>(key: string, initialValue: T) {
  // Memoize to avoid unnecessary re-renders for same object
  const jsonParse = memoize(JSON.parse);

  // Each instance of the hook has its own state/setState so that components re-render
  // When one components sets state, the rest need to be called to keep them in sync
  const stateSetters = new Set<(value: T) => void>();

  const setValue = (value: T) => {
    stateSetters.forEach(stateSetter => stateSetter(value));
    localStorage.setItem(key, JSON.stringify(value));
  };

  return function usePersistedState(): [T, (value: T) => void] {
    const [storedValue, setStoredValue] = useState<T>(() => {
      try {
        const item = localStorage.getItem(key);
        return item ? jsonParse(item) : initialValue;
      } catch (error) {
        return initialValue;
      }
    });

    useEffect(() => {
      const setState = (value: T) => {
        try {
          setStoredValue(value);
        } catch (error) {
          // Nothing
        }
      };

      stateSetters.add(setState);
      return () => {
        stateSetters.delete(setState);
      };
    }, []);

    return [storedValue, setValue];
  };
}
