import { Button, Menu as AntMenu, Dropdown, Row } from 'antd';
import { NavLink } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

import { Caron as CaronIcon } from '../../../components/icons';
import { colors, breakpoints, hiddenScrollbar } from '../../../theme';

const transitionDuration = '0.5s';

interface HasSubmenu {
  hasSubmenu: boolean;
}

export const Container = styled.div`
  height: 100%;
  padding: 30px 20px 15px;
  display: flex;
  flex-flow: column nowrap;
`;

export const Bottom = styled.div`
  margin-top: auto;
  margin-bottom: 4px;
  padding: 0 10px;
`;

const bottomLinkShared = `
  margin-right: 5px;
  font-size: 12px;
`;

export const BottomLink = styled.a`
  ${bottomLinkShared};
  :hover,
  :focus {
    text-decoration: underline;
  }
`;

export const BottomLinkDot = styled.span`
  ${bottomLinkShared};
  color: ${colors.grey4};
`;

export const Divider = styled.div`
  padding: 8px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid ${colors.grey2};
`;

export const MenuContainer = styled.div<HasSubmenu>`
  height: calc(100% - 130px);
  margin-top: ${({ hasSubmenu }) => (hasSubmenu ? '24px' : '60px')};

  overflow-y: scroll;
  overflow-x: hidden;
  ${hiddenScrollbar}

  @media (min-width: ${breakpoints.tabletPortrait}px) {
    width: 540px;
    position: relative;
    margin-top: 0;
    transform: ${({ hasSubmenu }) =>
      hasSubmenu ? 'translateX(-300px)' : 'translateX(0)'};
    transition: transform ${transitionDuration};
  }
`;

export const Menu = styled(AntMenu)`
  max-height: calc(100% - 130px);
  max-width: 220px;
`;

export const SubMenu = styled(AntMenu.ItemGroup)<{
  visible?: boolean;
}>`
  display: ${({ visible }) => (visible ? 'initial' : 'none')};
  @media (min-width: ${breakpoints.tabletPortrait}px) {
    position: absolute;
    max-width: 220px;
    top: 20px;
    left: 300px;
    width: 100%;
    display: initial;
    visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    transition: opacity ${transitionDuration}, visibility ${transitionDuration};
  }
  .ant-menu-item-group-title {
    height: auto;
    padding: 0;
  }
`;

export const SubMenuTitle = styled.div`
  color: ${colors.black};
  font-size: 15px;
  font-weight: 500;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
`;

export const MenuItem = styled(AntMenu.Item)<{
  count?: number;
  visible?: boolean;
}>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  align-items: center;
  border-radius: 5px;
  padding: 0 17px !important;
  transition-duration: 0.1s;
  min-height: 44px;

  svg {
    margin-right: 18px;
  }

  &.ant-menu-item {
    a {
      display: inline-flex;
      align-items: center;
    }
  }

  &.ant-menu-item-selected a,
  a.active {
    color: ${colors.fidelBlue600} !important;
    font-weight: 500;
  }

  &:not(.ant-menu-item-selected) {
    svg {
      fill: transparent;

      path,
      rect {
        fill: ${colors.grey4};
      }

      .svg-bg {
        fill: none;
      }
    }
  }

  a::after,
  a.active::after {
    content: '${({ count }) => count}';
    position: absolute;
    right: 10px;
  }

  @media (min-width: ${breakpoints.tabletPortrait}px) {
    position: absolute;
    display: flex;
  }
`;

export const NavItemHighlighter = styled.span.attrs(() => ({
  'data-testid': 'nav-item-highlighter',
}))`
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 8px;
  background-color: ${colors.fidelBlue600};
  border: 4px solid ${colors.lightblue2};
  border-radius: 50%;
`;

export const ReleaseWidgetStyle = createGlobalStyle`
  #HW_badge_cont {
    display: inline !important;
    margin-left: 2px;
  }

  #HW_badge {
    display: inline-block !important;
    position: initial !important;
    background: transparent !important;
    color: ${colors.fidelBlue600} !important;
    &.HW_softHidden {
      transform: scale(1) !important;
      :before {
        content: '(0';
      }
    }
    :before {
      content: '(';
    }
    :after {
      content: ')';
    }
  }
`;

export const LogoContainer = styled(NavLink)`
  margin-right: auto;
  display: flex;
  justify-content: center;
`;

export const LiveToggleContainer = styled.div`
  .ant-switch {
    min-width: 32px;
    height: 18px;

    .ant-switch-handle {
      width: 14px;
      height: 14px;
    }
    &.ant-switch-checked {
      .ant-switch-handle {
        left: calc(100% - 14px - 2px);
      }
    }
  }
`;

export const LiveToggleSwitch = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
`;

export const GoBackButtonContainer = styled.div<HasSubmenu>`
  display: ${({ hasSubmenu }) => (hasSubmenu ? 'initial' : 'none')};
  @media (min-width: ${breakpoints.tabletPortrait}px) {
    position: relative;
    display: initial;
    transform: ${({ hasSubmenu }) =>
      hasSubmenu ? 'translateX(0)' : 'translateX(300px)'};
    opacity: ${({ hasSubmenu }) => (hasSubmenu ? 1 : 0)};
    transition: opacity ${transitionDuration}, transform ${transitionDuration};
  }
`;

export const Caron = styled(CaronIcon)`
  height: 10px;
  transform: rotateZ(90deg);
  font-size: 10px;
  position: relative;
  top: -1px;
`;

export const GoBackButton = styled(Button)`
  align-items: center;
  align-self: flex-start;
  color: ${colors.grey5};
  margin-top: 44px;
  padding: 4px 10px;
  text-align: left;
  transition-duration: 0.1s;
  font-weight: normal;

  > .anticon + span {
    margin-left: 10px;
  }

  &:active,
  &:hover {
    color: ${colors.grey5};
    background-color: ${colors.lightgrey2};
  }
`;

export const SwitchContainer = styled.div<{ $disabled?: boolean }>`
  user-select: none;
  &:before {
    content: '';
    width: 32px;
    height: 25px;
    position: absolute;
    z-index: ${({ $disabled }) => ($disabled ? '2' : '0')};
  }

  .ant-switch-disabled {
    opacity: 1;
  }
`;

export const HelpDropdown = styled(Dropdown)`
  ${bottomLinkShared};
  color: ${colors.fidelBlue600};
  cursor: pointer;
  :hover,
  :focus {
    text-decoration: underline;
    opacity: 0.8;
  }
`;

export const BottomRow = styled(Row)`
  margin-top: 1rem;
`;
