import styled from 'styled-components';
import { Button } from 'antd';

import { Arrow } from '../../components/icons';
import { colors } from '../../theme';

export const FiltersButton = styled(Button)`
  color: ${colors.grey5};
  background: ${colors.lightgrey3};
  border: none;
  font-size: 14px;
  font-weight: normal;
  :hover,
  &.ant-dropdown-open {
    background: ${colors.black};
    color: white;
  }
`;

export const NumberOfActiveFilters = styled.span<{ number: number }>`
  display: ${({ number }) => (number ? 'inline-block' : 'none')} !important;
  &::after {
    content: '${({ number }) => number}';
    padding: 1px 5px;
    margin-left: 5px;
    font-size: 11px;
    font-weight: 700;
    color: white;
    background: ${colors.fidelBlue600};
    border-radius: 4px;
  }
`;

export const CheckboxContainer = styled.div<{ padding?: boolean }>`
  margin-left: -14px;
  margin-right: -14px;
  .ant-checkbox-wrapper {
    display: flex;
    align-items: baseline;
    padding: 4px 14px;
    width: 100%;
    color: ${colors.black};
    margin-bottom: 0;
    font-size: 14px;

    :hover {
      background: ${colors.lightgrey2};
    }

    .ant-checkbox {
      position: relative;
      top: 5px;
    }
  }

  .ant-form-item-with-help {
    margin-bottom: 0;
  }

  .ant-form-item-control {
    padding: ${({ padding }) => (padding ? '7px 14px' : 0)};
  }

  .ant-form-item-explain-error {
    margin-top: 0;
  }

  .ant-select-multiple .ant-select-selection-item {
    background-color: ${colors.white};
    border-color: ${colors.white};
  }

  :first-of-type {
    margin-top: 14px;
  }

  :last-of-type {
    margin-bottom: 14px;
  }
`;

export const RightArrow = styled(Arrow)`
  transform: rotate(-90deg);
  font-size: 12px;
  vertical-align: -0.1em;
  margin-left: -10px;
  margin-right: 10px;

  svg {
    fill: none;

    path {
      stroke: ${colors.grey3};
    }
  }
`;
