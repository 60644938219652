import styled from 'styled-components';
import { Button as AntdButton } from 'antd';
import { colors } from '../../theme';

// This file contains buttons which override Antd's Button

function disableAnimationMixin<P extends { $disableAnimation?: boolean }>(
  props: P,
) {
  return props.$disableAnimation
    ? `
    &[ant-click-animating-without-extra-node='true']::after,
    &.ant-click-animating-node,
    .ant-click-animating-node {
      animation-duration: 0s !important;
    }
  `
    : '';
}

export const BlackButton = styled(AntdButton)`
  background: ${colors.black};
  color: ${colors.white};
  border: none;

  &:hover {
    background: ${colors.fidelBlue600};
    color: ${colors.white};
    border: none;
  }
`;

export const FormButton = styled(AntdButton)`
  border: none;
  font-weight: normal;
  background: ${colors.lightgrey3};
  color: ${colors.grey5};

  :hover,
  :focus {
    background: ${colors.lightgrey2};
    color: ${colors.grey5};
  }
`;

export const GreyButton = styled(AntdButton)`
  border: none;
  background: ${colors.lightgrey3};
  color: ${colors.grey4};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:active,
  &:focus {
    background: ${colors.black};
    color: ${colors.white};
  }
`;

export const GreyDropdownButton = styled(GreyButton).attrs(() => ({
  size: 'small',
}))<{
  $controlledVisibility?: boolean;
  $disableAnimation?: boolean;
}>`
  font-weight: normal;

  ${disableAnimationMixin}

  span + .anticon {
    margin-left: 12px;
    position: relative;
    top: 1px;
  }

  &.ant-dropdown-open {
    background: ${props =>
      props.$controlledVisibility ? colors.black : colors.lightgrey3};
    color: ${props => (props.$controlledVisibility ? 'white' : colors.grey4)};
  }
`;

GreyDropdownButton.defaultProps = {
  $controlledVisibility: true,
};

export const InlineButton = styled(AntdButton)`
  padding: 0;
  height: 1em;
  font-weight: normal;
  :hover {
    background: none;
  }
`;

export const LinkButton = styled(AntdButton).attrs(() => ({
  type: 'link',
}))`
  margin: 0;
  padding: 0;
  border: none;
  font-size: 12px;
  height: unset;

  &.ant-btn {
    font-weight: 400;
  }

  &:hover {
    background: unset;
  }
`;

export const SecondaryLinkButton = styled(AntdButton).attrs(() => ({
  type: 'link',
}))`
  &.ant-btn-link {
    color: ${colors.grey5};
    font-weight: normal;
    font-size: 14px;
    margin: 0 6px;
    border: none;
    :hover {
      color: ${colors.grey4};
    }
  }
`;

export const GreenButton = styled(AntdButton).attrs(() => ({
  type: 'primary',
}))`
  background: ${colors.darkgreen};
  border-color: ${colors.darkgreen};

  :hover,
  :active,
  :focus {
    background: ${colors.green};
    border-color: ${colors.green};
  }
`;

const thinButtonMixin = `
  padding: 0 3px;
  width: 100%;
  height: 2em;
`;

export const ThinBlackButton = styled(BlackButton)`
  ${thinButtonMixin}
`;

export const ThinGreyButton = styled(GreyButton)`
  ${thinButtonMixin}
`;

export const SecondaryButton = styled(AntdButton)`
  background: ${colors.lightblue};
  color: ${colors.fidelBlue600};
  border: 0;

  :hover,
  :active,
  :focus {
    background: ${colors.lightblue5};
  }
`;
