import { sdk } from '../config';

export default function buildSDKScript() {
  const script = document.createElement('script');
  const cacheBuster = new Date().getTime();

  script.src = `${sdk.URL}?ts=${cacheBuster}`;
  script.setAttribute('data-fidel-env', sdk.ENV);

  return script;
}
