import styled from 'styled-components';
import { colors } from '../../../theme';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Created = styled.span`
  color: ${colors.grey4};
`;
