import React from 'react';
import { OnboardingKeys, OnboardingStepTypes } from './onboarding-config';
import { LastCursorPositionProps } from './index';

interface OnboardingTourContextProps {
  enabled: boolean;
  step: OnboardingStepTypes;
  setStep: (step: OnboardingStepTypes) => void;
  lastTourStep: OnboardingStepTypes;
  setLastTourStep: (step: OnboardingStepTypes) => void;
  completeOnboardingStep: (step: OnboardingKeys) => void;
  lastCursorPosition: LastCursorPositionProps;
  setLastCursorPosition: (lastCursorPosition: LastCursorPositionProps) => void;
  toggleOnboardingOff: () => void;
}

export const OnboardingTourContext = React.createContext(
  {} as OnboardingTourContextProps,
);
