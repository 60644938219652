import React, { useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CreateUpdateOfferFormModel, OfferProviderType } from '../../../types';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import useIsSignedIn from '../../../../../hooks/use-is-signed-in';
import { Select } from '../../../../../components/select';
import {
  compileSupplierFields,
  findFieldValue,
  findProviderInfo,
  SUPPORTED_REPORTING_PROVIDERS,
} from '../../utils';
import filterFalsy from '../../../../../utils/filter-falsy';
import Form from '../../../../../components/form';
import { CustomCollapse } from '../../../../../components/collapse/styled';
import KeyValuePairs, {
  KeyValuePairsDefaultFields,
} from '../../../../../components/form/components/KeyValuePairs';
import createUpdateOfferRules from '../../validation';
import { getReportingProviders } from '../../../../../store/offers';

export interface ReportingProviderProps {
  formModel: CreateUpdateOfferFormModel | null;
  disabled: boolean;
}

export default function ReportingProvider({
  formModel,
  disabled,
}: ReportingProviderProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('offers');
  const { reportingProviders } = useAppSelector(
    ({ offers }) => offers,
    shallowEqual,
  );
  const { isModerator } = useIsSignedIn();

  const {
    formState: { errors },
    control,
    setValue,
    watch,
  } = useFormContext<CreateUpdateOfferFormModel>();

  const { current: defaultSupplierFields } = useRef(
    formModel?.supplierInfo ?? [],
  );

  const [supplierFields, setSupplierFields] =
    useState<KeyValuePairsDefaultFields>(defaultSupplierFields);

  const selectedSupplier = watch('supplier');

  const providerInfo = findProviderInfo(
    reportingProviders,
    selectedSupplier ?? '',
  );

  const fieldsList = useMemo(
    () => compileSupplierFields(providerInfo),
    [providerInfo],
  );

  const compileFieldsInfo = useMemo(
    () =>
      fieldsList.map(fieldName => ({
        key: fieldName,
        value: findFieldValue(defaultSupplierFields, fieldName),
      })),
    [fieldsList, defaultSupplierFields],
  );

  const supportedSuppliersInfo: OfferProviderType[] = filterFalsy(
    SUPPORTED_REPORTING_PROVIDERS.map(supplier =>
      findProviderInfo(reportingProviders, supplier),
    ),
  );

  const reportingProvidersList = isModerator
    ? reportingProviders
    : supportedSuppliersInfo;

  useEffect(() => {
    if (!reportingProviders.length) dispatch(getReportingProviders());
  }, [dispatch, reportingProviders]);

  useEffect(() => {
    if (formModel?.supplier) setValue('supplier', formModel?.supplier);
  }, [formModel?.supplier, setValue]);

  useEffect(() => {
    setSupplierFields(compileFieldsInfo);
  }, [compileFieldsInfo]);

  const reportingProvidersDropdown = reportingProvidersList.map(
    ({ identifier, name }: OfferProviderType) => (
      <Select.Option value={identifier} key={`${identifier}-supplier`}>
        {name}
      </Select.Option>
    ),
  );

  return (
    <>
      {reportingProvidersDropdown.length ? (
        <Form.Item label={t('fields.reportingProvider.label')}>
          <Controller
            name="supplier"
            render={({ field }) => (
              <Select
                {...field}
                data-name="supplier"
                style={{ width: '100%' }}
                placeholder={t('createUpdate.optional')}
                defaultValue={formModel?.supplier}
                disabled={disabled}
                allowClear
              >
                {reportingProvidersDropdown}
              </Select>
            )}
            control={control}
          />
        </Form.Item>
      ) : null}

      {fieldsList.length ? (
        <CustomCollapse defaultActiveKey="collapse-panel-supplierInfo">
          {supplierFields.length && (
            <CustomCollapse.Panel
              header={t('fields.reportingProvider.info')}
              key="collapse-panel-supplierInfo"
            >
              <KeyValuePairs
                name="supplierInfo"
                errors={errors?.supplierInfo}
                fixedFields
                defaultFields={supplierFields}
                valueField={{
                  rules: createUpdateOfferRules.supplierInfo,
                }}
                disabled={disabled}
              />
            </CustomCollapse.Panel>
          )}
        </CustomCollapse>
      ) : null}
    </>
  );
}
