import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { Grid, Row, Col, Space } from 'antd';
import { getProfile } from '../../../../store/account/account-actions';
import { FieldProps, ProfileFormModel } from './types';
import Spin from '../../../../components/spin';
import FieldSection from './FieldSection';
import { StyledFooterText } from './styled';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

const { useBreakpoint } = Grid;

export const fields: FieldProps[] = [
  {
    name: 'company',
    type: 'input',
  },
  {
    name: 'pitch',
    type: 'textarea',
  },
  {
    name: 'websiteUrl',
    type: 'input',
    validation: 'url',
  },
  {
    name: 'androidAppUrl',
    type: 'input',
    validation: 'url',
  },
  {
    name: 'iosAppUrl',
    type: 'input',
    validation: 'url',
  },
  {
    name: 'audience',
    type: 'textarea',
  },
  {
    name: 'audienceSize',
    type: 'textarea',
  },
  {
    name: 'growthForecast',
    type: 'textarea',
  },
];

const Profile = () => {
  const { t } = useTranslation();
  const { xs: isXsBreakpoint } = useBreakpoint();
  const dispatch = useAppDispatch();
  const gettingProfile = useAppSelector(
    ({ account }) => account.profile.loading,
  );
  const form = useForm<ProfileFormModel>({
    mode: 'onChange',
    defaultValues: {
      company: '',
      pitch: '',
      websiteUrl: '',
      androidAppUrl: '',
      iosAppUrl: '',
      audience: '',
      audienceSize: '',
      growthForecast: '',
    },
  });

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  return gettingProfile ? (
    <Spin />
  ) : (
    <Space direction="vertical" size={32}>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <FormProvider {...form}>
            {fields.map(fieldProps => (
              <FieldSection
                key={fieldProps.name}
                fieldProps={fieldProps}
                form={form}
                isMobile={!!isXsBreakpoint}
              />
            ))}
          </FormProvider>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <StyledFooterText $fontSize={12}>
            {t('account.publisherProfile.footer')}
          </StyledFooterText>
        </Col>
      </Row>
    </Space>
  );
};

export default Profile;
