import React from 'react';
import styled from 'styled-components';
import useRedirect from '../../../hooks/use-redirect';
import FidelLogo from '../../../components/fidel-logo';

interface LogoProps {
  centered?: boolean;
}

const LogoContainer = styled.div<LogoProps>`
  padding: 15px 0;
  cursor: pointer;
  text-align: ${p => (p.centered ? 'center' : 'initial')};
`;

const Logo = ({ centered }: LogoProps) => {
  const redirect = useRedirect();

  return (
    <LogoContainer centered={centered} onClick={() => redirect('/')}>
      <FidelLogo type="solo" size="xl" color="black" />
    </LogoContainer>
  );
};

export default React.memo(Logo);
