import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { QuestionMark } from '../../../../components/icons';
import useDebounce from '../../../../hooks/use-debounce';
import OfferIcon from '../../components/OfferIcon';
import { StyledSpan } from '../styled';
import { DetailName, DetailField, DetailFieldsWithTooltip } from '../types';

export default function FieldLabel({
  name,
  showTooltip,
}: Pick<DetailField, 'name' | 'showTooltip'>) {
  const { t } = useTranslation('offers', { keyPrefix: 'fields' });

  const [hoveredDetail, setHoveredDetail] = useState<DetailName>();
  const debouncedHoveredDetail = useDebounce(hoveredDetail, 250);

  return (
    <StyledSpan
      onMouseEnter={() => setHoveredDetail(name)}
      onMouseLeave={() => setHoveredDetail(undefined)}
    >
      {t(`${name}.label`)}
      {showTooltip && debouncedHoveredDetail === name && (
        <OfferIcon
          icon={<QuestionMark data-testid="question-mark-icon" />}
          tooltip={{
            title: t(`${name as DetailFieldsWithTooltip}.tooltip`),
            placement: 'bottomLeft',
          }}
        />
      )}
    </StyledSpan>
  );
}
