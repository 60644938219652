import styled from 'styled-components';

export const FeatureContainer = styled.div`
  display: flex;
  margin-bottom: 32px;
  align-items: center;
`;

export const FeatureDescription = styled.div`
  font-size: 15px;
  b {
    font-weight: 500;
  }
`;

export const IconContainer = styled.div`
  width: 36px;
  height: 36px;
  margin-right: 16px;
`;
