import React, { ReactElement, useContext } from 'react';
import { createPortal } from 'react-dom';

import { TooltipPlacement } from 'antd/lib/tooltip';
import { Button } from 'antd';

import { useTranslation } from 'react-i18next';
import {
  OnboardingPopoverContent,
  OnboardingPopoverDescription,
  OnboardingPopoverFooter,
  OnboardingPopoverTitle,
  OnboardingPopoverTitleLink,
  OnboardingStyledPopover,
} from './styled';

import { OnboardingStepsIndicator } from '../onboarding-steps-indicator';
import { OnboardingOverlay } from '../../styled';
import { OnboardingTourContext } from '../../onboarding-provider';
import {
  numOfOnboardingIndicatorSteps,
  OnboardingStepTypes,
} from '../../onboarding-config';
import { useOnboardingTarget } from '../../hooks/use-onboarding-target';

export interface CustomButtonProps {
  action: () => void;
  buttonText: string;
}
interface PopoverContentProps {
  title: string;
  description: ReactElement;
  action: () => void;
  showSkipTour: boolean;
  index?: number;
  buttonText: string;
  customButton?: ({ action, buttonText }: CustomButtonProps) => ReactElement;
}

const PopoverContent = ({
  title,
  description,
  action,
  showSkipTour,
  index,
  buttonText,
  customButton,
}: PopoverContentProps) => {
  const { t } = useTranslation();
  const { step, setStep, setLastTourStep } = useContext(OnboardingTourContext);

  return (
    <OnboardingPopoverContent
      onClick={e => e.stopPropagation()}
      data-testid="onboarding-popover-content"
    >
      <OnboardingPopoverTitle>
        <span>{title}</span>
        {showSkipTour ? (
          <OnboardingPopoverTitleLink
            onClick={() => {
              setLastTourStep(step);
              setStep(OnboardingStepTypes.SKIP_TOUR);
            }}
            data-testid="onboarding-popover-skip-tour-button"
          >
            {t('onboarding.buttons.skipTour')}
          </OnboardingPopoverTitleLink>
        ) : null}
      </OnboardingPopoverTitle>
      <OnboardingPopoverDescription>{description}</OnboardingPopoverDescription>
      <OnboardingPopoverFooter>
        {customButton ? (
          customButton({ buttonText, action })
        ) : (
          <Button
            type="primary"
            size="small"
            onClick={() => action()}
            data-testid="onboarding-popover-button"
          >
            {buttonText}
          </Button>
        )}
        {index !== undefined && (
          <OnboardingStepsIndicator
            total={numOfOnboardingIndicatorSteps}
            current={index}
          />
        )}
      </OnboardingPopoverFooter>
    </OnboardingPopoverContent>
  );
};

interface OnboardingPopoverProps extends PopoverContentProps {
  placement: TooltipPlacement;
  target: string;
  hideOverlay?: boolean;
  wrapperStyle?: Record<string, any>;
  ghostElemStyle?: Record<string, any>;
  getPopupContainer?: (trigger: any) => any;
}

const OnboardingPopover = ({
  target,
  placement,
  hideOverlay,
  wrapperStyle,
  ghostElemStyle,
  getPopupContainer,
  ...props
}: OnboardingPopoverProps) => {
  const targetElement = useOnboardingTarget(target);

  return (
    targetElement &&
    createPortal(
      <div
        style={
          wrapperStyle || {
            position: 'relative',
          }
        }
      >
        {!hideOverlay && (
          <OnboardingOverlay
            showTint
            onClick={e => e.stopPropagation()}
            data-testid="onboarding-overlay"
          />
        )}
        <OnboardingStyledPopover
          content={<PopoverContent {...props} />}
          visible
          placement={placement}
          getPopupContainer={getPopupContainer}
          overlayStyle={{ width: '360px', zIndex: 3 }}
        >
          <div
            style={
              ghostElemStyle || {
                width: '100%',
              }
            }
          />
        </OnboardingStyledPopover>
      </div>,
      targetElement,
    )
  );
};
export default OnboardingPopover;
