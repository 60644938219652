import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer } from 'antd';
import UserOutlined from '@ant-design/icons/UserOutlined';

import Avatar from '../../../../components/avatar';
import { Name } from '../../styled/account-info';
import { resources } from '../../../../config';
import { AccountImages } from '../../../../store/account/account-model';
import { updateImages } from '../../../../store/account/account-actions';
import useProgressState from '../../../../hooks/use-progress-state';
import DrawerForm from '../../../../components/drawer-form';
import ImageUpload, {
  CroppedImages,
  ImageVisualizer,
} from '../../../../components/upload/CroppedImageUpload';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

type Action = 'view' | 'edit';

const UpdateProfilePhoto = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { t: tProfilePhoto } = useTranslation('common', {
    keyPrefix: 'account.info.profilePhoto',
  });

  const { accountImage, accountDetails, isUpdating } = useAppSelector(
    ({ account }) => ({
      accountImage: account.images.entities.rounded,
      accountDetails: account.details,
      isUpdating: account.images.updating,
    }),
  );

  const [images, setImages] = useState<AccountImages>();
  const [action, setAction] = useState<Action>();

  const imageUpdate = useProgressState(isUpdating);

  const photo = accountImage || accountDetails?.croppedImagePath;
  const { name } = accountDetails || {};

  const isViewing = action === 'view';
  const isEditing = action === 'edit';

  const { hasFinished } = imageUpdate;

  useEffect(() => {
    hasFinished && isViewing && imageUpdate.reset();
  }, [hasFinished, isViewing, imageUpdate]);

  function changePhotoHandler() {
    setAction('edit');
  }

  function viewPhotoHandler() {
    if (photo) setAction('view');
    else changePhotoHandler();
  }

  function onSubmit() {
    images && dispatch(updateImages(images));
  }

  function onViewClose() {
    !!action && setAction(undefined);
  }

  function onEditClose() {
    setAction(photo ? 'view' : undefined);
  }

  function onImagesCrop({ squared, rounded }: CroppedImages) {
    setImages({ image: squared, croppedImage: rounded });
  }

  const { icon, imageSrc, children, click } = {
    icon: !name && <UserOutlined />,
    imageSrc: photo ? `${resources.BASE_URL}${photo}` : undefined,
    children: !photo && name,
    click: viewPhotoHandler,
  };

  return (
    <>
      <Avatar
        size={72}
        shape="circle"
        icon={icon}
        imageSrc={imageSrc}
        data-testid="profile-photo-avatar"
        onClick={click}
      >
        {children}
      </Avatar>

      <Name>{name}</Name>

      <Drawer
        title={tProfilePhoto('view.title')}
        closable={false}
        onClose={onViewClose}
        visible={!!action}
        width={440}
        footer={null}
        push={{ distance: 8 }}
      >
        <ImageVisualizer
          imagePath={imageSrc}
          onChangeImage={changePhotoHandler}
        />

        <DrawerForm
          title={tProfilePhoto('edit.title')}
          confirmText={t('confirm.save')}
          onClose={onEditClose}
          onSubmit={onSubmit}
          visible={isEditing}
          submitDisabled={!images || isUpdating}
          processing={isUpdating}
          hasFinished={imageUpdate.hasFinished}
        >
          <ImageUpload height={256} onImagesCrop={onImagesCrop} />
        </DrawerForm>
      </Drawer>
    </>
  );
};

export default UpdateProfilePhoto;
