import React from 'react';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { ContentProviderInfo } from '../../../../../store/account/account-model';
import Form from '../../../../../components/form';
import OfferRules from '../../validation';
import { addBusinessDays } from '../../../../../utils/dates';
import { CreateUpdateOfferFormModel, OffersUserRole } from '../../../types';
import DateTimeInput from './DateTimeInput';

export interface StartEndDateTimeProps {
  contentProviderInfo: ContentProviderInfo | undefined;
  isMarketplaceOffer: boolean;
}
export default function StartEndDateTime({
  contentProviderInfo,
  isMarketplaceOffer,
}: StartEndDateTimeProps) {
  const { t } = useTranslation('offers');

  const isContentProvider = !!contentProviderInfo;
  const minOfferExpireDays = contentProviderInfo?.minOfferExpireDays ?? 3;

  const offersUserRole: OffersUserRole = isContentProvider
    ? 'content-provider'
    : `${isMarketplaceOffer ? 'marketplace-' : ''}publisher`;

  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useFormContext<CreateUpdateOfferFormModel>();

  const selectedStartDate = watch('startDate');
  const selectedEndDate = watch('endDate');

  function disableStartDate(currentDate: Moment) {
    return (
      (!selectedStartDate &&
        currentDate.isBefore(moment().subtract(1, 'day').endOf('day'))) ||
      (!!selectedEndDate &&
        currentDate.isSameOrAfter(
          moment(selectedEndDate).subtract(1, 'day').endOf('day'),
        ))
    );
  }

  function disableEndDateForContentProviders(currentDate: Moment) {
    const currentDay = moment().endOf('day');
    const disabledDate = addBusinessDays(minOfferExpireDays, currentDay);

    return currentDate.isSameOrBefore(disabledDate, 'days');
  }

  function disableEndDateForMarketplacePublishers(currentDate: Moment) {
    return currentDate.isSameOrBefore(moment(selectedStartDate).add(1, 'day'));
  }

  function disableEndDateForPublishers(currentDate: Moment) {
    return (
      !!selectedStartDate &&
      currentDate.isSameOrBefore(moment(selectedStartDate).add(1, 'day'))
    );
  }

  const disableEndDate = {
    'content-provider': disableEndDateForContentProviders,
    'marketplace-publisher': disableEndDateForMarketplacePublishers,
    publisher: disableEndDateForPublishers,
  }[offersUserRole];

  return (
    <>
      <Form.Item style={{ marginBottom: 0 }}>
        <DateTimeInput
          date={{
            errors,
            name: 'startDate',
            label: t('fields.startDate.label'),
            rules: OfferRules.startDate,
          }}
          time={{
            name: 'startTime',
            label: t('fields.time.label'),
          }}
          control={control}
          disabledDate={disableStartDate}
          getValues={getValues}
          setValue={setValue}
        />
      </Form.Item>

      <Form.Item
        validateStatus={(errors.endDate || errors.endTime) && 'error'}
        style={{ marginBottom: 0 }}
      >
        <DateTimeInput
          date={{
            name: 'endDate',
            label: t('fields.endDate.label'),
            placeholder: t('createUpdate.optional'),
          }}
          time={{
            name: 'endTime',
            label: t('fields.time.label'),
          }}
          control={control}
          disabledDate={disableEndDate}
          getValues={getValues}
          setValue={setValue}
        />
      </Form.Item>
    </>
  );
}
