import React, { ReactNode } from 'react';
import { uniqueId } from 'lodash';
import styled from 'styled-components';
import { Avatar as AntAvatar, Image } from 'antd';
import { Emoji as EmojiIcon } from 'emoji-mart';
import { Emoji, Sizes, AvatarProps, TextFormat } from './types';
import {
  isEmoji,
  avatarDefaults,
  textColor,
  bgColor,
  border,
  fontSize,
} from './utils';
import Badge from './Badge';

function icon(emoji: Emoji, size: Sizes) {
  return (
    isEmoji(emoji) && {
      icon: <EmojiIcon emoji={emoji} size={size} native />,
    }
  );
}

function getChildren(children: ReactNode, textFormat: TextFormat) {
  if (typeof children !== 'string') return children;

  const upperCased = children.toUpperCase();

  return textFormat === 'first'
    ? upperCased[0]
    : upperCased
        .split(' ')
        .map(word => word[0])
        .join('');
}

function image(imageSrc?: string) {
  return (
    imageSrc && <Image src={`${imageSrc}?id=${uniqueId()}`} preview={false} />
  );
}

const Avatar = ({
  children,
  shape = avatarDefaults.shape,
  size = avatarDefaults.size,
  color = avatarDefaults.color,
  textFormat = avatarDefaults.textFormat,
  imageSrc,
  emoji,
  badge,
  className,
  ...props
}: AvatarProps) => (
  <div className={className}>
    <AntAvatar
      {...{
        shape,
        size,
        ...icon(emoji || '', size),
        src: image(imageSrc),
        ...props,
      }}
    >
      {getChildren(children, textFormat)}
    </AntAvatar>
    {badge && <Badge {...badge} />}
  </div>
);

const StyledAvatar = styled(Avatar)`
  display: inline-block;
  transition: 150ms opacity ease-out;

  .ant-avatar {
    color: ${textColor};
    background-color: ${bgColor};
    border: ${border};

    .ant-avatar-string {
      font-size: ${fontSize}px;
      font-weight: 500;
    }

    &.ant-avatar-icon,
    .emoji-mart-emoji {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .emoji-mart-emoji {
      transform: scale(0.75);
    }
  }

  &:hover {
    ${({ onClick }) =>
      onClick &&
      `
      cursor: pointer;
      opacity: 0.75;
    `}

    .avatar-badge {
      width: 20px;
      height: 20px;

      .badge-icon {
        opacity: 1;
        display: flex;
      }
    }
  }
`;

export default StyledAvatar;
