import styled from 'styled-components';
import { Avatar, Row, Skeleton } from 'antd';

import { colors } from '../../../theme';

export const AccountAvatar = styled(Avatar)`
  font-size: 28px !important;
  font-weight: 500;

  &.ant-avatar {
    background: ${colors.black};
    color: white;
  }
`;

export const InfoSkeleton = styled(Skeleton)`
  .ant-skeleton-title {
    margin-top: 20px !important;
  }
`;

export const Container = styled(Row)`
  padding: 0 0 50px;
  font-size: 14px;
  font-weight: normal;
  color: ${colors.grey5};
`;

export const Name = styled.span`
  display: inline-block;
  margin-left: 20px;
`;
