import React, { useMemo } from 'react';
import { Checkbox } from 'antd';
import {
  Cell,
  CheckboxWrapper,
  IconWrapper,
  Sorted,
  StyledHeadings,
} from '../styled/base';
import { ReactComponent as Reload } from '../assets/reload.svg';
import showFirst from '../../../utils/show-first';
import { Column, defaultSettings } from '../BaseTable';
import { RowData } from './Row';

interface HeadingsProps {
  columns: Column[];
  rowsOnly: RowData[];
  onSelectAll?: (checked: boolean) => unknown;
  hasActionMenu: boolean;
  onReload?: (e: any) => unknown;
  padding?: boolean;
  hasItems: boolean;
}

export default function Headings({
  columns,
  rowsOnly,
  onSelectAll,
  hasActionMenu,
  onReload,
  padding = true,
  hasItems,
}: HeadingsProps) {
  const allSelected = useMemo(
    () => rowsOnly.every(row => row.selected),
    [rowsOnly],
  );
  const someSelected = useMemo(
    () => rowsOnly.some(row => row.selected),
    [rowsOnly],
  );

  function onHeadingClick(
    sortedBy: Column['sortedBy'],
    onSort: Pick<Required<Column>, 'onSort'>['onSort'],
  ) {
    if (sortedBy === 'asc') {
      onSort('unset');
      return;
    }

    const newSortedBy = sortedBy === 'desc' ? 'asc' : 'desc';

    onSort(newSortedBy);
  }

  return (
    <StyledHeadings padding={padding}>
      {onSelectAll && (
        <CheckboxWrapper>
          <Checkbox
            checked={hasItems && allSelected}
            indeterminate={hasItems && someSelected && !allSelected}
            onChange={e => onSelectAll(e.target.checked)}
          />
        </CheckboxWrapper>
      )}
      {columns.map(({ heading, align, size, sortedBy, onSort, style }, i) => {
        const isCurrentSortColumn = sortedBy && sortedBy !== 'unset';

        return (
          <Cell
            align={align ?? defaultSettings.align}
            size={size ?? defaultSettings.size}
            key={i}
            onClick={onSort && (() => onHeadingClick(sortedBy, onSort))}
            style={style}
            bold={isCurrentSortColumn}
          >
            {heading}
            {(sortedBy || onSort) && (
              <Sorted
                $active={isCurrentSortColumn}
                direction={sortedBy}
                data-testid={`sort-${sortedBy}`}
              />
            )}
          </Cell>
        );
      })}
      {showFirst([
        onReload && (
          <IconWrapper onClick={onReload}>
            <Reload data-testid="reload" />
          </IconWrapper>
        ),
        hasActionMenu && <IconWrapper />,
      ])}
    </StyledHeadings>
  );
}
