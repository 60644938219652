import { AxiosError } from 'axios';
import { useInfiniteQuery } from '@tanstack/react-query';
import http from '../../../services/http-service';
import { useSetStatus } from '../../../hooks/use-status';
import { parseAxiosError } from '../../../store/utils/transform';
import { Params, Webhook } from './types';
import { ApiResponse, Last } from '../../../types';

async function getAllWebhooks(last?: Last) {
  const params: Params = {
    projection: 'id,event,url,programId,secretKey,offerId,headers,created',
  };

  if (last) params.start = JSON.stringify(last);

  const response = await http.get<ApiResponse<Webhook>>(`hooks`, {
    params,
  });

  return response.data;
}

export function useWebhooks(programId: string) {
  const { setStatus } = useSetStatus();

  async function queryWebhooks({ pageParam }: { pageParam?: Last }) {
    const webhooksResponse = await getAllWebhooks(pageParam);

    const items = webhooksResponse.items.filter(
      (hook: Webhook) => hook.programId === programId || !hook.programId,
    );

    return {
      ...webhooksResponse,
      items,
    };
  }

  return useInfiniteQuery(['webhooks', programId], queryWebhooks, {
    enabled: !!programId,
    getNextPageParam: lastPage => lastPage.last || undefined,
    onError: (error: AxiosError) => {
      const { code, message } = parseAxiosError(error);

      setStatus(code, { message });
    },
  });
}
