import React from 'react';
import { Popover as AntdPopover, PopoverProps } from 'antd';
import {
  CloseButton,
  Heading,
  PopoverContainer,
  PopoverTitle,
} from './styled/popover';

type Props = {
  title: string;
  description: string;
  overTitle?: string;
  onClose: () => void;
} & PopoverProps;

function StyledPopover({
  title,
  overTitle = '',
  description,
  children,
  onClose,
  ...props
}: Props) {
  return (
    <PopoverContainer>
      <AntdPopover
        {...props}
        title={
          <>
            <CloseButton data-testid="close-popover" onClick={onClose} />
            <Heading>{overTitle}</Heading>
            <PopoverTitle>{title}</PopoverTitle>
          </>
        }
        content={description}
        getPopupContainer={(triggerNode: HTMLElement) => triggerNode}
      >
        {children}
      </AntdPopover>
    </PopoverContainer>
  );
}

export default StyledPopover;
