import React, { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { Dropdown } from 'antd';

import { SelectTransactionPeriod } from '../../../../../store/account/account-model';
import { Cell, TierDescription, TiersContainer, TierTitle } from './styled';
import BillingPeriods, {
  FilterPeriodType,
  useShowForm,
} from './BillingPeriodsTables';
import CreateUpdateSTPeriod from '../../create-update-billing-period/select-transactions';
import {
  ClickableTag,
  Caron,
} from '../../../../../components/tag/clickable-tag';
import { getPricingTemplate } from '../../../../../store/account/account-actions';
import { colors } from '../../../../../theme';
import { toDecimal, withCurrency } from '../../../../../utils/transform';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';

export interface BillingPeriodsProps {
  showingPeriod: FilterPeriodType;
  currentInvoice: number;
  children?: ReactNode;
}

const SelectTransactionBillingPeriods = ({
  showingPeriod,
  currentInvoice,
  children,
}: BillingPeriodsProps) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'account.contract.billingPeriods',
  });
  const { t: tPrograms } = useTranslation('programs');
  const dispatch = useAppDispatch();
  const { billingConfigurations, pricingTemplate } = useAppSelector(
    state => ({
      billingConfigurations: state.account.billingConfigurations.entity,
      pricingTemplate: state.account.pricingTemplate.entity,
    }),
    shallowEqual,
  );
  const form = useShowForm<SelectTransactionPeriod>();
  const { formVisible, formMode, formPeriod, closeForm } = form;

  const { currency, transactionPricing } = billingConfigurations || {};
  const { periods } = transactionPricing || {};

  const columns = [
    { heading: t('table.selectTransaction.minFee') },
    { heading: t('table.selectTransaction.minTransactions') },
    { heading: t('table.selectTransaction.brackets'), size: 1.1 },
  ];

  function periodToRow(
    period: SelectTransactionPeriod,
    isCurrentInvoice: boolean,
  ) {
    const { minimumFee, minimumTransactions, brackets } = period;
    const [firstBracketFee, firstBracketMax] = brackets[0];

    return [
      <Cell $bold highlighted={isCurrentInvoice}>
        {withCurrency(minimumFee, currency, false, 'code')}
      </Cell>,
      <Cell $bold highlighted={isCurrentInvoice}>
        {minimumTransactions.toLocaleString()}
      </Cell>,
      <Dropdown
        trigger={['click']}
        overlay={
          <TiersContainer>
            {brackets.map((bracket, bracketIdx) => (
              <div key={bracketIdx}>
                <TierTitle>Tier {bracketIdx + 1}</TierTitle>
                <TierDescription>
                  {toDecimal(bracket[1])} ({bracket[0]})
                </TierDescription>
              </div>
            ))}
          </TiersContainer>
        }
      >
        <ClickableTag $clickable color="default">
          {toDecimal(firstBracketMax)} ({firstBracketFee}){' '}
          <Caron style={{ right: 0 }} />
        </ClickableTag>
      </Dropdown>,
    ];
  }

  useEffect(() => {
    if (!pricingTemplate) dispatch(getPricingTemplate());
  }, [dispatch, pricingTemplate]);

  return (
    <>
      <BillingPeriods
        {...form}
        columns={columns}
        rowGenerator={periodToRow}
        accountPeriods={periods}
        periodKey="transactionPricing"
        title={{
          text: tPrograms('programType.transaction-select.label'),
        }}
        color={colors.product.selectTransaction}
        showingPeriod={showingPeriod}
        currentInvoice={currentInvoice}
      >
        {children}
      </BillingPeriods>
      <CreateUpdateSTPeriod
        selectedPeriod={formPeriod}
        visible={formVisible}
        mode={formMode}
        defaultBrackets={
          pricingTemplate?.transactionPricing?.[currency as string]?.brackets
        }
        onClose={() => {
          closeForm();
        }}
      />
    </>
  );
};

export default SelectTransactionBillingPeriods;
