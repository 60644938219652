import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Channels } from '../../../../../store/offers/offers-model';
import { capitalize } from '../../../../../utils/transform';
import Form from '../../../../../components/form';
import { ContentProviderOfferFormModel } from '../../../types';
import { Select } from '../../../../../components/select';

interface OfferChannelsSelectProps {
  disabled: boolean;
}

function OfferChannelsSelect({ disabled }: OfferChannelsSelectProps) {
  const { t } = useTranslation('offers');
  const {
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useFormContext<ContentProviderOfferFormModel>();

  const channelOptions = useMemo(
    () =>
      Object.values(Channels)
        .sort()
        .map(key => (
          <Select.Option key={key} value={key}>
            {capitalize(key)}
          </Select.Option>
        )),
    [],
  );

  useEffect(() => {
    const { channel } = getValues();

    if (!channel) setValue('channel', channelOptions[0].key as string);
  }, [channelOptions, setValue, getValues]);

  if (channelOptions.length === 0) return null;

  return (
    <Form.ItemController
      label={t('fields.channels.label')}
      errors={errors}
      controller={{
        name: 'channel',
        control,
        render: ({ field }) => (
          <Select
            {...field}
            style={{ width: '100%' }}
            data-testid="channel"
            data-value={
              field.value
              /* a data attribute is the only way we can pass value to the mock select */
            }
            disabled={disabled}
          >
            {channelOptions}
          </Select>
        ),
      }}
    />
  );
}

export default OfferChannelsSelect;
