import offers from './offers';
import brands from './brands';
import notifications from './notifications';
import programs from './programs';
import locations from './locations';

const modules = {
  offers,
  brands,
  notifications,
  programs,
  locations,
};

export default modules;
