import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import http from '../../../services/http-service';
import { useSetStatus } from '../../../hooks/use-status';
import { parseAxiosError } from '../../../store/utils/transform';
import { Webhook, WebhookPayload } from './types';
import { ApiResponse } from '../../../types';

async function createWebhook(webhook: WebhookPayload, programId: string) {
  if (webhook.event !== 'brand.consent') {
    const response = await http.post<ApiResponse<Webhook>>(
      `programs/${programId}/hooks`,
      webhook,
    );
    return response.data;
  }
  const response = await http.post<ApiResponse<Webhook>>(`hooks`, webhook);

  return response.data;
}

export function useCreateWebhook(programId: string) {
  const queryClient = useQueryClient();
  const { setStatus } = useSetStatus();

  return useMutation(
    (webhook: WebhookPayload) => createWebhook(webhook, programId),
    {
      onError: (error: AxiosError) => {
        const { code, message } = parseAxiosError(error);

        setStatus(code, { message });
      },
      onSettled: () => {
        queryClient.invalidateQueries(['webhooks']);
      },
    },
  );
}
