import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import Empty from '../../../../components/empty';
import Spin from '../../../../components/spin';
import { getVirtualCardTransactions } from '../../../../store/virtual-card/virtual-card-actions';
import { VirtualCardTransaction } from '../../../../store/virtual-card/virtual-card-model';
import showFirst from '../../../../utils/show-first';
import { DividerHeading } from '../../../transactions/components/styled/transaction-card';
import TransactionCard from '../../../transactions/components/TransactionCard';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

interface VirtualCardTransactionsProps {
  selectedTransactionId: string | undefined;
  onSelect: (transactionId: string | undefined) => unknown;
}

const VirtualCardTransactions = ({
  selectedTransactionId,
  onSelect,
}: VirtualCardTransactionsProps) => {
  const { t } = useTranslation('locations', {
    keyPrefix: 'linkVirtualCardTransaction',
  });
  const dispatch = useAppDispatch();
  const { loading, transactions } = useAppSelector(
    state => ({
      loading: state.virtualCard.loadingTransactions,
      transactions: state.virtualCard.transactions,
    }),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(getVirtualCardTransactions());
  }, [dispatch]);

  function onClick(txn: VirtualCardTransaction) {
    return () => {
      if (selectedTransactionId === txn.id) {
        onSelect(undefined);
      } else {
        onSelect(txn.id);
      }
    };
  }

  return (
    <div>
      <DividerHeading>{t('transactionsHeading')}</DividerHeading>
      {showFirst([
        loading && <Spin data-testid="loading" />,
        transactions?.length === 0 && <Empty>{t('noTransactions')}</Empty>,
        transactions?.map(txn => (
          <TransactionCard
            key={txn.id}
            onClick={onClick(txn)}
            selected={txn.id === selectedTransactionId}
            {...txn}
          />
        )),
      ])}
    </div>
  );
};

export default VirtualCardTransactions;
