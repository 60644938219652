import React, { ReactElement } from 'react';

import { OnboardingExistingUserWelcomeModal } from './components/onboarding-existing-user-welcome-modal';
import { WelcomeStep } from './steps/WelcomeStep';
import { ProgramsStep } from './steps/ProgramsStep';
import { SkipTourStep } from './steps/SkipTourStep';
import { ProgramsClickStep } from './steps/ProgramsClickStep';
import { BrandsStep } from './steps/BrandsStep';
import { BrandsClickStep } from './steps/BrandsClickStep';
import { LocationsStep } from './steps/LocationsStep';
import { LocationsClickStep } from './steps/LocationsClickStep';
import { CardsStep } from './steps/CardsStep';
import { CardsClickStep1 } from './steps/CardsClickStep1';
import { CardsClickStep2 } from './steps/CardsClickStep2';
import { PlaygroundStep } from './steps/PlaygroundStep';
import { PlaygroundClickStep } from './steps/PlaygroundClickStep';
import { DoneStep } from './steps/DoneStep';

export enum OnboardingStepTypes {
  EXISTING_USER_WELCOME = 'existing_user_welcome',
  NEW_USER_WELCOME = 'new_user_welcome',
  PROGRAMS = 'programs',
  PROGRAMS_CLICK = 'programs_click',
  LOCATIONS = 'locations',
  LOCATIONS_CLICK = 'locations_click',
  CARDS = 'cards',
  CARDS_CLICK_1 = 'cards_click_1',
  CARDS_CLICK_2 = 'cards_click_2',
  BRANDS = 'brands',
  BRANDS_CLICK = 'brands_click',
  PLAYGROUND = 'playground',
  PLAYGROUND_CLICK = 'playground_click',
  DONE = 'done',
  SKIP_TOUR = 'skip_tour',
  EXIT_TOUR = 'exit_tour',
}

export type OnboardingKeys =
  | 'dashboardExistingUser'
  | 'dashboardWelcome'
  | 'dashboardPrograms'
  | 'dashboardLocations'
  | 'dashboardCards'
  | 'dashboardBrands'
  | 'dashboardPlayground'
  | 'dashboardTransactions';

export const onboardingKeys: Record<OnboardingKeys, OnboardingStepTypes> = {
  dashboardExistingUser: OnboardingStepTypes.EXISTING_USER_WELCOME,
  dashboardWelcome: OnboardingStepTypes.NEW_USER_WELCOME,
  dashboardPrograms: OnboardingStepTypes.PROGRAMS,
  dashboardLocations: OnboardingStepTypes.LOCATIONS,
  dashboardCards: OnboardingStepTypes.CARDS,
  dashboardBrands: OnboardingStepTypes.BRANDS,
  dashboardPlayground: OnboardingStepTypes.PLAYGROUND,
  dashboardTransactions: OnboardingStepTypes.DONE,
};

type OnboardingStep = {
  permanent: boolean;
  component: ReactElement | null;
  redirectTo?: string;
};
const onboardingSteps: Record<OnboardingStepTypes, OnboardingStep> = {
  [OnboardingStepTypes.EXISTING_USER_WELCOME]: {
    permanent: false,
    redirectTo: '/programs',
    component: <OnboardingExistingUserWelcomeModal />,
  },
  [OnboardingStepTypes.NEW_USER_WELCOME]: {
    permanent: false,
    redirectTo: '/programs',
    component: <WelcomeStep />,
  },
  [OnboardingStepTypes.PROGRAMS]: {
    permanent: true,
    redirectTo: '/programs',
    component: <ProgramsStep />,
  },
  [OnboardingStepTypes.PROGRAMS_CLICK]: {
    permanent: false,
    component: <ProgramsClickStep />,
  },
  [OnboardingStepTypes.LOCATIONS]: {
    permanent: true,
    redirectTo: '/locations',
    component: <LocationsStep />,
  },
  [OnboardingStepTypes.LOCATIONS_CLICK]: {
    permanent: false,
    component: <LocationsClickStep />,
  },
  [OnboardingStepTypes.CARDS]: {
    permanent: true,
    redirectTo: '/cards',
    component: <CardsStep />,
  },
  [OnboardingStepTypes.CARDS_CLICK_1]: {
    permanent: false,
    component: <CardsClickStep1 />,
  },
  [OnboardingStepTypes.CARDS_CLICK_2]: {
    permanent: false,
    component: <CardsClickStep2 />,
  },
  [OnboardingStepTypes.BRANDS]: {
    permanent: true,
    redirectTo: '/brands',
    component: <BrandsStep />,
  },
  [OnboardingStepTypes.BRANDS_CLICK]: {
    permanent: false,
    component: <BrandsClickStep />,
  },
  [OnboardingStepTypes.PLAYGROUND]: {
    permanent: true,
    redirectTo: '/playground',
    component: <PlaygroundStep />,
  },
  [OnboardingStepTypes.PLAYGROUND_CLICK]: {
    permanent: false,
    component: <PlaygroundClickStep />,
  },
  [OnboardingStepTypes.DONE]: {
    permanent: false,
    redirectTo: '/transactions',
    component: <DoneStep />,
  },
  [OnboardingStepTypes.SKIP_TOUR]: {
    permanent: false,
    component: <SkipTourStep />,
  },
  [OnboardingStepTypes.EXIT_TOUR]: {
    permanent: false,
    component: null,
  },
};

export const numOfOnboardingIndicatorSteps = Object.values(
  onboardingSteps,
).filter(step => step.permanent).length;

export const getOnboardingStepComponent = (currStep: OnboardingStepTypes) =>
  onboardingSteps[currStep].component;

export const getOnboardingStepRedirectTo = (key: OnboardingKeys) =>
  onboardingSteps[onboardingKeys[key]].redirectTo || '';
