import React, { useState } from 'react';
import RawDetail from '../RawDetail';

export default function useRawDetail(defaultType: string) {
  const [type, setType] = useState<string>(defaultType);
  const [entityId, setEntityId] = useState<string>();

  const openRawDetail = (id: string, dynamicType?: string) => () => {
    setEntityId(id);
    if (dynamicType) setType(dynamicType);
  };
  const closeRawDetail = () => setEntityId(undefined);
  const rawDetail = (
    <RawDetail
      type={type}
      id={entityId}
      onClose={closeRawDetail}
      visible={!!entityId}
    />
  );

  return {
    openRawDetail,
    rawDetail,
  };
}
