import React from 'react';
import {
  OfferMarketplaceOnboardingKeys,
  OfferMarketplaceOnboardingStepTypes,
} from '../config';

interface OnboardingTourContextProps {
  enabled: boolean;
  step: OfferMarketplaceOnboardingStepTypes;
  setStep: (step: OfferMarketplaceOnboardingStepTypes) => void;
  completeOnboardingStep: (step: OfferMarketplaceOnboardingKeys) => void;
}

export const OffersMarketplaceOnboardingContext = React.createContext(
  {} as OnboardingTourContextProps,
);
