import { AccountImages } from '../account/account-model';

export type BrandLogo = AccountImages;

export interface CreateBrandOptions {
  brand: Omit<Brand, 'id'>;
  consent?: BrandConsent;
  programName?: string;
  programId?: string;
  live?: boolean;
}

export interface UpdateBrandPayload {
  image?: string;
  croppedImage?: string;
  websiteURL?: string;
}

export interface Brand {
  name: string;
  consent?: boolean;
  id: string;
  logoURL?: string;
  websiteURL?: string;
  created?: string;
  autoApproved?: boolean;
}

export interface BrandOptions {
  name?: string;
  consent?: boolean;
  limit?: number;
  last?: any;
}

export type UniqueBrand = Omit<Brand, 'consent'>;

export interface AccountUniqueBrand {
  uniqueBrandId: string;
  uniqueBrandName: string;
}

export interface UniqueBrandOptions {
  limit?: number;
  name?: string;
  projection?: string;
  start?: string;
  last?: any;
}

export interface BrandConsent {
  programId: string;
  nameFirst: string;
  nameLast: string;
  email: string;
  title: string;
  skipInvite: boolean;
  brandId?: string;
}

export enum BrandsNotification {
  UPDATE_BRAND = 'updateBrand',
}
