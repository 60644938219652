import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import OnboardingPopover from '../../../../components/onboarding-tour/components/onboarding-popover';
import { OffersMarketplaceOnboardingContext } from '../context/OffersMarketplaceOnboardingContext';
import { OfferMarketplaceOnboardingStepTypes } from '../config';

export const MarketplaceOfferStep = () => {
  const { t } = useTranslation(['common', 'offers']);

  const { completeOnboardingStep, setStep } = useContext(
    OffersMarketplaceOnboardingContext,
  );

  return (
    <OnboardingPopover
      placement="bottomLeft"
      target="demo-marketplace-offer"
      title={t('offers:marketplace.onboarding.marketplaceOfferStep.title')}
      description={
        <span>
          {t('offers:marketplace.onboarding.marketplaceOfferStep.description')}
        </span>
      }
      buttonText={t('common:onboarding.buttons.done')}
      action={() => {
        completeOnboardingStep('dashboardOffersMarketplaceSampleOffer');
        setStep(OfferMarketplaceOnboardingStepTypes.ADD_TO_PROGRAM);
      }}
      showSkipTour={false}
      hideOverlay
      wrapperStyle={{ position: 'relative', zIndex: 4 }}
      getPopupContainer={trigger => trigger.parentNode}
    />
  );
};
