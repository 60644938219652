import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Col, Row, Input } from 'antd';
import styled from 'styled-components';

import { ReactComponent as Visa } from '../../../../../assets/visa_icon.svg';
import { ReactComponent as Mastercard } from '../../../../../assets/mastercard_icon.svg';
import Form from '../../../../../components/form';
import { FieldWithCurrencyWrapper } from '../../../../../components/form/styled';
import regex from '../../../../../utils/regex';
import { TransactionStreamPeriod } from '../../../../../store/account/account-model';
import { colors } from '../../../../../theme';

const Schemes = ({ currency }: { currency: string | undefined }) => {
  const { t } = useTranslation();
  const { t: tAccountTransactionStream } = useTranslation('common', {
    keyPrefix: 'account.contract.billingPeriods.createUpdate.transactionStream',
  });
  const {
    control,
    formState: { errors },
  } = useFormContext<TransactionStreamPeriod>();

  function schemeRow(key: 'mastercard' | 'visa', logo: React.ReactNode) {
    return (
      <Row justify="space-between" align="middle">
        <Col span={12}>
          <LogoWithLabel>
            <div>{logo}</div>
            {t(`schemes.${key}`)}
          </LogoWithLabel>
        </Col>
        <Col span={12}>
          <FieldWithCurrencyWrapper>
            <Form.ItemController
              errors={errors}
              controller={{
                name: `cardPricing.cardVerifiedFee.${key}`,
                render: ({ field: { ...field } }) => (
                  <Input
                    {...field}
                    min={0}
                    prefix={currency}
                    placeholder={tAccountTransactionStream(
                      'cardVerifiedFee.placeholder',
                    )}
                  />
                ),
                rules: {
                  min: 0,
                  validate: {
                    numeric: value =>
                      !value || regex.positiveDecimalNumeric.test(value),
                  },
                },
                control,
              }}
            />
          </FieldWithCurrencyWrapper>
        </Col>
      </Row>
    );
  }

  return (
    <>
      <GroupLabel>
        {tAccountTransactionStream('cardVerifiedFee.label')}
      </GroupLabel>
      {schemeRow('visa', <Visa />)}
      {schemeRow('mastercard', <Mastercard />)}
    </>
  );
};

const GroupLabel = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.grey4};
`;

const LogoWithLabel = styled.div`
  div {
    width: 30px;
    margin-left: 5px;
    margin-right: 10px;
  }

  margin-bottom: 18px;
  display: flex;
  align-items: center;
`;

export default Schemes;
