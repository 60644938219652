import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, Form, Row } from 'antd';

import Feedback from '../../components/feedback';
import { SecondaryLinkButton } from '../buttons';
import Spin from '../../components/spin';

interface DrawerFormProps extends React.ComponentProps<typeof Drawer> {
  onClose: () => void;
  onSubmit: (...args: any[]) => void;
  cancelText?: string;
  confirmText?: ReactNode;
  onBack?: () => void;
  processing: boolean;
  hasFinished?: boolean;
  submitDisabled?: boolean;
  id?: string;
}

const DrawerForm = ({
  onBack,
  onClose,
  onSubmit,
  cancelText,
  confirmText,
  processing,
  // TODO: Make hasFinished mandatory to force all users of it
  // to handle errors/finished state
  hasFinished = true,
  children,
  submitDisabled,
  ...rest
}: DrawerFormProps) => {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (submitted && !processing && hasFinished) {
      onClose();
    }
  }, [submitted, processing, hasFinished, onClose]);

  useEffect(() => {
    if (processing) {
      setSubmitted(true);
    }
  }, [processing]);

  return (
    <Drawer
      closable={false}
      destroyOnClose
      width={440}
      onClose={onClose}
      {...rest}
      footer={
        <Row style={{ flex: '1 0 auto' }}>
          {onBack && (
            <SecondaryLinkButton onClick={onBack} size="small">
              {cancelText || t('confirm.back')}
            </SecondaryLinkButton>
          )}
          <div style={{ flex: '1 0 auto', textAlign: 'right' }}>
            <SecondaryLinkButton onClick={onClose} size="small">
              {cancelText || t('confirm.cancel')}
            </SecondaryLinkButton>
            <Button
              type="primary"
              onClick={(e: any) => {
                onSubmit(e);
              }}
              size="small"
              disabled={submitDisabled}
            >
              {processing ? (
                <Spin data-testid="loading" type="button" />
              ) : (
                confirmText || t('confirm.done')
              )}
            </Button>
          </div>
        </Row>
      }
    >
      <Form
        layout="vertical"
        style={{
          minHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {children}
      </Form>
      <Feedback right={460} />
    </Drawer>
  );
};

export default DrawerForm;
