import { useSetStatus } from './use-status';
import { StatusCode } from '../store/status/status-reducer';

export default function useHandleCognitoError() {
  const { setStatus, setErrorMessage } = useSetStatus();

  return function handleCognitoError(error: any) {
    const { code, message } = error;

    const recognisedCode = cognitoCodes[code] || cognitoCodes[message];

    if (recognisedCode) {
      setStatus(recognisedCode as StatusCode);
    } else {
      setErrorMessage(message);
    }
  };
}

const cognitoCodes: Record<string, string> = {
  'PreAuthentication failed with error User account not authorised. Please login at https://clo.fidel.uk.':
    'brandUserLoginError',
  'PreAuthentication failed with error User account not authorised. Please contact your account administrator.':
    'invalidCredentials',
  UserNotFoundException: 'userNotFoundException',
};
