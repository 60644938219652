import React from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper, ClearButton, CloseIcon } from './styled';

interface SearchSuffixProps {
  label?: string;
  searchValue: string;
  clearCallback: () => void;
  closeCallback: () => void;
}

const SearchSuffix = ({
  label,
  searchValue,
  clearCallback,
  closeCallback,
}: SearchSuffixProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      {searchValue && (
        <ClearButton
          data-testid="search-suffix-clear"
          onClick={e => {
            e.stopPropagation();
            clearCallback();
          }}
        >
          {label || t('components.searchSuffix.clear')}
        </ClearButton>
      )}
      <CloseIcon onClick={() => closeCallback()} />
    </Wrapper>
  );
};

export default SearchSuffix;
