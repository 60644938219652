import { shallowEqual } from 'react-redux';
import { Moment } from 'moment';
import {
  OfferMaxRedemptionsUnit,
  OfferTransactionSource,
} from '@fidel.uk/types/lib/offer';
import { useAppSelector } from '../../../../store/hooks';
import {
  ContentProviderMetadata,
  Offer,
  OfferChannels,
  UniqueOfferApprovalTypes,
} from '../../../../store/offers/offers-model';
import {
  ContentProviderOfferFormModel,
  CreateUpdateOfferFormModel,
  PublisherOfferFormModel,
} from '../../types';
import { WeekDaysIndexes, weekDays } from '../../../../types';

export function useFormModelToOffer(): (
  formModel: CreateUpdateOfferFormModel,
  currentOffer?: Offer | null,
  isUpdate?: boolean,
  hasUniqueOfferOrigin?: boolean,
  isOaas?: boolean,
) => Partial<Offer> {
  const { accountId, isContentProvider } = useAppSelector(
    ({ account }) => ({
      accountId: account?.details?.id,
      isContentProvider: !!account.contentProviderInfo,
    }),
    shallowEqual,
  );

  return (
    formModel,
    currentOffer,
    isUpdate = false,
    hasUniqueOfferOrigin = false,
    isOaas = false,
  ) => {
    const offer: Partial<Offer> = {
      activation: { enabled: formModel.activation },
      name: formModel.offerName,
      daysOfWeek: (formModel.schedule || []).map(
        day => weekDays.indexOf(day) as WeekDaysIndexes,
      ),
      minTransactionAmount: toNumber(formModel.minSpend),
      maxTransactionAmount: currentOffer?.maxTransactionAmount, // Remove this once we have new API version
      schemes: formModel.schemes,
    };

    offer.returnPeriod = formModel.returnDays
      ? toNumber(formModel.returnDays)
      : null;

    if (formModel.terms) offer.additionalTerms = formModel.terms;

    if (!isUpdate) {
      offer.transactionSource = isOaas
        ? OfferTransactionSource.OAAS
        : OfferTransactionSource.SELECT;
      offer.countryCode = formModel.country;
      offer.brandId = formModel.brandId;
      offer.publisherId = accountId;

      if (formModel.activation)
        offer.activation = {
          enabled: true,
          qualifiedTransactionsLimit: formModel.qualifiedTransactionsLimit,
        };
    }

    if (formModel.startDate) {
      offer.startDate = formatDateAndTime(
        formModel.startDate,
        formModel.startTime || formModel.startDate.startOf('day'),
      );
    }

    if (formModel.endDate) {
      offer.endDate = formatDateAndTime(
        formModel.endDate,
        formModel.endTime || formModel.endDate.endOf('day'),
      );
    } else {
      offer.endDate = null;
    }

    if (formModel.selfFunded) {
      offer.funded = {
        type: 'card-linking',
        id: accountId as string,
      };
    }

    if (formModel.supplier) {
      offer.supplier = formModel.supplier;
    }

    if (formModel.supplierInfo) {
      offer.supplierInfo = fieldArrayToRecord(formModel.supplierInfo);
    }

    if (formModel.customiseFees) {
      offer.fees = {
        publisherFee: formModel.publisherFee,
        fidelFee: formModel.fidelFee,
      };
    }

    const offerPayload = {
      ...offer,
      ...extendOfferValuesFromModel(formModel, isContentProvider),
    };

    if (isUpdate && isContentProvider) {
      const {
        name,
        activation,
        funded,
        metadata,
        schemes,
        additionalTerms,
        ...updateProviderOfferPayload
      } = offerPayload;

      return updateProviderOfferPayload;
    }

    if (isUpdate && hasUniqueOfferOrigin) {
      const { startDate, endDate, metadata } = offerPayload;

      return {
        startDate,
        endDate,
        metadata,
      };
    }

    return offerPayload;
  };
}

function toNumber(numberAsString?: string): number | undefined {
  return numberAsString !== undefined
    ? parseInt(numberAsString, 10)
    : undefined;
}

function fieldArrayToRecord(
  fieldArray: { key: string; value: string }[],
): Record<string, string> {
  const record: Record<string, string> = {};
  fieldArray.forEach(field => {
    record[field.key] = field.value;
  });
  return record;
}

function formatDateAndTime(date: Moment, time: Moment) {
  if (time) {
    date.set('hours', time.get('hours'));
    date.set('minutes', time.get('minutes'));
    date.set('seconds', time.get('seconds'));
  }

  // This format is from https://github.com/FidelLimited/api/blob/1faff40a71617750ad7eb421ac428d4a7625f2f0/src/lib/helpers/validate/index.js#L279
  return date.format('Y-MM-DDTHH:mm:ss');
}

function extendOfferValuesFromModel(
  formModel: CreateUpdateOfferFormModel,
  isContentProvider: boolean,
) {
  if (isContentProvider) {
    return getUniqueOfferValuesFromModel(
      formModel as ContentProviderOfferFormModel,
    );
  }

  const extendedOffer: Partial<Offer> = {};
  const publisherForm = formModel as PublisherOfferFormModel;

  extendedOffer.type = {
    name: publisherForm?.discountType === 'percentage' ? 'discount' : 'amount',
    value: parseFloat(publisherForm?.discountAmount),
    ...(publisherForm?.maxReward && {
      maxRewardAmount:
        publisherForm?.discountType === 'percentage'
          ? parseFloat(publisherForm?.maxReward)
          : null,
    }),
  };

  if (publisherForm.metadata) {
    extendedOffer.metadata = fieldArrayToRecord(publisherForm.metadata);
  } else {
    extendedOffer.metadata = null;
  }

  return extendedOffer;
}

function getUniqueOfferValuesFromModel(
  formModel: ContentProviderOfferFormModel,
) {
  const extendedUniqueOffer: Partial<Offer> = {};

  extendedUniqueOffer.type = {
    name: formModel.discountType === 'percentage' ? 'discount' : 'amount',
    value: formModel.grossCommission,
    ...(formModel?.maxReward && {
      maxRewardAmount:
        formModel.discountType === 'percentage'
          ? parseFloat(formModel?.maxReward)
          : null,
    }),
  };

  extendedUniqueOffer.metadata = {};

  const cpMetadata: ContentProviderMetadata = {
    payment: {
      maxTimeline: formModel.maxPaymentTimeline,
      channel: [formModel.channel] as OfferChannels[],
    },
    approval: {
      type: UniqueOfferApprovalTypes[formModel.approval ? 'STA' : 'NON_STA'],
    },
    commission: {
      minCardholder: 0,
    },
    customerRecency: {
      enabled: formModel.customerRecencyEnabled,
      ...(formModel.customerRecencyDays && {
        days: formModel.customerRecencyDays,
      }),
    },
    description: {
      short: formModel?.shortDescription,
      long: formModel?.longDescription,
    },
  };

  extendedUniqueOffer.metadata.contentProvider = cpMetadata;

  if (formModel.metadata) {
    extendedUniqueOffer.metadata = {
      ...extendedUniqueOffer.metadata,
      ...fieldArrayToRecord(formModel.metadata),
    };
  }

  if (formModel.maxRedemptions && formModel.transactionsLimit) {
    extendedUniqueOffer.maxRedemptions = {
      transactionsLimit: formModel.transactionsLimit,
      unit: formModel.unit as OfferMaxRedemptionsUnit,
      period: formModel.period,
    };
  }

  return extendedUniqueOffer;
}
