import RawDetailService from '../store/raw-detail/raw-detail-api-service';

const rawDetailService = new RawDetailService();

class InvoiceService {
  account: any;
  t: any;
  onError: any;

  constructor(t: any, onError: any) {
    this.t = t;
    this.onError = onError;
  }

  async download(id: string, account: any) {
    this.account = account;

    try {
      const invoiceResponse = await rawDetailService.getDetail(
        `accounts/${this.account.id}/invoices`,
        id,
      );

      const generateAndExportInvoice = (await import('@fidel.uk/invoices'))
        .default;

      generateAndExportInvoice(invoiceResponse.data.items[0], this.account);
    } catch (error) {
      if (error instanceof Error) this.onError(error.message);

      this.onError(this.t('account.invoice.error.notFound'));
    }

    return null;
  }
}

export default InvoiceService;
