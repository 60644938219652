import ModeratorApiService from './moderator-api-service';
import { actions } from './moderator-reducer';
import { UsersOptions } from './moderator-model';
import { State } from '..';
import { Thunk } from '../types';

const moderatorApiService = new ModeratorApiService();

export const getAccountsCount = (): Thunk => async dispatch => {
  try {
    const count = await moderatorApiService.getAccountsCount();
    dispatch(actions.getAccountsCountSuccess(count));
  } catch (error) {
    dispatch(actions.getAccountsCountError({ error }));
  }
};

export const getAccounts =
  (loadMore = false): Thunk =>
  async (dispatch, getState) => {
    const last = loadMore
      ? (getState() as State).moderator.accounts.last
      : undefined;

    if (loadMore && !last) {
      return;
    }

    dispatch(actions.getAccounts(loadMore));

    try {
      const response = await moderatorApiService.getAccounts(last);
      dispatch(
        actions.getAccountsSuccess({
          items: response.data.items,
          last: response.data.last,
          concat: loadMore,
        }),
      );
    } catch (error) {
      dispatch(actions.getAccountsError({ error }));
    }
  };

export const getAllAccounts = (): Thunk => async dispatch => {
  dispatch(actions.getAllAccounts());

  try {
    const response = await moderatorApiService.getAllAccounts();
    dispatch(actions.getAllAccountsSuccess(response));
  } catch (error) {
    dispatch(actions.getAllAccountsError({ error }));
  }
};

export const getAllUsers =
  (options?: any): Thunk =>
  async dispatch => {
    dispatch(actions.getAllUsers());

    try {
      const response = await moderatorApiService.getAllUsers(options);
      dispatch(actions.getAllUsersSuccess(response));
    } catch (error) {
      dispatch(actions.getAllUsersError({ error }));
    }
  };

export const getUsers =
  (options: UsersOptions = {}): Thunk =>
  async dispatch => {
    dispatch(actions.getUsers());

    try {
      const response = await moderatorApiService.getUsers(options);

      dispatch(actions.getUsersSuccess(response.data));
    } catch (error) {
      dispatch(actions.getUsersError({ error }));
    }
  };

export const reviewAccount =
  ({
    accountId,
    liveRequestId,
    approved,
    message,
  }: {
    accountId: string;
    liveRequestId: string | undefined;
    approved: boolean;
    message?: string;
  }): Thunk =>
  async dispatch => {
    dispatch(actions.reviewAccount());

    try {
      await moderatorApiService.reviewAccount(
        accountId,
        liveRequestId,
        approved,
        message,
      );

      dispatch(actions.reviewAccountSuccess());
      dispatch(getAccounts());
    } catch (error) {
      dispatch(actions.reviewAccountError({ error }));
    }
  };
