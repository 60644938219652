import { useEffect } from 'react';

import { getLastInvoice } from '../store/account/account-actions';
import { Invoice } from '../store/account/account-model';
import { useAppDispatch, useAppSelector } from '../store/hooks';

function currentPeriod(lastInvoice?: Invoice): number {
  if (!lastInvoice || !lastInvoice.invoiceNumber) return 1;

  return lastInvoice?.status === 'open'
    ? lastInvoice.invoiceNumber
    : lastInvoice?.invoiceNumber + 1;
}

function useCurrentPeriod() {
  const dispatch = useAppDispatch();
  const lastInvoice = useAppSelector(state => state.account.invoices.last);

  useEffect(() => {
    dispatch(getLastInvoice());
  }, [dispatch]);

  return currentPeriod(lastInvoice);
}

export default useCurrentPeriod;
