import CardsApiService from './cards-api-service';

import { CardsOptions } from './cards-types';
import { actions } from './cards-reducer';
import { Thunk } from '../types';
import { State } from '..';

const cardsService = new CardsApiService();

const getFilters = (state: State) => state.filters.cards;

export const { clear } = actions;

export const getCards =
  (cardsOptions: CardsOptions): Thunk =>
  async (dispatch, getState) => {
    dispatch(actions.getCards());

    const isLoadMore = !!cardsOptions.last;

    const { id, dateRange } = getFilters(getState());

    if (isLoadMore) dispatch(actions.getMoreCards());
    else dispatch(actions.getCards());

    try {
      const response = await cardsService.getCards({
        ...cardsOptions,
        ...dateRange,
        id,
      });
      const { items, last } = response.data;
      if (isLoadMore) dispatch(actions.getMoreCardsSuccess({ items, last }));
      else dispatch(actions.getCardsSuccess({ items, last }));
    } catch (error) {
      if (isLoadMore) dispatch(actions.getMoreCardsError({ error }));
      else dispatch(actions.getCardsError({ error }));
    }
  };

export const getCardsCount =
  (cardsOptions: CardsOptions): Thunk =>
  async dispatch => {
    dispatch(actions.getCardsCount());

    try {
      const count = await cardsService.getCardsCount(cardsOptions);
      dispatch(actions.getCardsCountSuccess(count));
    } catch (error) {
      dispatch(actions.getCardsCountError({ error }));
    }
  };

export const getExportableCards =
  (options: CardsOptions): Thunk =>
  async dispatch => {
    dispatch(actions.getExportableCards());

    try {
      const cards = await cardsService.getAllCards(options);
      dispatch(actions.getExportableCardsSuccess({ cards }));
    } catch (error) {
      dispatch(actions.getExportableCardsError({ error }));
    }
  };

export const deleteCard =
  (cardIds: string | string[]): Thunk =>
  async dispatch => {
    dispatch(actions.deleteCard());

    const arrayCardIds = Array.isArray(cardIds) ? cardIds : [cardIds];

    try {
      await Promise.all(
        arrayCardIds.map(cardId => cardsService.deleteCard(cardId)),
      );
      dispatch(actions.deleteCardSuccess());
    } catch (error) {
      dispatch(actions.deleteCardError({ error }));
    }
  };
