import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Transaction } from './transactions-model';
import { actionToError } from '../utils/transform';

export interface TransactionState {
  exportableTransactions: Transaction[];
  loading: boolean;
  transactions: Transaction[];
  transactionsCount: number;
  transactionsEmpty: boolean;
  error?: any;
  lastTransaction?: any;
}

const initialState: TransactionState = {
  exportableTransactions: [],
  loading: false,
  transactionsCount: 0,
  transactionsEmpty: false,
  transactions: [],
};

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    clear: () => initialState,
    clearTransaction(state) {
      state.loading = true;
      state.error = undefined;
    },
    clearTransactionSuccess(state) {
      state.loading = false;
    },
    clearTransactionError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },
    getTransactions(state) {
      state.loading = true;
      state.error = undefined;
      state.lastTransaction = undefined;
    },
    getTransactionsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.transactions = action.payload.transactions;
      state.lastTransaction = action.payload.last;
    },
    getTransactionsError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },
    getMoreTransactions(state) {
      state.loading = true;
      state.error = undefined;
    },
    getMoreTransactionsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.transactions = [
        ...state.transactions,
        ...action.payload.transactions,
      ];
      state.lastTransaction = action.payload.last;
    },
    getMoreTransactionsError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },
    getExportableTransactions(state) {
      state.loading = true;
      state.error = undefined;
      state.exportableTransactions = [];
    },
    getExportableTransactionsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.transactions = action.payload;
      state.exportableTransactions = action.payload;
      state.lastTransaction = undefined;
    },
    getExportableTransactionsError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },
    getTransactionsCount(state) {
      state.loading = true;
      state.error = undefined;
      state.transactionsCount = 0;
    },
    getTransactionsCountSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.transactionsCount = action.payload;
    },
    getTransactionsCountError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },
  },
});

export const { actions } = transactionsSlice;
export default transactionsSlice.reducer;
