import React, {
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';

import {
  getPricingTemplate,
  updateBillingConfigurations,
} from '../../../../../store/account/account-actions';
import { ProductPeriod } from '../../../../../store/account/account-model';
import {
  Column,
  DividerType,
  Highlight,
  RowType,
} from '../../../../../components/tables/BaseTable';
import LoadMoreTable from '../../../../../components/tables/LoadMoreTable';
import Tooltip from '../../../../../components/conditional-tooltip';
import {
  CreateUpdateBillingPeriodMode,
  useInvoiceInterval,
  PeriodKey,
} from '../../create-update-billing-period/utils';
import {
  AddPeriod,
  Cell,
  PlusIconContainer,
  Initials,
  StyledCustomCollapse,
} from './styled';
import { CollapsePanel, Subsection, SubsectionTitle } from '../../../styled';
import { ReactComponent as PlusIcon } from '../../../../../assets/plus.svg';
import { DOUBLE_MINUS } from '../../../../../utils/special-character';
import { hexToRgb } from '../../../../../utils/transform';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';

export type FilterPeriodType = number | 'all';

type PeriodsType = 'singlePeriod' | 'multiplePeriods';

type DividerPeriodsTrans = `${PeriodsType}.${'currentPeriod' | 'other'}`;

function isPeriod<P extends ProductPeriod>(
  period: P | DividerType,
): period is P {
  return 'invoiceNumber' in period;
}

function useFindDivider(currentInvoice?: number) {
  const { t } = useTranslation('common', {
    keyPrefix: 'account.contract.billingPeriods.table.divider',
  });

  return function findDivider(
    previousInvoiceNumber: number,
    nextInvoiceNumber: number,
  ): DividerType | null {
    if (previousInvoiceNumber === nextInvoiceNumber + 1) return null;

    function translationKey(key: PeriodsType): DividerPeriodsTrans {
      return `${key}.${
        previousInvoiceNumber - 1 === currentInvoice ? 'currentPeriod' : 'other'
      }`;
    }

    return {
      text:
        previousInvoiceNumber - 1 === nextInvoiceNumber + 1
          ? t(translationKey('singlePeriod'), {
              period: previousInvoiceNumber - 1,
              reference: nextInvoiceNumber,
            })
          : t(translationKey('multiplePeriods'), {
              start: nextInvoiceNumber + 1,
              end: previousInvoiceNumber - 1,
              reference: nextInvoiceNumber,
            }),
    };
  };
}

function useAddVoidPeriods(currentInvoice: number) {
  const findDivider = useFindDivider(currentInvoice);

  return function addVoidPeriods<P extends ProductPeriod>(
    periods: P[],
    filteredPeriod: FilterPeriodType,
    highlight?: Highlight,
  ): (P | DividerType)[] {
    const periodsWithDividers: (P | DividerType)[] = [];
    periods.forEach((period, idx) => {
      if (idx === 0) {
        periodsWithDividers.push(period);
        return;
      }

      const lastPeriod = periods[idx - 1];
      const divider = findDivider(
        lastPeriod.invoiceNumber,
        period.invoiceNumber,
      );
      if (divider) {
        periodsWithDividers.push(
          currentInvoice < lastPeriod.invoiceNumber &&
            currentInvoice > period.invoiceNumber
            ? { ...divider, highlight }
            : divider,
        );
      }
      periodsWithDividers.push(period);
    });

    if (
      !!periods.length &&
      (periods[0].invoiceNumber < currentInvoice || filteredPeriod !== 'all')
    ) {
      const divider = findDivider(
        (filteredPeriod !== 'all' ? filteredPeriod : currentInvoice) + 1,
        periods[0].invoiceNumber,
      );

      if (divider) {
        periodsWithDividers.unshift({ ...divider, highlight });
      }
    }

    return periodsWithDividers;
  };
}

interface UseShowFormReturnType<P extends ProductPeriod> {
  formVisible: boolean;
  formMode: CreateUpdateBillingPeriodMode | undefined;
  formPeriod: P | undefined;
  closeForm: () => void;
  showCreateForm: () => void;
  showDuplicateForm: (period: P) => void;
  showUpdateForm: (period: P) => void;
}

export function useShowForm<P extends ProductPeriod>() {
  const [formVisible, setFormVisible] = useState(false);
  const [formMode, setFormMode] = useState<CreateUpdateBillingPeriodMode>();
  const [formPeriod, setFormPeriod] = useState<P>();

  function showForm(mode: CreateUpdateBillingPeriodMode, period?: P) {
    setFormVisible(true);
    setFormMode(mode);
    if (period) {
      setFormPeriod(period);
    }
  }

  function closeForm() {
    setFormVisible(false);
    setFormMode(undefined);
    setFormPeriod(undefined);
  }

  return {
    formVisible,
    formMode,
    formPeriod,
    closeForm,
    showCreateForm: () => showForm(CreateUpdateBillingPeriodMode.Create),
    showDuplicateForm: (period: P) =>
      showForm(CreateUpdateBillingPeriodMode.Duplicate, period),
    showUpdateForm: (period: P) =>
      showForm(CreateUpdateBillingPeriodMode.Update, period),
  };
}

function findActivePeriod<P extends ProductPeriod>(
  referenceInvoiceNumber: number,
  periods: P[] | undefined,
) {
  return (periods || []).find(
    ({ invoiceNumber }) => invoiceNumber <= referenceInvoiceNumber,
  );
}

interface BillingPeriodsProps<P extends ProductPeriod> {
  accountPeriods: P[] | undefined;
  showCreateForm: UseShowFormReturnType<P>['showCreateForm'];
  showDuplicateForm: UseShowFormReturnType<P>['showDuplicateForm'];
  showUpdateForm: UseShowFormReturnType<P>['showUpdateForm'];
  columns: Column[];
  rowGenerator: (product: any, isCurrentInvoice: boolean) => ReactElement[];
  periodKey: PeriodKey;
  title: {
    text: string;
    icon?: ReactElement;
  };
  color?: string;
  showingPeriod: FilterPeriodType;
  currentInvoice: number;
}

const BillingPeriods: <P extends ProductPeriod>(
  p: PropsWithChildren<BillingPeriodsProps<P>>,
) => React.ReactElement<BillingPeriodsProps<P>> = ({
  accountPeriods,
  showCreateForm,
  showDuplicateForm,
  showUpdateForm,
  columns,
  rowGenerator,
  periodKey,
  title,
  color,
  showingPeriod,
  children,
  currentInvoice,
}) => {
  const { t } = useTranslation();
  const { t: tBillingPeriods } = useTranslation('common', {
    keyPrefix: 'account.contract.billingPeriods',
  });
  const dispatch = useAppDispatch();
  const { getInvoiceInterval } = useInvoiceInterval();
  const {
    billingConfigurations,
    loading,
    pricingTemplate,
    gettingPricingTemplate,
  } = useAppSelector(
    state => ({
      billingConfigurations: state.account.billingConfigurations.entity,
      loading: state.account.billingConfigurations.loading,
      pricingTemplate: state.account.pricingTemplate.entity,
      gettingPricingTemplate: state.account.pricingTemplate.loading,
    }),
    shallowEqual,
  );
  const addVoidPeriods = useAddVoidPeriods(currentInvoice);
  const [showing, setShowing] = useState(4);

  const { invoiceDay } = billingConfigurations || {};

  useEffect(() => {
    if (!pricingTemplate) dispatch(getPricingTemplate());
  }, [dispatch, pricingTemplate]);

  const activePeriod = findActivePeriod(currentInvoice, accountPeriods);

  function highlight(): Highlight | undefined {
    if (!color) return undefined;

    const { r, g, b } = hexToRgb(color) || {};
    return { color: `rgba(${r}, ${g}, ${b}, 0.2)` };
  }

  function filteredPeriods() {
    if (!accountPeriods) return [];
    if (showingPeriod === 'all') return accountPeriods;

    const exactMatch = accountPeriods.find(
      ({ invoiceNumber }) => invoiceNumber === showingPeriod,
    );

    if (exactMatch) return [exactMatch];

    const showingPeriodActivePeriod = findActivePeriod(
      showingPeriod,
      accountPeriods,
    );

    return showingPeriodActivePeriod ? [showingPeriodActivePeriod] : [];
  }

  const allRows: RowType[] = addVoidPeriods(
    filteredPeriods(),
    showingPeriod,
    highlight(),
  ).map(period => {
    if (!isPeriod(period)) return period as DividerType;

    const { invoiceNumber } = period;

    const isActiveInvoice = invoiceNumber === activePeriod?.invoiceNumber;

    return {
      highlight: invoiceNumber === currentInvoice ? highlight() : undefined,
      contents: [
        <Cell highlighted={isActiveInvoice}>{invoiceNumber}</Cell>,
        ...rowGenerator(period, isActiveInvoice),
        <span>
          {invoiceNumber >= currentInvoice
            ? getInvoiceInterval(invoiceNumber - currentInvoice).end
            : DOUBLE_MINUS}
        </span>,
      ],
      actions: [
        {
          label: tBillingPeriods('table.actions.edit'),
          callback: () => {
            showUpdateForm(period);
          },
          disabled: invoiceNumber < currentInvoice,
        },
        {
          label: tBillingPeriods('table.actions.duplicate'),
          callback: () => {
            showDuplicateForm(period);
          },
        },
        {
          label: tBillingPeriods('table.actions.delete'),
          callback: () => {
            Modal.confirm({
              title: tBillingPeriods('delete.title'),
              content: tBillingPeriods('delete.message'),
              onOk: () => {
                const currentBillingPeriods: ProductPeriod[] =
                  billingConfigurations?.[periodKey]?.periods || [];

                const currentAdjustmentPeriods =
                  billingConfigurations?.adjustment?.periods || [];

                dispatch(
                  updateBillingConfigurations({
                    adjustment: {
                      periods: [
                        ...currentAdjustmentPeriods.filter(
                          currentPeriod =>
                            currentPeriod.invoiceNumber !== invoiceNumber,
                        ),
                      ],
                    },
                    [periodKey]: {
                      ...billingConfigurations?.[periodKey],
                      periods: [
                        ...currentBillingPeriods.filter(
                          currentPeriod =>
                            currentPeriod.invoiceNumber !== invoiceNumber,
                        ),
                      ],
                    },
                  }),
                );
              },
              okButtonProps: { danger: true },
              okText: t('confirm.delete'),
              maskClosable: true,
              icon: null,
            });
          },
          disabled: invoiceNumber < currentInvoice || invoiceNumber === 1,
        },
      ],
    };
  });

  return (
    <StyledCustomCollapse defaultActiveKey={periodKey}>
      <CollapsePanel
        key={periodKey}
        header={
          <Subsection hideBorder>
            <SubsectionTitle>
              <Title icon={title.icon} color={color}>
                {title.text}
              </Title>
            </SubsectionTitle>
          </Subsection>
        }
      >
        {children}
        <LoadMoreTable
          bordered
          loading={loading}
          emptyText={null}
          minWidth={500}
          topRowElement={
            <Tooltip
              title={tBillingPeriods('table.disabledTooltip')}
              condition={!invoiceDay}
            >
              <AddPeriod
                data-testid={`add-period-${periodKey}`}
                disabled={!invoiceDay || gettingPricingTemplate}
                onClick={showCreateForm}
              >
                <PlusIconContainer>
                  <PlusIcon />
                </PlusIconContainer>
              </AddPeriod>
            </Tooltip>
          }
          columns={[
            {
              heading: tBillingPeriods('table.numberOfPeriods'),
              size: 0.3,
            },
            ...columns,
            { heading: tBillingPeriods('table.issueDate'), align: 'right' },
          ]}
          rows={allRows.slice(0, showing)}
          onLoadMore={() => setShowing(showing + 10)}
          total={allRows.length}
        />
      </CollapsePanel>
    </StyledCustomCollapse>
  );
};

interface TitleProps {
  color?: string;
  icon?: ReactElement;
  children?: ReactNode;
}

const Title = ({ color, icon, children }: TitleProps) => {
  if (typeof children !== 'string') return null;

  const initials = children
    .split(' ')
    .map(word => word[0])
    .join('');

  return (
    <h2>
      <Initials color={color}>{icon || initials}</Initials> {children}
    </h2>
  );
};

export default BillingPeriods;
