import { useHistory } from 'react-router-dom';

interface RedirectOptions {
  push?: boolean;
  state?: any;
}

export default function useRedirect() {
  const history = useHistory();

  return function redirect(
    to: string,
    { push = true, state }: RedirectOptions = {},
  ) {
    if (push) {
      history.push(to, state);
    } else {
      history.replace(to, state);
    }
  };
}
