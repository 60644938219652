import React, { useEffect, useMemo, useCallback } from 'react';
import { uniqBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import { getAllBrands } from '../../store/brands/brands-actions';
import filterOption from '../../utils/filter-option';
import { Select } from '../../components/select';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

interface BrandsFilterProps {
  onChange?: (brandId: string) => unknown;
  defaultValue?: string;
  placeholder?: string;
}

const BrandsFilter = ({
  onChange,
  defaultValue,
  placeholder,
}: BrandsFilterProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const brands = useAppSelector(state => state.brands.brands);

  useEffect(() => {
    dispatch(getAllBrands());
  }, [dispatch]);

  const onBrandSelect = useCallback(
    value => {
      if (onChange) onChange(value);
    },
    [onChange],
  );

  const options = useMemo(() => {
    const result = uniqBy(brands, brand => brand.name).map(brand => ({
      label: brand.name,
      value: brand.id,
    }));

    return result;
  }, [brands]);

  return (
    <Select
      data-name="brand"
      placeholder={placeholder || t('filters.brand.placeholder')}
      defaultValue={defaultValue}
      onChange={onBrandSelect}
      showSearch
      allowClear
      optionFilterProp="children"
      filterOption={filterOption}
      data-testid="filter-brand"
    >
      {options.map(({ label, value }) => (
        <Select.Option key={value} value={value}>
          {label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default BrandsFilter;
