import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { Form, Select } from 'antd';

import { Location } from '../../../store/locations/locations-model';
import { Offer } from '../../../store/offers/offers-model';
import * as OfferActions from '../../../store/offers';
import { isExpired } from './LinkToOfferDropdown';
import { useSetStatus } from '../../../hooks/use-status';
import { buildAddress } from '../../../utils/transform';
import DrawerForm from '../../../components/drawer-form';
import filterOption from '../../../utils/filter-option';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { filterLocation } from '../utils';

interface LinkLocationProps {
  onClose: () => any;
  location: Location;
}

const LinkLocation = ({ onClose, location }: LinkLocationProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['notifications', 'locations']);
  const {
    error,
    loadingOffers,
    offers: { all: allOffers = [] },
  } = useAppSelector(
    state => ({
      error: state.offer.error,
      loadingOffers: state.offer.loading,
      offers: state.offers.offers,
    }),
    shallowEqual,
  );
  const [loading, setLoading] = useState(false);
  const { handleSubmit, register, setValue, watch } = useForm({
    mode: 'onBlur',
  });
  const { setErrorMessage, setSuccessMessage } = useSetStatus();
  const hasSucceeded = !loadingOffers && !error && loading;

  useEffect(() => {
    dispatch(OfferActions.getAllOffers());
    register('offerId', { required: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hasSucceeded) {
      setLoading(false);
      setSuccessMessage(t('notifications:locations.linking.success'));
    }
  }, [hasSucceeded, setSuccessMessage, t]);

  useEffect(() => {
    if (error && loading) {
      setLoading(false);
      const errorResponse = error?.response?.data?.error;
      setErrorMessage(
        t(
          errorResponse
            ? 'notifications:common.error.invalidAPI'
            : 'notifications:common.error.server',
          {
            message: errorResponse?.message,
            code: errorResponse?.code,
          },
        ),
      );
    }
  }, [error, loading, setErrorMessage, t]);

  function onSubmit({ offerId }: any) {
    dispatch(OfferActions.linkLocation(location.id, offerId));
    setLoading(true);
  }

  function setOfferId(value: string) {
    setValue('offerId', value);
  }

  const offerToLink = allOffers.find(
    (offer: Offer) => offer.id === watch('offerId'),
  );

  return (
    <DrawerForm
      title={buildAddress(location)}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      submitDisabled={!watch('offerId')}
      confirmText={`${t('locations:link')} ${
        offerToLink ? t('locations:linkTo', { offer: offerToLink.name }) : ''
      }`}
      processing={loading}
      visible
    >
      <Form.Item label={t('locations:chooseOffer.label')}>
        <Select
          data-name="offer"
          showSearch
          placeholder={t('locations:chooseOffer.placeholder')}
          optionFilterProp="children"
          onChange={setOfferId}
          filterOption={filterOption}
          data-testid="offers-dropdown"
        >
          {allOffers
            .filter(offer => filterLocation(location, offer))
            .filter(offer => !isExpired(offer))
            .map(({ id, name }: Offer) => (
              <Select.Option key={id} value={id || ''}>
                {name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
    </DrawerForm>
  );
};

export default LinkLocation;
