import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import handleError from '../utils/handle-error';
import { transformToBrandUser } from './moderator-model';
import { actionToError } from '../utils/transform';

interface ListResource<T = any> {
  items: T[];
  loading: boolean;
  last: any | null;
  error?: any;
  count?: number;
}

export interface ModeratorState {
  accounts: ListResource;
  users: ListResource;
  reviewing: boolean;
}

const initialState: ModeratorState = {
  reviewing: false,
  accounts: {
    loading: false,
    last: null,
    items: [],
  },
  users: {
    loading: false,
    last: null,
    items: [],
  },
};

const moderatorSlice = createSlice({
  name: 'moderator',
  initialState,
  reducers: {
    getAccounts(state, action: PayloadAction<boolean>) {
      const loadMore = action.payload;

      if (!loadMore) {
        state.accounts.items = [];
        state.accounts.last = null;
      }

      state.accounts.loading = true;
    },
    getAccountsSuccess(state, action: PayloadAction<any>) {
      state.accounts.loading = false;

      const { last, items, concat } = action.payload;
      state.accounts.last = last;

      if (concat) {
        state.accounts.items.push(...items);
      } else {
        state.accounts.items = items;
      }
    },
    getAccountsError(state, action: PayloadAction<any>) {
      state.accounts.loading = false;
      state.accounts.last = null;
      state.accounts.error = actionToError(action);
    },

    getAccountsCountSuccess(state, action: PayloadAction<number>) {
      state.accounts.count = action.payload;
    },
    getAccountsCountError(state, action: PayloadAction<any>) {
      handleError(action.payload);
      state.accounts.count = undefined;
    },

    getAllAccounts(state) {
      state.accounts.loading = true;
      state.accounts.error = undefined;
      state.accounts.items = [];
    },
    getAllAccountsSuccess(state, action: PayloadAction<any>) {
      state.accounts.loading = false;
      state.accounts.items = action.payload;
      state.accounts.last = null;
    },
    getAllAccountsError(state, action: PayloadAction<any>) {
      state.accounts.loading = false;
      state.accounts.error = actionToError(action);
    },

    getAllUsers(state) {
      state.users.loading = true;
      state.users.error = undefined;
      state.users.items = [];
    },
    getAllUsersSuccess(state, action: PayloadAction<any>) {
      state.users.loading = false;
      state.users.items = action.payload.map(transformToBrandUser);
      state.users.last = null;
    },
    getAllUsersError(state, action: PayloadAction<any>) {
      state.users.loading = false;
      state.users.error = actionToError(action);
    },

    getUsers(state) {
      state.users.loading = true;
      state.users.error = undefined;
      state.users.items = [];
    },
    getUsersSuccess(state, action: PayloadAction<any>) {
      state.users.loading = false;
      state.users.items = action.payload.items.map(transformToBrandUser);
      state.users.last = action.payload.last;
    },
    getUsersError(state, action: PayloadAction<any>) {
      state.users.loading = false;
      state.users.error = actionToError(action);
    },

    reviewAccount(state) {
      state.reviewing = true;
    },
    reviewAccountSuccess(state) {
      state.reviewing = false;
    },
    reviewAccountError(state, action: PayloadAction<any>) {
      state.reviewing = false;
      handleError(action.payload);
    },
  },
});

export default moderatorSlice.reducer;
export const { actions } = moderatorSlice;
