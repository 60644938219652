import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { memoize } from 'lodash';

import { stripe } from '../../config';

const getStripeInstance = memoize(() => loadStripe(stripe.KEY));

function stripeWrapper<T>(Component: React.ComponentType<T>) {
  const ComponentWithElements = (props: T) => (
    <Elements stripe={getStripeInstance()}>
      <Component {...(props as T)} />
    </Elements>
  );

  return ComponentWithElements;
}

export default stripeWrapper;
