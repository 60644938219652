import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import { Input } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import { Subsection, SubsectionTitle, SubsectionSubtitle } from '../styled';
import DrawerForm from '../../../components/drawer-form';
import Form from '../../../components/form';
import { InlineButton } from '../../../components/buttons';
import { useSetStatus } from '../../../hooks/use-status';
import useIsSignedIn from '../../../hooks/use-is-signed-in';
import useRedirect from '../../../hooks/use-redirect';
import useHandleCognitoError from '../../../hooks/use-handle-cognito-error';
import regex from '../../../utils/regex';

const ChangePassword = () => {
  const { t } = useTranslation();
  const { t: tAccountSecurity } = useTranslation('common', {
    keyPrefix: 'account.security',
  });
  const { setSuccessMessage } = useSetStatus();
  const handleCognitoError = useHandleCognitoError();
  const { signOut } = useIsSignedIn();
  const redirect = useRedirect();
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [processing, setProcessing] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onBlur',
  });

  const onSubmit = async (values: any) => {
    setProcessing(true);
    try {
      const { oldPassword, newPassword } = values;
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(currentUser, oldPassword, newPassword);
      setSuccessMessage(t('form.changesSaved'));
      setProcessing(false);
    } catch (error) {
      handleCognitoError(error);
      setProcessing(false);
    }
  };

  const forgotPassword = () => {
    Auth.signOut();
    signOut();
    redirect('/reset-password');
  };

  const renderPasswordInput = (
    field: 'oldPassword' | 'newPassword',
    rules: React.ComponentProps<
      typeof Form.ItemController
    >['controller']['rules'],
  ) => (
    <Form.ItemController
      label={tAccountSecurity(field)}
      errors={errors}
      controller={{
        name: field,
        render: ({ field: { ...rest } }) => (
          <Input.Password
            {...rest}
            iconRender={(visible: boolean) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        ),
        control,
        rules,
      }}
    />
  );

  return (
    <>
      <Subsection>
        <SubsectionTitle>
          <div>
            <h2>{t('account.password')}</h2>
            <SubsectionSubtitle>
              {tAccountSecurity('passwordDescription')}
            </SubsectionSubtitle>
          </div>
          <InlineButton
            style={{ marginLeft: '20px' }}
            type="link"
            onClick={() => setShowPasswordForm(true)}
          >
            {tAccountSecurity('changePassword')}
          </InlineButton>
        </SubsectionTitle>
      </Subsection>
      <DrawerForm
        title={tAccountSecurity('changePassword')}
        onClose={() => setShowPasswordForm(false)}
        onSubmit={handleSubmit(onSubmit)}
        processing={processing}
        visible={showPasswordForm}
      >
        {renderPasswordInput('oldPassword', {
          required: true,
        })}
        {renderPasswordInput('newPassword', {
          required: true,
          minLength: 8,
          maxLength: 30,
          validate: { password: (value: string) => regex.password.test(value) },
        })}
        <InlineButton type="link" onClick={forgotPassword}>
          {t('signIn.forgottenPassword')}
        </InlineButton>
      </DrawerForm>
    </>
  );
};

export default ChangePassword;
