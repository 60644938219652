import React from 'react';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { useForm } from 'react-hook-form';
import { DatePicker } from 'antd';

import { updateBillingConfigurations } from '../../../../store/account/account-actions';
import ContractForm from './ContractForm';
import { ItemDescription } from '../../../../components/inline-form';
import { HiddenInput } from '../../../../components/form/styled';
import Upload, { supportedFileTypes } from '../../../../components/upload';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

interface ContractInfoForm {
  commencementDate: Moment;
  contractUrl?: string;
}

const ContractInfo = () => {
  const { t } = useTranslation('common', {
    keyPrefix: 'account.contract.contractInfo.form',
  });
  const dispatch = useAppDispatch();
  const billingConfigurations = useAppSelector(
    state => state.account.billingConfigurations.entity,
    shallowEqual,
  );

  const currentCommencementDate =
    billingConfigurations?.commencementDate &&
    moment(billingConfigurations?.commencementDate);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<any>({
    mode: 'onBlur',
    defaultValues: {
      commencementDate: currentCommencementDate,
      contractUrl: billingConfigurations?.contractUrl,
    },
  });

  const defaultContract = watch('contractUrl');

  function onSubmit({ commencementDate, contractUrl }: ContractInfoForm) {
    dispatch(
      updateBillingConfigurations({
        commencementDate: moment.utc(commencementDate).startOf('day').format(),
        contractUrl,
      }),
    );
  }

  return (
    <ContractForm onSubmit={handleSubmit(onSubmit)}>
      <ContractForm.ItemController
        label={t('commencementDate')}
        errors={errors}
        controller={{
          name: 'commencementDate',
          render: ({ field: { ref, ...rest } }) => (
            <DatePicker
              {...rest}
              format="ll"
              disabled={!!billingConfigurations?.subscriptionActive}
            />
          ),
          rules: { required: true },
          control,
        }}
      />
      <ContractForm.ItemController
        label={t('contract')}
        itemDescription={
          <ItemDescription>{t('contractDescription')}</ItemDescription>
        }
        errors={errors}
        controller={{
          name: 'contractUrl',
          render: ({ field }) => <HiddenInput {...field} />,
          rules: { required: true },
          control,
        }}
      >
        <Upload
          defaultDocument={
            defaultContract && {
              title: t('contract'),
              url: defaultContract,
            }
          }
          documentId="contractUrl"
          setValue={setValue}
          accept={[...supportedFileTypes, 'application/pdf']}
        />
      </ContractForm.ItemController>
    </ContractForm>
  );
};

export default ContractInfo;
