import React, { ReactNode } from 'react';
import { shallowEqual } from 'react-redux';
import useSelectedProgram from './use-selected-program';
import { useAppSelector } from '../store/hooks';

function useTransactionStream() {
  const { selectedProgram } = useSelectedProgram();

  const { products, isLive } = useAppSelector(
    state => ({
      products: state.account.details?.products,
      isLive: state.live,
    }),
    shallowEqual,
  );

  return {
    hasProgramTransactionStream: selectedProgram?.type === 'transaction-stream',
    hasAccountTransactionStream:
      !!isLive && products?.transactionStream === true,
  };
}

export const SelectTransactionCheck = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const { hasAccountTransactionStream } = useTransactionStream();

  return hasAccountTransactionStream ? null : <>{children}</>;
};

export default useTransactionStream;
