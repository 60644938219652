import { ReactElement } from 'react';

export interface Field {
  name: string;
  element?: ReactElement;
  help?: string | ReactElement;
  validateCharacters?: boolean;
  rules?: any;
  type?: string;
  style?: Record<string, any>;
  defaultValue?: string;
  disabled?: boolean;
}

export interface Option {
  id: string;
  name: string;
}

export type TFunction = (text: string, options?: any) => string;

export type WeekDaysIndexes = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export const weekendDays = ['saturday', 'sunday'] as const;
export const businessWeekDays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
] as const;
export const weekDays = [weekendDays[1], ...businessWeekDays, weekendDays[0]];

export type WeekendDays = typeof weekendDays[number];
export type BusinessDay = typeof businessWeekDays[number];
export type WeekDay = WeekendDays | BusinessDay;

export const countryCodes = [
  'CAN',
  'FIN',
  'IRL',
  'JPN',
  'NOR',
  'SWE',
  'GBR',
  'USA',
  'ARE',
] as const;
export type CountryCode = typeof countryCodes[number];

export type ApiResponse<T> = {
  items: T[];
  resource: string;
  status: number;
  execution: number;
  last?: Last;
  count?: number;
};

export type Last = {
  programIdDel: string;
  id: string;
  time: string;
};
