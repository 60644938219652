import React from 'react';
import Icon from '@ant-design/icons';
import styled from 'styled-components';

import { ReactComponent as ArrowBase } from '../../assets/arrow.svg';
import { ReactComponent as CaronBase } from '../../assets/caron.svg';
import { ReactComponent as CheckBase } from '../../assets/check.svg';
import { ReactComponent as CheckCircleBase } from '../../assets/check_circle.svg';
import { ReactComponent as CloseCircleBase } from '../../assets/close_circle.svg';
import { ReactComponent as CopyBase } from '../../assets/copy.svg';
import { ReactComponent as DownloadBase } from '../../assets/download.svg';
import { ReactComponent as ErrorBase } from '../../assets/error.svg';
import { ReactComponent as InfoBase } from '../../assets/info.svg';
import { ReactComponent as QuestionMarkBase } from '../../assets/question_mark.svg';
import { ReactComponent as TriangleBase } from '../../assets/triangle.svg';
import { ReactComponent as WarningCircleBase } from '../../assets/warning_circle.svg';
import { ReactComponent as XBase } from '../../assets/x.svg';

const makeIcon = <T>(component: React.ComponentType<T>) => styled(Icon).attrs(
  () => ({
    component,
  }),
)`
  font-size: 20px;
`;

export const Arrow = makeIcon(ArrowBase);
export const Check = makeIcon(CheckBase);
export const CheckCircle = makeIcon(CheckCircleBase);
export const CloseCircle = makeIcon(CloseCircleBase);
export const Copy = makeIcon(CopyBase);
export const Download = makeIcon(DownloadBase);
export const Info = makeIcon(InfoBase);
export const QuestionMark = makeIcon(QuestionMarkBase);
export const ErrorIcon = makeIcon(ErrorBase);
export const WarningCircle = makeIcon(WarningCircleBase);

export const Caron = ({ ...props }) =>
  React.createElement(Icon, {
    component: CaronBase,
    className: 'anticon-down',
    ...props,
  });

export const Triangle = styled(Icon).attrs(() => ({
  component: TriangleBase,
}))`
  font-size: 12px;
  padding-left: 6px;
`;

export const X = styled(Icon).attrs(() => ({
  component: XBase,
}))`
  font-size: 16px;
  path {
    stroke-width: 3;
  }
`;
