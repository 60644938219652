import React, { ReactElement, ReactNode, useEffect, useState } from 'react';

import {
  Container,
  ContentDelayMessage,
} from '../styled/content-state-handler';
import showFirst from '../../../utils/show-first';
import Spin from '../../spin';
import Empty from '../../empty';
import { IllustrationsKeys } from '../../empty/illustrations';

interface ContentStateHandlerProps {
  bordered?: boolean;
  emptyText: string | ReactElement | null;
  isLoading: boolean;
  loadingExtra?: string | ReactElement | null;
  hasItems: boolean;
  children?: ReactNode;
  emptyIllustration?: IllustrationsKeys;
}

const ContentStateHandler = ({
  bordered,
  emptyText,
  isLoading,
  loadingExtra,
  hasItems,
  children,
  emptyIllustration,
}: ContentStateHandlerProps) => {
  const [showLoadingExtra, setShowLoadingExtra] = useState(false);

  useEffect(() => {
    if (loadingExtra) {
      const timer = setTimeout(() => {
        setShowLoadingExtra(true);
      }, 3000);

      return () => {
        if (timer) {
          clearTimeout(timer);
        }
      };
    }
    return () => {};
  }, [loadingExtra]);

  return (
    <>
      {showFirst([
        isLoading && (
          <Container bordered={bordered}>
            <Spin />
            {!!loadingExtra && showLoadingExtra && (
              <ContentDelayMessage>{loadingExtra}</ContentDelayMessage>
            )}
          </Container>
        ),
        !hasItems && emptyText !== null && (
          <Container bordered={bordered}>
            <Empty illustrationKey={emptyIllustration}>{emptyText}</Empty>
          </Container>
        ),
        <>{children}</>,
      ])}
    </>
  );
};

export default ContentStateHandler;
