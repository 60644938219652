import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { useForm } from 'react-hook-form';

import InlineForm from '../../../../components/inline-form';
import {
  supportedCountries,
  SupportedCountriesType,
} from '../../../../utils/countries';
import { updateAccount } from '../../../../store/account/account-actions';
import { TaggedInputTag } from '../../../../components/tag/tagged-input';
import { Select } from '../../../../components/select';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

interface TerritoriesForm {
  territories: SupportedCountriesType[];
}

const Territories = () => {
  const { t } = useTranslation('common', {
    keyPrefix: 'account.contract.territories',
  });
  const dispatch = useAppDispatch();
  const { accountDetails, loading, error } = useAppSelector(
    state => ({
      accountDetails: state.account.details,
      loading: state.account.loading,
      error: state.account.error,
    }),
    shallowEqual,
  );
  const { territories: defaultTerritories } = accountDetails || {};

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<TerritoriesForm>({
    mode: 'onBlur',
    defaultValues: {
      territories: defaultTerritories,
    },
  });

  function onSubmit({ territories }: TerritoriesForm) {
    dispatch(updateAccount({ territories }));
  }

  return (
    <InlineForm
      onSubmit={handleSubmit(onSubmit)}
      loading={loading}
      error={error}
    >
      <InlineForm.ItemController
        label={t('title')}
        errors={errors}
        controller={{
          name: 'territories',
          render: ({ field }) => (
            <Select
              {...field}
              mode="multiple"
              defaultValue={defaultTerritories}
              tagRender={({ label, onClose }) => (
                <TaggedInputTag onClose={onClose}>{label}</TaggedInputTag>
              )}
            >
              {supportedCountries.map(({ code, name }) => (
                <Select.Option key={code} value={code}>
                  {name} ({code})
                </Select.Option>
              ))}
            </Select>
          ),
          rules: { required: true },
          control,
        }}
      />
    </InlineForm>
  );
};

export default Territories;
