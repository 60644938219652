import React from 'react';
import styled from 'styled-components';

import { breakpoints } from '../../theme';

import cube from './assets/deco_cube.png';
import gradient from './assets/gradient.svg';
import hexagon from './assets/deco_hexagon.png';
import mask from './assets/mask.svg';
import maskLarge from './assets/mask_large.svg';
import person from './assets/lady.png';
import shuttle from './assets/shuttle.png';
import stairs from './assets/deco_stairs.png';

const StartIllustration = () => (
  <Illustration>
    <Zigzag src={stairs} alt="" />
    <Cube src={cube} alt="" />
    <Hexagon src={hexagon} alt="" />
    <Shuttle src={shuttle} alt="" />
    <MaskLarge src={maskLarge} alt="" />
    <Person src={person} alt="" />
    <Mask src={mask} alt="" />
  </Illustration>
);

const Illustration = styled.div`
  width: 100vw;
  height: 600px;
  background: bottom center / cover url(${gradient}) no-repeat;
  position: relative;
  overflow: hidden;

  @media (min-width: ${breakpoints.tabletLandscape}px) {
    flex-flow: row nowrap;
    width: 60%;
    height: inherit;
  }
`;

const Img = styled.img`
  position: absolute;
`;

const Cube = styled(Img)`
  width: 50px;
  top: calc(30px + 2vw);
  left: 33%;

  @media (min-width: ${breakpoints.tabletPortrait}px) {
    top: 30px;
    left: 46%;
  }

  @media (min-width: ${breakpoints.tabletLandscape}px) {
    top: 80px;
    left: 30%;
  }
`;

const Hexagon = styled(Img)`
  width: 28px;
  top: calc(140px + 5vw);
  right: 8%;

  @media (min-width: ${breakpoints.tabletPortrait}px) {
    right: 12%;
  }

  @media (min-width: ${breakpoints.tabletLandscape}px) {
    left: 70%;
  }

  @media (min-width: 1300px) {
    top: 25vh;
    left: 50%;
  }
`;

const Zigzag = styled(Img)`
  width: 46px;
  top: calc(150px - 5vw);
  left: 14%;

  @media (min-width: ${breakpoints.tabletPortrait}px) {
    top: calc(130px - 5vw);
    left: 27%;
  }

  @media (min-width: ${breakpoints.tabletLandscape}px) {
    top: 16vh;
    left: 18%;
  }
`;

const Person = styled(Img)`
  max-width: 70%;
  max-height: 400px;
  bottom: 0;
  left: -20px;

  @media (min-width: ${breakpoints.tabletPortrait}px) {
    max-width: 33%;
    left: 15%;
  }

  @media (min-width: ${breakpoints.tabletLandscape}px) {
    max-width: 60%;
    max-height: 70vh;
    left: calc(-90px + 12vh);
  }
`;

const Shuttle = styled(Img)`
  max-width: 70%;
  max-height: 66%;
  bottom: 20%;
  right: -4px;

  @media (min-width: ${breakpoints.tabletPortrait}px) {
    right: 13%;
    max-height: 80%;
    top: 24px;
  }

  @media (min-width: ${breakpoints.tabletLandscape}px) {
    max-height: 65vh;
    top: 10vh;
    right: 6vw;
  }
`;

const Mask = styled(Img)`
  width: 180vw;
  left: -58vw;
  bottom: -20vw;

  @media (min-width: 500px) {
    left: -54vw;
  }

  @media (min-width: ${breakpoints.tabletLandscape}px) {
    display: none;
  }
`;

const MaskLarge = styled(Img)`
  display: none;

  @media (min-width: ${breakpoints.tabletLandscape}px) {
    display: block;
    bottom: 0;
    right: -1px;
    height: 100vh;
  }
`;

export default StartIllustration;
