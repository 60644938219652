import React, { useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { getProfile } from '../../../../store/account/account-actions';
import useProgressState from '../../../../hooks/use-progress-state';
import Form from '../../../../components/form';
import { FieldsWrapper, StyledTextArea, StyledSpin } from '../styled';
import { ProfileFormModel } from '../types';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

const PitchStep = () => {
  const dispatch = useAppDispatch();

  const { profilePitch, gettingPitch } = useAppSelector(
    ({ account }) => ({
      profilePitch: account.profile.entity?.pitch,
      gettingPitch: account.profile.loading,
    }),
    shallowEqual,
  );

  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<ProfileFormModel>();

  const profileUpdate = useProgressState(gettingPitch);

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  profileUpdate.onFinish(() => {
    profilePitch && !getValues('pitch') && setValue('pitch', profilePitch);
  });

  return (
    <FieldsWrapper>
      <Form.ItemController
        errors={errors}
        validateCharacters
        controller={{
          name: 'pitch',
          render: ({ field }) => (
            <StyledTextArea
              {...field}
              height={256}
              data-testid="pitch"
              disabled={gettingPitch}
            />
          ),
          control,
        }}
      />

      {gettingPitch && <StyledSpin />}
    </FieldsWrapper>
  );
};

export default PitchStep;
