import React from 'react';
import { Col, Row, Typography } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../theme';
import { CustomerRecency, Offer } from '../../../store/offers/offers-model';

export default function OfferLabels({ offer }: { offer: Offer }) {
  const { t } = useTranslation('offers');

  const { featured, customerRecency, metadata } = offer;

  const hasRecencyEnabled =
    customerRecency?.enabled ||
    (metadata?.customerRecency as CustomerRecency)?.enabled;

  const showLabels = featured === 1 || hasRecencyEnabled;

  if (!showLabels) return null;

  return (
    <StyledLabelsRow align="middle">
      {featured === 1 && (
        <Col>
          <StyledOfferLabel>{t('featured')}</StyledOfferLabel>
        </Col>
      )}
      {hasRecencyEnabled && (
        <Col>
          <StyledOfferLabel color={colors.fidelBlue600}>
            {t('fields.customerRecency.tag').toUpperCase()}
          </StyledOfferLabel>
        </Col>
      )}
    </StyledLabelsRow>
  );
}

const StyledLabelsRow = styled(Row)`
  > .ant-col {
    line-height: 0 !important;
  }

  > :not(:first-of-type) {
    padding: 0 5px;
    border-left: 2px solid ${colors.grey3};
  }

  > :first-of-type {
    margin-right: 5px !important;
  }
`;

const StyledOfferLabel = styled(Typography.Text)<{
  color?: string;
}>`
  font-weight: 700;
  font-size: 9px;
  line-height: 12px;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  color: ${props => props.color || colors.green600};
`;
