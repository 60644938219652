import { useCallback } from 'react';
import { CardsActions } from '../store';
import { CardsOptions } from '../store/cards/cards-types';
import { useAppDispatch } from '../store/hooks';

export default function useLoadCards(programId: string | undefined) {
  const dispatch = useAppDispatch();

  const loadCards = useCallback(
    ({ clearFirst }: { clearFirst: boolean } = { clearFirst: false }) => {
      if (clearFirst) dispatch(CardsActions.clear());

      if (programId) {
        const optionsWithFilters: CardsOptions = {
          programId,
        };

        dispatch(CardsActions.getCardsCount(optionsWithFilters));
        dispatch(CardsActions.getCards(optionsWithFilters));
      } else {
        dispatch(CardsActions.clear());
      }
    },
    [dispatch, programId],
  );

  return loadCards;
}
