import styled from 'styled-components';
import { Tag } from 'antd';

import { Caron as CaronIcon } from '../../icons';

const hoverColors: Record<string, [string, string]> = {
  red: ['white', '#dd0229'],
  blue: ['white', '#0069fd'],
  green: ['white', '#21b115'],
  default: ['white', '#516078'],
};

export const ClickableTag = styled(Tag)<{
  $clickable?: boolean;
  color: string;
}>`
  font-size: 13px;
  max-width: 100%;
  margin-right: 0;
  text-align: left;
  ${({ $clickable, color }) => {
    const hoverColor = hoverColors[color];
    if (!$clickable || !hoverColor) return '';
    return `
      :hover {
        cursor: pointer;
        color: ${hoverColor[0]};
        background: ${hoverColor[1]};
      }
    `;
  }}

  @media(max-width: 930px) {
    font-size: 11px;
  }

  @media (max-width: 1020px) {
    font-size: 12px;
  }
`;

export const Caron = styled(CaronIcon)`
  margin-left: 3px !important;
  font-size: 9px;
  line-height: 0;
  display: inline-block;
  position: relative;
  top: -1px;
  right: 8px;
  user-select: none;
  svg {
    path {
      stroke-width: 2.5;
    }
  }
`;
