import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Input, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

import { GoLiveFormProps } from '.';
import { Title, Description } from './styled';
import { GoLiveModel } from './use-go-live-form-state';
import { RequiredFormItemController } from '../../components/form/styled';
import Link from '../../components/link';
import { FeatureCheck } from '../../features';
import SelectProgramType from '../programs/components/SelectProgramType';
import SubForm from './SubForm';

const ProgramForm = ({ onClick }: GoLiveFormProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<GoLiveModel>();
  const { t } = useTranslation('common', {
    keyPrefix: 'account.goLive.steps.program',
  });

  return (
    <SubForm onClick={onClick}>
      <Title>{t('title')}</Title>
      <FeatureCheck feature="programTypeSelectionGoLive">
        <Description>{t('description')}</Description>
        <SelectProgramType />
        <Typography.Paragraph
          type="secondary"
          style={{ margin: '-20px 0 20px' }}
        >
          <Trans
            i18nKey="account.goLive.steps.program.note"
            components={{
              'support-contact': <Link to="mailto:support@fidel.uk" />,
            }}
          />
        </Typography.Paragraph>
      </FeatureCheck>
      <RequiredFormItemController
        label={t('fields.description')}
        errors={errors}
        validateCharacters
        controller={{
          name: 'description',
          render: ({ field }) => (
            <Input.TextArea
              {...field}
              autoSize={{ minRows: 10 }}
              style={{ resize: 'none' }}
            />
          ),
          rules: { required: true, minLength: 5, maxLength: 1000 },
          control,
        }}
      />
      <Typography.Paragraph type="secondary" style={{ margin: '7px 0' }}>
        {t('fields.descriptionExplanation')}
      </Typography.Paragraph>
    </SubForm>
  );
};

export default ProgramForm;
