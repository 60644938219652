import React, { PropsWithChildren } from 'react';
import { range } from 'lodash';
import { shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormContext, UseFormReturn } from 'react-hook-form';

import { updateBillingConfigurations } from '../../../../store/account/account-actions';
import { ProductPeriod } from '../../../../store/account/account-model';
import DrawerForm from '../../../../components/drawer-form';
import { DrawerVisibleProps } from '../../../../components/drawer-transition';
import {
  CreateUpdateBillingPeriodMode,
  findNextVoid,
  useInvoiceInterval,
  PeriodKey,
} from './utils';
import Form from '../../../../components/form';
import { Select } from '../../../../components/select';
import ConditionalTooltip from '../../../../components/conditional-tooltip';
import useCurrentPeriod from '../../../../hooks/use-current-period';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

export const numberOfFuturePeriodsToShow = 48;

export interface CreateUpdateBillingPeriodProps<P extends ProductPeriod>
  extends DrawerVisibleProps {
  onClose: () => any;
  mode?: CreateUpdateBillingPeriodMode;
  selectedPeriod?: P;
}

interface CreateUpdateBillingPeriodBaseProps<P extends ProductPeriod>
  extends CreateUpdateBillingPeriodProps<P> {
  form: UseFormReturn<P>;
  periodKey: PeriodKey;
  generatePeriodToSubmit: (event: any) => any;
  title: string;
}

export function calculateExistingFuturePeriods(
  periods: ProductPeriod[] | undefined,
  currentInvoice: number,
) {
  return (periods || [])
    .map((period: any) => period.invoiceNumber)
    .filter(n => n >= currentInvoice)
    .sort((a, b) => a - b);
}

const CreateUpdateBillingPeriod: <P extends ProductPeriod>(
  props: PropsWithChildren<CreateUpdateBillingPeriodBaseProps<P>>,
) => React.ReactElement<CreateUpdateBillingPeriodBaseProps<P>> = ({
  afterVisibleChange,
  onClose,
  mode,
  periodKey,
  selectedPeriod,
  visible = true,
  generatePeriodToSubmit,
  title,
  children,
}) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'account.contract.billingPeriods.createUpdate',
  });
  const dispatch = useAppDispatch();
  const { getInvoiceInterval } = useInvoiceInterval();
  const currentInvoiceNumber = useCurrentPeriod();
  const { billingConfigurations, processing } = useAppSelector(
    state => ({
      billingConfigurations: state.account.billingConfigurations.entity,
      processing: state.account.billingConfigurations.updating,
    }),
    shallowEqual,
  );
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useFormContext();

  const { periods } = billingConfigurations?.[periodKey] || {};

  const existingFuturePeriods = calculateExistingFuturePeriods(
    periods,
    currentInvoiceNumber,
  );

  const isModeUpate = mode === CreateUpdateBillingPeriodMode.Update;

  function onSubmit(event: Partial<ProductPeriod>) {
    const currentBillingPeriods = [
      ...(billingConfigurations?.[periodKey]?.periods || []),
    ];

    const periodToSubmit = generatePeriodToSubmit(event);

    if (isModeUpate) {
      dispatch(
        updateBillingConfigurations({
          [periodKey]: {
            ...billingConfigurations?.[periodKey],
            periods: [
              ...currentBillingPeriods.map(period =>
                period.invoiceNumber === selectedPeriod?.invoiceNumber
                  ? periodToSubmit
                  : period,
              ),
            ],
          },
        }),
      );
    } else {
      dispatch(
        updateBillingConfigurations({
          [periodKey]: {
            ...billingConfigurations?.[periodKey],
            periods: [periodToSubmit, ...currentBillingPeriods],
          },
        }),
      );
    }

    onClose();
  }

  return (
    <DrawerForm
      title={title}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
      processing={processing}
      visible={visible}
      afterVisibleChange={afterVisibleChange}
    >
      <Form.ItemController
        label={t('invoiceNumber')}
        errors={errors}
        controller={{
          name: 'invoiceNumber',
          defaultValue: isModeUpate
            ? selectedPeriod?.invoiceNumber
            : findNextVoid(existingFuturePeriods, currentInvoiceNumber),
          render: ({ field }) => (
            <Select {...field} disabled={isModeUpate}>
              {range(numberOfFuturePeriodsToShow).map(month => {
                const disabled = existingFuturePeriods.some(
                  periodInvoiceNumber =>
                    periodInvoiceNumber === month + currentInvoiceNumber,
                );
                const { start, end } = getInvoiceInterval(month);
                return (
                  <Select.Option
                    key={month}
                    value={currentInvoiceNumber + month}
                    disabled={disabled}
                    data-testid={`${start} ${end}`}
                  >
                    <ConditionalTooltip
                      condition={disabled}
                      title={t('optionDisabledText')}
                      trigger={['click', 'hover']}
                      mouseEnterDelay={1}
                    >
                      <>
                        {t('invoiceNumberOption', {
                          invoiceNumber: currentInvoiceNumber + month,
                          start,
                          end,
                        })}
                      </>
                    </ConditionalTooltip>
                  </Select.Option>
                );
              })}
            </Select>
          ),
          rules: { required: true },
          control,
        }}
      />
      {children}
    </DrawerForm>
  );
};

export default CreateUpdateBillingPeriod;
