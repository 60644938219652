import styled from 'styled-components';

import { colors } from '../../../theme';

export const CustomHeadersTitle = styled.h3`
  margin: 27px 0 14px 0;
  font-size: 16px;
  font-weight: normal;
`;

export const CustomHeadersWrapper = styled.div`
  display: flex;

  .ant-form-item {
    max-width: calc(50% - 25px);
  }
`;

export const Equals = styled.span.attrs(() => ({ children: '=' }))`
  color: ${colors.grey4};
  font-weight: bold;
  margin: 0 5px;
  position: relative;
  top: 32px;
`;
