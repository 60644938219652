import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Typography } from 'antd';
import memoNoProps from '../../utils/memo-no-props';
import { BoldText } from '../../components/styled/light-bold-text';
import { ReactComponent as LockIcon } from '../../assets/lock.svg';
import { Container, NextContainer, OuterContainer } from './styled';
import Link from '../../components/link';

const { Title, Paragraph } = Typography;

const AccountLocked = () => {
  const { t } = useTranslation();

  const emailAddress = t('accountLocked.email')
    .replace('<0>', '')
    .replace('</0>', '');

  return (
    <OuterContainer>
      <Container>
        <LockIcon style={{ marginBottom: '1rem' }} />
        <Title level={4}>{t('accountLocked.title')}</Title>
        <Paragraph>{t('accountLocked.description')}</Paragraph>
        <Paragraph>{t('accountLocked.finalThoughts')}</Paragraph>
        <Title level={5}>{t('accountLocked.nextTitle')}</Title>
        <NextContainer>
          <Trans
            i18nKey="accountLocked.contactSupport"
            components={{
              bold: <BoldText />,
            }}
            load
          />
          <div>
            <span>{t('accountLocked.reachOut')}</span>
            <Trans
              i18nKey="accountLocked.email"
              components={[<Link to={`mailto:${emailAddress}`} />]}
            />
          </div>
        </NextContainer>
      </Container>
    </OuterContainer>
  );
};

export default memoNoProps(AccountLocked);
