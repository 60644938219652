import styled from 'styled-components';

export const CustomEmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 65px;
  flex-direction: column;
  text-align: center;
`;
