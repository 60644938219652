import { Input } from 'antd';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Form from '../../../../../components/form';
import { RequiredFormItemController } from '../../../../../components/form/styled';
import ImageUpload, {
  CroppedImages,
} from '../../../../../components/upload/CroppedImageUpload';
import useFocusField from '../../../../../hooks/use-focus-field';
import { Brand, UniqueBrand } from '../../../../../store/brands/brands-model';
import { Program } from '../../../../../store/programs/programs-reducer';
import regex from '../../../../../utils/regex';
import { BrandDetailField, BrandDetailFieldsNames } from '../../../types';
import EditBrandLogo from './EditBrandLogo';
import SelectProgram from './SelectProgram';
import { useAppSelector } from '../../../../../store/hooks';

interface CreateEditBrandFormProps {
  isLive: boolean;
  brand?: Brand | UniqueBrand | null | undefined;
  program?: Program;
}

const CreateEditBrandForm = ({
  isLive,
  brand,
  program,
}: CreateEditBrandFormProps) => {
  const uniqueBrandsSearch = useAppSelector(
    state => state.brands.uniqueBrandsSearch,
  );
  const { t } = useTranslation('brands');
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const [brandLogo, setBrandLogo] = useState(brand?.logoURL);

  const createBrandMode = !brand;
  const isBrandWithoutLogo = !brand?.logoURL;
  const inputRef = useFocusField<Input>();
  const fields: Record<BrandDetailFieldsNames, BrandDetailField> = {
    brandName: {
      name: 'brandName',
      rules: { required: createBrandMode, minLength: 2, maxLength: 50 },
      validateCharacters: true,
      defaultValue: uniqueBrandsSearch,
      disabled: !createBrandMode,
    },
    brandLogo: {
      name: 'brandLogo',
    },
    websiteURL: {
      name: 'websiteURL',
      rules: {
        validate: {
          invalidUrl: (value: string) => !value || !!regex.httpsUrl.exec(value),
        },
      },
    },
  };

  function uploadBrandLogoHandler({ squared, rounded }: CroppedImages) {
    setValue('brandLogo', { image: squared, croppedImage: rounded });
    setBrandLogo(squared);
  }

  function getBrandLogoField() {
    if (createBrandMode || isBrandWithoutLogo) {
      return <ImageUpload height={198} onImagesCrop={uploadBrandLogoHandler} />;
    }

    return (
      <EditBrandLogo imageSrc={brandLogo} onSubmit={uploadBrandLogoHandler} />
    );
  }

  function setInputProps({
    name,
    rules,
    validateCharacters,
    defaultValue,
    disabled,
    element,
  }: BrandDetailField): React.ComponentProps<typeof Form.ItemController> & {
    key: string;
  } {
    return {
      key: name,
      label: t(`createBrand.label.${name}`),
      errors,
      validateCharacters,
      controller: {
        name,
        render: ({ field }) =>
          element || (
            <Input
              {...field}
              placeholder={t(`createBrand.placeholder.${name}`)}
              ref={name === 'brandName' ? inputRef : null}
              disabled={disabled}
            />
          ),
        control,
        rules,
        defaultValue,
      },
    };
  }

  return (
    <>
      <RequiredFormItemController {...setInputProps({ ...fields.brandName })} />
      <Form.ItemController
        {...setInputProps({
          ...fields.brandLogo,
          element: getBrandLogoField(),
        })}
      />
      <Form.ItemController {...setInputProps({ ...fields.websiteURL })} />

      {isLive && createBrandMode && <SelectProgram program={program} />}
    </>
  );
};

export default CreateEditBrandForm;
