import React from 'react';
import { Input, Typography } from 'antd';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import drawerTransition from '../../../../../components/drawer-transition';
import Form from '../../../../../components/form';
import { FieldWithCurrencyWrapper } from '../../../../../components/form/styled';

import { AdjustmentPeriod } from '../../../../../store/account/account-model';
import regex from '../../../../../utils/regex';
import CreateUpdatePeriod, {
  CreateUpdateBillingPeriodProps,
} from '../CreateUpdateBillingPeriod';
import { useAppSelector } from '../../../../../store/hooks';

const CreateUpdateAdjustmentPeriod = ({
  mode,
  onClose,
  visible,
  selectedPeriod,
  afterVisibleChange,
}: CreateUpdateBillingPeriodProps<AdjustmentPeriod>) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'account.contract.billingPeriods',
  });
  const billingConfigurations = useAppSelector(
    state => state.account.billingConfigurations.entity,
  );

  const { currency } = billingConfigurations || {};

  const form = useForm<AdjustmentPeriod>({
    mode: 'onBlur',
    defaultValues: {
      amount: billingConfigurations?.adjustment?.periods.find(
        period => period.invoiceNumber === selectedPeriod?.invoiceNumber,
      )?.amount,
    },
  });
  const {
    control,
    formState: { errors },
  } = form;

  return (
    <FormProvider {...form}>
      <CreateUpdatePeriod
        title={t(`createUpdate.title.${mode || 'create'}`, {
          name: t('title.adjustment'),
        })}
        form={form}
        mode={mode}
        periodKey="adjustment"
        onClose={onClose}
        visible={visible}
        selectedPeriod={selectedPeriod}
        afterVisibleChange={afterVisibleChange}
        generatePeriodToSubmit={(event: AdjustmentPeriod) => ({
          invoiceNumber: Number(event.invoiceNumber),
          amount: Number(event.amount),
        })}
      >
        <FieldWithCurrencyWrapper>
          <Form.ItemController
            label={t('createUpdate.adjustment.label')}
            errors={errors}
            style={{ marginBottom: '8px' }}
            controller={{
              name: 'amount',
              render: ({ field }) => <Input {...field} prefix={currency} />,
              rules: {
                required: true,
                validate: {
                  numeric: value => !value || regex.decimalNumeric.test(value),
                },
              },
              control,
            }}
            itemDescription={
              <Typography.Paragraph type="secondary">
                {t('description')}
              </Typography.Paragraph>
            }
          />
        </FieldWithCurrencyWrapper>
      </CreateUpdatePeriod>
    </FormProvider>
  );
};

export default drawerTransition(CreateUpdateAdjustmentPeriod);
