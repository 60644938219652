import React, { ReactNode, useEffect, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

import Spin from '../spin';
import {
  Content,
  Count,
  SpinContainer,
  NoCountPadding,
} from './styled/infinity-table';
import BaseTable, { BaseTableProps } from './BaseTable';

interface InfiniteScrollTableProps
  extends Omit<BaseTableProps, 'bottomRowElement'> {
  activeFilters?: number;
  onBottom?: () => unknown;
  topContent?: ReactNode;
  total?: number;
  showBottomPadding?: boolean;
}

const BaseTableMemo = memo(BaseTable);

const InfiniteScrollTable = ({
  activeFilters,
  loading,
  loadingExtra,
  refetching,
  minWidth = 800,
  onBottom,
  rows,
  topContent,
  total,
  showBottomPadding,
  stretch = true,
  ...rest
}: InfiniteScrollTableProps) => {
  const { t } = useTranslation();
  const [bottomRef, bottomInView] = useInView();
  const attachOnBottomListener =
    onBottom && rows && rows.length > 0 && !loading;

  const hasItems = rows.length > 0;
  const loadingItems = !!total && rows.length === 0 && !activeFilters;
  const isLoading = (loading && !hasItems) || loadingItems;

  const showTotal = hasItems && !!total;

  useEffect(() => {
    if (bottomInView && onBottom) {
      onBottom();
    }
  }, [bottomInView, onBottom]);

  return (
    <Content stretch={stretch}>
      <div style={{ padding: '0 20px' }}>{topContent}</div>
      <BaseTableMemo
        rows={rows}
        minWidth={minWidth}
        loading={isLoading}
        loadingExtra={loadingExtra}
        bottomRowElement={
          <>
            {showTotal && (
              <Count data-testid="count">
                {t('table.count', { count: rows.length, total })}
                {loading && (
                  <SpinContainer data-testid="table-loading">
                    <Spin />
                  </SpinContainer>
                )}
              </Count>
            )}
            {showBottomPadding && (
              <NoCountPadding>
                {refetching && (
                  <SpinContainer data-testid="table-loading">
                    <Spin />
                  </SpinContainer>
                )}
              </NoCountPadding>
            )}
            {attachOnBottomListener && (
              <div
                data-testid="infinity-table-bottom"
                ref={bottomRef}
                style={{ height: '1px' }}
              />
            )}
          </>
        }
        {...rest}
      />
    </Content>
  );
};

export default InfiniteScrollTable;
