import { SearchParamsConfig } from './types';

export function isValidSort(params: URLSearchParams) {
  const validOrder = ['desc', 'asc'];

  const sort = params.get('sort') || '';
  const order = params.get('order') || '';

  return [sort, order].every(value => !!value) && validOrder.includes(order);
}

export function filterInvalidSearchKeys(
  queryString: URLSearchParams,
  searchConfig: SearchParamsConfig,
) {
  const { filters: validFilterKeys, sort: validSortKeys } = searchConfig;

  const params = new URLSearchParams(queryString);
  const filteredParams = new URLSearchParams();

  const sort = params.get('sort') || '';
  const order = params.get('order') || '';

  if (isValidSort(params) && validSortKeys.includes(sort)) {
    filteredParams.append('sort', sort);
    filteredParams.append('order', order);
  }

  for (const [key, value] of params.entries()) {
    if (validFilterKeys.includes(key)) {
      filteredParams.append(key, value);
    }
  }

  return filteredParams;
}
