import { AccountDetails } from '../../../store/account/account-model';
import { MerchantIds } from '../../../store/locations/locations-api-service';
import { Location } from '../../../store/locations/locations-model';
import { MerchantIdsSubForModel } from '../components/CreateUpdateLocationForm';

export function merchantIdsToSearchBy(merchantIds: MerchantIdsSubForModel[]) {
  const merchantIdsByScheme = (merchantIds || []).reduce(
    (schemesAcc: Required<MerchantIds>, schemeMerchant) => {
      const { scheme, id } = schemeMerchant;

      if (!scheme || !id) return schemesAcc;

      if (scheme === 'amex') {
        schemesAcc.amex.push(id);
      } else {
        schemesAcc.mastercard.push(id);
        schemesAcc.visa.push(id);
      }

      return schemesAcc;
    },
    {
      mastercard: [],
      visa: [],
      amex: [],
    },
  );

  return {
    merchantIds: merchantIdsByScheme,
  };
}

export function searchByToMerchantIds(
  location?: Location,
  accountDetails?: AccountDetails,
) {
  let amexMid: MerchantIdsSubForModel[] = [];
  let visaMastercardMid: MerchantIdsSubForModel[] = [];

  if (location && location.searchBy && accountDetails) {
    const { amex, visa, mastercard } = location.searchBy.merchantIds;

    if (accountDetails.amex) {
      amexMid = (amex || []).map((mid: string) => ({
        scheme: 'amex',
        id: mid,
      }));
    }

    if (accountDetails.visa && !accountDetails.mastercard) {
      visaMastercardMid = (visa || []).map((mid: string) => ({
        scheme: 'visa',
        id: mid,
      }));
    } else if (!accountDetails.visa && accountDetails.mastercard) {
      visaMastercardMid = (mastercard || []).map((mid: string) => ({
        scheme: 'mastercard',
        id: mid,
      }));
    } else if (accountDetails.visa && accountDetails.mastercard) {
      visaMastercardMid = (visa || []).map((mid: string) => ({
        scheme: 'visa&mastercard',
        id: mid,
      }));
    }
  }

  return {
    merchantIds: [...amexMid, ...visaMastercardMid],
  };
}
