import React from 'react';
import Avatar from '../../../components/avatar';
import { CardContent } from '../../../components/tables/styled/helpers';
import Ellipsis from '../../../components/ellipsis';

const BrandCard = ({ logoURL, name }: { logoURL?: string; name?: string }) => (
  <CardContent data-testid="brand-card-container">
    <Avatar
      src={logoURL}
      color={{ border: 'lightgrey2' }}
      style={{ marginRight: '10px' }}
    >
      {name}
    </Avatar>
    <Ellipsis gutter={40}>{name}</Ellipsis>
  </CardContent>
);

export default BrandCard;
