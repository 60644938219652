import React from 'react';
import { Col, DatePicker, Row, TimePicker } from 'antd';
import { Moment } from 'moment';
import {
  Control,
  RegisterOptions,
  DeepMap,
  FieldError,
  UseFormSetValue,
  UseFormGetValues,
} from 'react-hook-form/dist/types';

import Form from '../../../../../components/form';
import { CreateUpdateOfferFormModel } from '../../../types';

type DateTimeOptions = {
  name: string;
  label: string;
  placeholder?: string;
  rules?: RegisterOptions;
  errors?: DeepMap<any, FieldError>;
};

interface DateTimeInputProps {
  control: Control<CreateUpdateOfferFormModel>;
  date: DateTimeOptions;
  time: DateTimeOptions;
  disabledDate?: (currentDate: Moment) => boolean;
  disabledHours?: () => number[];
  disabledMinutes?: (hour: number) => number[];
  disabled?: boolean;
  getValues: UseFormGetValues<CreateUpdateOfferFormModel>;
  setValue: UseFormSetValue<CreateUpdateOfferFormModel>;
}

function defaultTime({ name }: DateTimeOptions) {
  return name === 'endTime' ? '23:59' : '00:00';
}

function endTimeValue(value: string, setValue: UseFormSetValue<any>) {
  if (!value) setValue('endTime', undefined);
  return value;
}

function disabledEndTime(
  { name }: DateTimeOptions,
  { endDate }: CreateUpdateOfferFormModel,
) {
  return name === 'endTime' && !endDate;
}

function datePickerOnChangeHandler(
  onChange: (...event: any[]) => void,
  setValue: UseFormSetValue<any>,
) {
  return (event: Moment | null, dateString: string) => {
    onChange(event);
    endTimeValue(dateString, setValue);
  };
}

const DateTimeInput = ({
  control,
  date,
  time,
  disabledDate,
  disabledHours,
  disabledMinutes,
  getValues,
  setValue,
}: DateTimeInputProps) => (
  <Row justify="space-between" gutter={8}>
    <Col span={14}>
      <Form.ItemController
        errors={date.errors}
        label={date.label}
        style={{ width: '100%' }}
        controller={{
          name: date.name,
          render: ({ field: { onChange, ...rest } }) => (
            <DatePicker
              {...rest}
              disabledDate={disabledDate}
              format="DD/MM/YYYY"
              placeholder={date.placeholder}
              style={{ width: '100%' }}
              onChange={datePickerOnChangeHandler(onChange, setValue)}
            />
          ),
          rules: date.rules,
          control,
        }}
      />
    </Col>
    <Col>
      <Form.ItemController
        errors={time.errors}
        label={time.label}
        style={{ width: '100%' }}
        controller={{
          name: time.name,
          render: ({ field }) => (
            <TimePicker
              {...field}
              disabledHours={disabledHours}
              disabledMinutes={disabledMinutes}
              format="HH:mm"
              placeholder={defaultTime(time)}
              disabled={disabledEndTime(time, getValues())}
            />
          ),
          rules: time.rules,
          control,
        }}
      />
    </Col>
  </Row>
);

export default DateTimeInput;
