import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
`;

export const Separator = styled.span`
  text-align: center;
  width: 20px;
  ::after {
    content: '/';
  }
`;
