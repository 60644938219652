import styled from 'styled-components';
import { colors } from '../../../../../theme';
import Ellipsis from '../../../../../components/ellipsis';

export const BrandName = styled.span`
  color: ${colors.black};
  margin-right: 15px;
  white-space: nowrap;
`;
export const BrandWebsite = styled(Ellipsis)`
  font-size: 14px !important;
  color: ${colors.grey4};
`;
