import styled, { StyledComponent } from 'styled-components';
import { colors } from '../../theme';

const isRow = '@media (min-width: 1100px)';
const isColumn = '@media (max-width: 1100px)';

const Container = styled.div`
  display: flex;

  ${isColumn} {
    flex-direction: column;
  }
`;

const Side = styled.div`
  ${isRow} {
    padding-top: 30px;
    min-height: 100vh;
    padding-bottom: 5%;
  }

  ${isColumn} {
    padding: 20px;
  }

  flex-grow: 1;
  flex-basis: 450px;
`;

const Left = styled(Side)`
  ${isRow} {
    padding-right: 7.5%;
    padding-left: 15%;
  }

  background-color: ${colors.lightgrey};
`;

const Right = styled(Side)`
  ${isRow} {
    padding-right: 15%;
    padding-left: 7.5%;
  }
`;

type StyledDiv = StyledComponent<'div', any, Record<string, unknown>, never>;
type PageSplitType = StyledDiv & {
  Left: StyledComponent<'div', any, Record<string, unknown>, never>;
  Right: StyledComponent<'div', any, Record<string, unknown>, never>;
  isRow: string;
  isColumn: string;
};

const PageSplit = Container as PageSplitType;
PageSplit.Left = Left;
PageSplit.Right = Right;
PageSplit.isRow = isRow;
PageSplit.isColumn = isColumn;
export default PageSplit;
