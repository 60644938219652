import {
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { shallowEqual } from 'react-redux';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { AxiosError } from 'axios';

import OfferApiService from '../../../store/offers/offers-api-service';
import { useSetStatus } from '../../../hooks/use-status';
import { parseAxiosError } from '../../../store/utils/transform';
import { filterInvalidSearchKeys } from '../../../components/filters/utils';
import {
  OfferType,
  transformToOffer,
} from '../../../store/offers/offers-model';
import { columnsByTab } from '../offers-list/config';
import { filterNames } from '../../../components/filters/components/FilterDropdown';
import { SearchParamsConfig } from '../../../components/filters/types';
import { useAppSelector } from '../../../store/hooks';

const offerService = new OfferApiService();

export function useOffers(
  { enabled, count }: { enabled?: boolean; count?: boolean } = {
    enabled: true,
    count: false,
  },
) {
  const isContentProvider = useAppSelector(
    ({ account }) => !!account.contentProviderInfo,
    shallowEqual,
  );
  const { type } = useParams<{ type: OfferType }>();
  const [searchParams] = useSearchParams();
  const { setStatus } = useSetStatus();
  const queryClient = useQueryClient();

  const searchConfig: SearchParamsConfig = {
    filters: [...filterNames],
    sort: columnsByTab[type].map(column =>
      column === 'requestedDate' ? 'created' : column,
    ),
  };

  const validSearchParams = filterInvalidSearchKeys(searchParams, searchConfig);

  async function queryOffers({ pageParam }: any) {
    const res = await offerService.getOffersByType({
      type,
      last: pageParam,
      select: count ? 'count' : undefined,
      filters: validSearchParams,
      isContentProvider,
    });

    return {
      ...res.data,
      items: res.data.items.map(transformToOffer),
      count: res.data.count,
    };
  }

  return useInfiniteQuery(
    ['offers', `${type}${count ? '-count' : ''}`, searchParams.toString()],
    queryOffers,
    {
      getNextPageParam: lastPage => lastPage.last || undefined,
      onError: (error: AxiosError) => {
        const { code, message } = parseAxiosError(error);
        setStatus(code, { message });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['offers', 'count']);
        if (!count) queryClient.invalidateQueries(['offers', `${type}-count`]);
      },
      enabled,
    },
  );
}

export function useOffersCount() {
  const isContentProvider = useAppSelector(
    ({ account }) => !!account.contentProviderInfo,
    shallowEqual,
  );

  async function queryOffersCount() {
    const response = await offerService.getOffers({
      count: true,
      isContentProvider,
    });
    return response;
  }

  return useQuery(['offers', 'count'], queryOffersCount);
}
