import { ProgramType } from '../../store/programs/programs-reducer';

export const defaultProgramType: ProgramType = 'transaction-select';

export interface PlaygroundForm {
  cardFailChecked: boolean;
  cardId?: string;
  cardLastNumbers?: string;
  locationId?: string;
  programId?: string;
  programType: ProgramType;
}

export const defaultValues: PlaygroundForm = {
  cardFailChecked: false,
  cardId: undefined,
  cardLastNumbers: undefined,
  locationId: undefined,
  programId: undefined,
  programType: defaultProgramType,
};
