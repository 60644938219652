import styled from 'styled-components';
import { Menu } from 'antd';

import { Caron } from '../../../../components/icons';
import { colors, hiddenScrollbar } from '../../../../theme';

const flexItemWithAvatar = `
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 46px;
  font-size: 15px;

  .ant-avatar {
    flex: 0 0 auto;
    margin-right: 12px;
  }

  >:last-child {
    max-width: calc(100% - 40px);
  }
`;

export const ProgramInfoLabel = styled.small`
  color: ${colors.grey4};
`;

export const MenuItem = styled(Menu.Item)`
  ${flexItemWithAvatar}
  padding: 5px 14px;
  line-height: 1.2;
  color: ${colors.grey5};

  &:hover,
  &:focus {
    color: ${colors.fidelBlue600};
    background: ${colors.lightgrey5};
  }
`;

export const MenuItemGroup = styled(Menu.ItemGroup)`
  .ant-dropdown-menu-item-group-title {
    position: sticky;
    z-index: 2;
    top: 0;
    margin: 0;
    padding-top: 14px;
    padding-bottom: 10px;
    background: white;

    font-size: 13px;
    color: ${colors.grey4};
  }

  .ant-dropdown-menu-item-group-list {
    margin: 0;
    padding-top: 5px;

    span {
      font-weight: normal;
      color: ${colors.grey5};
    }
  }
`;

export const MenuItemTitle = styled.span`
  display: block;
  font-size: 15px;
  color: ${colors.black};
  font-weight: 500;
  line-height: 1.2;
`;

export const ProgramInfo = styled.div`
  width: calc(100% - 48px);
`;

export const ProgramMenu = styled.a`
  ${flexItemWithAvatar}
  flex: 0 0 auto;
  margin-bottom: 24px;
  padding: 0 10px;
  border-radius: 5px;
  color: inherit;
  line-height: 1.28;
  width: 100%;

  &:hover,
  &:focus {
    span :not(.ant-avatar-string) {
      color: ${colors.fidelBlue600};
    }
  }

  &:hover,
  &:focus,
  &.ant-dropdown-open {
    background: ${colors.lightgrey2};
  }
`;

export const ProgramList = styled(Menu)`
  position: relative;
  left: -5px;
  width: 230px;
  max-height: 300px;
  overflow: scroll;
  padding-top: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);

  ${hiddenScrollbar}
`;

export const DropdownCaron = styled(Caron)`
  position: absolute;
  right: 10px;
  font-size: 10px;
  color: ${colors.grey4} !important;

  transition: transform 0.3s ease;

  ${ProgramMenu}.ant-dropdown-open & {
    transform: rotateZ(180deg);
  }
`;

export const ViewAll = styled(Menu.Item)`
  position: sticky;
  width: 40%;
  top: 7px;
  margin: -30px 10px 0 auto;
  padding: 0;

  background: white;
  z-index: 3;

  font-size: 13px;
  text-align: right;

  a {
    color: ${colors.fidelBlue600};

    :hover {
      color: ${colors.fidelBlue600};
      opacity: 0.8;
    }
  }

  :hover {
    cursor: default;
    background: white;
  }
`;
