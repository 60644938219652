import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { Switch } from 'antd';

import { updateAccountSecurity } from '../../../store/account/account-actions';
import ChangePassword from './ChangePassword';
import { Select } from '../../../components/select';
import BaseTable from '../../../components/tables/BaseTable';
import { AccountTableDocRow } from '../../../components/tables/styled/helpers';
import { Subsection, SubsectionTitle, SubsectionSubtitle } from '../styled';
import { CertificateSubsection } from '../styled/security';
import { useSetStatus } from '../../../hooks/use-status';
import { ReactComponent as DocumentIcon } from '../../../assets/document.svg';
import Cert20230728 from '../assets/fidel-api-certificate-20230728.crt';
import Cert20240809 from '../assets/fidel-api-certificate-20240809.crt';
import useIsSignedIn from '../../../hooks/use-is-signed-in';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

interface Certificate {
  file: string;
  expDate: string;
  newest?: boolean;
}

const certificates: Certificate[] = [
  {
    file: Cert20240809,
    expDate: 'Aug 09, 2024 23:59:59',
    newest: true,
  },
  {
    file: Cert20230728,
    expDate: 'Jul 28, 2023 23:59:59',
  },
];

const cardNumbersOptions = [
  'firstNumbers',
  'lastNumbers',
  'firstAndLast',
  'showAll',
] as const;

const cardNumbersOptionsMap: { [option: string]: string[] } = {
  firstNumbers: ['firstNumbers'],
  lastNumbers: ['lastNumbers'],
  firstAndLast: ['firstNumbers', 'lastNumbers'],
  showAll: [],
};

const optionToRemoveProperties = (
  option: string,
  removeProperties: string[],
): string[] =>
  removeProperties
    .filter(
      property => property !== 'firstNumbers' && property !== 'lastNumbers',
    )
    .concat(cardNumbersOptionsMap[option]);

const removePropertiesToOption = (removeProperties: string[]): string => {
  if (
    removeProperties.includes('firstNumbers') &&
    removeProperties.includes('lastNumbers')
  )
    return 'firstAndLast';

  if (removeProperties.includes('firstNumbers')) return 'firstNumbers';
  if (removeProperties.includes('lastNumbers')) return 'lastNumbers';

  return 'showAll';
};

const Security = () => {
  const { t } = useTranslation();
  const { t: tAccountSecurity } = useTranslation('common', {
    keyPrefix: 'account.security',
  });
  const dispatch = useAppDispatch();
  const { setSuccessMessage } = useSetStatus();
  const { isAccountAdmin } = useIsSignedIn();
  const { details, errorAccount, loadingAccount } = useAppSelector(
    state => ({
      details: state.account.details,
      errorAccount: state.account.error,
      loadingAccount: state.account.loading,
    }),
    shallowEqual,
  );
  const [certificatePinning, setCertificatePinning] = useState(
    details?.config?.security?.certificatePinning || false,
  );
  const [removeProperties, setRemoveProperties] = useState(
    details?.config?.security?.removeProperties || [],
  );
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const { security } = details?.config || {};

  const hasSucceed = !loadingAccount && !errorAccount && loading;

  const certificateToRow = ({ file, expDate, newest }: Certificate) => ({
    contents: [
      <AccountTableDocRow bold={newest}>
        <DocumentIcon />
        {tAccountSecurity(`certificateTable.docRow${newest ? 'Newest' : ''}`)}
      </AccountTableDocRow>,
      tAccountSecurity('certificateTable.typeRow'),
      expDate,
      <a href={file} download>
        {t('export.download')}
      </a>,
    ],
  });

  useEffect(() => {
    if (hasSucceed) {
      setLoading(false);
      setDirty(false);
      setSuccessMessage(t('form.changesSaved'));
    }
  }, [hasSucceed, setSuccessMessage, t]);

  useEffect(() => {
    if (dirty) {
      setLoading(true);
      dispatch(
        updateAccountSecurity({
          config: {
            security: {
              ...security,
              removeProperties,
              certificatePinning,
            },
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, removeProperties, certificatePinning, dirty]);

  return (
    <>
      <ChangePassword />
      {isAccountAdmin && (
        <>
          <Subsection>
            <SubsectionTitle>
              <div>
                <h2>{tAccountSecurity('cardNumbers')}</h2>
                <SubsectionSubtitle>
                  {tAccountSecurity('cardNumbersDescription')}
                </SubsectionSubtitle>
              </div>
              <Select
                data-testid="card-numbers-select"
                defaultValue={removePropertiesToOption(removeProperties)}
                onChange={option => {
                  setRemoveProperties(
                    optionToRemoveProperties(
                      option as string,
                      removeProperties,
                    ),
                  );
                  setDirty(true);
                }}
                disabled={loading}
                style={{ minWidth: '285px' }}
              >
                {cardNumbersOptions.map(option => (
                  <Select.Option key={option} value={option}>
                    {tAccountSecurity(`cardNumbersOptions.${option}`)}
                  </Select.Option>
                ))}
              </Select>
            </SubsectionTitle>
          </Subsection>
          <CertificateSubsection>
            <SubsectionTitle>
              <div>
                <h2>{tAccountSecurity('certificatePinning')}</h2>
                <SubsectionSubtitle>
                  {tAccountSecurity('certificatePinningDescription')}
                </SubsectionSubtitle>
              </div>
              <Switch
                size="small"
                checked={certificatePinning}
                disabled={loading}
                onChange={() => {
                  setCertificatePinning(!certificatePinning);
                  setDirty(true);
                }}
                data-testid="certificates-switch"
              />
            </SubsectionTitle>
            {certificatePinning && (
              <BaseTable
                bordered
                columns={[
                  {
                    heading: tAccountSecurity('certificateTable.docHeader'),
                    size: 0.7,
                  },
                  {
                    heading: tAccountSecurity('certificateTable.typeHeader'),
                    size: 0.4,
                  },
                  {
                    heading: tAccountSecurity('certificateTable.expDate'),
                    size: 0.7,
                  },
                  { heading: '', size: 0.2, align: 'right' },
                ]}
                rows={certificates.map(certificateToRow)}
              />
            )}
          </CertificateSubsection>
        </>
      )}
    </>
  );
};

export default Security;
