import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ContentProviderOfferFormModel } from '../../../types';
import Form from '../../../../../components/form';

interface ApprovalCheckBoxProps {
  disabled: boolean;
}

function ApprovalCheckbox({ disabled }: ApprovalCheckBoxProps) {
  const { t } = useTranslation('offers');
  const { setValue, getValues } =
    useFormContext<ContentProviderOfferFormModel>();

  const approvalValue = useMemo(() => {
    const { approval } = getValues();

    return approval !== undefined ? approval : true;
  }, [getValues]);

  const [subjectToApproval, setSubjectToApproval] = useState(approvalValue);

  useEffect(() => {
    setValue('approval', subjectToApproval);
  }, [subjectToApproval, setValue]);

  function onChangeHandler({ target }: CheckboxChangeEvent) {
    setSubjectToApproval(target.checked);
  }

  return (
    <Form.Item>
      <Checkbox
        data-testid="subject-to-approval"
        checked={subjectToApproval}
        onChange={onChangeHandler}
        disabled={disabled}
      >
        {t('fields.approval.label')}
      </Checkbox>
    </Form.Item>
  );
}

export default ApprovalCheckbox;
