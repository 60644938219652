export const webhookEvents = [
  'brand.consent',
  'card.linked',
  'card.failed',
  'card.verification.started',
  'card.verification.time.exhausted',
  'card.verification.failed',
  'card.data.sharing.started',
  'card.data.sharing.ended',
  'location.status',
  'program.status',
  'transaction.auth',
  'transaction.auth.qualified',
  'transaction.clearing',
  'transaction.clearing.qualified',
  'transaction.refund',
  'transaction.refund.qualified',
  'marketplace.offer.updated',
  'marketplace.offer.live',
] as const;

export type WebhookEvent = typeof webhookEvents[number];
