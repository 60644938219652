import React from 'react';
import styled from 'styled-components';
import { Dropdown } from 'antd';
import { colors } from '../../../theme';
import { StyledMenuItem } from '../../menu';

export const AvatarNameContainer = styled.div`
  display: flex;
  align-items: center;
  width: 180px;
`;

export const AccountName = styled.p`
  font-size: 15px;
  font-weight: 500;
  margin: 0;
  width: 150px;
`;

export const StyledDropdown = styled(Dropdown)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 10px;
  border-radius: 4px;

  :hover,
  :focus,
  &.ant-dropdown-open {
    background: ${colors.lightgrey2};
  }

  > :last-child {
    margin-left: 10px;
    color: ${colors.grey4};
  }
`;

// Antd Menu passes down props to all it's children
// This component stops that for header
const NoPropsDiv = ({ children, className }: any) => (
  <div className={className}>{children}</div>
);

export const Header = styled(NoPropsDiv)`
  padding: 10px;
  display: flex;
`;

export const Name = styled.p`
  font-size: 14px;
  margin: 0;
  width: 170px;
`;

export const Role = styled.p`
  font-size: 12px;
  color: ${colors.grey4};
  margin: 0;
`;

export const StyledNavMenuItem = styled(StyledMenuItem)`
  padding: 0;

  a {
    margin: 0;
    width: 100%;
  }
`;
