import styled from 'styled-components';
import { Button, Input } from 'antd';
import { colors } from '../../../../../theme';
import { ReactComponent as XIcon } from '../../../../../assets/x.svg';

export const UniqueBrandsSearchContainer = styled.div`
  position: fixed;
  z-index: 4;
`;

export const UniqueBrandsSearchBox = styled.div.attrs(() => ({
  id: 'unique-brands-search-box',
}))<{ $setExpand: boolean }>`
  background: ${colors.white};
  border: 1px solid ${colors.grey2};
  border-radius: 10px;
  box-shadow: 0 4px 20px ${colors.lightgrey};
  padding: 16px 20px;
  height: ${({ $setExpand }) => ($setExpand ? '412' : '200')}px;
  width: calc(100vw - 340px);
  overflow: hidden;
  transition: height 250ms ease-out;

  @media (max-width: 1024px) {
    width: calc(100vw - 300px);
  }
  @media (max-width: 767px) {
    width: calc(100vw - 40px);
  }
`;

export const SearchInput = styled(Input).attrs(() => ({
  autoFocus: true,
  'data-testid': 'uniqueBrandsSearchInput',
}))`
  padding: 0 11px;
  height: 40px;
`;

export const CloseIcon = styled(XIcon).attrs(() => ({
  'data-testid': 'closeUniqueBrandsSearch',
}))`
  color: ${colors.grey4};

  &:hover {
    cursor: pointer;
  }
`;

export const ClearButton = styled(Button).attrs(() => ({
  type: 'text',
  size: 'small',
}))`
  margin-right: 10px;
  color: ${colors.grey4};
`;
