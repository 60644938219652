import styled from 'styled-components';
import { breakpoints, colors } from '../../../theme';

export const SelectionContainer = styled.div<{ $hasActions: boolean }>`
  display: flex;
  margin-top: 10px;
  height: 32px;

  @media (max-width: ${breakpoints.tabletPortrait}px) {
    flex-flow: column wrap;
    height: auto;
  }

  > * {
    margin-right: ${p => (p.$hasActions ? '8px' : '0')};
  }

  > *:last-child {
    margin-right: 0;
  }
`;

export const SelectionText = styled.p`
  line-height: 32px;
  font-size: 14px;
  margin-bottom: 0;
  width: 100%;

  text-align: center;
  border-radius: 4px;
  background: ${colors.lightgrey3};
  color: ${colors.grey4};

  @media (max-width: ${breakpoints.tabletPortrait}px) {
    margin-right: 0px;
    margin-bottom: 10px;
  }
`;

export const Selected = styled.span`
  color: #5f6d83;
  font-weight: bold;
`;
