import React from 'react';
import { useTranslation } from 'react-i18next';

import BaseTable, { BaseTableProps } from './BaseTable';
import { InlineButton } from '../buttons';
import { BottomElement } from './styled/load-more-table';

interface LoadMoreTableProps
  extends Omit<BaseTableProps, 'bottomRowElement' | 'stretch'> {
  onLoadMore: () => void;
  total: number;
}

const LoadMoreTable = ({
  onLoadMore,
  rows,
  total,
  ...rest
}: LoadMoreTableProps) => {
  const { t } = useTranslation();
  return (
    <BaseTable
      stretch={false}
      rows={rows}
      bottomRowElement={
        rows.length < total && (
          <BottomElement>
            <InlineButton type="link" onClick={onLoadMore}>
              {t('table.showMore')}
            </InlineButton>
          </BottomElement>
        )
      }
      {...rest}
    />
  );
};

export default LoadMoreTable;
