import React, { useState } from 'react';
import moment from 'moment';
import { shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Typography, Row } from 'antd';

import { selectProgramsAsList } from '../../store/programs/programs-selectors';
import { Program } from '../../store/programs/programs-reducer';
import { getPrograms, clear } from '../../store/programs/programs-actions';
import InfiniteScrollTable from '../../components/tables/InfiniteScrollTable';
import CreateUpdateProgram from './components/CreateUpdateProgram';
import CopyableTag from '../../components/tag/copyable-tag';
import useRawDetail from '../../components/raw-detail/hooks/use-raw-detail';
import Avatar from '../../components/avatar';
import Ellipsis from '../../components/ellipsis';
import memoNoProps from '../../utils/memo-no-props';
import RecentPrograms from './components/RecentPrograms';
import useSelectedProgram from '../../hooks/use-selected-program';
import useRedirect from '../../hooks/use-redirect';
import useCopyWithNotification from '../../hooks/use-copy-with-notification';
import Header from '../../components/header';
import DEMO_PROGRAM from '../../components/onboarding-tour/demo-program';
import ProgramOptionInfo from '../../components/program-option-info';
import useTransactionStream from '../../hooks/use-transaction-stream';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { AnalyticsForms } from '../../components/analytics-forms';

const Programs = () => {
  const [showCreateProgram, setShowCreateProgram] = useState(false);
  const [programToEdit, setProgramToEdit] = useState<any>();
  const { rawDetail, openRawDetail } = useRawDetail('programs');
  const copyWithNotification = useCopyWithNotification();
  const { t } = useTranslation(['common', 'programs']);
  const redirect = useRedirect();
  const programs = useAppSelector(selectProgramsAsList)();
  const { isLive, loading } = useAppSelector(
    state => ({
      isLive: state.live,
      loading: state.programs.loading,
    }),
    shallowEqual,
  );
  const { setSelectedProgramId } = useSelectedProgram();
  const { hasAccountTransactionStream } = useTransactionStream();
  const dispatch = useAppDispatch();

  function clickOnProgram(program: Program) {
    const { id, type } = program;

    setSelectedProgramId(id);

    const redirectTo =
      !type || type === 'transaction-select' ? '/locations' : '/transactions';
    redirect(redirectTo);
  }

  function programToRow(program: Program) {
    const { created, id, name, icon, iconBackground, type } = program;

    const actions = [
      {
        label: t('common:actions.viewRaw'),
        callback: openRawDetail(id),
      },
      {
        label: t('common:actions.copyId'),
        callback: () => copyWithNotification(id),
      },
      {
        label: t('common:actions.edit'),
        callback: () => setProgramToEdit(program),
      },
    ];

    const onboardingProps: any = {};

    if (name === DEMO_PROGRAM) {
      onboardingProps['data-onboarding-target'] = 'demo-program';
    }

    const contents = [
      <Row align="middle" style={{ flexWrap: 'nowrap' }}>
        <Avatar
          size={24}
          color={{ background: iconBackground }}
          emoji={icon}
          style={{
            marginRight: '10px',
          }}
        >
          {name}
        </Avatar>
        <Ellipsis gutter={40} {...onboardingProps}>
          {name}
        </Ellipsis>
      </Row>,
      <ProgramOptionInfo id={id} type={type} />,
      <div style={{ maxWidth: '175px' }}>
        <CopyableTag text={id} successMessage={t('common:copyable.id')} />
      </div>,
      <Typography.Text type="secondary">
        {moment(created).fromNow()}
      </Typography.Text>,
    ];

    return {
      contents,
      actions,
      onClick: () => clickOnProgram(program),
    };
  }

  const topContent = (
    <>
      <Header
        heading={t('programs:title')}
        topRight={
          <Button
            type="primary"
            size="small"
            data-testid="create-program"
            onClick={() => setShowCreateProgram(true)}
          >
            {t('common:addNew.program')}
          </Button>
        }
      />
      <RecentPrograms programs={programs} onClick={clickOnProgram} />
    </>
  );

  return (
    <>
      <InfiniteScrollTable
        columns={[
          { heading: t('programs:name'), size: 0.3 },
          {
            heading: hasAccountTransactionStream
              ? null
              : isLive
              ? t('programs:locationsHeader')
              : t('programs:programType.columnHeader'),
            size: hasAccountTransactionStream ? 0 : 0.2,
          },
          { heading: t('programs:id'), size: 0.3 },
          {
            heading: t('programs:created'),
            size: 0.15,
            sortedBy: 'unset',
          },
        ]}
        rows={programs.map(program => programToRow(program))}
        total={programs.length}
        loading={loading}
        topContent={topContent}
        emptyText={t('programs:empty')}
        onReload={() => {
          dispatch(clear());
          dispatch(getPrograms());
        }}
      />
      {rawDetail}
      <CreateUpdateProgram
        visible={showCreateProgram}
        onClose={() => setShowCreateProgram(false)}
      />
      <CreateUpdateProgram
        visible={Boolean(programToEdit)}
        onClose={() => setProgramToEdit(null)}
        program={programToEdit}
      />
      <AnalyticsForms />
    </>
  );
};

export default memoNoProps(Programs);
