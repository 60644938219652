import React, { useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button } from 'antd';
import { Card } from '../../store/cards/cards-types';
import * as CardsActions from '../../store/cards/cards-actions';
import { filterCards, clearCards } from '../../store/filters/filters-actions';
import CardsExport from './components/CardsExport';
import CardsList from './components/CardsList';
import FilterBox from '../../components/filter-box';
import useSelectedRows from '../../hooks/use-selected-rows';
import memoNoProps from '../../utils/memo-no-props';
import Header from '../../components/header';
import AddCard from './components/AddCard';
import useLoadCards from '../../hooks/use-load-cards';
import useSelectedProgram from '../../hooks/use-selected-program';
import useTransactionStream from '../../hooks/use-transaction-stream';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

const Cards = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { cards, filters } = useAppSelector(
    state => ({ cards: state.cards.cards, filters: state.filters.cards }),
    shallowEqual,
  );
  const selectedRows = useSelectedRows<Card>(card => card.id, cards);
  const { selectedProgram } = useSelectedProgram();
  const { hasProgramTransactionStream } = useTransactionStream();
  const programId = selectedProgram?.id;
  const loadCards = useLoadCards(programId);

  const sdkProgramType = hasProgramTransactionStream
    ? 'transaction-stream'
    : 'transaction-select';

  useEffect(() => {
    if (filters) {
      loadCards();
    }
  }, [filters, loadCards]);

  useEffect(() => loadCards(), [loadCards]);

  useEffect(
    () => () => {
      dispatch(CardsActions.clear());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const header = (
    <Header
      heading={t('cards.title')}
      topRight={
        <AddCard
          programId={programId}
          programType={sdkProgramType}
          loadCards={loadCards}
          button={
            <Button type="primary" size="small" data-testid="create-card-btn">
              {t('addNew.card')}
            </Button>
          }
        />
      }
      bottomLeft={
        <FilterBox filterName="cards" action={filterCards} clear={clearCards} />
      }
      bottomRight={selectedRows.count === 0 && <CardsExport />}
    />
  );

  return (
    <CardsList
      header={header}
      cards={cards}
      loadCards={loadCards}
      selectedRows={selectedRows}
    />
  );
};

export default memoNoProps(Cards);
