import UserApiService from '../user/user-api-service';
import { actions } from './users-reducer';
import { Thunk } from '../types';

const userApiService = new UserApiService();

export const getUsers = (): Thunk => async dispatch => {
  dispatch(actions.getUsers());

  try {
    const users = await userApiService.getUsers();
    dispatch(actions.getUsersSuccess(users));
  } catch (error) {
    dispatch(actions.getUsersError({ error }));
  }
};

export const inviteUser =
  ({ email, role }: { email: string; role: string }): Thunk =>
  async dispatch => {
    dispatch(actions.inviteUser());

    try {
      await userApiService.inviteUser({ email, role });
      dispatch(actions.inviteUserSuccess());
      dispatch(getUsers());
    } catch (error) {
      dispatch(actions.inviteUserError({ error }));
    }
  };

export const revokeUser =
  ({ userId }: { userId: string }): Thunk =>
  async dispatch => {
    dispatch(actions.revokeUser());

    try {
      await userApiService.revokeUser({ userId });
      dispatch(actions.revokeUserSuccess());
      dispatch(getUsers());
    } catch (error) {
      dispatch(actions.revokeUserError({ error }));
    }
  };

export const cancelUserInvite =
  ({ userId }: { userId: string }): Thunk =>
  async dispatch => {
    dispatch(actions.cancelUserInvite());

    try {
      await userApiService.cancelUserInvite({ userId });
      dispatch(actions.cancelUserInviteSuccess());
      dispatch(getUsers());
    } catch (error) {
      dispatch(actions.cancelUserInviteError({ error }));
    }
  };

export const { clear } = actions;
