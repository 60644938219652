import React from 'react';

import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { GoogleRecaptchaDisclaimerWrapper } from './styled/googleRecaptchaDisclaimer';

export default function GoogleRecaptchaDisclaimer() {
  return (
    <GoogleRecaptchaDisclaimerWrapper>
      <Trans
        ns="common"
        i18nKey="form.captcha"
        components={[
          <Link to="https://policies.google.com/privacy" />,
          <Link to="https://policies.google.com/terms" />,
        ]}
      />
    </GoogleRecaptchaDisclaimerWrapper>
  );
}
