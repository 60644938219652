import styled from 'styled-components';
import { Input } from 'antd';
import { colors } from '../../../../../../theme';
import FilterBox from '../../../../../../components/filter-box';
import { Select } from '../../../../../../components/select';

export const ListTableWrapper = styled.div`
  width: 100%;
  height: 450px;
  margin-top: 20px;
  font-size: 14px;
`;

export const SearchInput = styled(Input).attrs(() => ({
  autoFocus: true,
  'data-testid': 'uniqueBrandsSearchInput',
}))`
  padding: 0 11px;
  height: 40px;
`;

export const Filters = styled(FilterBox)`
  .ant-btn {
    width: 100%;
    height: 40px;
  }
`;

export const SearchFilters = styled(Select)`
  .ant-select-selector {
    color: ${colors.grey4};
    background-color: ${colors.lightgrey3} !important;
    border-color: ${colors.lightgrey3} !important;
    transition: all 100ms ease-out !important;

    &:hover {
      background-color: ${colors.lightgrey} !important;
      border-color: ${colors.lightgrey} !important;
    }
  }
`;

export const LocationIconWrapper = styled.div`
  width: 12px;
  height: 12px;
  margin-right: 12px;
  display: flex;
  align-items: center;
`;
