import { useInfiniteQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import BrandApiService from '../../../store/brands/brands-api-service';
import { State } from '../../../store';

const brandService = new BrandApiService();

export function useBrands({ searchTerm }: { searchTerm: string }) {
  async function queryUniqueBrands({ pageParam }: any) {
    const response = await brandService.getBrands({
      name: searchTerm,
      last: pageParam,
    });

    return response.data;
  }

  return useInfiniteQuery(['brands', searchTerm], queryUniqueBrands, {
    getNextPageParam: lastPage => lastPage.last || undefined,
  });
}

export function useUniqueBrands({
  searchTerm,
  limit = 100,
  enabled = true,
}: {
  searchTerm: string;
  limit?: number;
  enabled?: boolean;
}) {
  async function queryUniqueBrands({ pageParam }: any) {
    const response = await brandService.getUniqueBrands({
      name: searchTerm,
      limit,
      last: pageParam,
    });

    return response.data;
  }

  return useInfiniteQuery(['unique-brands', searchTerm], queryUniqueBrands, {
    getNextPageParam: lastPage => lastPage.last || undefined,
    enabled,
  });
}

export function useAccountUniqueBrands({ searchTerm }: { searchTerm: string }) {
  const accountId = useSelector((state: State) => state.account.details?.id);

  async function queryAccountUniqueBrands({ pageParam }: any) {
    const response = await brandService.getAccountUniqueBrands({
      accountId,
      name: searchTerm,
      last: pageParam,
    });

    return response.data;
  }

  return useInfiniteQuery(
    ['account-unique-brands', searchTerm],
    queryAccountUniqueBrands,
    {
      getNextPageParam: lastPage => lastPage.last || undefined,
    },
  );
}
