import { useState, useEffect } from 'react';

import clearAll from '../../store/clear-all';
import { setLive } from '../../store/live/live-actions';
import { getPrograms } from '../../store/programs/programs-actions';
import { getUserFromIds } from '../../store/user/user-actions';
import useRedirect from '../../hooks/use-redirect';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

export default function useLoadAccountAndRedirect() {
  const redirect = useRedirect();
  const dispatch = useAppDispatch();
  const [redirectTo, setRedirectTo] = useState<string | null>(null);
  const hasAccountDetails = useAppSelector(state => !!state.account.details);

  function loadAccountAndRedirect(account: any, path: string) {
    setRedirectTo(path);

    dispatch(clearAll());
    dispatch(getUserFromIds(account.userId.id, account.id));
    dispatch(getPrograms());
    dispatch(setLive(false));
  }

  useEffect(() => {
    if (hasAccountDetails && redirectTo) {
      setRedirectTo(null);
      redirect(redirectTo);
    }
  }, [hasAccountDetails, redirectTo, redirect, dispatch]);

  return loadAccountAndRedirect;
}
